import React, { useState, useEffect } from 'react';
import { Chip, Paper, Typography, Grid } from '@material-ui/core';
import { useTableStyles } from '../../../../../components/Styles/TableStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import CustomizedDialogs from '../../../../../components/Reusable/Dialogs/DeleteDialog';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { HeadCellsI } from '../../../../../actions';
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemGoto,
  ItemLogList,
} from '../../../../../components/Reusable/Buttons/TableButton';
import useLoading from '../../../../../useHook/useLoading';
import AddNewButton from '../../../../../components/Reusable/Buttons/AddNewButton';
import { Add } from '@material-ui/icons';
import Popups from '../../../../../components/Reusable/Dialogs/Popups';
import LiveClassesForm from './LiveClassesForm';
import {
  DeleteLiveClass,
  GetTeacherOnlineClassLog,
} from '../../../../../actions/TeacherDash/TeacherLiveClass/TeacherLiveAction';
import { LiveClassTypeI } from '../../../../../actions/TeacherDash/TeacherLiveClass/TeacherLiveClassActionTypes';
import NoImg from '../../../../../assets/images/no.gif';
import { dateConverterAdToBs } from '../../../../../components/utils/dateConverter';
import ClassRoomLog from '../../../../../components/ClassRoom/Components/ClassRoomLog';
import useTable from '../../../../../components/Reusable/useStudentTable';
import { tableStyle } from '../../../components/Styles/TeacherDashboardStyles';
import { LOCALURL, URL } from '../../../../../config/SERVER_API_URLS';
import LiveClassConfirm from './LiveClassConfirm';

const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'title', label: 'Title' },
  { id: 'subject', label: 'Subject' },
  { id: 'class_date', label: 'Start Date' },
  { id: 'start_time', label: 'Start Time' },
  { id: 'end_time', label: 'End Time' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' },
];

interface UserI {
  name: string;
}

const LiveClassesList = (props: any) => {
  const dispatch = useDispatch();
  const classes = useTableStyles();
  const history = useHistory();
  const { classData } = props;

  const [tableData, setTableData] = useState<LiveClassTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [classInfo, setClassInfo] = useState<any>(null);
  const [editItem, setEditItem] = useState<any>(null);
  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [title, setTitle] = useState('');
  const [meetLink, setMeetLink] = useState('');
  const [openClass, setOpenClass] = useState(false);

  const [nonEditPopup, setNoneEditPopup] = useState<boolean>(false);
  const [user, setUser] = useState<UserI | null>(null);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const LiveClassSelector = useSelector(
    (state: RootStore) => state.teacher_liveClass
  );
  const auth = useSelector((state: RootStore) => state.auth);
  const teacherProfileState = useSelector(
    (state: RootStore) => state.teacher_profile.teacher_profile
  );

  useEffect(() => {
    if (classData != null) {
      setClassInfo(classData);
    }
  }, [classData]);

  const { loading } = useLoading(LiveClassSelector.loading);

  useEffect(() => {
    if (LiveClassSelector?.live_class != null) {
      const items = LiveClassSelector.live_class;
      setTableData(items);
    }
  }, [LiveClassSelector]);

  const handlePopUpView = () => {
    setOpenPopup(true);
    setEditItem(null);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleEditClose = (value: boolean) => {
    setNoneEditPopup(value);
  };

  const handleEditClicked = (data: any) => {
    setEditItem(data);
    setOpenPopup(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, item: any) => {
    setItemId(item.id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteLiveClass(itemId));
    setDeleteModalOpen(false);
    setItemId(null);
  };

  const handleViewClicked = (id: string) => {
    dispatch(GetTeacherOnlineClassLog(id));
    setOpenViewModalPopup(true);
  };
  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  useEffect(() => {
    if (auth) {
      setUser({ name: auth.user.first_name + ' ' + auth.user.last_name });
    }
  }, [auth]);

  const handleConfirmClose = (value: boolean) => {
    setConfirmModal(value);
  };

  const meetConfirmation = (item: any) => {
    setItemId(item.id);
    setTitle(item.title);
    setMeetLink(item.link);
    setConfirmModal(true);
  };

  const onConfirmedMeet = (value: boolean) => {
    handleConfirmClose(false);
    if (value) {
      setOpenClass(true);
      // history.push("/online-conference/");
      if (isNaN(parseInt(meetLink))) {
        window.open(meetLink);
      } else {
        window.open(`${URL}online-class.html?mid=${meetLink}`);
        if (user?.name && teacherProfileState?.photo) {
          localStorage.setItem('user', user.name);
          localStorage.setItem('user_photo', teacherProfileState.photo);
          localStorage.setItem('home_url', window.location.origin);
        }
      }
    }
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.subject_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.class_date
            ? dateConverterAdToBs(item.class_date)
            : 'Regular Class'}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.start_time}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.end_time}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.is_completed ? (
            <Chip
              color="primary"
              size="small"
              label="Completed"
              className={classes.chipGreen}
            />
          ) : item.is_ongoing ? (
            <Chip
              color="primary"
              size="small"
              label="Running"
              className={classes.chipBlue}
            />
          ) : (
            <Chip
              color="primary"
              size="small"
              label="Upcoming"
              className={classes.chipRed}
            />
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.fourCell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          {/* <Link to={{ pathname: "online-conference", state: item.link }}> */}
          <ItemGoto title="Join class" onClick={() => meetConfirmation(item)} />
          {/* </Link> */}
          <ItemDeleteButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item)
            }
          />
          <ItemLogList
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewClicked(item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper style={tableStyle}>
      <Grid
        container
        justifyContent="flex-end"
        style={{ marginBottom: '16px' }}
      >
        <AddNewButton
          startIcon={<Add />}
          color="primary"
          title="Add New Schedule class"
          variant="outlined"
          onClick={() => handlePopUpView()}
        >
          Schedule Class
        </AddNewButton>
      </Grid>

      <TableContainer
        label="Live Classes List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        rowsPerPage={8}
        getCustomTableRow={getCustomTableRow}
        search_name="subject_name"
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        title={`Schedule New Class ${classInfo?.grade_name} ${classInfo?.name}`}
        maxWidth="sm"
      >
        <LiveClassesForm
          editItem={editItem}
          classInfo={classInfo}
          onClose={handleClose}
        />
      </Popups>
      <Popups
        openPopup={nonEditPopup}
        setOpenPopup={setNoneEditPopup}
        onClose={handleEditClose}
        maxWidth="sm"
        title="Cannot Modify"
        colored
      >
        <Grid container justifyContent="center">
          <img src={NoImg} alt="" />
        </Grid>
        <Grid container justifyContent="center">
          <Typography
            variant="h6"
            style={{ fontSize: '17px' }}
            color="textPrimary"
          >
            You cannot modify this Live Class 😥😥
          </Typography>
          You can only Edit / Delete Upcoming Class
        </Grid>
      </Popups>
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        maxWidth="md"
        fullWidth={true}
        title="Online Class Log"
        colored
      >
        <ClassRoomLog teacher />
      </Popups>
      <Popups
        openPopup={confirmModal}
        setOpenPopup={setConfirmModal}
        onClose={handleConfirmClose}
        title="Confirm a Title for the Meet"
        maxWidth="sm"
      >
        <LiveClassConfirm
          handleConfirmClose={handleConfirmClose}
          itemId={itemId}
          classTitle={title}
          onConfirmedMeet={onConfirmedMeet}
        />
      </Popups>
    </Paper>
  );
};

export default LiveClassesList;
