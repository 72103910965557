import {
  GET_PARENT_LEAVE_LOADING,
  GET_PARENT_LEAVE_ERROR,
  GET_PARENT_LEAVE_SUCCESS,
  ADD_PARENT_LEAVE_LOADING,
  ADD_PARENT_LEAVE_ERROR,
  ADD_PARENT_LEAVE_SUCCESS,
  DELETE_PARENT_LEAVE_LOADING,
  DELETE_PARENT_LEAVE_ERROR,
  DELETE_PARENT_LEAVE_SUCCESS,
  UPDATE_PARENT_LEAVE_LOADING,
  UPDATE_PARENT_LEAVE_ERROR,
  UPDATE_PARENT_LEAVE_SUCCESS,
  ParentLeaveI,
  LeaveDispatchTypes,
} from '../../actions/ParentsDash/ParentLeave/ParentLeaveActionTypes';

interface InitialStateI {
  loading: boolean;
  parent_leaves: any;
}

const initialState: InitialStateI = {
  loading: false,
  parent_leaves: [],
};

const ParentLeaveReducer = (
  state: InitialStateI = initialState,
  action: LeaveDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_PARENT_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PARENT_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_PARENT_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        parent_leaves: action.payload,
      };

    case ADD_PARENT_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_PARENT_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADD_PARENT_LEAVE_SUCCESS:
      const data = [...state.parent_leaves.results, action.payload];
      return {
        ...state,
        loading: false,
        parent_leaves: { ...state.parent_leaves, results: data },
      };

    case UPDATE_PARENT_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PARENT_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PARENT_LEAVE_SUCCESS:
      const current_data: any = state.parent_leaves;
      const index: number = current_data.results.findIndex(
        (el: any) => el.student_academic.id === action.payload.id
      );
      current_data.results[index] = action.payload;

      return {
        ...state,
        loading: false,
        parent_leaves: current_data,
      };

    case DELETE_PARENT_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PARENT_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PARENT_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        parent_leaves: {
          ...state.parent_leaves,
          results: [
            ...state.parent_leaves.results.filter(
              (el: any) => el.student_academic.id === action.payload
            ),
          ],
        },
      };
    default:
      return state;
  }
};

export default ParentLeaveReducer;
