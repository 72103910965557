import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_MEETING_LOADING,
  GET_TEACHER_MEETING_ERROR,
  GET_TEACHER_MEETING_SUCCESS,
  ADD_TEACHER_MEETING_LOADING,
  ADD_TEACHER_MEETING_SUCCESS,
  ADD_TEACHER_MEETING_ERROR,
  DELETE_TEACHER_MEETING_SUCCESS,
  DELETE_TEACHER_MEETING_ERROR,
  DELETE_TEACHER_MEETING_LOADING,
  TeacherMeetingDispatchTypes,
  MeetingPropsI,
  TEACHER_APPROVE_OR_DECLINE_ERROR,
  TEACHER_APPROVE_OR_DECLINE_SUCCESS,
  TEACHER_APPROVE_OR_DECLINE_LOADING,
} from "./TeacherMeetingActionType";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: number;
  meeting_type: number;
  status: string;
  status_name: string;
  meeting_from: string;
  meeting_to: string;
  applied_date: string;
  general_info: number;
  reason_for_meeting: string;
  meeting_name: string;
  no_of_days: string;
  first_name: string;
  last_name: string;
}

export const GetTeacherMeeting =
  () =>
  async (dispatch: Dispatch<TeacherMeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHER_MEETING_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/meeting/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHER_MEETING_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_MEETING_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     // snackbarMessage: error?.response.data.error.detail,
      //   },
      // });
    }
  };

export const AddTeacherMeeting =
  (data: any) =>
  async (dispatch: Dispatch<TeacherMeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_TEACHER_MEETING_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/meeting/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_TEACHER_MEETING_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Teacher Meeting Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_TEACHER_MEETING_ERROR,
        payload: error.response?.data,
      });
    }
  };

export const DeleteTeacherMeeting =
  (id: number) =>
  async (dispatch: Dispatch<TeacherMeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_TEACHER_MEETING_LOADING,
      });

      const res = await axios.delete(
        `${TEACHER_API_URL}/meeting/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_TEACHER_MEETING_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Teacher Meeting Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_TEACHER_MEETING_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Teacher Meeting Delete Failed",
        },
      });
    }
  };

export const GetTeacherSearchedMeetings =
  (query: any) =>
  async (dispatch: Dispatch<TeacherMeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHER_MEETING_LOADING,
      });

      const url = `${TEACHER_API_URL}/meeting/?meeting_type=${query.meetingType}&from_date=${query.date_first}&to_date=${query.date_second}`;

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_TEACHER_MEETING_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_MEETING_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Meetings Loading Failed",
      //   },
      // });
    }
  };

export const TeacherMeetingApproveOrDecline =
  (data: any) =>
  async (dispatch: Dispatch<TeacherMeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: TEACHER_APPROVE_OR_DECLINE_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/meeting/approve_or_decline/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: TEACHER_APPROVE_OR_DECLINE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Meeting status updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: TEACHER_APPROVE_OR_DECLINE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Meeting approve or decline Failed",
        },
      });
    }
  };
