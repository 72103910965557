import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_STUDENT_ASSIGNMENT_LOADING,
  GET_STUDENT_ASSIGNMENT_ERROR,
  GET_STUDENT_ASSIGNMENT_SUCCESS,
  SUBMIT_STUDENT_ASSIGNMENT_LOADING,
  SUBMIT_STUDENT_ASSIGNMENT_SUCCESS,
  SUBMIT_STUDENT_ASSIGNMENT_ERROR,
  GET_STUDENT_ASSIGNMENT_BY_ID_LOADING,
  GET_STUDENT_ASSIGNMENT_BY_ID_ERROR,
  GET_STUDENT_ASSIGNMENT_BY_ID_SUCCESS,
  GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_ERROR,
  GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_LOADING,
  GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_SUCCESS,
  StudentAssignmentDispatchTypes,
} from './StudentAssignmentActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { STUDENT_API_URL } from '../../../config/SERVER_API_URLS';

export const GetStudentAssignmentBySubjectID =
  (id: string, status?: boolean) =>
    async (dispatch: Dispatch<StudentAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_STUDENT_ASSIGNMENT_LOADING,
        });

        let url = `${STUDENT_API_URL}/student-assignment?subject=${id}`;
        if (status !== undefined) {
          url = url + `&submitted=${status}`;
        }

        const res = await axios.get(url, HeaderConfig());

        dispatch({
          type: GET_STUDENT_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_STUDENT_ASSIGNMENT_ERROR,
        });
      }
    };

export const GetStudentAssignmentbyID =
  (id: number) =>
    async (dispatch: Dispatch<StudentAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_STUDENT_ASSIGNMENT_BY_ID_LOADING,
        });

        const url = `${STUDENT_API_URL}/student_assignment_detail/${id}`;

        const res = await axios.get(url, HeaderConfig());

        dispatch({
          type: GET_STUDENT_ASSIGNMENT_BY_ID_SUCCESS,
          payload: res.data,
        });
      } catch (error: any) {
        dispatch({
          type: GET_STUDENT_ASSIGNMENT_BY_ID_ERROR,
        });
      }
    };

export const GetStudentSubmittedAssignmentbyID =
  (id: string) =>
    async (dispatch: Dispatch<StudentAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_LOADING,
        });

        const url = `${STUDENT_API_URL}/student-assignment-submission?assignment=${id}`;

        const res = await axios.get(url, HeaderConfig());

        dispatch({
          type: GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_SUCCESS,
          payload: res.data,
        });
      } catch (error: any) {
        dispatch({
          type: GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_ERROR,
          payload: error.response.data,
        });
      }
    };

export const SubmitStudentAssignment =
  (data: any) =>
    async (dispatch: Dispatch<StudentAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: SUBMIT_STUDENT_ASSIGNMENT_LOADING,
        });
        const res = await axios.post(
          `${STUDENT_API_URL}/student-assignment-submission/`,
          data,
          HeaderConfig()
        );
        dispatch({
          type: SUBMIT_STUDENT_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMessage: 'Assignment Submitttd Successfully.',
          },
        });
      } catch (error: any) {
        dispatch({
          type: SUBMIT_STUDENT_ASSIGNMENT_ERROR,
          payload: error.response.data,
        });
      }
    };

export const SubmitStudentRedoAssignment =
  (id: string, data: any) =>
    async (dispatch: Dispatch<StudentAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: SUBMIT_STUDENT_ASSIGNMENT_LOADING,
        });
        const res = await axios.put(
          `${STUDENT_API_URL}/student-assignment-submission/${id}/`,
          data,
          HeaderConfig()
        );
        dispatch({
          type: SUBMIT_STUDENT_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMessage: 'Assignment Submitttd Successfully.',
          },
        });
      } catch (error: any) {
        dispatch({
          type: SUBMIT_STUDENT_ASSIGNMENT_ERROR,
          payload: error.response.data,
        });
      }
    };
