import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Paper,
  TableBody,
  TableHead,
  Typography,
  createStyles,
  Table,
  Theme,
  makeStyles,
  Chip,
  Dialog,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import TableActionButtons from "../../../../../components/Reusable/Buttons/TableActionButtons";
import { useTable } from "../../../../../components/Reusable";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import { ItemViewButton } from "../../../../../components/Reusable/Buttons/TableButton";
import moment from "moment";
import FeeInvoice from "../../../../../components/Finance/CollectFees/FeeInvoice";
import { GetGeneralInfoData } from "../../../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { RootStore } from "../../../../../store";
import { GetInvoiceDetail } from "../../../../../actions/Fees/Daybook/DaybookAction";

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "date", label: "Date" },
  { id: "receipt", label: "Receipt" },
  { id: "discount", label: "Discount" },
  { id: "paid", label: "Paid" },
  { id: "status", label: "Status" },
];

const useDataGridStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "& th": {
        backgroundColor: "#e3e3e3",
      },
      "& .MuiTableCell-sizeSmall": {
        padding: "2px 2px 0px 2px",
        borderBottom: "0px",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    blue: {
      backgroundColor: "#1f4498",
      color: "white",
    },
  })
);

const PaidChildrenFeeTable = (props: any) => {
  const { statementTableData } = props;
  const classes = useTableStyles();
  const [openInvoice, setOpenInvoice] = useState(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const authData = useSelector((state: RootStore) => state.auth);
  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );
  const {
    TableContainer,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  const dispatch = useDispatch();

  // useEffect(() => {
  //     if (authData.general_info != null) {
  //         dispatch(GetGeneralInfoData(authData.general_info.id));
  //     }
  // }, [authData]);

  useEffect(() => {
    if (itemId !== null) {
      dispatch(GetInvoiceDetail(itemId));
    }
  }, [itemId]);

  const handleView = () => {
    console.log("view");
  };

  const handleViewModal = (id: string) => {
    setItemId(id);
    setOpenInvoice(true);
  };

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const getCustomTableRow = (item: any, index: number) => {
    console.log({ item });
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.created_on).format("YY-MM-DD")}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.receipt_id}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          Rs. {item.discount}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          Rs. {item.paid_amount}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleViewModal(item.receipt_id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <div style={{ maxHeight: "calc(100vh - 120px)", overflowY: "auto" }}>
        <TableContainer
          items={statementTableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          disableSearch
          disablePrint
        />
      </div>
      <Dialog
        open={openInvoice}
        onClose={handleCloseInvoice}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
        // className={classes.modalBox}
      >
        <FeeInvoice
          generalInfoData={generalInfoData}
          student=""
          invoiceID={itemId}
        />
      </Dialog>
    </>
  );
};

export default PaidChildrenFeeTable;
