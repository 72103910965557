import {
  GET_TEACHER_SUBJECT_LOADING,
  GET_TEACHER_SUBJECT_ERROR,
  GET_TEACHER_SUBJECT_SUCCESS,
  GET_TEACHER_ALL_SUBJECTS_LOADING,
  GET_TEACHER_ALL_SUBJECTS_ERROR,
  GET_TEACHER_ALL_SUBJECTS_SUCCESS,
  GET_TEACHER_SUBJECTS_BY_CLASS_ERROR,
  GET_TEACHER_SUBJECTS_BY_CLASS_LOADING,
  GET_TEACHER_SUBJECTS_BY_CLASS_SUCCESS,
  TeacherSubjectDispatchTypes,
  TeacherSubjectsI,
  TeacherSubjectByClass,
  TeacherAllSubjectsI
} from "../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes";

interface InitialStateI {
  loading: boolean;
  subjects: TeacherSubjectsI[];
  all_subjects: TeacherAllSubjectsI[];
  subject_by_class: TeacherSubjectByClass[],
}

const initialState: InitialStateI = {
  loading: false,
  subjects: [],
  all_subjects: [],
  subject_by_class: [],
};

const TeacherSubjectReducer = (
  state: InitialStateI = initialState,
  action: TeacherSubjectDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_SUBJECT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: action.payload,
      };

    case GET_TEACHER_ALL_SUBJECTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_ALL_SUBJECTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_ALL_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        all_subjects: action.payload,
      };

    case GET_TEACHER_SUBJECTS_BY_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_SUBJECTS_BY_CLASS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_SUBJECTS_BY_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        subject_by_class: action.payload,
      };

    default:
      return state;
  }
};

export default TeacherSubjectReducer;
