import {
  GET_PARENT_STUDENT_TEACHERS_LOADING,
  GET_PARENT_STUDENT_TEACHERS_ERROR,
  GET_PARENT_STUDENT_TEACHERS_SUCCESS,
  ParentStudentTeachersDispatchTypes,
  ParentStudentTeachersI,
} from "../../actions/ParentsDash/ParentStudentTeachers/ParentStudentTeachersActionTypes";

interface InitialStateI {
  loading: boolean;
  student_teachers: ParentStudentTeachersI[];
}

const initialState: InitialStateI = {
  loading: false,
  student_teachers: [],
};

const ParentStudentTeachersReducer = (
  state: InitialStateI = initialState,
  action: ParentStudentTeachersDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_PARENT_STUDENT_TEACHERS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_PARENT_STUDENT_TEACHERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_PARENT_STUDENT_TEACHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        student_teachers: action.payload,
      };

    default:
      return state;
  }
};

export default ParentStudentTeachersReducer;
