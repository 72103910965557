import styled from "styled-components";
import { useHistory } from 'react-router-dom';
import { TeacherClassSubjectsI } from '../../rtk/features/timetable/timetable'

const Card = styled.div`
    background-color: #eceef7;
    padding: 0.75em 1.5em;
    color: #fff;
    cursor: pointer;
`

const Value = styled.p`
    font-size: 2em;
    margin: 0;
    padding: 0;
    color: #132e98;
`

const SubjectCard = ({ data }: { data: TeacherClassSubjectsI }) => {
    const history = useHistory();
    const onRedirect = (id: string) => {
        history.push({
            pathname: `/assignment/detail/${id}`,
            state: { detail: data },
        });
    }
    return (
        <Card onClick={() => onRedirect(data.id)}>
            <Value>{data?.classes?.grade_name} ({data?.classes?.section?.name})</Value>
            <Value>{data?.subject?.name}</Value>
        </Card>
    )
}

export default SubjectCard