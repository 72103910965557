import {
  GET_TEACHER_ENOTES_CATEGORIES_LOADING,
  GET_TEACHER_ENOTES_CATEGORIES_ERROR,
  GET_TEACHER_ENOTES_CATEGORIES_SUCCESS,
  GET_TEACHER_ENOTES_LOADING,
  GET_TEACHER_ENOTES_ERROR,
  GET_TEACHER_ENOTES_SUCCESS,
  CREATE_TEACHER_ENOTES_LOADING,
  CREATE_TEACHER_ENOTES_ERROR,
  CREATE_TEACHER_ENOTES_SUCCESS,
  TeacherEnotesCategoryI,
  TeacherEnotesI,
  TeacherEnotesCategoriesDispatchTypes,
} from "../../actions/TeacherDash/TeacherEnotes/TeacherEnotesActionTypes";

interface InitialStateI {
  loading: boolean;
  enotes: TeacherEnotesI[];
  enotes_categories: TeacherEnotesCategoryI[];
  errors: null;
}

const initialState: InitialStateI = {
  loading: false,
  enotes: [],
  enotes_categories: [],
  errors: null
};

const TeacherEnotesReducer = (
  state: InitialStateI = initialState,
  action: TeacherEnotesCategoriesDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_ENOTES_CATEGORIES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_ENOTES_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_ENOTES_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        enotes_categories: action.payload,
      };

    case GET_TEACHER_ENOTES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_ENOTES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_ENOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        enotes: action.payload,
      };
    case CREATE_TEACHER_ENOTES_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case CREATE_TEACHER_ENOTES_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case CREATE_TEACHER_ENOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    default:
      return state;
  }
};

export default TeacherEnotesReducer;
