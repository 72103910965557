import React, { useState, useRef, useEffect } from 'react'
import { Grid, Paper, TableBody, TableHead, Typography, createStyles, Table, Theme, makeStyles, Chip, Checkbox } from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import TableActionButtons from '../../../../../components/Reusable/Buttons/TableActionButtons';
import { useTable } from '../../../../../components/Reusable';
import { useTableStyles } from '../../../../../components/Styles/TableStyles';
import { ItemViewButton } from '../../../../../components/Reusable/Buttons/TableButton';
import moment from 'moment';
import MyLiveClassesWidget from '../../../../StudentDashboard/components/Widget/MyLiveClassesWidget';
import GuardianPayFee from './GuardianPayFee';
import cx from "clsx";

interface HeadCellsI {
    id: string;
    label: string;
}

const headCells: HeadCellsI[] = [
    { id: "sn", label: "S.N." },
    { id: "date", label: "Issue Date" },
    { id: "fee_head", label: "Fee Head" },
    { id: "period", label: "Period" },
    { id: "charges", label: "Charges" },
    { id: "fine", label: "Fine" },
    { id: "balance", label: "Balance" },
];

const useDataGridStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            "& th": {
                backgroundColor: "#e3e3e3",
            },
            "& .MuiTableCell-sizeSmall": {
                padding: "2px 2px 0px 2px",
                borderBottom: '0px',
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        blue: {
            backgroundColor: '#1f4498',
            color: 'white'
        },
        payContainer: {
            backgroundColor: 'red',
            padding: '50px'
        }
    })
);

const InvoiceChildrenFeeTable = (props: any) => {
    const { statementTableData, student } = props;
    const classes = useTableStyles();
    const [selected, setSelected] = useState<string[]>([]);


    const {
        TableContainer,
        StyledTableCell,
        StyledTableRow,
        exportToExcel,
        tablePrint,
    } = useTable(headCells);

    const handleView = () => {
        console.log('view')
    };


    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = statementTableData.map((n: any) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    console.log({ selected })


    const handleCheckClick = (name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const getCustomTableRow = (item: any, index: number) => {
        const isItemSelected = isSelected(item.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        const cellStyle = cx(classes.cell, classes.cellSm);
        return (
            <StyledTableRow
                key={item.id}
                selected={isItemSelected}
                aria-checked={isItemSelected}
                role="checkbox"
                tabIndex={-1}
            >
                <StyledTableCell align="left" className={cellStyle}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Checkbox
                                color="default"
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                onClick={() => handleCheckClick(item.id)}
                            />
                        </Grid>
                        <Grid item>
                            {index}
                        </Grid>
                    </Grid>
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {moment(item.date).format('YY-MM-DD')}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.fee_head}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.period}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    Rs. {item.charges}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    Rs. {item.fine || 0}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    Rs. {item.balance || 0}
                </StyledTableCell>
            </StyledTableRow>
        );
    };

    return (
        <>
            <GuardianPayFee selected={selected} setSelected={setSelected} statementTableData={statementTableData} student={student} />
            <div style={{ maxHeight: "calc(100vh - 120px)", overflowY: 'auto' }}>
                <TableContainer
                    hasCheckbox
                    rowCount={statementTableData?.length}
                    handleSelectAllClicked={handleSelectAllClick}
                    items={statementTableData}
                    headCells={headCells}
                    getCustomTableRow={getCustomTableRow}
                    disableSearch
                    rowsPerPage={100}
                    numSelected={selected?.length}
                    disablePrint
                    disablePagination
                />
            </div>
        </>
    )
}

export default InvoiceChildrenFeeTable