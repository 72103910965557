import {
  GET_TEACHING_CLASS_LOADING,
  GET_TEACHING_CLASS_ERROR,
  GET_TEACHING_CLASS_SUCCESS,
  TeachingClassDispatchTypes,
  TeachingClassTypeI,
} from "../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";

interface InitialStateI {
  loading: boolean;
  teaching_class: any;
}

const initialState: InitialStateI = {
  loading: false,
  teaching_class: [],
};

const TeachingClassReducer = (
  state: InitialStateI = initialState,
  action: TeachingClassDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHING_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHING_CLASS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHING_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        teaching_class: action.payload.map((item: any) => ({
          ...item,
          // grade_name: item.grade_name,
        })),
        // teaching_class: action.payload,
      };

    default:
      return state;
  }
};

export default TeachingClassReducer;
