import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import { Box, Divider } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DashboardSyllabusI } from "../../../../actions/TeacherDash/Dasbhoard/TeacherDashboardActionTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      paddingTop: "0",
      paddingBottom: "8px",
    },
    title: {
      paddingTop: "12px",
      paddingBottom: "12px",
    },
    itemTitle: {
      fontWeight: 500,
      marginBottom: 5,
      display: "inline-block",
    },
  })
);

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 12,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundImage: `-webkit-linear-gradient(45deg,rgba(255,255,255,.2) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.2) 50%,rgba(255,255,255,.2) 75%,transparent 75%,transparent)`,
      backgroundSize: "40px 40px",
    },
  })
)(LinearProgress);

export default function TeacherSyllabusWidget(props: any) {
  const classes = useStyles();
  const { syllabus } = props;
  const data = [15, 35, 68, 97, 58, 67, 87];

  return (
    <List className={classes.root}>
      <ListItem dense button className={classes.title}>
        <ListItemText
          primary={
            <span
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "rgba(0,0,0,0.85)",
              }}
            >
              Your Syllabus
            </span>
          }
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="comments">
            <ChevronRightIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Box paddingLeft="16px" paddingRight="16px">
        <Divider />
      </Box>
      <div style={{ height: 200, overflowY: "auto" }}>
        {syllabus?.map((item: DashboardSyllabusI, index: number) => {
          const labelId = `checkbox-list-label-${item.id}`;

          return (
            <ListItem key={item.id} role={undefined} dense button>
              <ListItemText
                id={labelId}
                primary={
                  <div>
                    <span className={classes.itemTitle}>{item.name}</span>
                    <BorderLinearProgress
                      variant="determinate"
                      value={data[index]}
                    />
                  </div>
                }
              />
              <ListItemSecondaryAction>
                <b style={{ fontWeight: 500, paddingLeft: 15 }}>
                  {data[index]}%
                </b>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </div>
    </List>
  );
}
