import { Box, Grid } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import StudentEnoteNav from "./StudentEnotesNav";
import StudentEnoteList from "./StudentEnotesList";
import { useState } from "react";
import PageWrapper from "../../components/PageWrapper";

interface CategoryNavI {
  id: string;
  name: string;
}

const StudentEnotePage = () => {
  const [categoryNav, setCategoryNav] = useState<CategoryNavI | null>(null);

  const onCategoryClick = (val: CategoryNavI) => {
    setCategoryNav(val);
  };

  return (
    <Box>
      <PageHeader title="Student Events" />

      <PageWrapper>
        <Grid container>
          <Grid item xs={4}>
            <StudentEnoteNav categoryNav={onCategoryClick} />
          </Grid>
          <Grid item xs={8}>
            <StudentEnoteList enoteCategory={categoryNav} />
          </Grid>
        </Grid>
      </PageWrapper>
    </Box>
  );
};

export default StudentEnotePage;
