import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_EXAM_SCHEDULE_LOADING,
  GET_STUDENT_EXAM_SCHEDULE_ERROR,
  GET_STUDENT_EXAM_SCHEDULE_SUCCESS,
  StudentExamScheduleDispatchTypes,
} from "./StudentExamScheduleActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { STUDENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetStudentExamSchedule = (exam_info: number) => async (
  dispatch: Dispatch<StudentExamScheduleDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_STUDENT_EXAM_SCHEDULE_LOADING,
    });

    const res = await axios.get(
      `${STUDENT_API_URL}/exam_schedule/?exam_info=${exam_info}`,
      HeaderConfig()
    );

    dispatch({
      type: GET_STUDENT_EXAM_SCHEDULE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_EXAM_SCHEDULE_ERROR,
    });
  }
};
