import React, { useState } from "react";
import { FullSubModuleLayout } from "../../../../components/Reusable/Layouts/SubModuleLayout";
import ParentsDashSidebar from "../../../../components/ParentsDash/ParentsDashSidebar";
import ChildAttendanceTable from "./ChildAttendanceTable";
import SearchChildAttendace from "./SearchChildAttendace";
import { Tupple } from "../../../../common/json.constant";
import { Box } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";

const AttendanceReportPage = () => {
  const nav = <ParentsDashSidebar AttendanceReport />;
  const [monthName, setMonthName] = useState<Tupple | null>(null);
  const [childrenName, setChildrenName] = useState<Tupple | null>(null);

  return (
    <Box>
      <PageHeader title="Attendance Report" />

      <PageWrapper>
        <SearchChildAttendace
          setMonthName={setMonthName}
          setChildrenName={setChildrenName}
        />
        <ChildAttendanceTable
          monthName={monthName}
          childrenName={childrenName}
        />
      </PageWrapper>
    </Box>
  );
};

export default AttendanceReportPage;
