import { Box, makeStyles, Theme } from '@material-ui/core';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/utils/PrivateRoute';
import ParentDashboardSidebar from './ParentDashboardSidebar';
import ParentDashboardPage from './pages/Dashboard/ParentDashboardPage';
import PageNotFound from './pages/Error/PageNotFound';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';
import { useEffect } from 'react';
// import { GetStudentProfile } from "../../actions/StudentDash/StudentProfile/StudentProfileAction";
import ViewChildPage from './pages/MyChildren/ViewChildPage';
import ParentLiveClassPage from './pages/LiveClasses/ParentLiveClassPage';
import ParentLessonPlanPage from './pages/LessonPlan/ParentLessonPlanPage';
import ParentStudentTeachersPage from './pages/StudentTeachers/ParentStudentTeachersPage';
import ParentTimeTablePage from './pages/Timetable/ParentTimeTablePage';
import ChildrenAssignmentPage from './pages/Assignment/ViewChild';
import ParentSyllabusPage from './pages/Syllabus/ParentSyllabusPage';
import ParentEnotePage from './pages/Enotes/ParentEnotesPage';
import ParentAnnouncementPage from './pages/Annoucement/ParentAnnouncementPage';
import ParentEventsPage from './pages/Events/ParentEventsPage';
import ParentLeaveRequestPage from './pages/LeaveRequest/ParentLeaveRequestPage';
import ParentMeetingRequestPage from './pages/MeetingRequest/ParentMeetingRequestPage';
import ExamReportPage from './pages/AcademicReport/ExamReport.tsx/ExamReportPage';
import AttendanceReportPage from './pages/AttendanceReport/AttendanceReportPage';
import { GetChildrenWithAcademic } from '../../actions/ParentsDash/ViewChild/ViewChildAction';
import ParentProfile from './pages/Profile/ParentProfile';
import ParentProfileUpdate from './pages/Profile/ParentProfileUpdate';
import AssignmentSubmitPage from "../StudentDashboard/pages/Assignment/SubmitAssignment/AssignmentSubmitPage"

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    background: theme.parentPalette.primary,
    minHeight: '100vh',
  },
  container: {
    display: 'block',
    position: 'relative',
  },
}));

const ParentDashboardApp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const authState = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    dispatch(GetChildrenWithAcademic());

    // dispatch(
    //   localStorage.getItem("hamro_user") &&
    //   GetStudentProfile(JSON.parse(localStorage.getItem("hamro_user")!).id)
    // );
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <ParentDashboardSidebar />

        <Switch>
          <PrivateRoute exact path="/" component={ParentDashboardPage} />
          <PrivateRoute exact path="/view-child" component={ViewChildPage} />
          <PrivateRoute
            exact
            path="/children-assignment"
            component={ChildrenAssignmentPage}
          />
          <PrivateRoute
            exact
            path="/children-assignment/submit/:id"
            component={AssignmentSubmitPage}
          />
          <PrivateRoute
            exact
            path="/academic-report"
            component={ExamReportPage}
          />
          <PrivateRoute
            exact
            path="/announcement"
            component={ParentAnnouncementPage}
          />
          <PrivateRoute exact path="/events" component={ParentEventsPage} />
          <PrivateRoute
            exact
            path="/live-class"
            component={ParentLiveClassPage}
          />
          <PrivateRoute
            exact
            path="/leave-request"
            component={ParentLeaveRequestPage}
          />
          <PrivateRoute
            exact
            path="/meeting-request"
            component={ParentMeetingRequestPage}
          />
          <PrivateRoute
            exact
            path="/timetable"
            component={ParentTimeTablePage}
          />
          <PrivateRoute
            exact
            path="/attendance-report"
            component={AttendanceReportPage}
          />
          <PrivateRoute
            exact
            path="/child-teachers"
            component={ParentStudentTeachersPage}
          />
          <PrivateRoute exact path="/profile" component={ParentProfile} />
          <PrivateRoute
            exact
            path="/:guardianID/edit"
            component={ParentProfileUpdate}
          />
          <PrivateRoute exact path="/enotes" component={ParentEnotePage} />
          <PrivateRoute exact path="/syllabus" component={ParentSyllabusPage} />
          <PrivateRoute
            exact
            path="/lesson-plan"
            component={ParentLessonPlanPage}
          />
          <PrivateRoute component={PageNotFound} />
        </Switch>
      </Box>
    </Box>
  );
};

export default ParentDashboardApp;
