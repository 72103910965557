import React, { useEffect, useState } from "react";
import { Button, Grid, InputLabel, Paper, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../../actions/Academics/Class/ClassAction";
import { GetStudentAcademicInfo } from "../../../../actions/ParentsDash/AcademicInfo/StudentAcademicInfoAction";
import { RootStore } from "../../../../store";
import FullWidthFormLayout from "../../../../components/Reusable/Layouts/FullWidthForm.layout";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import getFullName from "../../../../components/utils/getFullName";
import { MONTH_CHOICES } from "../../../../common/json.constant";
import { GetDates } from "../../../../actions/Dates/DatesAction";
import CustomizedSelect, {
  RowSelect,
} from "../../../../components/Reusable/Inputs/Select";
import {
  GetTeacherClass,
  GetTeacherClassSection,
} from "../../../../actions/TeacherDash/TeacherClass/TeacherClassAction";
import { GetClassAttendanceReport } from "../../../../actions/TeacherDash/ClassAttendanceReport/AttendanceReportAction";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../../../components/utils/dateConverter";
import { Tupple } from "../../../../common/json.constant";
import { MyAttendances } from "../../../../actions/StudentDash/MyAttendance/MyAttendanceAction";
import { filterStyle } from "../../components/Styles/TeacherDashboardStyles";
import { GetCheckinCheckout } from "../../../../actions/TeacherDash/Attendance/TeacherAttendanceAction";

interface FormI {
  month_name: string | null;
  children_name: number | null;
}

const SearchReport = (props: any) => {
  const classes = useFormStyles();

  const { register, errors, setError } = useForm<FormI>();

  const [month, setMonth] = useState<Tupple | null>(null);
  const [monthName, setMonthName] = useState<string | any>(null);
  const [children, setChildren] = useState<Tupple | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number | null>(null);
  const [classChoice, setClassChoice] = useState<any | null>(null);
  const [sectionChoice, setSectionChoice] = useState<any | null>(null);
  const [sectionChoices, setSectionChoices] = useState<any | null>(null);

  const dispatch = useDispatch();
  const studentAcademicState = useSelector(
    (state: RootStore) => state.student_academic
  );
  const dateSelector = useSelector(
    (state: RootStore) => state.month_dates.data
  );
  const classState = useSelector((state: RootStore) => state.teacher_class);

  const teacherAttendanceState = useSelector(
    (state: RootStore) => state.teacher_attendance?.teacher_attendance
  );

  const monthDates = useSelector(
    (state: RootStore) => state.month_dates?.data?.dates
  );

  const inputStyle = { marginBottom: "0px" };

  useEffect(() => {
    dispatch(GetTeacherClass());
    dispatch(GetTeacherClassSection());
    const utc = new Date().toJSON().slice(0, 10);
    setCurrentMonth(parseInt(dateConverterAdToBs(utc).substring(5, 7)) - 1);
    dispatch(GetDates(parseInt(dateConverterAdToBs(utc).substring(5, 7))));
  }, []);

  useEffect(() => {
    if (currentMonth != null) {
      dispatch(GetDates(currentMonth + 1));
      setMonth(MONTH_CHOICES[currentMonth]);
    }
  }, [currentMonth]);

  useEffect(() => {
    setMonthName(month?.value);
  }, [month]);

  const handleMonthChange = (value: Tupple) => {
    if (value != null) {
      setMonth(value);
      dispatch(GetDates(value.key));
    } else {
      setMonth(null);
    }
  };

  useEffect(() => {
    if (dateSelector.dates != null) {
      const searchData = {
        from_date: dateConverterBsToAd(dateSelector.dates[0]),
        to_date: dateConverterBsToAd(
          dateSelector.dates[dateSelector.dates.length - 1]
        ),
      };
      dispatch(GetCheckinCheckout(searchData.from_date, searchData.to_date));
    }
  }, [dateSelector.dates, month]);

  // console.log(teacherAttendanceState, monthDates);

  return (
    <>
      <Paper style={filterStyle}>
        <Grid container spacing={3}>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <RowSelect
              fullWidth
              label="Month"
              name="month_name"
              options={MONTH_CHOICES}
              value={month}
              setValue={handleMonthChange}
              error={errors["month_name"] && errors["month_name"].message}
              inputRef={register({ required: true })}
              placeholder="Label"
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default SearchReport;
