import {
  Box,
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';

import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { SectionTypeI } from '../../../../actions/Academics/Section/SectionActionTypes';
import { GetExams } from '../../../../actions/Examination/Exam/ExamAction';
import { ExamTypeI } from '../../../../actions/Examination/Exam/ExamActionTypes';
import { GetExamSubjects } from '../../../../actions/Examination/ExamSchedule/ExamScheduleAction';
import { GetTeachingClass } from '../../../../actions/TeacherDash/TeachingClass/TeachingClassAction';
import { TeachingClassTypeI } from '../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes';
import {
  SubmitButton,
  ResetButton,
} from '../../../../components/Reusable/Buttons/SearchButton';
import {
  RowSelect,
  InputBox,
} from '../../../../components/Reusable/Inputs/Select';
import FullWidthFormLayout from '../../../../components/Reusable/Layouts/FullWidthForm.layout';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import { RootStore } from '../../../../store';
import { filterStyle } from '../../components/Styles/TeacherDashboardStyles';

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//

interface PropsI {
  onSearch: (data: SearchDataI | null) => void;
}

interface SearchDataI {
  grade: string;
  section: string;
}

interface FormI {
  exam_type: string | null;
  class: number | null;
  section: string | null;
}

// -----------------<end> Interfaces <end>-----------------------//

const ExamScheduleSearch = (props: PropsI) => {
  const { onSearch } = props;
  const classes = useFormStyles();

  const { register, handleSubmit, errors } = useForm<FormI>();

  ////////////////// State declarations ///////////////////////////////////////////

  const [examType, setExamType] = useState<ExamTypeI | null>(null);
  const [examTypeChoices, setExamTypeChoices] = useState<ExamTypeI[]>([]);
  const [selectedClass, setSelectedClass] = useState<any | null>(null);
  const [classChoices, setClassChoices] = useState<TeachingClassTypeI[]>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  ////////////////////////////////////////////////////////////////////////////////

  //======================================== <START> REACT HOOKS <START> ============================//

  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.teaching_class);
  const examSelector = useSelector((state: RootStore) => state.exam);

  useEffect(() => {
    dispatch(GetTeachingClass());
    dispatch(GetExams());
  }, []);

  const EXAM_CHOICES = examSelector.exams.map((item) => ({
    id: item.id,
    value: item.exam_name,
  }));
  const CLASS_CHOICES = classSelector.teaching_class.map((item: any) => ({
    id: item.id,
    value: item.grade_name,
    grade: item.grade,
    section: item.section,
  }));

  useEffect(() => {
    classSelector.teaching_class &&
      setClassChoices(classSelector.teaching_class);
  }, [classSelector]);

  useEffect(() => {
    setExamTypeChoices(examSelector.exams);
  }, [examSelector]);

  //======================================== <END> REACT HOOKS <END> ============================//

  //======================================== <START> EVENT HANDLERS <START> ============================//

  const handleClassChange = (value: any | null) => {
    setSelectedClass(value);
    setSelectedSection(null);
    value != null
      ? populateSectionChoices(value.section)
      : setSectionDisabler(true);
  };

  const populateSectionChoices = (sections: SectionTypeI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const handleSearch = (e: any) => {
    if (examType != null && selectedClass != null) {
      dispatch(
        GetExamSubjects(
          examType.id,
          selectedClass.grade,
          selectedSection != null ? selectedSection.id : ''
        )
      );
      onSearch({
        grade: selectedClass?.value,
        section: selectedSection != null ? selectedSection.name : '',
      });
    }
    e.preventDefault();
  };

  const handleFilter = () => {
    setSelectedClass(null);
    setExamType(null);
    setSelectedSection(null);
    onSearch(null);
  };
  //======================================== <END> EVENT HANDLERS <END> ============================//

  return (
    <>
      <Paper style={filterStyle}>
        <form className={classes.form} onSubmit={handleSearch}>
          <Box className={classes.formTitle}>
            <span>Search Exam Schedule</span>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={4} className={classes.formWrapperRow}>
              <RowSelect
                fullWidth
                label="Exam"
                name="exam_type"
                options={EXAM_CHOICES}
                value={examType}
                placeholder="Label"
                setValue={setExamType}
              />
            </Grid>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <RowSelect
                fullWidth
                label="Class"
                name="class"
                options={CLASS_CHOICES}
                value={selectedClass}
                placeholder="Label"
                setValue={handleClassChange}
                inputRef={register({ required: true })}
                error={errors['class'] ? 'Required Field.' : ''}
              />
            </Grid>
            <Grid item xs={2} className={classes.formWrapperRow}>
              <InputBox>Section </InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                value={selectedSection}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionTypeI | null
                ) => setSelectedSection(value)}
                options={sectionChoices}
                getOptionLabel={(option) => option.name}
                disabled={sectionDisabler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="section_name"
                    inputRef={register}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <SubmitButton style={{ marginTop: '5px' }} />
            <ResetButton style={{ marginTop: '5px' }} onClick={handleFilter} />
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default ExamScheduleSearch;
