export const GET_TEACHER_CLASS_LOADING = "GET_TEACHER_CLASS_LOADING";
export const GET_TEACHER_CLASS_ERROR = "GET_TEACHER_CLASS_ERROR";
export const GET_TEACHER_CLASS_SUCCESS = "GET_TEACHER_CLASS_SUCCESS";

export const GET_TEACHER_SECTION_LOADING = "GET_TEACHER_SECTION_LOADING";
export const GET_TEACHER_SECTION_ERROR = "GET_TEACHER_SECTION_ERROR";
export const GET_TEACHER_SECTION_SUCCESS = "GET_TEACHER_SECTION_SUCCESS";

export interface TeacherClassSectionI {
  grade: string;
  grade_name: string;
  section: string | null;
  section_name?: string;
}

//===============================<START>GET TEACHER CLASS <START>======================//

export interface GetTeacherClassLoading {
  type: typeof GET_TEACHER_CLASS_LOADING;
}

export interface GetTeacherClassError {
  type: typeof GET_TEACHER_CLASS_ERROR;
}

export interface GetTeacherClassSuccess {
  type: typeof GET_TEACHER_CLASS_SUCCESS;
  payload: TeacherClassSectionI[];
}

//===============================<END>GET TEACHER CLASS <END>======================//

//===============================<START>GET TEACHER SECTION <START>======================//

export interface GetTeacherSectionLoading {
  type: typeof GET_TEACHER_SECTION_LOADING;
}

export interface GetTeacherSectionError {
  type: typeof GET_TEACHER_SECTION_ERROR;
}

export interface GetTeacherSectionSuccess {
  type: typeof GET_TEACHER_SECTION_SUCCESS;
  payload: TeacherClassSectionI[];
}

//===============================<END>GET TEACHER SECTION <END>======================//

export type TeacherClassDispatchTypes =
  | GetTeacherClassLoading
  | GetTeacherClassError
  | GetTeacherClassSuccess
  | GetTeacherSectionLoading
  | GetTeacherSectionSuccess
  | GetTeacherSectionError;
