import {
  GET_STUDENT_LEAVE_LOADING,
  GET_STUDENT_LEAVE_ERROR,
  GET_STUDENT_LEAVE_SUCCESS,
  ADD_STUDENT_LEAVE_LOADING,
  ADD_STUDENT_LEAVE_ERROR,
  ADD_STUDENT_LEAVE_SUCCESS,
  DELETE_STUDENT_LEAVE_LOADING,
  DELETE_STUDENT_LEAVE_ERROR,
  DELETE_STUDENT_LEAVE_SUCCESS,
  UPDATE_STUDENT_LEAVE_LOADING,
  UPDATE_STUDENT_LEAVE_ERROR,
  UPDATE_STUDENT_LEAVE_SUCCESS,
  StudentLeaveDispatchTypes,
  StudentLeaveTypeI,
} from "../../actions/StudentDash/StudentLeave/StudentDashLeaveActionTypes";

interface InitialStateI {
  loading: boolean;
  studentDash_leave: StudentLeaveTypeI[];
  errors: any;
}

const initialState: InitialStateI = {
  loading: false,
  studentDash_leave: [],
  errors: null,
};

const StudentDashLeaveReducer = (
  state: InitialStateI = initialState,
  action: StudentLeaveDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STUDENT_LEAVE_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };

    case GET_STUDENT_LEAVE_ERROR:
      return {
        ...state,
        errors: null,
        loading: false,
      };

    case GET_STUDENT_LEAVE_SUCCESS:
      return {
        loading: false,
        errors: null,
        studentDash_leave: action.payload,
      };

    case ADD_STUDENT_LEAVE_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };
    case ADD_STUDENT_LEAVE_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case ADD_STUDENT_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        studentDash_leave: [...state.studentDash_leave, action.payload],
      };

    case UPDATE_STUDENT_LEAVE_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };
    case UPDATE_STUDENT_LEAVE_ERROR:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case UPDATE_STUDENT_LEAVE_SUCCESS:
      const current_data: StudentLeaveTypeI[] = state.studentDash_leave;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        errors: null,
        studentDash_leave: current_data,
      };

    case DELETE_STUDENT_LEAVE_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };
    case DELETE_STUDENT_LEAVE_ERROR:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case DELETE_STUDENT_LEAVE_SUCCESS:
      const new_data: StudentLeaveTypeI[] = state.studentDash_leave;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        errors: null,
        loading: false,
        studentDash_leave: new_data,
      };

    default:
      return state;
  }
};

export default StudentDashLeaveReducer;
