import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { HeadCellsI } from "../../../../../actions";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import cx from "clsx";
import { ParentLessonTypeI } from "../../../../../actions/ParentsDash/ParentLessonPlan/ParentLessonPlanActionTypes";
import { ItemViewButton } from "../../../../../components/Reusable/Buttons/TableButton";
import Popups from "../../../../../components/Reusable/Dialogs/Popups";
import { GetParentLessonPlan } from "../../../../../actions/ParentsDash/ParentLessonPlan/ParentLessonPlanAction";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import useTable from "../../../../../components/Reusable/useStudentTable";
import LessonPlanView from "../../../../TeacherDashboard/pages/LessonPlan/Components/LessonPlanView";

const headCells: HeadCellsI[] = [
  { id: "unit_title", label: "Unit Title", align: "left" },
  { id: "sub_unit_title", label: "Sub Unit Title" },
  { id: "lesson_title", label: "Lesson Title" },
  // { id: "status", label: "Status" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  { id: "actions", label: "Actions" },
];

const ParentLessonPlanList = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { academicID } = props;

  const [tableData, setTableData] = useState<ParentLessonTypeI[]>([]);
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //*-------------------------------STATE MANAGEMENT------------------------------*//
  const [item, setItem] = useState<any>();
  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [index, setIndex] = useState<number>();

  //*--------------------------REDUX MANAGEMENT---------------------------------*//

  useEffect(() => {
    dispatch(GetParentLessonPlan(academicID));
  }, [academicID]);

  const LessonPlanSelector = useSelector(
    (state: RootStore) => state.parent_lessonPlan
  );

  useEffect(() => {
    const items = LessonPlanSelector.lesson_plan;
    items && setTableData(items);
  }, [LessonPlanSelector]);

  //*-------------------------------FUNCTIONS-------------------------*//

  const handleViewClicked = (data: any, index: number) => {
    setItem(data);
    setIndex(index + 1);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const getCustomTableRow = (item: ParentLessonTypeI, index: number) => {
    return (
      <StyledTableRow key={index} hover tabIndex={-1}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.unit_title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.sub_unit_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.start_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.end_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewClicked(item, index)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper
        elevation={0}
        className={cx(classes.root, classes.attachFormTableRoot)}
        style={{ padding: "8px 16px 16px 8px" }}
      >
        <TableContainer
          label="Lesson Plan List"
          loading={LessonPlanSelector.loading}
          items={tableData}
          headCells={headCells}
          rowsPerPage={8}
          getCustomTableRow={getCustomTableRow}
          search_name="name"
          placeholder="Search by unit..."
        />
      </Paper>
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        maxWidth="xl"
        fullWidth={true}
        title="View Lesson Plan"
        colored
      >
        <LessonPlanView item={item} indexN={index} />
      </Popups>
    </>
  );
};

export default ParentLessonPlanList;
