import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { TeacherClassSubjectsI } from '../../../../../rtk/features/timetable/timetable'
import { Paper } from "@material-ui/core";
import { tblStyle } from "../../../components/Styles/TeacherDashboardStyles";
import useTable from "../../../../../components/Reusable/useStudentTable";
import { HeadCellsI } from "../../../../../actions";
import { RootStore } from "../../../../../store"
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import { Tupple, assignmentCategoryOptions } from '../../../../../common/json.constant';
import { GetTeacherAssignment } from "../../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction"
import { ItemSubmitButton } from "../../../../../components/Reusable/Buttons/TableButton";
import ConfirmDialog from "../../../../../components/Reusable/Dialogs/ConfirmDialog"
import { UpdateTeacherAssignment } from '../../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction';

const headCells: HeadCellsI[] = [
    { id: "sn", label: "S.N" },
    { id: "title", label: "Title" },
    { id: "assign_category", label: "Assignment Category" },
    { id: "submission_type", label: "Submission Type" },
    { id: "assign", label: "Assign Date" },
    { id: "due", label: "Due Date" },
    { id: "action", label: "Action" },
];

interface Props {
    data: TeacherClassSubjectsI | undefined,
}

const DraftPage = ({ data }: Props) => {
    const dispatch = useDispatch();
    const classes = useTableStyles();
    const [tableData, setTableData] = useState<any>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [draft, setDraft] = useState<any>();
    const [hasPublished, setHasPublished] = useState<boolean>(false);
    const { StyledTableCell, StyledTableRow, TableContainer } =
        useTable(headCells);
    const { assignments, loading } = useSelector((state: RootStore) => state.teacher_assignment)

    const initialize = () => {
        if (data) {
            const section = data.classes?.section?.id ? data.classes?.section?.id : "";
            dispatch(GetTeacherAssignment(data.classes.grade, section, data.subject.id, null, null, false))
        }
    }

    useEffect(() => {
        initialize()
    }, [])

    useEffect(() => {
        if (assignments.length > 0) {
            setTableData(assignments)
        } else {
            setTableData([])
        }
    }, [assignments])

    useEffect(() => {
        hasPublished && initialize()
    }, [hasPublished])

    const onClose = () => {
        setModalOpen(false);
    };

    const handleDraft = (item: any) => {
        setModalOpen(true)
        setDraft(item)
    }

    function deleteProps(obj: any, prop: string[]) {
        for (const p of prop) {
            delete obj[p];
        }
    }

    const handlePublish = () => {
        const formData = {
            ...draft,
            publish: true,
            grade: draft.grade.id,
            section: draft.section.id,
            subject: draft.subject.id,
        }
        deleteProps(formData, ['academic_session', 'active', 'created_by', 'created_on', 'id', 'submitted_students', 'total_students', 'teacher', 'useful_links'])
        if (!draft) return;
        dispatch(UpdateTeacherAssignment(draft.id, formData))
        setModalOpen(false)
        setHasPublished(true)
    }



    const getCustomTableRow = (item: any, index: number) => {
        const assignCategory = assignmentCategoryOptions.filter((assign: Tupple) => item.assignment_type === assign.key)[0].value;
        return (
            <StyledTableRow key={item.id}>
                <StyledTableCell align="center" className={classes.cell}>
                    {index + 1}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.title}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {assignCategory}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.submission_type}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {new Date(item.assign_on).toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {new Date(item.deadline).toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    <ItemSubmitButton onClick={() => handleDraft(item)} />
                </StyledTableCell>
            </StyledTableRow>
        );
    };

    return (
        <>
            <Paper style={tblStyle}>
                <TableContainer
                    label={data?.subject?.name}
                    loading={loading}
                    items={tableData}
                    headCells={headCells}
                    getCustomTableRow={getCustomTableRow}
                    search_name="sub_name"
                />
            </Paper>
            <ConfirmDialog
                title="Create the assignment"
                description="Are you sure you want to create this draft as assignment?"
                cancelText="CANCEL"
                agreeText="CREATE"
                actionConfirmed={handlePublish}
                onClose={onClose}
                open={modalOpen}
            />
        </>
    )
}

export default DraftPage