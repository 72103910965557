import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { Clear, Done } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { assignmentCategoryOptions, Tupple } from "../../../../common/json.constant";
import ReactHtmlParser from "react-html-parser";
import { dateConverterAdToBs } from "../../../../components/utils/dateConverter";

interface AssingmentViewProps {
  singleAssignment: any;
}

const AssignmentView = (props: AssingmentViewProps) => {
  const { singleAssignment } = props;

  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Submit after deadline:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {singleAssignment?.can_submit_after_deadline ? "Yes" : "No"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Title:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {singleAssignment?.title}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Assign On:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {singleAssignment?.assign_on && dateConverterAdToBs(singleAssignment?.assign_on).concat(" ", new Date(singleAssignment?.assign_on).toLocaleTimeString())}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Deadline:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {singleAssignment?.deadline && dateConverterAdToBs(singleAssignment?.deadline).concat(" ", new Date(singleAssignment?.deadline).toLocaleTimeString())}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Submission Type
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {singleAssignment.submission_type}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Assignment Type
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {assignmentCategoryOptions.filter((item: Tupple) => item.key === singleAssignment.assignment_type)[0].value}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Full Marks
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {singleAssignment.full_marks || "N/A"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Pass Marks
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {singleAssignment.pass_marks || "N/A"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Status
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {singleAssignment.active ? "Running" : "Expired"}
          </Typography>
        </Grid>
      </Grid>

      {singleAssignment.attached_file && (
        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={2}>
            File
          </Grid>

          <Grid item xs={10}>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
              {singleAssignment?.attached_file.map((item: any) =>
                <a href={item.file} target="_blank" rel="noreferrer" key={item.id} style={{ marginRight: "1.25em" }}>
                  {item.file?.substring(item.file?.lastIndexOf("/") + 1, item.file?.lastIndexOf("?"))}
                </a>)
              }
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid item xs={2}>
          Description:
        </Grid>

        <Grid
          item
          xs={10}
          style={{
            padding: "10px",
            // border: "1px solid #B6B4B3",
            marginBottom: "15px",
            // borderRadius: "6px",
          }}
        >
          {ReactHtmlParser(singleAssignment.description) || (
            <em>No Description Available</em>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AssignmentView;
