export const GET_TEACHER_APE_LOADING = "GET_TEACHER_APE_LOADING";
export const GET_TEACHER_APE_ERROR = "GET_TEACHER_APE_ERROR";
export const GET_TEACHER_APE_SUCCESS = "GET_TEACHER_APE_SUCCESS";

export const ADD_TEACHER_APE_LOADING = "ADD_TEACHER_APE_LOADING";
export const ADD_TEACHER_APE_ERROR = "ADD_TEACHER_APE_ERROR";
export const ADD_TEACHER_APE_SUCCESS = "ADD_TEACHER_APE_SUCCESS";

//===============================<START>GET TEACHER EXAM MARKS<START>======================//

export interface GetTeacherExamMarksLoading {
  type: typeof GET_TEACHER_APE_LOADING;
}

export interface GetTeacherExamMarksError {
  type: typeof GET_TEACHER_APE_ERROR;
}

export interface GetTeacherExamMarksSuccess {
  type: typeof GET_TEACHER_APE_SUCCESS;
  payload: any;
}
//===============================<END>GET TEACHER EXAM MARKS<END>======================//

//===============================<START>GET TEACHER EXAM MARKS<START>======================//

export interface AddTeacherExamMarksLoading {
  type: typeof ADD_TEACHER_APE_LOADING;
}

export interface AddTeacherExamMarksError {
  type: typeof ADD_TEACHER_APE_ERROR;
}

export interface AddTeacherExamMarksSuccess {
  type: typeof ADD_TEACHER_APE_SUCCESS;
  payload: { message: string };
}
//===============================<END>GET TEACHER EXAM MARKS<END>======================//

export type TeacherAPEDispatchTypes =
  | GetTeacherExamMarksLoading
  | GetTeacherExamMarksError
  | GetTeacherExamMarksSuccess
  | AddTeacherExamMarksLoading
  | AddTeacherExamMarksError
  | AddTeacherExamMarksSuccess;
