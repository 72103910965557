import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { SchoolOutlined } from '@material-ui/icons';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { Link } from 'react-router-dom';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import styled from 'styled-components';
import CountUp from 'react-countup';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    minWidth: 180,
    overflow: 'hidden',
    '& .MuiCardContent-root': {
      paddingBottom: '4px',
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    color: 'rgba(0,0,0,0.5)',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  pos: {},
  btn: {
    '& span': {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
  },

  icon: {
    color: 'white',
    position: 'absolute',
    top: '-25px',
    right: '-25px',
    background: '#43a047',
    borderRadius: '47%',
    padding: '35px 35px 10px 15px',
    zIndex: 999,
    '& svg': {
      fontSize: '2rem',
    },
  },
});

const UserCard = (props: any) => {
  const classes = useStyles();
  const student = props.item.student ? props.item.student : 0;
  const absent = props.item.absent ? props.item.absent : 0;

  const Icon = props.item.icon;
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {props.item.title}
        </Typography>
        <Typography
          variant="h3"
          component="h2"
          style={{ fontWeight: 600, marginBottom: 4 }}
        >
          {/* Rs. 1,00,00,000. */}
          Rs.{' '}
          <CountUp
            start={0}
            end={10000000}
            delay={0}
            // decimals={2}
            // decimal="."
            separator=","
          >
            {({ countUpRef, start }) => <span ref={countUpRef} />}
          </CountUp>
        </Typography>

        <div
          className={classes.icon}
          style={{ backgroundColor: props.item.color }}
        >
          <Icon />
        </div>
      </CardContent>

      <CardActions style={{ padding: '0px 16px 12px 16px' }}>
        {props.desc}
      </CardActions>
    </Card>
  );
};

const StyledTextDescription = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
`;

const StyledTextLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.main};
  display: inline;
  text-decoration: none;
  font-weight: 500;
`;

export default function UsersCard(props: any) {
  const { data } = props;

  return (
    <Grid container spacing={2}>
      <Grid item md={4}>
        <UserCard
          item={{
            icon: MonetizationOnIcon,
            title: 'Total Fee',
            color: '#3f51b5',
            absent: data.student_absent_count,
            student: data.students_count,
            to: '/student',
          }}
          desc={
            <StyledTextDescription>
              <StyledTextLink to="/">See all </StyledTextLink>
              fee head and amount
            </StyledTextDescription>
          }
        />
      </Grid>

      <Grid item md={4}>
        <UserCard
          item={{
            icon: MonetizationOnIcon,
            title: 'Total Paid',
            color: '#43a047 ',
            absent: data.teacher_absent_count,
            student: data.teachers_count,
            to: '/staff',
          }}
          desc={
            <StyledTextDescription>
              <StyledTextLink to="/">See students </StyledTextLink>
              paid fee
            </StyledTextDescription>
          }
        />
      </Grid>

      <Grid item md={4}>
        <UserCard
          item={{
            icon: MonetizationOnIcon,
            title: 'Total Receivable',
            color: '#ff5722',
            absent: data.staff_absent_count,
            student: data.staffs_count,
            to: '/staff',
          }}
          desc={
            <StyledTextDescription>
              <StyledTextLink to="/">See students </StyledTextLink>
              due balance
            </StyledTextDescription>
          }
        />
      </Grid>
    </Grid>
  );
}
