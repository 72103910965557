import { Box } from "@material-ui/core";
import ParentDetailsForm from "../../../../components/Student/ParentForm/ParentDetailsForm";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { RootStore } from '../../../../store'
import { GetGuardianProfile } from '../../../../actions/ParentsDash/ParentProfile/ParentProfileAction'

const ParentProfileUpdate = () => {
    const dispatch = useDispatch();
    const [guardian, setGuardian] = useState<any>([]);
    const guardianID = useSelector((state: RootStore) => state.auth.user?.id);

    useEffect(() => {
        if (guardianID != null) {
            dispatch(GetGuardianProfile());
        }
    }, [guardianID]);

    const guardianState = useSelector((state: RootStore) => state.parent_profile.guardian_profile_by_id)

    useEffect(() => {
        setGuardian(guardianState)
    }, [guardianState])


    return (
        <Box>
            <PageHeader title="Profile Update" />
            <PageWrapper>
                <ParentDetailsForm data={guardian} />
            </PageWrapper>
        </Box>
    )
}

export default ParentProfileUpdate