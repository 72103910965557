export const countdownTimer = (date: string, time: string) => {
  const data = date.split("-");
  const newdata = `${data[1]}-${data[2]}-${data[0]}`;
  const difference = +new Date(`${newdata} ${time}`) - +new Date();
  let timeLeft = null;

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const countdownToday = (time: string) => {
  const check = new Date();
  const newDate = check.toLocaleDateString();
  const newdata = newDate?.replaceAll("/", "-");
  const difference = +new Date(`${newdata} ${time}`) - +new Date();
  let timeLeft = null;

  if (difference > 0) {
    timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};
