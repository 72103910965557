import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PeriodsI } from "./periodSlices";
import {
  TimeTableByDayI,
  TimeTableByClassI,
  TimeTableByClassPayloadI,
  TimeTableByDayPayloadI,
  TeacherTimeTableI,
  TeacherTimeTablePayloadI,
  TeacherClassSubjectsI,
} from "./timetable";
import {
  copyTimeTable,
  getTeacherDashboardTimeTable,
  getTeacherTimeTable,
  getTimeTableByClass,
  getTimeTableByDay,
  updateTimeTable,
} from "./timetableApi";

const initialState: {
  timetableByClass: TimeTableByClassI | null;
  timeTableByDay: TimeTableByDayI | null;
  timeTableByDayInit: TimeTableByDayI | null;
  teacherTimetable: TeacherTimeTableI[];
  teacherDashboardTimetable: TeacherTimeTableI[];
  teacherClassSubjects: TeacherClassSubjectsI[];
  loading: boolean;
  actionPerformed: boolean;
} = {
  timetableByClass: null,
  timeTableByDay: null,
  timeTableByDayInit: null,
  teacherTimetable: [],
  teacherDashboardTimetable: [],
  teacherClassSubjects: [],
  loading: false,
  actionPerformed: false,
};

const timetableSlice = createSlice({
  name: "timeTable",
  initialState,
  reducers: {
    updateTimetableByDay: (
      state,
      {
        payload,
      }: PayloadAction<{
        grade: string;
        section: string | null;
        day: number;
        period: PeriodsI;
        field: "subject" | "teacher";
        subject_value: { id: string; name: string } | null;
        teacher_value: {
          id: string;
          first_name: string;
          last_name: string;
        } | null;
      }>
    ) => {
      if (state.timeTableByDay) {
        let found = true;
        const { grade, day, section } = state.timeTableByDay;

        if (payload.grade !== grade.id) {
          found = false;
        }
        const tableSection = section ? section.id : null;
        if (tableSection) {
          if (payload.section !== tableSection) {
            found = false;
          }
        }
        if (payload.day !== day) {
          found = false;
        }

        if (found) {
          const currentPeriod = state.timeTableByDay.timetable.find(
            (item) => item.period.id === payload.period.id
          );

          if (currentPeriod) {
            if (payload.field === "subject") {
              if (payload.subject_value) {
                currentPeriod.subject = payload.subject_value;
              } else {
                currentPeriod.subject.id = "";
                currentPeriod.subject.name = "";
              }
            }
            if (payload.field === "teacher") {
              if (payload.teacher_value) {
                currentPeriod.teacher = payload.teacher_value;
              } else {
                currentPeriod.teacher.id = "";
                currentPeriod.teacher.first_name = "";
                currentPeriod.teacher.last_name = "";
              }
            }
          } else {
            state.timeTableByDay.timetable.push({
              teacher: {
                id:
                  payload.field === "teacher"
                    ? payload.teacher_value?.id || ""
                    : "",
                first_name:
                  payload.field === "teacher"
                    ? payload.teacher_value?.first_name || ""
                    : "",
                last_name:
                  payload.field === "teacher"
                    ? payload.teacher_value?.last_name || ""
                    : "",
              },
              subject: {
                id:
                  payload.field === "subject"
                    ? payload.subject_value?.id || ""
                    : "",
                name:
                  payload.field === "subject"
                    ? payload.subject_value?.name || ""
                    : "",
              },
              period: payload.period,
            });
          }
        }
      } else {
        state.timeTableByDay = {
          grade: {
            id: payload.grade,
          },
          section: {
            id: payload.section || "",
          },
          day: payload.day,
          timetable: [
            {
              teacher: {
                id:
                  payload.field === "teacher"
                    ? payload.teacher_value?.id || ""
                    : "",
                first_name:
                  payload.field === "teacher"
                    ? payload.teacher_value?.first_name || ""
                    : "",
                last_name:
                  payload.field === "teacher"
                    ? payload.teacher_value?.last_name || ""
                    : "",
              },
              subject: {
                id:
                  payload.field === "subject"
                    ? payload.subject_value?.id || ""
                    : "",
                name:
                  payload.field === "subject"
                    ? payload.subject_value?.name || ""
                    : "",
              },
              period: payload.period,
            },
          ],
        };
      }
    },
    resetState: (state) => {
      state.actionPerformed = false;
      state.timetableByClass = null;
      state.timeTableByDay = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimeTableByClass.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getTimeTableByClass.fulfilled,
      (state, { payload }: PayloadAction<TimeTableByClassPayloadI>) => {
        const timetable = payload.timetable;
        const result: TimeTableByDayPayloadI[] = [];
        timetable.forEach((item) => {
          const mIndex = result.findIndex(
            (element) => element.day === item.day
          );

          if (mIndex !== -1) {
            result[mIndex].periods.push({
              teacher: item.teacher,
              subject: item.subject,
              period: item.period_info,
            });
          } else {
            result.push({
              day: item.day,
              periods: [
                {
                  teacher: item.teacher,
                  subject: item.subject,
                  period: item.period_info,
                },
              ],
            });
          }
        });
        state.timetableByClass = {
          grade: payload.grade,
          section: payload.section,
          timeTable: result,
        };
        state.loading = false;
      }
    );
    builder.addCase(getTimeTableByClass.rejected, (state) => {
      state.loading = false;
      state.timetableByClass = null;
    });

    builder.addCase(getTimeTableByDay.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getTimeTableByDay.fulfilled,
      (state, { payload }: PayloadAction<TimeTableByDayI>) => {
        state.loading = false;
        state.timeTableByDay = payload;
        state.timeTableByDayInit = payload;
      }
    );
    builder.addCase(getTimeTableByDay.rejected, (state) => {
      state.loading = false;
      state.timeTableByDay = null;
    });

    builder.addCase(updateTimeTable.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(updateTimeTable.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(updateTimeTable.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(copyTimeTable.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(copyTimeTable.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(copyTimeTable.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTeacherTimeTable.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getTeacherTimeTable.fulfilled,
      (state, { payload }: PayloadAction<TeacherTimeTablePayloadI[]>) => {
        const timetable: TeacherTimeTableI[] = [];
        payload.forEach((item) => {
          const mIndex = timetable.findIndex(
            (elem) =>
              elem.classes.grade === item.classes.grade &&
              elem.classes.section?.id === item.classes.section?.id
          );
          if (mIndex !== -1) {
            timetable[mIndex].timetable.push({
              id: item.id,
              day: item.day,
              subject: item.subject,
              period: item.period,
            });
          } else {
            timetable.push({
              classes: item.classes,
              timetable: [
                {
                  id: item.id,
                  day: item.day,
                  subject: item.subject,
                  period: item.period,
                },
              ],
            });
          }
        });
        state.teacherTimetable = timetable;
        state.loading = false;
      }
    );
    builder.addCase(getTeacherTimeTable.rejected, (state) => {
      state.loading = false;
      state.teacherTimetable = [];
    });

    builder.addCase(getTeacherDashboardTimeTable.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      getTeacherDashboardTimeTable.fulfilled,
      (state, { payload }: PayloadAction<TeacherTimeTablePayloadI[]>) => {
        const timetable: TeacherTimeTableI[] = [];
        payload.forEach((item) => {
          const mIndex = timetable.findIndex(
            (elem) =>
              elem.classes.grade === item.classes.grade &&
              elem.classes.section?.id === item.classes.section?.id
          );
          if (mIndex !== -1) {
            timetable[mIndex].timetable.push({
              id: item.id,
              day: item.day,
              subject: item.subject,
              period: item.period,
            });
          } else {
            timetable.push({
              classes: item.classes,
              timetable: [
                {
                  id: item.id,
                  day: item.day,
                  subject: item.subject,
                  period: item.period,
                },
              ],
            });
          }
        });
        state.teacherDashboardTimetable = timetable;
        state.teacherClassSubjects = payload;
        state.loading = false;
      }
    );
    builder.addCase(getTeacherDashboardTimeTable.rejected, (state) => {
      state.loading = false;
      state.teacherDashboardTimetable = [];
      state.teacherClassSubjects = [];
    });
  },
});

export const {
  updateTimetableByDay: updateTimetableByDayAction,
  resetState: timeTableResetState,
} = timetableSlice.actions;

export default timetableSlice.reducer;
