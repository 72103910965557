// -----------------<START> import modules starts----------------------------------------------//
import React, { useEffect, useState } from "react";
import { makeStyles, Tabs, Tab, Typography, Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { GetStudentProfile } from "../../../../../actions/Student/StudentProfile/StudentProfileAction";
import { RootStore } from "../../../../../store";
import Info from "../../../../../components/Student/StudentProfile/File/Info";
import ParentsInfo from "../../../../../components/Student/StudentProfile/File/ParentsInfo";
import OtherInfo from "../../../../../components/Student/StudentProfile/File/OtherInfo";
import LeaveTable from "../../../../../components/Student/StudentProfile/File/LeaveTab/LeaveTable";
import Document from "../../../../../components/Student/StudentProfile/File/Document";
import FeeType from "../../../../../components/Student/StudentProfile/File/FeeType";
import { GetViewChildData } from "../../../../../actions/ParentsDash/ViewChild/ViewChildAction";
import {
  ViewChildDetailProps,
  ViewDocument,
} from "../../../../../actions/ParentsDash/ViewChild/ViewChildActionTypes";
import ChildOtherInfo from "./ChildOtherInfo";
import ChildDocument from "./ChildDocument";
import FeeTab from './FeeTab';

//------------------------<END> import module ends---------------------------------------------------------------//

//-----------------<START> style starts--------------------------------------------------------//
const useStyles = makeStyles({
  root: {
    marginTop: "10px",
    paddingRight: "10px",
    "& .MuiTab-labelIcon": {
      minHeight: "40px",
      paddingTop: "6px!important",
    },
    "& .MuiTab-root": {
      fontSize: "14px",
      textTransform: "none",
      minWidth: "110px",
      maxWidth: "200px",
      height: "30px",
      letterSpacing: "0.01071em",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "1px solid #ddd",
    },
    "& .MuiBox-root": {
      padding: "10px 0px 0px 0px",
    },
  },
});

//----------------------<END> styled ends ---------------------------------------------------//
// ----------------------<STARTS> interface starts -------------------------------------------------------//
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
//------------------------<ENDS> interface ends -------------------------------------------------------//

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// -----------------<START> Time table tabs component starts---------------------//
export default function ChildInfoTabs(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const { studentID } = useParams<{ studentID: string }>();

  const [childDocument, setChildDocument] = useState<ViewDocument[]>([]);

  const [childData, setChildData] = React.useState<ViewChildDetailProps | null>(
    null
  );

  const viewChildDataSelector = useSelector(
    (state: RootStore) => state.view_child.children
  );

  useEffect(() => {
    props.childID && dispatch(GetViewChildData(props.childID));
  }, [props.childID]);

  useEffect(() => {
    viewChildDataSelector && setChildData(viewChildDataSelector);
    const childDoc = viewChildDataSelector?.student_document;
    childDoc && setChildDocument(childDoc);
  }, [viewChildDataSelector]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      // variant="fullWidth"
      >
        <Tab label="Personal Info" />
        <Tab label="Other Info" />
        <Tab label="Leave" />
        <Tab label="Documents" />
        <Tab label="Fee Invoice" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Info studentData={childData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChildOtherInfo studentData={childData} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LeaveTable studentID={studentID} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ChildDocument childDocument={childDocument} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <FeeTab data={childData} />
      </TabPanel>
    </div>
  );
}
//---------------<END> Routes tabs component ends ----------------------------//
