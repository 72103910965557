import {
  GET_TEACHER_TIMETABLE_LOADING,
  GET_TEACHER_TIMETABLE_ERROR,
  GET_TEACHER_TIMETABLE_SUCCESS,
  TimeTableDispatchTypes,
  TimeTableTypeI,
} from "../../actions/TeacherDash/TimeTable/TeacherTimeTableActionTypes";

interface InitialStateI {
  loading: boolean;
  timeTables: TimeTableTypeI[];
  dailyRoutine: TimeTableTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  timeTables: [],
  dailyRoutine: [],
};

const TeacherTimeTableReducer = (
  state: InitialStateI = initialState,
  action: TimeTableDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_TIMETABLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_TIMETABLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_TIMETABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        timeTables: action.payload,
      };

    default:
      return state;
  }
};

export default TeacherTimeTableReducer;
