import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_PARENT_LEAVE_LOADING,
  GET_PARENT_LEAVE_ERROR,
  GET_PARENT_LEAVE_SUCCESS,
  LeaveDispatchTypes,
  ADD_PARENT_LEAVE_LOADING,
  ADD_PARENT_LEAVE_SUCCESS,
  ADD_PARENT_LEAVE_ERROR,
  DELETE_PARENT_LEAVE_SUCCESS,
  DELETE_PARENT_LEAVE_ERROR,
  DELETE_PARENT_LEAVE_LOADING,
  UPDATE_PARENT_LEAVE_LOADING,
  UPDATE_PARENT_LEAVE_SUCCESS,
  UPDATE_PARENT_LEAVE_ERROR,
  ParentLeaveII,
} from './ParentLeaveActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { PARENT_API_URL } from '../../../config/SERVER_API_URLS';

export const GetLeaves =
  (academic_id: any) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_PARENT_LEAVE_LOADING,
      });

      const res = await axios.get(
        `${PARENT_API_URL}/parent_leave/?student_academic_id=${academic_id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_PARENT_LEAVE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PARENT_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leaves Loading Failed',
        },
      });
    }
  };

export const GetSearchedLeaves =
  (query: any) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_PARENT_LEAVE_LOADING,
      });

      const url = `${PARENT_API_URL}/parent_leave/?leave_type=${query.leaveType}&from_date=${query.date_first}&to_date=${query.date_second}`;

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_PARENT_LEAVE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PARENT_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leaves Loading Failed',
        },
      });
    }
  };

export const AddLeave =
  (data: any) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_PARENT_LEAVE_LOADING,
      });

      const res = await axios.post(
        `${PARENT_API_URL}/parent_leave/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_PARENT_LEAVE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Added Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_PARENT_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Add Failed',
        },
      });
    }
  };

export const UpdateLeave =
  (id: any, data: any) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_PARENT_LEAVE_LOADING,
      });

      const res = await axios.put(
        `${PARENT_API_URL}/parent_leave/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_PARENT_LEAVE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Updated Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PARENT_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Update Failed',
        },
      });
    }
  };

export const DeleteLeave =
  (id: any) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_PARENT_LEAVE_LOADING,
      });

      const res = await axios.delete(
        `${PARENT_API_URL}/parent_leave/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_PARENT_LEAVE_SUCCESS,
        payload: { id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Deleted Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_PARENT_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Delete Failed',
        },
      });
    }
  };
