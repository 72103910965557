import classes from '*.module.css';
import { Grid, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { HRsidebar } from '../../..';
import { GetStaffProfile } from '../../../../actions/HumanResource/StaffProfile/StaffProfileAction';
import { RootStore } from '../../../../store';
import CardLayout from '../../../Reusable/Layouts/CardLayout';
import SubModuleLayout from '../../../Reusable/Layouts/SubModuleLayout';
import { useCardStyles } from '../../../Styles/CardStyles';
import { dateConverterAdToBs } from '../../../utils/dateConverter';
import getFullName from '../../../utils/getFullName';
import StaffTabs from './StaffTabs';

function StaffProfile() {
  const classes = useCardStyles();
  const nav = <HRsidebar Staff />;
  const dispatch = useDispatch();
  const { staffID } = useParams<{ staffID: string }>();
  const [staff, setStaff] = useState<any>([]);

  useEffect(() => {
    staffID != undefined && dispatch(GetStaffProfile(staffID));
  }, []);

  const staffList = useSelector(
    (state: RootStore) => state.staffProfile.staff_profile
  );

  useEffect(() => {
    if (staffList != null) {
      setStaff(staffList);
    }
  }, [staffList]);

  const headData = [
    { id: 2, headCells: 'Gender:', cellData: staff?.gender_name },
    { id: 3, headCells: 'Designation:', cellData: staff?.designation_name },
    { id: 4, headCells: 'Address:', cellData: staff?.address },
    { id: 5, headCells: 'Phone:', cellData: staff?.phone },
    {
      id: 6,
      headCells: 'Date of Joining:',
      cellData:
        staff?.date_of_joining && dateConverterAdToBs(staff?.date_of_joining),
    },
    { id: 7, headCells: 'Pan No.:', cellData: staff?.pan_no },
  ];

  return (
    <>
      <SubModuleLayout studentMenuActive sideNav={nav}>
        <Grid container>
          <Grid item xs={3}>
            <CardLayout
              fullName={getFullName(
                staff?.user?.first_name,
                staff?.user?.last_name
              )}
              headData={headData}
              imgUrl={staff?.photo}
            />
          </Grid>
          <Grid item xs={9}>
            <StaffTabs />
          </Grid>
        </Grid>
      </SubModuleLayout>
    </>
  );
}

export default StaffProfile;
