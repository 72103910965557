import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_EVENT_LOADING,
  GET_TEACHER_EVENT_ERROR,
  GET_TEACHER_EVENT_SUCCESS,
  TeacherEventDispatchTypes,
} from "./TeacherEventActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetTeacherEvent = () => async (
  dispatch: Dispatch<TeacherEventDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_TEACHER_EVENT_LOADING,
    });

    const res = await axios.get(`${TEACHER_API_URL}/event/`, HeaderConfig());

    dispatch({
      type: GET_TEACHER_EVENT_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEACHER_EVENT_ERROR,
    });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     // snackbarMessage: error?.response.data.error.detail,
    //   },
    // });
  }
};
