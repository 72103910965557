// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  Paper,
  TableBody,
  TextField,
  Grid,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { Pagination } from "@material-ui/lab";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import {
  GetClasses,
  DeleteClass,
  GetClassStudentCount,
} from "../../../actions/Academics/Class/ClassAction";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { DeleteForeverOutlined } from "@material-ui/icons";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: ClassTypeI) => void;
  edit: { value: boolean };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "class", label: "Class" },
  { id: "section", label: "Section" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const ClassTable = (props: PropsI) => {
  const { onEditData, edit } = props;
  const classes = useTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");

  const [tableData, setTableData] = useState<ClassTypeI[]>([]);
  const [editMode, setEditMode] = useState(false);
  // section reducer
  const dispatch = useDispatch();
  const classState = useSelector((state: RootStore) => state.class);

  //================================<START>LIFECYCLE HOOKS<START>=================================//

  useEffect(() => {
    edit && setEditMode(edit.value);
  }, [edit]);

  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  useEffect(() => {
    setLoading(classState.loading);
  }, [classState]);

  useEffect(() => {
    setLoading(classState.loading);

    setTableData(classState.classes);
  }, [classState]);

  //================================<END>LIFECYCLE HOOKS<END>=================================//

  // Retrieving re-usable components from useTable
  const {
    StyledTableCell,
    StyledTableRow,

    TableContainer,
  } = useTable(headCells);

  // Function for handling action icons click event

  const handleEditClicked = (data: ClassTypeI) => {
    onEditData(data);
    setEditMode(true);
    dispatch(GetClassStudentCount(data.id));
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  // const onDeleteConfirmed = (confirmed: boolean) => {
  //   confirmed && itemId != null && dispatch(DeleteClass(itemId));
  //   setDeleteModalOpen(false);
  // };

  const getCustomTableRow = (item: ClassTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.grade_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.section.length
            ? item.section.map((element: SectionTypeI, index: number) =>
                index === item.section.length - 1
                  ? element.name
                  : `${element.name}, `
              )
            : "-"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          {/* <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Class List</Typography>
          </Grid>
        </Grid>
        <TableContainer
          loading={loading}
          headCells={headCells}
          items={tableData}
          getCustomTableRow={getCustomTableRow}
          search_name="grade_name"
          search_param="grade_name"
        />
      </Paper>
      {/* <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      /> */}
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default ClassTable;
