import { Box, Grid, makeStyles } from "@material-ui/core";
import StudentEventsNotification from "./StudentEventsNotification";
import StudentEventsTable from "./StudentEventsTable";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";

export const useStudentEventStyles = makeStyles(() => ({
  flexible: {
    flex: 1,
  },
  static: {
    flexBasis: "300px",
  },
}));

const StudentEventPage = () => {
  const classes = useStudentEventStyles();

  return (
    <Box>
      <PageHeader title="Student Events" />

      <PageWrapper>
        <Grid container>
          <Grid item className={classes.flexible}>
            <StudentEventsTable />
          </Grid>

          <Grid item className={classes.static}>
            <StudentEventsNotification />
          </Grid>
        </Grid>
      </PageWrapper>
    </Box>
  );
};

export default StudentEventPage;
