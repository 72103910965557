import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { GetStudentProfile2 } from "../../../../actions/Student/StudentProfile/StudentProfileAction";
import CardfullWidthLayout from "../../../../components/Reusable/Layouts/CardfullWidthLayout";
import StudentTabs from "./StudentTabs";
import getFullName from "../../../../components/utils/getFullName";
import { RootStore } from "../../../../store";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";

const StudentProfilePage = () => {
  const dispatch = useDispatch();
  const [student, setStudent] = useState<any>([]);
  const studentID = useSelector((state: RootStore) => state.auth.user?.id);

  useEffect(() => {
    if (studentID != null) {
      dispatch(GetStudentProfile2());
    }
  }, [studentID]);

  const studentState = useSelector(
    (state: RootStore) => state.student_dash_profile.student_profile
  );

  console.log(student);

  useEffect(() => {
    if (studentState != null) {
      setStudent(studentState);
    }
  }, [studentState]);

  const headData = [
    { id: 2, headCells: "Adm No:", cellData: student?.admission_number },
    {
      id: 3,
      headCells: "Grade:",
      cellData: student?.academic_info && student?.academic_info[0].grade.name,
    },
    {
      id: 4,
      headCells: "Section",
      cellData: student?.academic_info && student?.academic_info[0].section && student?.academic_info && student?.academic_info[0].section.name
    },
    {
      id: 5,
      headCells: "Roll No:",
      cellData: student?.academic_info && student?.academic_info[0].roll_number,
    },
    { id: 6, headCells: "Address:", cellData: student?.address },
    {
      id: 7,
      headCells: "Gender:",
      cellData: student?.gender,
    },
    { id: 8, headCells: "Phone No:", cellData: student?.phone_number },
  ];
  return (
    <Box>
      <PageHeader title="My Profile" />
      <PageWrapper>
        <CardfullWidthLayout
          fullName={getFullName(
            student?.student_user?.first_name,
            student?.student_user?.last_name
          )}
          headData={headData}
          profileImg={student?.student_photo}
        >
          <StudentTabs studentData={student} />
        </CardfullWidthLayout>
      </PageWrapper>
    </Box>
  );
};
export default StudentProfilePage;
