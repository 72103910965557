import { Box } from "@material-ui/core";
import React, { useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import { SelectAttendance } from "./SelectAttendance";
import { StudentAttendanceTake } from "./StudentAttendanceTake";

interface SearchParamI {
  grade: string;
  date: string;
  section: string;
}

const TeacherAttendancePage = () => {
  const [searchParam, setSearchParam] = useState<SearchParamI | null>(null);
  const handleSearch = (data: SearchParamI | null) => {
    setSearchParam(data);
  };

  return (
    <>
      <Box>
        <PageHeader
          title="Student Attendance"
          module={{ name: "Academics", to: "/student_attendance" }}
        />
        <PageWrapper>
          <SelectAttendance onSearch={handleSearch} />
          <StudentAttendanceTake searchParam={searchParam} />
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherAttendancePage;
