import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_ASSIGNMENT_LOADING,
  GET_TEACHER_ASSIGNMENT_ERROR,
  GET_TEACHER_ASSIGNMENT_SUCCESS,
  GET_SUBMITTED_ASSIGNMENT_LOADING,
  GET_SUBMITTED_ASSIGNMENT_ERROR,
  GET_SUBMITTED_ASSIGNMENT_SUCCESS,
  GET_UNSUBMITTED_ASSIGNMENT_LOADING,
  GET_UNSUBMITTED_ASSIGNMENT_ERROR,
  GET_UNSUBMITTED_ASSIGNMENT_SUCCESS,
  ADD_TEACHER_ASSIGNMENT_LOADING,
  ADD_TEACHER_ASSIGNMENT_ERROR,
  ADD_TEACHER_ASSIGNMENT_SUCCESS,
  UPDATE_TEACHER_ASSIGNMENT_LOADING,
  UPDATE_TEACHER_ASSIGNMENT_ERROR,
  UPDATE_TEACHER_ASSIGNMENT_SUCCESS,
  DELETE_TEACHER_ASSIGNMENT_LOADING,
  DELETE_TEACHER_ASSIGNMENT_ERROR,
  DELETE_TEACHER_ASSIGNMENT_SUCCESS,
  GET_TEACHER_ASSIGNMENT_BY_ID_LOADING,
  GET_TEACHER_ASSIGNMENT_BY_ID_ERROR,
  GET_TEACHER_ASSIGNMENT_BY_ID_SUCCESS,
  GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING,
  GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR,
  GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS,
  ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING,
  ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR,
  ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS,
  TeacherAssignmentDispatchTypes,
  ASSIGN_MARKS_ASSIGNMENT_LOADING,
  ASSIGN_MARKS_ASSIGNMENT_SUCCESS,
  ASSIGN_MARKS_ASSIGNMENT_ERROR,
  GET_ASSIGNMENT_FILES_ERROR,
  GET_ASSIGNMENT_FILES_LOADING,
  GET_ASSIGNMENT_FILES_SUCCESS,
  ADD_ASSIGNMENT_FILES_ERROR,
  ADD_ASSIGNMENT_FILES_LOADING,
  ADD_ASSIGNMENT_FILES_SUCCESS,
  DELETE_ASSIGNMENT_FILES_ERROR,
  DELETE_ASSIGNMENT_FILES_LOADING,
  DELETE_ASSIGNMENT_FILES_SUCCESS,
  GET_ASSIGNMENT_SUBJECT_LOADING,
  GET_ASSIGNMENT_SUBJECT_ERROR,
  GET_ASSIGNMENT_SUBJECT_SUCCESS,
} from "./TeacherAssignmentActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetTeacherAssignment =
  (grade: string, section: string, subject: string, submission: string | null, assignment: string | null, publish: boolean) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_TEACHER_ASSIGNMENT_LOADING,
        });

        let url = `${TEACHER_API_URL}/teacher-assignments/?grade=${grade}&section=${section || ""}&subject=${subject}&publish=${publish}`;
        if (submission && assignment) {
          url = `${TEACHER_API_URL}/teacher-assignments/?grade=${grade}&section=${section || ""}&subject=${subject}&submission_type=${submission}&assignment_type=${assignment}&publish=${publish}`
        } else if (submission && !assignment) {
          url = `${TEACHER_API_URL}/teacher-assignments/?grade=${grade}&section=${section || ""}&subject=${subject}&submission_type=${submission}&publish=${publish}`
        } else if (!submission && assignment) {
          url = `${TEACHER_API_URL}/teacher-assignments/?grade=${grade}&section=${section || ""}&subject=${subject}&assignment_type=${assignment}&publish=${publish}`
        }
        const res = await axios.get(url, HeaderConfig());

        dispatch({
          type: GET_TEACHER_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_TEACHER_ASSIGNMENT_ERROR,
        });
      }
    };

export const GetTeacherAssignmentInitials =
  () =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_TEACHER_ASSIGNMENT_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/teacher-assignments/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_TEACHER_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_TEACHER_ASSIGNMENT_ERROR,
        });
      }
    };

export const GetTeacherAssignmentById =
  (id: string) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_TEACHER_ASSIGNMENT_BY_ID_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/teacher-assignments/${id}/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_TEACHER_ASSIGNMENT_BY_ID_SUCCESS,
          payload: res.data,
        });
      } catch (error: any) {
        dispatch({
          type: GET_TEACHER_ASSIGNMENT_BY_ID_ERROR,
          payload: error?.response?.data,
        });
      }
    };

export const AddTeacherAssignment =
  (data: any) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: ADD_TEACHER_ASSIGNMENT_LOADING,
        });

        const res = await axios.post(
          `${TEACHER_API_URL}/teacher-assignments/`,
          data,
          HeaderConfig()
        );

        dispatch({
          type: ADD_TEACHER_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Assignment Added Successfully.",
          },
        });
      } catch (error: any) {
        dispatch({
          type: ADD_TEACHER_ASSIGNMENT_ERROR,
          payload: error.response.data,
        });
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Assignment Added Failed.",
          },
        });
      }
    };

export const UpdateTeacherAssignment =
  (id: any, data: any) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: UPDATE_TEACHER_ASSIGNMENT_LOADING,
        });

        const res = await axios.put(
          `${TEACHER_API_URL}/teacher-assignments/${id}/`,
          data,
          HeaderConfig()
        );

        dispatch({
          type: UPDATE_TEACHER_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Teacher Assignment Updated Successfully",
          },
        });
      } catch (error: any) {
        dispatch({
          type: UPDATE_TEACHER_ASSIGNMENT_ERROR,
          payload: error.response.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Teacher Assignment Update Failed",
          },
        });
      }
    };

export const DeleteTeacherAssignment =
  (id: string) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: DELETE_TEACHER_ASSIGNMENT_LOADING,
        });

        const res = await axios.delete(
          `${TEACHER_API_URL}/teacher-assignments/${id}`,
          HeaderConfig()
        );

        dispatch({
          type: DELETE_TEACHER_ASSIGNMENT_SUCCESS,
          payload: { id: id },
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Teacher Assignment Deleted Successfully",
          },
        });
      } catch (error: any) {
        dispatch({
          type: DELETE_TEACHER_ASSIGNMENT_ERROR,
          payload: error.response.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Teacher Assignment Delete Failed",
          },
        });
      }
    };

export const GetTeacherAssignmentAddMarks =
  (query: any) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING,
        });

        const url = `${TEACHER_API_URL}/assignment_student_list/?grade=${query.class}&assignment=${query.assignment}`;
        const res = await axios.get(url, HeaderConfig());

        dispatch({
          type: GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS,
          payload: res.data,
        });
      } catch (error: any) {
        dispatch({
          type: GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR,
          payload: error.response.data,
        });
      }
    };

export const BulkUpdateAssignmentMarks =
  (grade: number, assignment: number, data: any[]) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING,
        });

        const url = `${TEACHER_API_URL}/assignment_student_list/?grade=${grade}&assignment=${assignment}`;
        const res = await axios.post(url, data, HeaderConfig());

        dispatch({
          type: ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS,
          payload: res.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Assignment Marks Updated Successfully",
          },
        });
      } catch (error) {
        dispatch({
          type: ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR,
        });
      }
    };

export const GetSubmittedAssignments =
  (id: string) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_SUBMITTED_ASSIGNMENT_LOADING,
        });
        const url = `${TEACHER_API_URL}/teacher-submitted-assignment/?assignment=${id}`;
        const res = await axios.get(url, HeaderConfig());

        dispatch({
          type: GET_SUBMITTED_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
      } catch (error: any) {
        dispatch({
          type: GET_SUBMITTED_ASSIGNMENT_ERROR,
          payload: error.response.data,
        });
      }
    };

export const GetUnSubmittedAssignments =
  (id: string) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_UNSUBMITTED_ASSIGNMENT_LOADING,
        });
        const url = `${TEACHER_API_URL}/teacher-submitted-assignment/submission_remaining?assignment=${id}&submitted=false`;
        const res = await axios.get(url, HeaderConfig());

        dispatch({
          type: GET_UNSUBMITTED_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
      } catch (error: any) {
        dispatch({
          type: GET_UNSUBMITTED_ASSIGNMENT_ERROR,
          payload: error.response.data,
        });
      }
    };

export const AssignAssignmentMarks =
  (id: string, data: any) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: ASSIGN_MARKS_ASSIGNMENT_LOADING,
        })
        const url = `${TEACHER_API_URL}/teacher-submitted-assignment/${id}/`;
        const res = await axios.put(url, data, HeaderConfig())

        dispatch({
          type: ASSIGN_MARKS_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Marks assigned successfully",
          },
        });
      } catch (error: any) {
        dispatch({
          type: ASSIGN_MARKS_ASSIGNMENT_ERROR,
          payload: error.response.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to assign marks",
          },
        });
      }
    }

export const AssignPhysicalAssignmentMarks =
  (data: any) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: ASSIGN_MARKS_ASSIGNMENT_LOADING,
        })
        const url = `${TEACHER_API_URL}/teacher-submitted-assignment/`;
        const res = await axios.post(url, data, HeaderConfig())

        dispatch({
          type: ASSIGN_MARKS_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Marks assigned successfully",
          },
        });
      } catch (error: any) {
        dispatch({
          type: ASSIGN_MARKS_ASSIGNMENT_ERROR,
          payload: error.response.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to assign marks",
          },
        });
      }
    }

export const GetAssignmentFiles =
  (id: string) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_ASSIGNMENT_FILES_LOADING,
        });

        const res = await axios.get(`${TEACHER_API_URL}/assignment-files/${id}`, HeaderConfig());

        dispatch({
          type: GET_ASSIGNMENT_FILES_SUCCESS,
          payload: res.data,
        });
      } catch (error: any) {
        dispatch({
          type: GET_ASSIGNMENT_FILES_ERROR,
          payload: error.response.data,
        });
      }
    };

export const AddAssignmentFiles =
  (data: any) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: ADD_ASSIGNMENT_FILES_LOADING,
        });

        const res = await axios.post(
          `${TEACHER_API_URL}/assignment-files`,
          data,
          HeaderConfig()
        );

        dispatch({
          type: ADD_ASSIGNMENT_FILES_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Assignment Files Added Successfully.",
          },
        });
      } catch (error: any) {
        dispatch({
          type: ADD_ASSIGNMENT_FILES_ERROR,
          payload: error.response.data,
        });
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to Add Assignment Files.",
          },
        });
      }
    };

export const DeleteAssignmentFiles =
  (id: string) =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: DELETE_ASSIGNMENT_FILES_LOADING,
        });

        const res = await axios.delete(
          `${TEACHER_API_URL}/assignment-files/${id}`,
          HeaderConfig()
        );

        dispatch({
          type: DELETE_ASSIGNMENT_FILES_SUCCESS,
          payload: { id: id },
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Assignment File Deleted Successfully",
          },
        });
      } catch (error: any) {
        dispatch({
          type: DELETE_ASSIGNMENT_FILES_ERROR,
          payload: error.response.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to Delete Assignment File",
          },
        });
      }
    };


export const GetAssignmentSubjects =
  () =>
    async (dispatch: Dispatch<TeacherAssignmentDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_ASSIGNMENT_SUBJECT_LOADING,
        });

        const res = await axios.get(`${TEACHER_API_URL}/timetable-assg`, HeaderConfig());

        dispatch({
          type: GET_ASSIGNMENT_SUBJECT_SUCCESS,
          payload: res.data,
        });
      } catch (error: any) {
        dispatch({
          type: GET_ASSIGNMENT_SUBJECT_ERROR,
          payload: error.response.data,
        });
      }
    };