import moment from "moment";
import React, { useEffect, useState } from "react";

const TimerCard = () => {
  const [currentDate, setCurrentDate] = useState<string>(
    moment().format("MMMM D YYYY | h:mm:ss A")
  );

  useEffect(() => {
    const dateInterval = setInterval(() => {
      const date = moment().format("MMMM D YYYY | h:mm:ss A");
      setCurrentDate(date);
    }, 1000);
    return () => clearInterval(dateInterval);
  }, []);

  return <div>{currentDate}</div>;
};

export default TimerCard;
