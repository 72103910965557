import {
  GET_TEACHER_ANNOUNCEMENTS_LOADING,
  GET_TEACHER_ANNOUNCEMENTS_ERROR,
  GET_TEACHER_ANNOUNCEMENTS_SUCCESS,
  TeacherAnnouncementsDispatchTypes,
  TeacherAnnouncementTypeI,
} from "../../../actions/TeacherDash/TeacherAnnouncements/TeacherAnnouncementActionTypes";

interface InitialStateI {
  loading: boolean;
  teacher_announcements: TeacherAnnouncementTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  teacher_announcements: [],
};

const TeacherAnnouncementReducer = (
  state: InitialStateI = initialState,
  action: TeacherAnnouncementsDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_ANNOUNCEMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_ANNOUNCEMENTS_SUCCESS:
      return {
        loading: false,
        teacher_announcements: action.payload,
      };

    default:
      return state;
  }
};

export default TeacherAnnouncementReducer;
