import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetParentEvents } from "../../../../../actions/ParentsDash/ParentEvent/ParentEventAction";
import { ParentEventTypeI } from "../../../../../actions/ParentsDash/ParentEvent/ParentEventActionTypes";
import { RootStore } from "../../../../../store";
import { ItemViewButton } from "../../../../../components/Reusable/Buttons/TableButton";
import Popups from "../../../../../components/Reusable/Dialogs/Popups";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import { ViewEventModal } from "../../../../TeacherDashboard/pages/TeacherEvents/ModalView/ViewEventModal";
import { dateConverterAdToBs } from "../../../../../components/utils/dateConverter";
import useTable from "../../../../../components/Reusable/useStudentTable";
import { tblStyle } from "../../../ParentDashboardStyles";
import { HeadCellsI } from "../../../../../actions";
import { fromToConverter } from "../../../../../components/utils/dateTimeFormat";

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: 's.n', label: 'S.N.' },
  { id: "title", label: "Title", align: "left", filter: true },
  { id: "event_type", label: "Type" },
  { id: "location", label: "Location" },
  { id: "date", label: "Date" },
  { id: "time", label: "Time" },
  { id: "is_holiday", label: "Holiday Status" },
  { id: "action", label: "Action" },
];

const ParentEventTable = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<ParentEventTypeI[]>([]);

  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [item, setItem] = useState<ParentEventTypeI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  useEffect(() => {
    dispatch(GetParentEvents());
  }, []);

  const parentEventsSelector = useSelector(
    (state: RootStore) => state.parent_event
  );

  useEffect(() => {
    const data = parentEventsSelector.parents_event;
    setTableData(data);
    setLoading(parentEventsSelector.loading);
  }, [parentEventsSelector]);

  const handleViewModal = (item: any) => {
    setItem(item);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const getCustomTableRow = (item: ParentEventTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.event_type_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.location}
        </StyledTableCell>
        <StyledTableCell
          align="center"
          style={{ width: "15%" }}
          className={classes.cell}
        >
          {fromToConverter(item.from_date, item.to_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.time}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.is_holiday ? "Yes" : "No"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton title="View" onClick={() => handleViewModal(item)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root} style={tblStyle}>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="title"
          label="Events"
          rowsPerPage={7}
        />
      </Paper>

      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        fullWidth={true}
        maxWidth="sm"
        title="Events Detail"
        colored
      >
        <ViewEventModal item={item} />
      </Popups>
    </>
  );
};

export default ParentEventTable;
