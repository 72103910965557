import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { AlertTitle, Alert } from '@material-ui/lab';
import clsx from 'clsx';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingTop: '0',
      paddingBottom: '8px',
    },
    title: {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    titleText: {
      fontSize: '16px',
      fontWeight: 600,
      display: 'block',
      marginBottom: '-1px',
      color: 'rgba(0,0,0,0.85)',
    },
    titleDate: {
      fontSize: '13px',
      fontWeight: 400,
      color: '#1d4698',
    },
    itemTitle: {
      fontWeight: 500,
      marginBottom: 5,
      display: 'inline-block',
    },
    alertTitle: {
      fontSize: 14,
      marginBottom: 0,
      fontWeight: 600,
    },
    btnGrp: {
      borderColor: 'transparent',
      '& > button': {
        textTransform: 'capitalize',
        borderColor: 'transparent',
        borderRadius: '4px!important',
        borderRight: 'none!important',
      },
    },
    alertRoot: {
      width: '100%',
      color: 'rgba(0,0,0,.8)',
      borderRadius: 8,
      borderLeft: '8px solid #3fc581',
      background: 'rgb(72 197 129 / 10%)',
      '& > div': {
        width: '100%',
        padding: '4px 0',
      },
    },
    alertTime: {
      fontWeight: 600,
      fontSize: 13,
      color: '#1d4698',
      opacity: '0.9',
    },
    alertStatus: {
      display: 'flex',
      alignItems: 'center',
      gap: '3px',
      textDecoration: 'none',
      color: '#1d4698',
    },
    alertDanger: {
      borderLeft: '8px solid #e96c5a',
      background: 'rgb(233 108 90 / 0.1)',
    },
    alertWarning: {
      borderLeft: '8px solid #f2e82c',
      background: 'rgb(242 232 54 / 15%)',
    },
  })
);

export default function MyLiveClassesWidget() {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem dense className={classes.title}>
        <ListItemText
          primary={
            <div>
              <span className={classes.titleText}>Your Live Classes Today</span>
              <span className={classes.titleDate}>June 11th, 2021</span>
            </div>
          }
        />
        <ListItemSecondaryAction>
          <ButtonGroup
            variant="contained"
            color="default"
            aria-label="contained primary button group"
            size="small"
            className={classes.btnGrp}
            disableElevation
          >
            <Button>Yesterday</Button>
            <Button style={{ margin: '0px 6px' }} color="secondary">
              Today
            </Button>
            <Button>Tomorrow</Button>
          </ButtonGroup>
        </ListItemSecondaryAction>
      </ListItem>

      <Box paddingLeft="16px" paddingRight="16px" paddingBottom="8px">
        <Divider />
      </Box>

      {data.map((item) => {
        return (
          <ListItem key={item.id} role={undefined}>
            <Alert
              icon={false}
              severity="success"
              className={clsx(
                classes.alertRoot,
                item.status === 'warning' && classes.alertWarning,
                item.status === 'danger' && classes.alertDanger
              )}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Avatar
                        alt="Remy Sharp"
                        src="https://aacismaharjan.github.io/static/media/avatar.252219c3.jpg"
                        sizes="sm"
                      />
                    </Grid>

                    <Grid item>
                      <AlertTitle className={classes.alertTitle}>
                        {item.title}
                      </AlertTitle>

                      <b style={{ fontWeight: 500, fontSize: 13 }}>
                        {item.name}
                      </b>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <b className={classes.alertTime}>{item.time}</b>
                </Grid>

                <Grid item>
                  <b style={{ fontWeight: 600, fontSize: 13 }}>
                    {item.status === 'warning' && (
                      <a
                        href="https://meet.google.com"
                        rel="noreferrer"
                        className={classes.alertStatus}
                        target="_blank"
                      >
                        Join Class <ArrowRightAltIcon />
                      </a>
                    )}
                    {item.status === 'danger' && (
                      <span
                        style={{
                          color: '#e96c5a',
                        }}
                      >
                        Cancelled
                      </span>
                    )}
                    {item.status === 'success' && (
                      <span
                        style={{
                          color: '#3fc581',
                        }}
                      >
                        Complete
                      </span>
                    )}
                  </b>
                </Grid>
              </Grid>
            </Alert>
          </ListItem>
        );
      })}
    </List>
  );
}

const data = [
  {
    id: 1,
    name: 'Aashish Maharjan',
    title: 'Social Studies - Karl Mark',
    time: '11:00am - 12:00am',
    status: 'success',
  },
  {
    id: 2,
    name: 'Manoj Acharya',
    title: 'Science - Living Things',
    time: '10:00am - 11:00am',
    status: 'danger',
  },
  {
    id: 3,
    name: 'Reuben Thapa',
    title: 'Mathematics - Sets',
    time: '1:00pm - 2:00pm',
    status: 'warning',
  },
];
