import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Paper, Grid } from '@material-ui/core';
import AddNewButton from '../../../../../components/Reusable/Buttons/AddNewButton';
import { Add } from '@material-ui/icons';
import { filterStyle } from '../../../components/Styles/TeacherDashboardStyles';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import CustomizedSelect from "../../../../../components/Reusable/Inputs/Select"
import { assignmentCategoryOptions, Tupple, submissionTypeOptions } from "../../../../../common/json.constant"
import { GetTeacherAssignment } from "../../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction"
import { TeacherClassSubjectsI } from '../../../../../rtk/features/timetable/timetable'

const SingleSubjectSearch = ({ data, value }: { data: TeacherClassSubjectsI, value: number }) => {
  const classes = useFormStyles();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch()
  const [selectedAssignmentCategory, setSelectedAssignmentCategory] = useState<Tupple | null>(null)
  const [selectedSubmissionType, setSelectedSubmissionType] = useState<Tupple | null>(null)

  useEffect(() => {
    if (data) {
      const section = data.classes?.section?.id ? data.classes?.section?.id : ""
      selectedAssignmentCategory && !selectedSubmissionType && dispatch(GetTeacherAssignment(data.classes.grade, section, data.subject.id, null, selectedAssignmentCategory.key, true))
      selectedSubmissionType && !selectedAssignmentCategory && dispatch(GetTeacherAssignment(data.classes.grade, section, data.subject.id, selectedSubmissionType.key, null, true))
      selectedAssignmentCategory && selectedSubmissionType && dispatch(GetTeacherAssignment(data.classes.grade, section, data.subject.id, selectedSubmissionType.key, selectedAssignmentCategory.key, true))
      !selectedAssignmentCategory && !selectedSubmissionType && dispatch(GetTeacherAssignment(data.classes.grade, section, data.subject.id, null, null, true))
    }
  }, [selectedAssignmentCategory, selectedSubmissionType, value])

  return (
    <form className={classes.form}>
      <Paper style={filterStyle}>
        <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: "0.75em" }}>
          <Grid item>
            <span
              style={{
                color: 'rgb(77, 79, 92)',
                fontWeight: 500,
                fontSize: '20px',
              }}
            >
              {data?.classes?.grade_name} ({data?.classes?.section?.name})
            </span>
          </Grid>
          <Grid item>
            <Link
              to={{
                pathname: `/assignment/add/${id}`,
                state: { data }
              }}
              style={{ textDecoration: 'none' }}
            >
              <AddNewButton
                title="Add a new Assignment"
                type="button"
                color="primary"
                variant="outlined"
                startIcon={<Add />}
              >
                New Assignment
              </AddNewButton>
            </Link>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item className={classes.formWrapper} xs={4}>
            <CustomizedSelect
              label="Assignment Category"
              options={assignmentCategoryOptions}
              value={selectedAssignmentCategory}
              setValue={setSelectedAssignmentCategory}
              name="assignment_category"
            />
          </Grid>

          <Grid item className={classes.formWrapper} xs={4}>
            <CustomizedSelect
              label="Submission Type"
              options={submissionTypeOptions}
              value={selectedSubmissionType}
              setValue={setSelectedSubmissionType}
              name="submission_type"
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default SingleSubjectSearch;
