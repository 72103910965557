import { Paper, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetStudentMyComplains } from "../../../../actions/StudentDash/MyComplains/MyComplainsAction";
import { PRIMARY_DATE_FORMAT } from "../../../../common/constant";
import { ItemViewButton } from "../../../../components/Reusable/Buttons/TableButton";
import Popups from "../../../../components/Reusable/Dialogs/Popups";
import useTable from "../../../../components/Reusable/useStudentTable";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import getFullName from "../../../../components/utils/getFullName";
import { RootStore } from "../../../../store";
import { tblStyle } from "../../StudentDashboardStyles";
import MyComplaintView from "./MyComplaintView";

interface HeadCellsI {
  id: string;
  label: string;
  filter?: boolean;
  align?: "left" | "right" | "center";
}

interface TableDataI {
  id: number;
  complainer: string;
  complain_date: string;
  description: string;
}

// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "complainer", label: "Feedbacker", align: "left" },
  { id: "complain_date", label: "Complain Date" },
  { id: "description", label: "Description" },
  { id: "action", label: "Action" },
];

const MyComplaintTable = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<any[] | null>([]);
  const [complain, setComplain] = useState<any>(null);
  const [openPopup, setOpenPopup] = useState(false);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  useEffect(() => {
    dispatch(GetStudentMyComplains());
  }, []);

  const myComplainSelector = useSelector(
    (state: RootStore) => state.student_my_complains
  );

  useEffect(() => {
    const items = myComplainSelector.student_my_complains;
    setTableData(items);
  }, [myComplainSelector]);

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleViewClick = (complain: any) => {
    setComplain(complain);
    setOpenPopup(true);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {getFullName(item.complainer_first_name, item.complainer_last_name)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.created_on).format(PRIMARY_DATE_FORMAT)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.twoCell}>
          {item.description}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.twoCell}>
          <ItemViewButton onClick={() => handleViewClick(item)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root} style={tblStyle}>
        <TableContainer
          label="Feedback List"
          getCustomTableRow={getCustomTableRow}
          items={tableData}
          headCells={headCells}
          search_name="complainer"
          rowsPerPage={7}
          loading={myComplainSelector.loading}
        />

        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          title="My Feedback"
        >
          <MyComplaintView viewData={complain && complain} />
        </Popups>
      </Paper>
    </>
  );
};

export default MyComplaintTable;
