//-------------------<Start> module imports starts ----------------------//
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { Paper } from "@material-ui/core";
import { tblStyle } from "../../StudentDashboardStyles";
import ReusableTimetable from '../../../../components/Academic/TimeTable/ReusableTimetable'
// -------------------<END> module imports end ----------------------//


// -------------------- <START> Table Headings Data ---------------------------//
export const timeConvertor = (time: string): string => {
  const splitter = time.split(":");
  let hour = parseInt(splitter[0]);
  const mins = splitter[1];
  let meridian = splitter[2];

  if (hour > 12) {
    hour = hour - 12;
    meridian = "PM";
  } else if (hour === 12) {
    meridian = "PM";
  } else {
    meridian = "AM";
  }

  return `${hour}:${mins} ${meridian}`;
};
// ---------------------<END> Table Headings Data Ends --------------------------//

// ---------------<START> Timetable Schedule component starts -----------------//
const TimeTableViewS = () => {
  const classes = useTableStyles();

  return (
    <>
      <Paper
        className={classes.rootTableBox}
        style={{ ...tblStyle, padding: "auto" }}
      >
        <ReusableTimetable role="Student" />
      </Paper>
    </>
  );
};
// --------------------<ENDS> Time table Schedule ends ----------------------//
export default TimeTableViewS;
