import { Box } from "@material-ui/core";
import React, { useState } from "react";
import PageHeader from "../../../dashboard/TeacherDashboard/components/Header/PageHeader";
import PageWrapper from "../../../dashboard/TeacherDashboard/components/PageWrapper";
import AddAPESearch from "./AddAPESearch";
import AddAPETable from "./AddAPETable";

interface SearchParamI {
  exam_info: string;
  grade: string;
  section: string;
}

const AddAPEpage = () => {
  const [tableTitle, setTableTitle] = useState<SearchParamI | null>(null);

  const handleSearch = (data: SearchParamI) => {
    setTableTitle(data);
  };
  return (
    <>
      <Box>
        <PageHeader
          title="Exam Marks"
          module={{ name: "Academics", to: "/exam-marks" }}
        />
        <PageWrapper>
          <AddAPESearch onSearch={handleSearch} />
          <AddAPETable tableTitle={tableTitle} />
        </PageWrapper>
      </Box>
    </>
  );
};

export default AddAPEpage;
