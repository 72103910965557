import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../actions/SnackbarAction";
import { RootStore } from "../../store";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const dispatch = useDispatch();
  const authToken = localStorage.getItem("hamro_token");
  const password_validated = useSelector((state: RootStore) => state.auth.password_validated);
  const user = useSelector((state: RootStore) => state.auth.user);


  return (
    <Route
      {...rest}
      render={(props) => {
        if (authToken && password_validated) {
          return <Component {...props} />;
        } else if (authToken && user && !password_validated) {
          return (
            <Redirect
              to={{
                pathname: `/confirm-password/${user.id}`,
                state: {
                  from: props.location.pathname,
                },
              }}
            />
          );
        } else {
          // dispatch(setSnackbar(true, "error", "Login to Proceed"));
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location.pathname,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
