import React, { useEffect, useState } from 'react';
import { ExaminationSideBar, SearchResultBar } from '../../components';
import ResultForClass from '../../components/Examination/Results/ResultForClass';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import { SearchTitleI } from '../../components/Examination/Results/SearchResultBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';
import GenerateResultTable from '../../components/Examination/Results/GenerateResultTable';
import { Button, Grid } from '@material-ui/core';
import { GetResult } from '../../actions/Examination/Result/ResultAction';
import { GetStudentByClass } from '../../actions/Student/Student/StudentAction';

export default function Results() {
  const [searchTitle, setSearchTitle] = useState<SearchTitleI | null>(null);
  const [viewMode, setViewMode] = useState<boolean>(true);
  const nav = <ExaminationSideBar results />;

  // const dispatch = useDispatch();

  const handleSearch = (data: SearchTitleI) => {
    setSearchTitle(data);
  };

  const switchView = () => {
    setViewMode(!viewMode);
  };

  // useEffect(() => {
  //   if (searchTitle) {
  //     if (viewMode) {
  //       dispatch(
  //         GetResult(
  //           searchTitle.exam_info,
  //           searchTitle.grade,
  //           searchTitle.section_id
  //         )
  //       );
  //     } else {
  //       dispatch(GetStudentByClass(searchTitle.grade, searchTitle.section_id));
  //     }
  //   }
  // }, [viewMode]);

  return (
    <SubModuleLayout examinationMenuActive sideNav={nav}>
      <SearchResultBar onSearch={handleSearch} />
      {searchTitle ? (
        <Grid container justifyContent="flex-start">
          <Grid item>
            <Button
              style={{ margin: '-20px 0px -20px 15px', marginLeft: '15px' }}
              type="submit"
              color="primary"
              variant="contained"
              // startIcon={<Visibility />}
              onClick={() => switchView()}
            >
              {viewMode ? 'Generate Result' : 'View Result'}
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {viewMode ? (
        <ResultForClass searchTitle={searchTitle} />
      ) : (
        <GenerateResultTable
          searchTitle={searchTitle}
          onGen={() => setViewMode(true)}
        />
      )}
    </SubModuleLayout>
  );
}
