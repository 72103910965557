import {
  GET_CHILDREN_SUBJECTS_LOADING,
  GET_CHILDREN_SUBJECTS_ERROR,
  GET_CHILDREN_SUBJECTS_SUCCESS,
  ChildrenSubjectsDispatchTypes,
  ChildrenSubjectTypeI,
} from "../../actions/ParentsDash/ChildrenSubjects/ChildrenSubjectActionTypes";

interface InitialStateI {
  loading: boolean;
  subjects: ChildrenSubjectTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  subjects: [],
};

const ChildrenSubjectReducer = (
  state: InitialStateI = initialState,
  action: ChildrenSubjectsDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_CHILDREN_SUBJECTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CHILDREN_SUBJECTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CHILDREN_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: action.payload,
      };

    default:
      return state;
  }
};

export default ChildrenSubjectReducer;
