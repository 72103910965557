import React, { useState, useEffect } from "react";
import {
  Paper,
  TableBody,
  TextField,
  Grid,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import {
  DeleteForeverOutlined,
  EditOutlined,
  InsertDriveFileOutlined,
  PrintOutlined,
} from "@material-ui/icons";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import { Pagination } from "@material-ui/lab";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import ExamGroupScheduleModal from "../ExamSchedules/ExamGroupScheduleModal";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import { ExamTypeI } from "../../../actions/Examination/Exam/ExamActionTypes";
import {
  GetExams,
  DeleteExam,
} from "../../../actions/Examination/Exam/ExamAction";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import {
  PostAddButton,
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";

// -------------------<END> module imports end ----------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface PropsI {
  onEditData: (value: ExamTypeI) => void;
  edit: { check: boolean };
}

interface ExamDataI {
  id: string;
  name: string;
}

interface HeadCellsI {
  id: string;
  label: string;
}
// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "examType", label: "Exam Type" },
  { id: "start_date", label: "Start Date " },
  { id: "end_date", label: "End Date " },
  { id: "result_date", label: "Result Publish Date" },
  { id: "weightage", label: "Weightage" },
  { id: "action", label: "Action" },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

const SectionTable = (props: PropsI) => {
  const { onEditData, edit } = props;
  const classes = useTableStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [modelOpen, setModalOpen] = useState<boolean>(false);
  const [examType, setExamType] = useState<ExamDataI | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);

  // States declaration
  const [tableData, setTableData] = useState<ExamTypeI[]>([]);

  //////////////////////////////////////////////////

  //============================ <START> CYCLE HOOKS <START> =======================//
  const dispatch = useDispatch();
  const examSelector = useSelector((state: RootStore) => state.exam);

  useEffect(() => {
    dispatch(GetExams());
  }, []);

  useEffect(() => {
    const data = examSelector.exams;

    setLoading(examSelector.loading);
    setTableData(data);
  }, [examSelector]);

  useEffect(() => {
    edit && setEditMode(edit.check);
  }, [edit]);
  //============================ <END> CYCLE HOOKS <END> =======================//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //============================ <START> CYCLE HOOKS <START> =======================//

  const handleAddClicked = (exam_type: ExamDataI) => {
    setExamType(exam_type);
    setModalOpen(!modelOpen);
  };

  const handleEditClicked = (data: ExamTypeI) => {
    onEditData(data);
    setEditMode(true);
  };

  const handleClose = (value: boolean) => {
    setModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteExam(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: ExamTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.exam_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.start_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.end_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.result_publish_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.weightage || "-"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.threeCell}>
          <ItemEditButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  //============================ <END> CYCLE HOOKS <END> =======================//

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Exam List</Typography>
        <TableContainer
          loading={loading}
          headCells={headCells}
          items={tableData}
          getCustomTableRow={getCustomTableRow}
          search_name="exam_name"
          search_param="exam_name"
        />
      </Paper>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
};

export default SectionTable;
