export const GET_TEACHER_ANNOUNCEMENTS_LOADING =
  "GET_TEACHER_ANNOUNCEMENTS_LOADING";
export const GET_TEACHER_ANNOUNCEMENTS_ERROR =
  "GET_TEACHER_ANNOUNCEMENTS_ERROR";
export const GET_TEACHER_ANNOUNCEMENTS_SUCCESS =
  "GET_TEACHER_ANNOUNCEMENTS_SUCCESS";

export type TeacherAnnouncementTypeI = {
  id: number;
  priority: string;
  priority_display_name: string;
  title: string;
  announcement_date: string;
  created_on: string;
  start_time: string;
  end_time: string;
  description: string;
  group: GroupTypeI[];
  user: UserTypeI[];
};

type GroupTypeI = {
  id: number;
  name: string;
};

type UserTypeI = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  general_info: string;
};

//===============================<START>GET TEACHER_ANNOUNCEMENTS<START>======================//

export interface GetTeacherAnnouncementsLoading {
  type: typeof GET_TEACHER_ANNOUNCEMENTS_LOADING;
}

export interface GetTeacherAnnouncementsError {
  type: typeof GET_TEACHER_ANNOUNCEMENTS_ERROR;
}

export interface GetTeacherAnnouncementsSuccess {
  type: typeof GET_TEACHER_ANNOUNCEMENTS_SUCCESS;
  payload: TeacherAnnouncementTypeI[];
}
//===============================<END>GET TEACHER_ANNOUNCEMENTS<END>======================//

export type TeacherAnnouncementsDispatchTypes =
  | GetTeacherAnnouncementsLoading
  | GetTeacherAnnouncementsError
  | GetTeacherAnnouncementsSuccess;
