import { Box, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import TeacherAnnounce from "./ModalView/TeacherAnnounce";
import TeacherAnnounceTable from "./ModalView/TeacherAnnounceTable";

export const useTeacherStyles = makeStyles(() => ({
  flexible: {
    flex: 1,
  },
  static: {
    flexBasis: "300px",
  },
}));

const TeacherAnnouncementPage = () => {
  const classes = useTeacherStyles();

  return (
    <>
      <Box>
        <PageHeader title="Announcement" />
        <PageWrapper>
          <Grid container>
            <Grid item className={classes.flexible}>
              <TeacherAnnounceTable />
            </Grid>

            <Grid item className={classes.static}>
              <TeacherAnnounce />
            </Grid>
          </Grid>
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherAnnouncementPage;
