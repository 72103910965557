import {
  GET_CHILDREN_ENOTES_LOADING,
  GET_CHILDREN_ENOTES_ERROR,
  GET_CHILDREN_ENOTES_SUCCESS,
  ChildrenEnotesDispatchTypes,
} from "../../actions/ParentsDash/ChildrenEnote/ChildrenEnoteActionTypes";
import { StudentEnotesI } from "../../actions/StudentDash/StudentEnote/StudentEnoteActionTypes";

interface InitialStateI {
  loading: boolean;
  enotes: StudentEnotesI[];
}

const initialState: InitialStateI = {
  loading: false,
  enotes: [],
};

const ChildrenEnoteReducer = (
  state: InitialStateI = initialState,
  action: ChildrenEnotesDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_CHILDREN_ENOTES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CHILDREN_ENOTES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CHILDREN_ENOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        enotes: action.payload,
      };

    default:
      return state;
  }
};

export default ChildrenEnoteReducer;
