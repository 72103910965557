import {
  GET_TEACHER_LEAVE_LOADING,
  GET_TEACHER_LEAVE_ERROR,
  GET_TEACHER_LEAVE_SUCCESS,
  ADD_TEACHER_LEAVE_LOADING,
  ADD_TEACHER_LEAVE_ERROR,
  ADD_TEACHER_LEAVE_SUCCESS,
  DELETE_TEACHER_LEAVE_LOADING,
  DELETE_TEACHER_LEAVE_ERROR,
  DELETE_TEACHER_LEAVE_SUCCESS,
  TeacherLeaveDispatchTypes,
  LeavePropsI,
  UPDATE_TEACHER_LEAVE_LOADING,
  UPDATE_TEACHER_LEAVE_ERROR,
  UPDATE_TEACHER_LEAVE_SUCCESS,
} from '../../../actions/TeacherDash/TeacherLeave/TeacherLeaveActionType';

interface InitialStateI {
  loading: boolean;
  teacher_leave: any;
  errors: any;
}

const initialState: InitialStateI = {
  loading: false,
  teacher_leave: [],
  errors: null,
};

const TeacherLeaveReducer = (
  state: InitialStateI = initialState,
  action: TeacherLeaveDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case GET_TEACHER_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case GET_TEACHER_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        teacher_leave: action.payload,
      };

    case ADD_TEACHER_LEAVE_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };

    case ADD_TEACHER_LEAVE_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case ADD_TEACHER_LEAVE_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false,
        // teacher_leave: [...state.teacher_leave, action.payload],
      };

    case UPDATE_TEACHER_LEAVE_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };

    case UPDATE_TEACHER_LEAVE_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case UPDATE_TEACHER_LEAVE_SUCCESS:
      const current_data: any = state.teacher_leave;
      const index: number = current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        errors: null,
        loading: false,
        teacher_leave: current_data,
      };

    case DELETE_TEACHER_LEAVE_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };

    case DELETE_TEACHER_LEAVE_ERROR:
      return {
        ...state,
        errors: null,
        loading: false,
      };

    case DELETE_TEACHER_LEAVE_SUCCESS:
      const new_data: LeavePropsI[] = state.teacher_leave;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        errors: null,
        teacher_leave: new_data,
      };

    default:
      return state;
  }
};

export default TeacherLeaveReducer;
