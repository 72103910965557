import { useRef, useEffect } from 'react'
import WebViewer from '@pdftron/webviewer'
import styled from 'styled-components'

const Div = styled.div`
    height: 100vh;
`

const CheckAssignment = ({ id, file, onClose }: { id: string, file: any, onClose: any }) => {
    const ref = useRef<HTMLDivElement>(null)
    console.log({ file })

    useEffect(() => {
        WebViewer({
            path: '/lib',
            initialDoc: file && file[0]?.file,
            // initialDoc: 'https://resources.premierleague.com/premierleague/photo/2021/10/28/28acfb02-bce8-4880-add0-4cb5bf025eef/Kai-Havertz.jpg',
            extension: ''
        }, ref.current as HTMLDivElement).then(instance => {
            console.log(instance)
        })
    }, [])

    return (
        <Div ref={ref}></Div>
    )
}

export default CheckAssignment