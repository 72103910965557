import React, { useEffect, useState } from "react";
import { Button, Grid, InputLabel, Paper, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../../actions/Academics/Class/ClassAction";
import { GetStudentAcademicInfo } from "../../../../actions/ParentsDash/AcademicInfo/StudentAcademicInfoAction";
import { RootStore } from "../../../../store";
import FullWidthFormLayout from "../../../../components/Reusable/Layouts/FullWidthForm.layout";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import getFullName from "../../../../components/utils/getFullName";
import { MONTH_CHOICES } from "../../../../common/json.constant";
import { GetDates } from "../../../../actions/Dates/DatesAction";
import CustomizedSelect, {
  RowSelect,
} from "../../../../components/Reusable/Inputs/Select";
import {
  GetTeacherClass,
  GetTeacherClassSection,
} from "../../../../actions/TeacherDash/TeacherClass/TeacherClassAction";
import { GetClassAttendanceReport } from "../../../../actions/TeacherDash/ClassAttendanceReport/AttendanceReportAction";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../../../components/utils/dateConverter";
import { Tupple } from "../../../../common/json.constant";
import { AnyMxRecord } from "dns";
import { MyAttendances } from "../../../../actions/StudentDash/MyAttendance/MyAttendanceAction";
import { filterStyle } from "../../components/Styles/TeacherDashboardStyles";
import { GetTeachingClass } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassAction";
import { TeachingClassTypeI } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";
import { ADD_SECTION_SUCCESS, SectionTypeI } from "../../../../actions/Academics/Section/SectionActionTypes";

interface FormI {
  month_name: string | null;
  children_name: number | null;
}

const SearchAttendace = (props: any) => {
  const classes = useFormStyles();

  const { register, errors, setError } = useForm<FormI>();

  const [month, setMonth] = useState<Tupple | null>(null);
  const [children, setChildren] = useState<Tupple | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number | null>(null);
  const [classChoice, setClassChoice] = useState<any | null>(null);
  const [sectionChoice, setSectionChoice] = useState<any | null>(null);
  const [sectionChoices, setSectionChoices] = useState<any | null>(null);

  const dispatch = useDispatch();
  const studentAcademicState = useSelector(
    (state: RootStore) => state.student_academic
  );
  const dateSelector = useSelector(
    (state: RootStore) => state.month_dates.data
  );
  const classSelector = useSelector((state: RootStore) => state.teaching_class);

  const inputStyle = { marginBottom: "0px" };

  useEffect(() => {
    dispatch(GetTeachingClass());
    // dispatch(GetTeacherClassSection());
    const utc = new Date().toJSON().slice(0, 10);
    setCurrentMonth(parseInt(dateConverterAdToBs(utc).substring(5, 7)) - 1);
    dispatch(GetDates(parseInt(dateConverterAdToBs(utc).substring(5, 7))));
  }, []);

  useEffect(() => {
    if (currentMonth != null) {
      dispatch(GetDates(currentMonth + 1));
      setMonth(MONTH_CHOICES[currentMonth]);
    }
  }, [currentMonth]);

  const SELECT_CLASS_CHOICES: any[] = classSelector.teaching_class.map(
    (classItem: any) => ({
      ...classItem,
      key: classItem.grade?.toString(),
      value: `Class ${classItem.grade_name}`,
    })
  );

  useEffect(() => {
    if (
      dateSelector.dates != null &&
      classChoice != null &&
      sectionChoice != null
    ) {
      const searchData = {
        grade: classChoice?.key,
        section: sectionChoice?.key || "",
        from_date: dateConverterBsToAd(dateSelector.dates[0]),
        to_date: dateConverterBsToAd(
          dateSelector.dates[dateSelector.dates.length - 1]
        ),
      };
      dispatch(GetClassAttendanceReport(searchData));
    }
  }, [dateSelector.dates, classChoice, sectionChoice]);

  useEffect(() => {
    props.setMonthName(month?.value);
  }, [month]);

  // useEffect(() => {
  //   if (classChoice != null) {
  //     const temp = classSelector.teaching_class
  //     if (temp?.section?.length) {
  //       const choices: any[] = temp.map((elem: any) => ({
  //         key: elem.id?.toString() || "",
  //         value: `${elem.name || ""}`,
  //       }));
  //       setSectionChoices(choices);
  //     }
  //   }
  // }, [classChoice]);

  const handleMonthChange = (value: Tupple) => {
    if (value != null) {
      setMonth(value);
      dispatch(GetDates(value.key));
    } else {
      setMonth(null);
    }
  };

  const handleGradeChange = (value: TeachingClassTypeI | null) => {
    if (value != null) {
      setClassChoice(value);
      console.log("here", value)
      populateSectionChoices(value.section);
    } else {
      setSectionChoice(null);
      setClassChoice(null);
    }
  };

  const populateSectionChoices = (sections: SectionTypeI[] | null) => {
    if (sections === null) return;
    const temp = sections?.map((section) => {
      return {
        ...section,
        value: section.name,
      }
    })
    console.log(temp)
    setSectionChoices(temp);
  }

  const handleSectionChange = (value: any | null) => {
    if (value != null) {
      setSectionChoice(value);
    } else {
      setSectionChoice(null);
    }
  };

  return (
    <>
      <Paper style={filterStyle}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={3}
            className={classes.formWrapperRow}
            style={inputStyle}
          >
            <RowSelect
              fullWidth
              label="Class"
              options={SELECT_CLASS_CHOICES}
              value={classChoice}
              setValue={handleGradeChange}
              name="class_choice"
              error={errors["class_choice"] ? "Class must be selected" : ""}
              inputRef={register({ required: true })}
            />
          </Grid>
          <Grid
            item
            xs={3}
            className={classes.formWrapperRow}
            style={inputStyle}
          >
            <RowSelect
              fullWidth
              label="Section"
              options={sectionChoices}
              value={sectionChoice}
              setValue={handleSectionChange}
              name="section_choice"
              error={errors["section_choice"] ? "Section must be selected" : ""}
              disabled={classChoice ? false : true}
            />
          </Grid>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <RowSelect
              fullWidth
              label="Month"
              name="month_name"
              options={MONTH_CHOICES}
              value={month}
              setValue={handleMonthChange}
              error={errors["month_name"] && errors["month_name"].message}
              inputRef={register({ required: true })}
              placeholder="Label"
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default SearchAttendace;
