export const GET_EXAM_GRADE_LOADING = "GET_EXAM_GRADE_LOADING";
export const GET_EXAM_GRADE_ERROR = "GET_EXAM_GRADE_ERROR";
export const GET_EXAM_GRADE_SUCCESS = "GET_EXAM_GRADE_SUCCESS";

export const ADD_EXAM_GRADE_LOADING = "ADD_EXAM_GRADE_LOADING";
export const ADD_EXAM_GRADE_ERROR = "ADD_EXAM_GRADE_ERROR";
export const ADD_EXAM_GRADE_SUCCESS = "ADD_EXAM_GRADE_SUCCESS";

export const UPDATE_EXAM_GRADE_LOADING = "UPDATE_EXAM_GRADE_LOADING";
export const UPDATE_EXAM_GRADE_ERROR = "UPDATE_EXAM_GRADE_ERROR";
export const UPDATE_EXAM_GRADE_SUCCESS = "UPDATE_EXAM_GRADE_SUCCESS";

export const DELETE_EXAM_GRADE_LOADING = "DELETE_EXAM_GRADE_LOADING";
export const DELETE_EXAM_GRADE_ERROR = "DELETE_EXAM_GRADE_ERROR";
export const DELETE_EXAM_GRADE_SUCCESS = "DELETE_EXAM_GRADE_SUCCESS";

export type GradeTypeI = {
  id: number;
  grade_name: string;
  marks_from: string;
  marks_to: string;
  grade_point: number;
  created_on: string;
  modified_on: string;
  created_by: number;
  count?: number;
};

//===============================<START>GET GRADE<START>======================//

export interface GetGradeLoading {
  type: typeof GET_EXAM_GRADE_LOADING;
}

export interface GetGradeError {
  type: typeof GET_EXAM_GRADE_ERROR;
}

export interface GetGradeSuccess {
  type: typeof GET_EXAM_GRADE_SUCCESS;
  payload: GradeTypeI[];
}

//===============================<END>GET GRADE<END>======================//

//===============================<START>ADD GRADE<START>======================//

export interface AddGradeLoading {
  type: typeof ADD_EXAM_GRADE_LOADING;
}

export interface AddGradeError {
  type: typeof ADD_EXAM_GRADE_ERROR;
}

export interface AddGradeSuccess {
  type: typeof ADD_EXAM_GRADE_SUCCESS;
  payload: GradeTypeI;
}

//===============================<END>ADD GRADE<END>======================//

//===============================<START>UPDATE GRADE<START>======================//

export interface UpdateGradeLoading {
  type: typeof UPDATE_EXAM_GRADE_LOADING;
}

export interface UpdateGradeError {
  type: typeof UPDATE_EXAM_GRADE_ERROR;
}

export interface UpdateGradeSuccess {
  type: typeof UPDATE_EXAM_GRADE_SUCCESS;
  payload: GradeTypeI;
}

//===============================<END>UPDATE GRADE<END>======================//

//===============================<START>DELETE GRADE<START>======================//

export interface DeleteGradeLoading {
  type: typeof DELETE_EXAM_GRADE_LOADING;
}

export interface DeleteGradeError {
  type: typeof DELETE_EXAM_GRADE_ERROR;
}

export interface DeleteGradeSuccess {
  type: typeof DELETE_EXAM_GRADE_SUCCESS;
  payload: { id: number };
}

//===============================<END>DELETE GRADE<END>======================//

export type GradeDispatchTypes =
  | GetGradeLoading
  | GetGradeError
  | GetGradeSuccess
  | AddGradeLoading
  | AddGradeError
  | AddGradeSuccess
  | UpdateGradeLoading
  | UpdateGradeError
  | UpdateGradeSuccess
  | DeleteGradeLoading
  | DeleteGradeError
  | DeleteGradeSuccess;
