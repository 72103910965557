import { Box, Card, CardContent, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GetLeaveTypes } from '../../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import { GetTeacherLeave } from '../../../../actions/TeacherDash/TeacherLeave/TeacherLeaveAction';
import { RootStore } from '../../../../store';
import PageHeader from '../../components/Header/PageHeader';
import PageWrapper from '../../components/PageWrapper';
import TeacherLeaveList from './LeaveComponents/TeacherLeaveList';
import TeacherLeaveSearch from './LeaveComponents/TeacherLeaveSearch';
import Carousel from 'react-multi-carousel';

const StyledCard = styled(Card)`
  &.MuiCard-root {
    display: block;
    width: max-content;
    background-color: #48c581;
    border: none;
    color: white;
  }
`;

const StyledCardContent = styled(CardContent)`
  padding: 12px !important;
`;

const StyledCardTitle = styled(({ children, ...rest }: any) => (
  <Typography variant="subtitle2" gutterBottom>
    {children}
  </Typography>
))``;

const StyledCardNumber1 = styled(({ children, ...rest }: any) => (
  <Typography variant="h4" component="span" {...rest}>
    {children}
  </Typography>
))`
  font-size: 24px;
  font-weight: 600 !important;
`;

const StyledCardNumber2 = styled(({ children, ...rest }: any) => (
  <Typography variant="subtitle2" component="span">
    {children}
  </Typography>
))``;

const CardContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 15px;
`;

export const responsiveness = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 1200, min: 960 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 960, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 768, min: 575 },
    items: 2,
  },
  superLargeMobile: {
    breakpoint: { max: 575, min: 475 },
    items: 1,
  },
  largeMobile: {
    breakpoint: { max: 475, min: 0 },
    items: 1,
  },
};

const TeacherLeavePage = () => {
  const dispatch = useDispatch();
  const [leaveTypes, setLeaveTypes] = useState<any>([]);

  useEffect(() => {
    dispatch(GetLeaveTypes());
    dispatch(GetTeacherLeave());
  }, []);

  const teacherLeave = useSelector((state: RootStore) => state.teacher_leave);
  const teacherLeaveTypes = useSelector((state: RootStore) => state.leave_type);

  useEffect(() => {
    setLeaveTypes([...teacherLeaveTypes.leave_types]);
  }, [teacherLeaveTypes]);

  console.log('leaveTypes', leaveTypes);

  return (
    <>
      <Box>
        <PageHeader title="Leave" />
        <PageWrapper>
          <TeacherLeaveSearch />

          <CardContainer>
            {leaveTypes &&
              leaveTypes.length > 0 &&
              leaveTypes.map((leaveType: any, index: number) => {
                return (
                  <StyledCard key={index} variant="outlined">
                    <StyledCardContent>
                      <StyledCardTitle>{leaveType.name}</StyledCardTitle>
                      <StyledCardNumber1>
                        {leaveType.max_leave}
                      </StyledCardNumber1>
                      <StyledCardNumber2>
                        /{leaveType.max_leave}
                      </StyledCardNumber2>
                    </StyledCardContent>
                  </StyledCard>
                );
              })}
          </CardContainer>

          <TeacherLeaveList />
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherLeavePage;
