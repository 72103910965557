import React, { useState, useEffect } from "react";
import { Paper, TableCell } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { HeadCellsI } from "../../../../../actions";
import { GetLeaveTypes } from "../../../../../actions/HumanResource/LeaveType/LeaveTypeAction";
import {
  DeleteTeacherLeave,
  GetTeacherLeave,
} from "../../../../../actions/TeacherDash/TeacherLeave/TeacherLeaveAction";
import { PRIMARY_DATE_FORMAT } from "../../../../../common/constant";
import GetStatus from "../../../../../common/GetStatus";
import { RootStore } from "../../../../../store";
import { ItemViewButton } from "../../../../../components/Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../../../../components/Reusable/Dialogs/DeleteDialog";
import Popups from "../../../../../components/Reusable/Dialogs/Popups";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import { dateConverterAdToBs } from "../../../../../components/utils/dateConverter";
import getFullName from "../../../../../components/utils/getFullName";
import TeacherLeaveView from "./TeacherMeetingModal/TeacherMeetingView";
import {
  GetTeacherMeeting,
  TeacherMeetingApproveOrDecline,
} from "../../../../../actions/TeacherDash/TeacherMeeting/TeacherMeetingAction";
import { GetMeetingTypes } from "../../../../../actions/HumanResource/MeetingType/MeetingTypeAction";
import useTable from "../../../../../components/Reusable/useStudentTable";
import { tblStyle } from "../../../components/Styles/TeacherDashboardStyles";

const headCells: HeadCellsI[] = [
  { id: "meeting_type", label: "Meeting Type" },
  { id: "meet_date", label: "Meeting Date" },
  { id: "meet_time", label: "Meeting Time" },
  // { id: "description", label: "Description" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

interface TableDataI {
  id: number;
  name: string;
  leave_type: string;
  leave_date: string;
  days: string;
  applied_date: string;
  description: string;
  status: string;
}

const TeacherMeetingList = () => {
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TableDataI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [leaveData, setLeaveData] = useState([]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const teacherMeeting = useSelector(
    (state: RootStore) => state.teacher_meeting
  );

  useEffect(() => {
    dispatch(GetMeetingTypes());
    dispatch(GetTeacherMeeting());
  }, []);

  useEffect(() => {
    const dataleave = teacherMeeting.teacher_meeting;
    const data: any[] = [];
    dataleave.map((item: any) => {
      data.push({
        id: item.id,
        name: getFullName(item.teacher_first_name, item.teacher_last_name),
        meeting_type: item.meeting_type.name,
        meet_date: dateConverterAdToBs(item.meet_date),
        meet_time: item.meet_time,
        description: item.description,
        status: item.status,
      });
    });
    setTableData(data);
    setLoading(teacherMeeting.loading);
  }, [teacherMeeting]);

  const handleViewModal = (item: any) => {
    setLeaveData(item);
    setItemId(parseInt(item.id));
    setOpenPopup(true);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteTeacherLeave(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.meeting_type}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.meet_date).format(PRIMARY_DATE_FORMAT)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.meet_time}
        </StyledTableCell>
        <TableCell align="center" className={classes.cell}>
          <GetStatus status={item.status} />
        </TableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleViewModal(item)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const onActionConfirmed = (confirmed: boolean, status: string) => {
    const data = {
      id: itemId,
      status: status,
    };
    confirmed &&
      itemId != null &&
      dispatch(TeacherMeetingApproveOrDecline(data));
    setOpenPopup(false);
  };

  return (
    <Paper style={tblStyle}>
      <TableContainer
        label="Leave List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        rowsPerPage={4}
        getCustomTableRow={getCustomTableRow}
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        maxWidth="sm"
        title="Teacher Leave Request"
        colored
      >
        <TeacherLeaveView
          handleClose={handleClose}
          items={leaveData}
          onActionConfirmed={onActionConfirmed}
        />
      </Popups>
    </Paper>
  );
};

export default TeacherMeetingList;
