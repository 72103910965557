import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_LESSON_PLAN_LOADING,
  GET_LESSON_PLAN_ERROR,
  GET_LESSON_PLAN_SUCCESS,
  LessonPlanDispatchTypes,
} from "./StudentLessonPlanActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { STUDENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetStudentLessonPlan =
  (subject: string) =>
  async (dispatch: Dispatch<LessonPlanDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_LESSON_PLAN_LOADING,
      });

      const res = await axios.get(
        `${STUDENT_API_URL}/lesson_plan/?subject=${subject}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_LESSON_PLAN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LESSON_PLAN_ERROR,
      });
    }
  };
