import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import SubjectCard from "../../../../components/Reusable/SubjectCard"
import {
    createStyles,
    makeStyles,
    Paper,
    Theme,
} from "@material-ui/core";
import { RootStore } from "../../../../store"
// import { getTeacherDashboardTimeTable } from "../../../../rtk/features/timetable/timetableApi"
// import { TeacherClassSubjectsI } from "../../../../rtk/features/timetable/timetable"
import { GetAssignmentSubjects } from "../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(2),
            maxHeight: "75vh",
            marginTop: "1em",
            overflowY: "scroll",
        },
        cardContainer: {
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridTemplateRows: "auto",
            gap: "1.25em",
            maxHeight: "70vh",
        },
    })
)

const AssignmentCardPage = () => {
    const [data, setData] = useState<any[]>([])
    const dispatch = useDispatch();
    // const { teacherClassSubjects } = useSelector((state: RootStore) => state.timetable)
    const { assignment_subjects } = useSelector((state: RootStore) => state.teacher_assignment)

    useEffect(() => {
        // dispatch(getTeacherDashboardTimeTable())
        dispatch(GetAssignmentSubjects())
    }, [])

    useEffect(() => {
        setData(assignment_subjects)
    }, [assignment_subjects])

    const classes = useStyles();
    return (
        <Paper className={classes.container} elevation={1}>
            <div className={classes.cardContainer}>
                {data.length > 0 && data.map(item => <SubjectCard key={item.id} data={item} />)}
            </div>
        </Paper>
    )
}

export default AssignmentCardPage