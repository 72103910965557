import { Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { FeeCategorySelect, StudentSelectTable } from '../../../components';
import { ApplyFeeToStudents } from '../../../actions/Fees/FeeType/FeeAction';
import { setSnackbar } from '../../../actions/SnackbarAction';
import { RootStore } from '../../../store';
import AccountantFeeReportSidebar from '../../../components/Event/EventSidebar/AccountantFeeReportSidebar';
import BackDropLoader from '../../../components/Reusable/BackDropLoader';
import SubModuleLayout from '../../../components/Reusable/Layouts/SubModuleLayout';
import FinanceSidebar from '../../../components/Finance/FinanceSidebar';
import VoidSearch from '../../../components/Finance/Void/VoidSearch';
import VoidTable from '../../../components/Finance/Void/VoidTable';

interface SelectedFeesI {
  id: string;
  title: string;
  isMonthly: boolean;
}

export default function AccountVoidPage() {
  const [fees, setFees] = useState<SelectedFeesI[]>([]);
  const [months, setMonths] = useState<string[]>([]);
  const [student, setStudent] = useState<any>(null);

  const [grade, setGrade] = useState<any | null>(null);
  const [section, setSection] = useState<any | null>(null);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  // const [selected, setSelected] = useState<any[]>([]);

  const dispatch = useDispatch();

  const nav = <AccountantFeeReportSidebar voidReport />;

  return (
    <SubModuleLayout sideNav={nav}>
      <Paper style={{ margin: '10px' }}>
        <VoidSearch
          student={student}
          setStudent={setStudent}
          grade={grade}
          setGrade={setGrade}
          section={section}
          setSection={setSection}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <VoidTable
          grade={grade}
          section={section}
          startDate={startDate}
          endDate={endDate}
        />
      </Paper>
      <BackDropLoader open={loading} />
    </SubModuleLayout>
  );
}
