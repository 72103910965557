//---------------------------- <START> module imports start ----------------------------//
import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  withStyles,
  createStyles,
  Theme,
  Checkbox,
  LinearProgress,
  Typography,
  TextField,
  TableBody,
  TableSortLabel,
} from "@material-ui/core";
import XLSX from "xlsx";
import { Pagination } from "@material-ui/lab";
import { useTableStyles } from "../Styles/TableStyles";
import TableActionButtons from "./Buttons/TableActionButtons";
import snPrefix from "../utils/snPrefix";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store";
import { GetGeneralInfoData } from "../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
//---------------------------- <END> module imports start ------------------------------//

//---------------------------- <START> interface start ------------------------------//

interface EnhancedTableProps {
  hasCheckbox?: boolean;
  numSelected?: number;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount?: number;
  order?: any;
  orderBy?: any;
  onRequestSort?: any;
}

interface Data {
  sub_status: number;
}

interface HeadCellsProps {
  id: string;
  label: string | JSX.Element;
  filter?: boolean;
  align?: any;
}

//---------------------------- <END> interface ends ------------------------------//

// ---------------------------- <START> styling start ----------------------------------//
const useStyles = makeStyles((theme) => ({
  table: {
    borderBottom: "1px solid #D6D6D6",
    marginTop: "12px",
    "& thead th": {
      fontWeight: "600",
      color: "#4D4F5C",
      backgroundColor: "#e4e3e3",
      padding: "12px 15px",
    },
    "& tbody td": {
      fontWeight: "400",
      padding: "5px 10px 5px 4px",
      color: "#6C6D6E",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  cell: {
    // borderRight: "1px solid #D6D6D6",
  },
  headCell: {
    paddingLeft: "5px!important",
  },
}));

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> re-usable component starts ---------------------------//
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#00000017",
      color: "#211E1E",
      border: "none!important",
    },

    body: {
      border: "none",
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: "#f7f3f3",
      },
    },
  })
)(TableRow);

const useTable = (headCells: HeadCellsProps[]) => {
  const classes = useStyles();
  const tblClasses = useTableStyles();

  const [documentLabel, setDocumentLabel] = useState<string>("Excel_Sheet");

  // Make table print as a reusable
  const tablePrint = () => {
    const tableToPrint = document.getElementById("export-table")!.outerHTML;
    let htmlToPrint = `
      <style>
        table * {
          color: black;
          background: transparent!important;
        }

        table {
          text-align: left;
          border: 1px solid #000;
          border-width: 1px 1px 1px 1px !important;
          border-collapse: collapse;
        }

        th, td {
          border: 1px solid #000;
          border-width: 0 1px 1px 0 !important;
          padding: 0.5rem;
        }

        table tr td:last-child,
        table tr th:last-child {
          display: none;
        }

        td *{
          display: inline-block;
          margin-right: 5px;
        }


        td img {
          width: 40px;
        }

        input, textarea,
        button, svg{
          display: none!important;
        }

        @media print{
          .no-print, .no-print *{
            display: none !important;
          }
        }
        .hidden-school-name {
          display: block !important;
        }

      </style>
    `;

    htmlToPrint += tableToPrint;
    const a = window.open("", "", "height=1024, width=1024")!;
    a.document.write(htmlToPrint);
    a.document.title = documentLabel;
    a.print();
  };

  // Make exportToExcel() as a reusable
  const exportToExcel = () => {
    const SheetNames: string[] = [];
    const Sheets = {};
    const tableEl = document.getElementById("export-table")!;

    const wb = { SheetNames, Sheets };
    const ws9 = XLSX.utils.table_to_sheet(tableEl, { raw: true });
    // Get total number of columns
    const totalColumns = tableEl.querySelectorAll("th").length;

    // Hide last columns ~ actions buttons
    ws9["!cols"] = [];
    ws9["!cols"][totalColumns - 1] = { hidden: true };
    wb.SheetNames.push("Excel Sheet");
    wb.Sheets["Excel Sheet"] = ws9;
    XLSX.writeFile(wb, `${documentLabel}.xlsx`, { cellStyles: true });
  };

  // Making Table Container as a reusable
  const TblContainer = (props: any) => {
    return (
      <Table
        stickyHeader
        className={classes.table}
        id="export-table"
        aria-label="sticky table"
        {...props}
      >
        {props.children}
      </Table>
    );
  };

  // Making Table Heading as a reusable
  const TblHead = (props: EnhancedTableProps) => {
    const dispatch = useDispatch();

    const generalInfoData = useSelector(
      (state: RootStore) => state.general_info?.general_info
    );

    const authData = useSelector((state: RootStore) => state.auth);

    // useEffect(() => {
    //   if (authData.general_info != null) {
    //     dispatch(GetGeneralInfoData(authData.general_info.id));
    //   }
    // }, [authData]);

    const {
      hasCheckbox,
      onSelectAllClick,
      numSelected = 0,
      rowCount = 0,
      order,
      orderBy,
      onRequestSort,
    } = props;

    const CheckBox = () => (
      <Checkbox
        color="default"
        style={{ marginRight: "0" }}
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        inputProps={{ "aria-label": "select all desserts" }}
        onChange={onSelectAllClick}
      />
    );

    return (
      <>
        <div
          style={{
            marginTop: "5px",
            justifyContent: "space-between",
            paddingBottom: "30px",
            display: "none",
          }}
          className="hidden-school-name"
        >
          <div>
            <span style={{ fontSize: "20px" }}>
              <b style={{ textTransform: "uppercase" }}>
                {generalInfoData?.school_name}
              </b>
            </span>
            <br />
            <span>{generalInfoData?.address}</span>
            <br />
            <span>Tel/Fax: {generalInfoData?.phone_number}</span>
            <br />
            <span>Email: {generalInfoData?.email}</span>
            <br />
          </div>
        </div>
        <TableHead>
          <StyledTableRow>
            {headCells.map((element: HeadCellsProps, index: number) => {
              if (index === 0 && hasCheckbox) {
                return (
                  <StyledTableCell
                    className={classes.headCell}
                    align="left"
                    key={element.id}
                  >
                    <CheckBox />
                    {element.label}
                  </StyledTableCell>
                );
              }

              return (
                <StyledTableCell
                  className={classes.cell}
                  align={element.align || "center"}
                  key={element.id}
                  sortDirection={orderBy === element.id ? order : false}
                >
                  {element.filter ? (
                    <TableSortLabel
                      active={orderBy === element.id}
                      direction={orderBy === element.id ? order : "asc"}
                      onClick={(event: React.MouseEvent<unknown>) =>
                        props.onRequestSort(event, element.id)
                      }
                    >
                      {element.label}
                    </TableSortLabel>
                  ) : (
                    element.label
                  )}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        </TableHead>
      </>
    );
  };
  type Order = "asc" | "desc";

  const TableContainer = (props: any) => {
    const {
      rowsPerPage: nRows = props.forPagination.per_page,
      disablePagination,
      disableSearch,
      disablePrint,
      hasCheckbox,
      onImport,
      onSendSms,
    } = props;
    const { TblContainer, TblHead, TblFoot } = useTable(props.headCells);

    // Modal States
    const [data, setData] = useState(props.items);
    const [totalRecord, setTotalRecord] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(nRows);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(props.forPagination.current_page);
    const [recordFrom, setRecordFrom] = useState(1);
    const [recordTo, setRecordTo] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);

    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<keyof Data>("sub_status");

    useEffect(() => {
      props.label && setDocumentLabel(props.label);
    }, []);

    useEffect(() => {
      setLoading(props.loading);
      setTotalRecord(props.forPagination.total);
      setPageCount(props.forPagination.total_pages);
      setRecordFrom(
        props.forPagination.current_page === 1
          ? props.forPagination.current_page
          : (props.forPagination.current_page - 1) *
              props.forPagination.per_page +
              1
      );
      setRecordTo(
        props.forPagination.current_page === 1
          ? props.forPagination.current_page * props.forPagination.per_page
          : props.forPagination.current_page * props.forPagination.per_page
      );
      // console.log(
      //   "test",
      //   props.forPagination.current_page === 1
      //     ? props.forPagination.current_page
      //     : (props.forPagination.current_page - 1) *
      //         props.forPagination.per_page +
      //         1
      // );
      // console.log(props.forPagination);
      // console.log(
      //   "test",
      //   props.forPagination.current_page,
      //   props.forPagination.per_page
      // );
    }, [props.items]);

    const handlePageChange = (event: any, newPage: number) => {
      props.handlePaginationChange(newPage);
      setPage(newPage);
    };

    function handleTableSearch(
      e: React.ChangeEvent<HTMLInputElement>,
      ...rest: any
    ) {
      const query = e.target.value.toUpperCase();
      const search_data = props.items.filter((data: any) =>
        data[rest[0]].toUpperCase().includes(query)
      );

      setTotalRecord(search_data.length);
      setPageCount(Math.ceil(search_data.length / rowsPerPage));
      setPage(1);
      setRecordFrom(1);
      setRecordTo(rowsPerPage);
      setData(search_data);
    }

    const tblFootProps = {
      loading,
      totalRecord,
      page,
      pageCount,
      recordTo,
      recordFrom,
      handlePageChange,
    };

    const [selected, setSelected] = React.useState<string[]>([]);

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: keyof Data
    ) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }

    function getComparator<Key extends keyof any>(
      order: Order,
      orderBy: Key
    ): (
      a: { [key in Key]: number | string },
      b: { [key in Key]: number | string }
    ) => number {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
      const stabilizedThis = array?.map(
        (el, index) => [el, index] as [T, number]
      );
      stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis?.map((el) => el[0]);
    }

    const search_name = props.search_name || "name";

    // console.log(props.getCustomTableRow);

    return (
      <div className={tblClasses.tableWrapper}>
        <div className={tblClasses.tableTop}>
          {disableSearch ? (
            <div></div>
          ) : (
            <TextField
              style={{ width: "30%" }}
              variant="outlined"
              name="search"
              placeholder="Search ..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTableSearch(e, search_name)
              }
            />
          )}
          {!disablePrint ? (
            <TableActionButtons
              exportToExcel={exportToExcel}
              tablePrint={tablePrint}
              onImport={onImport}
              onSendSms={onSendSms}
            />
          ) : null}
        </div>

        <TblContainer>
          <TblHead
            hasCheckbox={hasCheckbox || false}
            rowCount={props.rowCount}
            numSelected={props.numSelected}
            onSelectAllClick={props.handleSelectAllClicked}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy))?.map(
              (item: any, index: number) =>
                props.getCustomTableRow(item, snPrefix(recordFrom + index))
            )}
          </TableBody>
        </TblContainer>
        {!disablePagination && <TblFoot {...tblFootProps} />}
      </div>
    );
  };

  // Make table footer reusable
  const TblFoot = (props: any) => {
    const {
      loading,
      totalRecord,
      page,
      pageCount,
      recordTo,
      recordFrom,
      handlePageChange,
    } = props;
    // console.log(recordFrom, recordTo, totalRecord);
    return (
      <>
        {loading && <LinearProgress />}

        <div className={tblClasses.tableBottom}>
          <div className={tblClasses.pageNavigation}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={handlePageChange}
              shape="rounded"
              color="primary"
            />
          </div>

          {totalRecord > 0 ? (
            <Typography>
              Showing {recordFrom} to{" "}
              {recordTo > totalRecord ? totalRecord : recordTo} of {totalRecord}
            </Typography>
          ) : (
            <Typography>No records found</Typography>
          )}
        </div>
      </>
    );
  };

  // returning re-usable components
  return {
    TblHead,
    TblContainer,
    TblFoot,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
    TableContainer,
  };
};
// ---------------------------- <END> re-usable component ends ----------------------------------//

export default useTable;
