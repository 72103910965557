import { Box } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import MyComplaintTable from "./MyComplaintTable";
import { crumbs } from "../MyTeacher/MyTeacherPage";

const MyFeedbackPage = () => {
  return (
    <Box>
      <PageHeader title="My Complains" module={crumbs} />
      <PageWrapper>
        <MyComplaintTable />
      </PageWrapper>
    </Box>
  );
};

export default MyFeedbackPage;
