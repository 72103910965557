import { Grid, InputLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SectionTypeI } from "../../../../actions/Academics/Section/SectionActionTypes";
import {
  AddTeacherComplaint,
  GetTeacherComplaint,
  UpdateTeacherComplaint,
} from "../../../../actions/TeacherDash/TeacherComplain/TeacherComplainAction";
import { TeacherComplaintTypeI } from "../../../../actions/TeacherDash/TeacherComplain/TeacherComplainActionTypes";
import { StudentSearchByTeacher } from "../../../../actions/TeacherDash/TeacherStudentList/TeacherStudentListAction";
import { StudentListTypeI } from "../../../../actions/TeacherDash/TeacherStudentList/TeacherStudentListActionTypes";
import { GetTeachingClass } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassAction";
import { TeachingClassTypeI } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";
import { RootStore } from "../../../../store";
import { CusRadioGroup } from "../../../../components/Reusable/Inputs/Radio";
import CustomizedSelect from "../../../../components/Reusable/Inputs/Select";
import CustomizedTextField from "../../../../components/Reusable/Inputs/TextField";
import FormLayout from "../../../../components/Reusable/Layouts/Form.Layout";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import getFullName from "../../../../components/utils/getFullName";

interface PropsI {
  editData: TeacherComplaintTypeI | null;
  onEditCheck: (value: boolean) => void;
}

interface FormI {
  student: string | number | null;
  class: string | number | null;
  description: string;
}

const TeacherComplainForm = (props: PropsI) => {
  const { editData, onEditCheck } = props;
  const classes = useFormStyles();

  const { register, handleSubmit, errors, setValue, reset } = useForm<FormI>({
    mode: "onChange",
  });
  //State declaration
  const [editID, setEditID] = useState<number | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // const [studentLoading, setStudentLoading] = useState<boolean>(false);

  const [selectedstudent, setSelectedStudent] = useState<any | null>(null);
  const [studentChoices, setStudentChoices] = useState<StudentListTypeI[]>([]);

  const [classChoices, setClassChoices] = useState<TeachingClassTypeI[]>([]);
  const [selectedClass, setSelectedClass] = useState<any | null>(null);

  const [sectionList, setSectionList] = useState<SectionTypeI[]>([]);
  const [selectedRadioValue, setSelectedRadioValue] = useState<string | null>(
    null
  );
  const [filteredStudent, setFilteredStudent] = useState<any>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  //============================== <START> REDUX HOOK <START> ===========================//
  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.teaching_class);

  const studentSelector = useSelector(
    (state: RootStore) => state.teacher_studentList
  );
  const add_or_update = useSelector((state: RootStore) => state.teacher_complain.add_or_update)

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    dispatch(GetTeachingClass());
    dispatch(GetTeacherComplaint());
  }, []);

  useEffect(() => {
    if (editData != null) {
      handleEditTableData(editData);
    }
    // editData != null && handleEditTableData(editData);
  }, [editData]);

  useEffect(() => {
    const student = studentSelector?.student_list;
    student && setStudentChoices(student);
    editMode && handleSelectStudent(editData);
  }, [studentSelector, editMode]);

  const STUDENT_CHOICES = studentChoices?.map((item: StudentListTypeI) => ({
    id: item.id,
    value: getFullName(item.student_first_name, item.student_last_name),
  }));

  const sectionTypeList = sectionList?.map(
    (element: SectionTypeI, index: number) => ({
      id: element.id,
      name: element.name,
    })
  );

  const CLASS_CHOICES = classSelector.teaching_class.map(
    (item: any, index: number) => ({
      id: item.id,
      value: item.grade_name,
      section: item.section,
      grade: item.grade,
      grade_name: item.grade_name,
    })
  );

  // console.log("section", sectionList);

  const handleSectionChange = (val: any) => {
    if (val != null) {
      setSelectedRadioValue(val);
    }
  };

  useEffect(() => {
    if (selectedClass?.grade && selectedRadioValue != null) {
      dispatch(
        StudentSearchByTeacher(selectedClass?.grade, selectedRadioValue)
      );
    } else if (selectedClass?.grade) {
      dispatch(StudentSearchByTeacher(selectedClass?.grade));
    }
  }, [selectedRadioValue, selectedClass?.grade]);

  //============================== <END> REDUX HOOK <END> ===========================//

  //============================== <START> EVENT HANDLERS <START> ===========================//

  const handleClassChange = (value: TeachingClassTypeI | null) => {
    if (value === null) {
      setSectionList([])
      return;
    }
    populateSectionRadio(value?.section);
    setSelectedClass(value);
    setSelectedStudent(null);
  };

  const populateSectionRadio = (value: SectionTypeI[] | null) => {
    value != null ? setSectionList(value) : setSectionList([]);
    setSelectedRadioValue(null);
  };

  const handleStudentChange = (value: StudentListTypeI | null) => {
    setSelectedStudent(value);
  };

  const onSubmit = (data: FormI) => {
    setIsBtnLoading(true);
    if (
      selectedstudent != null &&
      selectedClass != null &&
      selectedRadioValue != null
    ) {
      // const makeSubmission = (callback: () => void) => {
      const studentComplaintData = {
        grade: selectedClass.grade,
        section: selectedRadioValue,
        student: selectedstudent.id,
        description: data.description,
      };
      if (editMode) {
        editID != null &&
          dispatch(UpdateTeacherComplaint(editID, studentComplaintData));
      } else {
        dispatch(AddTeacherComplaint(studentComplaintData));
      }

      // callback();
      // };
    }
  };

  const handleEditTableData = (data: TeacherComplaintTypeI) => {
    setEditMode(true);
    setEditID(data.id);
    setSelectedRadioValue(data.section);

    const new_class = CLASS_CHOICES.find(
      (element: any) => element.grade === data.grade
    );

    const new_student = STUDENT_CHOICES?.find(
      (element: any) => element.id === data.student
    );

    new_class && setSelectedClass(new_class);

    new_class && setSectionList(new_class.section);

    setValue("description", data.description);
  };

  const handleSelectStudent = (data: any) => {
    const new_student = STUDENT_CHOICES?.find(
      (element: any) => element.id === data.student
    );
    new_student && setSelectedStudent(new_student);
  };

  //============================== <END> EVENT HANDLERS <START> ===========================//

  // ====================================================================================//

  const handleReset = () => {
    setEditMode(false);
    setEditID(null);
    setSelectedStudent(null);
    setSelectedClass(null);
    setSelectedRadioValue(null);
    setSectionList([]);
    reset();
    onEditCheck(false);
  };

  return (
    <FormLayout
      teacher
      title={editMode ? "Edit Feedback" : "Add Feedback"}
      onSubmit={handleSubmit(onSubmit)}
      editMode={editMode}
      loading={loading}
      onClick={!isBtnLoading && handleReset}
      add_or_update={isBtnLoading}
    >
      <Grid item xs={12} className={classes.formWrapper}>
        <CustomizedSelect
          label="Select Class"
          required
          name="class"
          options={CLASS_CHOICES}
          value={selectedClass}
          setValue={handleClassChange}
          error={errors["class"] ? "Please select Class" : ""}
          inputRef={register({ required: true })}
        />
      </Grid>
      <Grid item xs={12} className={classes.formWrapper}>
        <InputLabel>
          Section
          <span style={{ color: "red" }}>*</span>
        </InputLabel>
        {sectionList && sectionList.length ? (
          <>
            <CusRadioGroup
              // label="Section"
              items={sectionTypeList}
              value={selectedRadioValue}
              setValue={handleSectionChange}
            />
          </>
        ) : (
          <>
            <span className={classes.sectionUnavailableText}>
              ** No records found
            </span>
          </>
        )}
      </Grid>
      <Grid item xs={12} className={classes.formWrapper}>
        <CustomizedSelect
          label="Select Student"
          name="student"
          options={STUDENT_CHOICES}
          value={selectedstudent}
          setValue={handleStudentChange}
          required
          error={errors["student"] ? "Student must be selected" : ""}
          inputRef={register({ required: true })}
        />
      </Grid>

      <Grid item xs={12} className={classes.formWrapper}>
        <CustomizedTextField
          label="Description"
          placeholder="description"
          name="description"
          multiline
          rows={4}
          required
          error={errors["description"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
      </Grid>
    </FormLayout>
  );
};

export default TeacherComplainForm;
