import "moment";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetTeachingClass } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassAction";
import { ClassTypeI } from "../../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../../actions/Academics/Section/SectionActionTypes";
import { RootStore } from "../../../../store";
import SearchIcon from "@material-ui/icons/Search";

import { FormResetButton } from "../../../../components/Reusable/Buttons/FormButton";
import CustomizedSelect, {
  InputBox,
  RowSelect,
} from "../../../../components/Reusable/Inputs/Select";
import FullWidthFormLayout from "../../../../components/Reusable/Layouts/FullWidthForm.layout";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import CustomizedNepaliDatePicker, {
  RowNepaliDatePicker,
} from "../../../../components/Reusable/Inputs/NepaliDatePicker";
import { dateConverterBsToAd } from "../../../../components/utils/dateConverter";
import moment from "moment";
import { GetClassAttendance } from "../../../../actions/TeacherDash/ClassAttendance/ClassAttendanceAction";
import { AnyNsRecord } from "dns";
import {
  ResetButton,
  SubmitButton,
} from "../../../../components/Reusable/Buttons/SearchButton";
import { CUR_NEPALI_DATE } from "../../../../components/utils/nepaliDateUtils";
import { filterStyle } from "../../components/Styles/TeacherDashboardStyles";
import { TeachingClassTypeI } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";

interface PropsI {
  onSearch: (search_param: SearchParamI | null) => void;
}

interface SearchParamI {
  grade: string;
  date: string;
  section: string;
}

export const SelectAttendance = (props: PropsI) => {
  const { onSearch } = props;
  const today = new Date();
  const classes = useFormStyles();

  ////////////////// State declarations ///////////////////////////////////////////

  const [selectedClass, setSelectedClass] = useState<any | null>(null);
  const [classChoices, setClassChoices] = useState<any>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [date, setDate] = useState<string>(CUR_NEPALI_DATE);
  ////////////////////////////////////////////////////////////////////////////////

  //======================================== <START> REACT HOOKS <START> ============================//
  const { errors, setError, clearErrors } = useForm();
  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.teaching_class);

  useEffect(() => {
    dispatch(GetTeachingClass());
  }, []);

  useEffect(() => {
    classSelector.teaching_class &&
      setClassChoices(classSelector.teaching_class);
  }, [classSelector]);

  useEffect(() => {
    if (selectedClass == null && classChoices.length > 0) {
      handleClassChange(classChoices[0]);
      const sectionChoice = classChoices[0].section.length
        ? classChoices[0].section[0]
        : null;
      setSelectedSection(sectionChoice);
    }
  }, [classChoices]);

  useEffect(() => {
    handleSearch();
  }, [selectedClass, date, selectedSection]);

  //======================================== <END> REACT HOOKS <END> ============================//

  //======================================== <START> EVENT HANDLERS <START> ============================//

  const handleClassChange = (value: TeachingClassTypeI | null) => {
    if (value != null) {
      setSelectedClass(value);
      populateSectionChoices(value.section);
      clearErrors("grade");
    } else {
      setSectionDisabler(true);
    }
    setSelectedSection(null);
  };

  const populateSectionChoices = (sections: SectionTypeI[] | null) => {
    setSectionDisabler(false);
    if (sections === null) {
      setSectionDisabler(true)
    } else {
      setSectionChoices(sections)
    }
  };

  const handleSearch = () => {
    if (selectedClass) {
      const request_query = {
        grade: selectedClass.grade,
        date: date
          ? dateConverterBsToAd(date)
          : moment(today).format("YYYY-MM-DD"),
        section: selectedSection ? selectedSection.id : "",
      };

      dispatch(GetClassAttendance(request_query));
      onSearch(request_query);
    } else {
      setError("grade", {
        type: "required",
        message: "This field is required",
      });
    }
  };

  const handleFilter = () => {
    onSearch(null);
    setSelectedClass(null);
    setSelectedSection(null);
    setDate(CUR_NEPALI_DATE);
  };
  return (
    <>
      <Paper style={filterStyle}>
        <form>
          <Box className={classes.formTitle}>
            <span>Select Attendance</span>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <InputBox>Class</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                value={selectedClass}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: TeachingClassTypeI | null
                ) => handleClassChange(value)}
                options={classChoices}
                getOptionLabel={(option) => `Class ${option.grade_name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="class_name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={3} className={classes.formWrapperRow}>
              <RowSelect
                label="Class"
                fullWidth
                name="class"
                options={CLASS_CHOICES}
                value={selectedClass}
                placeholder="Label"
                setValue={handleClassChange}
                error={errors["grade"] ? "This field is required" : ""}
              />
            </Grid> */}
            <Grid item xs={3} className={classes.formWrapperRow}>
              <InputBox>Section</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                value={selectedSection}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionTypeI | null
                ) => setSelectedSection(value)}
                options={sectionChoices}
                getOptionLabel={(option) => option.name}
                disabled={sectionDisabler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="section_name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={3} className={classes.formWrapperRow}>
              <RowNepaliDatePicker
                label="Date"
                value={date}
                setValue={setDate}
              />
            </Grid>
            {/* <SubmitButton style={{ marginTop: "5px" }} /> */}
            <ResetButton onClick={handleFilter} style={{ marginTop: "5px" }} />
          </Grid>
        </form>
      </Paper>
    </>
  );
};
