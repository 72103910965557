import {
  Box,
  createStyles,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetStudentSubjects } from "../../../../actions/StudentDash/StudentSubjects/StudentSubjectAction";
import { StudentSubjectI } from "../../../../actions/StudentDash/StudentSubjects/StudentSubjectActionTypes";
import { RootStore } from "../../../../store";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import StudentSyllabusTable from "./StudentSyllabusTable";
import { filterStyle } from "../../StudentDashboardStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // margin: theme.spacing(2, 0, 2, 4),
      backgroundColor: theme.palette.background.paper,
      "& .MuiTypography-body1": {
        fontSize: "15px",
        textAlign: "left",
      },
    },
    list: {
      borderRadius: "8px",
      "&.Mui-selected": {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  })
);

function ListItemLink(props: ListItemProps<"a", { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

const StudentSyllabusManage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const classess = useTableStyles();

  const [subjectList, setSubjectList] = useState<StudentSubjectI[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [tempSubject, setTempSubject] = useState<string>();

  useEffect(() => {
    dispatch(GetStudentSubjects());
  }, []);

  const subjectSelector = useSelector(
    (state: RootStore) => state.student_subjects
  );

  useEffect(() => {
    const data = subjectSelector.subjects;
    setSubjectList(data);
    tempSubject == undefined && setTempSubject(data[0]?.subject);
  }, [subjectSelector]);

  const handleListItemClick = (
    event: any,
    index: number,
    item: StudentSubjectI
  ) => {
    setSelectedIndex(index);
    setTempSubject(item.subject);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <Paper style={filterStyle}>
            <Box fontWeight={500} component="h6" fontSize={18} my={0}>
              Subjects
            </Box>
            <div className={classes.root}>
              {subjectList &&
                subjectList.map((item: StudentSubjectI, index: number) => {
                  return (
                    <List key={index} component="nav" aria-label="subject list">
                      <ListItemLink
                        className={classes.list}
                        selected={selectedIndex === index}
                        onClick={(event) =>
                          handleListItemClick(event, index, item)
                        }
                      >
                        <ListItemText primary={item.subject_name} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="comments">
                            <ChevronRight
                              color={
                                selectedIndex === index
                                  ? "secondary"
                                  : "inherit"
                              }
                            />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItemLink>
                    </List>
                  );
                })}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <StudentSyllabusTable subject={tempSubject} />
        </Grid>
      </Grid>
    </>
  );
};

export default StudentSyllabusManage;
