import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Paper,
    Grid,
    Typography,
    Tooltip,
} from '@material-ui/core';
import {
    Add,
    PictureAsPdfOutlined,
} from '@material-ui/icons';
import { useTable } from '../../../../components/Reusable';
import {
    ItemDeleteButton,
    DownloadButton,
} from '../../../../components/Reusable/Buttons/TableButton';
import AddNewButton from '../../../../components/Reusable/Buttons/AddNewButton';
import { useTableStyles } from '../../../../components/Styles/TableStyles';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import CustomizedDialogs from '../../../../components/Reusable/Dialogs/DeleteDialog';
import { RootStore } from '../../../../store';
import UploadFile from './UploadFile'
import { DeleteAssignmentFiles, GetAssignmentFiles } from "../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction"
// ---------------------------- <START> Table Headings Data Starts ----------------------------------//
export interface HeadCellsI {
    id: string;
    label: string;
}
interface DocumentTypeI {
    id: string;
    title: string;
    document: any;
}
const headCells: HeadCellsI[] = [
    { id: 'file', label: 'File' },
    { id: 'action', label: 'Action' },
];
// ---------------------------- <END> Table Headings Ends 

const AssignmentUpload = (props: any) => {
    const classes = useTableStyles();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>()
    // State Declarations
    const [tableData, setTableData] = useState<DocumentTypeI[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [editData, setEditData] = useState<any>();
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [itemId, setItemId] = useState<string | null>(null);
    // Retrieving re-usable components from useTable
    const { StyledTableCell, TableContainer, StyledTableRow } =
        useTable(headCells);

    const { document, added } = useSelector((state: RootStore) => state.teacher_assignment)

    console.log("added", added)

    useEffect(() => {
        setTableData(document);
    }, [document]);

    useEffect(() => {
        added && dispatch(GetAssignmentFiles(id))
    }, [added])

    const handleUpload = () => {
        setOpenPopup(true);
    };
    const handleClose = (value: boolean) => {
        setOpenPopup(value);
    };
    const handleModalClose = (value: boolean) => {
        setDeleteModalOpen(value);
    };
    const handleDeleteModal = (value: boolean, id: string) => {
        setItemId(id);
        setDeleteModalOpen(value);
    };
    const onDeleteConfirmed = (confirmed: boolean) => {
        confirmed && itemId != null && dispatch(DeleteAssignmentFiles(itemId));
        setDeleteModalOpen(false);
    };

    const getCustomTableRow = (item: any) => {
        return (
            <StyledTableRow key={item.id}>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.file.split('.').pop() == 'pdf' ? (
                        <PictureAsPdfOutlined style={{ color: '#c91a69' }} />
                    ) : (
                        <img src={item.file} alt="" height="30px" width="30px" />
                    )}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {props.hasDeleteBtn && (
                        <ItemDeleteButton
                            onClick={(
                                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                            ) => handleDeleteModal(true, item.id)}
                        ></ItemDeleteButton>
                    )}
                </StyledTableCell>
            </StyledTableRow>
        );
    };
    return (
        <Paper elevation={0} className={classes.roottable}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h6">Documents</Typography>
                </Grid>
                {props.hasUpload && (
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-end">
                            <Tooltip title="Upload New file">
                                <AddNewButton
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<Add />}
                                    onClick={handleUpload}
                                >
                                    Upload New file
                                </AddNewButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <TableContainer
                loading={loading}
                headCells={headCells}
                items={document}
                getCustomTableRow={getCustomTableRow}
                disableSearch
                disablePrint
            />
            <Popups
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                onClose={handleClose}
                title="Upload a file"
                colored
                scroll="body"
                maxWidth="sm"
            >
                <UploadFile />
            </Popups>
            <CustomizedDialogs
                open={deleteModalOpen}
                onClose={handleModalClose}
                deleteConfirmed={onDeleteConfirmed}
                dialogTitle="Are you sure to delete this record?"
                okText="Delete"
            />
        </Paper>
    )
}

export default AssignmentUpload