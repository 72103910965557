// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  LinearProgress,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { ExamScheduleTypeI } from "../../../../actions/Examination/ExamSchedule/ExamScheduleActionTypes";
import TableActionButtons from "../../../../components/Reusable/Buttons/TableActionButtons";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { RootStore } from "../../../../store";
import useTable from "../../../../components/Reusable/useStudentTable";
import { tblStyle } from "../../components/Styles/TeacherDashboardStyles";

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface PropsI {
  searchTitle: SearchDataI | null;
}

interface SearchDataI {
  grade: string;
  section: string;
}

interface HeadCellsI {
  id: string;
  label: string;
  filter?: boolean;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "subject_name", label: "Subject", filter: true },
  { id: "date", label: "Date" },
  { id: "start_time", label: "Start Time" },
  { id: "duration", label: "Duration(in hour)" },
  { id: "exam_type", label: "Exam Type" },
  { id: "full_mark", label: "Full Mark" },
  { id: "pass_mark", label: "Pass Mark" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const ExamScheduleTable = (props: PropsI) => {
  const { searchTitle } = props;
  const classes = useTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<ExamScheduleTypeI[]>([]);
  const [tempTableData, setTempTableData] = useState<ExamScheduleTypeI[]>([]);

  //==================================== <START> REACT HOOKS <START> ================================//
  const examScheduleSelector = useSelector(
    (state: RootStore) => state.exam_schedule
  );

  useEffect(() => {
    const exam_data = examScheduleSelector.exam_subjects;
    setLoading(examScheduleSelector.loading);
    if (searchTitle) {
      setTableData(exam_data);
    }
  }, [examScheduleSelector]);

  console.log(searchTitle)

  useEffect(() => {
    !searchTitle && setTableData([])
  }, [searchTitle])
  //==================================== <END> REACT HOOKS <END> ================================//

  //==================================== <START> EVENT HANDLERS <START> ================================//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //==================================== <END> EVENT HANDLERS <END> ================================//

  const getCustomTableRow = (item: ExamScheduleTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item?.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item?.exam_subject_info?.exam_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item?.exam_subject_info?.start_time}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item?.exam_subject_info?.duration}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item?.exam_subject_info?.exam_info_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item?.subject_info?.full_marks_theory}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item?.subject_info?.pass_marks_theory}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper style={tblStyle}>
        <TableContainer
          label={
            searchTitle?.grade
              ? `Exam Schedule For Class ${searchTitle.grade} ${searchTitle.section}`
              : "Exam Schedule"
          }
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="subject_name"
          hiddenCols={[-1]}
        />
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default ExamScheduleTable;
