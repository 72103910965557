import React, { FormEventHandler, useEffect, useState } from 'react';
import { Button, Grid, InputLabel, Paper, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { GetClasses } from '../../../../actions/Academics/Class/ClassAction';
import { GetStudentAcademicInfo } from '../../../../actions/ParentsDash/AcademicInfo/StudentAcademicInfoAction';
import { RootStore } from '../../../../store';
import CustomizedSelect from '../../../../components/Reusable/Inputs/Select';
import FullWidthFormLayout from '../../../../components/Reusable/Layouts/FullWidthForm.layout';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import getFullName from '../../../../components/utils/getFullName';
import { MONTH_CHOICES } from '../../../../common/json.constant';
import { GetDates } from '../../../../actions/Dates/DatesAction';
import { GetChildrenAttendanceReport } from '../../../../actions/ParentsDash/AttendanceReport/AttendanceReportAction';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../../../components/utils/dateConverter';
import { Tupple } from '../../../../common/json.constant';
import { AnyMxRecord } from 'dns';
import SearchFormHeaderLayout from '../../../../components/Reusable/Layouts/SearchFormHeaderLayout';
import { filterStyle } from '../../ParentDashboardStyles';
import { SubmitButton } from '../../../../components/Reusable/Buttons/SearchButton';
import { GetChildren } from "../../../../actions/ParentsDash/ViewChild/ViewChildAction";

interface FormI {
  month_name: string | null;
  children_name: number | null;
}

const SearchChildAttendace = (props: any) => {
  const classes = useFormStyles();

  const { register, errors, setError } = useForm<FormI>();

  const [month, setMonth] = useState<Tupple | null>(null);
  const [children, setChildren] = useState<Tupple | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number | null>(null);

  const dispatch = useDispatch();
  const studentAcademicState = useSelector(
    (state: RootStore) => state.student_academic
  );
  const dateSelector = useSelector(
    (state: RootStore) => state.month_dates.data
  );

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetChildren());

    const utc = new Date().toJSON().slice(0, 10);
    setCurrentMonth(parseInt(dateConverterAdToBs(utc).substring(5, 7)) - 1);
    dispatch(GetDates(parseInt(dateConverterAdToBs(utc).substring(5, 7))));
  }, []);

  useEffect(() => {
    if (studentAcademicState != null) {
      setChildren(SELECT_CHILDREN && SELECT_CHILDREN[0]);
    }
  }, [studentAcademicState]);

  useEffect(() => {
    if (currentMonth != null) {
      dispatch(GetDates(currentMonth + 1));
    }
  }, [currentMonth]);

  useEffect(() => {
    if (studentAcademicState != null && currentMonth != null) {
      if (SELECT_CHILDREN[0] != null) {
        dispatch(GetDates(currentMonth + 1));
        setMonth(MONTH_CHOICES[currentMonth]);
        setChildren(SELECT_CHILDREN[0]);
        if (SELECT_CHILDREN[0] != null && dateSelector.dates != null) {
          const searchData = {
            student_id: parseInt(SELECT_CHILDREN[0] && SELECT_CHILDREN[0].key),
            from_date: dateConverterBsToAd(dateSelector.dates[0]),
            to_date: dateConverterBsToAd(
              dateSelector.dates[dateSelector.dates.length - 1]
            ),
          };
          dispatch(GetChildrenAttendanceReport(searchData));
        }
      }
    }
  }, [studentAcademicState, currentMonth]);

  useEffect(() => {
    props.setChildrenName(children?.value);
  }, [children]);

  useEffect(() => {
    props.setMonthName(month?.value);
  }, [month]);

  const SELECT_CHILDREN: any[] = studentAcademicState.student_academics.map(
    (elem: any) => ({
      key: elem.id.toString(),
      value: getFullName(elem.student_first_name, elem.student_last_name),
    })
  );

  const handleChildren = (value: Tupple | null) => {
    setChildren(value);
  };

  const handleMonthChange = (value: Tupple) => {
    if (value != null) {
      setMonth(value);
      dispatch(GetDates(value.key));
    } else {
      setMonth(null);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!children) {
      setError('children_name', {
        type: 'required',
        message: 'Children is required',
      });
    }

    if (!month) {
      setError('month_name', {
        type: 'required',
        message: 'Month is required',
      });
    }

    if (children && month) {
      const searchData = {
        student_id: parseInt(children?.key),
        from_date: dateConverterBsToAd(dateSelector.dates[0]),
        to_date: dateConverterBsToAd(
          dateSelector.dates[dateSelector.dates.length - 1]
        ),
      };
      dispatch(GetChildrenAttendanceReport(searchData));
    }
  };

  const handleReset = () => {
    setChildren(null);
    setMonth(null);
    setCurrentMonth(null);
    dispatch(
      GetChildrenAttendanceReport({
        student_id: '',
        from_date: '',
        to_date: '',
      })
    );
  };

  return (
    <>
      <Paper className={classes.pageContentBox} style={filterStyle}>
        <SearchFormHeaderLayout title="Attendance Search" reset={handleReset} />

        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CustomizedSelect
                label="Select Children"
                name="children_name"
                options={SELECT_CHILDREN}
                value={children}
                setValue={handleChildren}
                required
                error={
                  errors['children_name'] && errors['children_name'].message
                }
                inputRef={register({ required: true })}
                placeholder="Label"
              />
            </Grid>
            <Grid item xs={3}>
              <CustomizedSelect
                label="Choose Month"
                name="month_name"
                options={MONTH_CHOICES}
                value={month}
                setValue={handleMonthChange}
                required
                error={errors['month_name'] && errors['month_name'].message}
                inputRef={register({ required: true })}
              />
            </Grid>
            <SubmitButton style={{ marginTop: '5px' }} />
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default SearchChildAttendace;
