import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_EXAM_SCHEDULE_LOADING,
  GET_EXAM_SCHEDULE_ERROR,
  GET_EXAM_SCHEDULE_SUCCESS,
  ExamScheduleDispatchTypes,
  GET_EXAM_SUBJECTS_LOADING,
  GET_EXAM_SUBJECTS_SUCCESS,
  GET_EXAM_SUBJECTS_ERROR,
  DELETE_EXAM_SCHEDULE_SUCCESS,
  DELETE_EXAM_SCHEDULE_ERROR,
  DELETE_EXAM_SCHEDULE_LOADING,
  UPDATE_EXAM_SCHEDULE_LOADING,
  UPDATE_EXAM_SCHEDULE_SUCCESS,
  UPDATE_EXAM_SCHEDULE_ERROR,
} from "./ExamScheduleActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: string | null;
  section: string | null;
  exam_type: string;
  exam_date: string;
  subject: string;
  start_time: string;
  end_time: string;
  full_marks: number;
  pass_marks: number;
}

export const GetExamSchedules =
  (examInfo: string, grade: string, section: string) =>
  async (dispatch: Dispatch<ExamScheduleDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_EXAM_SCHEDULE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/exam-subject/?exam_info=${examInfo}&grade=${grade}&section=${section}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_EXAM_SCHEDULE_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_EXAM_SCHEDULE_ERROR,
      });
      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Server Error",
          },
        });
      }
    }
  };

export const GetExamSubjects =
  (examInfo: string, grade: string, section: string) =>
  async (dispatch: Dispatch<ExamScheduleDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_EXAM_SUBJECTS_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/exam-subject/?exam_info=${examInfo}&grade=${grade}&section=${section}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_EXAM_SUBJECTS_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_EXAM_SUBJECTS_ERROR,
      });
      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Server Error",
          },
        });
      }
    }
  };

export const UpdateExamSchedule =
  (
    exam_info: string,
    grade: string,
    section: string,
    post_data: RequestDataI
  ) =>
  async (dispatch: Dispatch<ExamScheduleDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_EXAM_SCHEDULE_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/exam-subject/save_bulk_exam_subject_info/?exam_info=${exam_info}&grade=${grade}`,
        post_data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_EXAM_SCHEDULE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Exam Schedule Updated Successfully",
        },
      });

      const sche = await axios.get(
        `${ADMIN_API_URL}/exam-subject/?exam_info=${exam_info}&grade=${grade}&section=${section}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_EXAM_SCHEDULE_SUCCESS,
        payload: sche.data,
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_EXAM_SCHEDULE_ERROR,
      });
      try {
        const err_obj = error?.response?.data?.error;
        const err_msg = err_obj[Object.keys(err_obj)[0]][0];
        err_msg != undefined &&
          dispatch({
            type: SET_SNACKBAR,
            payload: {
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: err_msg,
            },
          });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Exam Schedule Update Failed",
          },
        });
      }
    }
  };

export const DeleteExamSchedule =
  (id: string, grade: string, section: string, examInfo: string) =>
  async (dispatch: Dispatch<ExamScheduleDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_EXAM_SCHEDULE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/exam-subject/${id}/?grade=${grade}&section=${section}&exam_info=${examInfo}`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_EXAM_SCHEDULE_SUCCESS,
        payload: { id: id.toString() },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Exam Schedule Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_EXAM_SCHEDULE_ERROR,
      });
    }
  };
