import React, { useEffect, useState } from "react";
import { Chip, Paper } from "@material-ui/core";
import { HeadCellsI } from "../../../../actions";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { ItemAddButton } from "../../../../components/Reusable/Buttons/TableButton";
import { dateConverterAdToBs } from "../../../../components/utils/dateConverter";
import { LiveClassTypeI } from "../../../../actions/TeacherDash/TeacherLiveClass/TeacherLiveClassActionTypes";
import getFullName from "../../../../components/utils/getFullName";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../store";
import { GetStudentLiveClass } from "../../../../actions/StudentDash/StudentLiveClass/StudentLiveAction";
import TakeAttendanceAlert from "./TakeAttendanceAlert";
import useTable from "../../../../components/Reusable/useStudentTable";
import { tblStyle } from "../../StudentDashboardStyles";

const headCells: HeadCellsI[] = [
  { id: "teacher", label: "Teacher", align: "left" },
  { id: "subject", label: "Subject" },
  { id: "class_date", label: "Start Date", filter: true },
  { id: "start_time", label: "Start Time", filter: true },
  { id: "end_time", label: "End Time" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const StudentLiveClassList = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [liveClass, setLiveClass] = React.useState<any>(null);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //*-------------------------------------STATE MANAGEMENT--------------------------*//
  const [tableData, setTableData] = useState<any>([]);

  //*-----------------------------------------REDUX MANAGEMENT---------------------------*//
  const LiveClassSelector = useSelector(
    (state: RootStore) => state.student_live_classes
  );

  useEffect(() => {
    dispatch(GetStudentLiveClass());
  }, []);

  useEffect(() => {
    if (LiveClassSelector?.student_live_class != null) {
      const items = LiveClassSelector.student_live_class;
      setTableData(items);
    }
  }, [LiveClassSelector]);

  const handleJoinClass = (item: any) => {
    setLiveClass(item);
    setOpen(true);
  };

  const getCustomTableRow = (item: LiveClassTypeI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.teacher_first_name &&
            item.teacher_last_name &&
            getFullName(item.teacher_first_name, item.teacher_last_name)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.subject_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.class_date
            ? dateConverterAdToBs(item.class_date)
            : "Regular Class"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.start_time}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.end_time}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.is_completed ? (
            <Chip
              color="primary"
              size="small"
              label="Completed"
              className={classes.chipGreen}
            />
          ) : item.is_ongoing ? (
            <Chip
              color="primary"
              size="small"
              label="Running"
              className={classes.chipBlue}
            />
          ) : (
            <Chip
              color="primary"
              size="small"
              label="Upcoming"
              className={classes.chipRed}
            />
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemAddButton
            onClick={() => handleJoinClass(item)}
            title="Join class"
            disabled={item.is_completed || !item.is_ongoing}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper style={tblStyle} className={classes.root}>
        <TableContainer
          label="Live Classes List"
          items={tableData}
          headCells={headCells}
          rowsPerPage={8}
          getCustomTableRow={getCustomTableRow}
          search_name="subject_name"
          loading={LiveClassSelector.loading}
        />

        <TakeAttendanceAlert open={open} setOpen={setOpen} item={liveClass} />
      </Paper>
    </>
  );
};

export default StudentLiveClassList;
