import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_DASHBOARD_DATA_LOADING,
  GET_STUDENT_DASHBOARD_DATA_ERROR,
  GET_STUDENT_DASHBOARD_DATA_SUCCESS,
  GET_STUDENT_DASHBOARD_DATE_LOADING,
  GET_STUDENT_DASHBOARD_DATE_ERROR,
  GET_STUDENT_DASHBOARD_DATE_SUCCESS,
  StudentDashboardDataDispatchTypes,
} from "./StudentDashboardActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { STUDENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetStudentDashboardData =
  (class_date?: string) =>
  async (
    dispatch: Dispatch<StudentDashboardDataDispatchTypes | SetSnackBarI>
  ) => {
    try {
      dispatch({
        type: GET_STUDENT_DASHBOARD_DATE_LOADING,
      });

      let url = `${STUDENT_API_URL}/dashboard/`;
      if (class_date) {
        url = `${STUDENT_API_URL}/dashboard/?class_date=${class_date}`;
      }

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_STUDENT_DASHBOARD_DATE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_DASHBOARD_DATE_ERROR,
      });
    }
  };

export const GetStudentDashboardDays =
  (days?: string) =>
  async (
    dispatch: Dispatch<StudentDashboardDataDispatchTypes | SetSnackBarI>
  ) => {
    try {
      dispatch({
        type: GET_STUDENT_DASHBOARD_DATA_LOADING,
      });

      let url = `${STUDENT_API_URL}/dashboard/`;
      if (days) {
        url = `${STUDENT_API_URL}/dashboard/?day=${days}`;
      }

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_STUDENT_DASHBOARD_DATA_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_DASHBOARD_DATA_ERROR,
      });
    }
  };
