export const GET_STUDENT_ENOTES_CATEGORIES_LOADING =
  "GET_STUDENT_ENOTES_CATEGORIES_LOADING";
export const GET_STUDENT_ENOTES_CATEGORIES_ERROR =
  "GET_STUDENT_ENOTES_CATEGORIES_ERROR";
export const GET_STUDENT_ENOTES_CATEGORIES_SUCCESS =
  "GET_STUDENT_ENOTES_CATEGORIES_SUCCESS";

export const GET_STUDENT_ENOTES_LOADING = "GET_STUDENT_ENOTES_LOADING";
export const GET_STUDENT_ENOTES_ERROR = "GET_STUDENT_ENOTES_ERROR";
export const GET_STUDENT_ENOTES_SUCCESS = "GET_STUDENT_ENOTES_SUCCESS";

export type StudentEnotesI = {
  id: string;
  grade: string;
  grade_name: string;
  section: string | null;
  section_name: string | null;
  subject: string;
  subject_name: string;
  category: string;
  category_name: string;
  title: string;
  meta_description: string;
  enote_description: string;
  teacher: number;
  teacher_first_name: string;
  teacher_last_name: string;
  reference_links: string[];
  enote_documents: EnotesDocsI[];
};

export type StudentEnotesCategoryI = {
  id: string;
  name: string;
  description: string;
};

interface EnotesDocsI {
  id: string;
  enote: string;
  document: string;
}
//===============================<START>GET Student Enotes <START>======================//

export interface GetStudentEnotesLoading {
  type: typeof GET_STUDENT_ENOTES_LOADING;
}

export interface GetStudentEnotesError {
  type: typeof GET_STUDENT_ENOTES_ERROR;
}

export interface GetStudentEnotesSuccess {
  type: typeof GET_STUDENT_ENOTES_SUCCESS;
  payload: StudentEnotesI[];
}

//===============================<END>GET Student Enotes <END>======================//

//===============================<START>GET Student Enotes Categories <START>======================//

export interface GetStudentEnotesCategoriesLoading {
  type: typeof GET_STUDENT_ENOTES_CATEGORIES_LOADING;
}

export interface GetStudentEnotesCategoriesError {
  type: typeof GET_STUDENT_ENOTES_CATEGORIES_ERROR;
}

export interface GetStudentEnotesCategoriesSuccess {
  type: typeof GET_STUDENT_ENOTES_CATEGORIES_SUCCESS;
  payload: StudentEnotesCategoryI[];
}

//===============================<END>GET Student Enotes Categories <END>======================//

export type StudentEnotesDispatchTypes =
  | GetStudentEnotesCategoriesLoading
  | GetStudentEnotesCategoriesError
  | GetStudentEnotesCategoriesSuccess
  | GetStudentEnotesLoading
  | GetStudentEnotesError
  | GetStudentEnotesSuccess;
