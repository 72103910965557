import React from 'react'
import { makeStyles, Tabs, Tab, Typography, Box } from "@material-ui/core";
import FeeType from "../../../../../components/Student/StudentProfile/File/FeeType";
import { TabPanel } from './ChildInfoTabs'
import PaymentHistory from './PaymentHistory'
import PaidChildrenFee from './PaidChildrenFee';
import InvoiceChildrenFee from './InvoiceChildrenFee';

const useStyles = makeStyles({
    root: {
        marginTop: "10px",
        paddingRight: "10px",
        "& .MuiTab-labelIcon": {
            minHeight: "40px",
            paddingTop: "6px!important",
        },
        "& .MuiTab-root": {
            fontSize: "14px",
            textTransform: "none",
            minWidth: "110px",
            maxWidth: "200px",
            height: "30px",
            letterSpacing: "0.01071em",
        },
        "& .MuiTabs-flexContainer": {
            borderBottom: "1px solid #ddd",
        },
        "& .MuiBox-root": {
            padding: "10px 0px 0px 0px",
        },
    },
});

const FeeTab = (props: any) => {
    const { data } = props;
    console.log({ data })
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
            // variant="fullWidth"
            >
                <Tab label="Invoices" />
                <Tab label="History" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <InvoiceChildrenFee data={data} value={value} invoiceMode={true} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PaidChildrenFee data={data} value={value} invoiceMode={false} />
            </TabPanel>
        </div >
    )
}

export default FeeTab