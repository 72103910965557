export const GET_STUDENT_ASSIGNMENT_LOADING = "GET_STUDENT_ASSIGNMENT_LOADING";
export const GET_STUDENT_ASSIGNMENT_ERROR = "GET_STUDENT_ASSIGNMENT_ERROR";
export const GET_STUDENT_ASSIGNMENT_SUCCESS = "GET_STUDENT_ASSIGNMENT_SUCCESS";
export const SUBMIT_STUDENT_ASSIGNMENT_LOADING =
  "SUBMIT_STUDENT_ASSIGNMENT_LOADING";
export const SUBMIT_STUDENT_ASSIGNMENT_ERROR =
  "SUBMIT_STUDENT_ASSIGNMENT_ERROR";
export const SUBMIT_STUDENT_ASSIGNMENT_SUCCESS =
  "SUBMIT_STUDENT_ASSIGNMENT_SUCCESS";

export const GET_STUDENT_ASSIGNMENT_BY_ID_LOADING =
  "GET_STUDENT_ASSIGNMENT_BY_ID_LOADING";
export const GET_STUDENT_ASSIGNMENT_BY_ID_ERROR =
  "GET_STUDENT_ASSIGNMENT_BY_ID_ERROR";
export const GET_STUDENT_ASSIGNMENT_BY_ID_SUCCESS =
  "GET_STUDENT_ASSIGNMENT_BY_ID_SUCCESS";

export const GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_LOADING =
  "GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_LOADING";
export const GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_ERROR =
  "GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_ERROR";
export const GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_SUCCESS =
  "GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_SUCCESS";

interface Section {
  id: string;
  name: string;
  created_by: string;
  general_info: number;
}
interface Grade extends Section {
  grade_name: string;
}
interface Subject extends Section {
  credit_hour: number;
  subject_code: string;
  subject_type: number;
  is_optional: boolean;
  subject: string;
}
interface File {
  id: string;
  file: string;
}
export interface StudentAssignmentType {
  id: string;
  grade: Grade;
  section: Section;
  subject: Subject;
  title: string;
  attached_file: File[];
  description: string;
  teacher: string;
  assignment_type: string;
  submission_type: string;
  assign_on: string;
  deadline: string;
  submitted: boolean;
  academic_session: string;
  full_marks: number | null;
  pass_marks: number | null;
  created_on: string;
  created_by: string;
}

//===============================<START>GET STUDENT ASSIGNMENT <START>======================//

export interface GetStudentAssignmentLoading {
  type: typeof GET_STUDENT_ASSIGNMENT_LOADING;
}

export interface GetStudentAssignmentError {
  type: typeof GET_STUDENT_ASSIGNMENT_ERROR;
}

export interface GetStudentAssignmentSuccess {
  type: typeof GET_STUDENT_ASSIGNMENT_SUCCESS;
  payload: StudentAssignmentType[];
}

//===============================<END>GET STUDENT ASSIGNMENT <END>======================//

//===============================<START>GET STUDENT ASSIGNMENT <START>======================//

export interface GetStudentSubmittedAssignmentByIdLoading {
  type: typeof GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_LOADING;
}

export interface GetStudentSubmittedAssignmentByIdError {
  type: typeof GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_ERROR;
}

export interface GetStudentSubmittedAssignmentByIdSuccess {
  type: typeof GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_SUCCESS;
  payload: any;
}

//===============================<END>GET STUDENT ASSIGNMENT <END>======================//

//===============================<START>DELETE STUDENT ASSIGNMENT <START>======================//

export interface GetStudentAssignmentByIdLoading {
  type: typeof GET_STUDENT_ASSIGNMENT_BY_ID_LOADING;
}

export interface GetStudentAssignmentByIdError {
  type: typeof GET_STUDENT_ASSIGNMENT_BY_ID_ERROR;
}

export interface GetStudentAssignmentByIdSuccess {
  type: typeof GET_STUDENT_ASSIGNMENT_BY_ID_SUCCESS;
  payload: any;
}

//===============================<END>DELETE TEACHER ASSIGNMENT <END>======================//

//===============================<START>GET ADD MARKS TEACHER ASSIGNMENT <START>======================//

export interface SubmitStudentAssignmentLoading {
  type: typeof SUBMIT_STUDENT_ASSIGNMENT_LOADING;
}

export interface SubmitStudentAssignmentError {
  type: typeof SUBMIT_STUDENT_ASSIGNMENT_ERROR;
}

export interface SubmitStudentAssignmentSuccess {
  type: typeof SUBMIT_STUDENT_ASSIGNMENT_SUCCESS;
  payload: any;
}

//===============================<END>GET ADD MARKS TEACHER ASSIGNMENT <END>======================//

export type StudentAssignmentDispatchTypes =
  | GetStudentAssignmentLoading
  | GetStudentAssignmentError
  | GetStudentAssignmentSuccess
  | SubmitStudentAssignmentLoading
  | SubmitStudentAssignmentError
  | SubmitStudentAssignmentSuccess
  | GetStudentAssignmentByIdLoading
  | GetStudentAssignmentByIdError
  | GetStudentAssignmentByIdSuccess
  | GetStudentSubmittedAssignmentByIdLoading
  | GetStudentSubmittedAssignmentByIdError
  | GetStudentSubmittedAssignmentByIdSuccess;
