import React, { useState, useEffect } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import StaffCard, {
  StaffCardTypeI,
} from '../../../../../components/Reusable/Dialogs/StaffCard.Modal';
import formatDate from '../../../../../components/utils/formatDate';
import checkImageUrl from '../../../../../components/utils/checkImageUrl';

const TeacherLeaveView = (props: any) => {
  const [staff, setStaff] = useState<StaffCardTypeI | null>(null);

  useEffect(() => {
    const item: StaffCardTypeI = {
      name: props.items.name,
      avatar: checkImageUrl(props.items.photo),
      id: props.items.id,
      designation: 'Teacher',
    };

    setStaff(item);
  }, []);

  const { applied_date, leave_type, leave_date, days, description, remarks } =
    props.items;
  const appliedDate = formatDate(applied_date);
  const leaveDays = moment(days).diff(leave_date, 'days');

  return (
    <>
      <div>
        {staff && <StaffCard staff={staff} />}
        <Divider style={{ marginBottom: '20px' }} />
        <Grid container style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            Leave Type:
          </Grid>

          <Grid item xs={8}>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
              {leave_type}
            </Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            Apply Date:
          </Grid>

          <Grid item xs={8}>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
              {appliedDate}
            </Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            Leave Date:
          </Grid>

          <Grid item xs={8}>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
              {formatDate(leave_date)} - {formatDate(days)} (
              <strong>{leaveDays} days</strong>)
            </Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            Reason:
          </Grid>

          <Grid item xs={8}>
            {description || 'No Description'}
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            Remarks:
          </Grid>

          <Grid item xs={8}>
            {remarks}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TeacherLeaveView;
