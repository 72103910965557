// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTableWithPagination } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  DeleteStaff,
  DisableStaff,
  GetPaginatedStaffs,
  GetStaffs,
} from "../../../actions/HumanResource/Staff/StaffAction";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemDisableButton,
  ItemEditButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
// import getFullName from '../../utils/getFullName';
import getNewFullName from "../../utils/getNewFullName";
import useLoading from "../../../useHook/useLoading";
import UserAvatar from "../../Reusable/Profile/UserAvatar";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import { paginationChoices } from "../../../common/json.constant";
import StaffSearch from "./StaffSearch";
import DisableDialog from "../../Reusable/Dialogs/DisableDialog";
import { useStyles } from "../../Styles/FormStyles";
import { setSnackbar } from "../../../actions/SnackbarAction";
// ----------------------------<END> module imports end ----------------------------------//

// -------------- <START> interface starts --------------------//
interface TableDataI {
  id: string;
  staff_photo: string;
  staff_name: string;
  designation: string;
  department: string;
  phone_no: string;
}

// -------------- <END> interface ends --------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "student_name", label: "Staff Name", filter: true },
  { id: "designation", label: "Designation" },
  { id: "department", label: "Department" },
  { id: "phone_no", label: "Phone No." },
  { id: "action", label: "Action" },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const StaffList: React.FC = () => {
  const classes = useTableStyles();
  const styles = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  // States declaration
  const [tableData, setTableData] = useState<TableDataI[]>([]);

  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const [staffID, setStaffData] = useState([]);
  const [remarks, setRemarks] = useState<string>("");

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  // Staff reducer
  const staffState = useSelector((state: RootStore) => state.staff);
  const { loading, add_or_update, staffs } = staffState;
  const [selectedPagination, setSelectedPagination] = useState<{
    key: string;
    value: string;
  }>({
    key: "10",
    value: "10",
  });

  useEffect(() => {
    if (add_or_update) {
      dispatch(
        GetPaginatedStaffs(
          staffs.current_page || 1,
          Number(selectedPagination.key)
        )
      );
      setRemarks("");
    }
  }, [add_or_update]);

  useEffect(() => {
    if (selectedPagination != null) {
      dispatch(GetPaginatedStaffs(1, Number(selectedPagination.key)));
    }
  }, [selectedPagination]);

  useEffect(() => {
    if (staffState.staffs.results != null) {
      const items = staffState.staffs.results.map((staff: any) => {
        return {
          id: staff.id.toString(),
          staff_photo: staff.photo,
          staff_name: getNewFullName(
            staff.user.first_name,
            staff.user.last_name
          ),
          designation: staff.designation_name || "-",
          department:
            (staff.staffs_academic_info &&
              staff.staffs_academic_info.department_name) ||
            "-",
          phone_no: staff.phone || "-",
          delete_id: staff?.user?.id,
        };
      });

      setTableData(items);
    }
  }, [staffState]);

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
    setRemarks("");
  };

  const handleDeleteModal = (id: string) => {
    setItemId(id);
    setDeleteModalOpen(true);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    if (confirmed && itemId) {
      if (remarks) {
        dispatch(DisableStaff({ staff: itemId, remarks }));
        setDeleteModalOpen(false);
      } else {
        dispatch(setSnackbar(true, "warning", "Please add a remarks"));
      }
    }
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(
      GetPaginatedStaffs(paginationIndex, Number(selectedPagination?.key))
    );
  };

  const handleImport = () => {
    history.push("/staff-import");
  };

  const handleStaffSearch = (data: {
    department_id: string;
    designation_id: string;
    search: string;
  }) => {
    dispatch(
      GetPaginatedStaffs(
        1,
        Number(selectedPagination.key),
        data.department_id,
        data.designation_id,
        data.search
      )
    );
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <UserAvatar name={item.staff_name} src={item.staff_photo} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.designation}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.department}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.phone_no}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton component={Link} to={`staff/profile/${item.id}`} />
          <Link to={`staff/edit/${item.id}`}>
            <ItemEditButton />
          </Link>

          <ItemDisableButton onClick={() => handleDeleteModal(item.id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.rootTableBox}>
      <Typography variant="h6">Staff List</Typography>
      <div>
        <StaffSearch searchStaffParams={(data) => handleStaffSearch(data)} />
      </div>
      <Grid container justifyContent="flex-end">
        <Box fontSize={10} textAlign="center">
          <CustomizedSelect
            label="Table Size"
            name="gender"
            options={paginationChoices}
            value={selectedPagination}
            setValue={(value: { key: string; value: string } | null) =>
              setSelectedPagination(value || { key: "10", value: "10" })
            }
          />
        </Box>
      </Grid>
      <TableContainer
        label="Staff List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        forPagination={staffState?.staffs}
        handlePaginationChange={handlePaginationChange}
        getCustomTableRow={getCustomTableRow}
        onImport={handleImport}
        disableSearch
      />

      <DisableDialog
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      >
        <Grid container justifyContent="center">
          <Grid className={styles.formWrapper} style={{ marginBottom: "2rem" }}>
            <InputLabel>Remarks</InputLabel>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              name="remarks"
              placeholder="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Grid>
        </Grid>
      </DisableDialog>
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default StaffList;
