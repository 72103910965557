import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Grid,
} from "@material-ui/core";
import React from "react";
import defaultPlaceholder from "../../../assets/images/defaultPlaceholder";
import { usefullCardStyles } from "../../Styles/CardStyles";
import TeacherTabs from "../../../dashboard/TeacherDashboard/pages/TeacherProfile/components/TeacherTabs";

function CardfullWidthLayout(props: any) {
  const classes = usefullCardStyles();

  return (
    <>
      <Card className={classes.cardroot} elevation={props.elevation}>
        <Grid container spacing={2}>
          <CardActionArea className={classes.cardbg}></CardActionArea>
          <Grid item xs={3} className={classes.contentbg}>
            <Avatar
              className={classes.profileImg}
              alt=""
              src={
                props.profileImg != null ? props.profileImg : defaultPlaceholder
              }
            />
            <CardContent>
              <Typography
                className={classes.title}
                align="center"
                gutterBottom
                variant="h4"
                component="h3"
              >
                {props.fullName}
              </Typography>
              {props.headData.map((item: any) => {
                return (
                  <div key={item.id} className={classes.content}>
                    <Typography variant="body2" color="inherit" component="p">
                      {item.headCells}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {item.cellData}
                    </Typography>
                  </div>
                );
              })}
            </CardContent>
          </Grid>
          <Grid item xs={9}>
            {props.children}
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default CardfullWidthLayout;
