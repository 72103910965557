import { Box } from "@material-ui/core";
import React from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import AssignmentForm from "./AssignmentForm";

const AssignmentAddPage = () => {
  return (
    <Box>
      <PageHeader
        title="Add Assignment"
      />
      <PageWrapper>
        <AssignmentForm />
      </PageWrapper>
    </Box>
  );
};

export default AssignmentAddPage;
