import {
  Avatar,
  Badge,
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { UserMenuPopoverWrapper } from '../../../../components/Header/UserMenuPopover/UserMenuPopover';
import getFullName from '../../../../components/utils/getFullName';
import { RootStore } from '../../../../store';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '16px 0',
    marginBottom: '16px',
    justifyContent: 'flex-end',
  },
  title: {
    color: '#0f2248',
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 0,
  },
  avatar: {
    boxShadow: '2px 4px 20px 3px rgb(0 0 0 / 12%)',
    border: '2px solid white',
  },
  notificationBadge: {
    color: theme.palette.primary.main,
    borderRadius: 5,
    padding: 5,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '2px 4px 20px 3px rgb(0 0 0 / 12%)',
    cursor: 'pointer',

    '& > span': {
      top: 2,
      right: 2,
      padding: 6,
      borderRadius: '50%',
      backgroundColor: theme.palette.success.main,
    },
  },
}));

const StudentProfileCard = () => {
  const classes = useStyles();
  const authState = useSelector((state: RootStore) => state.auth);
  const profileState = useSelector((state: RootStore) => state.student_profile);

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <UserMenuPopoverWrapper student>
          <Box style={{ display: 'flex', gap: '10px' }}>
            <Box textAlign="right">
              <Typography variant="h6" className={classes.title}>
                {getFullName(
                  authState.user.first_name,
                  authState.user.last_name
                )}
              </Typography>
              <Typography variant="body2" style={{ fontSize: 13 }}>
                {localStorage.getItem('role') || 'Student'}
              </Typography>
            </Box>

            <Box>
              <Avatar
                alt="Remy Sharp"
                src={profileState.student.student_photo}
                sizes="sm"
                className={classes.avatar}
              />
            </Box>
          </Box>
        </UserMenuPopoverWrapper>
      </Grid>
    </Grid>
  );
};

export default StudentProfileCard;
