import {
  GET_MY_SUBJECT_LOADING,
  GET_MY_SUBJECT_ERROR,
  GET_MY_SUBJECT_SUCCESS,
  MySubjectTypeI,
  MySubjectDispatchTypes,
} from "../../actions/TeacherDash/MySubjects/MySubjectsActionTypes";

interface InitialStateI {
  loading: boolean;
  my_subjects: MySubjectTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  my_subjects: [],
};

const MySubjectReducer = (
  state: InitialStateI = initialState,
  action: MySubjectDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_MY_SUBJECT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_MY_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_MY_SUBJECT_SUCCESS:
      return {
        loading: false,
        my_subjects: action.payload,
      };

    default:
      return state;
  }
};

export default MySubjectReducer;
