export const GET_LIVE_CLASS_LOADING = "GET_LIVE_CLASS_LOADING";
export const GET_LIVE_CLASS_ERROR = "GET_LIVE_CLASS_ERROR";
export const GET_LIVE_CLASS_SUCCESS = "GET_LIVE_CLASS_SUCCESS";

export const ADD_LIVE_CLASS_LOADING = "ADD_LIVE_CLASS_LOADING";
export const ADD_LIVE_CLASS_ERROR = "ADD_LIVE_CLASS_ERROR";
export const ADD_LIVE_CLASS_SUCCESS = "ADD_LIVE_CLASS_SUCCESS";

export const UPDATE_LIVE_CLASS_LOADING = "UPDATE_LIVE_CLASS_LOADING";
export const UPDATE_LIVE_CLASS_ERROR = "UPDATE_LIVE_CLASS_ERROR";
export const UPDATE_LIVE_CLASS_SUCCESS = "UPDATE_LIVE_CLASS_SUCCESS";

export const UPDATE_MEET_LOADING = "UPDATE_MEET_LOADING";
export const UPDATE_MEET_ERROR = "UPDATE_MEET_ERROR";
export const UPDATE_MEET_SUCCESS = "UPDATE_MEET_SUCCESS";

export const DELETE_LIVE_CLASS_LOADING = "DELETE_LIVE_CLASS_LOADING";
export const DELETE_LIVE_CLASS_ERROR = "DELETE_LIVE_CLASS_ERROR";
export const DELETE_LIVE_CLASS_SUCCESS = "DELETE_LIVE_CLASS_SUCCESS";

export const GET_ONLINE_CLASS_LOG_LOADING = "GET_ONLINE_CLASS_LOG_LOADING";
export const GET_ONLINE_CLASS_LOG_ERROR = "GET_ONLINE_CLASS_LOG_ERROR";
export const GET_ONLINE_CLASS_LOG_SUCCESS = "GET_ONLINE_CLASS_LOG_SUCCESS";

export interface LiveClassTypeI {
  id?: string;
  subject: string;
  title?: string;
  teacher_first_name?: string | undefined;
  teacher_last_name?: string | undefined;
  subject_name?: string;
  class_date: string | null;
  days: string[] | [];
  start_time: string;
  end_time: string;
  grade: string;
  section: string;
  academic_session?: string;
  link: string;
  is_completed?: boolean;
  is_upcomming?: boolean;
  is_ongoing?: boolean;
}

//===============================<START>GET LIVE CLASSES<START>======================//

export interface GetLiveClassLoading {
  type: typeof GET_LIVE_CLASS_LOADING;
}

export interface GetLiveClassError {
  type: typeof GET_LIVE_CLASS_ERROR;
}

export interface GetLiveClassSuccess {
  type: typeof GET_LIVE_CLASS_SUCCESS;
  payload: LiveClassTypeI[];
}

//===============================<END>GET LIVE CLASSES<END>======================//

//===============================<START>ADD LIVE CLASSES<START>======================//

export interface AddLiveClassLoading {
  type: typeof ADD_LIVE_CLASS_LOADING;
}

export interface AddLiveClassError {
  type: typeof ADD_LIVE_CLASS_ERROR;
  payload: any;
}

export interface AddLiveClassSuccess {
  type: typeof ADD_LIVE_CLASS_SUCCESS;
  payload: LiveClassTypeI;
}

//===============================<END>ADD LIVE CLASSES<END>======================//
//===============================<START>UPDATE LIVE CLASSES<START>======================//

export interface UpdateLiveClassLoading {
  type: typeof UPDATE_LIVE_CLASS_LOADING;
}

export interface UpdateLiveClassError {
  type: typeof UPDATE_LIVE_CLASS_ERROR;
  payload: any;
}

export interface UpdateLiveClassSuccess {
  type: typeof UPDATE_LIVE_CLASS_SUCCESS;
  payload: LiveClassTypeI;
}

//===============================<END>UPDATE LIVE CLASSES<END>======================//

//===============================<START>UPDATE LIVE MEET<START>======================//

export interface UpdateMeetLoading {
  type: typeof UPDATE_MEET_LOADING;
}

export interface UpdateMeetError {
  type: typeof UPDATE_MEET_ERROR;
}

export interface UpdateMeetSuccess {
  type: typeof UPDATE_MEET_SUCCESS;
  payload: { title: string };
}

//===============================<END>UPDATE LIVE MEET<END>======================//
//===============================<START>DELETE LIVE CLASSES<START>======================//

export interface DeleteLiveClassLoading {
  type: typeof DELETE_LIVE_CLASS_LOADING;
}

export interface DeleteLiveClassError {
  type: typeof DELETE_LIVE_CLASS_ERROR;
}

export interface DeleteLiveClassSuccess {
  type: typeof DELETE_LIVE_CLASS_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE LIVE CLASSES<END>======================//

//===============================<START>GET ONLINE CLASSES<START>======================//

export interface GetOnlineClassLogLoading {
  type: typeof GET_ONLINE_CLASS_LOG_LOADING;
}

export interface GetOnlineClassLogError {
  type: typeof GET_ONLINE_CLASS_LOG_ERROR;
}

export interface GetOnlineClassLogSuccess {
  type: typeof GET_ONLINE_CLASS_LOG_SUCCESS;
  payload: any;
}

//===============================<END>GET ONLINE CLASSES<END>======================//

export type LiveClassDispatchTypes =
  | GetLiveClassLoading
  | GetLiveClassError
  | GetLiveClassSuccess
  | AddLiveClassLoading
  | AddLiveClassError
  | AddLiveClassSuccess
  | UpdateLiveClassLoading
  | UpdateLiveClassError
  | UpdateLiveClassSuccess
  | DeleteLiveClassLoading
  | DeleteLiveClassError
  | DeleteLiveClassSuccess
  | GetOnlineClassLogLoading
  | GetOnlineClassLogError
  | GetOnlineClassLogSuccess
  | UpdateMeetLoading
  | UpdateMeetSuccess
  | UpdateMeetError;
