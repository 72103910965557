import { Paper, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { GetStaffProfile } from "../../../actions/HumanResource/StaffProfile/StaffProfileAction";
import { RootStore } from "../../../../../store";
import StaffAcademic from "../../../../../components/HumanResource/StaffList/StaffProfile/StaffTabs/StaffAcademic";
import StaffDocument from "../../../../../components/HumanResource/StaffList/StaffProfile/StaffTabs/StaffDocument";
import { TabPanel } from "../../../../../components/Student/StudentProfile/File/NewTabs";
import {
  useCardStyles,
  usefullCardStyles,
} from "../../../../../components/Styles/CardStyles";
import TeacherAcademic from "./TeacherTabs/TeacherAcademic";
import UserInfo from "./TeacherTabs/UserInfo";

const TeacherTabs = (props: any) => {
  const classes = usefullCardStyles();
  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();
  // const { staffID } = useParams<{ staffID: string }>();

  const staffID = 4;

  const [staff, setStaff] = useState<any>([]);

  useEffect(() => {
    // staffID != undefined && dispatch(GetStaffProfile(staffID));
  }, []);

  const staffList = useSelector(
    (state: RootStore) => state.staffProfile.staff_profile
  );

  useEffect(() => {
    if (staffList != null) {
      setStaff(staffList);
    }
  }, [staffList]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper elevation={0} className={classes.tabRoot}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          // variant="fullWidth"
        >
          <Tab label="Academic Info" />
          <Tab label="Documents" />
          <Tab label="Manage Password" />
          {/* <Tab label="Attendance" /> */}
        </Tabs>
        <TabPanel value={value} index={0}>
          <TeacherAcademic teacher={props.teacherData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StaffDocument
            hasUpload={false}
            hasDeleteBtn={false}
            Documents={props.teacherData?.documents}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UserInfo />
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          Attendance
        </TabPanel> */}
      </Paper>
    </>
  );
};

export default TeacherTabs;
