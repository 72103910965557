import React from "react";
import { Box } from "@material-ui/core";
import PageHeader from "../../dashboard/TeacherDashboard/components/Header/PageHeader";
import PageWrapper from "../../dashboard/TeacherDashboard/components/PageWrapper";
import CheckinCheckout from "../../dashboard/TeacherDashboard/pages/Attendance/CheckinCheckout";

const CheckinCheckoutPage = () => {
  return (
    <>
      <Box>
        <PageHeader
          title="Today Checkin / Checkout"
          module={{ name: "Academics", to: "/my-subjects" }}
        />
        <PageWrapper>
          <CheckinCheckout />
        </PageWrapper>
      </Box>
    </>
  );
};

export default CheckinCheckoutPage;
