import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SET_SNACKBAR } from '../../../../../actions/SnackbarActionTypes';

export const ViewEventModal = (props: any) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    console.log('Data', data);

    props.handleClose();

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: 'success',
        snackbarMessage: 'Your response is sent successfully.',
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            Title:
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" style={{ lineHeight: 1 }}>
              {props.item?.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            Location:
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" style={{ lineHeight: 1 }}>
              {props.item?.location}
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            Date:
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" style={{ lineHeight: 1 }}>
              {props.item?.date}
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            Time:
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" style={{ lineHeight: 1 }}>
              {props.item?.time}
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: '10px' }}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="attended"
                inputRef={register({ required: false })}
              />
            }
            label="I have attended this event."
          />
        </Grid>

        <Grid container style={{ marginBottom: '10px' }}>
          <TextField
            multiline
            variant="outlined"
            fullWidth
            rows={2}
            placeholder="Remarks"
            name="remarks"
            inputRef={register({ required: true })}
          />
        </Grid>

        <Grid container style={{ marginBottom: 8 }}>
          <Button variant="contained" color="primary" type="submit">
            Submit Remarks
          </Button>
        </Grid>
      </form>
    </>
  );
};
