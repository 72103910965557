export const GET_SYLLABUS_LOADING = "GET_SYLLABUS_LOADING";
export const GET_SYLLABUS_ERROR = "GET_SYLLABUS_ERROR";
export const GET_SYLLABUS_SUCCESS = "GET_SYLLABUS_SUCCESS";

export const GET_SUB_UNIT_LOADING = "GET_SUB_UNIT_LOADING";
export const GET_SUB_UNIT_ERROR = "GET_SUB_UNIT_ERROR";
export const GET_SUB_UNIT_SUCCESS = "GET_SUB_UNIT_SUCCESS";

export interface StudentSyllabusTypeI {
  id?: number;
  name: string;
  subject: number | undefined;
  academic_class: number | undefined;
  teaching_hours: string;
}

export interface StudentsubArrayI {
  id?: number | undefined;
  sub_unit: string;
  title: string;
}

export interface StudentSubUnitTypeI {
  id?: number;
  unit: StudentSyllabusTypeI;
  sub_unit: string | StudentsubArrayI[];
  title: string;
  completed?: boolean;
}

//===============================<START>GET SYLLABUS<START>======================//

export interface GetSyllabusLoading {
  type: typeof GET_SYLLABUS_LOADING;
}

export interface GetSyllabusError {
  type: typeof GET_SYLLABUS_ERROR;
}

export interface GetSyllabusSuccess {
  type: typeof GET_SYLLABUS_SUCCESS;
  payload: StudentSyllabusTypeI[];
}

//===============================<END>GET SYLLABUS<END>======================//

//===============================<START>GET sub unit<START>======================//

export interface GetSubUnitLoading {
  type: typeof GET_SUB_UNIT_LOADING;
}

export interface GetSubUnitError {
  type: typeof GET_SUB_UNIT_ERROR;
}

export interface GetSubUnitSuccess {
  type: typeof GET_SUB_UNIT_SUCCESS;
  payload: StudentSubUnitTypeI[];
}

//===============================<END>GET SubUnit<END>======================//

export type SyllabusDispatchTypes =
  | GetSyllabusLoading
  | GetSyllabusError
  | GetSyllabusSuccess
  | GetSubUnitLoading
  | GetSubUnitError
  | GetSubUnitSuccess;
