import React, { useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { useTabStyles, TabPanel } from "../../../../../components/Student/StudentProfile/File/NewTabs"
import SingleSubjectSearch from './SingleSubjectSearch'
import SingleSubjectTable from './SingleSubjectTable'
import DraftPage from "./DraftPage"
import { TeacherClassSubjectsI } from '../../../../../rtk/features/timetable/timetable'

interface Props {
    data: TeacherClassSubjectsI | undefined,
}

const NewTabs = ({ data }: Props) => {
    const classes = useTabStyles();
    const [value, setValue] = useState<number>(0);
    const isEmpty = data === undefined

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue);

    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
            // variant="fullWidth"
            >
                <Tab label="Assignment List" value={0} />
                <Tab label="Draft List" value={1} />
            </Tabs>

            <TabPanel value={value} index={0}>
                {!isEmpty && <SingleSubjectSearch data={data} value={value} />}
                {!isEmpty && <SingleSubjectTable data={data} />}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {!isEmpty && <DraftPage data={data} />}
            </TabPanel>
        </div>
    )
}

export default NewTabs