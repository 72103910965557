import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { Box, Divider, Typography, Avatar } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DashboardSyllabusI } from '../../../../actions/TeacherDash/Dasbhoard/TeacherDashboardActionTypes';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useState } from 'react';
import styled from 'styled-components';
import party from 'party-js';
import PodiumContainer from '../../../../core-ui/podium';
import { useRef } from 'react';
import { SET_SNACKBAR } from '../../../../actions/SnackbarActionTypes';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: 360,
      background: 'linear-gradient(220deg, #f000, #4259b6)',
      paddingTop: '0',
      paddingBottom: '8px',
    },
    title: {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    itemTitle: {
      fontWeight: 500,
      marginBottom: 5,
      display: 'inline-block',
    },
  })
);

const StyledContainer = styled(Box)`
  height: 280px;
  overflow-y: auto;
`;

const StyledRankWrapper = styled(Box)`
  position: relative;
  margin: 0 auto;
  display: block;
  width: max-content;
  height: 120px;
  width: 100px;
  background-image: url('https://png.pngtree.com/element_our/20200611/ourmid/pngtree-match-ranking-image_2251297.jpg');
`;

const StyledRankContainer = styled(Box)`
  display: inline-block;
`;

const StyledRank = styled(Box)`
  width: 80px;
  height: 100px;
  margin: 0px 2px;
  background-color: orange;
`;

const StyledTitle = styled(Typography)`
  background: orange;
  color: white;
  font-weight: 600;
  text-align: center;
  height: 40px;
  line-height: 36px;
  width: 380px;
  margin: 0 auto;
`;

const StyledFavoriteIcon = styled(FavoriteIcon)`
  color: tomato;
`;

export default function EventResultWidget(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const data = [15, 35, 68, 97, 58, 67, 87];

  const [liked, setLiked] = useState(false);
  const partyButtonRef = useRef(null);

  const handleLike = (event: any) => {
    setLiked((like) => {
      if (like === false) {
        return !like;
      }
      return like;
    });
    if (partyButtonRef.current) {
      party.confetti(partyButtonRef.current, {
        count: party.variation.range(40, 80),
        spread: 80,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'You have celebrated this event.',
        },
      });
    } else {
      console.log('Party not started yet');
    }
  };

  return (
    <List className={classes.root}>
      <ListItem dense button className={classes.title}>
        <ListItemText
          primary={
            <span
              style={{
                fontSize: '16px',
                fontWeight: 600,
                color: 'white',
              }}
            >
              ECA Event Result
            </span>
          }
        />

        <ListItemSecondaryAction>
          <IconButton
            onClick={handleLike}
            ref={partyButtonRef !== undefined ? partyButtonRef : null}
          >
            {liked ? <StyledFavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Box paddingLeft="16px" paddingRight="16px">
        <Divider />
      </Box>

      <PodiumContainer />
    </List>
  );
}
