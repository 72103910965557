import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Add,
  DeleteForever,
  KeyboardArrowRight,
  Send,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../../../../actions/SnackbarAction';
import {
  AddUnitSyllabus,
  GetSyllabus,
} from '../../../../../actions/TeacherDash/Syllabus/SyllabusAction';
import {
  subArrayI,
  SyllabusTypeI,
} from '../../../../../actions/TeacherDash/Syllabus/SyllabusActionTypes';
import { GetTeachingSubject } from '../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectAction';
import { TeacherSubjectByClass } from '../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes';
import { GetTeachingClass } from '../../../../../actions/TeacherDash/TeachingClass/TeachingClassAction';
import { TeachingClassTypeI } from '../../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes';
import { RootStore } from '../../../../../store';
import CustomizedTextField from '../../../../../components/Reusable/Inputs/TextField';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import { useTableStyles } from '../../../../../components/Styles/TableStyles';

interface SectionII {
  id: string;
  name: string;
}
interface ClassII {
  id: string;
  grade: string;
  grade_name: string;
  section: SectionII[] | null | string;
  section_name?: string;
}

const SyllabusNewCreate = (props: any) => {
  const classes = useFormStyles();
  const localClass = useTableStyles();
  const dispatch = useDispatch();

  // const { subject, grade, onClose } = props;

  const { register, handleSubmit, setValue, errors, reset } = useForm<any>({
    mode: 'onChange',
  });

  // * --------------------------<START>-STATE HANDLER--<START> ----------------------------*//
  const [selectedClass, setSelectedClass] = useState<any>(null);
  const [classChoices, setClassChoices] = useState<any>([]);

  const [selectedSubjects, setSelectedSubjects] =
    useState<TeacherSubjectByClass | null>(null);
  const [subjectChoices, setSubjectChoices] = useState<TeacherSubjectByClass[]>(
    []
  );
  const [subjectDisabler, setSubjectDisabler] = useState<boolean>(true);

  const [unit, setUnit] = useState<any>();
  const [subUnit, setSubUnit] = useState<subArrayI[]>([]);

  const [unitCount, setUnitCount] = useState<number>(1);
  const [subUnitCount, setSubUnitCount] = useState<any>(0);

  // * --------------------------<END>-STATE HANDLER--<END> ----------------------------*//

  // * ------------------------<START> REDUX MANAGEMENT <START>  --------------------------------------------*//
  const classSelector = useSelector((state: RootStore) => state.teaching_class);
  const subjectSelector = useSelector(
    (state: RootStore) => state.teacher_subject
  );
  const syllabusSelector = useSelector(
    (state: RootStore) => state.teacher_syllabus.add_unit
  );

  useEffect(() => {
    const length = syllabusSelector?.results?.length;
    setUnitCount(length + 1);
  }, [syllabusSelector]);

  useEffect(() => {
    dispatch(GetTeachingClass());
  }, []);

  useEffect(() => {
    const classes = classSelector.teaching_class;
    const finalArray: ClassII[] = [];
    classes.forEach((item: any) => {
      if (item.section === null) {
        finalArray.push(item);
      } else {
        for (let i = 0; i < item.section.length; i++) {
          const data1 = {
            ...item,
            section: item.section[i].id,
            section_name: item.section[i].name,
          };
          finalArray.push(data1);
        }
      }
    });
    setClassChoices(finalArray);
  }, [classSelector.teaching_class]);

  useEffect(() => {
    setSubjectChoices(subjectSelector.subject_by_class);
  }, [subjectSelector.subject_by_class]);

  useEffect(() => {
    selectedSubjects?.id &&
      selectedClass?.id &&
      dispatch(GetSyllabus(selectedClass?.id, selectedSubjects?.id, 1, 100));
  }, [selectedClass?.id, selectedSubjects?.id]);

  // * ------------------------ <END>  REDUX MANAGEMENT <END>  --------------------------------------------*//

  useEffect(() => {
    subUnit?.length &&
      setSubUnitCount(Math.max(...subUnit.map((element: any) => element.id)));
  }, [subUnit]);

  //* ------------------------<START> FUNCTIONS <START> ---------------------------------------*//
  const handleClassChange = (value: any | null) => {
    value != null && setSelectedClass(value);
    setSelectedSubjects(null);
    if (value) {
      const section = value.section ? value.section : '';
      setSubjectDisabler(false);
      dispatch(GetTeachingSubject(value.grade, section));
    }
  };

  const handleSubjectChange = (value: TeacherSubjectByClass | null) => {
    setSelectedSubjects(value);
  };

  let SubUnitTempData: subArrayI[] = [];

  useEffect(() => {
    SubUnitTempData = subUnit;
  }, [subUnit]);

  const handleSubUnitChange = (index: number, subUnitValue: string) => {
    const tempData = subUnit;
    tempData[index].title = subUnitValue;
    setSubUnit(tempData);
  };

  const handleDeleteRecord = (sub_itemID: number) => {
    console.log('id', sub_itemID);
    let newData = SubUnitTempData.length ? SubUnitTempData : subUnit;
    newData = newData.filter((elem: any) => elem.id != sub_itemID + 1);

    setSubUnit(handleReorder(newData));
    // setSubUnit(newData);
  };

  const handleAddSubUnitChange = (event: any) => {
    const data = SubUnitTempData.length ? SubUnitTempData : subUnit;

    const checkEmptyRow = (callback: (value: boolean) => void) => {
      let isEmpty = false;
      data.map((element) => {
        if (element.title === '') {
          isEmpty = true;
          return;
        }
      });

      callback(isEmpty);
    };

    checkEmptyRow((foundEmpty) => {
      if (foundEmpty) {
        dispatch(setSnackbar(true, 'warning', 'Fields cannot be empty'));
      } else {
        const subID = subUnitCount + 1;
        setSubUnit(
          handleReorder(data).concat({
            id: subID,
            sub_unit: subID.toString(),
            title: '',
          })
        );
      }
    });
  };

  const handleReorder = (item: subArrayI[]) => {
    const temporaryData: subArrayI[] = [];
    item.map((elem: subArrayI, index: number) => {
      temporaryData.push({ ...elem, id: (index + 1).toString() });
    });
    return temporaryData;
  };

  const onFormSubmit = (data: SyllabusTypeI) => {
    const new_data = {
      academic_class: selectedClass?.id,
      subject: selectedSubjects?.id,
      teaching_hours: data.teaching_hours,
      name: data.name,
    };
    new_data && setUnit(new_data);

    const submit_data = {
      unit: new_data,
      sub_unit: subUnit,
    };

    if (submit_data) {
      dispatch(AddUnitSyllabus(submit_data));
    }

    // const checkEmptyRow = (callback: (value: boolean) => void) => {
    //   let isEmpty = false;
    //   subUnit.map((element) => {
    //     if (element.title === "") {
    //       isEmpty = true;
    //       return;
    //     }
    //   });

    //   callback(isEmpty);
    // };

    // checkEmptyRow((foundEmpty) => {
    //   if (foundEmpty) {
    //     dispatch(setSnackbar(true, "warning", "Fields cannot be empty"));
    //   } else if (new_data) {
    //     const submit_data = {
    //       unit: new_data,
    //       sub_unit: subUnit,
    //     };
    //     console.log("subunit");
    //     // dispatch(AddUnitSyllabus(submit_data));
    //     dispatch(AddUnitSyllabus(submit_data));
    //   } else if (unit != null) {
    //     console.log("empty unit only");
    //   }
    // });

    props.onClose();
  };

  //* ------------------------<END> FUNCTIONS <END> ---------------------------------------*//
  console.log(subjectChoices, selectedSubjects);
  return (
    <>
      <Paper elevation={0}>
        <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Select Class</InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: TeachingClassTypeI | null
                ) => handleClassChange(value)}
                options={classChoices}
                value={selectedClass}
                getOptionLabel={(option) =>
                  `Class ${option.grade_name} ${
                    option.section_name !== undefined ? option.section_name : ''
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="class_name"
                    inputRef={register({ required: true })}
                    variant="outlined"
                    helperText={
                      errors?.class_name ? 'Class must be selected' : ''
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>Select Subject</InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: TeacherSubjectByClass | null
                ) => handleSubjectChange(value)}
                options={subjectChoices}
                value={selectedSubjects}
                disabled={subjectDisabler}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="subject_name"
                    inputRef={register({ required: true })}
                    variant="outlined"
                    helperText={
                      errors?.subject_name ? 'Subject must be selected' : ''
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={3}>
              <Grid
                container
                direction="row"
                style={{
                  marginBottom: '10px',
                  marginTop: '25px',
                }}
              >
                <KeyboardArrowRight />
                <Typography variant="body1" style={{ fontSize: '15px' }}>
                  Unit {unitCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <CustomizedTextField
                label="Unit Title"
                name="name"
                type="text"
                required
                error={errors['name'] ? 'Required Field.' : ''}
                placeholder="Label"
                inputRef={register({ required: true })}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomizedTextField
                label="Teaching Hour"
                name="teaching_hours"
                type="text"
                error={errors['teaching_hours'] ? 'Required Field.' : ''}
                placeholder="Label"
                inputRef={register({ required: true })}
              />
            </Grid>
            <Grid
              item
              style={{
                marginTop: '22px',
              }}
              xs={2}
            >
              <Button
                type="submit"
                style={{ minWidth: '160px' }}
                fullWidth
                startIcon={<Add />}
                variant="outlined"
                color="primary"
                onClick={handleAddSubUnitChange}
              >
                Add Sub Unit
              </Button>
            </Grid>
          </Grid>
          {subUnit &&
            subUnit.map((item: subArrayI, index: number) => {
              return (
                <Grid key={index} container direction="row" spacing={3}>
                  <Grid item xs={2}>
                    <Grid
                      container
                      direction="row"
                      style={{
                        marginBottom: '10px',
                        marginTop: '25px',
                      }}
                    >
                      <KeyboardArrowRight />
                      <Typography variant="body1" style={{ fontSize: '15px' }}>
                        Unit {unitCount}.{index + 1}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <CustomizedTextField
                      label="Sub Unit Title"
                      name="sub_unit_title"
                      type="text"
                      placeholder="Label"
                      required
                      // error={errors["sub_unit_title"] ? "Required Field." : ""}
                      onChange={(event: any) =>
                        handleSubUnitChange(index, event.target.value)
                      }
                      inputRef={register({ required: true })}
                    />
                  </Grid>
                  {index + 1 == subUnit.length && (
                    <>
                      <Grid
                        key={index}
                        item
                        xs={1}
                        style={{ marginTop: '25px' }}
                      >
                        <a
                          style={{ cursor: 'pointer', padding: '2px' }}
                          onClick={(
                            event: React.MouseEvent<
                              HTMLAnchorElement,
                              MouseEvent
                            >
                          ) => handleDeleteRecord(index)}
                        >
                          <DeleteForever className={localClass.deleteIcon} />
                        </a>
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}
          <Grid container justifyContent="flex-end">
            <Grid item xs={4}>
              <Button
                startIcon={<Send />}
                variant="contained"
                color="primary"
                type="submit"
                style={{ width: '100%', margin: '20px auto' }}
                // onClick={handleSaveSubUnit}
              >
                Save All
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default SyllabusNewCreate;
