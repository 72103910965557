import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Link, { LinkProps } from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Route, useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      "& nav > .MuiBreadcrumbs-ol": {
        justifyContent: "flex-start",
        "& > li > a": {
          fontWeight: 500,
          fontSize: "16px",
        },
      },
    },
    lists: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(1),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    linkText: {
      textTransform: "capitalize",
      color: "rgba(0,0,0,0.85)",
      fontWeight: 500,
    },
  })
);

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

export default function BreadCrumbs(props: any) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <div className={classes.root}>
      <Route>
        {() => {
          const pathnames = location.pathname
            .split("/")
            .filter((x) => x)
            .slice(0, 2);

          return (
            <Breadcrumbs separator={"/"} aria-label="breadcrumb">
              <Typography variant="subtitle2">
                <LinkRouter to="/" className={classes.linkText}>
                  Dashboard
                </LinkRouter>
              </Typography>

              {props.module && (
                <Typography variant="subtitle2">
                  <LinkRouter to={props.module.to} className={classes.linkText}>
                    {props.module.name}
                  </LinkRouter>
                </Typography>
              )}

              {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                return last ? (
                  <Typography
                    variant="subtitle2"
                    key={to}
                    className={classes.linkText}
                  >
                    {pathnames[index]}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2">
                    <LinkRouter to={to} key={to} className={classes.linkText}>
                      {pathnames[index]}
                    </LinkRouter>
                  </Typography>
                );
              })}
            </Breadcrumbs>
          );
        }}
      </Route>
    </div>
  );
}
