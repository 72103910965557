export const GET_CLASS_ATTENDANCE_LOADING = "GET_CLASS_ATTENDANCE_LOADING";
export const GET_CLASS_ATTENDANCE_ERROR = "GET_CLASS_ATTENDANCE_ERROR";
export const GET_CLASS_ATTENDANCE_SUCCESS = "GET_CLASS_ATTENDANCE_SUCCESS";

export const POST_CLASS_ATTENDANCE_LOADING = "POST_CLASS_ATTENDANCE_LOADING";
export const POST_CLASS_ATTENDANCE_ERROR = "POST_CLASS_ATTENDANCE_ERROR";
export const POST_CLASS_ATTENDANCE_SUCCESS = "POST_CLASS_ATTENDANCE_SUCCESS";

export type GetClassAttendanceTypeI = {
  student_first_name: string;
  student_last_name: string;
  student_academic: number;
  roll_number: number;
  status: "A" | "P" | "I" | null;
};

//===============================<START>GET CLASS ATTENDANCE<START>======================//

export interface GetClassAttendanceLoading {
  type: typeof GET_CLASS_ATTENDANCE_LOADING;
}

export interface GetClassAttendanceError {
  type: typeof GET_CLASS_ATTENDANCE_ERROR;
}

export interface GetClassAttendanceSuccess {
  type: typeof GET_CLASS_ATTENDANCE_SUCCESS;
  payload: GetClassAttendanceTypeI[];
}
//===============================<END>GET CLASS ATTENDANCE<END>======================//

//===============================<START>POST CLASS ATTENDANCE<START>======================//

export interface PostClassAttendanceLoading {
  type: typeof POST_CLASS_ATTENDANCE_LOADING;
}

export interface PostClassAttendanceError {
  type: typeof POST_CLASS_ATTENDANCE_ERROR;
}

export interface PostClassAttendanceSuccess {
  type: typeof POST_CLASS_ATTENDANCE_SUCCESS;
  payload: { messgage: string };
}
//===============================<END>POST CLASS ATTENDANCE<END>======================//

export type ClassAttendanceDispatchTypes =
  | GetClassAttendanceLoading
  | GetClassAttendanceError
  | GetClassAttendanceSuccess
  | PostClassAttendanceLoading
  | PostClassAttendanceError
  | PostClassAttendanceSuccess;
