import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  ListItem,
  ListItemText,
  makeStyles,
  List,
  Theme,
  createStyles,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import cx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetEnoteCategorys } from "../../../../actions/Academics/EnoteCategory/EnoteCategoryAction";
import { RootStore } from "../../../../store";
import { ChevronRight, EventNoteSharp } from "@material-ui/icons";
import { GetTeacherEnotesCategories } from "../../../../actions/TeacherDash/TeacherEnotes/TeacherEnotesAction";
import { GetStudentEnotesCategories } from "../../../../actions/StudentDash/StudentEnote/StudentEnoteAction";
import { filterStyle } from "../../StudentDashboardStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listSelected: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,

      "&:hover": {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
      },
    },
    listText: {
      "& .MuiListItemText-primary": {
        fontSize: "0.9375rem",
      },
    },
    listItem: {
      borderRadius: 8,
      marginBottom: 12,
      "&:last-child": {
        borderBottom: "0px solid #ccc",
      },
    },
  })
);

interface PropsI {
  categoryNav: (val: CategoryNavI) => void;
}

interface CategoryNavI {
  id: string;
  name: string;
}

const StudentEnoteNav = (props: PropsI) => {
  const { categoryNav } = props;
  const classes = useFormStyles();
  const classes2 = useStyles();
  const [dataSet, setDataSet] = useState<any[]>([]);

  const dispatch = useDispatch();
  const enoteCategorySelector = useSelector(
    (state: RootStore) => state.student_enote
  );

  useEffect(() => {
    dispatch(GetStudentEnotesCategories());
  }, []);

  useEffect(() => {
    const data: any[] = enoteCategorySelector.enotes_categories.map(
      (element, index) => ({
        id: element.id,
        text: element.name,
        url: element.name,
        activeStatus: index === 0 ? true : false,
      })
    );

    if (data.length) {
      const val: CategoryNavI = {
        id: data[0].id,
        name: data[0].text,
      };

      categoryNav(val);
    }

    setDataSet(data);
  }, [enoteCategorySelector.enotes_categories]);

  const handleClick = (id: number) => {
    setDataSet(
      dataSet.map((item) => {
        if (item.id === id) {
          return { ...item, activeStatus: true };
        } else {
          return { ...item, activeStatus: false };
        }
      })
    );

    const search = dataSet.find((element) => element.id === id);
    if (search) {
      const val: CategoryNavI = {
        id: search.id,
        name: search.text,
      };

      categoryNav(val);
    }
  };

  return (
    <div>
      <Paper
        className={classes.pageContent}
        style={{ ...filterStyle, marginRight: 20 }}
      >
        <Box
          className={classes.formTitle}
          style={{ marginBottom: "4px", fontSize: 20 }}
        >
          <span>Choose by category</span>
        </Box>

        <List component="nav" aria-label="inner sidebar list">
          {dataSet.map((element: any, index: number) => (
            <ListItem
              button
              className={cx(
                classes2.listItem,
                element.activeStatus && classes2.listSelected
              )}
              key={index}
              onClick={() => handleClick(element.id)}
            >
              <ListItemText
                classes={{ root: classes2.listText }}
                primary={element.text}
              />

              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight
                    color={element.activeStatus ? "secondary" : "inherit"}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </div>
  );
};

export default StudentEnoteNav;
