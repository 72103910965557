import { Paper, Grid, InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetStudentLessonPlan } from "../../../../actions/StudentDash/StudentLessonPlan/StudentLessonPlanAction";
import { GetStudentSubjects } from "../../../../actions/StudentDash/StudentSubjects/StudentSubjectAction";
import { StudentSubjectI } from "../../../../actions/StudentDash/StudentSubjects/StudentSubjectActionTypes";
import { RootStore } from "../../../../store";
import { InputBox } from "../../../../components/Reusable/Inputs/Select";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import { filterStyle } from "../../StudentDashboardStyles";

const SearchLessonPlan = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm<any>();
  const [subject, setSubject] = useState<StudentSubjectI | null>(null);
  const [subjectChoices, setSubjectChoices] = useState<StudentSubjectI[]>([]);

  useEffect(() => {
    dispatch(GetStudentSubjects());
  }, []);

  const subjectState = useSelector(
    (state: RootStore) => state.student_subjects.subjects
  );

  useEffect(() => {
    subjectState && setSubjectChoices(subjectState);
    if (subject == null) {
      setSubject(subjectState[0]);
    }
  }, [subjectState]);

  useEffect(() => {
    if (subject) {
      dispatch(GetStudentLessonPlan(subject.subject));
    }
  }, [subject]);

  return (
    <>
      <Paper className={classes.pageContentBox} style={filterStyle}>
        <form className={classes.form}>
          <Grid container>
            <Grid item xs={9} className={classes.formTitle}>
              <span>Lesson Plan Search</span>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4} className={classes.formWrapperRow}>
              <InputBox>Subject </InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                value={subject}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: StudentSubjectI | null
                ) => setSubject(value)}
                options={subjectChoices}
                getOptionLabel={(option) => option.subject_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="subject_name"
                    inputRef={register({ required: false })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default SearchLessonPlan;
