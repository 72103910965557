import { Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AccountantFeeReportSidebar from '../../../components/Event/EventSidebar/AccountantFeeReportSidebar';
import BackDropLoader from '../../../components/Reusable/BackDropLoader';
import SubModuleLayout from '../../../components/Reusable/Layouts/SubModuleLayout';
import FinanceSidebar from '../../../components/Finance/FinanceSidebar';
import FeeStatementSearch from '../../../components/Finance/FeeStatement/FeeStatementSearch';
import FeeStatementTable from '../../../components/Finance/FeeStatement/FeeStatementTable';

interface SelectedFeesI {
  id: string;
  title: string;
  isMonthly: boolean;
}

export default function AccountFeeStatementPage() {
  const [fees, setFees] = useState<SelectedFeesI[]>([]);
  const [months, setMonths] = useState<string[]>([]);
  const [student, setStudent] = useState<any>(null);

  const [grade, setGrade] = useState<any | null>(null);
  const [section, setSection] = useState<any | null>(null);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  // const [selected, setSelected] = useState<any[]>([]);

  const dispatch = useDispatch();

  const nav = <AccountantFeeReportSidebar feeStatement />;

  return (
    <SubModuleLayout sideNav={nav}>
      <Paper style={{ margin: '10px' }}>
        <FeeStatementSearch
          student={student}
          setStudent={setStudent}
          grade={grade}
          setGrade={setGrade}
          section={section}
          setSection={setSection}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <FeeStatementTable
          grade={grade}
          section={section}
          startDate={startDate}
          endDate={endDate}
        />
      </Paper>
      <BackDropLoader open={loading} />
    </SubModuleLayout>
  );
}
