import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_ACADEMIC_LOADING,
  GET_STUDENT_ACADEMIC_ERROR,
  GET_STUDENT_ACADEMIC_SUCCESS,
  GET_STUDENT_ACADEMIC_MARKS_LOADING,
  GET_STUDENT_ACADEMIC_MARKS_ERROR,
  GET_STUDENT_ACADEMIC_MARKS_SUCCESS,
  StudentAcademicInfoDispatchTypes,
} from "./StudentAcademicInfoActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { PARENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetStudentAcademicInfo = () => async (
  dispatch: Dispatch<StudentAcademicInfoDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_STUDENT_ACADEMIC_LOADING,
    });

    const res = await axios.get(
      `${PARENT_API_URL}/student_academic_info/`,
      HeaderConfig()
    );

    dispatch({
      type: GET_STUDENT_ACADEMIC_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_ACADEMIC_ERROR,
    });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     snackbarMessage: "Academic Info Loading Failed",
    //   },
    // });
  }
};

export const GetStudentAcademicMark = (query: any) => async (
  dispatch: Dispatch<StudentAcademicInfoDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_STUDENT_ACADEMIC_MARKS_LOADING,
    });

    const res = await axios.get(
      `${PARENT_API_URL}/student_academic_report/?id=${query.student_academic_id}&exam_info=${query.exam_info}`,
      HeaderConfig()
    );

    http: dispatch({
      type: GET_STUDENT_ACADEMIC_MARKS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_ACADEMIC_MARKS_ERROR,
    });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     snackbarMessage: "Academic Mark Loading Failed",
    //   },
    // });
  }
};
