import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_LIVE_CLASS_LOADING,
  GET_LIVE_CLASS_ERROR,
  GET_LIVE_CLASS_SUCCESS,
  LiveClassDispatchTypes,
} from "./StudentLiveActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { STUDENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetStudentLiveClass =
  () => async (dispatch: Dispatch<LiveClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_LIVE_CLASS_LOADING,
      });

      const res = await axios.get(
        `${STUDENT_API_URL}/online_class/?all=true`,
        HeaderConfig()
      );

      dispatch({
        type: GET_LIVE_CLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LIVE_CLASS_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Live Class Loading Failed",
      //   },
      // });
    }
  };

export const TakeStudentAttendance =
  (onlineClass: number) =>
  async (dispatch: Dispatch<LiveClassDispatchTypes | SetSnackBarI>) => {
    try {
      const res = await axios.post(
        `${STUDENT_API_URL}/online_class_attendance/`,
        {
          online_class: onlineClass,
        }
      );

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Your attendance is successful!",
        },
      });
    } catch (error) {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Unable to take your attendance",
        },
      });
    }
  };
