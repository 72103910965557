import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

interface LayoutShellProps {
  children: React.ReactNode;
}

const StyledTitle = styled(Typography)`
  background: ${(props) => props.theme.palette.primary.light};
  color: white;
  font-weight: 600;
  text-align: center;
  height: 40px;
  line-height: 36px;
  width: 380px;
  margin: 4px auto 0px auto;
`;

export default function LayoutShell({ children }: LayoutShellProps) {
  return (
    <div className="App">
      <main className="container relative mx-auto max-w-screen-sm pb-8">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          {children}

          <StyledTitle>Quiz Competition March 2022</StyledTitle>
        </div>
      </main>
    </div>
  );
}
