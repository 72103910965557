import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import { FormRefreshButton } from '../../../../../components/Reusable/Buttons/FormButton';
import Popups from '../../../../../components/Reusable/Dialogs/Popups';
import CustomizedSelect, {
  RowSelect,
} from '../../../../../components/Reusable/Inputs/Select';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import { dateConverterBsToAd } from '../../../../../components/utils/dateConverter';
import TeacherLeaveAdd from './TeacherMeetingModal/TeacherMeetingAdd';
import {
  FormNewButton,
  FormSearchButton,
} from '../../../../../components/Reusable/Buttons/SearchBoxButton';
import { CUR_NEPALI_DATE } from '../../../../../components/utils/nepaliDateUtils';
import { GetMeetingTypes } from '../../../../../actions/HumanResource/MeetingType/MeetingTypeAction';
import { GetTeacherSearchedMeetings } from '../../../../../actions/TeacherDash/TeacherMeeting/TeacherMeetingAction';
import {
  ResetButton,
  SubmitButton,
} from '../../../../../components/Reusable/Buttons/SearchButton';
import { filterStyle } from '../../../components/Styles/TeacherDashboardStyles';

const TeacherMeetingSearch = () => {
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { handleSubmit } = useForm();

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>(CUR_NEPALI_DATE);
  const [toDate, setToDate] = useState<string>(CUR_NEPALI_DATE);
  const [meetingType, setMeetingType] = useState<any>(null);

  useEffect(() => {
    dispatch(GetMeetingTypes());
  }, []);

  const meetingTypeState = useSelector(
    (state: RootStore) => state.meeting_type
  );

  const MEETING_TYPE_CHOICES = meetingTypeState.meeting_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  const onSubmit = (data: any) => {
    const query: any = {
      meetingType: meetingType ? meetingType.key : '',
      date_first: (fromDate && dateConverterBsToAd(fromDate)) || '',
      date_second: (toDate && dateConverterBsToAd(toDate)) || '',
    };

    dispatch(GetTeacherSearchedMeetings(query));
  };

  const handleNewClick = () => {
    setOpenPopup(true);
  };

  const handleReset = () => {
    setFromDate('');
    setToDate('');
    setMeetingType(null);

    const query: any = {
      meetingType: '',
      date_first: '',
      date_second: '',
    };

    dispatch(GetTeacherSearchedMeetings(query));
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  return (
    <Paper style={filterStyle}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6} className={classes.formTitle}>
            <Typography variant="h6">Teacher Meeting Requests</Typography>
          </Grid>

          <Grid container item xs={6}>
            <Grid container justifyContent="flex-end" spacing={2}>
              {/* <Grid item>
                <FormRefreshButton onClick={handleReset} />
              </Grid> */}

              <Grid item>
                <FormNewButton onClick={handleNewClick}>
                  Request New Meeting
                </FormNewButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={4} className={classes.formWrapperRow}>
            <RowSelect
              fullWidth
              label="Meeting"
              name="meetingType"
              options={MEETING_TYPE_CHOICES}
              value={meetingType}
              setValue={setMeetingType}
            />
          </Grid>

          <SubmitButton style={{ marginTop: '5px' }} />
          <ResetButton style={{ marginTop: '5px' }} onClick={handleReset} />
        </Grid>
      </form>

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        title="Request Meeting"
        maxWidth="sm"
      >
        <TeacherLeaveAdd onClose={handleClose} />
      </Popups>
    </Paper>
  );
};

export default TeacherMeetingSearch;
