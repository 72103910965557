import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../../config/SERVER_API_URLS";

export const getMarksLedger = createAsyncThunk(
  "getMarksLedger",
  async (
    data: { examId: string; gradeId: string; sectionId?: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/student_marks_ledger/?exam_info=${
          data.examId
        }&grade=${data.gradeId}&section=${data.sectionId || ""}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      if (error.response.status === 400) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(null);
    }
  }
);

export const generateGradeResult = createAsyncThunk(
  "generateGradeResult",
  async (
    data: { examId: string; gradeId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/save/student/mark-sheet/?exam_info=${data.examId}&grade=${data.gradeId}`
      );
      dispatch(setSnackbar(true, "success", "Result generated successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(null);
    }
  }
);
