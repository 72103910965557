import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ExaminationSideBar } from "../../components";
import ResultLedger from "../../components/Examination/Ledger/ResultLedger";
import OptionalSubjectForm from "../../components/Examination/OptionalSubjects/OptionalSubjectForm";
import OptionalSubjectTable from "../../components/Examination/OptionalSubjects/OptionalSubjectTable";
import BackDropLoader from "../../components/Reusable/BackDropLoader";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { RootStore } from "../../store";

const OptionalSubjectPage = () => {
  const nav = <ExaminationSideBar optionalSubjects />;

  const OptLoading = useSelector(
    (state: RootStore) => state.optSubject.loading
  );
  const SubLoading = useSelector(
    (state: RootStore) => state.gradeSubject.loading
  );

  return (
    <SubModuleLayout examinationMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <OptionalSubjectForm />
        </Grid>
        <Grid item xs={9}>
          <OptionalSubjectTable />
        </Grid>
      </Grid>
      <BackDropLoader open={OptLoading || SubLoading} />
    </SubModuleLayout>
  );
};

export default OptionalSubjectPage;
