import React, { useState } from 'react';
import { useSidebar } from '../../Reusable';
import { useSidebarStyles } from '../../Styles/SidebarStyles';
import { DonutLargeOutlined } from '@material-ui/icons';
import SidebarTitle from '../../Reusable/Titles/SidebarTitle';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { FaRupeeSign } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import SvgIcon from '@material-ui/core/SvgIcon';

interface PropsI {
  collectFee?: boolean;
}

interface DataSetI {
  text: string;
  url: string;
  activeStatus?: boolean;
}

const AccountantCollectFeeSidebar = (props: PropsI) => {
  const classes = useSidebarStyles();
  const [collectFee] = useState(props.collectFee);

  const dataSet: DataSetI[] = [
    { text: 'Collect Fees', url: 'collect-fees', activeStatus: collectFee },
  ];
  const { CustomSidebar } = useSidebar(dataSet, 'FINANCE');
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle
          icon={
            <GiReceiveMoney
              style={{
                display: 'inline-block',
                marginRight: 5,
                marginTop: -4,
              }}
            />
          }
        >
          Collect Fees
        </SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default AccountantCollectFeeSidebar;
