import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_LIVE_CLASS_LOADING,
  GET_LIVE_CLASS_ERROR,
  GET_LIVE_CLASS_SUCCESS,
  GET_ONLINE_CLASS_LOG_LOADING,
  GET_ONLINE_CLASS_LOG_ERROR,
  GET_ONLINE_CLASS_LOG_SUCCESS,
  ADD_LIVE_CLASS_LOADING,
  ADD_LIVE_CLASS_ERROR,
  ADD_LIVE_CLASS_SUCCESS,
  UPDATE_LIVE_CLASS_LOADING,
  UPDATE_LIVE_CLASS_ERROR,
  UPDATE_LIVE_CLASS_SUCCESS,
  UPDATE_MEET_LOADING,
  UPDATE_MEET_ERROR,
  UPDATE_MEET_SUCCESS,
  DELETE_LIVE_CLASS_LOADING,
  DELETE_LIVE_CLASS_ERROR,
  DELETE_LIVE_CLASS_SUCCESS,
  LiveClassTypeI,
  LiveClassDispatchTypes,
} from "./TeacherLiveClassActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetLiveClass =
  (grade: string, section: string) =>
  async (dispatch: Dispatch<LiveClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_LIVE_CLASS_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/online_class/?all=true&grade=${grade}&section=${section}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_LIVE_CLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LIVE_CLASS_ERROR,
      });
    }
  };

export const GetTeacherOnlineClassLog =
  (id: string) =>
  async (dispatch: Dispatch<LiveClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ONLINE_CLASS_LOG_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/online_class_attendance/?online_class=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ONLINE_CLASS_LOG_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ONLINE_CLASS_LOG_ERROR,
      });
    }
  };

export const AddLiveClass =
  (data: LiveClassTypeI) =>
  async (dispatch: Dispatch<LiveClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_LIVE_CLASS_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/online_class/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_LIVE_CLASS_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Live Class Added Successsfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_LIVE_CLASS_ERROR,
        payload: error?.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Live Class Adding Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_LIVE_CLASS_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/online_class/?all=true&grade=${data.grade}&section=${data.section}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_LIVE_CLASS_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_LIVE_CLASS_ERROR,
        });
      }
    }
  };

export const UpdateLiveClass =
  (id: string, data: LiveClassTypeI) =>
  async (dispatch: Dispatch<LiveClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_LIVE_CLASS_LOADING,
      });

      const res = await axios.put(
        `${TEACHER_API_URL}/online_class/${id}/?all=true`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_LIVE_CLASS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Live Class Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_LIVE_CLASS_ERROR,
        payload: error?.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Live Class Update Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_LIVE_CLASS_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/online_class/?all=true&grade=${data.grade}&section=${data.section}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_LIVE_CLASS_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_LIVE_CLASS_ERROR,
        });
      }
    }
  };

export const UpdateLiveMeet =
  (id: string, data: { title: string }) =>
  async (dispatch: Dispatch<LiveClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_MEET_LOADING,
      });

      const res = await axios.patch(
        `${TEACHER_API_URL}/online_class/${id}/?all=true`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_MEET_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Meet Title Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_MEET_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Meet Title Update Failed",
        },
      });
    }
  };

export const DeleteLiveClass =
  (id: string) =>
  async (dispatch: Dispatch<LiveClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_LIVE_CLASS_LOADING,
      });

      const res = await axios.delete(
        `${TEACHER_API_URL}/online_class/${id}/?all=true`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_LIVE_CLASS_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Live Class Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_LIVE_CLASS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Live Class Delete Failed",
        },
      });
    }
  };
