import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_DASHBOARD_LOADING,
  GET_TEACHER_DASHBOARD_ERROR,
  GET_TEACHER_DASHBOARD_SUCCESS,
  GET_TEACHER_DASHBOARD_ONLINECLASS_LOADING,
  GET_TEACHER_DASHBOARD_ONLINECLASS_ERROR,
  GET_TEACHER_DASHBOARD_ONLINECLASS_SUCCESS,
  GET_TEACHER_DASHBOARD_EVENTS_LOADING,
  GET_TEACHER_DASHBOARD_EVENTS_ERROR,
  GET_TEACHER_DASHBOARD_EVENTS_SUCCESS,
  TeacherDashboardDispatchTypes,
} from "./TeacherDashboardActionTypes";

import { SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetTeacherDashboard =
  (class_date: string) =>
  async (dispatch: Dispatch<TeacherDashboardDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHER_DASHBOARD_ONLINECLASS_LOADING,
      });

      let url = `${TEACHER_API_URL}/dashboard/`;
      if (class_date) {
        url = `${TEACHER_API_URL}/dashboard/?class_date=${class_date}`;
      }

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_TEACHER_DASHBOARD_ONLINECLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_DASHBOARD_ONLINECLASS_ERROR,
      });
    }
  };

export const GetTeacherDashboardDays =
  (days?: string) =>
  async (dispatch: Dispatch<TeacherDashboardDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHER_DASHBOARD_LOADING,
      });

      let url = `${TEACHER_API_URL}/dashboard/`;
      if (days) {
        url = `${TEACHER_API_URL}/dashboard/?day=${days}`;
      }

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_TEACHER_DASHBOARD_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_DASHBOARD_ERROR,
      });
    }
  };

export const GetTeacherDashboardEvents =
  (month?: number) =>
  async (dispatch: Dispatch<TeacherDashboardDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHER_DASHBOARD_EVENTS_LOADING,
      });

      let url = `${TEACHER_API_URL}/event-list/`;
      if (month) {
        url = `${TEACHER_API_URL}/event-list/?month=${month}`;
      }

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_TEACHER_DASHBOARD_EVENTS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_DASHBOARD_EVENTS_ERROR,
      });
    }
  };
