// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  TableHead,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  InsertDriveFileOutlined,
  PrintOutlined,
} from '@material-ui/icons';
import { useTableStyles } from '../../../../../components/Styles/TableStyles';
import { useTable } from '../../../../../components/Reusable';
import TableActionButtons from '../../../../../components/Reusable/Buttons/TableActionButtons';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import { tblStyle } from '../../../ParentDashboardStyles';

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S. No.' },
  { id: 'subject', label: 'Subjects' },
  { id: 'fullMarks', label: 'Full Marks' },
  { id: 'passMarks', label: 'Pass Marks' },
  { id: 'th', label: 'TH' },
  { id: 'pr', label: 'PR' },
  { id: 'total', label: 'Total' },
  { id: 'grade_point', label: 'Grade Point' },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

const useStyles = makeStyles(() => ({
  tableCell: {
    padding: '0px 5px !important',
    borderBottom: '1px solid #D6D6D6',
    borderRight: '1px solid #D6D6D6',
  },
}));

interface propsI {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  student: string;
}

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const ExamReportTable = (props: any) => {
  const { onClose, student } = props;
  const classes = useTableStyles();
  const localClasses = useStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  // ============================ <START> REACT HOOKS <START> ============================== //
  const marksheetSelector = useSelector(
    (state: RootStore) => state.student_academic
  );

  useEffect(() => {
    setLoading(marksheetSelector.loading);
    setTableData(marksheetSelector.exam_marks);
  }, [marksheetSelector]);
  // ============================ <END> REACT HOOKS <END> ============================== //

  // ============================ <START> EVENT HANDLERS <START> ============================== //

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    return;
  };

  // ============================ <END> EVENT HANDLERS <END> ============================== //

  return (
    <>
      <Paper className={classes.rootTableBox} style={tblStyle}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6" style={{ color: '#4D4F5C' }}>
                  1st Terminal Examination Marks - {student}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className={classes.tableWrapper}>
          <div className={classes.tableTop}>
            <TextField
              autoComplete="off"
              variant="outlined"
              name="search"
              placeholder="Search ..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTableSearch(e)
              }
            />
            <TableActionButtons
              exportToExcel={exportToExcel}
              tablePrint={tablePrint}
            />
          </div>
          <TblContainer size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell
                  rowSpan={2}
                  align="center"
                  className={localClasses.tableCell}
                >
                  S. No.
                </StyledTableCell>
                <StyledTableCell
                  rowSpan={2}
                  align="left"
                  className={localClasses.tableCell}
                >
                  Subjects
                </StyledTableCell>
                <StyledTableCell
                  rowSpan={2}
                  align="center"
                  className={localClasses.tableCell}
                >
                  Full Marks
                </StyledTableCell>
                <StyledTableCell
                  rowSpan={2}
                  align="center"
                  className={localClasses.tableCell}
                >
                  Pass Marks
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  align="center"
                  className={localClasses.tableCell}
                >
                  Obtained Marks
                </StyledTableCell>
                <StyledTableCell
                  rowSpan={2}
                  align="center"
                  className={localClasses.tableCell}
                >
                  Total
                </StyledTableCell>
                <StyledTableCell
                  rowSpan={2}
                  align="center"
                  className={localClasses.tableCell}
                >
                  Grade Point
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell
                  align="center"
                  className={localClasses.tableCell}
                >
                  TH
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  className={localClasses.tableCell}
                >
                  PR
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>

            <TableBody>
              {tableData.map((item: any, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center" className={classes.cell}>
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    {item.obtain_subject_marks[0].exam_subject__subject__name}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.obtain_subject_marks[0].exam_subject__full_marks}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.obtain_subject_marks[0].exam_subject__pass_marks}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.obtain_subject_marks[0].marks_obtained_theory}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.obtain_subject_marks[0].marks_obtained_practical}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.obtain_subject_marks[0].total_marks_obtained}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.obtain_subject_marks[0].grade_point_total}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TblContainer>
        </div>
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default ExamReportTable;
