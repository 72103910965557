import {
  GET_LIVE_CLASS_LOADING,
  GET_LIVE_CLASS_ERROR,
  GET_LIVE_CLASS_SUCCESS,
  GET_ONLINE_CLASS_LOG_LOADING,
  GET_ONLINE_CLASS_LOG_ERROR,
  GET_ONLINE_CLASS_LOG_SUCCESS,
  ADD_LIVE_CLASS_LOADING,
  ADD_LIVE_CLASS_ERROR,
  ADD_LIVE_CLASS_SUCCESS,
  UPDATE_LIVE_CLASS_LOADING,
  UPDATE_LIVE_CLASS_ERROR,
  UPDATE_LIVE_CLASS_SUCCESS,
  DELETE_LIVE_CLASS_LOADING,
  DELETE_LIVE_CLASS_ERROR,
  DELETE_LIVE_CLASS_SUCCESS,
  LiveClassDispatchTypes,
  LiveClassTypeI,
} from "../../../actions/TeacherDash/TeacherLiveClass/TeacherLiveClassActionTypes";

interface InitialStateI {
  loading: boolean;
  live_class: LiveClassTypeI[];
  errors: any;
  recent: any;
  class_log: any;
}

const initialState: InitialStateI = {
  loading: false,
  live_class: [],
  errors: null,
  recent: null,
  class_log: null,
};

const TeacherLiveClassReducer = (
  state: InitialStateI = initialState,
  action: LiveClassDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_LIVE_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_LIVE_CLASS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_LIVE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        live_class: action.payload,
        recent: null,
      };

    case GET_ONLINE_CLASS_LOG_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ONLINE_CLASS_LOG_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ONLINE_CLASS_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        class_log: action.payload,
      };

    case ADD_LIVE_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_LIVE_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case ADD_LIVE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        // live_class: [...state.live_class, action.payload],
        errors: null,
        recent: action.payload,
      };

    case UPDATE_LIVE_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_LIVE_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case UPDATE_LIVE_CLASS_SUCCESS:
      const current_data: LiveClassTypeI[] = state.live_class;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        live_class: current_data,
        errors: null,
        recent: action.payload,
      };

    case DELETE_LIVE_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_LIVE_CLASS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_LIVE_CLASS_SUCCESS:
      const new_data: LiveClassTypeI[] = state.live_class;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return { ...state, loading: false, live_class: new_data };

    default:
      return state;
  }
};

export default TeacherLiveClassReducer;
