import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import LessonPlanCreate from "./Components/LessonPlanCreate";

const LessonPlanCreatePage = () => {
  return (
    <Box>
      <PageHeader
        title="Lesson Plan Create"
        module={{ name: "Academics", to: "/lesson-plan-create" }}
      />
      <PageWrapper>
        <LessonPlanCreate />
      </PageWrapper>
    </Box>
  );
};
export default LessonPlanCreatePage;
