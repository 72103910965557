// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import {
  Paper,
  TableBody,
  TextField,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { Pagination } from '@material-ui/lab';
import { useTableStyles } from '../../../../components/Styles/TableStyles';
import TableActionButtons from '../../../../components/Reusable/Buttons/TableActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import {
  T_GetMarksDetailI,
  T_GetStudentMarksI,
} from '../../../../actions/TeacherDash/TeacherExamMarks/TeacherExamMarksActionTypes';
import {
  AddTeacherExamMarks,
  PostBulkDataI,
} from '../../../../actions/TeacherDash/TeacherExamMarks/TeacherExamMarksAction';
import useTable from '../../../../components/Reusable/useStudentTable';
import UserAvatar from '../../../../components/Reusable/Profile/UserAvatar';
import getFullName from '../../../../components/utils/getFullName';
import { tblStyle } from '../../components/Styles/TeacherDashboardStyles';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  tableTitle: SearchParamI | null;
}

interface SearchParamI {
  exam_info: string;
  exam_subject: string;
  subject: string;
  grade: string;
  grade_id: string;
  section: string;
  section_id: string;
}

interface HeadCellsI {
  id: string;
  label: string;
  filter?: boolean;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  // { id: "roll_no", label: "Roll No" },
  { id: 'sn', label: 'S.N' },
  { id: 'student_first_name', label: 'Name', filter: true },
  { id: 'full_marks', label: 'Full Marks' },
  { id: 'pass_marks', label: 'Pass Marks' },
  { id: 'marks_obtained', label: 'Marks Obtained' },
  { id: 'remarks', label: 'Remarks' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const AddMarksTable = (props: PropsI) => {
  const { tableTitle } = props;
  const classes = useTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<T_GetStudentMarksI[]>([]);
  const [marksDetail, setMarksDetail] = useState<T_GetMarksDetailI[]>([]);

  const [editMode, setEditMode] = useState<boolean>(false);

  // -----------------<START> Pagination states and functions <START>-----------------------//

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //=============================== <START> REACT HOOKS <START> ===========================//
  const dispatch = useDispatch();
  const marksSelector = useSelector(
    (state: RootStore) => state.teacher_exam_marks
  );
  const marksLoading = useSelector(
    (state: RootStore) => state.exam_marks.loading
  );

  useEffect(() => {
    setLoading(marksLoading);
  }, [marksLoading]);

  useEffect(() => {
    setEditMode(false);
    if (tableTitle) {
      setTableData(marksSelector.exam_marks);

      setMarksDetail(marksSelector.marks_detail);
    }
  }, [marksSelector.exam_marks]);

  //=============================== <END> REACT HOOKS <END> ===========================//

  // -------------------------------<START> Event Handlers <START>------------------------//

  let updatedData: T_GetStudentMarksI[] = [];

  const handleEditClick = () => {
    setEditMode(true);
    updatedData = [];
  };

  const handleSaveClick = () => {
    updatedData.length && setTableData(updatedData);
    const post_data: PostBulkDataI[] = [];
    updatedData.map((element) => {
      post_data.push({
        student_academic: element.id,
        marks_obtained: parseInt(
          element.marks_obtained ? element.marks_obtained : '0'
        ),
        remarks: element.remarks ? element.remarks : '-',
      });
    });
    if (tableTitle != null && updatedData.length) {
      const params = {
        exam_info: tableTitle.exam_info,
        exam_subject: tableTitle.exam_subject,
        grade: tableTitle.grade_id,
        section: tableTitle.section_id,
      };
      dispatch(AddTeacherExamMarks(post_data, params));
    }
    setEditMode(false);
  };

  const handleInputChange = (
    field_name: string,
    value: string,
    item_id: string
  ) => {
    const tempData: T_GetStudentMarksI[] = updatedData.length
      ? updatedData
      : tableData;
    const updated: T_GetStudentMarksI[] = [];

    tempData.map((element: T_GetStudentMarksI) => {
      element.id === item_id
        ? updated.push({
          ...element,
          marks_obtained:
            field_name === 'marks' ? value : element.marks_obtained,
          remarks: field_name === 'remarks' ? value : element.remarks,
        })
        : updated.push(element);
    });

    updatedData = updated;
  };

  // -------------------------------<END> Event Handlers <END>------------------------//
  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <UserAvatar
            src={item.student_photo}
            name={getFullName(
              item?.student_first_name,
              item?.student_last_name
            )}
          />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {marksDetail[0].full_marks}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {marksDetail[0].pass_marks}
        </StyledTableCell>
        {editMode ? (
          <StyledTableCell align="center" className={classes.cell}>
            <TextField
              type="number"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('marks', event.target.value, item.id)
              }
              id={String(item.id)}
              defaultValue={item.marks_obtained}
              variant="outlined"
            />
          </StyledTableCell>
        ) : (
          <StyledTableCell align="center" className={classes.cell}>
            {item.marks_obtained}
          </StyledTableCell>
        )}
        {editMode ? (
          <StyledTableCell align="center" className={classes.cell}>
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('remarks', event.target.value, item.id)
              }
              defaultValue={item.remarks}
              variant="outlined"
            />
          </StyledTableCell>
        ) : (
          <StyledTableCell align="center" className={classes.cell}>
            {item.remarks}
          </StyledTableCell>
        )}
      </StyledTableRow>
    );
  };

  return (
    <Paper style={tblStyle}>
      <TableContainer
        label={
          tableTitle
            ? `Class ${tableTitle.grade} ${tableTitle.section && `(${tableTitle.section})`
            } ${tableTitle.subject}`
            : 'Marks List'
        }
        loading={loading}
        items={tableData}
        disableSearch
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        hiddenCols={[-1]}
      />
      {tableData.length ? (
        <Grid container justifyContent="flex-end">
          <Grid item xs={3}>
            <Grid container justifyContent="flex-end">
              {!editMode ? (
                <Button
                  onClick={handleEditClick}
                  className={classes.addMarksButton}
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  onClick={handleSaveClick}
                  className={classes.addMarksButton}
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default AddMarksTable;
