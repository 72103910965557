import { Box } from "@material-ui/core";
import StudentSyllabusManage from "./StudentSyllabusManage";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import { crumbs } from "../MyTeacher/MyTeacherPage";

const MySyllabusPage = () => {
  return (
    <Box>
      <PageHeader title="Syllabus" module={crumbs} />
      <PageWrapper>
        <StudentSyllabusManage />
      </PageWrapper>
    </Box>
  );
};

export default MySyllabusPage;
