import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CardfullWidthLayout from "../../../../../components/Reusable/Layouts/CardfullWidthLayout";
import getFullName from "../../../../../components/utils/getFullName";
import NewTabs from "../../../../../components/Student/StudentProfile/File/NewTabs";
import ChildInfoTabs from "./ChildInfoTabs";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import {
  GetChildren,
  GetViewChildData,
} from "../../../../../actions/ParentsDash/ViewChild/ViewChildAction";
import { Link } from "react-router-dom";
import {
  ViewChildDetailProps,
  ViewChildrenPropsI,
} from "../../../../../actions/ParentsDash/ViewChild/ViewChildActionTypes";
import { tblStyle } from "../../../ParentDashboardStyles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface HeadPropsI {
  id: number;
  headCells: string;
  cellData: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    "& span.MuiTab-wrapper": {
      fontSize: "14px",
      textTransform: "capitalize",
      letterSpacing: "0.02rem",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
}));

const ViewChildBar = (props: any) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [childData, setChildData] = React.useState<any | null>(
    null
  );
  const [children, setChildren] = React.useState<ViewChildrenPropsI[]>([]);
  const [childID, setChildID] = React.useState<number | null>(null);

  const [headData, setHeadData] = React.useState<HeadPropsI[]>([]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const viewChildrenSelector = useSelector(
    (state: RootStore) => state.view_child.view_child
  );

  const viewChildDataSelector = useSelector(
    (state: RootStore) => state.view_child.children
  );

  useEffect(() => {
    dispatch(GetChildren());
  }, []);

  useEffect(() => {
    const data = viewChildrenSelector;
    setChildren(data);
    data.length && setChildID(data[0].id);
  }, [viewChildrenSelector]);

  useEffect(() => {
    childID && dispatch(GetViewChildData(childID));
  }, [childID]);

  useEffect(() => {
    viewChildDataSelector && setChildData(viewChildDataSelector);
  }, [viewChildDataSelector]);

  useEffect(() => {
    childData != null &&
      setHeadData([
        {
          id: 2,
          headCells: "Adm No.:",
          cellData: childData.admission_number,
        },
        {
          id: 3,
          headCells: "Class:",
          cellData:
            childData.academic_info[0].grade_name ? childData.academic_info[0].grade_name : childData.academic_info[0].grade.name,
        },
        {
          id: 4,
          headCells: "Roll No.:",
          cellData:
            childData.academic_info[0] &&
            childData.academic_info[0].roll_number,
        },
        { id: 5, headCells: "Address:", cellData: childData.address },
      ]);
  }, [childData]);

  return (
    <div className={classes.root} style={{ ...tblStyle, padding: 0 }}>
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        style={{ borderBottom: "1px solid #f5f5f5" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {children.map((item: any, index: number) => {
            // console.log(getFullName(item.student_user.first_name, item.student_user.last_name));
            return (
              <Tab
                key={index}
                onClick={() => setChildID(item.id)}
                value={index}
                className={classes.tab}
                label={getFullName(
                  item?.student_user?.first_name,
                  item?.student_user?.last_name
                )}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {children.map((item: any, index: number) => (
        <TabPanel key={index} value={value} index={index}>
          <CardfullWidthLayout
            fullName={getFullName(
              item?.student_user?.first_name,
              item?.student_user?.last_name
            )}
            headData={headData.length ? headData : []}
            profileImg={childData?.student_photo}
            elevation={0}
          >
            <ChildInfoTabs childID={childID} />
          </CardfullWidthLayout>
        </TabPanel>
      ))}
    </div>
  );
};

export default ViewChildBar;
