import React, { useState, useEffect } from "react";
import { Typography, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import { RootStore } from "../../../../../store";
import { GetChildrenFeeInvoice } from "../../../../../actions/ParentsDash/ChildrenFee/ChildrenFeeAction";
import getMonthName from "../../../../../components/utils/getMonthName";
import InvoiceChildrenFeeTable from "./InvoiceChildrenFeeTable";

interface HeadCellsI {
    id: string;
    label: string;
}
interface TableDataI {
    id: number;
    is_monthly: boolean;
    name: string;
    amount: number;
    due_date: string;
    count: number;
}

const headCells: HeadCellsI[] = [
    { id: "name", label: "Fee Name" },
    { id: "type", label: "Type" },
    { id: "amount", label: "Amount" },
    { id: "date", label: "Due Date" },
];

const InvoiceChildrenFee = (props: any) => {
    const classes = useTableStyles();
    const dispatch = useDispatch();
    const studentSelector = useSelector((state: RootStore) => state.student_profile.student);

    const [feeData, setFeeData] = useState<any>(null);
    const [student, setStudent] = useState<any>(null);

    const feeHead = useSelector(
        (state: RootStore) => {
            return state.children_invoice.invoice;
        }
    );

    useEffect(() => {
        if (props.data) {
            setStudent(props.data)
        } else {
            setStudent(studentSelector);
        }
    }, [studentSelector])

    console.log({ student })

    useEffect(() => {
        if (student) {
            const academic_id = student?.academic_info?.[0]?.id;
            if (props.value === 0) {
                dispatch(GetChildrenFeeInvoice(academic_id));
            }
        }
    }, [student]);


    useEffect(() => {
        console.log({ feeHead })
        const paidData: any = []
        const dueData: any = []

        if (feeHead?.generated_invoices?.length > 0) {

            const data: any = []
            for (let i = 0; i < feeHead?.generated_invoices?.length; i++) {
                for (let j = 0; j < feeHead?.generated_invoices[i].invoices?.length; j++) {
                    dueData.push({
                        id: feeHead?.generated_invoices[i].invoices[j].id,
                        date: moment(feeHead?.generated_invoices[i].invoices[j].created_on).format('YYYY-MM-DD'),
                        transaction_type: feeHead?.generated_invoices[i].invoices[j].invoice_id,
                        fee_head: feeHead?.generated_invoices[i].invoices[j].month !== "" ? feeHead?.generated_invoices[i].fee_name + " - " + getMonthName(feeHead?.generated_invoices[i].invoices[j].month) : feeHead?.generated_invoices[i].fee_name,
                        period: feeHead?.generated_invoices[i].invoices[j].month !== "" ? getMonthName(feeHead?.generated_invoices[i].invoices[j].month) : feeHead?.generated_invoices[i].invoices[j].fee_type__due_date,
                        charges: feeHead?.generated_invoices[i].invoices[j].amount,
                        fine: 'Rs. 0',
                        balance: feeHead?.generated_invoices[i].invoices[j].amount,
                        status: 'Unpaid'
                    })
                }
            }
        }

        // if (feeHead?.dues !== null) {
        //     for (let i = 0; i < feeHead?.dues?.length; i++) {
        //         dueData.push({
        //             date: moment(feeHead?.dues[i]?.created_on).format('YYYY-MM-DD'),
        //             transaction_type: feeHead?.dues[i]?.paid_invoice?.receipt,
        //             fee_head: feeHead?.dues[i]?.paid_invoice?.month !== "" ? feeHead?.dues[i]?.paid_invoice?.invoice?.fee_type?.name + " - " + feeHead?.dues[i]?.paid_invoice?.month + ' (due)' : feeHead?.dues[i].invoices?.paid_invoice?.invoice?.fee_type?.name + ' (due)',
        //             period: getMonthName(feeHead?.dues[i]?.paid_invoice?.invoice?.month),
        //             charges: Number(feeHead?.dues[i]?.paid_invoice?.paid_amount) + Number(feeHead?.dues[i]?.due_amount),
        //             fine: 'Rs. 0',
        //             balance: Number(feeHead?.dues[i]?.paid_invoice?.paid_amount) + Number(feeHead?.dues[i]?.due_amount),
        //             status: 'Unpaid'
        //         })
        //     }
        // }

        // if (feeHead?.paid_invoices !== null && !props.invoiceMode) {
        //     for (let i = 0; i < feeHead?.paid_invoices?.length; i++) {
        //         if (props?.value === 1) {
        //             paidData.push({
        //                 date: moment(feeHead?.paid_invoices[i]?.created_on).format('YYYY-MM-DD'),
        //                 transaction_type: feeHead?.paid_invoices[i]?.receipt_id,
        //                 charges: feeHead?.paid_invoices[i].paid_amount,
        //                 balance: feeHead?.paid_invoices[i].paid_amount,
        //                 status: 'Paid'
        //             })
        //         } else {
        //             paidData.push({
        //                 date: moment(feeHead?.paid_invoices[i]?.created_on).format('YYYY-MM-DD'),
        //                 transaction_type: feeHead?.paid_invoices[i]?.receipt__receipt_id || feeHead?.paid_invoices[i]?.receipt_id,
        //                 fee_head: feeHead?.paid_invoices[i].invoice__month && feeHead?.paid_invoices[i].invoice__month !== "" ? feeHead?.paid_invoices[i].invoice__fee_type__name + " - " + getMonthName(feeHead?.paid_invoices[i].invoice__month) : feeHead?.paid_invoices[i].invoice__fee_type__name,
        //                 period: feeHead?.paid_invoices[i].invoice__month ? getMonthName(feeHead?.paid_invoices[i].invoice__month) : null,
        //                 charges: feeHead?.paid_invoices[i].paid_amount,
        //                 fine: feeHead?.paid_invoices[i].fine_amount ? 'Rs. ' + feeHead?.paid_invoices[i].fine_amount : 'Rs 0',
        //                 balance: feeHead?.paid_invoices[i].paid_amount,
        //                 status: 'Paid'
        //             })
        //         }
        //     }
        // }
        setFeeData(dueData)
    }, [feeHead])


    return (
        <>
            <Paper className={classes.roottable}>
                <Typography variant="h6">{props.invoiceMode ? 'Fee Invoices' : 'Fee Statements'}</Typography>
                <InvoiceChildrenFeeTable
                    student={student}
                    statementTableData={feeData}
                    feesHead={feeData}
                    value={props.value}
                    isInvoice={props.invoiceMode}
                />
            </Paper>
        </>
    );
};

export default InvoiceChildrenFee;