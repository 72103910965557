import { Grid, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../store";
import { GetStudentAcademicInfo } from "../../../../actions/ParentsDash/AcademicInfo/StudentAcademicInfoAction";
import { InputBox } from "../../../../components/Reusable/Inputs/Select";
import { GetChildrenAssignmentList } from "../../../../actions/ParentsDash/ChildrenAssignment/ChildrenAssignmentAction";
import { CUR_NEPALI_DATE } from "../../../../components/utils/nepaliDateUtils";
import SearchFormHeaderLayout from "../../../../components/Reusable/Layouts/SearchFormHeaderLayout";
import { filterStyle } from "../../ParentDashboardStyles";
import { GetChildren } from '../../../../actions/ParentsDash/ViewChild/ViewChildAction'
import getFullName from "../../../../components/utils/getFullName"
import { GetChildrenSubjects } from "../../../../actions/ParentsDash/ChildrenSubjects/ChildrenSubjectAction"
import { MdOutlineChildFriendly } from "react-icons/md";
import { SubjectI } from "../../../../actions/ParentsDash/ChildrenSubjects/ChildrenSubjectActionTypes"

// -----------------<start> Interfaces <start>-----------------------//

interface FormI {
  academic_year: string | null;
  class: number | null;
  section: string | null;
  rollNumber: string | number | null;
}

interface SectionI {
  id: number;
  title: string;
}

interface ClassI {
  id: number;
  title: string;
  sections: SectionI[] | [];
}

interface Props {
  state: {
    children: any,
    setChildren: any,
  }
}

// -----------------<end> States <end>-----------------------//

const AssignmentSearch = ({ state: { children, setChildren } }: Props) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm<FormI>({
    mode: "onChange",
  });

  const [subject, setSubject] = useState<SubjectI>();

  useEffect(() => {
    dispatch(GetChildren());
  }, []);

  useEffect(() => {
    children && dispatch(GetChildrenSubjects(children.academic_info[0].id))
  }, [children])

  const childrenState = useSelector((state: RootStore) => state.view_child)
  const subjectState = useSelector((state: RootStore) => state.children_subjects)

  const handleChildren = (value: any | null) => {
    if (value === null) return;
    setChildren(value);
    dispatch(GetChildrenAssignmentList(value.academic_info[0].id));
  };

  const handleSubject = (value: any | null) => {
    if (value === null) {
      handleChildren(children);
    } else {
      setSubject(value);
      dispatch(GetChildrenAssignmentList(children.academic_info[0].id, value.id));
    }
  };

  return (
    <>
      <Paper className={classes.pageContentBox} style={filterStyle}>
        <form className={classes.form}>
          <SearchFormHeaderLayout title="Assignment Search" />

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={3} className={classes.formWrapperRow}>
              <InputBox>Child</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ClassI | null
                ) => handleChildren(value)}
                options={childrenState.view_child}
                value={children}
                getOptionLabel={(option) => getFullName(option.student_user.first_name, option.student_user.last_name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="child_name"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapperRow}>
              <InputBox>Subject</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SubjectI | null
                ) => handleSubject(value)}
                options={subjectState?.subjects?.[0]?.subject}
                value={subject}
                disabled={children === null}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="child_name"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default AssignmentSearch;
