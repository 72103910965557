import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { Clear, Done } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { PRIMARY_DATE_FORMAT } from '../../../../common/constant';
import StaffCard, {
  StaffCardTypeI,
} from '../../../../components/Reusable/Dialogs/StaffCard.Modal';
import GetStatus from '../../../../common/GetStatus';

const LeaveRequestView = (props: any) => {
  const [staff, setStaff] = useState<StaffCardTypeI | null>(null);

  useEffect(() => {
    const item: StaffCardTypeI = {
      name: props.items.name,
      avatar: '/favicon.png',
      id: '123',
      designation: 'Student',
    };

    setStaff(item);
  }, []);

  return (
    <React.Fragment>
      {staff && <StaffCard staff={staff} />}
      <Divider style={{ marginBottom: '20px' }} />
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Leave Type:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {props.items.leave_type}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Apply Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {moment(props.items.applied_date).format(PRIMARY_DATE_FORMAT)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Leave Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {moment(props.items.leave_date).format(PRIMARY_DATE_FORMAT)} -{' '}
            {moment(props.items.days).format(PRIMARY_DATE_FORMAT)} (
            <strong>
              {moment(props.items.days).diff(props.items.leave_date, 'days')}{' '}
              days
            </strong>
            )
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Reason:
        </Grid>

        <Grid item xs={8}>
          {props.items.description || 'No Description'}
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Status:
        </Grid>

        <Grid item xs={8}>
          {/* {props.items.description || "No Description"} */}
          <GetStatus status={props.items.status} />
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Remarks:
        </Grid>

        <Grid item xs={8}>
          {props.items.remarks || <em>No Remarks</em>}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LeaveRequestView;
