import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import PageHeader from "../../../components/Header/PageHeader";
import PageWrapper from "../../../components/PageWrapper";
import AssignmentTable from "./AssignmentTable"
import AssignmentInfo from "./AssignmentInfo"
import { Tupple, assignmentStatusOptions } from "../../../../../common/json.constant"
import { GetTeacherAssignmentById } from "../../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction"

const AssignmentViewPage = () => {
    const dispatch = useDispatch()
    const { id } = useParams<{ id: string }>()
    const [selectedStatus, setSelectedStatus] = useState<Tupple | null>(assignmentStatusOptions[1]);

    useEffect(() => {
        dispatch(GetTeacherAssignmentById(id))
    }, [])

    return (
        <Box>
            <PageHeader
                title="View Student Assignment"
            />
            <PageWrapper>
                <AssignmentInfo status={{ selectedStatus, setSelectedStatus }} />
                <AssignmentTable selectedStatus={selectedStatus} />
            </PageWrapper>
        </Box>
    )
}

export default AssignmentViewPage