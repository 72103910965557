import { Paper } from '@material-ui/core';
import NepaliCalendarPage from '../../../../pages/Dashboard/Calendar/NepaliCalendar';

function TeacherEventsCalendar() {
  return (
    <Paper elevation={0}>
      <div style={{ padding: '20px 24px 24px 24px' }}>
        <NepaliCalendarPage />
      </div>
    </Paper>
  );
}

export default TeacherEventsCalendar;
