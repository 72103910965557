import React from 'react';
import { CommunicationSidebar } from '../../../components';
import AnnoucementList from '../../../components/Communication/Annoucement/AnnoucementList';
import AccountantCommunicationSidebar from '../components/AccountantCommunicationSidebar';
import SubModuleLayout from '../../../components/Reusable/Layouts/SubModuleLayout';

const AccountantAnnoucementPage = () => {
  const nav = <AccountantCommunicationSidebar announcement />;
  return (
    <SubModuleLayout communicationMenuActive sideNav={nav}>
      <AnnoucementList />
    </SubModuleLayout>
  );
};

export default AccountantAnnoucementPage;
