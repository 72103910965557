import {
  GET_MY_TEACHER_LOADING,
  GET_MY_TEACHER_ERROR,
  GET_MY_TEACHER_SUCCESS,
  MyTeacherDispatchTypes,
  StudentMyTeacherTypeI,
} from "../../actions/StudentDash/StudentMyTeacher/StudentTeacherActionTypes";

interface InitialStateI {
  loading: boolean;
  my_teachers: any[];
}

const initialState: InitialStateI = {
  loading: false,
  my_teachers: [],
};

const StudentMyTeacherReducer = (
  state: InitialStateI = initialState,
  action: MyTeacherDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_MY_TEACHER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_MY_TEACHER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_MY_TEACHER_SUCCESS:
      return {
        ...state,
        loading: false,
        my_teachers: action.payload,
      };

    default:
      return state;
  }
};

export default StudentMyTeacherReducer;
