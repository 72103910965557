import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TeacherLiveClassesWidget from "./TeacherLiveClassesWidget";
import { useDispatch, useSelector } from "react-redux";
import {
  GetTeacherDashboard,
  GetTeacherDashboardDays,
} from "../../../../actions/TeacherDash/Dasbhoard/TeacherDashboardAction";
import { RootStore } from "../../../../store";
import { DashboardOnlineClassI } from "../../../../actions/TeacherDash/Dasbhoard/TeacherDashboardActionTypes";
import {
  GetStudentDashboardDays,
  GetStudentDashboardData,
} from "../../../../actions/StudentDash/Dashboard/StudentDashboardAction";
import CustomBackdrop from "../../../../components/Reusable/Inputs/CustomBackdrop";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 238,
    "& .MuiButtonBase-root": {
      minWidth: "100% !important",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function NewLiveClassesTabs(props: any) {
  const { onlineClass, student } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [todayNumber, setTodayNumber] = useState("");
  const [liveClasses, setLiveClasses] = useState<DashboardOnlineClassI[]>([]);
  const [loading, setLoading] = useState(false);

  const LiveclassSelector = props.student
    ? useSelector(
        (state: RootStore) =>
          state.student_dashboard.student_dashboard?.live_class
      )
    : useSelector(
        (state: RootStore) => state.teacher_dashboard.dashboard?.live_class
      );

  const LiveclassLoading = props.student
    ? useSelector((state: RootStore) => state.student_dashboard?.loading)
    : useSelector((state: RootStore) => state.teacher_dashboard?.loading);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const n = new Date().getDay();
    const newdate = new Date().toISOString().slice(0, 10);
    if (props.student) {
      if (n == 0) {
        setTodayNumber("7");
        dispatch(GetStudentDashboardDays("7"));
      } else {
        setTodayNumber(n.toString());
        dispatch(GetStudentDashboardDays(n.toString()));
      }
    } else {
      if (n == 0) {
        setTodayNumber("7");
        dispatch(GetTeacherDashboardDays("7"));
      } else {
        setTodayNumber(n.toString());
        dispatch(GetTeacherDashboardDays(n.toString()));
      }
    }
  }, [props.student]);

  useEffect(() => {
    setLoading(LiveclassLoading);
    if (LiveclassSelector) {
      setLiveClasses(LiveclassSelector);
    }
  }, [LiveclassSelector]);

  return (
    <>
      <CustomBackdrop open={false} />
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab
            onClick={() =>
              props.student
                ? dispatch(GetStudentDashboardDays(todayNumber))
                : dispatch(GetTeacherDashboardDays(todayNumber))
            }
            label="Today"
            {...a11yProps(0)}
          />
          <Tab
            onClick={() =>
              props.student
                ? dispatch(GetStudentDashboardDays("7"))
                : dispatch(GetTeacherDashboardDays("7"))
            }
            label="Sunday"
            {...a11yProps(1)}
          />
          <Tab
            onClick={() =>
              props.student
                ? dispatch(GetStudentDashboardDays("1"))
                : dispatch(GetTeacherDashboardDays("1"))
            }
            label="Monday"
            {...a11yProps(2)}
          />
          <Tab
            onClick={() =>
              props.student
                ? dispatch(GetStudentDashboardDays("2"))
                : dispatch(GetTeacherDashboardDays("2"))
            }
            label="Tuesday"
            {...a11yProps(3)}
          />
          <Tab
            onClick={() =>
              props.student
                ? dispatch(GetStudentDashboardDays("3"))
                : dispatch(GetTeacherDashboardDays("3"))
            }
            label="Wednesday"
            {...a11yProps(4)}
          />
          <Tab
            onClick={() =>
              props.student
                ? dispatch(GetStudentDashboardDays("4"))
                : dispatch(GetTeacherDashboardDays("4"))
            }
            label="Thursday"
            {...a11yProps(5)}
          />
          <Tab
            onClick={() =>
              props.student
                ? dispatch(GetStudentDashboardDays("5"))
                : dispatch(GetTeacherDashboardDays("5"))
            }
            label="Friday"
            {...a11yProps(6)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <TeacherLiveClassesWidget
            student={props.student}
            getToday
            onlineClass={liveClasses}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TeacherLiveClassesWidget onlineClass={LiveclassSelector} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TeacherLiveClassesWidget onlineClass={LiveclassSelector} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TeacherLiveClassesWidget onlineClass={LiveclassSelector} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <TeacherLiveClassesWidget onlineClass={LiveclassSelector} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <TeacherLiveClassesWidget onlineClass={LiveclassSelector} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <TeacherLiveClassesWidget onlineClass={LiveclassSelector} />
        </TabPanel>
      </div>
    </>
  );
}
