export const GET_TEACHER_ASSIGNMENT_LOADING = "GET_TEACHER_ASSIGNMENT_LOADING";
export const GET_TEACHER_ASSIGNMENT_ERROR = "GET_TEACHER_ASSIGNMENT_ERROR";
export const GET_TEACHER_ASSIGNMENT_SUCCESS = "GET_TEACHER_ASSIGNMENT_SUCCESS";
export const GET_SUBMITTED_ASSIGNMENT_LOADING =
  "GET_SUBMITTED_ASSIGNMENT_LOADING";
export const GET_SUBMITTED_ASSIGNMENT_ERROR = "GET_SUBMITTED_ASSIGNMENT_ERROR";
export const GET_SUBMITTED_ASSIGNMENT_SUCCESS =
  "GET_SUBMITTED_ASSIGNMENT_SUCCESS";
export const GET_UNSUBMITTED_ASSIGNMENT_LOADING =
  "GET_UNSUBMITTED_ASSIGNMENT_LOADING";
export const GET_UNSUBMITTED_ASSIGNMENT_ERROR = "GET_UNSUBMITTED_ASSIGNMENT_ERROR";
export const GET_UNSUBMITTED_ASSIGNMENT_SUCCESS =
  "GET_UNSUBMITTED_ASSIGNMENT_SUCCESS";

export const ADD_TEACHER_ASSIGNMENT_LOADING = "ADD_TEACHER_ASSIGNMENT_LOADING";
export const ADD_TEACHER_ASSIGNMENT_ERROR = "ADD_TEACHER_ASSIGNMENT_ERROR";
export const ADD_TEACHER_ASSIGNMENT_SUCCESS = "ADD_TEACHER_ASSIGNMENT_SUCCESS";
export const UPDATE_TEACHER_ASSIGNMENT_LOADING =
  "UPDATE_TEACHER_ASSIGNMENT_LOADING";
export const UPDATE_TEACHER_ASSIGNMENT_ERROR =
  "UPDATE_TEACHER_ASSIGNMENT_ERROR";
export const UPDATE_TEACHER_ASSIGNMENT_SUCCESS =
  "UPDATE_TEACHER_ASSIGNMENT_SUCCESS";
export const DELETE_TEACHER_ASSIGNMENT_LOADING =
  "DELETE_TEACHER_ASSIGNMENT_LOADING";
export const DELETE_TEACHER_ASSIGNMENT_ERROR =
  "DELETE_TEACHER_ASSIGNMENT_ERROR";
export const DELETE_TEACHER_ASSIGNMENT_SUCCESS =
  "DELETE_TEACHER_ASSIGNMENT_SUCCESS";

export const GET_TEACHER_ASSIGNMENT_BY_ID_LOADING =
  "GET_TEACHER_ASSIGNMENT_BY_ID_LOADING";
export const GET_TEACHER_ASSIGNMENT_BY_ID_ERROR =
  "GET_TEACHER_ASSIGNMENT_BY_ID_ERROR";
export const GET_TEACHER_ASSIGNMENT_BY_ID_SUCCESS =
  "GET_TEACHER_ASSIGNMENT_BY_ID_SUCCESS";
export const GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING =
  "GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING";
export const GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR =
  "GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR";
export const GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS =
  "GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS";
export const ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING =
  "ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING";
export const ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR =
  "ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR";
export const ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS =
  "ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS";

export const ASSIGN_MARKS_ASSIGNMENT_LOADING = "ASSIGN_MARKS_ASSIGNMENT_LOADING"
export const ASSIGN_MARKS_ASSIGNMENT_ERROR = "ASSIGN_MARKS_ASSIGNMENT_ERROR"
export const ASSIGN_MARKS_ASSIGNMENT_SUCCESS = "ASSIGN_MARKS_ASSIGNMENT_SUCCESS"

export const GET_ASSIGNMENT_FILES_LOADING = "GET_ASSIGNMENT_FILES_LOADING"
export const GET_ASSIGNMENT_FILES_ERROR = "GET_ASSIGNMENT_FILES_ERROR"
export const GET_ASSIGNMENT_FILES_SUCCESS = "GET_ASSIGNMENT_FILES_SUCCESS"

export const ADD_ASSIGNMENT_FILES_LOADING = "ADD_ASSIGNMENT_FILES_LOADING"
export const ADD_ASSIGNMENT_FILES_ERROR = "ADD_ASSIGNMENT_FILES_ERROR"
export const ADD_ASSIGNMENT_FILES_SUCCESS = "ADD_ASSIGNMENT_FILES_SUCCESS"


export const DELETE_ASSIGNMENT_FILES_LOADING = "DELETE_ASSIGNMENT_FILES_LOADING"
export const DELETE_ASSIGNMENT_FILES_ERROR = "DELETE_ASSIGNMENT_FILES_ERROR"
export const DELETE_ASSIGNMENT_FILES_SUCCESS = "DELETE_ASSIGNMENT_FILES_SUCCESS"

export const GET_ASSIGNMENT_SUBJECT_LOADING = "GET_ASSIGNMENT_SUBJECT_LOADING"
export const GET_ASSIGNMENT_SUBJECT_ERROR = "GET_ASSIGNMENT_SUBJECT_ERROR"
export const GET_ASSIGNMENT_SUBJECT_SUCCESS = "GET_ASSIGNMENT_SUBJECT_SUCCESS"

//===============================<START>GET TEACHER ASSIGNMENT <START>======================//

export interface GetTeacherAssignmentLoading {
  type: typeof GET_TEACHER_ASSIGNMENT_LOADING;
}

export interface GetTeacherAssignmentError {
  type: typeof GET_TEACHER_ASSIGNMENT_ERROR;
}

export interface GetTeacherAssignmentSuccess {
  type: typeof GET_TEACHER_ASSIGNMENT_SUCCESS;
  payload: any;
}

//===============================<END>GET TEACHER ASSIGNMENT <END>======================//

//===============================<START>ADD TEACHER ASSIGNMENT <START>======================//

export interface AddTeacherAssignmentLoading {
  type: typeof ADD_TEACHER_ASSIGNMENT_LOADING;
}

export interface AddTeacherAssignmentError {
  type: typeof ADD_TEACHER_ASSIGNMENT_ERROR;
  payload: any;
}

export interface AddTeacherAssignmentSuccess {
  type: typeof ADD_TEACHER_ASSIGNMENT_SUCCESS;
  payload: any;
}

//===============================<END>ADD TEACHER ASSIGNMENT <END>======================//

//===============================<START>DELETE TEACHER ASSIGNMENT <START>======================//

export interface GetTeacherAssignmentByIdLoading {
  type: typeof GET_TEACHER_ASSIGNMENT_BY_ID_LOADING;
}

export interface GetTeacherAssignmentByIdError {
  type: typeof GET_TEACHER_ASSIGNMENT_BY_ID_ERROR;
  payload: any;
}

export interface GetTeacherAssignmentByIdSuccess {
  type: typeof GET_TEACHER_ASSIGNMENT_BY_ID_SUCCESS;
  payload: any;
}

//===============================<END>DELETE TEACHER ASSIGNMENT <END>======================//

//===============================<START>DELETE TEACHER ASSIGNMENT <START>======================//

export interface DeleteTeacherAssignmentLoading {
  type: typeof DELETE_TEACHER_ASSIGNMENT_LOADING;
}

export interface DeleteTeacherAssignmentError {
  type: typeof DELETE_TEACHER_ASSIGNMENT_ERROR;
  payload: any;
}

export interface DeleteTeacherAssignmentSuccess {
  type: typeof DELETE_TEACHER_ASSIGNMENT_SUCCESS;
  payload: any;
}

//===============================<END>DELETE TEACHER ASSIGNMENT <END>======================//

//===============================<START>DELETE TEACHER ASSIGNMENT <START>======================//

export interface UpdateTeacherAssignmentLoading {
  type: typeof UPDATE_TEACHER_ASSIGNMENT_LOADING;
}

export interface UpdateTeacherAssignmentError {
  type: typeof UPDATE_TEACHER_ASSIGNMENT_ERROR;
  payload: any;
}

export interface UpdateTeacherAssignmentSuccess {
  type: typeof UPDATE_TEACHER_ASSIGNMENT_SUCCESS;
  payload: any;
}

//===============================<END>DELETE TEACHER ASSIGNMENT <END>======================//

//===============================<START>GET ADD MARKS TEACHER ASSIGNMENT <START>======================//

export interface AddMarksTecherAssignmentLoading {
  type: typeof ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING;
}

export interface AddMarksTecherAssignmentError {
  type: typeof ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR;
}

export interface AddMarksTecherAssignmentSuccess {
  type: typeof ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS;
  payload: any;
}

//===============================<END>GET ADD MARKS TEACHER ASSIGNMENT <END>======================//

//===============================<START>GET ADD MARKS TEACHER ASSIGNMENT <START>======================//

export interface GetAddMarksTecherAssignmentLoading {
  type: typeof GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING;
}

export interface GetAddMarksTecherAssignmentError {
  type: typeof GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR;
  payload: any;
}

export interface GetAddMarksTecherAssignmentSuccess {
  type: typeof GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS;
  payload: any;
}

//===============================<END>GET ADD MARKS TEACHER ASSIGNMENT <END>======================//

//===============================<START>GET SUBMITTED ASSIGNMENT <START>======================//
export interface GetSubmittedAssignmentLoading {
  type: typeof GET_SUBMITTED_ASSIGNMENT_LOADING;
}

export interface GetSubmittedAssignmentError {
  type: typeof GET_SUBMITTED_ASSIGNMENT_ERROR;
  payload: any;
}

export interface GetSubmittedAssignmentSuccess {
  type: typeof GET_SUBMITTED_ASSIGNMENT_SUCCESS;
  payload: any;
}
//===============================<END>GET SUBMITTED ASSIGNMENT <END>======================//

//===============================<START>GET UNSUBMITTED ASSIGNMENT <START>======================//
export interface GetUnSubmittedAssignmentLoading {
  type: typeof GET_UNSUBMITTED_ASSIGNMENT_LOADING;
}

export interface GetUnSubmittedAssignmentError {
  type: typeof GET_UNSUBMITTED_ASSIGNMENT_ERROR;
  payload: any;
}

export interface GetUnSubmittedAssignmentSuccess {
  type: typeof GET_UNSUBMITTED_ASSIGNMENT_SUCCESS;
  payload: any;
}
//===============================<END>GET UNSUBMITTED ASSIGNMENT <END>======================//

//===============================<START>ASSIGN MARKS ASSIGNMENT <START>======================//
export interface AssignMarksTeacherAssignmentLoading {
  type: typeof ASSIGN_MARKS_ASSIGNMENT_LOADING;
}

export interface AssignMarksTeacherAssignmentError {
  type: typeof ASSIGN_MARKS_ASSIGNMENT_ERROR;
  payload: any;
}

export interface AssignMarksTeacherAssignmentSuccess {
  type: typeof ASSIGN_MARKS_ASSIGNMENT_SUCCESS;
  payload: any;
}
//===============================<END>GET ADD MARKS TEACHER ASSIGNMENT <END>======================//

//===============================<START>GET ASSIGNMENT FILES<START>======================//
export interface GetAssignmentFilesLoading {
  type: typeof GET_ASSIGNMENT_FILES_LOADING;
}

export interface GetAssignmentFilesError {
  type: typeof GET_ASSIGNMENT_FILES_ERROR;
  payload: any;
}

export interface GetAssignmentFilesSuccess {
  type: typeof GET_ASSIGNMENT_FILES_SUCCESS;
  payload: any;
}
//===============================<END>GET ASSIGNMENT FILES<END>======================//

//===============================<START>ADD ASSIGNMENT FILES<START>======================//
export interface AddAssignmentFilesLoading {
  type: typeof ADD_ASSIGNMENT_FILES_LOADING;
}

export interface AddAssignmentFilesError {
  type: typeof ADD_ASSIGNMENT_FILES_ERROR;
  payload: any;
}

export interface AddAssignmentFilesSuccess {
  type: typeof ADD_ASSIGNMENT_FILES_SUCCESS;
  payload: any;
}
//===============================<END>ADD ASSIGNMENT FILES<END>======================//

//===============================<START>DELETE ASSIGNMENT FILES<START>======================//
export interface DeleteAssignmentFilesLoading {
  type: typeof DELETE_ASSIGNMENT_FILES_LOADING;
}

export interface DeleteAssignmentFilesError {
  type: typeof DELETE_ASSIGNMENT_FILES_ERROR;
  payload: any;
}

export interface DeleteAssignmentFilesSuccess {
  type: typeof DELETE_ASSIGNMENT_FILES_SUCCESS;
  payload: any;
}
//===============================<END>DELETE ASSIGNMENT FILES<END>======================//

//===============================<START>GET ASSIGNMENT SUBJECTS<START>======================//
export interface GetAssignmentSubjectsLoading {
  type: typeof GET_ASSIGNMENT_SUBJECT_LOADING;
}

export interface GetAssignmentSubjectsError {
  type: typeof GET_ASSIGNMENT_SUBJECT_ERROR;
  payload: any;
}

export interface GetAssignmentSubjectsSuccess {
  type: typeof GET_ASSIGNMENT_SUBJECT_SUCCESS;
  payload: any;
}
//===============================<END>GET ASSIGNMENT SUBJECTS<END>======================//

export type TeacherAssignmentDispatchTypes =
  | GetTeacherAssignmentLoading
  | GetTeacherAssignmentError
  | GetTeacherAssignmentSuccess
  | GetSubmittedAssignmentLoading
  | GetSubmittedAssignmentError
  | GetSubmittedAssignmentSuccess
  | GetUnSubmittedAssignmentLoading
  | GetUnSubmittedAssignmentError
  | GetUnSubmittedAssignmentSuccess
  | AddTeacherAssignmentLoading
  | AddTeacherAssignmentError
  | AddTeacherAssignmentSuccess
  | DeleteTeacherAssignmentLoading
  | DeleteTeacherAssignmentError
  | DeleteTeacherAssignmentSuccess
  | GetTeacherAssignmentByIdLoading
  | GetTeacherAssignmentByIdError
  | GetTeacherAssignmentByIdSuccess
  | UpdateTeacherAssignmentLoading
  | UpdateTeacherAssignmentError
  | UpdateTeacherAssignmentSuccess
  | AddMarksTecherAssignmentLoading
  | AddMarksTecherAssignmentError
  | AddMarksTecherAssignmentSuccess
  | GetAddMarksTecherAssignmentLoading
  | GetAddMarksTecherAssignmentError
  | GetAddMarksTecherAssignmentSuccess
  | AssignMarksTeacherAssignmentLoading
  | AssignMarksTeacherAssignmentError
  | AssignMarksTeacherAssignmentSuccess
  | GetAssignmentFilesLoading
  | GetAssignmentFilesError
  | GetAssignmentFilesSuccess
  | AddAssignmentFilesLoading
  | AddAssignmentFilesError
  | AddAssignmentFilesSuccess
  | DeleteAssignmentFilesLoading
  | DeleteAssignmentFilesError
  | DeleteAssignmentFilesSuccess
  | GetAssignmentSubjectsLoading
  | GetAssignmentSubjectsError
  | GetAssignmentSubjectsSuccess;
