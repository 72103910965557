import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_CHILDREN_TIMETABLE_ERROR,
  GET_CHILDREN_TIMETABLE_LOADING,
  GET_CHILDREN_TIMETABLE_SUCCESS,
  GET_CHILDREN_PERIOD_ERROR,
  GET_CHILDREN_PERIOD_LOADING,
  GET_CHILDREN_PERIOD_SUCCESS,
  ChildrenTimeTableDispatchTypes,
} from "./ChildrenTimeTableActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { PARENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetChildrenTimeTable =
  (id: string) =>
    async (dispatch: Dispatch<ChildrenTimeTableDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_CHILDREN_TIMETABLE_LOADING,
        });

        const res = await axios.get(
          `${PARENT_API_URL}/timetable/?student_academic_id=${id}`,
          HeaderConfig()
        );
        dispatch({
          type: GET_CHILDREN_TIMETABLE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_CHILDREN_TIMETABLE_ERROR,
        });
      }
    };

export const GetChildrenPeriod =
  (id: string) =>
    async (dispatch: Dispatch<ChildrenTimeTableDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_CHILDREN_PERIOD_LOADING,
        });

        const res = await axios.get(
          `${PARENT_API_URL}/periods/?student_academic_id=${id}`,
          HeaderConfig()
        );
        dispatch({
          type: GET_CHILDREN_PERIOD_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_CHILDREN_PERIOD_ERROR,
        });
      }
    };

