export const GET_CHILDREN_SUBJECTS_LOADING = "GET_CHILDREN_SUBJECTS_LOADING";
export const GET_CHILDREN_SUBJECTS_ERROR = "GET_CHILDREN_SUBJECTS_ERROR";
export const GET_CHILDREN_SUBJECTS_SUCCESS = "GET_CHILDREN_SUBJECTS_SUCCESS";

//===============================<START>GET Children Subjects<START>======================//

export interface SubjectI {
  id: string;
  name: string;
  credit_hour: number;
  subject_code: string;
  subject_type: number;
  is_optional: boolean;
  subject: string;
  created_by: string;
  general_info: number;
}
interface SectionI {
  id: string;
  name: string;
  created_by: string;
  general_info: number;
}
export interface ChildrenSubjectTypeI {
  id: string;
  name: string;
  subject: SubjectI[];
  description: string;
  section: SectionI[];
  grade: string;
  grade_name: string;
  created_by: string;
  general_info: number;
}

export interface GetChildrenSubjectsLoading {
  type: typeof GET_CHILDREN_SUBJECTS_LOADING;
}

export interface GetChildrenSubjectsError {
  type: typeof GET_CHILDREN_SUBJECTS_ERROR;
}

export interface GetChildrenSubjectsSuccess {
  type: typeof GET_CHILDREN_SUBJECTS_SUCCESS;
  payload: ChildrenSubjectTypeI[];
}
//===============================<END>GET Children Subjects<END>======================//

export type ChildrenSubjectsDispatchTypes =
  | GetChildrenSubjectsLoading
  | GetChildrenSubjectsError
  | GetChildrenSubjectsSuccess;
