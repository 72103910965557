import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_CLASS_ATTENDANCE_LOADING,
  GET_CLASS_ATTENDANCE_ERROR,
  GET_CLASS_ATTENDANCE_SUCCESS,
  POST_CLASS_ATTENDANCE_ERROR,
  POST_CLASS_ATTENDANCE_LOADING,
  POST_CLASS_ATTENDANCE_SUCCESS,
  ClassAttendanceDispatchTypes,
  GetClassAttendanceTypeI,
} from "./ClassAttendanceActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

interface GetRequestParamsI {
  grade: string;
  date: string;
  section: string;
}

interface PostBulkDataI {
  student_academic: string;
  status: "P" | "A" | "I";
}

export const GetClassAttendance =
  (request_params: GetRequestParamsI) =>
  async (dispatch: Dispatch<ClassAttendanceDispatchTypes | SetSnackBarI>) => {
    const { grade, date, section } = request_params;
    try {
      dispatch({
        type: GET_CLASS_ATTENDANCE_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/attendance/?grade=${grade}&date=${date}&section=${section}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_CLASS_ATTENDANCE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CLASS_ATTENDANCE_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Attendance Loading Failed",
      //   },
      // });
    }
  };

export const PostClassAttendance =
  (post_data: PostBulkDataI[], q_params: GetRequestParamsI) =>
  async (dispatch: Dispatch<ClassAttendanceDispatchTypes | SetSnackBarI>) => {
    const { grade, date, section } = q_params;
    try {
      dispatch({
        type: POST_CLASS_ATTENDANCE_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/attendance/?grade=${grade}&date=${date}&section=${section}`,
        post_data,
        HeaderConfig()
      );

      dispatch({
        type: POST_CLASS_ATTENDANCE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Attendance Submitted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: POST_CLASS_ATTENDANCE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Attendance Submission Failed",
        },
      });
    }
  };
