import {
  GET_STUDENT_TIMETABLE_LOADING,
  GET_STUDENT_TIMETABLE_ERROR,
  GET_STUDENT_TIMETABLE_SUCCESS,
  GET_STUDENT_PERIOD_ERROR,
  GET_STUDENT_PERIOD_LOADING,
  GET_STUDENT_PERIOD_SUCCESS,
  StudentTimeTableDispatchTypes,
  StudentTimeTableI,
  StudentPeriods,
} from "../../actions/StudentDash/StudentTimeTable/StudentTimeTableActionTypes";

interface InitialStateI {
  loading: boolean;
  timeTable: StudentTimeTableI;
  periods: StudentPeriods;
}

const initialState: InitialStateI = {
  loading: false,
  timeTable: {} as StudentTimeTableI,
  periods: {} as StudentPeriods,
};

const StudentTimeTableReducer = (
  state: InitialStateI = initialState,
  action: StudentTimeTableDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STUDENT_TIMETABLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_TIMETABLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_TIMETABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        timeTable: action.payload,
      };

    case GET_STUDENT_PERIOD_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_PERIOD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        periods: action.payload,
      };

    default:
      return state;
  }
};

export default StudentTimeTableReducer;
