export const GET_TEACHER_SUBJECT_LOADING = "GET_TEACHER_SUBJECT_LOADING";
export const GET_TEACHER_SUBJECT_ERROR = "GET_TEACHER_SUBJECT_ERROR";
export const GET_TEACHER_SUBJECT_SUCCESS = "GET_TEACHER_SUBJECT_SUCCESS";

export const GET_TEACHER_ALL_SUBJECTS_LOADING =
  "GET_TEACHER_ALL_SUBJECTS_LOADING";
export const GET_TEACHER_ALL_SUBJECTS_ERROR = "GET_TEACHER_ALL_SUBJECTS_ERROR";
export const GET_TEACHER_ALL_SUBJECTS_SUCCESS =
  "GET_TEACHER_ALL_SUBJECTS_SUCCESS";

export const GET_TEACHER_SUBJECTS_BY_CLASS_LOADING =
  "GET_TEACHER_SUBJECTS_BY_CLASS_LOADING";
export const GET_TEACHER_SUBJECTS_BY_CLASS_ERROR = "GET_TEACHER_SUBJECTS_BY_CLASS_ERROR";
export const GET_TEACHER_SUBJECTS_BY_CLASS_SUCCESS =
  "GET_TEACHER_SUBJECTS_BY_CLASS_SUCCESS";

export type TeacherSubjectsI = {
  grade: string;
  section: string | null;
  grade_name: string;
  section_name?: string;
  subject: string;
  subject_name: string;
  subject_type: string;
  subject_credit_hour: string;
};

export type TeacherSubjectByClass = {
  id: string;
  name: string;
  subject_code: string;
  credit_hour: number;
  subject_type: number;
}

export type TeacherAllSubjectsI = {
  id: string;
  name: string;
}
//===============================<START>GET TEACHER SUBJECT <START>======================//

export interface GetTeacherSubjectLoading {
  type: typeof GET_TEACHER_SUBJECT_LOADING;
}

export interface GetTeacherSubjectError {
  type: typeof GET_TEACHER_SUBJECT_ERROR;
}

export interface GetTeacherSubjectSuccess {
  type: typeof GET_TEACHER_SUBJECT_SUCCESS;
  payload: TeacherSubjectsI[];
}

//===============================<END>GET TEACHER SUBJECT <END>======================//

//===============================<START>GET TEACHER ALL SUBJECTS <START>======================//

export interface GetTeacherAllSubjectsLoading {
  type: typeof GET_TEACHER_ALL_SUBJECTS_LOADING;
}

export interface GetTeacherAllSubjectsError {
  type: typeof GET_TEACHER_ALL_SUBJECTS_ERROR;
}

export interface GetTeacherAllSubjectsSuccess {
  type: typeof GET_TEACHER_ALL_SUBJECTS_SUCCESS;
  payload: TeacherAllSubjectsI[];
}

//===============================<END>GET TEACHER ALL SUBJECTS <END>======================//

//===============================<START>GET TEACHER SUBJECTS BY CLASS<START>======================//

export interface GetTeacherSubjectsByClassLoading {
  type: typeof GET_TEACHER_SUBJECTS_BY_CLASS_LOADING;
}

export interface GetTeacherSubjectsByClassError {
  type: typeof GET_TEACHER_SUBJECTS_BY_CLASS_ERROR;
}

export interface GetTeacherSubjectsByClassSuccess {
  type: typeof GET_TEACHER_SUBJECTS_BY_CLASS_SUCCESS;
  payload: TeacherSubjectByClass[];
}

//===============================<END>GET TEACHER SUBJECTS BY CLASS<END>======================//

export type TeacherSubjectDispatchTypes =
  | GetTeacherSubjectLoading
  | GetTeacherSubjectError
  | GetTeacherSubjectSuccess
  | GetTeacherAllSubjectsLoading
  | GetTeacherAllSubjectsError
  | GetTeacherAllSubjectsSuccess
  | GetTeacherSubjectsByClassLoading
  | GetTeacherSubjectsByClassSuccess
  | GetTeacherSubjectsByClassError;
