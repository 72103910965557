import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Khalti from '../../../../../components/DigitalPayment/Khalti/Khalti';

interface PropsI {
  open: boolean;
  onClose: (value: boolean) => void;
  deleteConfirmed: (value: boolean) => void;
  onPayClick: (value: string) => void;
  dialogTitle?: string;
  okText?: string;
  title?: string;
  children?: React.ReactNode;
  amount: number;
  payment?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalHeader: {
      padding: theme.spacing(1, 3),
      color: '#fff',
      backgroundColor: '#5767a4',
      '& .MuiTypography-body1': {},
    },
    modalContainer: {
      padding: theme.spacing(3, 1),
    },
    modalFooter: {
      padding: theme.spacing(0, 0, 4, 0),
      '& .MuiButton-contained': {
        color: '#fff',
        backgroundColor: '#3d6392',
      },
    },
  })
);

const paymentDialog = (props: PropsI) => {
  const classes = useStyles();
  const { open, onClose, deleteConfirmed, amount, onPayClick, payment } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleDeleteConfirmed = () => {
    deleteConfirmed(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="xl"
    >
      <div style={{ minWidth: '350px' }}>
        <Grid
          container
          className={classes.modalHeader}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>
            {props.title ? props.title : 'Make a payment?'}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </Grid>
        <Grid
          container
          className={classes.modalContainer}
          justifyContent="center"
        >
          <Typography>
            {props.dialogTitle
              ? props.dialogTitle
              : 'Are you sure to make a payment'}
          </Typography>
        </Grid>
        {props.children}
        <Grid
          container
          className={classes.modalFooter}
          justifyContent="space-around"
        >
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>
          {payment?.key === 'khalti' ? (
            <Khalti
              amount={amount * 100}
              title={`Confirm Paying Rs. ${amount}`}
              onPaymentConfirm={(token: string) => onPayClick(token)}
              handleClose={handleClose}
            />
          ) : (
            <Button onClick={() => onPayClick('')} variant="contained">
              {`Confirm Paying Rs. ${amount}`}
            </Button>
          )}
        </Grid>
      </div>
    </Dialog>
  );
};

export default paymentDialog;
