import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_CLASS_ATTENDANCE_LOADING,
  GET_CLASS_ATTENDANCE_ERROR,
  GET_CLASS_ATTENDANCE_SUCCESS,
  ClassAttendanceDispatchTypes,
  QueryI,
} from "./AttendanceReportActionTypes";

import { SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetClassAttendanceReport =
  (query: any) =>
  async (dispatch: Dispatch<ClassAttendanceDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_CLASS_ATTENDANCE_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/attendance_detail?grade=${query.grade}&section=${query.section}&from_date=${query.from_date}&to_date=${query.to_date}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_CLASS_ATTENDANCE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CLASS_ATTENDANCE_ERROR,
      });
    }
  };
