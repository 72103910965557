import { Box } from "@material-ui/core";
import React from "react";
import MySubjectsList from "./MySubjectsList";
import MySubjectsSearch from "./MySubjectsSearch";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";

const TeacherSubjectsPage = () => {
  return (
    <>
      <Box>
        <PageHeader
          title="My Subjects"
          module={{ name: "Academics", to: "/my-subjects" }}
        />
        <PageWrapper>
          <MySubjectsSearch />
          <MySubjectsList />
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherSubjectsPage;
