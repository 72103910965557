import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { useFormStyles } from "../../Styles/FormStyles";
import AddNewButton from "../../Reusable/Buttons/AddNewButton";
import { SubjectTypeI } from "../../../actions/Academics/Subject/SubjectActionTypes";
import {
  AddSubject,
  UpdateSubject,
} from "../../../actions/Academics/Subject/SubjectAction";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { CusRadio, CusRadioGroup } from "../../Reusable/Inputs/Radio";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";

// -----------------<start> Styling <start>-----------------------//
// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//
interface PropsI {
  editData: SubjectTypeI | null;
  onEditMode: (value: boolean) => void;
}

interface FormInputI {
  name: string;
  subject_code: string;
  credit_hour: number;
}
// -----------------<end> Interfaces <end>-----------------------//

const SubjectForm = (props: PropsI) => {
  const { editData, onEditMode } = props;
  const classes = useFormStyles();

  // -----------------<start> States <start>-----------------------//
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);

  const [selectedRadioValue, setSelectedRadioValue] = useState<string>("");
  const [optionalSubject, setOptionalSubject] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  //============================<START> REDUCER <START>==========================//

  const dispatch = useDispatch();
  const subjectLoading = useSelector(
    (state: RootStore) => state.subject.loading
  );
  const add_or_update = useSelector(
    (state: RootStore) => state.subject.add_or_update
  );

  //============================<END> REDUCER <END>==========================//

  //============================<START> REDUX HOOKS <START>==========================//

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      setEditMode(false);
      setEditID(null);
    }
  }, [add_or_update]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  useEffect(() => {
    setLoading(subjectLoading);
  }, [subjectLoading]);
  //============================<START> REDUX HOOKS <START>==========================//

  // -----------------<end> States <end>-----------------------//
  //============================<START> EVENT HANDLERS <START>==========================//
  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    setError,
    setValue,
    reset,
  } = useForm<any>({
    mode: "onChange",
  });

  const onFormSubmit = (data: FormInputI) => {
    setIsBtnLoading(true);
    const submitData = {
      ...data,
      subject_code: data.subject_code.toUpperCase(),
      subject_type: selectedRadioValue,
      is_optional: optionalSubject,
    };

    editID != null
      ? dispatch(UpdateSubject(editID, submitData))
      : dispatch(AddSubject(submitData));
  };

  const handleReset = () => {
    setEditMode(false);
    reset();
    setOptionalSubject(false);
    setSelectedRadioValue("");
    setEditID(null);
    onEditMode(false);
  };

  const handleEditTableData = (data: SubjectTypeI) => {
    setEditMode(true);
    setEditID(data.id);

    setValue("name", data.name);
    setValue("subject_code", data.subject_code);
  };

  //============================<END> EVENT HANDLERS <END>==========================//

  const subjectTypeList = [
    { id: 1, name: "Theory" },
    { id: 2, name: "Theory & Practical" },
  ];

  //----------------------ERROR HANDLING---------------------------//
  const [serverErrors, setServerErrors] = useState<any>(null);
  const subjectSelector = useSelector((state: RootStore) => state.subject);
  const errorsData = subjectSelector.errors;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: "serverSideError",
            message: errorsData.error[elem] && errorsData.error[elem],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (subjectSelector?.recent) {
      handleReset();
    }
  }, [subjectSelector]);

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Subject" : "Add Subject"}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedTextField
          label=" Subject Title"
          placeholder="Title"
          name="name"
          required
          error={errors["name"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
          onChange={() => clearErrors("detail")}
        />
        <span className={classes.validationErrorInfo}>
          {errors.detail?.type === "serverSideError" && errors.detail.message}
        </span>
        <CustomizedTextField
          label=" Subject Code"
          placeholder="Subject Code"
          name="subject_code"
          required
          error={errors["subject_code"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
          // onChange={() => clearErrors("")}
        />
      </FormLayout>
    </>
  );
};

export default SubjectForm;
