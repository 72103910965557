import { useDispatch, useSelector } from "react-redux"
import { RootStore } from "../../../../../store"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  LinearProgress
} from '@material-ui/core';
import styled from 'styled-components';
import { useEffect } from "react";
import { GetStaffProfileLeave } from "../../../../../actions/HumanResource/StaffProfile/StaffProfileAction"
import { StaffLeaveInfoType } from "../../../../../actions/HumanResource/StaffProfile/StaffProfileActionTypes"

const StyledTableContainer = styled(TableContainer)`
  padding-bottom: 1rem;
  max-width: 640px;

  th,
  td {
    padding: 8px !important;
  }
`;

export default function StaffLeaveInfo(props: any) {
  const dispatch = useDispatch();
  const { staff_profile, staff_leave, loading } = useSelector((state: RootStore) => state.staffProfile)

  useEffect(() => {
    staff_profile && dispatch(GetStaffProfileLeave(staff_profile.id))
  }, [staff_profile])

  return (
    <>
      {loading && (
        <LinearProgress style={{ margin: "-24px -24px 24px -24px" }} />
      )}
      <StyledTableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>S.N.</TableCell>
              <TableCell align="left">Leave Type</TableCell>
              <TableCell align="right">Total Leave (days)</TableCell>
              <TableCell align="right">Leave Used (days)</TableCell>
              <TableCell align="right">Leave Remaining (days)</TableCell>
              <TableCell align="right">Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {staff_leave.map((row: StaffLeaveInfoType, index: number) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="left">{row.leave_type.name}</TableCell>
                <TableCell align="right">{row.leave_type.max_leave}</TableCell>
                <TableCell align="right">{row.total_used}</TableCell>
                <TableCell align="right">{row.leave_type.max_leave - row.total_used}</TableCell>
                <TableCell align="right">{row.reason_for_leave}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
}
