import React from "react";
import { Box, Grid } from "@material-ui/core";
import { FullSubModuleLayout } from "../../../../components/Reusable/Layouts/SubModuleLayout";
import ParentsDashSidebar from "../../../../components/ParentsDash/ParentsDashSidebar";
import ParentEventAnnounce from "./Components/ParentEventAnnounce";
import ParentEventTable from "./Components/ParentEventTable";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";

const ParentEventsPage = () => {
  const nav = <ParentsDashSidebar Events />;

  return (
    <Box>
      <PageHeader title="Events" />

      <PageWrapper>
        <Grid container>
          <Grid item xs={8}>
            <ParentEventTable />
          </Grid>
          <Grid item xs={4}>
            <ParentEventAnnounce />
          </Grid>
        </Grid>
      </PageWrapper>
    </Box>
  );
};
export default ParentEventsPage;
