import {
  Button,
  Grid,
  makeStyles,
  Theme,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneIphoneOutlinedIcon from "@material-ui/icons/PhoneIphoneOutlined";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link as RouterLink, useParams } from "react-router-dom";
import AuthForm from "../Components/AuthForm";
import AuthBanner from "../Components/AuthBanner";
import AuthInputField, {
  AuthPasswordField,
} from "../Components/AuthInputField";
import { LoginAction } from "../../../actions/Auth/Login/LoginAction";
import { RootStore } from "../../../store";

const useStyles = makeStyles((theme: Theme) => {
  return {
    form: {
      marginTop: theme.spacing(6),
      maxWidth: 340,
    },
    link: {
      fontWeight: 600,
    },
  };
});

const LoginForm = () => {
  const classes = useStyles();
  const { role } = useParams<any>();

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const isAuthenticated = useSelector((state: RootStore) => {
    return state.auth.authenticated;
  });

  const onFormSubmit = (data: any) => {
    dispatch(LoginAction(data));
  };

  if (isAuthenticated && localStorage.getItem("hamro_token")) {
    if (localStorage.getItem("role")) {
      return <Redirect to="/" />;
    }
    return <Redirect to="/choose-role" />;
  }

  const startPhoneIcon = (
    <InputAdornment position="start">
      <PhoneIphoneOutlinedIcon />
    </InputAdornment>
  );

  const renderTitle = (value: any) => {
    switch (value) {
      case "student":
        return "Login as Student";
        break;
      case "teacher":
        return "Login as Teacher";
        break;
      case "parent":
        return "Login as Parent";
        break;
      default:
        return "Login";
        break;
    }
  };

  return (
    <Grid container>
      <Grid item md={5}>
        <AuthForm
          title={renderTitle(role)}
          subtitle="Enter your valid credentials below."
          className={classes.form}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AuthInputField
                required
                label="UserName / Mobile Number"
                startAdornment={startPhoneIcon}
                name="username"
                placeholder="Mobile Number"
                error={Boolean(errors.username)}
                helperText={
                  errors.username
                    ? errors.username?.message || "Phone is Field"
                    : ""
                }
                register={register}
              />
            </Grid>

            <Grid item xs={12}>
              <AuthPasswordField
                required
                label="Password"
                placeholder="Password"
                name="password"
                error={Boolean(errors.password)}
                helperText={
                  errors.password
                    ? errors.password?.message || "Password is Field"
                    : ""
                }
                register={register}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {/* <Grid item>
                  <FormControlLabel
                    control={<Checkbox defaultChecked color="primary" />}
                    label="Remember Me"
                  />
                </Grid> */}

                <Grid item>
                  <Link
                    to="/authentication/password-recovery"
                    className={classes.link}
                    component={RouterLink}
                  >
                    Forgot Password?
                  </Link>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Log In
              </Button>
            </Grid>
            <p>You can Login as:</p>
            <Grid container spacing={5}>
              {role !== "teacher" && (
                <Grid item xs={4}>
                  <Link
                    to="/user-login/teacher"
                    className={classes.link}
                    component={RouterLink}
                  >
                    <Button fullWidth variant="outlined" color="primary">
                      Teacher
                    </Button>
                  </Link>
                </Grid>
              )}
              {role !== "student" && (
                <Grid item xs={4}>
                  <Link
                    to="/user-login/student"
                    className={classes.link}
                    component={RouterLink}
                  >
                    <Button fullWidth variant="outlined" color="primary">
                      Student
                    </Button>
                  </Link>
                </Grid>
              )}
              {role !== "parent" && (
                <Grid item xs={4}>
                  <Link
                    to="/user-login/parent"
                    className={classes.link}
                    component={RouterLink}
                  >
                    <Button fullWidth variant="outlined" color="primary">
                      Parent
                    </Button>
                  </Link>
                </Grid>
              )}
            </Grid>
          </Grid>
        </AuthForm>
      </Grid>

      <Grid item md={7}>
        <AuthBanner
          id={0}
          title="Welcome to the school you've always wanted."
          subtitle="We are ready to serve you with our premium learning contents embedded with a cutting edge technology"
        />
      </Grid>
    </Grid>
  );
};

export default LoginForm;
