//-------------------<Start> module imports starts ----------------------//
import React, { useEffect, useState, useRef } from "react";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import {
  Paper,
  Grid,
  Typography,
  LinearProgress,
  CardContent,
  Card,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { GetTimeTables } from "../../../actions/Academics/TimeTable/TimeTableAction";
import { TimeTableTypeI } from "../../../actions/Academics/TimeTable/TimeTableActionTypes";
import { RootStore } from "../../../store";
import { timeConvertor } from "../../../dashboard/StudentDashboard/pages/MyTimetable/StudentTimeTableView";
import { indigo, yellow } from "@material-ui/core/colors";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import { resetPeriodStateAction } from "../../../rtk/features/timetable/periodSlices";
import data from "../../../dashboard/SuperAdminDashboard/data";
import { getTimeTableByClass } from "../../../rtk/features/timetable/timetableApi";
import { getTimePeriodByGrade } from "../../../rtk/features/timetable/periodApi";
import { timeTableResetState } from "../../../rtk/features/timetable/timetableSlices";

// -------------------<END> module imports end ----------------------//

//---------------------------- <START> interface starts ----------------------------------//

const PreviewContainer = styled.div`
  overflow: scroll;
`;

const Row = styled.div`
  &:nth-of-type(odd) {
    background-color: #3d3d3d15;
  }
  min-height: 80px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
`;

const IndexCell = styled.div`
  width: 100px;
  margin: 2px;
  text-align: center;
`;

const DataCell = styled.div`
  width: 150px;
  margin: 2px;
`;

const PeriodDetail = styled.div`
  text-align: center;
  background-color: #a3dabc;
  border-radius: 10px;
  padding: 5px;

  & > h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0;
  }

  & > p {
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0;
  }
`;

const SubjectDetail = styled.div`
  text-align: center;
  padding: 10px 5px;
  background-color: #d2e7dc;
  border-radius: 10px;

  & > h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0;
  }

  & > p {
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0;
  }
`;

interface TableTableTypeExtendedI {
  id: string;
  section: string;
  section_name: string;
  grade: string;
  grade_name: string;
  subject: string;
  subject_name: string;
  teacher: string;
  teacher_full_name: string;
  start_time: string;
  end_time: string;
  day: number;
  created_by: string;
  general_info_name: string;
  assigned: boolean;
}
//---------------------------- <END> interface endss ----------------------------------//

//---------------------------- <START> table dummy data starts ----------------------------------//
//---------------------------- <END> table dummy data ends  ----------------------------------//

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      textAlign: "left",
      padding: 4,
      background: yellow[200],
    },
    cardContent: {
      padding: 6,
      paddingBottom: "8px !important",
    },
    title: { fontSize: 15, fontWeight: 600 },
    time: { fontSize: 12 },
    pos: {
      fontSize: 12,
      marginBottom: 0,
      fontWeight: 500,
    },
  };
});

// -------------------- <START> Table Headings Data ---------------------------//

// ---------------------<END> Table Headings Data Ends --------------------------//

// ---------------<START> Timetable Schedule component starts -----------------//

interface PropsI {
  searchData: {
    gradeId: string | null;
    section: string | null;
    classId: string | null;
    hasSection: boolean;
  };
}

const TimeTablePreview = (props: PropsI) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const cardStyle = useStyles();
  const { searchData } = props;
  const componentRef = useRef<any>();
  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [searched, setSearched] = useState<boolean>(false);
  const [tableTitle, setTableTitle] = useState<string>("TimeTable Schedule");

  const [sortedData, setSortedData] = useState<TableTableTypeExtendedI[][]>([]);
  const [headCells, setHeadCells] = useState<any>([]);

  const preTimeTableState = useSelector(
    (state: RootStore) => state.pre_timeTable.preTimeTables
  );

  const { periodsByClass } = useSelector((state: RootStore) => state.period);
  const { timetableByClass, actionPerformed } = useSelector(
    (state: RootStore) => state.timetable
  );

  useEffect(() => {
    if (searchData.gradeId) {
      if (searchData.hasSection) {
        if (searchData.section) {
          dispatch(
            getTimeTableByClass({
              grade_id: searchData.gradeId,
              section_id: searchData.section,
            })
          );
          dispatch(getTimePeriodByGrade(searchData.gradeId));
        } else {
          dispatch(resetPeriodStateAction());
          dispatch(timeTableResetState());
        }
      } else {
        dispatch(
          getTimeTableByClass({
            grade_id: searchData.gradeId,
            section_id: searchData.section,
          })
        );
        dispatch(getTimePeriodByGrade(searchData.gradeId));
      }
    } else {
      dispatch(resetPeriodStateAction());
      dispatch(timeTableResetState());
    }
  }, [dispatch, searchData, actionPerformed]);

  useEffect(() => {
    const tempCells: any = [
      {
        id: 0,
        label: "Day",
      },
    ];
    if (searched) {
      preTimeTableState.map((elem: any) => {
        tempCells.push({
          id: elem.period,
          label:
            elem.period != "break"
              ? `Period ${elem.period}\r\n(${timeConvertor(
                  elem.start_time
                )} - ${timeConvertor(elem.end_time)})`
              : `Break \r\n(${timeConvertor(elem.start_time)} - ${timeConvertor(
                  elem.end_time
                )})`,
        });
        // }
      });
      setHeadCells(tempCells);
    }
  }, [preTimeTableState]);

  //================================ <START> REDUX CYCLE HOOKS <START> ========================//
  const timeTableSelector = useSelector((state: RootStore) => state.timeTable);

  useEffect(() => {
    const data = timeTableSelector.timeTables;

    const filterData: any = [];
    if (searchData) {
      for (let i = 1; i < 8; i++) {
        const new_data: any = [];
        const days = data.filter((elem: any) => elem.day == i);
        let k = 0;
        for (let j = 0; j < preTimeTableState.length; j++) {
          if (days[k]?.start_time == preTimeTableState[j]?.start_time) {
            new_data.push(days[k]);
            k = k + 1;
          } else {
            new_data.push(preTimeTableState[j]);
          }
        }
        filterData.push(new_data);
      }
    }
    setSortedData(filterData);
  }, [timeTableSelector]);

  //================================ <END> REDUX CYCLE HOOKS <END> ========================//

  const returnPeriodDetail = (
    period: string,
    startTime: string,
    endTime: string
  ) => {
    return (
      <PeriodDetail>
        <h3>{period}</h3>
        <p>
          {startTime} - {endTime}
        </p>
      </PeriodDetail>
    );
  };

  const returnSubjectDetail = (day: number, periodId: string) => {
    if (timetableByClass) {
      const byDay = timetableByClass.timeTable.find((item) => item.day === day);

      if (byDay) {
        const pd = byDay.periods.find((item) => item.period.id === periodId);
        if (pd) {
          return (
            <SubjectDetail>
              <h3>{pd.subject.name || "--"}</h3>
              <p>
                {pd.teacher.first_name
                  ? `${pd.teacher.first_name} ${pd.teacher.last_name}`
                  : "--"}
              </p>
            </SubjectDetail>
          );
        }
      }
    }
    return (
      <SubjectDetail>
        <h3>--</h3>
        <p>--</p>
      </SubjectDetail>
    );
  };

  const scheduleDays = [
    { id: 7, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
  ];

  return (
    <>
      {/* <SearchTimetable resetTable={reloadTable} searchData={fetchSearchData} /> */}
      <Paper className={classes.rootTableBox}>
        {loading && (
          <LinearProgress style={{ margin: "-24px -24px 24px -24px" }} />
        )}
        <ReactToPrint
          trigger={() => (
            <p
              style={{
                backgroundColor: "#132E98",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "5px 0px",
                cursor: "pointer",
              }}
            >
              Print This Out !
            </p>
          )}
          content={() => componentRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
        <div ref={componentRef}>
          <PreviewContainer>
            <Row>
              <IndexCell>Days</IndexCell>
              {periodsByClass?.periods.map((item) => (
                <DataCell key={item.id}>
                  {returnPeriodDetail(
                    item.name,
                    item.start_time,
                    item.end_time
                  )}
                </DataCell>
              ))}
            </Row>

            {scheduleDays.map((day) => {
              return (
                <Row key={day.id}>
                  <IndexCell>{day.name}</IndexCell>
                  {periodsByClass?.periods.map((item) => {
                    return (
                      <DataCell key={item.id}>
                        {returnSubjectDetail(day.id, item.id)}
                      </DataCell>
                    );
                  })}
                </Row>
              );
            })}
          </PreviewContainer>
        </div>
      </Paper>
    </>
  );
};
// --------------------<ENDS> Time table Schedule ends ----------------------//
export default TimeTablePreview;
