import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_TEACHER_LEAVE_LOADING,
  GET_TEACHER_LEAVE_ERROR,
  GET_TEACHER_LEAVE_SUCCESS,
  ADD_TEACHER_LEAVE_LOADING,
  ADD_TEACHER_LEAVE_SUCCESS,
  ADD_TEACHER_LEAVE_ERROR,
  DELETE_TEACHER_LEAVE_SUCCESS,
  DELETE_TEACHER_LEAVE_ERROR,
  DELETE_TEACHER_LEAVE_LOADING,
  TeacherLeaveDispatchTypes,
  LeavePropsI,
  UPDATE_TEACHER_LEAVE_LOADING,
  UPDATE_TEACHER_LEAVE_SUCCESS,
  UPDATE_TEACHER_LEAVE_ERROR,
} from './TeacherLeaveActionType';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { TEACHER_API_URL } from '../../../config/SERVER_API_URLS';

interface RequestDataI {
  id: number;
  leave_type: number;
  status: string;
  status_name: string;
  leave_from: string;
  leave_to: string;
  applied_date: string;
  general_info: number;
  reason_for_leave: string;
  leave_name: string;
  no_of_days: string;
  first_name: string;
  last_name: string;
}

export const GetTeacherLeave =
  () =>
  async (dispatch: Dispatch<TeacherLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHER_LEAVE_LOADING,
      });

      const res = await axios.get(`${TEACHER_API_URL}/leave/`, HeaderConfig());

      dispatch({
        type: GET_TEACHER_LEAVE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_LEAVE_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     // snackbarMessage: error?.response.data.error.detail,
      //   },
      // });
    }
  };

export const AddTeacherLeave =
  (data: any) =>
  async (dispatch: Dispatch<TeacherLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_TEACHER_LEAVE_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/leave/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_TEACHER_LEAVE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Teacher Leave Added Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_TEACHER_LEAVE_ERROR,
        payload: error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Something went wrong!',
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_TEACHER_LEAVE_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/leave/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_TEACHER_LEAVE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_TEACHER_LEAVE_ERROR,
        });

        console.log('error2', error);
      }
    }
  };

export const UpdateTeacherLeave =
  (id: number, data: any) =>
  async (dispatch: Dispatch<TeacherLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_TEACHER_LEAVE_LOADING,
      });

      const res = await axios.put(
        `${TEACHER_API_URL}/leave/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_TEACHER_LEAVE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Updated Successfully',
        },
      });
    } catch (error: any) {
      // console.log({ error });
      dispatch({
        type: UPDATE_TEACHER_LEAVE_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Update Failed',
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_TEACHER_LEAVE_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/leave/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_TEACHER_LEAVE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_TEACHER_LEAVE_ERROR,
        });
      }
    }
  };

export const DeleteTeacherLeave =
  (id: number) =>
  async (dispatch: Dispatch<TeacherLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_TEACHER_LEAVE_LOADING,
      });

      const res = await axios.delete(
        `${TEACHER_API_URL}/leave/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_TEACHER_LEAVE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Teacher Leave Deleted Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_TEACHER_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Teacher Leave Delete Failed',
        },
      });
    }
  };

export const GetTeacherSearchedLeaves =
  (query: any) =>
  async (dispatch: Dispatch<TeacherLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHER_LEAVE_LOADING,
      });

      const url = `${TEACHER_API_URL}/leave/?leave_type=${query.leaveType}&from_date=${query.date_first}&to_date=${query.date_second}`;

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_TEACHER_LEAVE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_LEAVE_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Leaves Loading Failed",
      //   },
      // });
    }
  };
