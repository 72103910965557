import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTeacherEvent } from "../../../../../actions/TeacherDash/TeacherEvents/TeacherEventAction";
import { TeacherEventTypeI } from "../../../../../actions/TeacherDash/TeacherEvents/TeacherEventActionTypes";
import { RootStore } from "../../../../../store";
import { ItemViewButton } from "../../../../../components/Reusable/Buttons/TableButton";
import Popups from "../../../../../components/Reusable/Dialogs/Popups";
import useTable from "../../../../../components/Reusable/useStudentTable";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import { ViewEventModal } from "./ViewEventModal";
import { tblStyle } from "../../../components/Styles/TeacherDashboardStyles";
import { dateConverterBsToAd } from "../../../../../components/utils/dateConverter";

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------- <END> Interface------------------//

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: "title", label: "Title" },
  { id: "event_type", label: "Type" },
  { id: "location", label: "Location" },
  { id: "date", label: "Date" },
  { id: "time", label: "Time" },
  { id: "is_holiday", label: "Holiday Status" },
  { id: "action", label: "Action" },
];

const TeacherEventsList = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<TeacherEventTypeI[]>([]);

  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [item, setItem] = useState<TeacherEventTypeI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const teacherEvents = useSelector((state: RootStore) => state.teacher_event);

  useEffect(() => {
    dispatch(GetTeacherEvent());
  }, []);

  useEffect(() => {
    const data = teacherEvents.teacher_event;
    setTableData(data);
    setLoading(teacherEvents.loading);
  }, [teacherEvents]);

  const handleViewModal = (item: any) => {
    setItem(item);
    setItemId(item.id);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const getCustomTableRow = (item: TeacherEventTypeI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.event_type_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.location}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterBsToAd(item.from_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.time}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.is_holiday ? "Yes" : "No"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton
            title="View"
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewModal(item)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper style={tblStyle}>
        <TableContainer
          label="Event List"
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="title"
          rowsPerPage={7}
        />
      </Paper>
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        fullWidth={true}
        maxWidth="sm"
        title="Events Detail"
        colored
      >
        <ViewEventModal item={item} />
      </Popups>
    </>
  );
};

export default TeacherEventsList;
