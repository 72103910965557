import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Avatar, Menu, MenuItem, SvgIconTypeMap } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { LogoutAction } from "../../../actions/Auth/Logout/LogoutAction";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import getFullName from "../../utils/getFullName";
import EditIcon from "@material-ui/icons/Edit";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { GetTeacherProfile } from "../../../actions/TeacherDash/TeacherProfile/TeacherProfileAction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverMenu: {
      marginTop: "48px",
      "& .MuiMenu-paper": { width: "200px!important", top: "8px!important" },
    },
    popoverMenu2: {
      marginTop: "48px",
      "& .MuiMenu-paper": {
        width: "200px!important",
        top: "42px!important",
        overflow: "visible!important",
        transition: "none!important",
        "&::before": {
          content: "''",
          position: "absolute",
          width: "30px",
          height: "30px",
          background: "white",
          transform: "rotate(45deg) translate(-50%, 0%)",
          top: "4px",
          left: "50%",
          zIndex: "-11",
          transition: "none",
        },
      },
    },
    authBoxWrapper: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      background: "rgba(255, 255, 255, .175)",
      padding: "1px 4px",
      borderRadius: "100px",
      marginLeft: "12px",
    },
    userText: {
      marginRight: "8px",
      marginBottom: 0,
      marginTop: 0,
      padding: "8px 4px",

      "& > strong": {
        fontWeight: 600,
      },
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    menuIcon: { marginRight: "8px" },
  })
);

const UserMenuIcon = (props: {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}) => {
  const Icon = props.icon;
  return <Icon style={{ marginRight: "8px", fontSize: "18px" }} />;
};

const UserMenuPopover = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [userRole, setUserRole] = useState<string>("");
  const [fullName, setFullName] = useState<any>("Unknown User");

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const authState = useSelector((state: RootStore) => state.auth);
  const teacherProfileState = useSelector(
    (state: RootStore) => state.teacher_profile.teacher_profile
  );

  useEffect(() => {
    const role = localStorage.getItem("role");
    role && setUserRole(role);

    if (authState.user) {
      const userFullName = getFullName(
        authState?.user?.first_name,
        authState?.user?.last_name
      );

      setFullName(userFullName);
    }
    if (role == "Teacher" && authState.user?.id) {
      dispatch(GetTeacherProfile(authState.user?.id));
    }
  }, [authState]);

  return (
    <>
      <UserMenuPopoverWrapper>
        <p className={classes.userText}>
          Hi, <strong>{fullName}</strong>
        </p>
        <Avatar
          src={
            userRole == "Teacher"
              ? teacherProfileState?.photo
              : authState.general_info?.logo
          }
          className={classes.small}
          alt={fullName}
        />
      </UserMenuPopoverWrapper>
    </>
  );
};

const UserMenuPopoverWrapper = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [userRole, setUserRole] = useState<string>("");
  const [fullName, setFullName] = useState<any>("Unknown User");

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const authState = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    const role = localStorage.getItem("role");
    role && setUserRole(role);

    if (authState.user) {
      const userFullName = getFullName(
        authState.user.first_name,
        authState.user.last_name
      );

      setFullName(userFullName);
    }
  }, [authState]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserLogout = () => {
    dispatch(LogoutAction());
    handleClose();
    history.push("/user-login/student");
  };

  const handleRoleChange = () => {
    handleClose();
    history.push("/choose-role");
  };

  return (
    <div>
      <div className={classes.authBoxWrapper} onClick={handleClick}>
        {props.children}
      </div>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={props.student ? classes.popoverMenu2 : classes.popoverMenu}
        elevation={1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {["Teacher", "Parent", "Student"].includes(userRole) && (
          <MenuItem
            onClick={() => {
              handleClose();
              history.push("/profile");
            }}
          >
            <UserMenuIcon icon={PersonIcon} />
            Profile
          </MenuItem>
        )}

        {["Administrator"].includes(userRole) && (
          <MenuItem
            onClick={() => {
              handleClose();
              history.push("/session");
            }}
          >
            <UserMenuIcon icon={SettingsIcon} />
            Settings
          </MenuItem>
        )}

        {["Accountant"].includes(userRole) && (
          <MenuItem
            onClick={() => {
              handleClose();
              history.push("/general-settings");
            }}
          >
            <UserMenuIcon icon={SettingsIcon} />
            Settings
          </MenuItem>
        )}

        {/* Get Default User Menu Items */}
        {authState.user.groups?.length >= 2 && (
          <MenuItem onClick={handleRoleChange}>
            {/* <EditIcon fontSize="small" className={classes.menuIcon} /> */}
            <UserMenuIcon icon={EditIcon} /> Change Role
          </MenuItem>
        )}

        <MenuItem onClick={handleUserLogout}>
          <UserMenuIcon icon={ExitToAppIcon} />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenuPopover;
export { UserMenuPopoverWrapper };
