import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_STUDENT_LEAVE_LOADING,
  GET_STUDENT_LEAVE_ERROR,
  GET_STUDENT_LEAVE_SUCCESS,
  ADD_STUDENT_LEAVE_LOADING,
  ADD_STUDENT_LEAVE_SUCCESS,
  ADD_STUDENT_LEAVE_ERROR,
  DELETE_STUDENT_LEAVE_SUCCESS,
  DELETE_STUDENT_LEAVE_ERROR,
  DELETE_STUDENT_LEAVE_LOADING,
  UPDATE_STUDENT_LEAVE_LOADING,
  UPDATE_STUDENT_LEAVE_SUCCESS,
  UPDATE_STUDENT_LEAVE_ERROR,
  StudentLeaveDispatchTypes,
  StudentLeaveII,
} from './StudentDashLeaveActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { STUDENT_API_URL } from '../../../config/SERVER_API_URLS';

export const GetStudentDashLeave =
  () =>
  async (dispatch: Dispatch<StudentLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_LEAVE_LOADING,
      });

      const res = await axios.get(
        `${STUDENT_API_URL}/student_leave/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STUDENT_LEAVE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_LEAVE_ERROR,
      });
    }
  };

export const AddStudentLeave =
  (data: StudentLeaveII) =>
  async (dispatch: Dispatch<StudentLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_STUDENT_LEAVE_LOADING,
      });

      const res = await axios.post(
        `${STUDENT_API_URL}/student_leave/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_STUDENT_LEAVE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Added Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_STUDENT_LEAVE_ERROR,
        payload: error.response && error.response?.data,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Leave Add Failed",
      //   },
      // });
    } finally {
      try {
        dispatch({
          type: GET_STUDENT_LEAVE_LOADING,
        });

        const res = await axios.get(
          `${STUDENT_API_URL}/student_leave/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_STUDENT_LEAVE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_STUDENT_LEAVE_ERROR,
        });
      }
    }
  };

export const UpdateStudentLeave =
  (id: number, data: any) =>
  async (dispatch: Dispatch<StudentLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_STUDENT_LEAVE_LOADING,
      });

      const res = await axios.put(
        `${STUDENT_API_URL}/student_leave/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_STUDENT_LEAVE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Updated Successfully',
        },
      });
    } catch (error: any) {
      // console.log({ error });
      dispatch({
        type: UPDATE_STUDENT_LEAVE_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Update Failed',
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_STUDENT_LEAVE_LOADING,
        });

        const res = await axios.get(
          `${STUDENT_API_URL}/student_leave/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_STUDENT_LEAVE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_STUDENT_LEAVE_ERROR,
        });
      }
    }
  };

export const DeleteStudentLeave =
  (id: number) =>
  async (dispatch: Dispatch<StudentLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_STUDENT_LEAVE_LOADING,
      });

      const res = await axios.delete(
        `${STUDENT_API_URL}/student_leave/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_STUDENT_LEAVE_SUCCESS,
        payload: { id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Deleted Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_STUDENT_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Delete Failed',
        },
      });
    }
  };

export const GetStudentSearchedLeaves =
  (query: any) =>
  async (dispatch: Dispatch<StudentLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_LEAVE_LOADING,
      });

      const url = `${STUDENT_API_URL}/student_leave/?leave_from=${query.date_first}&leave_to=${query.date_second}`;

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_STUDENT_LEAVE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_LEAVE_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Leaves Loading Failed",
      //   },
      // });
    }
  };
