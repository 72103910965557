import { useState } from "react";
import { Box } from "@material-ui/core";
import { Tupple } from "../../../../common/json.constant";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import AttendanceTable from "./AttendanceTable";
import SearchAttendace from "./SearchAttendace";
import { crumbs } from "../MyTeacher/MyTeacherPage";

const MyAttendancePage = () => {
  const [monthName, setMonthName] = useState<Tupple | null>(null);
  const [childrenName, setChildrenName] = useState<Tupple | null>(null);

  return (
    <Box>
      <PageHeader title="My Attendance" module={crumbs} />
      <PageWrapper>
        <SearchAttendace
          setMonthName={setMonthName}
          setChildrenName={setChildrenName}
        />
        <AttendanceTable monthName={monthName} childrenName={childrenName} />
      </PageWrapper>
    </Box>
  );
};

export default MyAttendancePage;
