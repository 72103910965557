import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import getFullName from '../../../../components/utils/getFullName';
import moment from 'moment';
import { LeaveApproveOrDecline } from '../../../../actions/Student/StudentLeave/LeaveAction';
import { GetDashboardData } from '../../../../actions/Dashboard/DashboardAction';
import styled from 'styled-components';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',

    '& th, & td': {
      padding: '12px 24px 12px 16px',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    '& tbody tr:last-child td ': {
      borderBottom: 'none',
    },
  },

  approveBtn: {
    '& span': {
      fontSize: '13px',
    },
  },
});

function createData(name: any, post: any, date: any) {
  return { name, post, date };
}

const rows = [
  createData('Aashish Maharjan', 'Teacher | Science', 'May 30, 2021'),
  createData('Manoj Aacharya', 'Student | Class 9 | Sectino B', 'May 30, 2021'),
  createData('Reuben Thapa', 'Teacher | Computer', 'May 30, 2021'),
  createData('Rakesh Singh', 'Teacher | Science', 'May 30, 2021'),
  createData('Bill Gates', 'Student | Class 9 | Sectino B', 'May 30, 2021'),
  createData('Elon Musk', 'Teacher | Computer', 'May 30, 2021'),
];

const tempData = [
  {
    id: 1,
    name: 'Aashish Maharjan',
    message: '20 days overdue',
    amount: 'Rs. 25,000',
  },
  {
    id: 2,
    name: 'Manoj Adhikari',
    message: '10 days overdue',
    amount: 'Rs. 38,000',
  },
  {
    id: 3,
    name: 'Aashish Maharjan',
    message: '20 days overdue',
    amount: 'Rs. 25,000',
  },
  {
    id: 4,
    name: 'Manoj Adhikari',
    message: '10 days overdue',
    amount: 'Rs. 38,000',
  },
  {
    id: 5,
    name: 'Aashish Maharjan',
    message: '20 days overdue',
    amount: 'Rs. 25,000',
  },
];

const StyledButton = styled(Button)`
  text-transform: initial;
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  padding: 4px 8px !important;

  &:hover {
    background: ${(props) => props.theme.palette.primary.dark};
    color: ${(props) => props.theme.palette.primary.contrastText};
  }
`;

export default function DueAlertList() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const leaveRequests = useSelector(
    (state: RootStore) => state.dashboard.data.leave_requests
  );

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="Leave Requests List">
        <TableBody>
          {tempData.map((item: any, index: number) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                  {item.name}
                </Typography>
                <Typography variant="body1" style={{ color: '#555' }}>
                  {item.message}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle2">{item.amount}</Typography>
              </TableCell>
              <TableCell align="right">
                <StyledButton>Send Alert</StyledButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
