import { RubricStaffI } from "../../Academics/AssignRubric/AssignRubricActionTypes"

export const GET_APPLICABLE_RUBRIC_LOADING = "GET_APPLICABLE_RUBRIC_LOADING"
export const GET_APPLICABLE_RUBRIC_ERROR = "GET_APPLICABLE_RUBRIC_ERROR"
export const GET_APPLICABLE_RUBRIC_SUCCESS = "GET_APPLICABLE_RUBRIC_SUCCESS"

export const GET_STUDENT_RUBRIC_LOADING = "GET_STUDENT_RUBRIC_LOADING"
export const GET_STUDENT_RUBRIC_ERROR = "GET_STUDENT_RUBRIC_ERROR"
export const GET_STUDENT_RUBRIC_SUCCESS = "GET_STUDENT_RUBRIC_SUCCESS"

export const APPLY_STUDENT_RUBRIC_LOADING = "APPLY_STUDENT_RUBRIC_LOADING"
export const APPLY_STUDENT_RUBRIC_ERROR = "APPLY_STUDENT_RUBRIC_ERROR"
export const APPLY_STUDENT_RUBRIC_SUCCESS = "APPLY_STUDENT_RUBRIC_SUCCESS"

export interface StudentRubricGrade {
    grade: string;
    id: string;
    remarks: string;
    rubric: string;
    student: string;
}
export interface StudentI {
    grade: {
        id: string;
        name: string;
        grade_name: string;
    },
    id: string,
    section: {
        id: string;
        name: string;
    },
    student: {
        id: string;
        student_user: {
            email: string;
            first_name: string;
            id: string;
            last_name: string;
            username: string;
        }
    },
    student_rubric_grade: StudentRubricGrade[],
}

export interface StudentII {
    student: string;
    grade: string;
    remarks: string;
}
export interface ApplyRubricPayload {
    rubric: string;
    students: StudentII[]
}

//===============================<START>GET APPLICABLE RUBRIC<START>======================//
export interface GetApplicableRubricLoading {
    type: typeof GET_APPLICABLE_RUBRIC_LOADING;
}
export interface GetApplicableRubricError {
    type: typeof GET_APPLICABLE_RUBRIC_ERROR;
}
export interface GetApplicableRubricSuccess {
    type: typeof GET_APPLICABLE_RUBRIC_SUCCESS;
    payload: RubricStaffI[];
}
//===============================<END>GET APPLICABLE RUBRIC<END>======================//

//===============================<START>GET STUDENT RUBRIC<START>======================//
export interface GetStudentRubricLoading {
    type: typeof GET_STUDENT_RUBRIC_LOADING;
}
export interface GetStudentRubricError {
    type: typeof GET_STUDENT_RUBRIC_ERROR;
}
export interface GetStudentRubricSuccess {
    type: typeof GET_STUDENT_RUBRIC_SUCCESS;
    payload: StudentI[];
}
//===============================<END>GET STUDENT RUBRIC<END>======================//

//===============================<START>APPLY STUDENT RUBRIC<START>======================//
export interface ApplyStudentRubricLoading {
    type: typeof APPLY_STUDENT_RUBRIC_LOADING;
}
export interface ApplyStudentRubricError {
    type: typeof APPLY_STUDENT_RUBRIC_ERROR;
}
export interface ApplyStudentRubricSuccess {
    type: typeof APPLY_STUDENT_RUBRIC_SUCCESS;
    payload: ApplyRubricPayload;
}
//===============================<END>APPLY STUDENT RUBRIC<END>======================//

export type TeacherRubricDispatchTypes =
    | GetApplicableRubricLoading
    | GetApplicableRubricError
    | GetApplicableRubricSuccess
    | GetStudentRubricLoading
    | GetStudentRubricError
    | GetStudentRubricSuccess
    | ApplyStudentRubricLoading
    | ApplyStudentRubricError
    | ApplyStudentRubricSuccess;