// ---------------- <START> module import ------------------//
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux"
import { Paper } from "@material-ui/core";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { RootStore } from "../../../../store";
import { HeadCellsI } from "../../../../actions";
import {
  ItemViewButton,
  ItemSubmitButton,
} from "../../../../components/Reusable/Buttons/TableButton";
import Popups from "../../../../components/Reusable/Dialogs/Popups";
import AssignmentView from "./AssignmentView";
import { tblStyle } from "../../ParentDashboardStyles";
import useTable from "../../../../components/Reusable/useStudentTable";
import { GetTeachingStatus } from "../../../../common/GetStatus";
import { GetStudentAssignmentbyID } from '../../../../actions/StudentDash/StudentAssignment/StudentAssignmentAction';
// ---------------- <END> module import ------------------//

// ---------------- <START> Head Cells -----  -------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "title", label: "Title", align: "left", filter: true },
  { id: "subject", label: "Subject" },
  { id: "assign_on", label: "Assigned On" },
  { id: "date1", label: "Deadline" },
  { id: "status", label: "Status" },
  { id: "marks", label: "Obtained Marks" },
  { id: "action", label: "Action" },
];
// ---------------- <START> Head Cells ------------------//
interface Props {
  optionState: any;
}
const ROLE = localStorage.getItem("role");
// ---------------- <START> Designation Table Component ------------------//
const AssignmentList = ({ optionState }: Props) => {
  const dispatch = useDispatch();
  const classes = useTableStyles();
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [assignment, setAssignment] = useState<any>(null);
  const [assignmentID, setAssignmentID] = useState<number | null>(null);
  const [assignmentViewClicked, setAssignmentViewClicked] = useState(false);

  const { loading, children_assignments } = useSelector(
    (state: RootStore) => state.children_assignment
  );

  useEffect(() => {
    if (children_assignments && children_assignments.length > 0) {
      setTableData(children_assignments);
    } else {
      setTableData([]);
    }
  }, [children_assignments]);

  useEffect(() => {
    !optionState && setTableData([]);
  }, [optionState])

  const handleViewModal = (item: any) => {
    setAssignment(item);
    setOpenPopup(true);
  };

  const handleSubmitAssignment = (item: any) => {
    const { id } = item;
    setAssignmentID(id);
    setAssignmentViewClicked(true);
    setAssignment(item);
    ROLE === 'Student' && dispatch(GetStudentAssignmentbyID(id));
  };

  if (assignmentViewClicked) {
    return <Redirect to={{ pathname: `/children-assignment/submit/${assignmentID}`, state: { id: optionState.academic_info[0].id } }} />;
  }

  const getCustomTableRow = (item: any, index: number) => {
    const status = new Date() > new Date(item.deadline)
    let disabled;
    if (item.submission_type === "Physical" || item.submitted || (!item.can_submit_after_deadline && status)) {
      disabled = true;
    } else if (item.can_submit_after_deadline) {
      disabled = false;
    }
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.subject.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.assign_on.replace("T", " ")}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.deadline.replace("T", " ")}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <GetTeachingStatus
            status={item.submitted ? "completed" : "remaining"}
          />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item?.marks ? item.marks : 'N/A'}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleViewModal(item)} />
          <ItemSubmitButton
            onClick={() => handleSubmitAssignment(item)}
            disabled={disabled}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  return (
    <Paper className={classes.root} style={tblStyle}>
      <TableContainer
        label="Assignment List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        placeholder="Search Assignment"
        search_name="title"
      />

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        maxWidth="md"
        title="Assignment Details"
        colored
      >
        {assignment && <AssignmentView assignment={assignment} />}
      </Popups>
    </Paper>
  );
};
// ---------------- <START> Designation Table Component ------------------//

export default AssignmentList;
