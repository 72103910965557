import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { FaMedal } from 'react-icons/fa';
import MySyllabusWidget from '../../components/Widget/MySyllabusWidget';
import DashboardWidgetCard from '../../components/Card/DashboardWidgetCard';
import ParentProfileCard from '../../components/Card/ParentProfileCard';
import MyAssignmentWidget from '../../components/Widget/MyAssignmentWidget';
import EventCalendar from '../../components/Widget/EventCalendar';
import MyLiveClassesWidget from '../../components/Widget/MyLiveClassesWidget';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import NepaliCalendarPage from '../../../../pages/Dashboard/Calendar/NepaliCalendar';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '240px',
    minHeight: '100vh',
    borderRadius: '30px 0 0 30px',
    background: '#fff',
  },
  widget: {
    padding: '20px',
    position: 'relative',
    width: '370px',
    minHeight: '100vh',
    background: '#f0f0fc',
  },
  notificationBadge: {
    color: theme.palette.primary.main,
    borderRadius: 5,
    padding: 5,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '2px 4px 20px 3px rgb(0 0 0 / 12%)',

    '& > span': {
      top: 2,
      right: 2,
      padding: 6,
      borderRadius: '50%',
      backgroundColor: theme.palette.success.main,
    },
  },
  paper: {
    borderRadius: 18,
    boxShadow: 'rgb(0 0 0 / 6%) 1px 1px 18px 1px',
  },
}));

const ParentDashboardPage = () => {
  const classes = useStyles();
  const authState = useSelector((state: RootStore) => state.auth);

  return (
    <Box className={classes.content}>
      <Box style={{ flex: '1', padding: '16px 24px' }}>
        <Typography variant="h5" color="primary" style={{ marginTop: '10px' }}>
          <span style={{ fontWeight: 600 }}>Good Morning</span>{' '}
          <span style={{ fontWeight: 400 }}>{authState.user.first_name}!</span>
        </Typography>

        <Typography variant="subtitle2">
          Welcome back to your Dashboard.
        </Typography>

        <Grid container spacing={3} style={{ marginTop: '16px' }}>
          <Grid item md={12}>
            <Paper className={classes.paper}>
              <MyLiveClassesWidget />
            </Paper>
          </Grid>
          <Grid item>
            <Paper className={classes.paper} style={{ padding: 16 }}>
              {/* <EventCalendar /> */}
              <NepaliCalendarPage showBySize="small" />
            </Paper>
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Paper className={classes.paper}>
              <MyAssignmentWidget />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.widget}>
        <ParentProfileCard />

        {/* <Grid container spacing={3} style={{ marginBottom: "16px" }}>
          <Grid item>
            <DashboardWidgetCard
              title="Your Attendance is Good"
              icon={StarIcon}
            >
              You are <b>300 Days</b> present out of <b>365 Days</b>
            </DashboardWidgetCard>
          </Grid>

          <Grid item>
            <DashboardWidgetCard
              title="Hey!!"
              icon={FaMedal}
              variant="secondary"
            >
              You got no complains so far. We are proud of you <b>Raman.</b>
            </DashboardWidgetCard>
          </Grid>
        </Grid>

        <MySyllabusWidget /> */}
      </Box>
    </Box>
  );
};

export default ParentDashboardPage;
