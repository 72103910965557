import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_EXAM_MARKS_LOADING,
  GET_TEACHER_EXAM_MARKS_ERROR,
  GET_TEACHER_EXAM_MARKS_SUCCESS,
  ADD_TEACHER_EXAM_MARKS_LOADING,
  ADD_TEACHER_EXAM_MARKS_ERROR,
  ADD_TEACHER_EXAM_MARKS_SUCCESS,
  TeacherExamMarksDispatchTypes,
} from "./TeacherExamMarksActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

interface GetRequestParamsI {
  exam_info: string;
  exam_subject: string;
  grade: string;
  section: string;
}

export interface PostBulkDataI {
  marks_obtained: number;
  student_academic: string;
  remarks: string;
}

export const GetTeacherExamMarks =
  (request_params: GetRequestParamsI) =>
  async (dispatch: Dispatch<TeacherExamMarksDispatchTypes | SetSnackBarI>) => {
    const { exam_info, exam_subject, grade, section } = request_params;
    try {
      dispatch({
        type: GET_TEACHER_EXAM_MARKS_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/student_subject_mark/?exam_info=${exam_info}&exam_subject=${exam_subject}&grade=${grade}&section=${section}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHER_EXAM_MARKS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_EXAM_MARKS_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Marks Loading Failed",
      //   },
      // });
    }
  };

export const AddTeacherExamMarks =
  (bulk_data: PostBulkDataI[], request_params: GetRequestParamsI) =>
  async (dispatch: Dispatch<TeacherExamMarksDispatchTypes | SetSnackBarI>) => {
    const { exam_info, exam_subject, grade, section } = request_params;
    try {
      dispatch({
        type: GET_TEACHER_EXAM_MARKS_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/student_subject_mark/?exam_info=${exam_info}&exam_subject=${exam_subject}&grade=${grade}&section=${section}`,
        bulk_data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_TEACHER_EXAM_MARKS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Marks Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_TEACHER_EXAM_MARKS_ERROR,
      });

      const msg = error.response.data.error[0];
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: msg,
        },
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Marks Update Failed",
      //   },
      // });
    }
  };
