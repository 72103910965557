import React from 'react';
import { FinanceSidebar, FeeTypeReportList } from '../../../components';
import AccountantFeeReportSidebar from '../../../components/Event/EventSidebar/AccountantFeeReportSidebar';
import FeeLedgerList from '../../../components/Finance/FeeLedger/FeeLedgerList';
import SubModuleLayout from '../../../components/Reusable/Layouts/SubModuleLayout';

const AccountFeeTypeReport = () => {
  const nav = <AccountantFeeReportSidebar feeStatement />;
  return (
    <SubModuleLayout FeesMenuActive sideNav={nav}>
      <FeeTypeReportList />
    </SubModuleLayout>
  );
};

export default AccountFeeTypeReport;
