import React from "react";
import { Box, LinearProgress, Paper } from "@material-ui/core";
import { useFormStyles } from "../../Styles/FormStyles";
import {
  FormCreateButton,
  FormEditButton,
  FormResetButton,
  FormSaveLoadingButton,
} from "../Buttons/FormButton";
import { filterStyle } from "../../../dashboard/StudentDashboard/StudentDashboardStyles";
import SaveIcon from "@material-ui/icons/Save";
import UpdateIcon from "@material-ui/icons/Update"
import Spinner from '../../../common/spinnerSVG/Spinner'

const FormLayout = (props: any) => {
  const classes = useFormStyles();

  return (
    <Paper
      className={classes.pageContent}
      style={props.teacher ? filterStyle : undefined}
    >
      <Box className={classes.formTitle}>
        <span>{props.title}</span>
      </Box>

      <form className={classes.form} onSubmit={props.onSubmit}>
        {props.children}

        <Box display="flex" justifyContent="space-around" style={{ marginTop: '20px' }}>
          {props.editMode ? (
            <>
              <FormSaveLoadingButton
                icon={props.add_or_update ? <Spinner /> : <UpdateIcon />}
                disabled={props.add_or_update ? true : false}
                text={props.add_or_update ? "Updating..." : "Update"}
              />
              <FormResetButton onClick={props.onClick} />
            </>
          ) : (
            <FormSaveLoadingButton
              icon={props.add_or_update ? <Spinner /> : <SaveIcon />}
              disabled={props.add_or_update ? true : false}
              text={props.add_or_update ? "Creating..." : "Create"}
            />
          )}
        </Box>
      </form>

      {props.loading && <LinearProgress className={classes.formProgress} />}
    </Paper>
  );
};

export default FormLayout;