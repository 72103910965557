import {
  Button,
  LinearProgress,
  ListItem,
  Paper,
  Typography,
  Popover,
  List,
  Grid,
  Tooltip,
  ListItemText
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteFeeType,
  GetFeeType,
} from "../../../actions/Fees/FeeType/FeeTypeAction";
import { Fee_TypeI } from "../../../actions/Fees/FeeType/FeeTypeActionTypes";
import { RootStore } from "../../../store";
import { useTable, useTableWithPagination } from "../../Reusable";
import { ActionRoleAccess } from "../../utils/rolesConfig";

import {
  ItemEditButton,
  ItemDeleteButton,
} from "../../Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { useTableStyles } from "../../Styles/TableStyles";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { MONTH_CHOICES } from "../../../common/json.constant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
      left: "45%",
    },
  })
);

interface PropsI {
  onEditData: (value: Fee_TypeI) => void;
  editCheck: { check: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells: HeadCellsI[] = [
  { id: "name", label: "Fee Type" },
  { id: "is_monthly", label: "Type" },
  { id: "billing_period", label: "Billing Period" },
  { id: "due", label: "Due" },
  { id: "description", label: "Description" },
  { id: "action", label: "Action" },
];

const getMonthFromString = (item: string): string => {
  switch (item) {
    case "1":
      return "Baisakh";

    case "2":
      return "Jestha";

    case "3":
      return "Asar";

    case "4":
      return "Shrawan";

    case "5":
      return "Bhadra";

    case "6":
      return "Asoj";

    case "7":
      return "Karthik";

    case "8":
      return "Mangsir";

    case "9":
      return "Poush";

    case "10":
      return "Magh";

    case "11":
      return "Falgun";

    case "12":
      return "Chaitra";

    default:
      return "";
  }
};
export const getMonthName = (index: any) => {
  const month = MONTH_CHOICES.find(elem => Number(elem.key) === index)
  return month?.value || null
}

export const ToolTipSubjectViewComponent = (props: any) => {
  const { items } = props;

  return (
    <Grid direction="column" container>
      <Grid>
        <div>
          <List dense>
            {items.map((item: any, index: number) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle2">{getMonthName(items[index])}</Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
    </Grid>
  );
};

const FeeTypeTable = (props: PropsI) => {
  const { onEditData, editCheck } = props;

  const classes = useTableStyles();
  const popoverStyles = useStyles();
  const dispatch = useDispatch();

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const [loading, setLoading] = useState<boolean>(false);
  const feeTypeSelector = useSelector((state: RootStore) => state.fee_type);

  // States declaration
  const [tableData, setTableData] = useState<any>([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);

  const authState = useSelector((state: RootStore) => state.auth);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(GetFeeType(1));
  }, []);

  useEffect(() => {
    setTableData(feeTypeSelector.feeType?.results);

    setLoading(feeTypeSelector.loading);
  }, [feeTypeSelector]);

  useEffect(() => {
    editCheck && setEditMode(editCheck.check);
  }, [editCheck]);

  //Event handlers
  const handleEditClicked = (data: Fee_TypeI) => {
    onEditData(data);
    setEditMode(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (applied: boolean, value: boolean, id: string) => {
    if (!applied) {
      setItemId(id);
      setDeleteModalOpen(value);
    }
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteFeeType(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: Fee_TypeI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fee_type === "M" ? "Monthly" : "One-Time"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fee_type === "M" ?
            <Tooltip
              arrow
              title={
                item.billing_period?.length ? (
                  <ToolTipSubjectViewComponent items={item.billing_period} />
                ) : (
                  ''
                )
              }
            >
              <div>
                {`${item.billing_period && item.billing_period[0] ? getMonthName(item.billing_period[0]) : 'N/A'} ${item.billing_period?.length ? `+${item.billing_period?.length - 1} more` : ''
                  }`}
              </div>
            </Tooltip>
            : 'None'}

        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fee_type === 'M'
            ? item.due_period_type === "P"
              ? `${item.due_day} Days Prior`
              : `${item.due_day} Days Before`
            : dateConverterAdToBs(item.due_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.description}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.twoCell}>
          {/* <ItemEditButton
            disabled={ActionRoleAccess(
              authState?.role,
              "FINANCE",
              "fee_type",
              "update"
            ).toString()}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          /> */}
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            title={item.applied ? "Can't delete beacuse it has already been applied" : 'Delete'}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(item.applied, true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(
      GetFeeType(
        paginationIndex
      )
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Fee Type List</Typography>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          forPagination={feeTypeSelector.feeType}
          handlePaginationChange={handlePaginationChange}
          getCustomTableRow={getCustomTableRow}


        />
      </Paper>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
    </>
  );
};

export default FeeTypeTable;
