import axios from "axios";
import { Dispatch } from "redux";

import {
  StudentDispatchTypes,
  STUDENT_SEARCH_LOADING,
  STUDENT_SEARCH_ERROR,
  STUDENT_SEARCH_SUCCESS,
} from "./TeacherStudentListActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import {
  HeaderConfig,
  HeaderConfigForDelete,
} from "../../Auth/Login/LoginAction";
import {
  ADMIN_API_URL,
  TEACHER_API_URL,
} from "../../../config/SERVER_API_URLS";

//Student Admission

export const StudentSearchByTeacher =
  (grade_id: any, section_id?: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_SEARCH_LOADING,
      });

      let url = `${TEACHER_API_URL}/student_list/?grade=${grade_id}`;

      if (section_id)
        url = `${TEACHER_API_URL}/student_list/?grade=${grade_id}&section=${section_id}`;

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: STUDENT_SEARCH_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: STUDENT_SEARCH_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };
