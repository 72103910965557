import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_SUBJECT_LOADING,
  GET_TEACHER_SUBJECT_ERROR,
  GET_TEACHER_SUBJECT_SUCCESS,
  GET_TEACHER_ALL_SUBJECTS_LOADING,
  GET_TEACHER_ALL_SUBJECTS_ERROR,
  GET_TEACHER_ALL_SUBJECTS_SUCCESS,
  GET_TEACHER_SUBJECTS_BY_CLASS_ERROR,
  GET_TEACHER_SUBJECTS_BY_CLASS_LOADING,
  GET_TEACHER_SUBJECTS_BY_CLASS_SUCCESS,
  TeacherSubjectDispatchTypes,
} from "./TeacherSubjectActionTypes";

import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetTeacherSubject =
  (id: string, sectionID?: string) =>
    async (dispatch: Dispatch<TeacherSubjectDispatchTypes>) => {
      try {
        dispatch({
          type: GET_TEACHER_SUBJECT_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/my/subjects/?grade=${id}&section=${sectionID || ""}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_TEACHER_SUBJECT_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_TEACHER_SUBJECT_ERROR,
        });
      }
    };

export const GetTeacherAllSubjects =
  () => async (dispatch: Dispatch<TeacherSubjectDispatchTypes>) => {
    try {
      dispatch({
        type: GET_TEACHER_ALL_SUBJECTS_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/my-subjects/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHER_ALL_SUBJECTS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_ALL_SUBJECTS_ERROR,
      });
    }
  };

export const GetTeacherSubjectByGrade =
  (grade: string) =>
    async (dispatch: Dispatch<TeacherSubjectDispatchTypes>) => {
      try {
        dispatch({
          type: GET_TEACHER_ALL_SUBJECTS_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/my/subjects/?grade=${grade}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_TEACHER_ALL_SUBJECTS_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_TEACHER_ALL_SUBJECTS_ERROR,
        });
      }
    };

export const GetTeacherSubjectByClass =
  (grade: string, section: string) =>
    async (dispatch: Dispatch<TeacherSubjectDispatchTypes>) => {
      try {
        dispatch({
          type: GET_TEACHER_SUBJECTS_BY_CLASS_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/my-subjects/?grade_id=${grade}&section_id=${section}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_TEACHER_SUBJECTS_BY_CLASS_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_TEACHER_SUBJECTS_BY_CLASS_ERROR,
        });
      }
    };

    export const GetTeachingSubject =
  (grade: string, section: string) =>
    async (dispatch: Dispatch<TeacherSubjectDispatchTypes>) => {
      try {
        dispatch({
          type: GET_TEACHER_SUBJECTS_BY_CLASS_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/my-subjects/?grade_id=${grade}&section_id=${section}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_TEACHER_SUBJECTS_BY_CLASS_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_TEACHER_SUBJECTS_BY_CLASS_ERROR,
        });
      }
    };

