import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Edit, Save } from "@material-ui/icons";
import {
  Grid,
  Paper,
  TableHead,
  TableBody,
  Table,
  Button,
  Divider,
  TableRow,
} from "@material-ui/core";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { useTableWithPagination } from "../../../../components/Reusable";
import { RootStore } from "../../../../store";
import BackDropLoader from "../../../../components/Reusable/BackDropLoader"
// import { changeFields } from "../../../../rtk/features/exam/addMarks/addMarksSlice";
import { TeachingClassTypeI } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";
import { SectionTypeI } from "../../../../actions/Academics/Section/SectionActionTypes";
import { StudentI } from "../../../../actions/TeacherDash/TeacherRubric/TeacherRubricActionTypes"
import { T_ExamMarksI, T_ExamI, T_ExamSubjectsI } from "../../../../actions/TeacherDash/AddMarks/AddMarksActionTypes"
import { AddTeacherExamMarks, ChangeFields, GetTeacherExamMarks } from "../../../../actions/TeacherDash/AddMarks/AddMarksAction"

const CellInput = styled.input<{ fullWidth?: boolean; error?: boolean }>`
  && {
    width: ${(props) => (props.fullWidth ? `unset` : `80px`)};
    padding: 5px;
    border-radius: 3px;
    border: ${(props) => (props.error ? `1px solid red` : `1px solid #000`)};
    color: ${(props) => (props.error ? `red` : `#000`)};

    &:focus {
      outline: none;
    }
  }
`;

const ExamDetail = styled.span`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 1.6;

  & span {
    font-weight: 500;
  }
`;

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells = [
  { id: "roll_no", label: "Roll No" },
  { id: "student_name", label: "Student Name" },
  { id: "full_marks", label: "Full Marks" },
  { id: "pass_marks", label: "Pass Marks" },
  { id: "marks", label: "Marks" },
  { id: "remarks", label: "Remarks" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

interface AddMarksTableProps {
  stateProps: {
    selectedClass: TeachingClassTypeI | null,
    selectedSection: SectionTypeI | null,
    selectedExamType: T_ExamI | null,
    selectedSubject: T_ExamSubjectsI | null
  }
}


const AddMarksTable = ({ stateProps: { selectedClass, selectedExamType, selectedSection, selectedSubject } }: AddMarksTableProps) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { loading, marks, action_performed } = useSelector(
    (state: RootStore) => state.teacher_add_marks
  );


  const [editMode, setEditMode] = useState<boolean>(false);
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  useEffect(() => {
    if (action_performed) {
      setEditMode(false)

      if (selectedExamType && selectedSubject && selectedClass) {
        const requestParams = {
          grade: selectedClass.grade,
          exam_subject: selectedSubject.exam_subject_info.id,
          exam: selectedExamType.id,
        }
        dispatch(GetTeacherExamMarks(requestParams))
      }
    }
  }, [action_performed])

  const handleReset = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  const handleSaveMarks = () => {
    const data: {
      student_info: string;
      marks_obtained_th: number | null;
      marks_obtained_pr: number | null;
      remarks: string;
    }[] = [];

    marks.forEach((el: T_ExamMarksI) => {
      data.push({
        student_info: el.student_id,
        marks_obtained_th: Number(el.marks_obtained_theory),
        marks_obtained_pr: Number(el.marks_obtained_theory) || null,
        remarks: el.remarks || "",
      });
    });

    if (selectedExamType && selectedSubject && selectedClass) {
      dispatch(
        AddTeacherExamMarks(data, {
          grade: selectedClass.grade,
          exam_subject: selectedSubject.exam_subject_info.id,
          exam_info: selectedExamType.id,
          is_practical: selectedSubject.subject_info.has_practical
        })
      );
    }
  };

  return (
    <Paper className={classes.rootTableBox}>
      <Grid container>
        <Grid item xs={4}>
          <ExamDetail>
            Exam: <span>{selectedExamType?.exam_name || "-"}</span>
          </ExamDetail>
        </Grid>
        <Grid item xs={8}>
          <ExamDetail>
            Subject: <span>{selectedSubject?.name || "-"}</span>
          </ExamDetail>
        </Grid>
        <Grid item xs={4}>
          <ExamDetail>
            Grade: <span>{selectedClass?.grade_name || "-"}</span>
          </ExamDetail>
        </Grid>
        <Grid item xs={4}>
          <ExamDetail>
            Section: <span>{selectedSection?.name || "-"}</span>
          </ExamDetail>
        </Grid>
      </Grid>

      <Divider style={{ margin: "10px 0 15px 0" }} />
      <Grid container>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell rowSpan={2} align="center">
                Roll No
              </StyledTableCell>
              <StyledTableCell rowSpan={2} align="center">
                Student Name
              </StyledTableCell>
              <StyledTableCell colSpan={2} align="center">
                Full Marks
              </StyledTableCell>
              <StyledTableCell colSpan={2} align="center">
                Pass Marks
              </StyledTableCell>
              <StyledTableCell colSpan={2} align="center">
                Marks Obtained
              </StyledTableCell>
              <StyledTableCell rowSpan={2} align="center">
                Remarks
              </StyledTableCell>
            </StyledTableRow>

            <TableRow>
              <StyledTableCell align="center">TH</StyledTableCell>
              <StyledTableCell align="center">PR</StyledTableCell>

              <StyledTableCell align="center">TH</StyledTableCell>
              <StyledTableCell align="center">PR</StyledTableCell>

              <StyledTableCell align="center">TH</StyledTableCell>
              <StyledTableCell align="center">PR</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {marks.map((item: T_ExamMarksI, index) => (
              <StyledTableRow key={item.student_id}>
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">{item.student_full_name}</StyledTableCell>
                <StyledTableCell align="center">
                  {item.full_marks_theory || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {selectedSubject?.exam_subject_info.full_marks_practical || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.pass_marks_theory || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {selectedSubject?.exam_subject_info.pass_marks_practical || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {editMode ? (
                    <CellInput
                      value={item.marks_obtained_theory}
                      maxLength={4}
                      onChange={(e) =>
                        dispatch(
                          ChangeFields({
                            id: item.student_id,
                            field: "marksTheory",
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  ) : (
                    item.marks_obtained_theory || "--"
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {editMode ? (
                    <CellInput
                      value={item.marks_obtained_practical}
                      maxLength={4}
                      onChange={(e) =>
                        dispatch(
                          ChangeFields({
                            id: item.student_id,
                            field: "marksPractical",
                            value: e.target.value,
                          })
                        )
                      }
                      disabled={!selectedSubject?.subject_info.has_practical}
                    />
                  ) : (
                    item.marks_obtained_practical || "--"
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {editMode ? (
                    <CellInput
                      fullWidth
                      value={item.remarks}
                      onChange={(e) =>
                        dispatch(
                          ChangeFields({
                            id: item.student_id,
                            field: "remarks",
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  ) : (
                    item.remarks || "--"
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReset}
            startIcon={<Edit />}
            style={{ margin: "10px 10px 0 0" }}
          >
            {editMode ? "Cancel" : "Edit"}
          </Button>
          {editMode && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSaveMarks()}
              startIcon={<Save />}
              style={{ margin: "10px 10px 0 0" }}
            >
              Save
            </Button>
          )}
        </div>
      </Grid>
      <BackDropLoader open={loading} />
    </Paper>
  )
}

export default AddMarksTable