import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import { assignmentCategoryOptions, Tupple } from "../../../../common/json.constant"

interface AssignmentProps {
  grade_name: string;
  subject_name: string;
  assign_on: string;
  deadline: string;
  active: boolean;
  attached_file: string;
  description: string;
}

interface AssingmentViewProps {
  assignment: any;
}

const AssignmentView = (props: AssingmentViewProps) => {
  const {
    assign_on,
    deadline,
    active,
    attached_file,
    description,
    can_submit_after_deadline,
    title,
    assignment_type,
    submission_type,
    full_marks,
    pass_marks,
  } = props.assignment;

  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: "10px", marginTop: "16px" }}>
        <Grid item xs={2}>
          Submit after deadline:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {can_submit_after_deadline ? "Yes" : "No"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Title:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {title}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Assign On:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {new Date(assign_on).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Deadline:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {new Date(deadline).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Submission Type
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {submission_type}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Assignment Type
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {assignmentCategoryOptions.filter((item: Tupple) => item.key === assignment_type)[0].value}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Full Marks
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {full_marks || "N/A"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Pass Marks
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {pass_marks || "N/A"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Status
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {active ? "Running" : "Draft"}
          </Typography>
        </Grid>
      </Grid>

      {attached_file && (
        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={2}>
            File
          </Grid>

          <Grid item xs={10}>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
              {attached_file.map((item: any) =>
                <a href={item.file} target="_blank" rel="noreferrer" key={item.id} style={{ marginRight: "1.25em" }}>
                  {item.file?.substring(item.file?.lastIndexOf("/") + 1, item.file?.lastIndexOf("?"))}
                </a>)
              }
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid item xs={2}>
          Description:
        </Grid>

        <Grid
          item
          xs={10}
          style={{
            padding: "10px",
            marginBottom: "15px",
            // border: "1px solid #B6B4B3",
            // borderRadius: "6px",
          }}
        >
          {ReactHtmlParser(description) || <em>No Description Available</em>}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AssignmentView;
