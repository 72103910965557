import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { Box, Divider, Grid } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { AlertTitle, Alert } from '@material-ui/lab';
import clsx from 'clsx';
import moment from 'moment';
import { Link } from 'react-router-dom'
import { StudentDashboardAssignmentI } from '../../../../actions/StudentDash/Dashboard/StudentDashboardActionTypes';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingTop: '0',
      paddingBottom: '8px',
    },
    title: {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    itemTitle: {
      fontWeight: 500,
      marginBottom: 5,
      display: 'inline-block',
    },
    alertTitle: {
      fontSize: 12,
      marginBottom: 2,
      fontWeight: 600,
    },
    alertRoot: {
      width: '100%',
      color: 'rgba(0,0,0,.8)',
      borderRadius: 8,
      borderLeft: '8px solid #3fc581',
      background: 'rgb(72 197 129 / 10%)',
      '& > div': {
        width: '100%',
        padding: '4px 0',
      },
    },
    alertDanger: {
      borderLeft: '8px solid #e96c5a',
      background: 'rgb(233 108 90 / 0.1)',
    },
    alertWarning: {
      borderLeft: '8px solid #f2e82c',
      background: 'rgb(242 232 54 / 15%)',
    },
  })
);

export interface MyAssignmentWidgetPropsI {
  items: StudentDashboardAssignmentI[];
}

export default function MyAssignmentWidget(props: MyAssignmentWidgetPropsI) {
  const classes = useStyles();

  const [items, setItems] = useState<AssignmentType[]>([]);

  useEffect(() => {
    if (props.items && props.items.length > 0) {
      const data: AssignmentType[] = props.items.map((item) => ({
        id: item.id,
        start: item.assign_on,
        deadline: item.deadline,
        status: 'warning',
      }));

      setItems(data);
    }
  }, [props.items]);

  return (
    <List className={classes.root}>
      <ListItem dense button className={classes.title}>
        <ListItemText
          primary={
            <span
              style={{
                fontSize: '16px',
                fontWeight: 600,
                color: 'rgba(0,0,0,0.85)',
              }}
            >
              Your Assignments
            </span>
          }
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="comments">
            <Link to='/assignment'>
              <ChevronRightIcon />
            </Link>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Box paddingLeft="16px" paddingRight="16px" paddingBottom="8px">
        <Divider />
      </Box>

      {items &&
        items.length &&
        items.map((item) => {
          return (
            <ListItem key={item.id} role={undefined} dense button>
              <Alert
                icon={false}
                severity="success"
                className={clsx(
                  classes.alertRoot,
                  item.status === 'warning' && classes.alertWarning,
                  item.status === 'danger' && classes.alertDanger
                )}
              >
                <AlertTitle className={classes.alertTitle}>
                  {moment(item.start).format('MMMM DD')}
                </AlertTitle>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <b style={{ fontWeight: 500, fontSize: 13 }}>3 Subjects</b>
                  </Grid>
                  <Grid item style={{ fontSize: 13 }}>
                    Deadline <b style={{ fontWeight: 500 }}>{item.deadline}</b>
                  </Grid>
                </Grid>
              </Alert>
            </ListItem>
          );
        })}
    </List>
  );
}

export interface AssignmentType {
  id: string;
  start: string;
  deadline: string;
  status: string;
}
