import React from "react";
import { Box } from "@material-ui/core";
import MyTeachersList from "./MyTeachersList";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";

const crumbs = { name: "Academics", to: "/my-teachers" };

const MyTeacherPage = () => {
  return (
    <Box>
      <PageHeader title="My Teachers" module={crumbs} />
      <PageWrapper>
        <MyTeachersList />
      </PageWrapper>
    </Box>
  );
};

export default MyTeacherPage;
export { crumbs };
