import {
  GET_TEACHER_EXAM_MARKS_LOADING,
  GET_TEACHER_EXAM_MARKS_ERROR,
  GET_TEACHER_EXAM_MARKS_SUCCESS,
  TeacherExamMarksDispatchTypes,
  T_GetMarksDetailI,
  T_GetStudentMarksI,
} from "../../actions/TeacherDash/TeacherExamMarks/TeacherExamMarksActionTypes";

interface InitialStateI {
  loading: boolean;
  exam_marks: T_GetStudentMarksI[];
  marks_detail: T_GetMarksDetailI[];
}

const initialState: InitialStateI = {
  loading: false,
  exam_marks: [],
  marks_detail: [],
};

const TeacherExamMarksReducer = (
  state: InitialStateI = initialState,
  action: TeacherExamMarksDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_EXAM_MARKS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_EXAM_MARKS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_EXAM_MARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        exam_marks: action.payload.student,
        marks_detail: action.payload.subject_mark_detail,
      };

    default:
      return state;
  }
};

export default TeacherExamMarksReducer;
