import { SectionTypeI } from "../../Academics/Section/SectionActionTypes";

export const GET_PARENT_ANNOUNCEMENTS_LOADING =
  "GET_PARENT_ANNOUNCEMENTS_LOADING";
export const GET_PARENT_ANNOUNCEMENTS_ERROR = "GET_PARENT_ANNOUNCEMENTS_ERROR";
export const GET_PARENT_ANNOUNCEMENTS_SUCCESS =
  "GET_PARENT_ANNOUNCEMENTS_SUCCESS";

export type ParentAnnouncementTypeI = {
  priority: string;
  priority_display: string;
  grade: number | null;
  section: SectionTypeI[];
  grade_name: string | null;
  section_name: string | null;
  title: string;
  announcement_date: string;
  start_time: string;
  end_time: string;
  description: string;
  group_name: string;
  group: GroupTypeI[];
  user_name: string | null;
  user: UserTypeI[];
  created_on: string;
};

type GroupTypeI = {
  id: number;
  name: string;
};

type UserTypeI = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  general_info: string;
};

//===============================<START>GET TEACHER_ANNOUNCEMENTS<START>======================//

export interface GetParentAnnouncementsLoading {
  type: typeof GET_PARENT_ANNOUNCEMENTS_LOADING;
}

export interface GetParentAnnouncementsError {
  type: typeof GET_PARENT_ANNOUNCEMENTS_ERROR;
}

export interface GetParentAnnouncementsSuccess {
  type: typeof GET_PARENT_ANNOUNCEMENTS_SUCCESS;
  payload: any;
}
//===============================<END>GET Parent_ANNOUNCEMENTS<END>======================//

export type ParentAnnouncementsDispatchTypes =
  | GetParentAnnouncementsLoading
  | GetParentAnnouncementsError
  | GetParentAnnouncementsSuccess;
