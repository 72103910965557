import {
  GET_TEACHER_EXAMS_ERROR,
  GET_TEACHER_EXAMS_LOADING,
  GET_TEACHER_EXAMS_SUCCESS,
  GET_TEACHER_EXAM_MARKS_ERROR,
  GET_TEACHER_EXAM_MARKS_LOADING,
  GET_TEACHER_EXAM_MARKS_SUCCESS,
  GET_TEACHER_EXAM_SUBJECTS_ERROR,
  GET_TEACHER_EXAM_SUBJECTS_LOADING,
  GET_TEACHER_EXAM_SUBJECTS_SUCCESS,
  ADD_TEACHER_EXAM_MARKS_SUCCESS,
  TeacherAddMarksDispatchTypes,
  CHANGE_FIELDS,
  RELOAD,
  T_ExamI,
  T_ExamMarksI,
  T_ExamSubjectsI,
} from "../../../actions/TeacherDash/AddMarks/AddMarksActionTypes"

interface InitialStateI {
  loading: boolean;
  exams: T_ExamI[],
  subjects: T_ExamSubjectsI[],
  marks: T_ExamMarksI[],
  action_performed: boolean,
}
const initialState: InitialStateI = {
  loading: false,
  exams: [],
  marks: [],
  subjects: [],
  action_performed: false,
}

const TeacherAddMarksReducer = (
  state: InitialStateI = initialState,
  action: TeacherAddMarksDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_EXAMS_LOADING:
      return {
        ...state,
        exams: [],
        loading: true,
        action_performed: false,
      }
    case GET_TEACHER_EXAMS_ERROR:
      return {
        ...state,
        exams: [],
        loading: false,
        action_performed: false,
      }
    case GET_TEACHER_EXAMS_SUCCESS:
      return {
        ...state,
        exams: action.payload,
        loading: false,
        action_performed: false,
      }

    case GET_TEACHER_EXAM_SUBJECTS_LOADING:
      return {
        ...state,
        subjects: [],
        loading: true,
        action_performed: false,
      }
    case GET_TEACHER_EXAM_SUBJECTS_ERROR:
      return {
        ...state,
        subjects: [],
        loading: false,
        action_performed: false,
      }
    case GET_TEACHER_EXAM_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: action.payload,
        loading: false,
        action_performed: false,
      }

    case GET_TEACHER_EXAM_MARKS_LOADING:
      return {
        ...state,
        marks: [],
        loading: true,
        action_performed: false,
      }
    case GET_TEACHER_EXAM_MARKS_ERROR:
      return {
        ...state,
        marks: [],
        loading: false,
        action_performed: false,
      }
    case GET_TEACHER_EXAM_MARKS_SUCCESS:
      return {
        ...state,
        marks: action.payload,
        loading: false,
        action_performed: false,
      }

    case ADD_TEACHER_EXAM_MARKS_SUCCESS:
      return {
        ...state,
        action_performed: true,
      }

    case CHANGE_FIELDS:
      const { id, field, value } = action.payload;
      const current_data: T_ExamMarksI[] = state.marks;
      const index = current_data.findIndex((el: T_ExamMarksI) => el.student_id === id)
      const target = state.marks.find((el) => el.student_id === id)
      if (target) {
        if (field === "marksTheory") {
          const re = /^[0-9]*\.?[0-9]*$/;

          if (value === "" || re.test(value)) {
            current_data[index].marks_obtained_theory = value;
          }
        }

        if (field === "marksPractical") {
          const re = /^[0-9]*\.?[0-9]*$/;

          if (value === "" || re.test(value)) {
            current_data[index].marks_obtained_practical = value;
          }
        }

        if (field === "remarks") {
          current_data[index].remarks = value;
        }

        return {
          ...state,
          marks: current_data
        };
      }
      return state;

    case RELOAD:
      return {
        ...state,
        marks: [],
        subjects: [],
      }

    default:
      return state;
  }
}

export default TeacherAddMarksReducer;