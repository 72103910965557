import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { SettingsBackupRestore } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SectionTypeI } from "../../../../actions/Academics/Section/SectionActionTypes";
import { SectionI } from '../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes'
import { GetExams } from "../../../../actions/Examination/Exam/ExamAction";
import { ExamTypeI } from "../../../../actions/Examination/Exam/ExamActionTypes";
import { GetTeacherExamMarks } from "../../../../actions/TeacherDash/TeacherExamMarks/TeacherExamMarksAction";
import { GetTeacherExamSubjects } from "../../../../actions/TeacherDash/TeacherExamSubjects/TeacherExamSubjectAction";
import { GetTeacherExamSubjectsTypeI } from "../../../../actions/TeacherDash/TeacherExamSubjects/TeacherExamSubjectsActionTypes";
import { GetTeachingClass } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassAction";
import { TeachingClassTypeI } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";
// import { GetTeacherSubjectByClass } from "../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectAction";
// import { TeacherSubjectByClass } from "../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes";
import {
  SubmitButton,
  ResetButton,
} from "../../../../components/Reusable/Buttons/SearchButton";
import { InputBox } from "../../../../components/Reusable/Inputs/Select";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import { RootStore } from "../../../../store";
import { filterStyle } from "../../../StudentDashboard/StudentDashboardStyles";

// -----------------------<START> Interface <START>--------------------------------//

interface PropsI {
  onSearch: (value: SearchParamI) => void;
}

interface SearchParamI {
  exam_info: string;
  exam_subject: string;
  subject: string;
  grade: string;
  grade_id: string;
  section: string;
  section_id: string;
}

interface SubjectTypeChoiceI {
  id: number;
  name: string;
}

// -----------------------<END> Interface <END>--------------------------------//

// -----------------------<START><START>--------------------------------//
// -----------------------<END><END>--------------------------------//

const AddMarks = (props: PropsI) => {
  const { onSearch } = props;
  const classes = useFormStyles();
  // -----------------------<START> States <START>--------------------------------//
  const [subjectLoading, setSubjectLoading] = useState<boolean>(false);

  const [selectedExamType, setSelectedExamType] = useState<ExamTypeI | null>(
    null
  );
  const [examTypeChoices, setExamTypeChoices] = useState<ExamTypeI[]>([]);

  const [selectedClass, setSelectedClass] = useState<TeachingClassTypeI | null>(
    null
  );
  const [classChoices, setClassChoices] = useState<TeachingClassTypeI[]>([]);

  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);

  const [selectedSubject, setSelectedSubject] =
    useState<GetTeacherExamSubjectsTypeI | null>(null);
  const [subjectChoices, setSubjectChoices] = useState<
    GetTeacherExamSubjectsTypeI[]
  >([]);
  const [selectedSubjectType, setSelectedSubjectType] =
    useState<SubjectTypeChoiceI | null>(null);

  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [subjectDisabler, setSubjectDisabler] = useState<boolean>(true);

  // -----------------------<END> States <END>--------------------------------//

  // =========================== <START> REACT HOOKS <START> ============================//

  const dispatch = useDispatch();
  const examSelector = useSelector((state: RootStore) => state.exam);
  const classSelector = useSelector((state: RootStore) => state.teaching_class);
  const subjectSelector = useSelector(
    (state: RootStore) => state.teacher_exam_subject
  );

  console.log("subject selector", subjectSelector)

  useEffect(() => {
    dispatch(GetExams());
    dispatch(GetTeachingClass());
  }, []);

  useEffect(() => {
    setExamTypeChoices(examSelector.exams);
  }, [examSelector]);

  useEffect(() => {
    classSelector.teaching_class &&
      setClassChoices(classSelector.teaching_class);
  }, [classSelector]);

  useEffect(() => {
    setSubjectLoading(subjectSelector.loading);
    setSubjectChoices(subjectSelector.exam_subjects);
  }, [subjectSelector]);
  // =========================== <END> REACT HOOKS <END> ============================//

  // -----------------------<START> Event Handlers <START>--------------------------------//

  const { register, handleSubmit } = useForm();

  const onSearchClick = () => {
    if (
      selectedExamType != null &&
      selectedClass != null &&
      selectedSubject != null
    ) {
      const params = {
        exam_info: selectedExamType.id,
        exam_subject: selectedSubject.id,
        grade: selectedClass.grade,
        section: selectedSection ? selectedSection.id : "",
      };

      dispatch(GetTeacherExamMarks(params));
      onSearch({
        exam_info: selectedExamType.id,
        exam_subject: selectedSubject.id,
        subject: `${selectedSubject.subject_name} ${selectedSubject.exam_type_display}`,
        grade: selectedClass.grade_name,
        grade_id: selectedClass.grade,
        section: selectedSection ? selectedSection.name : "",
        section_id: selectedSection ? selectedSection.id : "",
      });
    }
  };

  const handleExamTypeChange = (value: ExamTypeI | null) => {
    setSelectedExamType(value);

    if (value && selectedClass?.grade) {
      const param = {
        exam_info: value.id,
        grade: selectedClass.grade,
        section: selectedSection ? selectedSection.id : "",
      };

      dispatch(GetTeacherExamSubjects(param));
    }
  };

  const handleClassChange = (value: TeachingClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedSection(null);
    setSelectedSubject(null);

    if (value) {
      populateSectionChoices(value.section);
      populateSubjects(value, null);
    } else {
      setSelectedSection(null);
      setSelectedSubject(null);
      setSectionDisabler(true);
      setSubjectDisabler(true);
    }
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    setSelectedSection(value);
    setSelectedSubject(null);

    if (value) {
      selectedClass && populateSubjects(selectedClass, value);
    } else {
      selectedClass && populateSubjects(selectedClass, null);
    }
  };

  const populateSectionChoices = (sections: SectionI[] | null) => {
    if (sections === null) return;
    if (sections.length > 0) {
      setSectionDisabler(false);
      setSectionChoices(sections);
    } else {
      setSectionChoices([]);
      setSectionDisabler(true);
    }
  };

  const populateSubjects = (
    grade: TeachingClassTypeI,
    section: SectionTypeI | null
  ) => {
    if (selectedExamType != null && grade != null) {
      const param = {
        exam_info: selectedExamType.id,
        grade: grade.grade,
        section: section ? section.id : "",
      };
      dispatch(GetTeacherExamSubjects(param));
      setSubjectDisabler(false);
    } else {
      setSelectedSubject(null);
      setSubjectChoices([]);
      setSubjectDisabler(true);
    }
  };

  const handleReset = () => {
    setSelectedExamType(null);
    setSelectedClass(null);
    setSelectedSection(null);
    setSelectedSubject(null);
  };

  // -----------------------<END> Event Handlers<END>--------------------------------//

  return (
    <>
      <Paper style={filterStyle}>
        <Box className={classes.formTitle}>
          <span>Search Class and Subject</span>
        </Box>
        <form className={classes.form} onSubmit={handleSubmit(onSearchClick)}>
          <Grid container>
            <Grid item md={3} className={classes.formWrapperRow}>
              <InputBox>Exam</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ExamTypeI | null
                ) => handleExamTypeChange(value)}
                options={examTypeChoices}
                value={selectedExamType}
                getOptionLabel={(option) => option.exam_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    variant="outlined"
                    name="exam_type"
                    inputRef={register({ required: true })}
                  />
                )}
              />
              <span className={classes.validationErrorInfo} />
            </Grid>
            <Grid item md={2} className={classes.formWrapperRow}>
              <InputBox>Class</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: TeachingClassTypeI | null
                ) => handleClassChange(value)}
                options={classChoices}
                value={selectedClass}
                getOptionLabel={(option) => option.grade_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    variant="outlined"
                    name="grade"
                    inputRef={register({ required: true })}
                  />
                )}
              />
              <span className={classes.validationErrorInfo} />
            </Grid>
            <Grid item md={2} className={classes.formWrapperRow}>
              <InputBox>Section</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                value={selectedSection}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionTypeI | null
                ) => handleSectionChange(value)}
                options={sectionChoices}
                getOptionLabel={(option) => option.name}
                disabled={sectionDisabler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    variant="outlined"
                    name="section"
                    inputRef={register}
                  />
                )}
              />
              <span className={classes.validationErrorInfo} />
            </Grid>
            <Grid
              item
              style={{ maxWidth: "20%" }}
              md={3}
              className={classes.formWrapperRow}
            >
              <InputBox>Subject</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                value={selectedSubject}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: GetTeacherExamSubjectsTypeI | null
                ) => setSelectedSubject(value)}
                options={subjectChoices}
                getOptionLabel={(option) =>
                  `${option.subject_name} ${option.exam_type === "1" ? "(TH)" : "(PR)"
                  }`
                }
                disabled={subjectDisabler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    variant="outlined"
                    name="subject"
                    inputRef={register({ required: true })}
                  />
                )}
              />
              <span className={classes.validationErrorInfo} />
            </Grid>
            <SubmitButton style={{ marginTop: "5px" }} />
            <ResetButton style={{ marginTop: "5px" }} onClick={handleReset} />
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default AddMarks;
