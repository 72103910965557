import EnhancedSidebar from '../../components/NewSideBar/EnhancedSidebar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NoteIcon from '@material-ui/icons/Note';
import { HiSpeakerphone } from 'react-icons/hi';
import { MdAssignment } from 'react-icons/md';
import { HiAcademicCap } from 'react-icons/hi';
import { MdEventNote } from 'react-icons/md';
import { GiTeacher } from 'react-icons/gi';
import { BsCardChecklist } from 'react-icons/bs';
import { BiCommentError } from 'react-icons/bi';
import { IoMdHand } from 'react-icons/io';
import { GoTasklist } from 'react-icons/go';
import { FaClipboardList, FaChalkboardTeacher } from 'react-icons/fa';

const user = localStorage.getItem('hamro_user');
const userId = user ? JSON.parse(user).id : 'user';

const dataSet: any = [
  {
    text: 'Dashboard',
    url: '/',
    icon: DashboardIcon,
    activeStatus: location.pathname === '/',
  },
  {
    text: 'Academics',
    url: '',
    icon: HiAcademicCap,
    dataSet: [
      {
        text: 'My Teachers',
        url: '/my-teachers',
        icon: FaChalkboardTeacher,
        activeStatus: location.pathname === `/my-teachers`,
      },
      {
        text: 'My Attendances',
        url: '/my-attendance',
        icon: IoMdHand,
        activeStatus: location.pathname === '/my-attendance',
      },
      {
        text: 'My TimeTable',
        url: '/timetable',
        icon: MdEventNote,
        activeStatus: location.pathname === '/timetable',
      },
      {
        text: 'Exam Schedule',
        url: '/exam-schedule',
        icon: FaClipboardList,
        activeStatus: location.pathname === '/exam-schedule',
      },
      {
        text: 'My Complains',
        url: '/my-complains',
        icon: BiCommentError,
        activeStatus: location.pathname === '/my-complains',
      },
      {
        text: 'Lesson Plan',
        url: '/lesson-plan',
        icon: GoTasklist,
        activeStatus: location.pathname === '/lesson-plan',
      },
      {
        text: 'My Syllabus',
        url: '/syllabus',
        icon: BsCardChecklist,
        activeStatus: location.pathname === '/syllabus',
      },
      {
        text: 'Live Classes',
        url: '/live-classes',
        icon: GiTeacher,
        activeStatus: location.pathname === '/live-classes',
      },
    ],
  },
  {
    text: 'Assignment',
    url: '/assignment',
    icon: MdAssignment,
    activeStatus: location.pathname === '/assignment',
  },
  {
    text: 'Enotes',
    url: '/enote',
    icon: NoteIcon,
    activeStatus: location.pathname === '/enote',
  },
  {
    text: 'Leave',
    url: '/leave',
    icon: ExitToAppIcon,
    activeStatus: location.pathname === '/leave',
  },
  {
    text: 'Announcement',
    url: '/announcement',
    icon: HiSpeakerphone,
    activeStatus: location.pathname === '/announcement',
  },

  {
    text: 'Events',
    url: '/events',
    icon: EventNoteIcon,
    activeStatus: location.pathname === '/events',
  },
];

const StudentDashboardSidebar = () => {
  return <EnhancedSidebar items={dataSet} />;
};

export default StudentDashboardSidebar;
