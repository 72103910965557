import {
  Grid,
  Paper,
  TableBody,
  TextField,
  Typography,
} from '@material-ui/core';
import { DeleteForeverOutlined, VisibilityOutlined } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ParentAnnouncementTypeI } from '../../../../../actions/ParentsDash/ParentAnnouncement/ParentAnnoucementActionTypes';
import { GetParentAnnouncements } from '../../../../../actions/ParentsDash/ParentAnnouncement/ParentAnnouncementAction';
import { RootStore } from '../../../../../store';
import ActionButton from '../../../../../components/Reusable/Buttons/ActionButton';
import Popups from '../../../../../components/Reusable/Dialogs/Popups';
import { useTableStyles } from '../../../../../components/Styles/TableStyles';
import TeacherAnnouceModal from '../../../../TeacherDashboard/pages/TeacherAnnouncement/ModalView/TeacherAnnouceModal';
import { dateConverterAdToBs } from '../../../../../components/utils/dateConverter';
import useTable from '../../../../../components/Reusable/useStudentTable';
import { tblStyle } from '../../../ParentDashboardStyles';
import { HeadCellsI } from '../../../../../actions';

export interface DataI {
  data: any[];
  message_to: React.ReactNode;
}

//---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 's.n', label: 'S.N.' },
  {
    id: 'annoucement_title',
    label: 'Annoucement Title',
    align: 'left',
    filter: true,
  },
  { id: 'notice_date', label: 'Notice Date' },
  { id: 'published_date', label: 'Published Date' },
  { id: 'action', label: 'Action' },
];

const ParentAnnounceTable = () => {
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<ParentAnnouncementTypeI[]>([]);
  const [tempTableData, setTempTableData] = useState<ParentAnnouncementTypeI[]>(
    []
  );

  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [item, setItem] = useState<any[] | null>(null);

  const [totalRecord, setTotalRecord] = useState(tempTableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const [page, setPage] = useState(1);

  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(rowsPerPage);

  // React Hooks

  const dispatch = useDispatch();

  const parentannounceSelector = useSelector(
    (state: RootStore) => state.parent_announcement
  );
  console.log(tableData)

  useEffect(() => {
    dispatch(GetParentAnnouncements());
  }, []);

  useEffect(() => {
    const data = parentannounceSelector.parent_announcements;
    setTableData(data.results);
    setTempTableData(data);
  }, [parentannounceSelector]);
  ////////////////////////////////////////////////////////

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search_data = tempTableData.filter((data) =>
      data.title.toUpperCase().includes(e.target.value.toUpperCase())
    );
    setTableData(search_data);
  };

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
    TableContainer,
  } = useTable(headCells);

  // Modal View Popup

  const handleViewModal = (item: any) => {
    setItem(item);
    setItemId(item.id);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const getCustomTableRow = (item: ParentAnnouncementTypeI, index: number) => {
    return (
      <StyledTableRow key={index}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.announcement_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.created_on)}
          {/* {new Date().toNepali(item.created_on)} */}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ActionButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewModal(item)
            }
            title="View"
          >
            <VisibilityOutlined className={classes.viewIcon} />
          </ActionButton>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.rootTableBox} style={tblStyle}>
        <TableContainer
          loading={parentannounceSelector.loading}
          search_name="title"
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          items={tableData}
          label="Annoucements"
        />
      </Paper>
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        fullWidth={true}
        maxWidth="lg"
        title="Announcement Detail"
        colored
      >
        <TeacherAnnouceModal item={item} />
      </Popups>
    </>
  );
};

export default ParentAnnounceTable;
