// ---------------- <START> module import starts ------------------//
import { LinearProgress, Paper } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, InputLabel, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { useForm } from "react-hook-form";
import { useFormStyles } from "../../Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  AddGrade,
  UpdateGrade,
} from "../../../actions/Academics/Grade/GradeAction";
import { GradeTypeI } from "../../../actions/Academics/Grade/GradeActionTypes";
import { Autocomplete } from "@material-ui/lab";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedSelect from "../../Reusable/Inputs/Select";
// ---------------- <END> module import ends ------------------//

interface PropsI {
  editData: GradeTypeI | null;
  onEditMode: (value: boolean) => void;
}

interface GradeChoiceI {
  id: number;
  label: string;
  value: string;
}

// ---------------- <START> section form component starts ------------------//
const GradeForm = (props: PropsI) => {
  const { editData, onEditMode } = props;

  const classes = useFormStyles();

  //= =============================<START> Component States <START>=================================//
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string>("");
  const [grade, setGrade] = useState<GradeChoiceI | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const GRADE_CHOICES: GradeChoiceI[] = [
    { id: 0, value: "Play Group", label: "play-group" },
    { id: 14, value: 'KG', label: 'kg' },
    { id: 1, value: "Nursery", label: "nursery" },
    { id: 2, value: "LKG", label: "lkg" },
    { id: 3, value: "UKG", label: "ukg" },
    { id: 4, value: "One", label: "1" },
    { id: 5, value: "Two", label: "2" },
    { id: 6, value: "Three", label: "3" },
    { id: 7, value: "Four", label: "4" },
    { id: 8, value: "Five", label: "5" },
    { id: 9, value: "Six", label: "6" },
    { id: 10, value: "Seven", label: "7" },
    { id: 11, value: "Eight", label: "8" },
    { id: 12, value: "Nine", label: "9" },
    { id: 13, value: "Ten", label: "10" },
  ];

  //= =============================<END> Component States <END>=================================//

  //==================================<START>REDUX REDUCER<START>=============================//

  const dispatch = useDispatch();
  const sectionLoading = useSelector(
    (state: RootStore) => state.section.loading
  );
  const add_or_update = useSelector((state: RootStore) => state.grade.add_or_update)

  //==================================<END>REDUX REDUCER<END>=============================//

  //===================================<START>CYCLE HOOKS<START>===========================//

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      setEditMode(false);
      setEditID("");
    }
  }, [add_or_update])

  useEffect(() => {
    setLoading(sectionLoading);
  }, [sectionLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  //===================================<END>CYCLE HOOKS<END>===========================//

  //= =============================<START> Event Handlers <START>=================================//

  const handleEditTableData = (data: GradeTypeI) => {
    setEditMode(true);
    setEditID(data.id);
    const edit_data = GRADE_CHOICES.find(
      (element) => element.label === String(data.name)
    );
    edit_data && setGrade(edit_data);
  };

  //= =============================<END> Event Handlers <END>=================================//

  //= ===================================<START> REACT HOOK FORM <START>==========================//

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    setError,
    clearErrors,
    errors,
  } = useForm({
    mode: "onChange",
  });

  const handleGrade = (value: any) => {
    setGrade(value);
    clearErrors("detail");
  };

  const onFormSubmit = () => {
    setIsBtnLoading(true);
    if (grade) {
      if (editMode) {
        editID != null && dispatch(UpdateGrade(editID, { name: grade.label }));
      } else {
        dispatch(AddGrade({ name: grade.label }));
      }
    }
  };

  const handleReset = () => {
    setGrade(null);
    setEditID("");
    setEditMode(false);
    reset();
    onEditMode(false);
  };

  //= ===================================<END> REACT HOOK FORM <END>==========================//

  //----------------------ERROR HANDLING---------------------------//
  const [serverErrors, setServerErrors] = useState<any>(null);
  const gradeSelector = useSelector((state: RootStore) => state.grade);
  const errorsData = gradeSelector.errors;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: "serverSideError",
            message: errorsData.error[elem] && errorsData.error[elem],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (gradeSelector.recent) {
      handleReset();
    }
  }, [gradeSelector.recent]);

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Grade" : "Add Grade"}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedSelect
          label="Grade"
          name="name"
          required
          options={GRADE_CHOICES}
          value={grade}
          setValue={handleGrade}
          error={errors["name"] ? "Grade must be selected" : ""}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.detail?.type === "serverSideError" && errors.detail.message}
        </span>
      </FormLayout>
    </>
  );
};
// ---------------- <END> section form components ends ------------------//

export default GradeForm;
