import {
    GET_MISC_FEE_TYPE_ERROR,
    GET_MISC_FEE_TYPE_LOADING,
    GET_MISC_FEE_TYPE_SUCCESS,
    CREATE_MISC_FEE_TYPE_SUCCESS,
    CREATE_MISC_FEE_TYPE_LOADING,
    CREATE_MISC_FEE_TYPE_ERROR,
    UPDATE_MISC_FEE_TYPE_SUCCESS,
    UPDATE_MISC_FEE_TYPE_LOADING,
    UPDATE_MISC_FEE_TYPE_ERROR,
    DELETE_MISC_FEE_TYPE_SUCCESS,
    DELETE_MISC_FEE_TYPE_LOADING,
    DELETE_MISC_FEE_TYPE_ERROR,
    APPLY_MISC_FEE_TYPE_SUCCESS,
    APPLY_MISC_FEE_TYPE_LOADING,
    APPLY_MISC_FEE_TYPE_ERROR,
    MiscFeeTypeDispatchTypes,
    MiscFeeTypeI
} from "../../actions/Fees/MiscFee/MiscFeeTypeActionTypes";

interface InitialStateI {
    loading: boolean;
    stLoading: boolean;
    feeType: any;
    stFee: Array<Object>;
    errors: any;
    recent?: boolean;
    action_performed: boolean;
    add_or_update: boolean;
}

const initialState: InitialStateI = {
    loading: false,
    stLoading: false,
    feeType: [],
    stFee: [],
    errors: null,
    recent: false,
    action_performed: false,
    add_or_update: false,
};

const MiscFeeTypeReducer = (
    state: InitialStateI = initialState,
    action: MiscFeeTypeDispatchTypes
): InitialStateI => {
    switch (action.type) {
        case GET_MISC_FEE_TYPE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_MISC_FEE_TYPE_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_MISC_FEE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                feeType: action.payload,
                errors: null,
            };

        case CREATE_MISC_FEE_TYPE_LOADING:
            return {
                ...state,
                loading: true,
                action_performed: false,
                add_or_update: false,
            };

        case CREATE_MISC_FEE_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                errors: action.payload,
                action_performed: false,
                add_or_update: true,
            };

        case CREATE_MISC_FEE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                feeType: [...state.feeType, action.payload],
                errors: null,
                recent: true,
                action_performed: true,
                add_or_update: true,
            };

        case UPDATE_MISC_FEE_TYPE_LOADING:
            return {
                ...state,
                loading: true,
                action_performed: false,
                add_or_update: false,
            };

        case UPDATE_MISC_FEE_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                errors: action.payload,
                action_performed: false,
                add_or_update: true,
            };

        case UPDATE_MISC_FEE_TYPE_SUCCESS:
            const current_data: any = state.feeType.results;
            const index: number = current_data.findIndex(
                (element: any) => element.id === action.payload.id
            );
            current_data[index] = action.payload;

            return {
                ...state,
                loading: false,
                feeType: current_data,
                errors: null,
                recent: true,
                action_performed: true,
                add_or_update: true,
            };

        case DELETE_MISC_FEE_TYPE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case DELETE_MISC_FEE_TYPE_ERROR:
            return {
                ...state,
                loading: false,
            };

        case DELETE_MISC_FEE_TYPE_SUCCESS:
            const new_data: any = state.feeType;
            const item_index = new_data.results.findIndex(
                (element: any) => element.id === action.payload.id
            );
            new_data.splice(item_index, 1);

            console.log({ new_data })

            return { ...state, loading: false, feeType: new_data };

        case APPLY_MISC_FEE_TYPE_LOADING:
            return {
                ...state,
                loading: true,
                add_or_update: false
            };

        case APPLY_MISC_FEE_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                add_or_update: true
            };

        case APPLY_MISC_FEE_TYPE_SUCCESS:
            return { ...state, loading: false, add_or_update: true };

        default:
            return state;
    }
};

export default MiscFeeTypeReducer;
