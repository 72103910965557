import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import { useParams } from 'react-router-dom'
import { Paper, Grid, InputLabel, TextField } from "@material-ui/core"
import { Autocomplete } from '@material-ui/lab';
import { filterStyle } from "../../../components/Styles/TeacherDashboardStyles";
import styled from "styled-components";
import {
    assignmentStatusOptions,
    Tupple,
} from "../../../../../common/json.constant"
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import { GetSubmittedAssignments, GetUnSubmittedAssignments } from "../../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction"

const Heading = styled.span`
    color: rgb(77, 79, 92);
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 10em;
`

const SubHeading = styled.span`
    color: rgb(24, 24, 27);
    font-size: 15px;
`

const AssignmentInfo = ({ status }: { status: any }) => {
    const { selectedStatus, setSelectedStatus } = status;
    const { id }: { id: string } = useParams();
    const classes = useFormStyles();
    const dispatch = useDispatch()

    const { assignmentById: assignment, added } = useSelector((state: RootStore) => state.teacher_assignment)

    useEffect(() => {
        if (selectedStatus !== null) {
            selectedStatus.key === "S" && dispatch(GetSubmittedAssignments(id))
            selectedStatus.key === "U" && dispatch(GetUnSubmittedAssignments(id))
        }
    }, [selectedStatus])

    useEffect(() => {
        added && selectedStatus.key === "S" && dispatch(GetSubmittedAssignments(id))
        added && selectedStatus.key === "U" && dispatch(GetUnSubmittedAssignments(id))
    }, [added])

    return (
        <Paper style={filterStyle}>
            <Grid container alignContent="center" justifyContent="space-between">
                <Grid justifyContent="space-between" style={{ minWidth: "200px" }}>
                    <Grid item >
                        <Heading>{assignment?.grade?.name} ({assignment?.section?.name}) - {assignment?.subject?.name}</Heading>
                    </Grid>
                    <Grid item >
                        <form className={classes.form}>
                            <Autocomplete
                                style={{
                                    padding: 0,
                                }}
                                value={selectedStatus}
                                onChange={(
                                    event: React.ChangeEvent<{}>,
                                    value: Tupple | null
                                ) => setSelectedStatus(value)}
                                options={assignmentStatusOptions}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Filter by status"
                                        name="status_name"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </form>
                    </Grid>
                </Grid>
                {assignment && <Grid>
                    <Grid item>
                        <SubHeading>
                            Full Marks: {assignment.full_marks || "N/A"}
                        </SubHeading>
                        <SubHeading style={{ marginLeft: "0.75em" }}>
                            Pass Marks: {assignment.pass_marks || "N/A"}
                        </SubHeading>
                    </Grid>
                    <Grid item>
                        <SubHeading>
                            Assigned On: {new Date(assignment.assign_on).toLocaleString()}
                        </SubHeading>
                    </Grid>
                    <Grid item>
                        <SubHeading>
                            Deadline: {new Date(assignment.deadline).toLocaleString()}
                        </SubHeading>
                    </Grid>
                    <Grid item>
                        <SubHeading>
                            Submission Type: {assignment.submission_type}
                        </SubHeading>
                    </Grid>
                    <Grid item>
                        <SubHeading>
                            Can Submit After Deadline: {assignment.can_submit_after_deadline ? "Yes" : "No"}
                        </SubHeading>
                    </Grid>
                </Grid>}
            </Grid>

        </Paper>
    )
}

export default AssignmentInfo