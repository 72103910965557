import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { GetLeaveTypes } from '../../../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import { GetTeacherSearchedLeaves } from '../../../../../actions/TeacherDash/TeacherLeave/TeacherLeaveAction';
import { RootStore } from '../../../../../store';
import { FormRefreshButton } from '../../../../../components/Reusable/Buttons/FormButton';
import Popups from '../../../../../components/Reusable/Dialogs/Popups';
import CustomizedSelect, {
  RowSelect,
} from '../../../../../components/Reusable/Inputs/Select';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import { dateConverterBsToAd } from '../../../../../components/utils/dateConverter';
import TeacherLeaveAdd from './TeacherLeaveAdd';
import {
  FormNewButton,
  FormSearchButton,
} from '../../../../../components/Reusable/Buttons/SearchBoxButton';
import { CUR_NEPALI_DATE } from '../../../../../components/utils/nepaliDateUtils';
import {
  ResetButton,
  SubmitButton,
} from '../../../../../components/Reusable/Buttons/SearchButton';
import { filterStyle } from '../../../components/Styles/TeacherDashboardStyles';

const TeacherLeaveSearch = () => {
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { handleSubmit } = useForm();

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>(CUR_NEPALI_DATE);
  const [toDate, setToDate] = useState<string>(CUR_NEPALI_DATE);
  const [leaveType, setLeaveType] = useState<any>(null);

  useEffect(() => {
    dispatch(GetLeaveTypes);
  }, []);

  const leaveTypeState = useSelector((state: RootStore) => state.leave_type);

  const LEAVE_TYPE_CHOICES = leaveTypeState.leave_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  const onSubmit = (data: any) => {
    const query: any = {
      leaveType: leaveType ? leaveType.key : '',
      date_first: (fromDate && dateConverterBsToAd(fromDate)) || '',
      date_second: (toDate && dateConverterBsToAd(toDate)) || '',
    };

    dispatch(GetTeacherSearchedLeaves(query));
  };

  const handleNewClick = () => {
    setOpenPopup(true);
  };

  const handleReset = () => {
    setFromDate('');
    setToDate('');
    setLeaveType(null);

    const query: any = {
      leaveType: '',
      date_first: '',
      date_second: '',
    };

    dispatch(GetTeacherSearchedLeaves(query));
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  return (
    <Paper style={filterStyle}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6} className={classes.formTitle}>
            <Typography variant="h6">Teacher Leave Requests</Typography>
          </Grid>

          <Grid container item xs={6}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <FormNewButton onClick={handleNewClick}>
                  Request New Leave
                </FormNewButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <RowSelect
              fullWidth
              label="Leave"
              name="leaveType"
              options={LEAVE_TYPE_CHOICES}
              value={leaveType}
              placeholder={leaveType ? '' : 'Label'}
              setValue={setLeaveType}
            />
          </Grid>
          <SubmitButton style={{ marginTop: '5px' }} />
          <ResetButton style={{ marginTop: '5px' }} onClick={handleReset} />
        </Grid>
      </form>

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        title="Request Leave"
        maxWidth="sm"
      >
        <TeacherLeaveAdd onClose={handleClose} />
      </Popups>
    </Paper>
  );
};

export default TeacherLeaveSearch;
