import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { GetMyTeacher } from "../../../../actions/StudentDash/StudentMyTeacher/StudentTeacherAction";
import { RootStore } from "../../../../store";
import { StudentMyTeacherTypeI } from "../../../../actions/StudentDash/StudentMyTeacher/StudentTeacherActionTypes";
import getFullName from "../../../../components/utils/getFullName";
import useTable from "../../../../components/Reusable/useStudentTable";
import { tblStyle } from "../../StudentDashboardStyles";

interface HeadCellsI {
  id: string;
  label: string;
  filter?: boolean;
}

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "teacher_first_name", label: "Teacher Name", filter: true },
  { id: "subject_name", label: "Subject" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> My Teachers Table Starts ---------------------------//
const MyTeachersList = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //*-------------------------------STATE MANAGEMENT-------------------------*//
  const [tableData, setTableData] = useState<any[]>([]);

  //*-------------------------------REDUX MANAGEMENT-------------------------*//
  useEffect(() => {
    dispatch(GetMyTeacher());
  }, []);

  const MyTeacherSelector = useSelector(
    (state: RootStore) => state.student_myTeacher
  );

  useEffect(() => {
    const items = MyTeacherSelector.my_teachers;
    setTableData(items);
  }, [MyTeacherSelector]);

  //*-------------------------------OTHER FUNCTIONS-------------------------*//

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(item.staff_firstname, item.staff_lastname)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.subject}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.root} style={tblStyle}>
      <TableContainer
        label="My Teachers"
        items={tableData}
        loading={MyTeacherSelector.loading}
        headCells={headCells}
        search_name="teacher_first_name"
        getCustomTableRow={getCustomTableRow}
        hiddenCols={[-1]}
      />
    </Paper>
  );
};
// ---------------------------- <END> My Teachers Table Ends -------------------------------//

export default MyTeachersList;
