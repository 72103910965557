import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_TEACHING_CLASS_LOADING,
  GET_TEACHING_CLASS_ERROR,
  GET_TEACHING_CLASS_SUCCESS,
  TeachingClassDispatchTypes,
} from "./TeachingClassActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetTeachingClass =
  () =>
  async (dispatch: Dispatch<TeachingClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHING_CLASS_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/my-class/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHING_CLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHING_CLASS_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Teaching Class Loading Failed",
      //   },
      // });
    }
  };
