import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetTeacherProfile } from '../../../../../actions/TeacherDash/TeacherProfile/TeacherProfileAction';
import { RootStore } from '../../../../../store';
import CardfullWidthLayout from '../../../../../components/Reusable/Layouts/CardfullWidthLayout';
import { FullSubModuleLayout } from '../../../../../components/Reusable/Layouts/SubModuleLayout';
import getFullName from '../../../../../components/utils/getFullName';
import TeacherDashSidebar from '../../../components/TeacherDashSidebar';
import TeacherTabs from './TeacherTabs';

const TeacherProfile = () => {
  const dispatch = useDispatch();

  // const { staffID } = useParams<{ staffID: string }>();
  const [teacher, setTeacher] = useState<any>([]);
  const teacherID = useSelector((state: RootStore) => state.auth.user?.id);

  const nav = <TeacherDashSidebar TeacherProfile />;

  useEffect(() => {
    if (teacherID) {
      dispatch(GetTeacherProfile(teacherID));
    }
  }, [teacherID]);

  const teacherState = useSelector(
    (state: RootStore) => state.teacher_profile.teacher_profile
  );

  useEffect(() => {
    if (teacherState != null) {
      setTeacher(teacherState);
    }
  }, [teacherState]);

  const headData = [
    { id: 2, headCells: 'Gender:', cellData: teacher?.gender_display },
    {
      id: 3,
      headCells: 'Designation:',
      cellData: teacher?.designation_display,
    },
    { id: 4, headCells: 'Address:', cellData: teacher?.address },
    { id: 5, headCells: 'Phone:', cellData: teacher?.phone },
    {
      id: 6,
      headCells: 'Date Of Joining:',
      cellData: teacher?.date_of_joining,
    },
    { id: 7, headCells: 'Pan No.:', cellData: teacher?.pan_no },
  ];

  return (
    <CardfullWidthLayout
      fullName={getFullName(
        teacher?.user?.first_name,
        teacher?.user?.last_name
      )}
      headData={headData}
      profileImg={teacher?.photo}
    >
      <TeacherTabs teacherData={teacher} />
    </CardfullWidthLayout>
  );
};

export default TeacherProfile;
