import { Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AccountantFeeSettingsSidebar from '../../../components/Event/EventSidebar/AccountantFeeSettingsSidebar';
import BackDropLoader from '../../../components/Reusable/BackDropLoader';
import SubModuleLayout from '../../../components/Reusable/Layouts/SubModuleLayout';
import FinanceSidebar from '../../../components/Finance/FinanceSidebar';
import CollectFeeSearch from '../../../components/Finance/CollectFees/CollectFeeSearch';
import CollectFeeTabContent from '../../../components/Finance/CollectFees/CollectFeeTabContent';
import AccountantCollectFeeSidebar from '../../../components/Event/EventSidebar/AccountantCollectFeeSidebar';

interface SelectedFeesI {
  id: string;
  title: string;
  isMonthly: boolean;
}

export default function AccountCollectFeePage() {
  const [fees, setFees] = useState<SelectedFeesI[]>([]);
  const [months, setMonths] = useState<string[]>([]);
  const [student, setStudent] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const nav = <AccountantCollectFeeSidebar collectFee />;

  return (
    <SubModuleLayout sideNav={nav}>
      <Paper style={{ margin: '10px' }}>
        <CollectFeeSearch student={student} setStudent={setStudent} />
        <CollectFeeTabContent student={student} setStudent={setStudent} />
      </Paper>
      <BackDropLoader open={loading} />
    </SubModuleLayout>
  );
}
