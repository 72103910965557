import { Button, Grid, InputLabel, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import GetAppIcon from "@material-ui/icons/GetApp";
import styled from "styled-components";

import { useFormStyles } from "../../Styles/FormStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ExamTypeI } from "../../../actions/Examination/Exam/ExamActionTypes";
import { GetExams } from "../../../actions/Examination/Exam/ExamAction";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { getExamSchedule } from "../../../rtk/features/exam/examSchedule/examScheduleApi";
import {
  generateGradeResult,
  getMarksLedger,
} from "../../../rtk/features/exam/marksLedger/marksLedgerApi";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { setSearchParams } from "../../../rtk/features/exam/marksLedger/marksLedgerSlice";

const Wrapper = styled(Paper)`
  background-color: #fff;
  padding: 16px;
  margin: 10px;
`;

const SearchButton = styled(Button)`
  margin: 5px;
  width: 120px;
`;

const LedgerForm: React.FC = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const [resultFor, setResultFor] = React.useState<string>("terminal");
  const [resultIn, setResultIn] = React.useState<string>(
    "marksheet_gardesheet"
  );

  const [selectedExam, setSelectedExam] = useState<ExamTypeI | null>(null);

  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);

  const [sectionChoice, setSectionChoice] = useState<SectionTypeI[]>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionDisabled, setSectionDisabled] = useState<boolean>(true);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  //   Redux Store Selector
  const { exams } = useSelector((state: RootStore) => state.exam);
  const ClassSelector = useSelector((state: RootStore) => state.class);
  const { result_generated } = useSelector(
    (state: RootStore) => state.marksLedger
  );
  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(GetExams());
    dispatch(GetClasses());
    // dispatch(GetGeneralInfoData())
  }, []);

  useEffect(() => {
    if (result_generated === false) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [result_generated]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResultFor((event.target as HTMLInputElement).value);

    if ((event.target as HTMLInputElement).value === "annual") {
      setSelectedExam(null);
    }
  };

  const handleResultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResultIn((event.target as HTMLInputElement).value);
  };

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedSection(null);

    if (value) {
      if (value.section.length) {
        setSectionChoice(value.section);
        setSectionDisabled(false);
      } else {
        setSectionChoice([]);
        setSectionDisabled(true);
      }
    } else {
      setSectionChoice([]);
      setSectionDisabled(true);
    }
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    setSelectedSection(value);
  };

  const handleSearch = () => {
    if (resultFor === "annual") {
      if (!selectedClass) {
        dispatch(setSnackbar(true, "warning", "Select Grade"));
        return;
      }
    } else {
      if (!selectedExam) {
        dispatch(setSnackbar(true, "warning", "Select Exam"));
        return;
      }
      if (!selectedClass) {
        dispatch(setSnackbar(true, "warning", "Select Grade"));
        return;
      }

      dispatch(
        getMarksLedger({
          examId: selectedExam.id,
          gradeId: selectedClass.grade,
          sectionId: selectedSection?.id || "",
        })
      );
      dispatch(
        setSearchParams({
          exam: selectedExam.exam_name,
          grade: selectedClass.grade_name,
          section: selectedSection?.name || "",
        })
      );
    }
  };

  const handleGenerateResult = () => {
    if (selectedExam && selectedClass) {
      dispatch(
        generateGradeResult({
          examId: selectedExam.id,
          gradeId: selectedClass.grade,
        })
      );
    }
  };

  return (
    <>
      <Wrapper>
        <Grid container>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Exam Result</FormLabel>
              <RadioGroup
                aria-label="exam_result"
                name="exam_result"
                value={resultFor}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="annual"
                  control={<Radio />}
                  label="Annual Result"
                />
                <FormControlLabel
                  value="terminal"
                  control={<Radio />}
                  label="Terminal Result"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* <Grid item xs={8}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Result Display</FormLabel>
              <RadioGroup
                aria-label="exam_result"
                name="exam_result"
                value={resultIn}
                onChange={handleResultChange}
                row
              >
                <FormControlLabel
                  value="marksheet_gardesheet"
                  control={<Radio />}
                  label="Marksheet/Gradesheet"
                />
                <FormControlLabel
                  value="marksheet"
                  control={<Radio />}
                  label="Marksheet"
                />
                <FormControlLabel
                  value="gradesheet"
                  control={<Radio />}
                  label="Gradesheet"
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <InputLabel>Exam</InputLabel>
              <Autocomplete
                value={selectedExam}
                onChange={(event: React.ChangeEvent<{}>, value) =>
                  setSelectedExam(value)
                }
                options={exams}
                getOptionLabel={(option) => option.exam_name}
                disabled={resultFor === "annual"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="exam"
                    placeholder="Select Exam"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <InputLabel>Class</InputLabel>
              <Autocomplete
                value={selectedClass}
                onChange={(event: React.ChangeEvent<{}>, value) =>
                  handleClassChange(value)
                }
                options={ClassSelector.classes}
                getOptionLabel={(option) => option.grade_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="select_message"
                    placeholder="Select Class"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <InputLabel>Section</InputLabel>
              <Autocomplete
                value={selectedSection}
                onChange={(event: React.ChangeEvent<{}>, value) =>
                  handleSectionChange(value)
                }
                options={sectionChoice}
                getOptionLabel={(option) => option.name}
                disabled={sectionDisabled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="select_message"
                    placeholder="Select Section"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <SearchButton
              color="primary"
              variant="contained"
              onClick={() => handleSearch()}
              startIcon={<GetAppIcon />}
            >
              Load
            </SearchButton>
          </Grid>
        </Grid>
      </Wrapper>
      <CustomizedDialogs
        title="Generate Result"
        dialogTitle="Result for this class has not been generated yet. Do you want to generate result for this class?"
        okText="Generate"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        deleteConfirmed={() => handleGenerateResult()}
      />
    </>
  );
};

export default LedgerForm;
