import { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import ExamScheduleSearch from "./ExamScheduleSearch";
import ExamScheduleTable from "./ExamScheduleTable";
import { crumbs } from "../MyTeacher/MyTeacherPage";

interface SearchDataI {
  exam: string;
}

const ExamSchedulePage = () => {
  const [searchParam, setSearchParam] = useState<SearchDataI | null>(null);

  const handleSearch = (data: SearchDataI) => {
    setSearchParam(data);
  };

  return (
    <Box>
      <PageHeader title="Exam Schedule" module={crumbs} />
      <PageWrapper>
        <Grid container>
          <Grid item xs={12}>
            <ExamScheduleSearch onSearch={handleSearch} />
            <ExamScheduleTable searchTitle={searchParam} />
          </Grid>
        </Grid>
      </PageWrapper>
    </Box>
  );
};

export default ExamSchedulePage;
