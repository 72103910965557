import {
  GET_TEACHER_APE_LOADING,
  GET_TEACHER_APE_ERROR,
  GET_TEACHER_APE_SUCCESS,
  ADD_TEACHER_APE_LOADING,
  ADD_TEACHER_APE_ERROR,
  ADD_TEACHER_APE_SUCCESS,
} from "../../actions/TeacherDash/APE/TeacherAPEActionTypes";

interface InitialStateI {
  loading: boolean;
  exam_apes: any;
  errors: any;
  recent?: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  exam_apes: [],
  errors: null,
  recent: false,
};

const TeacherAPEreducer = (
  state: InitialStateI = initialState,
  action: any
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_APE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_APE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_APE_SUCCESS:
      return {
        loading: false,
        exam_apes: action.payload,
        errors: null,
        recent: false,
      };

    case ADD_TEACHER_APE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_TEACHER_APE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case ADD_TEACHER_APE_SUCCESS:
      return {
        loading: false,
        exam_apes: [...state.exam_apes, action.payload],
        errors: null,
        recent: true,
      };

    default:
      return state;
  }
};

export default TeacherAPEreducer;
