import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  TableContainer,
  Checkbox,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import { useCheckBoxTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import { SearchedStudentsI } from '../../../actions/Student/Student/StudentActionTypes';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { StudentSearchForm } from '../..';
interface HeadCellsI {
  id: string;
  label: string;
}

interface PropsI {
  onStudentChange: (student_ids: string[]) => void;
  handleApplyFees: () => void;
  setIsBtnLoading: (data: boolean) => void;
  isBtnLoading: boolean;
}

const headCells: HeadCellsI[] = [
  { id: 'index', label: 'S.N' },
  { id: 'student', label: 'Student Name' },
  { id: 'phone', label: 'Phone' },
  { id: 'address', label: 'Address' },
  { id: 'guadian', label: 'Guardian Name' },
];

const StudentSelectTable = (props: any) => {
  const {
    onStudentChange,
    handleApplyFees,
    isBtnLoading,
    setIsBtnLoading,
    grade,
    setGrade,
    section,
    setSection,
    tempTableData,
    setTempTableData,
  } = props;

  const classes = useTableStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  // const [grade, setGrade] = useState<any | null>(null);

  const [tableData, setTableData] = useState<any>([]);

  const studentSelector = useSelector(
    (state: RootStore) => state.student.searched_student
  );

  const applyFeeAction = useSelector(
    (state: RootStore) => state.fee.actionPerformed
  );

  useEffect(() => {
    if (applyFeeAction) {
      setSelected([]);
    }
  }, [applyFeeAction]);

  useEffect(() => {
    setTableData(studentSelector?.results);
  }, [studentSelector]);

  useEffect(() => {
    onStudentChange(selected);
  }, [selected]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useCheckBoxTable(headCells);

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n: any) => n.academic_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const getCustomTableRow = (row: SearchedStudentsI, index: number) => {
    const isItemSelected = isSelected(row.academic_id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);
    return (
      <StyledTableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.academic_id}
        selected={isItemSelected}
      >
        <StyledTableCell className={cellStyle} align="left">
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Checkbox
                color="default"
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
                onClick={() => handleCheckClick(row.academic_id)}
              />
            </Grid>
            <Grid item>{index + 1}</Grid>
          </Grid>
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="center">
          {row.student_first_name} {row.student_last_name}
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="center">
          {row.phone_number}
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="center">
          {row.address}
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="center">
          {row.guardian_first_name} {row.guardian_last_name}
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  return (
    <Paper
      className={classes.rootCheckBoxTable}
      style={{ marginRight: '5px', marginLeft: '5px' }}
    >
      <Grid container justifyContent="center">
        {/* <Grid item xs={12}>
                    <Typography className={classes.title}>Select Students</Typography>
                </Grid> */}
        <Grid item xs={12}>
          <StudentSearchForm
            grade={grade}
            setGrade={setGrade}
            handleApplyFees={handleApplyFees}
            isBtnLoading={isBtnLoading}
            setIsBtnLoading={setIsBtnLoading}
            section={section}
            setSection={setSection}
          />
        </Grid>

        <Grid item xs={12}>
          <div style={{ height: '450px', overflow: 'auto' }}>
            <TableContainer
              hasCheckbox
              handleSelectAllClicked={handleSelectAllClick}
              rowCount={tableData?.length}
              numSelected={selected.length}
              loading={false}
              items={grade && tableData}
              headCells={headCells}
              getCustomTableRow={getCustomTableRow}
              hiddenCols={[-1]}
              disablePagination
              disablePrint
              disableSearch
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StudentSelectTable;
