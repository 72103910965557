import {
  Box,
  Button,
  Chip,
  createStyles,
  Divider,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { ArrowBack, CloudUpload } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import BraftEditor from 'braft-editor';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setSnackbar } from '../../../../../actions/SnackbarAction';
import { AddLessonPlan } from '../../../../../actions/TeacherDash/LessonPlan/LessonPlanAction';
import {
  GetSubUnit,
  GetSyllabus,
} from '../../../../../actions/TeacherDash/Syllabus/SyllabusAction';
import { RootStore } from '../../../../../store';
import CustomizedNepaliDatePicker from '../../../../../components/Reusable/Inputs/NepaliDatePicker';
import CustomizedTextField from '../../../../../components/Reusable/Inputs/TextField';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../../../../components/utils/dateConverter';
import firstWordCapital from '../../../../../components/utils/firstWordCapital';
import { CUR_NEPALI_DATE } from '../../../../../components/utils/nepaliDateUtils';
import {
  filterStyle,
  tableStyle,
} from '../../../components/Styles/TeacherDashboardStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // position: "fixed",
      '& .MuiListSubheader-sticky': {
        position: 'sticky',
        top: '20px',
      },
      '& .Mui-selected': {
        backgroundColor: 'rgba(19, 46, 152, 0.8)',
        color: '#fff !important',
        '& span': {
          color: '#fff !important',
        },
        '& p': {
          color: '#fff !important',
        },
        '&:hover': {
          backgroundColor: 'rgba(19, 46, 152, 0.8)',
          color: '#fff',
        },
      },
      backgroundColor: theme.palette.background.paper,
      '& .MuiTypography-body1': {
        fontSize: '13px',
        textAlign: 'left',
      },
    },
  })
);

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

const usePreventLeave = () => {
  const listener = (event: any) => {
    event.preventDefault();
    event.returnValue = 'You cannot leave';
  };

  const enablePrevent = () => window.addEventListener('beforeunload', listener);
  const disablePrevent = () =>
    window.removeEventListener('beforeunload', listener);

  return { enablePrevent, disablePrevent };
};

const LessonPlanCreate = () => {
  const classes = useStyles();
  const localClass = useFormStyles();
  const location = useLocation<any>();
  const dispatch = useDispatch();
  const history = useHistory();

  const { enablePrevent, disablePrevent } = usePreventLeave();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    setError,
    clearErrors,
  } = useForm({ mode: 'onChange' });

  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [lessonData, setLessonData] = useState<any>([]);

  const [unitChoice, setUnitChoice] = useState<any | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<any | null>(null);

  const [subUnitChoice, setSubUnitChoice] = useState<any | null>(null);
  const [selectedSubUnit, setSelectedSubUnit] = useState<any | null>(null);

  const [subUnitDisabler, setsubUnitDisabler] = useState<boolean>(true);

  const [editorState, setEditorState] = useState<any>();
  const [startDate, setStartDate] = useState<string>(CUR_NEPALI_DATE);
  const [endDate, setEndDate] = useState<string>(CUR_NEPALI_DATE);
  const [dueDate, setDueDate] = useState<string>(CUR_NEPALI_DATE);

  const [linkChipText, setLinkChipText] = useState('');
  const [chipData, setChipData] = React.useState<any>([]);

  const [enoteFiles, setEnoteFiles] = useState<any>([]);
  const [enoteDocs, setEnoteDocs] = useState<any[]>([]);
  const [url, setUrl] = useState<string>('');

  const [serverErrors, setServerErrors] = useState<any>(null);

  //*-----------------------------------REDUX MANAGEMENT ------------------------------------*//

  const unitSelector = useSelector(
    (state: RootStore) => state.teacher_syllabus
  );
  const LessonPlanSelector = useSelector(
    (state: RootStore) => state.teacher_lessonPlan
  );
  const errorsData = LessonPlanSelector.error;

  const recentSelector = useSelector(
    (state: RootStore) => state.teacher_lessonPlan.recent
  );

  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    const items = LessonPlanSelector.lesson_plan;
    setLessonData(items);
  }, [LessonPlanSelector]);

  useEffect(() => {
    if (initialErrorsData.current == errorsData) {
      initialErrorsData.current = errorsData;
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (errors) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [errors]);

  useEffect(() => {
    location?.state?.classID?.id &&
      location.state?.subID &&
      dispatch(
        GetSyllabus(location.state?.classID?.id, location.state?.subID1, 1, 100)
      );
    const n = window.location.href.lastIndexOf('/');
    setUrl(window.location.href.substring(n + 1));
  }, []);

  useEffect(() => {
    const subUnit = unitSelector?.sub_unit;
    setSubUnitChoice(subUnit);
  }, [unitSelector?.sub_unit]);

  useEffect(() => {
    if (unitSelector?.add_unit != null) {
      const unit = unitSelector.add_unit;
      setUnitChoice(unit);
    }
  }, [unitSelector?.add_unit]);

  const handleListItemClick = (event: any, index: number, item: any) => {
    setSelectedIndex(index);
    // setTempSubject(item.subject);
  };

  const handleUnitChange = (value: any | null) => {
    setSelectedUnit(value);
    setSelectedSubUnit(null);

    if (value != null) {
      dispatch(GetSubUnit(value.id));
      setsubUnitDisabler(false);
    }
  };

  const handleSubUnitChange = (value: any | null) => {
    setSelectedSubUnit(value);
  };

  //*-------------------------------------LINKS ADDING---------------------------*//
  const handleDelete = (chipToDelete: any) => {
    setChipData((chips: any) =>
      chips.filter((chip: any) => chip.key !== chipToDelete.key)
    );
  };

  const handleLinkChipChange = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const linkVal: string = event.target.value;

      const checkStart = () => {
        if (linkVal.startsWith('http://') || linkVal.startsWith('https://')) {
          return true;
        }
        return false;
      };

      if (checkStart()) {
        setChipData([
          ...chipData,
          { key: chipData.length + 1, label: event.target.value },
        ]);
        setLinkChipText('');
      } else {
        dispatch(
          setSnackbar(
            true,
            'warning',
            `invalid url, eg. "http://www.google.com"`
          )
        );
      }
    }
  };
  // const handleLinkChipChange = (event: any) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();

  //     setChipData([
  //       ...chipData,
  //       { key: chipData.length + 1, label: event.target.value },
  //     ]);
  //     setLinkChipText("");
  //   }
  // };

  //*-------------------------------------UPLOAD FILE---------------------------*//
  const handleEnoteFileUpload = (event: any) => {
    const filesArray = Array.from(event.target.files);
    setEnoteFiles([...enoteFiles, ...filesArray]);
  };

  const handleFileDelete = (file: any) => {
    const files = enoteFiles
      ? Array.from(enoteFiles).filter((item: any) => item.name !== file.name)
      : null;
    setEnoteFiles(files);
  };

  useEffect(() => {
    const arr: any[] = [];
    enoteFiles.map((data: File) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(data);

      fileReader.onload = () => {
        arr.push({ title: data.name, document: fileReader.result as string });
      };
    });
    setEnoteDocs(arr);
    enoteFiles.length && clearErrors('files');
  }, [enoteFiles]);

  const onSubmit = (data: any) => {
    const links = chipData.map((element: any) => element.label);
    const editorData = editorState.toHTML();
    const new_data = {
      name: data.title,
      start_date: dateConverterBsToAd(startDate),
      end_date: dateConverterBsToAd(endDate),
      due_date: dateConverterBsToAd(dueDate),
      unit: selectedUnit.id,
      sub_unit: selectedSubUnit ? selectedSubUnit.id : null,
      reference_links: links,
      description: editorData,
      lesson_plan_document: enoteDocs,
    };
    dispatch(AddLessonPlan(new_data));
  };

  const handleStartDate = (value: any) => {
    setStartDate(value);
    clearErrors('start_date');
    clearErrors('error');
  };
  const handleEndDate = (value: any) => {
    setEndDate(value);
    clearErrors('end_date');
    clearErrors('error');
  };
  const handleDueDate = (value: any) => {
    setDueDate(value);
    clearErrors('due_date');
    clearErrors('error');
  };

  if (recentSelector) {
    history.push('/lesson-plan');
  }

  const handleEditor = (data: any) => {
    setEditorState(data);
  };

  console.log(location.state);

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <Paper style={filterStyle}>
            <Button onClick={() => history.goBack()} color="primary">
              <ArrowBack fontSize="small" />
              Back
            </Button>
            <div className={classes.root}>
              <ListSubheader
                style={{
                  padding: '10px',
                  // background: "#4aa96c",
                }}
              >
                <Grid container direction="column">
                  <Typography color="textSecondary" variant="h6">
                    Class {location.state?.classID?.grade_name} (
                    {location.state?.classID?.section_name})
                  </Typography>
                  <Typography variant="subtitle1">
                    Subject = {location.state?.subID?.name}
                  </Typography>
                  <Divider style={{ marginTop: '5px' }} />
                  {/* <Chip
                    size="medium"
                    variant="outlined"
                    label={`Subject = ${location.state.subject}`}
                    color="secondary"
                  /> */}
                </Grid>
              </ListSubheader>
              {lessonData &&
                lessonData.map((item: any, index: number) => {
                  return (
                    <List key={index} component="ul" aria-label="subject list">
                      <ListItemLink
                        selected={selectedIndex === index}
                        onClick={(event) =>
                          handleListItemClick(event, index, item)
                        }
                      >
                        <ListItemText
                          primary={item.start_date}
                          secondary={item.name}
                          primaryTypographyProps={{
                            style: {
                              color: '#696969',
                            },
                            component: 'span',
                          }}
                        />
                        {/* <Chip
                          size="small"
                          variant="outlined"
                          label={item.start_date}
                          color="primary"
                        /> */}
                      </ListItemLink>
                    </List>
                  );
                })}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper style={tableStyle}>
            <form className={localClass.form} onSubmit={handleSubmit(onSubmit)}>
              {serverErrors &&
                serverErrors?.error &&
                Object.keys(serverErrors.error)
                  .filter((elem: any) => elem == 'error')
                  .map((elem: any, index: number) => {
                    return (
                      <div
                        className={localClass.serversidemessages}
                        key={index}
                      >
                        <Alert severity="error">
                          {firstWordCapital(errorsData?.error[elem][0])}
                        </Alert>
                      </div>
                    );
                  })}
              <Typography variant="h6" style={{ marginBottom: '12px' }}>
                Create Lesson Plan
              </Typography>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={4}
                  className={localClass.formWrapper}
                  // style={inputStyle}
                >
                  <InputLabel>Select Unit</InputLabel>
                  <Autocomplete
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: any | null
                    ) => handleUnitChange(value)}
                    options={unitChoice}
                    value={selectedUnit}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="--select--"
                        name="unit"
                        helperText={errors?.unit ? 'Unit must be selected' : ''}
                        inputRef={register({ required: true })}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  className={localClass.formWrapper}
                  // style={inputStyle}
                >
                  <InputLabel>Select Sub Unit</InputLabel>
                  <Autocomplete
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: any | null
                    ) => handleSubUnitChange(value)}
                    options={subUnitChoice}
                    value={selectedSubUnit}
                    disabled={subUnitDisabler}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="--select--"
                        name="sub_unit"
                        // helperText={
                        //   errors?.sub_unit ? "Sub Unit must be selected" : ""
                        // }
                        // inputRef={register({ required: true })}
                        inputRef={register}
                        variant="outlined"
                      />
                    )}
                  />
                  <span className={localClass.validationErrorInfo}>
                    {errors.sub_unit?.type === 'serverSideError' &&
                      errors.sub_unit.message}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  className={localClass.formWrapper}
                  // style={inputStyle}
                >
                  <CustomizedTextField
                    label="Title"
                    placeholder="Label"
                    name="title"
                    error={errors['title'] ? 'Title is required' : ''}
                    required
                    inputRef={register({ required: true })}
                    onChange={enablePrevent}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <InputLabel>Description</InputLabel>

                <div
                  style={{
                    marginTop: '10px',
                    marginBottom: '24px',
                    border: '1px solid #ccc',
                  }}
                >
                  <BraftEditor
                    language="en"
                    value={editorState}
                    onChange={(data) => handleEditor(data)}
                  />
                </div>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <CustomizedNepaliDatePicker
                    label="Start Date"
                    value={startDate}
                    name="start_date"
                    onChange={handleStartDate}
                    // setValue={setStartDate}
                    required
                  />
                  <span className={localClass.validationErrorInfo}>
                    {errors.class_date?.type === 'required' &&
                      errors.class_date.message}
                  </span>
                  <span className={localClass.validationErrorInfo}>
                    {errors.class_date?.type === 'serverSideError' &&
                      errors.class_date.message}
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <CustomizedNepaliDatePicker
                    label="End Date"
                    value={endDate}
                    name="end_date"
                    onChange={handleEndDate}
                    // setValue={setEndDate}
                    required
                  />
                  <span className={localClass.validationErrorInfo}>
                    {errors.class_date?.type === 'required' &&
                      errors.class_date.message}
                  </span>
                  <span className={localClass.validationErrorInfo}>
                    {errors.class_date?.type === 'serverSideError' &&
                      errors.class_date.message}
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <CustomizedNepaliDatePicker
                    label="Due Date"
                    value={dueDate}
                    name="due_date"
                    onChange={handleDueDate}
                    // setValue={setDueDate}
                    required
                  />
                  <span className={localClass.validationErrorInfo}>
                    {errors.class_date?.type === 'required' &&
                      errors.class_date.message}
                  </span>
                  <span className={localClass.validationErrorInfo}>
                    {errors.class_date?.type === 'serverSideError' &&
                      errors.class_date.message}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  className={localClass.formWrapper}
                  // style={inputStyle}
                >
                  <CustomizedTextField
                    label="Add useful links *(optional)"
                    placeholder={
                      chipData.length >= 3
                        ? 'Only 3 links are allowed'
                        : 'Label'
                    }
                    name="useful_links"
                    type="url"
                    value={linkChipText}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLinkChipText(event.target.value);
                    }}
                    disabled={chipData.length >= 3}
                    onKeyDown={handleLinkChipChange}
                  />
                </Grid>

                {chipData.map((data: any) => {
                  return (
                    <Chip
                      key={data.key}
                      label={data.label}
                      onDelete={() => handleDelete(data)}
                      className={localClass.chip}
                      size="small"
                      style={{
                        marginTop: '-8px',
                        marginBottom: '24px',
                        marginLeft: '16px',
                      }}
                    />
                  );
                })}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} className={localClass.formWrapper}>
                  <InputLabel>Add enote files</InputLabel>
                  <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    style={{
                      marginBottom: '16px',
                      marginTop: '8px',
                      minWidth: '200px',
                    }}
                    startIcon={<CloudUpload />}
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      multiple
                      onChange={handleEnoteFileUpload}
                    />
                  </Button>
                </Grid>
                {enoteFiles &&
                  Array.from(enoteFiles).map((data: any, index: number) => {
                    return (
                      <div key={index}>
                        <Chip
                          label={data.name}
                          onDelete={() => handleFileDelete(data)}
                          className={localClass.chip}
                          size="small"
                          style={{
                            marginTop: '-8px',
                            marginBottom: '24px',
                            marginLeft: '16px',
                          }}
                        />
                      </div>
                    );
                  })}
              </Grid>
              <Grid container justifyContent="flex-end">
                <div onClick={disablePrevent}>
                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default LessonPlanCreate;
