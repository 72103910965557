import { Box } from "@material-ui/core";
import StudentLeaveSearch from "./StudentLeaveSearch";
import StudentLeavesTable from "./StudentLeavesTable";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";

const StudentLeavePage = () => {
  return (
    <Box>
      <PageHeader title="Student Leave Requests" />

      <PageWrapper>
        <StudentLeaveSearch />
        <StudentLeavesTable />
      </PageWrapper>
    </Box>
  );
};

export default StudentLeavePage;
