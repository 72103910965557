import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_ENOTES_CATEGORIES_LOADING,
  GET_TEACHER_ENOTES_CATEGORIES_ERROR,
  GET_TEACHER_ENOTES_CATEGORIES_SUCCESS,
  GET_TEACHER_ENOTES_LOADING,
  GET_TEACHER_ENOTES_ERROR,
  GET_TEACHER_ENOTES_SUCCESS,
  CREATE_TEACHER_ENOTES_LOADING,
  CREATE_TEACHER_ENOTES_ERROR,
  CREATE_TEACHER_ENOTES_SUCCESS,
  TeacherEnotesCategoriesDispatchTypes,
} from "./TeacherEnotesActionTypes";

import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";

export const GetTeacherEnotesCategories =
  () => async (dispatch: Dispatch<TeacherEnotesCategoriesDispatchTypes>) => {
    try {
      dispatch({
        type: GET_TEACHER_ENOTES_CATEGORIES_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/enote_category/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHER_ENOTES_CATEGORIES_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_ENOTES_CATEGORIES_ERROR,
      });
    }
  };

export const GetTeacherEnotes =
  (grade: string, subject: string, category: string) =>
  async (dispatch: Dispatch<TeacherEnotesCategoriesDispatchTypes>) => {
    try {
      dispatch({
        type: GET_TEACHER_ENOTES_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/e_note/?grade=${grade}&subject=${subject}&category=${category}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHER_ENOTES_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_ENOTES_ERROR,
      });
    }
  };

export const CreateTeacherEnotes =
  (data: any) =>
  async (
    dispatch: Dispatch<TeacherEnotesCategoriesDispatchTypes | SetSnackBarI>
  ) => {
    try {
      dispatch({
        type: CREATE_TEACHER_ENOTES_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/e_note/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: CREATE_TEACHER_ENOTES_SUCCESS,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Enote Created Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: CREATE_TEACHER_ENOTES_ERROR,
        payload: error?.response?.data,
      });
    }
  };
