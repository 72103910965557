export const GET_PARENT_EVENT_LOADING = "GET_PARENT_EVENT_LOADING";
export const GET_PARENT_EVENT_ERROR = "GET_PARENT_EVENT_ERROR";
export const GET_PARENT_EVENT_SUCCESS = "GET_PARENT_EVENT_SUCCESS";

//===============================<START>GET STUDENT_HOUSE<START>======================//

export interface ParentEventTypeI {
  id: number;
  event_type: number;
  event_type_name: string;
  title: string;
  location: string;
  from_date: string;
  to_date: string;
  time: string;
  is_holiday: boolean;
  academic_session: number;
  academic_session_name: string;
  description: string;
}

export interface GetParentEventLoading {
  type: typeof GET_PARENT_EVENT_LOADING;
}

export interface GetParentEventError {
  type: typeof GET_PARENT_EVENT_ERROR;
}

export interface GetParentEventSuccess {
  type: typeof GET_PARENT_EVENT_SUCCESS;
  payload: ParentEventTypeI[];
}
//===============================<END>GET parent_event<END>======================//

//===============================<END>DELETE parent_event<END>======================//

export type ParentEventDispatchTypes =
  | GetParentEventLoading
  | GetParentEventError
  | GetParentEventSuccess;
