import {
  GET_LIVE_CLASS_LOADING,
  GET_LIVE_CLASS_ERROR,
  GET_LIVE_CLASS_SUCCESS,
  LiveClassDispatchTypes,
  ParentLiveClassTypeI,
} from "../../actions/ParentsDash/ParentLiveClass/ParentLiveClassActionTypes";

interface InitialStateI {
  loading: boolean;
  parent_liveClass: ParentLiveClassTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  parent_liveClass: [],
};

const ParentLiveClassReducer = (
  state: InitialStateI = initialState,
  action: LiveClassDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_LIVE_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_LIVE_CLASS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_LIVE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        parent_liveClass: action.payload,
      };

    default:
      return state;
  }
};

export default ParentLiveClassReducer;
