export const GET_STUDENT_MY_COMPLAIN_LOADING =
  "GET_STUDENT_MY_COMPLAIN_LOADING";
export const GET_STUDENT_MY_COMPLAIN_ERROR = "GET_STUDENT_MY_COMPLAIN_ERROR";
export const GET_STUDENT_MY_COMPLAIN_SUCCESS =
  "GET_STUDENT_MY_COMPLAIN_SUCCESS";

export const ADD_STUDENT_MY_COMPLAIN_LOADING =
  "ADD_STUDENT_MY_COMPLAIN_LOADING";
export const ADD_STUDENT_MY_COMPLAIN_ERROR = "ADD_STUDENT_MY_COMPLAIN_ERROR";
export const ADD_STUDENT_MY_COMPLAIN_SUCCESS =
  "ADD_STUDENT_MY_COMPLAIN_SUCCESS";

export const UPDATE_STUDENT_MY_COMPLAIN_LOADING =
  "UPDATE_STUDENT_MY_COMPLAIN_LOADING";
export const UPDATE_STUDENT_MY_COMPLAIN_ERROR =
  "UPDATE_STUDENT_MY_COMPLAIN_ERROR";
export const UPDATE_STUDENT_MY_COMPLAIN_SUCCESS =
  "UPDATE_STUDENT_MY_COMPLAIN_SUCCESS";

export const DELETE_STUDENT_MY_COMPLAIN_LOADING =
  "DELETE_STUDENT_MY_COMPLAIN_LOADING";
export const DELETE_STUDENT_MY_COMPLAIN_ERROR =
  "DELETE_STUDENT_MY_COMPLAIN_ERROR";
export const DELETE_STUDENT_MY_COMPLAIN_SUCCESS =
  "DELETE_STUDENT_MY_COMPLAIN_SUCCESS";

export interface StudentMyComplainTypeI {
  id: number;
  leave_type: number;
  status: string;
  status_name: string;
  leave_name: string;
  leave_from: string;
  leave_to: string;
  applied_date: string;
  general_info: number;
  reason_for_leave: string;
  no_of_days: string;
  first_name: string;
  last_name: string;
  student_academic: string | number;
}
export interface StudentMyComplainII {
  leave_type: number;
  leave_from: string;
  leave_to: string;
  reason_for_leave: string;
  student_academic: number;
}
//===============================<START>GET student_leave<START>======================//

export interface GetStudentMyComplainLoading {
  type: typeof GET_STUDENT_MY_COMPLAIN_LOADING;
}

export interface GetStudentMyComplainError {
  type: typeof GET_STUDENT_MY_COMPLAIN_ERROR;
}

export interface GetStudentMyComplainSuccess {
  type: typeof GET_STUDENT_MY_COMPLAIN_SUCCESS;
  payload: StudentMyComplainTypeI[];
}

//===============================<END>GET student_leave<END>======================//

//===============================<START>ADD student_leave<START>======================//

export interface AddStudentMyComplainLoading {
  type: typeof ADD_STUDENT_MY_COMPLAIN_LOADING;
}

export interface AddStudentMyComplainError {
  type: typeof ADD_STUDENT_MY_COMPLAIN_ERROR;
  payload: any;
}

export interface AddStudentMyComplainSuccess {
  type: typeof ADD_STUDENT_MY_COMPLAIN_SUCCESS;
  payload: StudentMyComplainTypeI;
}

//===============================<END>Add student_leave<END>======================//

//===============================<START>UPDATE STUDENT_MY_COMPLAIN<START>======================//

export interface UpdateStudentMyComplainLoading {
  type: typeof UPDATE_STUDENT_MY_COMPLAIN_LOADING;
}

export interface UpdateStudentMyComplainError {
  type: typeof UPDATE_STUDENT_MY_COMPLAIN_ERROR;
  payload: any;
}

export interface UpdateStudentMyComplainSuccess {
  type: typeof UPDATE_STUDENT_MY_COMPLAIN_SUCCESS;
  payload: StudentMyComplainTypeI;
}

//===============================<END>UPDATE STUDENT_MY_COMPLAIN<END>======================//

//===============================<START>DELETE STUDENT_MY_COMPLAIN<START>======================//

export interface DeleteStudentMyComplainLoading {
  type: typeof DELETE_STUDENT_MY_COMPLAIN_LOADING;
}

export interface DeleteStudentMyComplainError {
  type: typeof DELETE_STUDENT_MY_COMPLAIN_ERROR;
}

export interface DeleteStudentMyComplainSuccess {
  type: typeof DELETE_STUDENT_MY_COMPLAIN_SUCCESS;
  payload: { id: number };
}

//===============================<END>DELETE STUDENT_MY_COMPLAIN<END>======================//

export type StudentMyComplainDispatchTypes =
  | GetStudentMyComplainLoading
  | GetStudentMyComplainError
  | GetStudentMyComplainSuccess
  | AddStudentMyComplainLoading
  | AddStudentMyComplainError
  | AddStudentMyComplainSuccess
  | UpdateStudentMyComplainLoading
  | UpdateStudentMyComplainError
  | UpdateStudentMyComplainSuccess
  | DeleteStudentMyComplainLoading
  | DeleteStudentMyComplainError
  | DeleteStudentMyComplainSuccess;
