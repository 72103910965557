import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_CLASS_LOADING,
  GET_TEACHER_CLASS_ERROR,
  GET_TEACHER_CLASS_SUCCESS,
  GET_TEACHER_SECTION_LOADING,
  GET_TEACHER_SECTION_ERROR,
  GET_TEACHER_SECTION_SUCCESS,
  TeacherClassDispatchTypes,
} from "./TeacherClassActionTypes";

import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetTeacherClass =
  () => async (dispatch: Dispatch<TeacherClassDispatchTypes>) => {
    try {
      dispatch({
        type: GET_TEACHER_CLASS_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/my/grades/?unique_grade=true`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHER_CLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_CLASS_ERROR,
      });
    }
  };

export const GetTeacherClassSection =
  () => async (dispatch: Dispatch<TeacherClassDispatchTypes>) => {
    try {
      dispatch({
        type: GET_TEACHER_SECTION_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/my/grades/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHER_SECTION_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      // dispatch({
      //   type: GET_TEACHER_SECTION_ERROR,
      //   payload: error.response?.data,
      // });
    }
  };
