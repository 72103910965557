import React from "react";
import { FullSubModuleLayout } from "../../../../components/Reusable/Layouts/SubModuleLayout";
import ParentsDashSidebar from "../../../../components/ParentsDash/ParentsDashSidebar";
import ViewChildBar from "./Components/ViewChildBar";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import { Box } from "@material-ui/core";

const ViewChildPage = () => {
  return (
    <Box>
      <PageHeader title="My Children" />
      <PageWrapper>
        <ViewChildBar />
      </PageWrapper>
    </Box>
  );
};

export default ViewChildPage;
