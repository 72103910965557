import React from "react";
import { FullSubModuleLayout } from "../../../../components/Reusable/Layouts/SubModuleLayout";
import ParentsDashSidebar from "../../../../components/ParentsDash/ParentsDashSidebar";
import { Box } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import ChildrenListBar from "../LiveClasses/Components/ChildrenListBar";

const ParentLessonPlanPage = () => {
  const nav = <ParentsDashSidebar LessonPlan />;

  return (
    <Box>
      <PageHeader title="Children Lesson Plan" />

      <PageWrapper>
        <ChildrenListBar lessonPlanList />
      </PageWrapper>
    </Box>
  );
};

export default ParentLessonPlanPage;
