import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_EVENT_LOADING,
  GET_STUDENT_EVENT_ERROR,
  GET_STUDENT_EVENT_SUCCESS,
  StudentEventDispatchTypes,
} from "./StudentEventActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { STUDENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetStudentEvents = () => async (
  dispatch: Dispatch<StudentEventDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_STUDENT_EVENT_LOADING,
    });

    const res = await axios.get(`${STUDENT_API_URL}/event/`, HeaderConfig());

    dispatch({
      type: GET_STUDENT_EVENT_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_EVENT_ERROR,
    });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     snackbarMessage: "Events Loading Failed",
    //   },
    // });
  }
};
