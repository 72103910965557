import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import StaffForm from "../../../../components/HumanResource/Staff/StaffForm";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import { tblStyle } from "../../ParentDashboardStyles";
import CardfullWidthLayout from "../../../../components/Reusable/Layouts/CardfullWidthLayout";
import getFullName from "../../../../components/utils/getFullName";
import { RootStore } from "../../../../store";
import ParentTabs from "./ParentTabs";
import { GetGuardianProfile } from '../../../../actions/ParentsDash/ParentProfile/ParentProfileAction'

const ParentProfile = () => {
    const dispatch = useDispatch();
    const [guardian, setGuardian] = useState<any>([]);
    const guardianID = useSelector((state: RootStore) => state.auth.user?.id);

    useEffect(() => {
        if (guardianID != null) {
            dispatch(GetGuardianProfile());
        }
    }, [guardianID]);

    const guardianState = useSelector((state: RootStore) => state.parent_profile.guardian_profile_by_id);

    useEffect(() => {
        setGuardian(guardianState)
    }, [guardianState])

    const headData = [
        { id: 2, headCells: "Address:", cellData: guardian?.address },
        {
            id: 3,
            headCells: "Occupation:",
            cellData: guardian?.occupation,
        },
    ];

    return (
        <Box>
            <PageHeader title="My Profile" />
            <PageWrapper>
                <CardfullWidthLayout
                    fullName={getFullName(
                        guardian?.guardian_user?.first_name,
                        guardian?.guardian_user?.last_name
                    )}
                    headData={headData}
                    profileImg={guardian?.photo}
                >
                    <ParentTabs guardian={guardian} />
                </CardfullWidthLayout>
            </PageWrapper>
        </Box>
    );
}

export default ParentProfile