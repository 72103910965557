export const tblStyle = {
  boxShadow: "rgb(0 0 0 / 6%) 1px 1px 18px 1px",
  marginLeft: 0,
  marginTop: 20,
  borderRadius: 8,
  padding: "24px 16px",
  borderBottom: "none",
};

export const filterStyle = {
  ...tblStyle,
  marginBottom: 24,
  borderBottom: "none",
};

export const tableStyle = {
  boxShadow: "rgb(0 0 0 / 6%) 1px 1px 18px 1px",
  marginTop: 20,
  borderRadius: 8,
  padding: "24px 16px",
  borderBottom: "none",
};

export const newFilterStyle = {
  ...tableStyle,
  marginBottom: 24,
  borderBottom: "none",
};
