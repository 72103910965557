import React, { useState } from "react";
import { useSidebar } from "../../Reusable";
import { useSidebarStyles } from "../../Styles/SidebarStyles";
import { MenuBookOutlined } from "@material-ui/icons";
import SidebarTitle from "../../Reusable/Titles/SidebarTitle";

interface propsI {
  createExam?: boolean | undefined;
  examSchedules?: boolean | undefined;
  subjectClass?: boolean | undefined;
  optionalSubjects?: boolean | undefined;
  assignOptSubjects?: boolean | undefined;
  addMarks?: boolean | undefined;
  grades?: boolean | undefined;
  results?: boolean | undefined;
  ape?: boolean | undefined;
  ledger?: boolean | undefined;
}

const ExaminationSidebar = (props: propsI) => {
  const classes = useSidebarStyles();
  const [createExam] = useState(props.createExam);
  const [examSchedules] = useState(props.examSchedules);
  const [subjectClass] = useState(props.subjectClass);
  const [optionalSubjects] = useState(props.optionalSubjects);
  const [assignOptSubjects] = useState(props.assignOptSubjects);
  const [addMarks] = useState(props.addMarks);
  const [grades] = useState(props.grades);
  const [results] = useState(props.results);
  const [ape] = useState(props.ape);
  const [ledger] = useState(props.ledger);

  const dataSet = [
    { text: "Create Exam", url: "examination", activeStatus: createExam },
    {
      text: "Exam Schedules",
      url: "exam-schedule",
      activeStatus: examSchedules,
    },
    { text: "Subject Class", url: "subject-class", activeStatus: subjectClass },
    {
      text: "Optional Subjects",
      url: "opt-subjects",
      activeStatus: optionalSubjects,
    },
    {
      text: "Assign Optional Subjects",
      url: "assign-opt-subjects",
      activeStatus: assignOptSubjects,
    },
    { text: "Add Marks", url: "add-marks", activeStatus: addMarks },
    { text: "Grades", url: "grades", activeStatus: grades },
    { text: "APE Factor", url: "ape-factor", activeStatus: ape },
    // { text: "Results", url: "results", activeStatus: results },
    { text: "Ledger", url: "ledger", activeStatus: ledger },
  ];
  const { CustomSidebar } = useSidebar(dataSet, "FINANCE");
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<MenuBookOutlined />}>Examination</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default ExaminationSidebar;
