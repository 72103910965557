import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import { GetStudentTimeTable, GetStudentPeriods } from '../../../actions/StudentDash/StudentTimeTable/StudentTimeTableAction';
import { StudentTimeTable, Period } from '../../../actions/StudentDash/StudentTimeTable/StudentTimeTableActionTypes';
import { GetChildrenTimeTable, GetChildrenPeriod } from '../../../actions/ParentsDash/ChildrenTimeTable/ChildrenTimeTableAction'
import { RootStore } from "../../../store";

const PreviewContainer = styled.div`
  overflow: scroll;
`;

const Row = styled.div`
  &:nth-of-type(odd) {
    background-color: #3d3d3d15;
  }
  min-height: 80px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
`;

const IndexCell = styled.div`
  width: 100px;
  margin: 2px;
  text-align: center;
`;

const DataCell = styled.div`
  width: 150px;
  margin: 2px;
`;

const PeriodDetail = styled.div`
  text-align: center;
  background-color: #a3dabc;
  border-radius: 10px;
  padding: 5px;

  & > h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0;
  }

  & > p {
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0;
  }
`;

const SubjectDetail = styled.div`
  text-align: center;
  padding: 10px 5px;
  background-color: #d2e7dc;
  border-radius: 10px;

  & > h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0;
  }

  & > p {
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0;
  }
`;

const ReusableTimetable = (props: any) => {
    const dispatch = useDispatch();
    const { academicID, role } = props;
    const [periods, setPeriods] = useState<Period[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [timeTable, setTimeTable] = useState<StudentTimeTable[]>([])
    const { loading: loadingStudent, timeTable: timeTableStudent, periods: periodsStudent } = useSelector((state: RootStore) => state.student_timetable)
    // const { classPeriods } = useSelector((state: RootStore) => state.period)
    const { loading: loadingParent, timeTable: timeTableParent, periods: periodsParent } = useSelector((state: RootStore) => state.children_timetable)

    console.log("periods parent", periodsParent)

    useEffect(() => {
        if (role === "Student") {
            dispatch(GetStudentTimeTable())
            dispatch(GetStudentPeriods());
        }
    }, [])

    useEffect(() => {
        if (academicID) {
            dispatch(GetChildrenTimeTable(academicID))
            dispatch(GetChildrenPeriod(academicID))
        }
    }, [academicID])

    useEffect(() => {
        if (role === "Student") {
            setTimeTable(timeTableStudent.timetable)
            setLoading(loadingStudent)
            setPeriods(periodsStudent.periods)
        }
    }, [timeTableStudent, periodsStudent])

    useEffect(() => {
        if (role === "Parent") {
            setTimeTable(timeTableParent.timetable)
            setLoading(loadingParent)
            setPeriods(periodsParent?.[0]?.periods)
        }
    }, [timeTableParent, periodsParent])



    console.log(loading, timeTable)

    // useEffect(() => {
    //     const tt = timeTable.timetable
    //     if (tt && classPeriods) {
    //         setTimetable(tt)
    //         const uniquePeriods: any = classPeriods.filter((item) =>
    //             item.grade.id === timeTable.grade.id
    //         )
    //         console.log(uniquePeriods)
    //         setPeriods(uniquePeriods?.[0]?.periods)
    //     }
    // }, [timeTable, classPeriods])

    const returnPeriodDetail = (
        period: string,
        startTime: string,
        endTime: string
    ) => {
        return (
            <PeriodDetail>
                <h3>{period}</h3>
                <p>
                    {startTime} - {endTime}
                </p>
            </PeriodDetail>
        );
    };

    const returnSubjectDetail = (day: number, periodId: string) => {
        if (timeTable) {
            const byDay = timeTable.filter((item: StudentTimeTable) => item.day === day);
            if (byDay) {
                const pd = byDay.filter((item: any) => item.period_info.id === periodId);
                console.log("inside", byDay, pd)
                if (pd) {
                    return (
                        <SubjectDetail>
                            <h3>{pd?.[0]?.subject.name || "--"}</h3>
                            <p>
                                {pd?.[0]?.teacher?.first_name
                                    ? `${pd?.[0]?.teacher?.first_name} ${pd?.[0]?.teacher?.last_name}`
                                    : "--"}
                            </p>
                        </SubjectDetail>
                    );
                }
            }
        }
        return (
            <SubjectDetail>
                <h3>--</h3>
                <p>--</p>
            </SubjectDetail>
        );
    };

    const scheduleDays = [
        { id: 7, name: "Sunday" },
        { id: 1, name: "Monday" },
        { id: 2, name: "Tuesday" },
        { id: 3, name: "Wednesday" },
        { id: 4, name: "Thursday" },
        { id: 5, name: "Friday" },
    ];

    console.log("outside", periods, timeTable)



    return (
        <>
            {loading && (
                <LinearProgress style={{ margin: "-24px -24px 24px -24px" }} />
            )}
            <PreviewContainer>
                <Row>
                    <IndexCell>Days</IndexCell>
                    {periods?.map((item: any) => (
                        <DataCell key={item.id}>
                            {returnPeriodDetail(
                                item.name,
                                item.start_time,
                                item.end_time
                            )}
                        </DataCell>
                    ))}
                </Row>

                {scheduleDays.map((day) => {
                    return (
                        <Row key={day.id}>
                            <IndexCell>{day.name}</IndexCell>
                            {periods?.map((item: any) => {
                                return (
                                    <DataCell key={item.id}>
                                        {returnSubjectDetail(day.id, item.id)}
                                    </DataCell>
                                );
                            })}
                        </Row>
                    );
                })}
            </PreviewContainer>
        </>
    )
}

export default ReusableTimetable