import {
  Button,
  IconButton,
  InputBase,
  InputLabel,
  Tooltip,
  Grid,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  AddIncomeDetails,
  AddIncomeType,
  GetIncomeType,
  UpdateIncomeDetails,
  UpdateIncomeType,
} from "../../../actions/Fees/Income/IncomeAction";
import { AddIncomeTypeI } from "../../../actions/Fees/Income/IncomeActionTypes";
import { RootStore } from "../../../store";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import { useUploadStyles } from "../../../dashboard/TeacherDashboard/pages/AssignmentDetails/AssignmentForm";
import { CloudUpload } from "@material-ui/icons";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { useRef } from "react";

interface PropsI {
  editData: AddIncomeTypeI | null;
  onEditCheck: (value: boolean) => void;
}

interface SelectIncomeType {
  key: string;
  value: string;
}

const AddIncomeTestForm = (props: PropsI) => {
  const { editData, onEditCheck } = props;
  const dispatch = useDispatch();
  const uploadClasses = useUploadStyles();

  //States Declaration===============//
  const [incomeType, setIncomeType] = useState<any>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [docName, setDocName] = useState<string | null>("");
  const [document1Name, setDocument1Name] = useState<any | null>(null);
  const [date, setDate] = useState<string>("");
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const incomeLoading = useSelector(
    (state: RootStore) => state.income_type.loading
  );
  const add_or_update = useSelector((state: RootStore) => state.income_type.add_or_update)

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])
  useEffect(() => {
    dispatch(GetIncomeType());
  }, []);

  const AddIncomeSelector = useSelector(
    (state: RootStore) => state.income_type.income_type
  );

  const { register, handleSubmit, setValue, setError, reset, errors } =
    useForm<AddIncomeTypeI>({
      mode: "onChange",
    });

  const INCOME_TYPE_CHOICES = AddIncomeSelector.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  //===================================<START>CYCLE HOOKS<START>===========================//

  useEffect(() => {
    setLoading(incomeLoading);
  }, [incomeLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  //===================================<END>CYCLE HOOKS<END>===========================//

  const handleFileUpload = ({ target }: any) => {
    setSelectedFile(target.files[0]);
    // setDocName(target[0].name);
  };

  const handleEditTableData = (data: AddIncomeTypeI) => {
    const documentName = data.document
      ? data.document.split("/").pop()
      : "Browse";
    setEditMode(true);
    setEditID(data.id);

    setValue("name", data.name);
    setValue("invoice", data.invoice);
    setValue("amount", data.amount);
    setValue("description", data.description);
    data.document && setSelectedFile(data.document);
    setDocName(documentName);
    setIncomeType(
      INCOME_TYPE_CHOICES.find((item) => item.key == data.name) ||
      INCOME_TYPE_CHOICES[0]
    );
    setDate(dateConverterAdToBs(data.income_date));
  };

  const onFormSubmit = (data: AddIncomeTypeI) => {
    setIsBtnLoading(true);
    if (incomeType) {
      const formData = new FormData();
      selectedFile
        ? !selectedFile[5] && formData.append("document", selectedFile)
        : null;
      !selectedFile && formData.append("document", "");

      const incomeValue = incomeType?.value;
      formData.append("income_type_name", incomeValue);
      formData.append("income_type", incomeType?.key);
      formData.append("income_date", dateConverterBsToAd(date));
      formData.append("name", data.name);
      formData.append("amount", data.amount);
      formData.append("invoice", data.invoice);
      formData.append("description", data.description);

      if (editMode && editID) {
        dispatch(UpdateIncomeDetails(editID, formData));
      } else {
        dispatch(AddIncomeDetails(formData));
      }
    }
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    setIncomeType(null);
    setSelectedFile(null);
    setEditID("");
    onEditCheck(false);
  };

  //----------------------ERROR HANDLING---------------------------//
  const [serverErrors, setServerErrors] = useState<any>(null);
  const incomeSelector = useSelector((state: RootStore) => state.income_type);
  const errorsData = incomeSelector.errors;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: "serverSideError",
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (incomeSelector.recent) {
      handleReset();
    }
  }, [incomeSelector.recent]);

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Income" : "Add Income"}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedSelect
          label="Income Type"
          name="income_type"
          required
          options={INCOME_TYPE_CHOICES}
          value={incomeType}
          setValue={setIncomeType}
          error={errors["income_type"] ? "Income Type must be selected" : ""}
          inputRef={register({ required: true })}
        />
        <CustomizedTextField
          label="Name"
          placeholder="Name"
          name="name"
          required
          error={errors["name"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <CustomizedTextField
          label="Invoice"
          placeholder="Invoice No."
          name="invoice"
          error={errors["invoice"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <CustomizedNepaliDatePicker
          label="Income Date"
          value={date}
          setValue={setDate}
        />
        <CustomizedTextField
          label="Amount"
          placeholder="Amount"
          name="amount"
          error={errors["amount"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <Grid container>
          <Grid item xs={12}>
            <InputLabel>Choose file</InputLabel>

            <input
              className={uploadClasses.input}
              id="faceImage"
              type="file"
              onChange={handleFileUpload}
            />
            <Tooltip title="Select File">
              <label htmlFor="faceImage">
                <IconButton
                  className={uploadClasses.faceImage}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloudUpload fontSize="large" />
                </IconButton>
              </label>
            </Tooltip>
            <label>
              {selectedFile != null || undefined
                ? !selectedFile[5]
                  ? selectedFile.name
                  : selectedFile.substring(selectedFile.lastIndexOf("/") + 1)
                : "Select File"}
            </label>
          </Grid>
        </Grid>

        <CustomizedTextField
          label="Description"
          placeholder="Description"
          name="description"
          required
          multiline
          rows={4}
          error={errors["description"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
      </FormLayout>
    </>
  );
};

export default AddIncomeTestForm;
