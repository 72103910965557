export const GET_STUDENT_EVENT_LOADING = "GET_STUDENT_EVENT_LOADING";
export const GET_STUDENT_EVENT_ERROR = "GET_STUDENT_EVENT_ERROR";
export const GET_STUDENT_EVENT_SUCCESS = "GET_STUDENT_EVENT_SUCCESS";

export interface StudentEventTypeI {
  id: number;
  event_type: number;
  event_type_name: string;
  title: string;
  location: string;
  from_date: string;
  to_date: string;
  time: string;
  is_holiday: boolean;
  academic_session: number;
  academic_session_name: string;
  description: string;
}

//===============================<START>GET parent_event<START>======================//

export interface GetStudentEventLoading {
  type: typeof GET_STUDENT_EVENT_LOADING;
}

export interface GetStudentEventError {
  type: typeof GET_STUDENT_EVENT_ERROR;
}

export interface GetStudentEventSuccess {
  type: typeof GET_STUDENT_EVENT_SUCCESS;
  payload: StudentEventTypeI[];
}

//===============================<END>GET parent_event<END>======================//

export type StudentEventDispatchTypes =
  | GetStudentEventLoading
  | GetStudentEventError
  | GetStudentEventSuccess;
