//---------------------<START> import modules starts-----------------------//
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import React, { useState, useEffect } from "react";
import { useTable } from "../../../../Reusable";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { TimeTableTypeI } from "../../../../../actions/Academics/TimeTable/TimeTableActionTypes";
import { SubjectTypeI } from "../../../../../actions/Academics/Subject/SubjectActionTypes";
import { RootStore } from "../../../../../store";
import {
  DeleteTimeTable,
  DeleteTimeTableByDays,
  GetTimeTablesByDay,
  UpdateTimeTable,
} from "../../../../../actions/Academics/TimeTable/TimeTableAction";
import { setSnackbar } from "../../../../../actions/SnackbarAction";
import { StaffTypeI } from "../../../../../actions/HumanResource/Staff/StaffActionTypes";
import { GetAllTeachers } from "../../../../../actions/HumanResource/Staff/StaffAction";
import { GetSubjectGroupsByClass } from "../../../../../actions/Academics/Subject/SubjectGroupAction";
import SelectDaysDialog from "./SelectDaysDialog";
import { DaysI } from "./SelectDaysDialog";
import { v4 as uuidv4 } from "uuid";
import { GetPreTimeTables } from "../../../../../actions/Academics/PreTimeTable/PreTimeTableAction";
import { timeConvertor } from "../../../../../dashboard/StudentDashboard/pages/MyTimetable/StudentTimeTableView";
import Popups from "../../../../Reusable/Dialogs/Popups";
import { StaffForm } from "../../../..";
import { Cancel, Edit } from "@material-ui/icons";
import {
  PeriodsI,
  resetPeriodStateAction,
} from "../../../../../rtk/features/timetable/periodSlices";
import { updateTimetableByDayAction } from "../../../../../rtk/features/timetable/timetableSlices";
import { getTimePeriodByGrade } from "../../../../../rtk/features/timetable/periodApi";
import { GetSubjects } from "../../../../../actions/Academics/Subject/SubjectAction";
import BackDropLoader from "../../../../Reusable/BackDropLoader";
import {
  copyTimeTable,
  getTimeTableByDay,
  updateTimeTable,
} from "../../../../../rtk/features/timetable/timetableApi";
//---------------------<END> import modules ends -----------------------//

//= ====================== <START> Styles <START> ===================================//

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containedButton: {
      width: "90%",
      margin: theme.spacing(2, 0),
    },
    deleteIcon: {
      color: "#E96C5A",
    },
    tableSection: {
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.5, 2),
      },
      "& .MuiAutocomplete-inputRoot": {
        padding: "2px",
      },
      "& .MuiFormControl-fullWidth": {
        width: "256px",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

//======================= <END> Styles <END> ===================================//
//-------------------<START> interface starts-----------------------------------------------//

interface PropsI {
  classChoice: {
    gradeId: string | null;
    section: string | null;
    classId: string | null;
    hasSection: boolean;
  };
  tab_day: number;
}

interface HeadCellsI {
  id: string;
  label: string;
}

// -----------------<END> interface ends --------------------------------------//

// ---------------------<START> Table Heading Data starts ----------------------------//
const headCells: HeadCellsI[] = [
  { id: "period", label: "Period" },
  { id: "subject", label: "Subject" },
  { id: "teacher", label: "Teacher" },
  { id: "start_time", label: "Start Time" },
  { id: "end_time", label: "End Time" },
  { id: "action", label: "Action" },
];
//----------------------<END> Table Heading Data ends ---------------------------------//
//------------------------<START> Sunday Component starts ------------------------------//
const Sunday = (props: PropsI) => {
  const { classChoice, tab_day } = props;
  const classes = useStyles();
  //Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    TableContainer,
  } = useTable(headCells);

  //======================= <START> Component States <START> ===================================//

  const [daysDialog, setDaysDialog] = useState<boolean>(false);

  const { subjectGroupByClass } = useSelector(
    (state: RootStore) => state.subject_group
  );
  const { all_teachers } = useSelector((state: RootStore) => state.staff);

  const teacherLoading = useSelector((state: RootStore) => state.staff.loading);
  const subjectLoading = useSelector(
    (state: RootStore) => state.subject.loading
  );

  const [subjects, setSubjects] = useState<SubjectTypeI[]>([]);
  //======================= <END> Component States <END> ===================================//
  //======================= <START> REDUX HOOKS <START> ===================================//
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllTeachers(""));
  }, []);

  useEffect(() => {
    if (classChoice.gradeId) {
      dispatch(
        GetSubjectGroupsByClass(classChoice.gradeId, classChoice.section)
      );
    }
  }, [classChoice]);

  useEffect(() => {
    if (subjectGroupByClass) {
      setSubjects(subjectGroupByClass.subject);
    } else {
      setSubjects([]);
    }
  }, [subjectGroupByClass]);

  // ======= Component States =========== //
  const [tableEdit, setTableEdit] = useState<boolean>(false);
  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const { periodsByClass } = useSelector((state: RootStore) => state.period);
  const { timeTableByDayInit, timeTableByDay, actionPerformed, loading } =
    useSelector((state: RootStore) => state.timetable);
  /////////////////////////////////////////

  // ============== Hooks =============== //
  useEffect(() => {
    if (actionPerformed) {
      setTableEdit(false);
      setCheckedRows([]);
      setDaysDialog(false);
      if (classChoice.gradeId) {
        dispatch(
          getTimeTableByDay({
            grade_id: classChoice.gradeId,
            section_id: classChoice.section,
            day: tab_day,
          })
        );
      }
    }
  }, [actionPerformed]);

  useEffect(() => {
    if (classChoice.gradeId) {
      if (classChoice.hasSection) {
        if (classChoice.section) {
          dispatch(getTimePeriodByGrade(classChoice.gradeId));
          dispatch(
            getTimeTableByDay({
              grade_id: classChoice.gradeId,
              section_id: classChoice.section,
              day: tab_day,
            })
          );
        } else {
          dispatch(resetPeriodStateAction());
        }
      } else {
        dispatch(getTimePeriodByGrade(classChoice.gradeId));
        dispatch(
          getTimeTableByDay({
            grade_id: classChoice.gradeId,
            section_id: classChoice.section,
            day: tab_day,
          })
        );
      }
    } else {
      dispatch(resetPeriodStateAction());
    }

    setTableEdit(false);
    setCheckedRows([]);
  }, [classChoice, tab_day]);

  useEffect(() => {
    if (periodsByClass) {
      periodsByClass.periods.length === checkedRows.length
        ? setAllChecked(true)
        : setAllChecked(false);
    } else {
      setAllChecked(false);
    }
  }, [checkedRows, periodsByClass]);
  //////////////////////////////////////////

  const returnTeacherData = (id: string) => {
    const teacherFirstName =
      timeTableByDay?.timetable.find((item) => item.period.id === id)?.teacher
        .first_name || "";

    const teacherLastName =
      timeTableByDay?.timetable.find((item) => item.period.id === id)?.teacher
        .last_name || "";

    const teacherId =
      timeTableByDay?.timetable.find((item) => item.period.id === id)?.teacher
        .id || null;

    return { teacherFirstName, teacherLastName, teacherId };
  };

  const returnSubjectData = (id: string) => {
    const subjectName =
      timeTableByDay?.timetable.find((item) => item.period.id === id)?.subject
        .name || "";

    const subjectId =
      timeTableByDay?.timetable.find((item) => item.period.id === id)?.subject
        .id || null;

    return { subjectName, subjectId };
  };
  // ======= Event Handler ============== //

  const handleRowCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedRows([...checkedRows, event.target.value]);
    } else {
      const newCheckedRows = checkedRows.filter(
        (item) => item !== event.target.value
      );
      setCheckedRows([...newCheckedRows]);
    }
  };

  const handleRowCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allRows = periodsByClass?.periods.map((item) => item.id);
      allRows && setCheckedRows([...allRows]);
    } else {
      setCheckedRows([]);
    }
  };

  const handleChangeInputs = (
    period: PeriodsI,
    field: "teacher" | "subject",
    subject_value: { id: string; name: string } | null,
    teacher_value: { id: string; first_name: string; last_name: string } | null
  ) => {
    if (classChoice.gradeId) {
      dispatch(
        updateTimetableByDayAction({
          grade: classChoice.gradeId,
          section: classChoice.section,
          day: tab_day,
          period,
          field,
          subject_value,
          teacher_value,
        })
      );
    }
  };

  const handleDiscardChanges = () => {
    setTableEdit(false);

    if (classChoice.gradeId) {
      dispatch(
        getTimeTableByDay({
          grade_id: classChoice.gradeId,
          section_id: classChoice.section,
          day: tab_day,
        })
      );
    }
  };

  const returnChanged = ({
    period,
    subject,
    staff,
  }: {
    period: string;
    subject: string | null;
    staff: string | null;
  }): boolean => {
    const counter = timeTableByDayInit?.timetable || null;
    if (counter) {
      const result = counter.find((el) => el.period.id === period);

      if (result) {
        const init_staff = result.teacher.id || "";
        const init_subject = result.subject.id || "";
        const staff_id = staff || "";
        const subject_id = subject || "";
        if (init_staff !== staff_id || init_subject !== subject_id) {
          return true;
        }
        return false;
      }
      return false;
    } else {
      if (subject && staff) {
        return true;
      }
      return false;
    }
  };

  const handleSaveChanges = () => {
    if (tableEdit) {
      if (classChoice.gradeId) {
        if (timeTableByDay) {
          const post_periods: {
            period: string;
            subject: string | null;
            staff: string | null;
          }[] = [];
          timeTableByDay.timetable.forEach((item) => {
            post_periods.push({
              period: item.period.id,
              subject: item.subject.id || null,
              staff: item.teacher.id || null,
            });
          });
          periodsByClass?.periods.forEach((el) => {
            if (!post_periods.map((it) => it.period).includes(el.id)) {
              post_periods.push({
                period: el.id,
                subject: null,
                staff: null,
              });
            }
          });

          const post_timetable: {
            period: string;
            subject: string | null;
            staff: string | null;
            changed: boolean;
          }[] = post_periods.map((el) => ({
            ...el,
            changed: returnChanged(el),
          }));
          dispatch(
            updateTimeTable({
              grade: classChoice.gradeId,
              section: classChoice.section,
              day: tab_day,
              periods: post_timetable,
            })
          );
        } else {
          dispatch(
            setSnackbar(true, "warning", "Cannot save empty timetable data")
          );
        }
      }
    } else {
      setTableEdit(true);
    }
  };

  const applyToDays = (val: DaysI[]) => {
    const daysToBeApplied = val.map((item) => item.id);
    if (daysToBeApplied.length && checkedRows.length) {
      if (classChoice.gradeId) {
        dispatch(
          copyTimeTable({
            grade_id: classChoice.gradeId,
            section_id: classChoice.section,
            from_day: tab_day,
            to_days: daysToBeApplied,
            periods: checkedRows,
          })
        );
      }
    }
  };
  //////////////////////////////////////////

  return (
    <div>
      <Grid container className={classes.tableSection}>
        <Grid container>
          <Grid container justifyContent="space-between">
            <Button
              onClick={() => {
                setDaysDialog(true);
              }}
              color="primary"
              variant="outlined"
              disabled={!Boolean(checkedRows.length)}
              startIcon={<CheckOutlinedIcon />}
            >
              Apply To
            </Button>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <StyledTableRow>
              {!tableEdit && (
                <StyledTableCell align="center">
                  <Checkbox
                    color="default"
                    onChange={handleRowCheckAll}
                    checked={allChecked}
                  />
                </StyledTableCell>
              )}
              <StyledTableCell align="center">Period</StyledTableCell>
              <StyledTableCell align="center">Subject</StyledTableCell>
              <StyledTableCell align="center">Teacher</StyledTableCell>
              <StyledTableCell align="center">Start Time</StyledTableCell>
              <StyledTableCell align="center">End Time</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {periodsByClass?.periods.map((item) => (
              <StyledTableRow key={item.id}>
                {!tableEdit && (
                  <StyledTableCell align="center">
                    <Checkbox
                      color="default"
                      checked={checkedRows.includes(item.id)}
                      value={item.id}
                      onChange={handleRowCheck}
                    />
                  </StyledTableCell>
                )}
                <StyledTableCell align="center">{item.name}</StyledTableCell>
                <StyledTableCell align="center">
                  {tableEdit ? (
                    <Autocomplete
                      value={
                        subjects.find(
                          (element) =>
                            element.id === returnSubjectData(item.id).subjectId
                        ) || null
                      }
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        value: { id: string; name: string } | null
                      ) => {
                        handleChangeInputs(item, "subject", value, null);
                      }}
                      options={subjects.map((item) => ({
                        id: item.id,
                        name: item.name,
                      }))}
                      getOptionLabel={(option) => option.name}
                      disabled={item.name.startsWith("Break")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Label"
                          name="period"
                          variant="outlined"
                        />
                      )}
                    />
                  ) : (
                    returnSubjectData(item.id).subjectName || "--"
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {tableEdit ? (
                    <Autocomplete
                      value={
                        all_teachers.find(
                          (element) =>
                            element.id === returnTeacherData(item.id).teacherId
                        ) || null
                      }
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        value: {
                          id: string;
                          first_name: string;
                          last_name: string;
                        } | null
                      ) => {
                        handleChangeInputs(item, "teacher", null, value);
                      }}
                      options={all_teachers}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name}`
                      }
                      disabled={item.name.startsWith("Break")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Label"
                          name="period"
                          variant="outlined"
                        />
                      )}
                    />
                  ) : (
                    returnTeacherData(item.id).teacherFirstName +
                    " " +
                    returnTeacherData(item.id).teacherLastName
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.start_time}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.end_time}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container justifyContent="flex-end">
          <Grid item xs={2}>
            <Grid container justifyContent="flex-end">
              <Button
                onClick={handleDiscardChanges}
                disabled={!tableEdit}
                className={classes.containedButton}
                color="primary"
                variant="contained"
                startIcon={<Cancel />}
                style={{ marginRight: "10px" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleSaveChanges}
                className={classes.containedButton}
                color="primary"
                variant="contained"
                startIcon={tableEdit ? <SaveIcon /> : <Edit />}
                disabled={!Boolean(periodsByClass?.periods.length)}
              >
                {tableEdit ? "Save" : "Edit"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SelectDaysDialog
        modalState={daysDialog}
        handleClose={setDaysDialog}
        currentDay={tab_day}
        handleApply={applyToDays}
      />
      <BackDropLoader open={subjectLoading || teacherLoading || loading} />
    </div>
  );
};
// ------------------------------<END> Sunday Components ends----------------------------------------------//
export default Sunday;
