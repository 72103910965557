import {
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { RootStore } from "../../../../store";
import { dateConverterBsToAd } from "../../../../components/utils/dateConverter";
import getDayName from "../../../../components/utils/getDayName";
import { AttendenceColorBox } from "../../../../components/Reusable";
import { MyAttendances } from "../../../../actions/StudentDash/MyAttendance/MyAttendanceAction";
import getFullName from "../../../../components/utils/getFullName";
import { tblStyle } from "../../components/Styles/TeacherDashboardStyles";

const AttendanceTable = (props: any) => {
  const classes = useTableStyles();
  const [dateCells, setDateCells] = useState<any | []>([]);

  const monthDates = useSelector((state: RootStore) => state.month_dates);
  const MyAttendance = useSelector(
    (state: RootStore) => state.teacher_class_attendance.attendances
  );

  const checkStatus = (k: number, date: string) => {
    const dateObject = new Date(date);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const _day = days[dateObject.getDay()];
    if (MyAttendance[k].attendance_detail != null) {
      const index: number = MyAttendance[k].attendance_detail.findIndex(
        (element: any) => element.date === date
      );

      if (_day != "Sat") {
        if (index >= 0) {
          return MyAttendance[k].attendance_detail[index].status;
        } else {
          return "x";
        }
      } else {
        return "holiday";
      }
    }
  };

  useEffect(() => {
    if (MyAttendance != null) {
      const monthData = monthDates.data.dates;
      if (monthData != null) {
        const prepared = [];

        for (let i = 0; i < MyAttendance.length; i++) {
          const __temp = [];
          for (let j = 0; j < monthData.length; j++) {
            const convertedDate = dateConverterBsToAd(monthData[j]);
            const checked = monthData[j].substring(8, 10);

            __temp.push({
              status: checkStatus(i, convertedDate),
              date: checked,
              day: getDayName(convertedDate),
            });
          }
          prepared.push({
            student_first_name: getFullName(
              MyAttendance[i].student_first_name,
              MyAttendance[i].student_last_name
            ),
            attendance_detail: __temp,
          });
        }
        setDateCells(prepared);
      }
    }
  }, [MyAttendance]);

  return (
    <>
      <Paper style={tblStyle}>
        {MyAttendance.length ? (
          <>
            <AttendenceColorBox
              monthName={props.monthName}
              childrenName={props.childrenName}
            />
            <div className={classes.tableWrapper}>
              <TableContainer>
                <Table
                  className={classes.table}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.cellDayName}>
                        <span>Student Name</span>
                      </TableCell>
                      {dateCells[0] &&
                        dateCells[0].attendance_detail.map((column: any) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            className={classes.newcell}
                          >
                            {column.date}
                          </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.cellDayName}
                      ></TableCell>
                      {dateCells[0] &&
                        dateCells[0].attendance_detail.map((column: any) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            className={classes.cellDayName}
                          >
                            <span key={column.id}>{column.day}</span>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dateCells.map((item: any, index: number) => {
                      return (
                        <TableRow key={index} hover tabIndex={-1}>
                          <TableCell
                            key={index}
                            align="center"
                            className={classes.newcell}
                            style={{ width: "200px" }}
                          >
                            {item.student_first_name}
                          </TableCell>
                          {item.attendance_detail.map(
                            (item: any, index: number) => {
                              return (
                                <TableCell
                                  key={index}
                                  align="center"
                                  className={classes.newcell}
                                  style={{
                                    backgroundColor:
                                      item.status == "P"
                                        ? "#51AB67"
                                        : item.status == "holiday"
                                        ? "#EA5E42"
                                        : item.status == "I"
                                        ? "#FEAF4A"
                                        : "#B9BCBE",
                                    width: "35px",
                                  }}
                                ></TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        ) : (
          "There is no data to show."
        )}
      </Paper>
    </>
  );
};

export default AttendanceTable;
