//-----------------<start> import module starts -----------------------//
import React, { useState, useEffect } from "react";
import { Box, Grid, InputLabel, Paper, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import { SearchButton } from "../../../../components";
import { GetTeachingClass } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassAction";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../store";
import { ClassTypeI } from "../../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../../actions/Academics/Section/SectionActionTypes";
import { GetMySubjects } from "../../../../actions/TeacherDash/MySubjects/MySubjectsAction";
import { SubmitButton } from "../../../../components/Reusable/Buttons/SearchButton";
import { InputBox } from "../../../../components/Reusable/Inputs/Select";
import { filterStyle } from "../../components/Styles/TeacherDashboardStyles";
import { GetTeacherSubjectByClass, GetTeachingSubject } from "../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectAction"

//-----------------<start> import module ends -----------------------//

//-----------------<start> Interfaces starts-----------------------//
interface FormI {
  gradeID: string | null;
  sectionID: string | null;
  grade: string;
  section: string;
}

interface SectionII {
  id: string;
  name: string;
}
interface ClassII {
  id: string;
  grade: string;
  grade_name: string;
  section: SectionII[] | null | string;
  section_name?: string;
}
// -----------------<end> Interfaces <end>-----------------------//

//-----------------<start> Search timetable component <start>-----------------------//
const MySubjectsSearch = (props: any) => {
  const classes = useFormStyles();

  //-----------------<START> States starts-----------------------//

  const [selectedClass, setSelectedClass] = useState<ClassII | null>(null);
  const [classChoices, setClassChoices] = useState<any>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [openPopup, setOpenPopup] = useState(false);
  //-----------------<END> States ends-----------------------//

  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.teaching_class);

  useEffect(() => {
    dispatch(GetTeachingClass());
  }, []);

  useEffect(() => {
    classChoices.length > 0 && handleClassChange(classChoices[0]);
  }, [classChoices]);

  useEffect(() => {
    const classes = classSelector.teaching_class;
    const finalArray: ClassII[] = [];
    classes.forEach((item: any) => {
      if (item.section === null) {
        finalArray.push(item);
      } else {
        for (let i = 0; i < item.section.length; i++) {
          const data1 = {
            ...item,
            section: item.section[i].id,
            section_name: item.section[i].name,
          }
          finalArray.push(data1);
        }
      }
    })
    setClassChoices(finalArray);
    setSelectedClass(finalArray[0]);
  }, [classSelector.teaching_class]);

  // useEffect(() => {
  //   classSelector.teaching_class &&
  //     setClassChoices(classSelector.teaching_class);
  // }, [classSelector]);

  const handleClassChange = (value: any | null) => {
    if (value != null) {
      const section = value.section ? value.section : ""
      setSelectedClass(value);
      dispatch(GetTeachingSubject(value.grade, section));
    }
  };

  console.log(classChoices)

  const { register, handleSubmit, errors, setValue } = useForm();
  return (
    <>
      <Paper style={filterStyle}>
        <form className={classes.form}>
          <Box className={classes.formTitle}>
            <span>Search Subject</span>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <InputBox>Class</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ClassII | null
                ) => handleClassChange(value)}
                options={classChoices}
                value={selectedClass}
                getOptionLabel={(option) => `Class ${option.grade_name} ${option.section_name !== undefined ? `(${option?.section_name})` : ""}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="class_name"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};
// -----------------<End> Search timetable ends ----------------------//

export default MySubjectsSearch;
