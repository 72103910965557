import { Paper } from "@material-ui/core";
import { VisibilityOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetStudentAnnouncements } from "../../../../actions/StudentDash/StudentAnnouncement/StudentAnnouncementAction";
import { StudentAnnouncementTypeI } from "../../../../actions/StudentDash/StudentAnnouncement/StudentAnnouncementActionTypes";
import { RootStore } from "../../../../store";
import ActionButton from "../../../../components/Reusable/Buttons/ActionButton";
import Popups from "../../../../components/Reusable/Dialogs/Popups";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { dateConverterAdToBs } from "../../../../components/utils/dateConverter";
import TeacherAnnouceModal from "../../../TeacherDashboard/pages/TeacherAnnouncement/ModalView/TeacherAnnouceModal";
import useTable from "../../../../components/Reusable/useStudentTable";
import { tblStyle } from "../../StudentDashboardStyles";

export interface DataI {
  data: any[];
  message_to: React.ReactNode;
}

interface HeadCellsI {
  id: string;
  label: string;
  filter?: boolean;
  align?: "left" | "right" | "center";
}

//---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  {
    id: "annoucement_title",
    label: "Annoucement Title",
    filter: true,
    align: "left",
  },
  { id: "notice_date", label: "Notice Date" },
  { id: "published_date", label: "Published Date" },
  { id: "action", label: "Action" },
];

const StudentAnnouncementTable = () => {
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<StudentAnnouncementTypeI[]>([]);

  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [item, setItem] = useState<any[] | null>(null);

  const dispatch = useDispatch();

  const studentAnnouncementSelector = useSelector(
    (state: RootStore) => state.student_announcement
  );

  useEffect(() => {
    dispatch(GetStudentAnnouncements());
  }, []);

  useEffect(() => {
    const data = studentAnnouncementSelector.announcements;
    setTableData(data);
  }, [studentAnnouncementSelector]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const handleViewModal = (item: any) => {
    setItem(item);
    setItemId(item.id);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.announcement_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.created_on)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ActionButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewModal(item)
            }
            title="View"
          >
            <VisibilityOutlined className={classes.viewIcon} />
          </ActionButton>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.rootTableBox} style={tblStyle}>
        <TableContainer
          search_name="title"
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          items={tableData}
          rowsPerPage={4}
          placeholder="Search Annoucements"
          label="Annoucements"
          loading={studentAnnouncementSelector.loading}
        />
      </Paper>

      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        fullWidth={true}
        maxWidth="sm"
        title="Announcement Detail"
        colored
      >
        <TeacherAnnouceModal item={item} />
      </Popups>
    </>
  );
};

export default StudentAnnouncementTable;
