export const GET_TEACHER_LEAVE_LOADING = 'GET_TEACHER_LEAVE_LOADING';
export const GET_TEACHER_LEAVE_ERROR = 'GET_TEACHER_LEAVE_ERROR';
export const GET_TEACHER_LEAVE_SUCCESS = 'GET_TEACHER_LEAVE_SUCCESS';

export const ADD_TEACHER_LEAVE_LOADING = 'ADD_TEACHER_LEAVE_LOADING';
export const ADD_TEACHER_LEAVE_ERROR = 'ADD_TEACHER_LEAVE_ERROR';
export const ADD_TEACHER_LEAVE_SUCCESS = 'ADD_TEACHER_LEAVE_SUCCESS';

export const UPDATE_TEACHER_LEAVE_LOADING = 'UPDATE_TEACHER_LEAVE_LOADING';
export const UPDATE_TEACHER_LEAVE_ERROR = 'UPDATE_TEACHER_LEAVE_ERROR';
export const UPDATE_TEACHER_LEAVE_SUCCESS = 'UPDATE_TEACHER_LEAVE_SUCCESS';

export const DELETE_TEACHER_LEAVE_LOADING = 'DELETE_TEACHER_LEAVE_LOADING';
export const DELETE_TEACHER_LEAVE_ERROR = 'DELETE_TEACHER_LEAVE_ERROR';
export const DELETE_TEACHER_LEAVE_SUCCESS = 'DELETE_TEACHER_LEAVE_SUCCESS';

//===============================<START>GET STUDENT_HOUSE<START>======================//

export type LeavePropsI = {
  id: number;
  leave_type: number;
  status: string;
  status_name: string;
  leave_from: string;
  leave_to: string;
  applied_date: string;
  general_info: number;
  reason_for_leave: string;
  leave_name: string;
  no_of_days: string;
  first_name: string;
  last_name: string;
};

export interface GetTeacherLeaveLoading {
  type: typeof GET_TEACHER_LEAVE_LOADING;
}

export interface GetTeacherLeaveError {
  type: typeof GET_TEACHER_LEAVE_ERROR;
}

export interface GetTeacherLeaveSuccess {
  type: typeof GET_TEACHER_LEAVE_SUCCESS;
  payload: LeavePropsI[];
}
//===============================<END>GET STUDENT_HOUSE<END>======================//
//===============================<START>ADD LEAVE<START>======================//

export interface AddTeacherLeaveLoading {
  type: typeof ADD_TEACHER_LEAVE_LOADING;
}

export interface AddTeacherLeaveError {
  type: typeof ADD_TEACHER_LEAVE_ERROR;
  payload: any;
}

export interface AddTeacherLeaveSuccess {
  type: typeof ADD_TEACHER_LEAVE_SUCCESS;
  payload: LeavePropsI;
}
//===============================<START>DELETE LEAVE<START>======================//

export interface DeleteTeacherLeaveLoading {
  type: typeof DELETE_TEACHER_LEAVE_LOADING;
}

export interface DeleteTeacherLeaveError {
  type: typeof DELETE_TEACHER_LEAVE_ERROR;
}

export interface DeleteTeacherLeaveSuccess {
  type: typeof DELETE_TEACHER_LEAVE_SUCCESS;
  payload: { id: number };
}

//===============================<END>ADD LEAVE<END>======================//

//===============================<END>DELETE STUDENT_HOUSE<END>======================//

//===============================<START>ADD LEAVE<START>======================//

export interface UpdateTeacherLeaveLoading {
  type: typeof UPDATE_TEACHER_LEAVE_LOADING;
}

export interface UpdateTeacherLeaveError {
  type: typeof UPDATE_TEACHER_LEAVE_ERROR;
  payload: any;
}

export interface UpdateTeacherLeaveSuccess {
  type: typeof UPDATE_TEACHER_LEAVE_SUCCESS;
  payload: any;
}

export type TeacherLeaveDispatchTypes =
  | GetTeacherLeaveLoading
  | GetTeacherLeaveError
  | GetTeacherLeaveSuccess
  | AddTeacherLeaveLoading
  | AddTeacherLeaveError
  | AddTeacherLeaveSuccess
  | DeleteTeacherLeaveLoading
  | DeleteTeacherLeaveError
  | DeleteTeacherLeaveSuccess
  | UpdateTeacherLeaveLoading
  | UpdateTeacherLeaveError
  | UpdateTeacherLeaveSuccess;
