// ---------------- <START> module import starts ------------------//
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useSidebar } from "../Reusable";
import { useSidebarStyles } from "../Styles/SidebarStyles";
// ---------------- <END> module import ends ------------------//

//---------------------------- <START> interface start ------------------------------//
interface propsI {
  generalInfo?: boolean | undefined;
  emailSetting?: boolean | undefined;
  SMSTemplate?: boolean | undefined;
  session?: boolean | undefined;
  roles?: boolean | undefined;
  managePassword?: boolean | undefined;
  prayer?: boolean | undefined;
  termsandconditions?: boolean | undefined;
  miscellaneous?: boolean | undefined;
}
//---------------------------- <END> interface ends ------------------------------//

// ---------------- <START> Setting Sidebar Component starts ------------------//
const Sidebar = (props: propsI) => {
  const classes = useSidebarStyles();
  const [generalInfo] = useState(props.generalInfo);
  const [emailSetting] = useState(props.emailSetting);
  const [SMSTemplate] = useState(props.SMSTemplate);
  const [session] = useState(props.session);
  const [roles] = useState(props.roles);
  const [managePassword] = useState(props.managePassword);
  const [prayer] = useState(props.prayer);
  const [termsandconditions] = useState(props.termsandconditions);
  const [miscellaneous] = useState(props.miscellaneous);

  const dataSet = [
    { text: "Session", url: "session", activeStatus: session },
    { text: "General Info", url: "general-setting", activeStatus: generalInfo },
    // { text: "Email Setting", url: "email-setting", activeStatus: emailSetting },
    { text: "SMS Config", url: "sms-template", activeStatus: SMSTemplate },
    { text: "Manage Roles", url: "roles", activeStatus: roles },
    // {
    //   text: "Manage Password",
    //   url: "manage-password",
    //   activeStatus: managePassword,
    // },
    { text: "Prayer", url: "prayer", activeStatus: prayer },
    {
      text: "Terms & Conditions",
      url: "termsandconditions",
      activeStatus: termsandconditions,
    },
    {
      text: "Miscellaneous",
      url: "miscellaneous",
      activeStatus: miscellaneous,
    },
  ];

  const { CustomSidebar } = useSidebar(dataSet, "FINANCE");

  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <div className={classes.sidebarTitle}>
          <Typography variant="h3">General Setting</Typography>
        </div>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default Sidebar;
// ---------------- <END> Setting Sidebar Component ends ------------------//
