import {
  GET_STUDENT_ACADEMIC_LOADING,
  GET_STUDENT_ACADEMIC_ERROR,
  GET_STUDENT_ACADEMIC_SUCCESS,
  GET_STUDENT_ACADEMIC_MARKS_LOADING,
  GET_STUDENT_ACADEMIC_MARKS_ERROR,
  GET_STUDENT_ACADEMIC_MARKS_SUCCESS,
  StudentAcademicInfoTypeI,
  StudentAcademicInfoDispatchTypes,
} from "../../actions/ParentsDash/AcademicInfo/StudentAcademicInfoActionTypes";

interface InitialStateI {
  loading: boolean;
  student_academics: StudentAcademicInfoTypeI[];
  exam_marks: any;
}

const initialState: InitialStateI = {
  loading: false,
  student_academics: [],
  exam_marks: [],
};

const StudentAcademicInfoReducer = (
  state: InitialStateI = initialState,
  action: StudentAcademicInfoDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STUDENT_ACADEMIC_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_STUDENT_ACADEMIC_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_STUDENT_ACADEMIC_SUCCESS:
      return {
        ...state,
        loading: false,
        student_academics: action.payload,
      };
    case GET_STUDENT_ACADEMIC_MARKS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_STUDENT_ACADEMIC_MARKS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_STUDENT_ACADEMIC_MARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        exam_marks: action.payload,
      };

    default:
      return state;
  }
};

export default StudentAcademicInfoReducer;
