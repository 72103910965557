import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { Box, Button, Divider, Grid } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { AlertTitle, Alert } from '@material-ui/lab';
import clsx from 'clsx';
import moment from 'moment';
import { DashboardSubmitAssignmentI } from '../../../../actions/TeacherDash/Dasbhoard/TeacherDashboardActionTypes';
import getFullName from '../../../../components/utils/getFullName';
import getFullClassName from '../../../../components/utils/getFullClassName';
import { Link } from 'react-router-dom';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import AssignmentView from '../../pages/SubmittedAssignment/SubmittedAssignmentView';
import { useState } from 'react';
import AssignmentViewCard from './AssignmentViewCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingTop: '0',
      paddingBottom: '8px',
    },
    title: {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    itemTitle: {
      fontWeight: 500,
      marginBottom: 5,
      display: 'inline-block',
    },
    alertTitle: {
      fontSize: 12,
      marginBottom: 2,
      fontWeight: 600,
    },
    bold: {
      fontWeight: 600,
    },
    alertContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    alertRoot: {
      width: '100%',
      color: 'rgba(0,0,0,.8)',
      borderRadius: 8,
      borderLeft: '8px solid #3fc581',
      background: 'rgb(72 197 129 / 10%)',
      '& > div': {
        width: '100%',
        padding: '4px 0',
      },
    },
    alertDanger: {
      borderLeft: '8px solid #e96c5a',
      background: 'rgb(233 108 90 / 0.1)',
    },
    alertWarning: {
      borderLeft: '8px solid #f2e82c',
      background: 'rgb(242 232 54 / 15%)',
    },
  })
);

interface PropsI {
  assignment: DashboardSubmitAssignmentI[];
}

export default function SubmittedAssignmentWidget(props: PropsI) {
  const classes = useStyles();
  const { assignment } = props;

  const [openPopup, setOpenPopup] = useState(false);
  const [assign, setAssign] = useState<any>(null);

  const myString = (data: string) => {
    // console.log("data", data.substring(0, 50));
    return data.substring(0, 15) + '...';
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleViewModal = (item: any) => {
    setAssign(item);
    setOpenPopup(true);
  };

  return (
    <List className={classes.root}>
      <ListItem dense button className={classes.title}>
        <ListItemText
          primary={
            <span
              style={{
                fontSize: '16px',
                fontWeight: 600,
                color: 'rgba(0,0,0,0.85)',
              }}
            >
              Submitted Assignments
            </span>
          }
        />
        <ListItemSecondaryAction>
          <Button color="primary">View All</Button>
        </ListItemSecondaryAction>
      </ListItem>

      <Box paddingLeft="16px" paddingRight="16px" paddingBottom="8px">
        <Divider />
      </Box>
      <List style={{ maxHeight: '240px', overflow: 'auto' }}>
        {assignment?.map((item) => {
          return (
            <ListItem key={item.id} role={undefined} dense button>
              <Alert
                icon={false}
                severity="success"
                className={clsx(
                  classes.alertRoot
                  // item.status === "warning" && classes.alertWarning,
                  // item.status === "danger" && classes.alertDanger
                )}
              >
                <Grid container justifyContent="space-between">
                  <Grid item className={classes.alertContent}>
                    <span className={classes.bold}>
                      {getFullName(
                        item.student_first_name,
                        item.student_last_name
                      )}
                    </span>
                    <span>{getFullClassName(item.grade, item.section)}</span>
                  </Grid>
                  <Grid
                    item
                    style={{ fontSize: 13 }}
                    className={classes.alertContent}
                  >
                    <span>Subject:</span>
                    {/* <span className={classes.bold}>{item.subject}</span> */}
                    <span className={classes.bold}>{item.subject}</span>
                  </Grid>
                  <Grid
                    item
                    style={{ fontSize: 13 }}
                    className={classes.alertContent}
                  >
                    <span>Topic:</span>
                    <span className={classes.bold}>{myString(item.title)}</span>
                  </Grid>
                  <Grid
                    item
                    style={{ fontSize: 13 }}
                    className={classes.alertContent}
                  >
                    <span>Deadline:</span>
                    <span className={classes.bold}>{item.deadline}</span>
                  </Grid>
                  <Button onClick={() => handleViewModal(item)} color="primary">
                    View
                  </Button>
                </Grid>
              </Alert>
            </ListItem>
          );
        })}
      </List>
      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        maxWidth="md"
        title="Assignment Details"
        colored
      >
        <AssignmentViewCard singleAssignment={assign} />
      </Popups>
    </List>
  );
}

const data = [
  {
    id: 1,
    student_name: 'Reuben Thapa',
    class: 'Two B',
    subject: 'Science',
    topic: 'A good measure of a student in helping',
    start: '2021-05-12',
    deadline: '2021-05-15',
    status: 'danger',
  },
  {
    id: 2,
    student_name: 'Manoz Acharya',
    class: 'Two B',
    subject: 'Science',
    topic: 'How to solve math',
    start: '2021-04-12',
    deadline: '2021-04-15',
    status: 'warning',
  },
  {
    id: 3,
    student_name: 'Aacis Maharjan',
    class: 'Two B',
    subject: 'Science',
    topic: 'A good measure of a student in helping',
    start: '2021-03-12',
    deadline: '2021-03-15',
    status: 'success',
  },
  {
    id: 4,
    student_name: 'Aacis Maharjan',
    class: 'Two B',
    subject: 'Science',
    topic: 'A good measure of a student in helping',
    start: '2021-03-12',
    deadline: '2021-03-15',
    status: 'success',
  },
  {
    id: 5,
    student_name: 'Aacis Maharjan',
    class: 'Two B',
    subject: 'Science',
    topic: 'A good measure of a student in helping',
    start: '2021-03-12',
    deadline: '2021-03-15',
    status: 'success',
  },
];
