/* eslint-disable react/prop-types */
import React from "react";
import KhaltiCheckout from "khalti-checkout-web";
import KhaltiConfig from "./KhaltiConfig";
import myKey from "./KhaltiKey";
// import config from "./KhaltiConfig";

export default function Khalti(props) {
  const { onPaymentConfirm, handleClose, amount } = props;

  const btnClicked = () => {
    handleClose()
    checkout.show({ amount: amount })
  }

  let config = {
    publicKey: myKey.publicTestKey,
    productIdentity: "123766",
    productName: "Hamro Acadmey",
    productUrl: "https://demo.hamro.academy",
    eventHandler: {
      onSuccess(payload) {
        
        console.log(payload);
        onPaymentConfirm(payload?.token);
      },
      onError(error) {
        console.log(error);
      },
      onClose() {
        console.log("widget is closing");
      },
    },
    paymentPreference: [
      "KHALTI"
    ],
  };

  // const tempConfig = <KhaltiConfig onPaymentConfirm={onPaymentConfirm} />;

  let checkout = new KhaltiCheckout(config);

  let buttonStyles = {
    backgroundColor: "purple",
    padding: "10px",
    color: "white",
    cursor: "pointer",
    fontWeight: "bold",
    borderRadius: "3px",
    border: "none",
  };
  return (
    <div>
      <button
        onClick={() => btnClicked()}
        style={buttonStyles}
      >
        {props.title || "Pay Via Khalti"}
      </button>
    </div>
  );
}
