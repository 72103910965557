import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  ListItem,
  ListItemText,
  makeStyles,
  List,
} from "@material-ui/core";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import cx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../store";
import { GetEnoteCategorys } from "../../../../actions/Academics/EnoteCategory/EnoteCategoryAction";
import { filterStyle } from "../../ParentDashboardStyles";

const useStyles = makeStyles({
  listSelected: {
    backgroundColor: "#d3cfcf",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: "#d3cfcf",
    },
  },
  listText: {
    "& .MuiListItemText-primary": {
      fontSize: "1rem",
    },
  },
  listItem: {
    borderBottom: "1px solid #ccc",
    paddingTop: "4px",
    paddingBottom: "4px",

    "&:last-child": {
      borderBottom: "0px solid #ccc",
    },
  },
});

interface PropsI {
  categoryNav: (val: CategoryNavI) => void;
}

interface CategoryNavI {
  id: string;
  name: string;
}

const ParentEnoteNav = (props: PropsI) => {
  const { categoryNav } = props;
  const classes = useFormStyles();
  const classes2 = useStyles();
  const [dataSet, setDataSet] = useState<any[]>([]);

  const dispatch = useDispatch();
  const enoteCategorySelector = useSelector(
    (state: RootStore) => state.enote_category
  );
  console.log(enoteCategorySelector)

  useEffect(() => {
    dispatch(GetEnoteCategorys());
  }, []);

  useEffect(() => {
    // console.log("enoteCategorySelector.enotes_categories", enoteCategorySelector.enotes_categories)
    const data: any[] = enoteCategorySelector.enote_categories.map(
      (element, index) => ({
        id: element.id,
        text: element.name,
        url: element.name,
        activeStatus: index === 0 ? true : false,
      })
    );
    // console.log("Data", data)

    if (data.length) {
      const val: CategoryNavI = {
        id: data[0].id,
        name: data[0].text,
      };
      categoryNav(val);
    }
    setDataSet(data);
  }, [enoteCategorySelector.enote_categories]);

  // console.log("dataSet", dataSet)

  const handleClick = (id: number) => {
    setDataSet(
      dataSet.map((item) => {
        if (item.id === id) {
          return { ...item, activeStatus: true };
        } else {
          return { ...item, activeStatus: false };
        }
      })
    );

    const search = dataSet.find((element) => element.id === id);
    if (search) {
      const val: CategoryNavI = {
        id: search.id,
        name: search.text,
      };

      categoryNav(val);
    }
  };

  return (
    <div>
      <Paper className={classes.pageContent} style={filterStyle}>
        <Box className={classes.formTitle}>
          <span>Choose by category</span>
        </Box>

        <List component="nav" aria-label="inner sidebar list">
          {dataSet.map((element: any, index: number) => (
            <ListItem
              button
              className={cx(
                classes2.listItem,
                element.activeStatus && classes2.listSelected
              )}
              key={index}
              onClick={() => handleClick(element.id)}
            >
              <ListItemText
                classes={{ root: classes2.listText }}
                primary={element.text}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </div>
  );
};

export default ParentEnoteNav;
