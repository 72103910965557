//---------------------------<START> import modules starts-----------------------//
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useFormStyles } from "../../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import "nepali-datepicker-reactjs/dist/index.css";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import { useDispatch, useSelector } from "react-redux";
import {
  AddAnnouncement,
  SaveAnnouncementDraft,
  UpdateAnnouncement,
} from "../../../../actions/Communication/Announcement/AnnouncementAction";
import { AnnouncementTypeI } from "../../../../actions/Communication/Announcement/AnnouncementActionTypes";
import { AnnouncementTypeI as AnnouncementCategoryI } from "../../../../actions/Communication/AnnouncementType/AnnouncementTypeActionTypes";
import { TabToFormI } from "../../../../pages/Communication/AddAnnoucModalPage";
import Popups from "../../../Reusable/Dialogs/Popups";
import ConfirmationModalView from "./ConfirmationModal";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../../utils/dateConverter";
import moment from "moment";
import {
  AddAnnouncementType,
  GetAnnouncementTypes,
} from "../../../../actions/Communication/AnnouncementType/AnnouncementTypeAction";
import { RootStore } from "../../../../store";
import BraftEditor from "braft-editor";
import styled from "styled-components";
import AnnouncementTarget from "./AnnouncementTarget";
import { Save, Search, Send } from "@material-ui/icons";
import { setSnackbar } from "../../../../actions/SnackbarAction";
import { useHistory } from "react-router-dom";

//-------------------------<END> import modules ends ----------------------------//

const StyledPaper = styled(Paper)`
  margin: 20px;
  padding: 25px 20px;
`;

//--------------------<START> interface starts------------------------------------------------//

interface SelectPriorityI {
  id: number;
  value: string;
  title: string;
}

export interface TabDataI {
  id: number;
  group: number[];
  user: number[];
  grade: number | null;
  section: number[];
}

interface FormDataI {
  announcement_category: string | null;
  title: string;
  announcement_date: string | null;
  announcement_time: string | null;
  description: string;
}

export interface PostDataI {
  priority: string;
  title: string;
  announcement_date: string;
  description: string;
  group: string[];
  user: string[];
  grade: string | null;
  section: string[];
  sent_to_parent: boolean;
  sent_to_student: boolean;
}

export interface ExtendedPostDataI {
  priority: string;
  title: string;
  announcement_date: string;
  description: string;
  group: string[];
  user: string[];
  grade: string | null;
  sent_to_parent: boolean;
  sent_to_student: boolean;
  gradeLabel: string;
  section: string[];
  sectionLabel: string[];
  groupLabel: string[];
  userLabel: string[];
}
//----------------<END> interface ends--------------------------------------------------//

const today = moment(new Date()).format("YYYY-MM-DD");

//--------------------------<START> Add Annoucement Modal components starts---------------------------------------------------//
const AnnoucementForm = () => {
  const classes = useFormStyles();
  const history = useHistory();

  const [announcementType, setAnnouncementType] =
    useState<AnnouncementCategoryI | null>(null);

  const [featuredImage, setFeaturedImage] = useState<File | null>(null);
  const [date, setDate] = useState<string | any>(dateConverterAdToBs(today));
  const [formData, setFormData] = useState<FormDataI>({
    announcement_category: null,
    title: "",
    announcement_date: dateConverterAdToBs(today),
    announcement_time: "10:00",
    description: "",
  });
  const [metaDesc, setMetaDesc] = useState<string>("");

  const [sendNow, setSendNow] = useState<boolean>(false);

  //-------------------<START> React hooks forms starts ----------------------//
  const dispatch = useDispatch();
  const { register, errors, setError, setValue, clearErrors } = useForm({
    mode: "onChange",
  });
  const [target, setTarget] = useState<any>(null);

  const announcementTypeSelector = useSelector(
    (state: RootStore) => state.announcement_type.announcement_types
  );
  const { actionPerformed, draftSaved } = useSelector(
    (state: RootStore) => state.announcement
  );

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFormChange = (
    field_name: string,
    field_value: any,
    priority_choice: SelectPriorityI | null,
    announcement_category: AnnouncementCategoryI | null
  ) => {
    if (field_name === "announcement_type") {
      announcement_category
        ? clearErrors("announcement_type")
        : setError("announcement_type", {
            type: "required",
            message: "This field is required",
          });
    }
    field_name === "announcement_type" &&
      setAnnouncementType(announcement_category);
    field_name === "announcement_type" &&
      setFormData({
        ...formData,
        announcement_category: announcement_category
          ? announcement_category.id
          : "",
      });
    field_name === "title" && setFormData({ ...formData, title: field_value });
    field_name === "time" &&
      setFormData({ ...formData, announcement_time: field_value });
    if (field_name === "description") {
      field_value
        ? clearErrors("description")
        : setError("description", {
            type: "required",
            message: "This field is required",
          });
    }
    field_name === "description" &&
      setFormData({ ...formData, description: field_value.toHTML() });
  };

  useEffect(() => {
    dispatch(GetAnnouncementTypes());
  }, []);

  useEffect(() => {
    if (actionPerformed) {
      history.push("/communication");
    }
  }, [actionPerformed]);

  useEffect(() => {
    if (draftSaved) {
      history.push("/announcement-draft");
    }
  }, [draftSaved]);

  useEffect(() => {
    if (date) {
      setFormData({
        ...formData,
        announcement_date: dateConverterBsToAd(date),
      });
      clearErrors("announcement_date");
    }
  }, [date]);

  const handleSend = async () => {
    let post_target = {
      user: [],
      group: [],
      user_group: null,
      grade: null,
      section: [],
      sent_to_parent: false,
      sent_to_student: false,
    };
    let valid = true;
    Object.keys(formData).forEach((item: string) => {
      if (formData[item] === "") {
        valid = false;
        setError(item, { type: "required", message: "This field is required" });
      }
    });

    if (target) {
      if (target.target === "groups") {
        if (target.data.length === 0) {
          valid = false;
          dispatch(setSnackbar(true, "warning", "Select groups"));
        } else {
          post_target = { ...post_target, group: target.data };
        }
      }
      if (target.target === "individuals") {
        if (target.data.users.length === 0) {
          valid = false;
          dispatch(setSnackbar(true, "warning", "Select users"));
        } else {
          post_target = {
            ...post_target,
            user_group: target.data.user_group,
            user: target.data.users,
          };
        }
      }

      if (target.target === "classes") {
        if (target.data.grade === null) {
          valid = false;
          dispatch(setSnackbar(true, "warning", "Select class"));
        } else {
          if (
            target.data.sendTo.parent === false &&
            target.data.sendTo.student === false
          ) {
            valid = false;
            dispatch(
              setSnackbar(
                true,
                "warning",
                "Check targeted groups for the class"
              )
            );
          } else {
            post_target = {
              ...post_target,
              grade: target.data.grade,
              section: target.data.section,
              sent_to_parent: target.data.sendTo.parent,
              sent_to_student: target.data.sendTo.student,
            };
          }
        }
      }
    } else {
      valid = false;
      dispatch(setSnackbar(true, "warning", "Select groups, users or class"));
    }

    if (valid) {
      const post_data: any = {
        ...formData,
        ...post_target,
        meta_description: metaDesc,
      };

      if (featuredImage) {
        try {
          const file = await toBase64(featuredImage);
          post_data.featured_image = file;
        } catch (error) {
          post_data.featured_image = null;
        }
      }
      dispatch(AddAnnouncement(post_data));
    }
  };

  const handleSendNow = () => {
    setSendNow(!sendNow);

    if (sendNow) {
      setFormData({
        ...formData,
        announcement_date: today,
        announcement_time: "10:00",
      });
    } else {
      setFormData({
        ...formData,
        announcement_date: null,
        announcement_time: null,
      });
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFeaturedImage(e.target.files ? e.target.files[0] : null);
  };

  //------------------------<END> React Hooks form ends -------------------------//
  return (
    <>
      <AnnouncementTarget setTarget={(data) => setTarget(data)} />
      <StyledPaper>
        <form className={classes.form}>
          <Grid container>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>
                Announcement Category <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                value={announcementType}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: AnnouncementCategoryI | null
                ) => handleFormChange("announcement_type", "", null, value)}
                options={announcementTypeSelector}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Normal"
                    variant="outlined"
                  />
                )}
              />
              <span className={classes.validationErrorInfo}>
                {errors.announcement_type && errors.announcement_type.message}
              </span>
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={6} className={classes.formWrapper}>
              <InputLabel>
                Title <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                name="title"
                placeholder="Title"
                autoComplete="off"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleFormChange("title", event.target.value, null, null);
                }}
                inputRef={register({ required: "This field is required" })}
              />
              <span className={classes.validationErrorInfo}>
                {errors.title && errors.title.message}
              </span>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} className={classes.formWrapper}>
              <InputLabel>Meta Description</InputLabel>
              <TextField
                variant="outlined"
                multiline
                rows={3}
                name="metaDescription"
                placeholder="Meta Description"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setMetaDesc(event.target.value);
                }}
                value={metaDesc}
              />
              <span className={classes.validationErrorInfo}>
                {errors.title && errors.title.message}
              </span>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} className={classes.formWrapper}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value={sendNow}
                    onChange={handleSendNow}
                  />
                }
                label={<InputLabel>Send now</InputLabel>}
                labelPlacement="start"
                style={{ marginLeft: "0px", flexDirection: "row" }}
              />
            </Grid>
            <Grid item xs={6} />
            {!sendNow && (
              <Grid container spacing={1} item xs={12}>
                <Grid item xs={4} className={classes.formWrapper}>
                  <InputLabel>
                    Publish on <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <NepaliDatePicker
                    className={classes.nepaliDateInput}
                    onChange={(data: string) => setDate(data)}
                    value={date}
                    options={{ calenderLocale: "en", valueLocale: "en" }}
                    inputClassName="inputFormControl"
                  />
                  <span className={classes.validationErrorInfo}>
                    {errors.announcement_date &&
                      errors.announcement_date.message}
                  </span>
                </Grid>

                <Grid item xs={4} className={classes.formWrapper}>
                  <InputLabel>
                    Time <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth={true}
                    type="time"
                    name="announcement_time"
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleFormChange("time", event.target.value, null, null);
                    }}
                    value={formData.announcement_time}
                    inputRef={register({ required: "This field is required" })}
                  />
                  <span className={classes.validationErrorInfo}>
                    {errors.announcement_time &&
                      errors.announcement_time.message}
                  </span>
                </Grid>
              </Grid>
            )}

            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel style={{ marginBottom: "6px" }}>
                Featured Image
              </InputLabel>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </Grid>

            <Grid item xs={10} className={classes.formWrapper}>
              <InputLabel>
                Description <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "24px",
                  border: "1px solid #ccc",
                }}
              >
                <BraftEditor
                  language="en"
                  // value={editorState}
                  onChange={(data: any) => {
                    handleFormChange("description", data, null, null);
                  }}
                />
                <span className={classes.validationErrorInfo}>
                  {errors.description && errors.description.message}
                </span>
              </div>
            </Grid>

            <Grid item xs={12}>
              {/* <Button
                color="primary"
                variant="contained"
                startIcon={<Save />}
                onClick={() => handleSaveDraft()}
                style={{ margin: "10px 10px 0 0" }}
              >
                Save Draft
              </Button> */}

              <Button
                color="primary"
                variant="contained"
                startIcon={<Send />}
                onClick={() => handleSend()}
                style={{ marginTop: "10px" }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </form>
      </StyledPaper>
    </>
  );
};
//---------------------------<END> Add Annoucement Modal components end ---------------------------//
export default AnnoucementForm;
