// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import 'nepali-datepicker-reactjs/dist/index.css';
import EnoteCard from './StudentEnotesCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { DeleteSmsTemplate } from '../../../../actions/Communication/SmsTemplate/SmsTemplateAction';
import { Autocomplete } from '@material-ui/lab';
import { TeacherClassSectionI } from '../../../../actions/TeacherDash/TeacherClass/TeacherClassActionTypes';
import { TeacherSubjectsI } from '../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes';
import { GetStudentSubjects } from '../../../../actions/StudentDash/StudentSubjects/StudentSubjectAction';
import { GetStudentEnotes } from '../../../../actions/StudentDash/StudentEnote/StudentEnoteAction';
import { StudentEnotesI } from '../../../../actions/StudentDash/StudentEnote/StudentEnoteActionTypes';
import StudentEnotesModal from './StudentEnotesModal';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import { tblStyle } from '../../StudentDashboardStyles';

// -----------------<END> imports module ends -----------------------//
interface PropsI {
  enoteCategory: EnoteCategoryI | null;
}

interface EnoteCategoryI {
  id: string;
  name: string;
}

// -----------------<START> Leave Search component starts-----------------------//
const StudentEnoteList = (props: PropsI) => {
  const { enoteCategory } = props;
  const classes = useFormStyles();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [modalItem, setModalItem] = useState<StudentEnotesI | null>(null);
  const [selectedClass, setSelectedClass] =
    useState<TeacherClassSectionI | null>(null);
  const [selectedSubject, setSelectedSubject] =
    useState<TeacherSubjectsI | null>(null);

  const [templatesData, setTemplatesData] = useState<any[]>([]);

  // ======================== <START> REACT HOOKS <START> ==================================//
  const dispatch = useDispatch();
  const enoteSelector = useSelector((state: RootStore) => state.student_enote);

  useEffect(() => {
    dispatch(GetStudentSubjects());
  }, []);

  useEffect(() => {
    setSelectedClass(null);
    setSelectedSubject(SUBJECT_CHOICES[0]);
    setTemplatesData([]);
  }, [enoteCategory]);

  useEffect(() => {
    const data: StudentEnotesI[] = enoteSelector.enotes;
    setTemplatesData(data);
  }, [enoteSelector]);

  // ======================== <END> REACT HOOKS <END> ==================================//

  const handleModalClose = (value: boolean) => {
    setItemId(null);
    setDeleteModalOpen(value);
  };

  const handleViewModalOpen = (data: StudentEnotesI) => {
    setModalItem(data);
    setDeleteModalOpen(true);
  };

  const SUBJECT_CHOICES = useSelector(
    (state: RootStore) => state.student_subjects.subjects
  );

  useEffect(() => {
    handleSubjectChange(SUBJECT_CHOICES[0]);
  }, [SUBJECT_CHOICES]);

  const handleSubjectChange = (value: TeacherSubjectsI | null) => {
    setSelectedSubject(value);
    if (value && enoteCategory) {
      dispatch(GetStudentEnotes(value.subject, enoteCategory.id));
    }
    !value && setTemplatesData([]);
  };

  return (
    <Paper className={classes.pageAttachContent} style={tblStyle}>
      <Grid container justifyContent="space-between">
        <Grid item xs={6} className={classes.formTitle}>
          <Typography variant="h6">
            {enoteCategory ? enoteCategory.name : ''}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={5} className={classes.formWrapper}>
          <Autocomplete
            onChange={(
              event: React.ChangeEvent<{}>,
              value: TeacherSubjectsI | null
            ) => handleSubjectChange(value)}
            options={SUBJECT_CHOICES}
            value={selectedSubject}
            getOptionLabel={(option) => option.subject_name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Subject"
                name="class_name"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {templatesData.map((template: StudentEnotesI, index: number) => (
          <Grid item md={6} key={index}>
            <EnoteCard item={template} handleView={handleViewModalOpen} />
          </Grid>
        ))}
      </Grid>

      <Popups
        openPopup={deleteModalOpen}
        setOpenPopup={setDeleteModalOpen}
        onClose={handleModalClose}
        fullWidth={true}
        maxWidth="md"
        title="Enote"
        colored
      >
        <StudentEnotesModal
          item={modalItem}
          handleModalClose={setDeleteModalOpen}
        />
      </Popups>
    </Paper>
  );
};

//-----------------<ENDS> Leave Search component ends-----------------------//
export default StudentEnoteList;
