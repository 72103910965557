import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Box, Grid } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
  },
  media: {
    width: 60,
    height: 60,
    borderRadius: '50%',
  },
  frame: {
    padding: '6px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarContainer: {
    padding: '16px 0 0 0',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 600,
    marginTop: 24,
    lineHeight: '1.2',
  },
  stat: {
    fontSize: '15px',
    '& > span': {
      display: 'inline',
      fontSize: '12px',
      color: '#aaa',
    },
  },
});

const MemberStat = (props: any) => {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant="button"
        display="block"
        className={classes.title}
        style={{
          color: props.color,
        }}
      >
        {props.title}
      </Typography>

      <Typography variant="h6" className={classes.stat}>
        {props.value}
        {/* <span>/{props.total}</span> */}
      </Typography>
    </>
  );
};

export default function SchoolCard(props: any) {
  const classes = useStyles();
  const { school } = props;

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <div className={classes.avatarContainer}>
          <div className={classes.frame}>
            <CardMedia
              className={classes.media}
              image={school.logo}
              title={school.name}
            />
          </div>
        </div>

        <CardContent style={{ textAlign: 'center', paddingBottom: '8px' }}>
          <Typography
            variant="h6"
            component="h5"
            style={{
              fontSize: '18px',
              fontFamily: 'Montserrat, Poppins, Roboto, sans-serif',
            }}
          >
            {school.name}
          </Typography>

          <Typography
            variant="body2"
            style={{ fontWeight: 500, color: '#1d4698' }}
          >
            {school.address}
          </Typography>

          <Box>
            <Grid container justifyContent="space-between">
              <Grid item md={3}>
                <MemberStat
                  title="Students"
                  value={school.student.value}
                  total={school.student.total}
                  color="rgba(0, 255, 0, 0.6)"
                />
              </Grid>

              <Grid item md={3}>
                <MemberStat
                  title="Teachers"
                  value={school.teacher.value}
                  total={school.teacher.total}
                  color="rgba(0, 0, 255, 0.6)"
                />
              </Grid>

              <Grid item md={3}>
                <MemberStat
                  title="Staffs"
                  value={school.staff.value}
                  total={school.staff.total}
                  color="rgba(255, 0, 0, 0.6)"
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
