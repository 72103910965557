import {
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Chip,
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      background: 'rgb(43 110 245 / 87%)',
      margin: '1rem',

      '& *': {
        color: 'white',
      },
    },
    details: {
      // display: "flex",
    },

    cover: {
      width: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'space-between',
      padding: theme.spacing(1, 2),
      // paddingBottom: theme.spacing(1),
      '& .MuiChip-outlinedPrimary': {
        color: '#fff',
        border: '2px solid #fff',
      },
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  })
);

export interface AssignmentCardTypeI {
  title: string;
  assign_on: string;
  deadline: string;
}

const getRemainOrDueTime = (deadline: string) => {
  let assign_days = moment
    .duration(moment(new Date()).diff(moment(deadline)))
    .humanize();

  if (moment().isBefore(deadline)) {
    assign_days += ' remaining';
  } else {
    assign_days += ' due';
  }

  return assign_days;
};

const AssignmentCard = ({ item }: { item: any }) => {
  const { title, assign_on, deadline } = item;
  const classes = useStyles();

  return (
    <>
      <Card
        className={classes.root}
        style={{
          boxShadow: 'rgb(0 0 0 / 6%) 1px 1px 18px 1px',
          marginLeft: 0,
          marginTop: 20,
          borderRadius: 8,
          padding: '8px 8px',
        }}
      >
        <Grid container>
          <CardContent>
            <Typography component="h5" variant="h5" color="textSecondary">
              Assignment Details
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {title}
            </Typography>
          </CardContent>
          <Grid
            container
            className={classes.controls}
            justifyContent="space-between"
          >
            <Typography variant="subtitle1" color="textSecondary">
              Assign Date: {new Date(assign_on).toLocaleDateString()} {new Date(assign_on).toLocaleTimeString()}
            </Typography>
            <Chip
              variant="outlined"
              label={getRemainOrDueTime(deadline)}
              size="small"
              color="primary"
            />
            <Typography variant="subtitle1" color="textSecondary">
              Deadline: {new Date(deadline).toLocaleDateString()} {new Date(deadline).toLocaleTimeString()}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AssignmentCard;
