import { Box } from "@material-ui/core";
import PageHeader from '../../components/Header/PageHeader';
import PageWrapper from "../../components/PageWrapper";
import StaffForm from '../../../../components/HumanResource/Staff/StaffForm';

const TeacherUpdateProfile = () => {
    return (
        <>
            <Box>
                <PageHeader title="Update Profile" />
                <PageWrapper>
                    <StaffForm />
                </PageWrapper>
            </Box>
        </>
    );
};

export default TeacherUpdateProfile;