// ---------------------------- <START> module imports start ----------------------------------//
import { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootStore } from "../../../../store";
import { StudentExamScheduleI } from "../../../../actions/StudentDash/StudentExamSchedule/StudentExamScheduleActionTypes";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import useTable from "../../../../components/Reusable/useStudentTable";
import { tblStyle } from "../../StudentDashboardStyles";

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface PropsI {
  searchTitle: SearchDataI | null;
}

interface SearchDataI {
  exam: string;
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "subject", label: "Subject" },
  { id: "date", label: "Date" },
  { id: "start_time", label: "Start Time" },
  { id: "end_time", label: "End Time" },
  { id: "exam_type", label: "Exam Type" },
  { id: "full_mark", label: "Full Mark" },
  { id: "pass_mark", label: "Pass Mark" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const ExamScheduleTable = (props: PropsI) => {
  const { searchTitle } = props;
  const classes = useTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<StudentExamScheduleI[]>([]);
  const [tempTableData, setTempTableData] = useState<StudentExamScheduleI[]>(
    []
  );

  //==================================== <START> REACT HOOKS <START> ================================//
  const examScheduleSelector = useSelector(
    (state: RootStore) => state.student_exam_schedule
  );

  useEffect(() => {
    const exam_data = examScheduleSelector.exam_schedule;

    setLoading(examScheduleSelector.loading);
    if (searchTitle) {
      setTableData(exam_data);
      setTempTableData(exam_data);
    }
  }, [examScheduleSelector]);
  //==================================== <END> REACT HOOKS <END> ================================//

  //==================================== <START> EVENT HANDLERS <START> ================================//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //==================================== <END> EVENT HANDLERS <END> ================================//

  const getCustomTableRow = (item: StudentExamScheduleI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.subject_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.exam_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.start_time}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.end_time}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.exam_type === "1" ? "Theory" : "Practical"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.full_marks}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.pass_marks}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.rootTableBox} style={tblStyle}>
        <TableContainer
          label={
            searchTitle ? `Exam Schedule: ${searchTitle.exam}` : "Exam Schedule"
          }
          items={tableData}
          loading={examScheduleSelector.loading}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          rows={8}
          search_name="subject_name"
          hiddenCols={[-1]}
        />
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default ExamScheduleTable;
