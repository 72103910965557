import { Box } from "@material-ui/core";
import React from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import SyllabusManage from "./Componets/SyllabusManage";

const TeacherSyllabusPage = () => {
  return (
    <>
      <Box>
        <PageHeader
          title="Syllabus"
          module={{ name: "Academics", to: "/syllabus" }}
        />
        <PageWrapper>
          <SyllabusManage />
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherSyllabusPage;
