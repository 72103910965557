import { Box, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { TeacherComplaintTypeI } from "../../../../actions/TeacherDash/TeacherComplain/TeacherComplainActionTypes";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import TeacherComplainForm from "./TeacherComplainForm";
import TeacherComplainTable from "./TeacherComplainTable";

const TeacherFeedbackPage = () => {
  const [formData, setFormData] = useState<TeacherComplaintTypeI | null>(null);
  const [count, setCount] = useState<number>(0);
  const [editCheck, setEditCheck] = useState<any>();

  const onEditCheck = (data: any) => {
    setEditCheck({ check: data, count: count });
    setCount(count);
  };

  const fetchEditData = (data: TeacherComplaintTypeI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };

  return (
    <>
      <Box>
        <PageHeader
          title="Feedback"
          module={{ name: "Academics", to: "/complaint" }}
        />
        <PageWrapper>
          <Grid container>
            <Grid item xs={3}>
              <TeacherComplainForm
                onEditCheck={onEditCheck}
                editData={formData}
              />
            </Grid>
            <Grid item xs={9}>
              <TeacherComplainTable
                editCheck={editCheck}
                onEditData={fetchEditData}
              />
            </Grid>
          </Grid>
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherFeedbackPage;
