import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_PARENT_SYLLABUS_LOADING,
  GET_PARENT_SYLLABUS_ERROR,
  GET_PARENT_SYLLABUS_SUCCESS,
  ParentSyllabusDispatchTypes,
} from "./ParentSyllabusActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { PARENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetParentSyllabus =
  (academic_id: number) =>
  async (dispatch: Dispatch<ParentSyllabusDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_PARENT_SYLLABUS_LOADING,
      });

      const res = await axios.get(
        `${PARENT_API_URL}/unit/?student_academic_id=${academic_id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_PARENT_SYLLABUS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PARENT_SYLLABUS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Student's Teachers Loading Failed",
        },
      });
    }
  };
