import { Box } from "@material-ui/core";
import React from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import LessonPlanManage from "./Components/LessonPlanManage";

const LessonplanPage = () => {
  return (
    <>
      <Box>
        <PageHeader
          title="Lesson Plan"
          module={{ name: "Academics", to: "/lesson-plan" }}
        />
        <PageWrapper>
          <LessonPlanManage />
        </PageWrapper>
      </Box>
    </>
  );
};

export default LessonplanPage;
