import { motion } from 'framer-motion';
import { Winner } from './';
import { positions } from './constants';
import styled from 'styled-components';

interface Props {
  readonly podium: Winner[];
  readonly winner: Winner;
  readonly index: number;
}

const StyledContainer = styled.div`
  .item {
    background: ${(props) => props.theme.palette.primary.light} !important;
    border-color: ${(props) => props.theme.palette.primary.light} !important;
  }
`;

export default function PodiumStep({ podium, winner, index }: Props) {
  return (
    <StyledContainer>
      <div className="flex flex-col place-content-center">
        <motion.div
          custom={index}
          initial="hidden"
          animate="visible"
          variants={{
            visible: () => ({
              opacity: 1,
              transition: {
                delay: 0 + (podium.length - winner.place + 0.25),
                duration: 0.25,
              },
            }),
            hidden: { opacity: 0 },
          }}
          className="mb-1 self-center"
        >
          <img
            src={winner.avatar}
            alt=""
            className="rounded-full overflow-hidden border border-gray-200 shadow-sm w-12 h-12"
          />
        </motion.div>
        <motion.div
          custom={index}
          initial="hidden"
          animate="visible"
          variants={{
            visible: () => ({
              height: 180 * ((podium.length - winner.place) / podium.length),
              opacity: 2,
              transition: {
                delay: 0 + (podium.length - winner.place),
                duration: 0.75,
                ease: 'backInOut',
              },
            }),
            hidden: { opacity: 0, height: 0 },
          }}
          className="item flex w-20 border border-b-0 rounded-t-lg shadow-lg place-content-center cursor-pointer"
          style={{
            marginBottom: -1,
            filter: `opacity(${
              0.1 + (podium.length - winner.place) / podium.length
            })`,
          }}
        >
          <span className="self-end text-white font-semibold">
            {positions[winner.place]}
          </span>
        </motion.div>
      </div>
    </StyledContainer>
  );
}
