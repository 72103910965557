import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { Tupple } from "../../../../common/json.constant";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import AttendanceTable from "./AttendanceTable";
import SearchAttendace from "./SearchAttendace";

const AttendanceReportPage = () => {
  const [monthName, setMonthName] = useState<Tupple | null>(null);
  const [childrenName, setChildrenName] = useState<Tupple | null>(null);

  return (
    <>
      <Box>
        <PageHeader
          title="Class Attendace"
          module={{ name: "Reports", to: "/attendance-report" }}
        />
        <PageWrapper>
          <SearchAttendace
            setMonthName={setMonthName}
            setChildrenName={setChildrenName}
          />
          <AttendanceTable monthName={monthName} childrenName={childrenName} />
        </PageWrapper>
      </Box>
    </>
  );
};

export default AttendanceReportPage;
