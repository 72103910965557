import { Paper } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { HeadCellsI } from '../../../../actions';
import { GetStudentAssignmentbyID } from '../../../../actions/StudentDash/StudentAssignment/StudentAssignmentAction';
import { StudentAssignmentType } from '../../../../actions/StudentDash/StudentAssignment/StudentAssignmentActionTypes';
import { GetTeachingStatus } from '../../../../common/GetStatus';
import { RootStore } from '../../../../store';
import {
  ItemViewButton,
  ItemSubmitButton,
} from '../../../../components/Reusable/Buttons/TableButton';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import { useTableStyles } from '../../../../components/Styles/TableStyles';
import AssignmentView from './StudentAssignmentView';
import useTable from '../../../../components/Reusable/useStudentTable';
import { tblStyle } from '../../StudentDashboardStyles';

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 's.n', label: 'S.N.' },
  { id: 'title', label: 'Title' },
  // { id: 'date', label: 'Assigned On' },
  // { id: 'date1', label: 'Deadline' },
  { id: 'status', label: 'Status' },
  { id: 'marks', label: 'Obtained Marks' },
  { id: 'remarks', label: 'Remarks' },
  { id: 'action', label: 'Action' },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

const StudentAssignmentList = () => {
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<StudentAssignmentType[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [assignmentViewClicked, setAssignmentViewClicked] = useState(false);
  const [assignmentID, setAssignmentID] = useState<number | null>(null);
  const [assignment, setAssignment] = useState<any>(null);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const { loading, student_assignment, assignmentbyID } = useSelector(
    (state: RootStore) => state.student_assignment
  );

  useEffect(() => {
    if (student_assignment != null) {
      setTableData(student_assignment);
    }
  }, [student_assignment]);

  //========================= <END> REDUX HOOKS <END> ==============================//

  const handleViewModal = (item: any) => {
    dispatch(GetStudentAssignmentbyID(item.id));
    setAssignment(item);
    setOpenPopup(true);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleSubmitAssignment = (item: any) => {
    const { id } = item;
    setAssignmentID(id);
    setAssignmentViewClicked(true);
    setAssignment(item);
    dispatch(GetStudentAssignmentbyID(id));
  };

  if (assignmentViewClicked) {
    return <Redirect to={`/assignment/submit/${assignmentID}`} />;
  }

  const getCustomTableRow = (item: any, index: number) => {
    const deadline = new Date() > new Date(item.deadline)
    let disabled;
    if (item.submission_type === "Physical" || item.submitted || (!item.can_submit_after_deadline && deadline)) {
      disabled = true;
    } else {
      disabled = false;
    }
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
          {new Date(item.assign_on).toLocaleString()}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {new Date(item.deadline).toLocaleString()}
        </StyledTableCell> */}
        <StyledTableCell align="center" className={classes.cell}>
          <GetTeachingStatus
            status={item.submitted ? 'completed' : 'remaining'}
          />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item?.marks ? item.marks : 'N/A'}
        </StyledTableCell>
        <StyledTableCell>
          {item?.remarks ? item.remarks : 'N/A'}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleViewModal(item)} />
          <ItemSubmitButton
            onClick={() => handleSubmitAssignment(item)}
            disabled={disabled}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper
        className={clsx(classes.root, classes.attachFormTableRoot)}
        style={tblStyle}
      >
        <TableContainer
          label="Assignment List"
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="title"
          rowsPerPage={4}
          placeholder="Search Assignment"
        />
        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
          maxWidth="md"
          title="Assignment Details"
          colored
        >
          {assignment && <AssignmentView singleAssignment={assignment} />}
        </Popups>
      </Paper>
    </>
  );
};

export default StudentAssignmentList;
