const data = [
  {
    id: 1,
    name: "Ruby's International School",
    address: "Kathmandu, Nepal",
    logo: "/favicon.png",
    student: {
      value: 324,
      total: 327,
    },
    teacher: {
      value: 32,
      total: 32,
    },
    staff: {
      value: 32,
      total: 32,
    },
  },
  {
    id: 2,
    name: "St. Xavier's School",
    address: "Kathmandu, Nepal",
    logo: "/favicon.png",
    student: {
      value: 324,
      total: 327,
    },
    teacher: {
      value: 32,
      total: 32,
    },
    staff: {
      value: 32,
      total: 32,
    },
  },
  {
    id: 3,
    name: "Pathsala Nepal Foundation",
    address: "Kathmandu, Nepal",
    logo: "/favicon.png",
    student: {
      value: 324,
      total: 327,
    },
    teacher: {
      value: 32,
      total: 32,
    },
    staff: {
      value: 32,
      total: 32,
    },
  },
  {
    id: 4,
    name: "Modern Indian School",
    address: "Kathmandu, Nepal",
    logo: "/favicon.png",
    student: {
      value: 324,
      total: 327,
    },
    teacher: {
      value: 32,
      total: 32,
    },
    staff: {
      value: 32,
      total: 32,
    },
  },
  {
    id: 5,
    name: "Lincoln School",
    address: "Kathmandu, Nepal",
    logo: "/favicon.png",
    student: {
      value: 324,
      total: 327,
    },
    teacher: {
      value: 32,
      total: 32,
    },
    staff: {
      value: 32,
      total: 32,
    },
  },
  {
    id: 6,
    name: "The British School",
    address: "Kathmandu, Nepal",
    logo: "/favicon.png",
    student: {
      value: 324,
      total: 327,
    },
    teacher: {
      value: 32,
      total: 32,
    },
    staff: {
      value: 32,
      total: 32,
    },
  },
  {
    id: 7,
    name: "Pathsala Nepal Foundation",
    address: "Kathmandu, Nepal",
    logo: "/favicon.png",
    student: {
      value: 324,
      total: 327,
    },
    teacher: {
      value: 32,
      total: 32,
    },
    staff: {
      value: 32,
      total: 32,
    },
  },
  {
    id: 8,
    name: "Ruby's International School",
    address: "Kathmandu, Nepal",
    logo: "/favicon.png",
    student: {
      value: 324,
      total: 327,
    },
    teacher: {
      value: 32,
      total: 32,
    },
    staff: {
      value: 32,
      total: 32,
    },
  },
  {
    id: 9,
    name: "St. Xavier's School",
    address: "Kathmandu, Nepal",
    logo: "/favicon.png",
    student: {
      value: 324,
      total: 327,
    },
    teacher: {
      value: 32,
      total: 32,
    },
    staff: {
      value: 32,
      total: 32,
    },
  },
];

export default data;
