import {
  GET_SYLLABUS_LOADING,
  GET_SYLLABUS_ERROR,
  GET_SYLLABUS_SUCCESS,
  ADD_SYLLABUS_LOADING,
  ADD_SYLLABUS_ERROR,
  ADD_SYLLABUS_SUCCESS,
  UPDATE_SYLLABUS_LOADING,
  UPDATE_SYLLABUS_ERROR,
  UPDATE_SYLLABUS_SUCCESS,
  DELETE_SYLLABUS_LOADING,
  DELETE_SYLLABUS_ERROR,
  DELETE_SYLLABUS_SUCCESS,
  GET_SUB_UNIT_LOADING,
  GET_SUB_UNIT_ERROR,
  GET_SUB_UNIT_SUCCESS,
  ADD_SUB_UNIT_LOADING,
  ADD_SUB_UNIT_ERROR,
  ADD_SUB_UNIT_SUCCESS,
  UPDATE_SUB_UNIT_LOADING,
  UPDATE_SUB_UNIT_ERROR,
  UPDATE_SUB_UNIT_SUCCESS,
  DELETE_SUB_UNIT_LOADING,
  DELETE_SUB_UNIT_ERROR,
  DELETE_SUB_UNIT_SUCCESS,
  SyllabusDispatchTypes,
  SyllabusTypeI,
} from "../../../actions/TeacherDash/Syllabus/SyllabusActionTypes";

interface InitialStateI {
  loading: boolean;
  add_unit: any;
  sub_unit: any;
}

const initialState: InitialStateI = {
  loading: false,
  add_unit: [],
  sub_unit: [],
};

const TeacherSyllabusReducer = (
  state: InitialStateI = initialState,
  action: SyllabusDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_SYLLABUS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SYLLABUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SYLLABUS_SUCCESS:
      return {
        ...state,
        loading: false,
        add_unit: action.payload,
      };

    case ADD_SYLLABUS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_SYLLABUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_SYLLABUS_SUCCESS:
      return {
        ...state,
        loading: false,
        sub_unit: [...state.sub_unit, action.payload],
        // add_unit: [...state.add_unit, action.payload[0]?.unit],
      };

    case UPDATE_SYLLABUS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SYLLABUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_SYLLABUS_SUCCESS:
      const current_data: any[] = state.sub_unit;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        sub_unit: current_data,
      };

    case DELETE_SYLLABUS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SYLLABUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SYLLABUS_SUCCESS:
      const new_data: any = state.add_unit;
      const item_index = new_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return { ...state, loading: false, add_unit: new_data };

    case GET_SUB_UNIT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SUB_UNIT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SUB_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        sub_unit: action.payload,
      };

    case ADD_SUB_UNIT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_SUB_UNIT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_SUB_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        sub_unit: [...state.sub_unit, action.payload],
      };

    case UPDATE_SUB_UNIT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SYLLABUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_SUB_UNIT_SUCCESS:
      const c_data: any = state.sub_unit;
      const ind: number = c_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      c_data[ind] = action.payload;

      return { ...state, loading: false, sub_unit: c_data };

    case DELETE_SUB_UNIT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SUB_UNIT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SUB_UNIT_SUCCESS:
      const new_d: any = state.sub_unit;
      const item_i = new_d.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_d.splice(item_i, 1);

      return { ...state, loading: false, sub_unit: new_d };

    default:
      return state;
  }
};

export default TeacherSyllabusReducer;
