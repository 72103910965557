import {
  AppBar,
  Box,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import SchoolList from "./SchoolList";
import { tblStyle } from "../../../../ParentDashboard/ParentDashboardStyles";
import SchoolForm from "./SchoolForm";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
    padding: "0px!important",

    "& > div > .MuiBox-root": {
      padding: 0,
    },
  },

  tab: {
    "& span.MuiTab-wrapper": {
      fontSize: "14px",
      textTransform: "capitalize",
      letterSpacing: "0.02rem",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
}));

const SchoolTab = (props: any) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const [schoolData, setSchoolData] = React.useState(null);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root} style={{ ...tblStyle, padding: 0 }}>
      <AppBar position="static" color="inherit" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          scrollButtons="off"
          style={{ boxShadow: "0px 8px 12px 0px rgb(0 0 0 / 5%)" }}
        >
          <Tab label="School List" className={classes.tab} wrapped></Tab>
          <Tab label="Create School" className={classes.tab} wrapped></Tab>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <SchoolList
          setSchoolData={setSchoolData}
          onCancel={() => setValue(1)}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <SchoolForm
          schoolData={schoolData}
          setSchoolData={setSchoolData}
          onCancel={() => setValue(0)}
        />
      </TabPanel>
    </div>
  );
};

export default SchoolTab;
