import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import BackDropLoader from "../../Reusable/BackDropLoader";
import AddMarksAllTable from "./AddMarksAllTable";
import AddMarksForm from "./AddMarksForm";
import AddMarksTable from "./AddMarksTable";

const AddMarks = () => {
  const [marksType, setMarksType] = useState<string>("singleSubject");

  const { loading } = useSelector((state: RootStore) => state.addMarks);
  return (
    <>
      <div>
        <AddMarksForm addMarksMethod={setMarksType} />
        {marksType === "singleSubject" ? (
          <AddMarksTable />
        ) : (
          <AddMarksAllTable />
        )}
      </div>
      <BackDropLoader open={loading} />
    </>
  );
};

export default AddMarks;
