import { SectionTypeI } from "../../Academics/Section/SectionActionTypes";

export const GET_TEACHING_CLASS_LOADING = "GET_TEACHING_CLASS_LOADING";
export const GET_TEACHING_CLASS_ERROR = "GET_TEACHING_CLASS_ERROR";
export const GET_TEACHING_CLASS_SUCCESS = "GET_TEACHING_CLASS_SUCCESS";

export interface SectionI {
  id: string;
  name: string;
}
export interface TeachingClassTypeI {
  id: string;
  grade: string;
  grade_name: string;
  section: SectionI[];
}

//===============================<START>GET SYLLABUS<START>======================//

export interface GetTeachingClassLoading {
  type: typeof GET_TEACHING_CLASS_LOADING;
}

export interface GetTeachingClassError {
  type: typeof GET_TEACHING_CLASS_ERROR;
}

export interface GetTeachingClassSuccess {
  type: typeof GET_TEACHING_CLASS_SUCCESS;
  payload: any;
}

//===============================<END>GET SYLLABUS<END>======================//

export type TeachingClassDispatchTypes =
  | GetTeachingClassLoading
  | GetTeachingClassError
  | GetTeachingClassSuccess;
