import React from "react";
import { Box } from "@material-ui/core";
import PageWrapper from "../../../ParentDashboard/components/PageWrapper";
import PageHeader from "../../../ParentDashboard/components/Header/PageHeader";
import SchoolTab from "./Components/SchoolTab";

const SchoolPage = () => {
  return (
    <Box>
      <PageHeader title="School Details" />

      <PageWrapper>
        <SchoolTab />
      </PageWrapper>
    </Box>
  );
};

export default SchoolPage;
