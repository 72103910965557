// ---------------- <START> module import start ------------------//
import React from 'react';
import { Typography, CardContent, Card, Grid } from '@material-ui/core';
import { StudentEnotesI } from '../../../../../actions/StudentDash/StudentEnote/StudentEnoteActionTypes';
import { ItemViewButton } from '../../../../../components/Reusable/Buttons/TableButton';

// ----------------<END> module import end ------------------//

// interface PropsI {
//   item: SmsTemplateTypeI;
//   handleDelete: (id: number) => void;
// }

interface PropsI {
  item: StudentEnotesI;
  handleView: (data: StudentEnotesI) => void;
}

// ------------------- <START> Enote Card Component Starts -------------------//
const EnoteCard = (props: PropsI) => {
  const { item, handleView } = props;
  return (
    <Card variant="outlined">
      <CardContent style={{ paddingBottom: '16px' }}>
        <Typography variant="h4" gutterBottom>
          <strong>{item.title}</strong>

          <Typography variant="caption" color={'textSecondary'}>
            {', 14th April, 2021'}
          </Typography>
        </Typography>

        <Typography variant="body2" component="p" gutterBottom>
          {item.meta_description}
        </Typography>

        <Grid
          container
          justifyContent="space-between"
          style={{ marginBottom: '8px' }}
        >
          <Grid item>
            <Grid container spacing={1}>
              {/* <Grid item>
                <Typography variant="subtitle2">Alisha Pokhrel</Typography>
              </Grid>

              <Grid item>{" 3 (A)"}</Grid> */}
            </Grid>
          </Grid>

          {/* <Grid item>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <ItemDeleteButton
                  onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleDelete(item.id);
                  }}
                />
              </Grid>

              <Grid item>
                <ItemEditButton
                  onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    history.push("/enote/add");
                  }}
                />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <ItemViewButton
                  onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleView(item);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
// ------------------- <EMD> Enote Card Component Starts -------------------//

export default EnoteCard;
