import {
  GET_LIVE_CLASS_LOADING,
  GET_LIVE_CLASS_ERROR,
  GET_LIVE_CLASS_SUCCESS,
  LiveClassDispatchTypes,
  StudentLiveClassTypeI,
} from "../../actions/StudentDash/StudentLiveClass/StudentLiveActionTypes";

interface InitialStateI {
  loading: boolean;
  student_live_class: StudentLiveClassTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  student_live_class: [],
};

const StudentLiveClassReducer = (
  state: InitialStateI = initialState,
  action: LiveClassDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_LIVE_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_LIVE_CLASS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_LIVE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        student_live_class: action.payload,
      };

    default:
      return state;
  }
};

export default StudentLiveClassReducer;
