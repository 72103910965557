import React from 'react';
import {
  Paper,
  makeStyles,
  createStyles,
  Theme,
  Tooltip,
  Typography,
  Grid,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import TeacherBoardHeader from './TeacherBoardHeader';
import TeacherEventCard from '../TeacherEventCard';
import NepaliDate from 'nepali-date-converter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2.5, 3, 0.5, 3),
      '& .fc-prev-button': {
        border: 'none',
      },
      '& .fc-next-button': {
        border: 'none',
      },
      '& .fc-toolbar-title': {
        fontSize: '12px',
      },
      '& .fc-direction-ltr .fc-toolbar > * > :not(:first-child)': {
        marginLeft: theme.spacing(0),
      },
      '& .fc .fc-button-primary': {
        color: 'black',
        backgroundColor: 'white',
        borderColor: '#929292',
        borderRadius: '3px',
        margin: '5px',
      },
      '& .fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active':
        {
          backgroundColor: '#132E98',
          boxShadow: 'none',
          borderColor: '#132E98',
        },
      '& .fc .fc-button-primary:focus': {
        boxShadow: 'none',
      },
      '& .fc .fc-daygrid-day.fc-day-today': {
        backgroundColor: '#d7dff5',
        color: '#000',
      },
      '& .fc .fc-daygrid-day-number': {
        fontSize: '11px',
      },
    },
    eventList: {
      display: 'flex',
      overflow: 'hidden',
      alignItems: 'center',
    },
    boxHeaderLink: {
      '&:hover': {
        textDecoration: 'none',
        paddingBottom: '2px',
        borderBottom: ' 2px solid',
      },
    },
  })
);

const EventWidgetCard = (props: any) => {
  const classes = useStyles();

  const date5 = new NepaliDate();
  const month = date5.format('MMMM');
  const year = date5.format('YYYY');
  const date = new Date();
  const EnglishMonth = date.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <Paper elevation={0}>
      <div className={classes.root}>
        <TeacherBoardHeader
          title="Events"
          date={`${EnglishMonth}`}
          to="/events"
        />
        <TeacherEventCard />
      </div>
    </Paper>
  );
};

export default EventWidgetCard;
