// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import { Paper, TableCell } from '@material-ui/core';
import moment from 'moment';
import { useTableStyles } from '../../../../components/Styles/TableStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import CustomizedDialogs from '../../../../components/Reusable/Dialogs/DeleteDialog';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import MeetingRequestView from './MeetingRequestView';

import { PRIMARY_DATE_FORMAT } from '../../../../common/constant';
import cx from 'clsx';
import { MeetingTypeI } from '../../../../actions/HumanResource/Meeting/MeetingActionTypes';
import getFullName from '../../../../components/utils/getFullName';
import GetStatus from '../../../../common/GetStatus';
import { HeadCellsI } from '../../../../actions';
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from '../../../../components/Reusable/Buttons/TableButton';
import { dateConverterAdToBs } from '../../../../components/utils/dateConverter';
import { GetMeetingTypes } from '../../../../actions/HumanResource/MeetingType/MeetingTypeAction';
import useLoading from '../../../../useHook/useLoading';
import {
  DeleteMeeting,
  GetMeetings,
} from '../../../../actions/ParentsDash/ParentMeeting/ParentMeetingAction';
import {
  ParentMeetingI,
  ParentMeetingII,
} from '../../../../actions/ParentsDash/ParentMeeting/ParentMeetingActionTypes';
import MeetingRequestModal from './MeetingRequestModal';
import useTable from '../../../../components/Reusable/useStudentTable';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "s.n", label: "S.N." },
  { id: 'meeting_type', label: 'Meeting Type' },
  { id: 'meeting_date', label: 'Meeting Date' },
  { id: 'meeting_time', label: 'Meet Time' },
  { id: 'applied_date', label: 'Applied Date' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

interface MeetingListProps {
  academicID?: string;
}

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const MeetingList = (props: MeetingListProps) => {
  const { academicID } = props;
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<ParentMeetingII[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openFormPopup, setOpenFormPopup] = useState<boolean>(false);
  const [meetingData, setMeetingData] = useState([]);
  const [item, setItem] = useState<object | null>(null);

  console.log('academicID', academicID);

  const meetingSelector = useSelector(
    (state: RootStore) => state.parent_meeting
  );
  const { loading } = useLoading(meetingSelector.loading);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  useEffect(() => {
    dispatch(GetMeetingTypes());
    dispatch(GetMeetings());
  }, []);

  useEffect(() => {
    // const items = formatData(dummyData);
    const items = formatData(meetingSelector.parent_meetings);
    setTableData(items);
  }, [meetingSelector]);

  const formatData = (items: ParentMeetingI[]) => {
    return items.map((item: ParentMeetingI) => ({
      id: item.id,
      meeting_type_id: item.meeting_type.id,
      meeting_type: item.meeting_type.name,
      meeting_date: dateConverterAdToBs(item.meet_date),
      meeting_time: item.meet_time,
      applied_date: dateConverterAdToBs(item.created_on),
      description: item.description,
      status: item.status,
    }));
  };
  //========================= <END> REDUX HOOKS <END> ==============================//

  const handleDeleteModal = (id: string) => {
    setItemId(id);
    setDeleteModalOpen(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteMeeting(itemId));
    setDeleteModalOpen(false);
  };

  const handleViewModal = (item: any) => {
    setMeetingData(item);
    setItemId(item.id);
    setOpenPopup(true);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleFormClose = (value: boolean) => {
    setOpenFormPopup(value);
  };

  const handleEditModal = (item: any) => {
    setOpenFormPopup(true);
    setItem(item);
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.meeting_type}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.meeting_date).format(PRIMARY_DATE_FORMAT)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.meeting_time, 'HH:mm:ss').format('hh:mm A')}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.applied_date).format(PRIMARY_DATE_FORMAT)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <GetStatus status={item.status} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleViewModal(item)} />
          {/* Parent cannot edit meeting */}
          {/* <ItemEditButton onClick={() => handleEditModal(item)} /> */}
          <ItemDeleteButton onClick={() => handleDeleteModal(item.id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper
      className={cx(classes.root, classes.attachFormTableRoot)}
      elevation={0}
      style={{ padding: 16 }}
    >
      <TableContainer
        label="Meeting List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        rowsPerPage={4}
        getCustomTableRow={getCustomTableRow}
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />

      <Popups
        openPopup={openFormPopup}
        setOpenPopup={setOpenFormPopup}
        onClose={handleFormClose}
        title="Update Request Meeting"
        maxWidth="sm"
      >
        <MeetingRequestModal onClose={handleFormClose} item={item} editable />
      </Popups>

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        maxWidth="sm"
        title="Meeting Request"
        colored
      >
        <MeetingRequestView items={meetingData} />
      </Popups>
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default MeetingList;
