import React, { useState, useEffect } from "react";
import { Typography, Paper, Grid, Chip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { useParams } from "react-router";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import { RootStore } from "../../../../../store";
import { GetChildrenFeePaidInvoice } from "../../../../../actions/ParentsDash/ChildrenFee/ChildrenFeeAction";
import getMonthName from "../../../../../components/utils/getMonthName";
import FeeTable from "../../../../../components/Student/StudentProfile/File/FeeTable";
import PaidChildrenFeeTable from "./PaidChildrenFeeTable";

interface HeadCellsI {
  id: string;
  label: string;
}
interface TableDataI {
  id: number;
  is_monthly: boolean;
  name: string;
  amount: number;
  due_date: string;
  count: number;
}

const headCells: HeadCellsI[] = [
  { id: "name", label: "Fee Name" },
  { id: "type", label: "Type" },
  { id: "amount", label: "Amount" },
  { id: "date", label: "Due Date" },
];

const PaidChildrenFee = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const studentSelector = useSelector((state: RootStore) => state.student_profile.student);

  const [feeData, setFeeData] = useState<any>([]);
  const [student, setStudent] = useState<any>(null);
  const [feesHead, setFeesHead] = useState<any>([]);

  const feeHead = useSelector(
    (state: RootStore) => {
      if (localStorage.getItem("role") === "Administrator") {
        return state.collect_fees?.fee_invoice;
      }
      if (props.value === 0) {
        return state.children_invoice.invoice;
      }
      return state.children_invoice.paid_invoice;
    }
  );

  console.log({ feesHead })

  useEffect(() => {
    if (props.value === 1) {
      setFeesHead({
        paid_invoices: feeHead,
      })
    } else if (props.value === 0) {
      setFeesHead(feeHead);
    } else {
      setFeesHead(feeHead);
    }
  }, [feeHead, props.invoiceMode])

  useEffect(() => {
    if (props.data) {
      setStudent(props.data)
    } else {
      setStudent(studentSelector);
    }
  }, [studentSelector])

  console.log({ student })

  useEffect(() => {
    if (student) {
      const academic_id = student?.academic_info?.[0]?.id;
      if (props.value === 1) {
        dispatch(GetChildrenFeePaidInvoice(academic_id));
      }
    }
  }, [student]);


  useEffect(() => {
    console.log({ feesHead })
    const paidData: any[] = []
    const dueData: any[] = []

    if (feesHead?.generated_invoices !== null) {

      const data: any[] = []
      for (let i = 0; i < feesHead?.generated_invoices?.length; i++) {
        for (let j = 0; j < feesHead?.generated_invoices[i].invoices?.length; j++) {
          dueData.push({
            date: moment(feesHead?.generated_invoices[i].invoices[j].created_on).format('YYYY-MM-DD'),
            transaction_type: feesHead?.generated_invoices[i].invoices[j].invoice_id,
            fee_head: feesHead?.generated_invoices[i].invoices[j].month !== "" ? feesHead?.generated_invoices[i].fee_name + " - " + getMonthName(feesHead?.generated_invoices[i].invoices[j].month) : feesHead?.generated_invoices[i].fee_name,
            period: feesHead?.generated_invoices[i].invoices[j].month !== "" ? getMonthName(feesHead?.generated_invoices[i].invoices[j].month) : feesHead?.generated_invoices[i].invoices[j].fee_type__due_date,
            charges: feesHead?.generated_invoices[i].invoices[j].amount,
            fine: 'Rs. 0',
            balance: feesHead?.generated_invoices[i].invoices[j].amount,
            status: 'Unpaid'
          })
        }
      }
    }

    if (feesHead?.dues !== null) {
      for (let i = 0; i < feesHead?.dues?.length; i++) {
        dueData.push({
          date: moment(feesHead?.dues[i]?.created_on).format('YYYY-MM-DD'),
          transaction_type: feesHead?.dues[i]?.paid_invoice?.receipt,
          fee_head: feesHead?.dues[i]?.paid_invoice?.month !== "" ? feesHead?.dues[i]?.paid_invoice?.invoice?.fee_type?.name + " - " + feesHead?.dues[i]?.paid_invoice?.month + ' (due)' : feesHead?.dues[i].invoices?.paid_invoice?.invoice?.fee_type?.name + ' (due)',
          period: getMonthName(feesHead?.dues[i]?.paid_invoice?.invoice?.month),
          charges: Number(feesHead?.dues[i]?.paid_invoice?.paid_amount) + Number(feesHead?.dues[i]?.due_amount),
          fine: 'Rs. 0',
          balance: Number(feesHead?.dues[i]?.paid_invoice?.paid_amount) + Number(feesHead?.dues[i]?.due_amount),
          status: 'Unpaid'
        })
      }
    }

    if (feesHead?.paid_invoices !== null && !props.invoiceMode) {
      for (let i = 0; i < feesHead?.paid_invoices?.length; i++) {
        if (props?.value === 1) {
          paidData.push({
            date: moment(feesHead?.paid_invoices[i]?.created_on).format('YYYY-MM-DD'),
            transaction_type: feesHead?.paid_invoices[i]?.receipt_id,
            charges: feesHead?.paid_invoices[i].paid_amount,
            balance: feesHead?.paid_invoices[i].paid_amount,
            status: 'Paid'
          })
        } else {
          paidData.push({
            date: moment(feesHead?.paid_invoices[i]?.created_on).format('YYYY-MM-DD'),
            transaction_type: feesHead?.paid_invoices[i]?.receipt__receipt_id || feesHead?.paid_invoices[i]?.receipt_id,
            fee_head: feesHead?.paid_invoices[i].invoice__month && feesHead?.paid_invoices[i].invoice__month !== "" ? feesHead?.paid_invoices[i].invoice__fee_type__name + " - " + getMonthName(feesHead?.paid_invoices[i].invoice__month) : feesHead?.paid_invoices[i].invoice__fee_type__name,
            period: feesHead?.paid_invoices[i].invoice__month ? getMonthName(feesHead?.paid_invoices[i].invoice__month) : null,
            charges: feesHead?.paid_invoices[i].paid_amount,
            fine: feesHead?.paid_invoices[i].fine_amount ? 'Rs. ' + feesHead?.paid_invoices[i].fine_amount : 'Rs 0',
            balance: feesHead?.paid_invoices[i].paid_amount,
            status: 'Paid'
          })
        }
      }
    }
    console.log({ dueData })
    setFeeData(dueData.concat(paidData))
  }, [feesHead, props.invoiceMode])


  return (
    <>
      <Paper className={classes.roottable}>
        <Typography variant="h6">{props.invoiceMode ? 'Fee Invoices' : 'Fee Statements'}</Typography>

        <PaidChildrenFeeTable
          student={student}
          statementTableData={feeHead}
          feesHead={feesHead}
          value={props.value}
          isInvoice={props.invoiceMode}
        />
      </Paper>
    </>
  );
};

export default PaidChildrenFee;