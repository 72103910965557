import {
  GET_PARENT_MEETING_LOADING,
  GET_PARENT_MEETING_ERROR,
  GET_PARENT_MEETING_SUCCESS,
  ADD_PARENT_MEETING_LOADING,
  ADD_PARENT_MEETING_ERROR,
  ADD_PARENT_MEETING_SUCCESS,
  DELETE_PARENT_MEETING_LOADING,
  DELETE_PARENT_MEETING_ERROR,
  DELETE_PARENT_MEETING_SUCCESS,
  UPDATE_PARENT_MEETING_LOADING,
  UPDATE_PARENT_MEETING_ERROR,
  UPDATE_PARENT_MEETING_SUCCESS,
  ParentMeetingI,
  MeetingDispatchTypes,
} from '../../actions/ParentsDash/ParentMeeting/ParentMeetingActionTypes';

interface InitialStateI {
  loading: boolean;
  parent_meetings: ParentMeetingI[];
}

const initialState: InitialStateI = {
  loading: false,
  parent_meetings: [],
};

const ParentMeetingReducer = (
  state: InitialStateI = initialState,
  action: MeetingDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_PARENT_MEETING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PARENT_MEETING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_PARENT_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        parent_meetings: action.payload,
      };

    case ADD_PARENT_MEETING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_PARENT_MEETING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADD_PARENT_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        parent_meetings: [...state.parent_meetings, action.payload],
      };

    case UPDATE_PARENT_MEETING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PARENT_MEETING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PARENT_MEETING_SUCCESS:
      const current_data: ParentMeetingI[] = state.parent_meetings;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        parent_meetings: current_data,
      };

    case DELETE_PARENT_MEETING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PARENT_MEETING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PARENT_MEETING_SUCCESS:
      const new_data: ParentMeetingI[] = state.parent_meetings;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        parent_meetings: new_data,
      };

    default:
      return state;
  }
};

export default ParentMeetingReducer;
