import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaMedal } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { GetTeacherDashboard } from '../../../../actions/TeacherDash/Dasbhoard/TeacherDashboardAction';
import {
  DashboardLeaveRequestI,
  DashboardOnlineClassI,
  DashboardSubmitAssignmentI,
  DashboardSyllabusI,
} from '../../../../actions/TeacherDash/Dasbhoard/TeacherDashboardActionTypes';
import { RootStore } from '../../../../store';
import EventResultWidget from '../../components/Widget/EventResultWidget';
import EventWidgetCard from '../../components/Widget/EventWidgetCard';
import LeaveRequestWidget from '../../components/Widget/LeaveRequestWidget';
import NewLiveClassesTabs from '../../components/Widget/NewLiveClassesTabs';
import SubmittedAssignmentWidget from '../../components/Widget/SubmittedAssignmentWidget';
import TeacherEventsCalendar from '../../components/Widget/TeacherEventsCalendar';
import TeacherSyllabusWidget from '../../components/Widget/TeacherSyllabusWidget';
import TimerCard from '../../components/Widget/TimerCard';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    marginLeft: '240px',
    minHeight: '100vh',
    // borderRadius: "30px 0 0 30px",
    background: 'rgba(0,0,0,0.08)',
  },
  contentArea: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  widget: {
    paddingLeft: '20px',
    position: 'relative',
    // width: "370px",
    // minHeight: "100vh",
    background: '#f0f0fc',
  },
  notificationBadge: {
    color: theme.palette.primary.main,
    borderRadius: 5,
    padding: 5,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '2px 4px 20px 3px rgb(0 0 0 / 12%)',

    '& > span': {
      top: 2,
      right: 2,
      padding: 6,
      borderRadius: '50%',
      backgroundColor: theme.palette.success.main,
    },
  },
  paper: {
    // borderRadius: 18,
    boxShadow: 'rgb(0 0 0 / 6%) 1px 1px 18px 1px',
    marginBottom: '20px',
  },
}));

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export function EventResultCelebrate() {
  const classes = useStyles();
  return (
    <Carousel responsive={responsive}>
      {[1, 2, 3, 4, 5].map((item) => {
        return (
          <Paper key={item} className={classes.paper}>
            <EventResultWidget />
          </Paper>
        );
      })}
    </Carousel>
  );
}

const TeacherDashboardPage = () => {
  const classes = useStyles();

  const [onlineClass, setOnlineClass] = useState<DashboardOnlineClassI[]>([]);
  const [syllabus, setSyllabus] = useState<DashboardSyllabusI[]>([]);
  const [leave, setLeave] = useState<DashboardLeaveRequestI[]>([]);
  const [assignment, setAssignment] = useState<DashboardSubmitAssignmentI[]>(
    []
  );

  const authState = useSelector((state: RootStore) => state.auth);
  const dashboardSelector = useSelector(
    (state: RootStore) => state.teacher_dashboard.dashboard
  );
  const dashboardLoading = useSelector(
    (state: RootStore) => state.teacher_dashboard?.loading
  );

  useEffect(() => {
    if (dashboardSelector) {
      setOnlineClass(dashboardSelector.live_class);
      setSyllabus(dashboardSelector.subject);
      setLeave(dashboardSelector.leave_request);
      setAssignment(dashboardSelector.assignment_submitted);
      setAssignment(dashboardSelector.assignment_submitted);
    }
  }, [dashboardSelector]);

  return (
    <Box className={classes.content}>
      <Box style={{ padding: '16px 24px' }}>
        <Typography
          variant="h5"
          color="primary"
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ fontWeight: 600 }}>
            {authState.general_info?.school_name}
          </span>
          <span style={{ fontWeight: 400 }}>Dashboard</span>
        </Typography>

        <Typography color="textSecondary" variant="subtitle2">
          <TimerCard />
        </Typography>

        <Grid
          container
          spacing={3}
          className={classes.contentArea}
          style={{ marginTop: '16px' }}
        >
          <Grid md={7}>
            {onlineClass && onlineClass.length > 0 && (
              <Paper className={classes.paper}>
                <NewLiveClassesTabs onlineClass={onlineClass} />
              </Paper>
            )}

            <Paper className={classes.paper}>
              <TeacherEventsCalendar />
              {/* <NepaliCalendarPage /> */}
            </Paper>

            {assignment && assignment.length > 0 && (
              <Paper className={classes.paper}>
                <SubmittedAssignmentWidget assignment={assignment} />
              </Paper>
            )}
          </Grid>
          <Grid md={5} className={classes.widget}>
            <EventResultCelebrate></EventResultCelebrate>
            <Paper className={classes.paper}>
              <EventWidgetCard />
            </Paper>
            <Paper className={classes.paper}>
              <TeacherSyllabusWidget syllabus={syllabus} />
            </Paper>
            <Paper className={classes.paper}>
              <LeaveRequestWidget leave={leave} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TeacherDashboardPage;
