export const GET_STUDENT_ANNOUNCEMENTS_LOADING =
  "GET_STUDENT_ANNOUNCEMENTS_LOADING";
export const GET_STUDENT_ANNOUNCEMENTS_ERROR =
  "GET_STUDENT_ANNOUNCEMENTS_ERROR";
export const GET_STUDENT_ANNOUNCEMENTS_SUCCESS =
  "GET_STUDENT_ANNOUNCEMENTS_SUCCESS";

export type StudentAnnouncementTypeI = {
  id: number;
  priority: string;
  priority_display_name: string;
  grade_name: string;
  section_name: string | null;
  title: string;
  announcement_date: string;
  created_on: string;
  start_time: string;
  end_time: string;
  description: string;
};

//===============================<START>GET STUDENT_ANNOUNCEMENTS<START>======================//

export interface GetStudentAnnouncementsLoading {
  type: typeof GET_STUDENT_ANNOUNCEMENTS_LOADING;
}

export interface GetStudentAnnouncementsError {
  type: typeof GET_STUDENT_ANNOUNCEMENTS_ERROR;
}

export interface GetStudentAnnouncementsSuccess {
  type: typeof GET_STUDENT_ANNOUNCEMENTS_SUCCESS;
  payload: StudentAnnouncementTypeI[];
}
//===============================<END>GET STUDENT_ANNOUNCEMENTS<END>======================//

export type StudentAnnouncementsDispatchTypes =
  | GetStudentAnnouncementsLoading
  | GetStudentAnnouncementsError
  | GetStudentAnnouncementsSuccess;
