import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  RootRef,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '../../../../assets/images/CloseIcon.svg';
import { Add, AddShoppingCart } from '@material-ui/icons';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Popups from '../../../Reusable/Dialogs/Popups';
import CustomizedTextField from '../../../Reusable/Inputs/TextField';
import { FormSaveButton } from '../../../Reusable/Buttons/FormButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .dropzone': {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed #777',
        paddingTop: '16px',
        paddingBottom: '16px',
        padding: '16px',
        borderRadius: '4px',

        '&:hover': {
          border: '1px dashed dodgerblue',
        },
      },
    },
    imgPreview: {
      position: 'relative',
      textAlign: 'center',

      '& .close-icon': {
        position: 'absolute',
        top: 4,
        right: 5,
        color: '#aaa',
        height: '18px',
        width: '18px',
        cursor: 'pointer',
        borderRadius: '4px',
        padding: '2px',
        backgroundColor: 'rgba(0,0,0,0.75)',
        zIndex: 999,

        '&:hover': {
          color: '#fff',
        },
      },
    },
  })
);

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 120,
  height: 120,
  padding: 4,
  // boxSizing: "border-box",
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: '120px',
  height: '100%',
};

export default function FileUploadZone(props: any) {
  const { files, setFiles } = props;
  const [confirmModal, setConfirmModal] = useState(false);
  const [caption, setCaption] = useState();

  useEffect(() => {
    // setFiles([]);
  }, []);

  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone({
    // accept: "image/*",
    onDrop: (files: any) => {
      setFiles(
        files.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const { ref, ...rootProps } = getRootProps();
  const handleCaption = (value: string) => {
    console.log(value);
  };
  const handleConfirmModal = (open: boolean, index: number) => {
    console.log(open, index);
    setConfirmModal(open);
  };

  const thumbs = files.map((file: any, index: number) => (
    <div
      key={file.name}
      className={classes.imgPreview}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <img
        className="close-icon"
        src={CloseIcon}
        alt="CloseIcon"
        onClick={() => {
          setFiles(files.filter((item: any) => item.name !== file.name));
        }}
      />

      {file.type.indexOf('image') > -1 ? (
        <React.Fragment>
          <div
            style={{
              position: 'absolute',
              bottom: 40,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgb(192,192,192, 0.5)',
              cursor: 'pointer',
            }}
            onClick={() => handleConfirmModal(true, index)}
          >
            <Typography align="center" style={{ fontSize: '11px' }}>
              Click to Add Caption
            </Typography>
          </div>
          <img
            src={file.preview}
            alt={file.name}
            title={file.name}
            style={{
              width: '120px',
              height: '120px',
              objectFit: 'cover',
              borderRadius: '8px',
            }}
          />
          <div>
            {file.name.substring(0, 16)}
            {file.name.length > 16 ? '...' : null}
          </div>
        </React.Fragment>
      ) : (
        <>
          <InsertDriveFileIcon
            // title={file.name}
            style={{
              width: '120px',
              height: '120px',
              objectFit: 'cover',
              borderRadius: '8px',
            }}
          />

          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 4,
              top: 15,
              left: 0,
            }}
          >
            <span
              style={{
                backgroundColor: 'white',
                padding: '4px',
                borderRadius: '4px',
                fontSize: '12px',
              }}
            >
              {(file.size / (1024 * 1024)).toFixed(1)} MB
            </span>

            <span
              style={{
                backgroundColor: 'white',
                padding: '4px',
                borderRadius: '4px',
                fontSize: '9px',
              }}
              title={file.name}
            >
              {file.name.slice(0, 12)}
            </span>
          </div>
        </>
      )}
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const handleConfirmClose = () => {
    setConfirmModal(false);
  };

  return (
    <section className="container" style={{ width: '100%' }}>
      <div className={classes.root}>
        {/* <RootRef rootRef={ref}> */}
        <Paper {...rootProps} className="dropzone">
          <input {...getInputProps()} />
          {files && files.length > 0 ? (
            <div style={{ width: '100%' }}>
              <aside
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  gap: '16px',
                  marginTop: 8,
                }}
              >
                {thumbs}

                <div className={classes.imgPreview}>
                  <IconButton
                    color="primary"
                    style={{
                      width: '120px',
                      height: '120px',
                      objectFit: 'cover',
                      borderRadius: '8px',
                      backgroundColor: 'rgba(0,0,0,0.05)',
                    }}
                  >
                    <Add fontSize="large" />
                  </IconButton>
                </div>
              </aside>
            </div>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </Paper>
        {/* </RootRef> */}
      </div>
      <Popups
        openPopup={confirmModal}
        setOpenPopup={setConfirmModal}
        onClose={handleConfirmClose}
        // title="Confirm a Title for the Meet"
        maxWidth="sm"
      >
        <Grid item xs={12}>
          <CustomizedTextField
            label="Enter a Caption"
            placeholder="Label"
            name="caption"
            onChange={(event: React.ChangeEvent<{}>, value: string) =>
              handleCaption(value)
            }
          />
        </Grid>
        <Grid container justifyContent="flex-end">
          <FormSaveButton />
        </Grid>
      </Popups>
    </section>
  );
}
