import { Box, Chip, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetTeacherEvent } from '../../../../../actions/TeacherDash/TeacherEvents/TeacherEventAction';
import { RootStore } from '../../../../../store';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import { getDateOccurrence } from '../../TeacherAnnouncement/ModalView/TeacherAnnounce';
import { tableStyle } from '../../../components/Styles/TeacherDashboardStyles';

interface OtherPropsI {
  id: number;
  academic_session: number;
  academic_session_name: string;
  from_date: string;
  description: string;
  event_type: number;
  event_type_name: string;
  is_holiday: boolean;
  location: string;
  time: string;
  title: string;
}
interface WrapperI {
  heading: string;
  data: OtherPropsI[];
  occurrence: string;
  selected?: boolean;
}

export const EventsAnnounce = () => {
  const classes = useFormStyles();
  const margin = { marginLeft: `5px`, color: 'blue' };

  const dispatch = useDispatch();

  const teacherEvents = useSelector((state: RootStore) => state.teacher_event);
  const [todayData, setTodayData] = useState<OtherPropsI[]>([]);
  const [tomData, setTomData] = useState<OtherPropsI[]>([]);
  const [otherData, setOtherData] = useState<OtherPropsI[]>([]);

  const [dataSliced, setDataSliced] = useState({
    today: false,
    tomorrow: false,
    upcoming: false,
  });

  useEffect(() => {
    GetTeacherEvent();
  }, []);

  useEffect(() => {
    const data = teacherEvents.teacher_event;

    const todays: OtherPropsI[] = [];
    const tomorrows: OtherPropsI[] = [];
    const upcomings: OtherPropsI[] = [];

    if (data.length) {
      data.map((element: OtherPropsI) => {
        switch (getDateOccurrence(element.from_date)) {
          case 'today':
            todays.push(element);
            break;

          case 'tomorrow':
            tomorrows.push(element);
            break;

          case 'upcoming':
            upcomings.push(element);
            break;

          default:
            break;
        }
      });
    }
    setTodayData(todays);
    setTomData(tomorrows);
    setOtherData(upcomings);
  }, [teacherEvents]);

  //EVENT HANDLER///////////////////////////////////////////

  const changeViewState = (state: string) => {
    switch (state) {
      case 'today':
        setDataSliced({
          ...dataSliced,
          today: !dataSliced.today,
        });
        break;

      case 'tomorrow':
        setDataSliced({
          ...dataSliced,
          tomorrow: !dataSliced.tomorrow,
        });
        break;

      case 'upcoming days':
        setDataSliced({
          ...dataSliced,
          upcoming: !dataSliced.upcoming,
        });
        break;

      default:
        break;
    }
  };

  const getViewStatus = (state: string): string => {
    switch (state) {
      case 'today':
        return dataSliced.today ? 'View Less' : 'View More';

      case 'tomorrow':
        return dataSliced.tomorrow ? 'View Less' : 'View More';

      case 'upcoming days':
        return dataSliced.upcoming ? 'View Less' : 'View More';

      default:
        return '';
    }
  };

  const checkOccurrence = (card: string): boolean => {
    switch (card) {
      case 'today':
        return dataSliced.today;

      case 'tomorrow':
        return dataSliced.tomorrow;

      case 'upcoming days':
        return dataSliced.upcoming;

      default:
        return false;
    }
  };

  const EventsWrapper = (props: WrapperI) => {
    const { heading, data, occurrence } = props;
    const margin = {
      marginLeft: `5px`,
      color: !props.selected ? '#132e98' : 'white',
    };
    const mainStyle = {
      maxHeight: '400px',
      overflow: 'auto',
    };

    return (
      <Paper
        style={{
          ...tableStyle,
          backgroundColor: props.selected ? '#132e98' : 'white',
          boxShadow: props.selected
            ? 'none'
            : 'rgb(0 0 0 / 7%) 1px 1px 20px 1px',
        }}
      >
        <Box
          className={classes.formTitle}
          style={{
            position: 'static',
            margin: 0,
            marginBottom: 4,
            fontSize: '18px',
            color: 'white',
            fontWeight: 600,
          }}
        >
          <span style={{ color: props.selected ? 'white' : '#132e98' }}>
            {' '}
            {heading}{' '}
          </span>
        </Box>
        <Box style={mainStyle}>
          {data.length ? (
            data
              .slice(0, checkOccurrence(occurrence) ? data.length : 3)
              .map((element: OtherPropsI) => (
                <>
                  <Chip
                    color={props.selected ? 'default' : 'primary'}
                    label={element.from_date}
                    size="small"
                    style={{ fontSize: '11px' }}
                  />
                  <Grid>
                    <Typography
                      style={{ color: props.selected ? 'white' : '' }}
                      gutterBottom
                      variant="caption"
                    >
                      Title:
                    </Typography>
                    <Typography gutterBottom variant="caption" style={margin}>
                      {element.title}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      style={{ color: props.selected ? 'white' : '' }}
                      gutterBottom
                      variant="caption"
                    >
                      Description:
                    </Typography>
                    <Typography gutterBottom variant="caption" style={margin}>
                      {element.description}
                    </Typography>
                  </Grid>
                  <Divider
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      backgroundColor: props.selected ? 'white' : '',
                    }}
                  />
                </>
              ))
          ) : (
            <Typography
              gutterBottom
              variant="caption"
              style={{
                margin: 0,
                fontSize: '13px',
                color: props.selected ? 'white' : '#132e98',
                fontWeight: 500,
              }}
            >
              No events for {occurrence}
            </Typography>
          )}
        </Box>
        {data.length > 3 && (
          <Grid container justifyContent="center">
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => changeViewState(occurrence)}
            >
              {getViewStatus(occurrence)}
            </a>
          </Grid>
        )}
      </Paper>
    );
  };

  return (
    <>
      <EventsWrapper
        data={todayData}
        heading="Today's Events"
        occurrence="today"
        selected
      />
      <EventsWrapper
        data={tomData}
        heading="Tomorrow's Events"
        occurrence="tomorrow"
      />
      <EventsWrapper
        data={otherData}
        heading="Upcoming Events"
        occurrence="upcoming"
      />
    </>
  );
};
