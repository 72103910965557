export const GET_TEACHER_EXAMS_LOADING = "GET_TEACHER_EXAMS_LOADING";
export const GET_TEACHER_EXAMS_SUCCESS = "GET_TEACHER_EXAMS_SUCCESS";
export const GET_TEACHER_EXAMS_ERROR = "GET_TEACHER_EXAMS_ERROR";

export const GET_TEACHER_EXAM_SUBJECTS_LOADING =
  "GET_TEACHER_EXAM_SUBJECTS_LOADING";
export const GET_TEACHER_EXAM_SUBJECTS_SUCCESS =
  "GET_TEACHER_EXAM_SUBJECTS_SUCCESS";
export const GET_TEACHER_EXAM_SUBJECTS_ERROR =
  "GET_TEACHER_EXAM_SUBJECTS_ERROR";

export const GET_TEACHER_EXAM_MARKS_LOADING = "GET_TEACHER_EXAM_MARKS_LOADING";
export const GET_TEACHER_EXAM_MARKS_SUCCESS = "GET_TEACHER_EXAM_MARKS_SUCCESS";
export const GET_TEACHER_EXAM_MARKS_ERROR = "GET_TEACHER_EXAM_MARKS_ERROR";

export const ADD_TEACHER_EXAM_MARKS_LOADING = "ADD_TEACHER_EXAM_MARKS_LOADING";
export const ADD_TEACHER_EXAM_MARKS_SUCCESS = "ADD_TEACHER_EXAM_MARKS_SUCCESS";
export const ADD_TEACHER_EXAM_MARKS_ERROR = "ADD_TEACHER_EXAM_MARKS_ERROR";

export const CHANGE_FIELDS = "CHANGE_FIELDS"
export const RELOAD = "RELOAD"

export type T_ExamI = {
  id: string;
  exam_name: string;
  start_date: string;
  end_date: string;
  session: string;
  result_publish_date: string;
  has_weightage: boolean;
  weightage: number | null;
  general_info: number;
  created_on: string;
  modified_on: string;
  created_by: string;
}
export type T_ExamSubjectsI = {
  id: string;
  name: string;
  subject_info: {
    id: string;
    subject: string;
    subject_name: string;
    grade: string;
    subject_type: string;
    has_practical: boolean;
    subject_category: string;
    pass_check: string;
    credit_hours: string;
    subject_status: string;
    credit_hour_theory: number;
    credit_hour_practical: number | null;
    full_marks_theory: number;
    pass_marks_theory: number;
    full_marks_practical: number | null;
    pass_marks_practical: number | null;
  },
  exam_subject_info: {
    id: string;
    exam_info: string;
    exam_info_name: string;
    subject: string;
    subject_name: string;
    exam_date: string;
    grade: string;
    grade_name: string;
    full_marks_theory: number;
    pass_marks_theory: number;
    full_marks_practical: number | null;
    pass_marks_practical: number | null;
    start_time: string;
    duration: string;
  }
}
export type T_ExamMarksI = {
  student_id: string;
  roll_number: number;
  student_full_name: string;
  full_marks_theory: string;
  pass_marks_theory: string;
  marks_obtained_theory: string;
  marks_obtained_practical: string;
  remarks: string;
  grades: string;
}

//===============================<START>GET TEACHER EXAM<START>======================//

export interface GetTeacherExamsLoading {
  type: typeof GET_TEACHER_EXAMS_LOADING;
}

export interface GetTeacherExamsError {
  type: typeof GET_TEACHER_EXAMS_ERROR;
}

export interface GetTeacherExamsSuccess {
  type: typeof GET_TEACHER_EXAMS_SUCCESS;
  payload: T_ExamI[];
}
//===============================<END>GET TEACHER EXAM<END>======================//

//===============================<START>GET TEACHER EXAM SUBJECTS<START>======================//

export interface GetTeacherExamSubjectsLoading {
  type: typeof GET_TEACHER_EXAM_SUBJECTS_LOADING;
}

export interface GetTeacherExamSubjectsError {
  type: typeof GET_TEACHER_EXAM_SUBJECTS_ERROR;
}

export interface GetTeacherExamSubjectsSuccess {
  type: typeof GET_TEACHER_EXAM_SUBJECTS_SUCCESS;
  payload: T_ExamSubjectsI[];
}
//===============================<END>GET TEACHER EXAM SUBJECTS<END>======================//

//===============================<START>GET TEACHER EXAM MARKS<START>======================//

export interface GetTeacherExamMarksLoading {
  type: typeof GET_TEACHER_EXAM_MARKS_LOADING;
}

export interface GetTeacherExamMarksError {
  type: typeof GET_TEACHER_EXAM_MARKS_ERROR;
}

export interface GetTeacherExamMarksSuccess {
  type: typeof GET_TEACHER_EXAM_MARKS_SUCCESS;
  payload: T_ExamMarksI[];
}
//===============================<END>GET TEACHER EXAM MARKS<END>======================//

//===============================<START>ADD TEACHER EXAM MARKS<START>======================//

export interface AddTeacherExamMarksLoading {
  type: typeof ADD_TEACHER_EXAM_MARKS_LOADING;
}

export interface AddTeacherExamMarksError {
  type: typeof ADD_TEACHER_EXAM_MARKS_ERROR;
}

export interface AddTeacherExamMarksSuccess {
  type: typeof ADD_TEACHER_EXAM_MARKS_SUCCESS;
  payload: { message: string };
}
//===============================<END>ADD TEACHER EXAM MARKS<END>======================//

export interface ChangeFields {
  type: typeof CHANGE_FIELDS;
  payload: { id: string; field: "marksTheory" | "marksPractical" | "remarks", value: string }
}
export interface Reload {
  type: typeof RELOAD;
}

export type TeacherAddMarksDispatchTypes =
  | GetTeacherExamsLoading
  | GetTeacherExamsError
  | GetTeacherExamsSuccess
  | GetTeacherExamSubjectsLoading
  | GetTeacherExamSubjectsError
  | GetTeacherExamSubjectsSuccess
  | GetTeacherExamMarksLoading
  | GetTeacherExamMarksError
  | GetTeacherExamMarksSuccess
  | AddTeacherExamMarksLoading
  | AddTeacherExamMarksError
  | AddTeacherExamMarksSuccess
  | ChangeFields
  | Reload;

