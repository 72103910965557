import { useState } from "react";
import { Box } from "@material-ui/core";
import AssignmentList from "./AssignmentList";
import AssignmentSearch from "./AssignmentSearch";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";

const ChildrenAssignmentPage = () => {
  const [children, setChildren] = useState<any | null>(null);

  return (
    <Box>
      <PageHeader title="Children Assigment" />

      <PageWrapper>
        <AssignmentSearch state={{ children, setChildren }} />
        <AssignmentList optionState={children} />
      </PageWrapper>
    </Box>
  );
};

export default ChildrenAssignmentPage;
