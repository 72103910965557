import StudentAssignmentList from "./StudentAssignmentList"
import StudentAssignmentSearch from "./StudentAssignmentSearch"
import { Box } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import { useLocation } from "react-router-dom";
import { StudentMyTeacherTypeI } from "../../../../actions/StudentDash/StudentMyTeacher/StudentTeacherActionTypes"

interface LocationState {
  detail: StudentMyTeacherTypeI
}

const StudentSubjectPage = () => {
  const { state: { detail } } = useLocation<LocationState>();
  return (
    <Box>
      <PageHeader title={`${detail.subject} Assignment`} />
      <PageWrapper>
        <StudentAssignmentSearch />
        <StudentAssignmentList />
      </PageWrapper>
    </Box>
  )
}

export default StudentSubjectPage