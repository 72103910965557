import { Box, Grid } from "@material-ui/core";
import React, { useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import LiveClassesList from "./components/LiveClassesList";
import SelectedListItem from "./components/LiveClasssesSidenav";

const TeacherLiveClassesPage = () => {
  const [classData, setClassData] = useState<any | null>(null);

  const fetchClassData = (data: any) => {
    setClassData(data);
  };
  return (
    <>
      <Box>
        <PageHeader
          title="Online Classes"
          module={{ name: "Academics", to: "/online-classes" }}
        />
        <PageWrapper>
          <Grid container>
            <Grid item xs={3}>
              <SelectedListItem onClassData={fetchClassData} />
            </Grid>
            <Grid item xs={9}>
              <LiveClassesList classData={classData} />
            </Grid>
          </Grid>
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherLiveClassesPage;
