import {
  GET_STUDENT_ASSIGNMENT_LOADING,
  GET_STUDENT_ASSIGNMENT_ERROR,
  GET_STUDENT_ASSIGNMENT_SUCCESS,
  SUBMIT_STUDENT_ASSIGNMENT_LOADING,
  SUBMIT_STUDENT_ASSIGNMENT_SUCCESS,
  SUBMIT_STUDENT_ASSIGNMENT_ERROR,
  GET_STUDENT_ASSIGNMENT_BY_ID_LOADING,
  GET_STUDENT_ASSIGNMENT_BY_ID_ERROR,
  GET_STUDENT_ASSIGNMENT_BY_ID_SUCCESS,
  GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_ERROR,
  GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_LOADING,
  GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_SUCCESS,
  StudentAssignmentDispatchTypes,
  StudentAssignmentType,
} from "../../actions/StudentDash/StudentAssignment/StudentAssignmentActionTypes";

interface InitialStateI {
  loading: boolean;
  student_assignment: StudentAssignmentType[];
  assignmentbyID: any;
  errors: any;
  submit_assignment: any;
  redo_assignment: any;
  has_submitted: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  student_assignment: [],
  assignmentbyID: null,
  errors: null,
  submit_assignment: [],
  redo_assignment: [],
  has_submitted: false,
};

const StudentAssignmentReducer = (
  state: InitialStateI = initialState,
  action: StudentAssignmentDispatchTypes
): any => {
  switch (action.type) {
    case GET_STUDENT_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        student_assignment: action.payload,
      };

    case SUBMIT_STUDENT_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        has_submitted: false,
      };

    case SUBMIT_STUDENT_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        has_submitted: false,
      };

    case SUBMIT_STUDENT_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        submit_assignment: action.payload,
        has_submitted: true,
      };

    case GET_STUDENT_ASSIGNMENT_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        assignmentbyID: null,
      };

    case GET_STUDENT_ASSIGNMENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        assignmentbyID: action.payload,
      };

    case GET_STUDENT_ASSIGNMENT_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        redo_assignment: null,
      };

    case GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        redo_assignment: action.payload,
      };

    case GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default StudentAssignmentReducer;
// GET_STUDENT_SUBMITTED_ASSIGNMENT_BY_ID_ERROR
