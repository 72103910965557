import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useCardStyles } from "../../../../../components/Styles/CardStyles";

export default function StudentDashTransportation(props: any) {
  const classes = useCardStyles();

  // const {
  //   route_from,
  //   route_to,
  //   pickup_time,
  //   drop_time,
  //   fare,
  //   driver,
  //   bus_number,
  //   seat,
  // } = props?.student?.transportation_detail;

  const rows = [
    createData(
      "Route from:",
      props?.student?.transportation_detail?.route_from
    ),
    createData("Route to:", props?.student?.transportation_detail?.route_to),
    createData(
      "Pickup time:",
      props?.student?.transportation_detail?.pickup_time
    ),
    createData("Drop time:", props?.student?.transportation_detail?.drop_time),
    createData("Fare:", props?.student?.transportation_detail?.fare),
    createData("Driver:", props?.student?.transportation_detail?.driver),
    createData(
      "Bus number:",
      props?.student?.transportation_detail?.bus_number
    ),
    createData("Seat:", props?.student?.transportation_detail?.seat),
  ];

  function createData(name: string, infoData: string) {
    return { name, infoData };
  }

  return (
    <>
      <Paper className={classes.infobar} elevation={0}>
        <TableContainer className={classes.roota}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.table}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.infoData}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
