export const STUDENT_SEARCH_LOADING = "STUDENT_SEARCH_LOADING";
export const STUDENT_SEARCH_ERROR = "STUDENT_SEARCH_ERROR";
export const STUDENT_SEARCH_SUCCESS = "STUDENT_SEARCH_SUCCESS";

export interface StudentListTypeI {
  id: number;
  grade: number;
  section: number;
  student_first_name: string;
  student_last_name: string;
  roll_number: number | null;
}

//===============================<START>STUDENT SEARCH<START>======================//

export interface StudentSearchLoading {
  type: typeof STUDENT_SEARCH_LOADING;
}

export interface StudentSearchError {
  type: typeof STUDENT_SEARCH_ERROR;
}

export interface StudentSearchSuccess {
  type: typeof STUDENT_SEARCH_SUCCESS;
  payload: StudentListTypeI[];
}

//===============================<END>STUDENT SEARCH<END>======================//
export type StudentDispatchTypes =
  | StudentSearchLoading
  | StudentSearchError
  | StudentSearchSuccess;
