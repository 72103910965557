import {
  GET_LESSON_PLAN_LOADING,
  GET_LESSON_PLAN_ERROR,
  GET_LESSON_PLAN_SUCCESS,
  ADD_LESSON_PLAN_LOADING,
  ADD_LESSON_PLAN_ERROR,
  ADD_LESSON_PLAN_SUCCESS,
  UPDATE_LESSON_PLAN_LOADING,
  UPDATE_LESSON_PLAN_ERROR,
  UPDATE_LESSON_PLAN_SUCCESS,
  DELETE_LESSON_PLAN_LOADING,
  DELETE_LESSON_PLAN_ERROR,
  DELETE_LESSON_PLAN_SUCCESS,
  LessonPlanDispatchTypes,
} from "../../../actions/TeacherDash/LessonPlan/LessonPlanActionTypes";

interface InitialStateI {
  loading: boolean;
  lesson_plan: any;
  recent: any;
  error: any;
}

const initialState: InitialStateI = {
  loading: false,
  lesson_plan: [],
  recent: null,
  error: null,
};

const TeacherLessonPlanReducer = (
  state: InitialStateI = initialState,
  action: LessonPlanDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_LESSON_PLAN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_LESSON_PLAN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        lesson_plan: action.payload,
        recent: null,
      };

    case ADD_LESSON_PLAN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_LESSON_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        lesson_plan: [...state.lesson_plan, action.payload],
        recent: action.payload,
        // single_unit: action.payload,
      };

    case UPDATE_LESSON_PLAN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_LESSON_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_LESSON_PLAN_SUCCESS:
      const current_data: any[] = state.lesson_plan;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        lesson_plan: current_data,
      };

    case DELETE_LESSON_PLAN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_LESSON_PLAN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_LESSON_PLAN_SUCCESS:
      const new_data: any[] = state.lesson_plan;
      const item_index = new_data.findIndex(
        (element) => element.unit.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return { ...state, loading: false, lesson_plan: new_data };

    default:
      return state;
  }
};

export default TeacherLessonPlanReducer;
