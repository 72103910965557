import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import { HeadCellsI } from "../../../../../actions";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import cx from "clsx";
import useTable from "../../../../../components/Reusable/useStudentTable";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteSchool,
  GetSchools,
} from "../../../../../actions/SuperAdmin/School/SchoolAction";
import { RootStore } from "../../../../../store";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from "../../../../../components/Reusable/Buttons/TableButton";
import { SchoolListI } from "../../../../../actions/SuperAdmin/School/SchoolActionTypes";
import CustomizedDialogs from "../../../../../components/Reusable/Dialogs/DeleteDialog";
import { useTableWithPagination } from "../../../../../components/Reusable";
import getFullName from "../../../../../components/utils/getFullName";

const headCells: HeadCellsI[] = [
  // { id: "sn", label: "S.N." },
  { id: "name", label: "School Name", align: "left", filter: true },
  { id: "address", label: "Address" },
  { id: "admin", label: "Administrator" },
  { id: "phone", label: "Phone No." },
  { id: "students", label: "Total Students" },
  { id: "staffs", label: "Total Staffs" },
  { id: "action", label: "Actions" },
];

const SchoolList = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);

  const schoolList = useSelector(
    (state: RootStore) => state.sa_schools.schools
  );
  const [tableData, setTableData] = useState<SchoolListI[]>([]);

  React.useEffect(() => {
    dispatch(GetSchools(1));
    props.setSchoolData(null);
  }, []);

  React.useEffect(() => {
    if (schoolList.results != null) {
      const data: any = [];
      schoolList.results.map((elem: any, index: number) => {
        data.push({
          key: index + 1,
          id: elem.id,
          admin_phone: "9811050526",
          admin_email: "ashdfjcgv@oo.com",
          admin_full_name: "Ram Bdr.",
          logo: elem.logo,
          school_reg_number: elem.school_reg_number,
          slogan: elem.slogan,
          school_name: elem.school_name,
          address: elem.address,
          school_email: elem.email,
          admin: getFullName(
            elem.user_general_info[0].first_name,
            elem.user_general_info[0].last_name
          ),
          phone_number: elem.phone_number,
          students: elem.total_students,
          staffs: elem.total_staffs,
        });
      });
      setTableData(data);
    }
  }, [schoolList.results]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const handleEditClicked = (data: SchoolListI) => {
    props.setSchoolData(data);
    props.onCancel();
  };

  const handleSchoolView = (data: SchoolListI) => {
    console.log("none");
  };

  // Delete record modal
  const handleDeleteModal = (value: boolean, id: number) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteSchool(itemId));
    setDeleteModalOpen(false);
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(GetSchools(paginationIndex));
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        {/* <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell> */}
        <StyledTableCell align="left" className={classes.cell}>
          {item.school_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.address}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.admin}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.phone_number}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.students}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.staffs}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {/* <ItemViewButton onClick={() => handleSchoolView(item)} /> */}
          <ItemEditButton onClick={() => handleEditClicked(item)} />
          {/* <ItemDeleteButton onClick={() => handleDeleteModal(true, item.id)} /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper
        elevation={0}
        className={cx(classes.root, classes.attachFormTableRoot)}
        style={{ padding: "8px 16px 16px 8px" }}
      >
        <TableContainer
          label="School List"
          loading={schoolList?.loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          forPagination={schoolList}
          search_name="name"
          placeholder="Search by School Name..."
          hiddenCols={[-1]}
          disableSearch
          handlePaginationChange={handlePaginationChange}
        />
      </Paper>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
};

export default SchoolList;
