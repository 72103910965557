export const GET_STUDENT_LEAVE_LOADING = "GET_STUDENT_LEAVE_LOADING";
export const GET_STUDENT_LEAVE_ERROR = "GET_STUDENT_LEAVE_ERROR";
export const GET_STUDENT_LEAVE_SUCCESS = "GET_STUDENT_LEAVE_SUCCESS";

export const ADD_STUDENT_LEAVE_LOADING = "ADD_STUDENT_LEAVE_LOADING";
export const ADD_STUDENT_LEAVE_ERROR = "ADD_STUDENT_LEAVE_ERROR";
export const ADD_STUDENT_LEAVE_SUCCESS = "ADD_STUDENT_LEAVE_SUCCESS";

export const UPDATE_STUDENT_LEAVE_LOADING = "UPDATE_STUDENT_LEAVE_LOADING";
export const UPDATE_STUDENT_LEAVE_ERROR = "UPDATE_STUDENT_LEAVE_ERROR";
export const UPDATE_STUDENT_LEAVE_SUCCESS = "UPDATE_STUDENT_LEAVE_SUCCESS";

export const DELETE_STUDENT_LEAVE_LOADING = "DELETE_STUDENT_LEAVE_LOADING";
export const DELETE_STUDENT_LEAVE_ERROR = "DELETE_STUDENT_LEAVE_ERROR";
export const DELETE_STUDENT_LEAVE_SUCCESS = "DELETE_STUDENT_LEAVE_SUCCESS";

export interface StudentLeaveTypeI {
  id: number;
  leave_type: number;
  status: string;
  status_name: string;
  leave_name: string;
  leave_from: string;
  leave_to: string;
  applied_date: string;
  general_info: number;
  reason_for_leave: string;
  no_of_days: string;
  first_name: string;
  last_name: string;
  student_academic: string | number;
}
export interface StudentLeaveII {
  leave_type: number;
  leave_from: string;
  leave_to: string;
  reason_for_leave: string;
  student_academic: number;
}
//===============================<START>GET student_leave<START>======================//

export interface GetStudentLeaveLoading {
  type: typeof GET_STUDENT_LEAVE_LOADING;
}

export interface GetStudentLeaveError {
  type: typeof GET_STUDENT_LEAVE_ERROR;
}

export interface GetStudentLeaveSuccess {
  type: typeof GET_STUDENT_LEAVE_SUCCESS;
  payload: StudentLeaveTypeI[];
}

//===============================<END>GET student_leave<END>======================//

//===============================<START>ADD student_leave<START>======================//

export interface AddStudentLeaveLoading {
  type: typeof ADD_STUDENT_LEAVE_LOADING;
}

export interface AddStudentLeaveError {
  type: typeof ADD_STUDENT_LEAVE_ERROR;
  payload: any;
}

export interface AddStudentLeaveSuccess {
  type: typeof ADD_STUDENT_LEAVE_SUCCESS;
  payload: StudentLeaveTypeI;
}

//===============================<END>Add student_leave<END>======================//

//===============================<START>UPDATE STUDENT_LEAVE<START>======================//

export interface UpdateStudentLeaveLoading {
  type: typeof UPDATE_STUDENT_LEAVE_LOADING;
}

export interface UpdateStudentLeaveError {
  type: typeof UPDATE_STUDENT_LEAVE_ERROR;
  payload: any;
}

export interface UpdateStudentLeaveSuccess {
  type: typeof UPDATE_STUDENT_LEAVE_SUCCESS;
  payload: StudentLeaveTypeI;
}

//===============================<END>UPDATE STUDENT_LEAVE<END>======================//

//===============================<START>DELETE STUDENT_LEAVE<START>======================//

export interface DeleteStudentLeaveLoading {
  type: typeof DELETE_STUDENT_LEAVE_LOADING;
}

export interface DeleteStudentLeaveError {
  type: typeof DELETE_STUDENT_LEAVE_ERROR;
}

export interface DeleteStudentLeaveSuccess {
  type: typeof DELETE_STUDENT_LEAVE_SUCCESS;
  payload: { id: number };
}

//===============================<END>DELETE STUDENT_LEAVE<END>======================//

export type StudentLeaveDispatchTypes =
  | GetStudentLeaveLoading
  | GetStudentLeaveError
  | GetStudentLeaveSuccess
  | AddStudentLeaveLoading
  | AddStudentLeaveError
  | AddStudentLeaveSuccess
  | UpdateStudentLeaveLoading
  | UpdateStudentLeaveError
  | UpdateStudentLeaveSuccess
  | DeleteStudentLeaveLoading
  | DeleteStudentLeaveError
  | DeleteStudentLeaveSuccess;
