// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from 'react';
import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import 'nepali-datepicker-reactjs/dist/index.css';
import LeaveRequestModal from './LeaveRequestModal';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import CustomizedTextField from '../../../../components/Reusable/Inputs/TextField';
import CustomizedNepaliDatePicker from '../../../../components/Reusable/Inputs/NepaliDatePicker';
import AddNewButton from '../../../../components/Reusable/Buttons/AddNewButton.test';
import FormButton from '../../../../components/Reusable/Buttons/FormButton';
import CustomizedSelect, {
  InputBox,
} from '../../../../components/Reusable/Inputs/Select';
import { RootStore } from '../../../../store';
import { GetLeaveTypes } from '../../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import { Tupple } from '../../../../actions';
import { dateConverterBsToAd } from '../../../../components/utils/dateConverter';
import { GetChildren } from '../../../../actions/ParentsDash/ViewChild/ViewChildAction';
import getFullName from '../../../../components/utils/getFullName';
import { CUR_NEPALI_DATE } from '../../../../components/utils/nepaliDateUtils';
import { GetSearchedLeaves } from '../../../../actions/ParentsDash/ParentLeave/ParentLeaveAction';
import SearchFormHeaderLayout from '../../../../components/Reusable/Layouts/SearchFormHeaderLayout';
import { Autocomplete } from '@material-ui/lab';
import { SubmitButton } from '../../../../components/Reusable/Buttons/SearchButton';

// -----------------<END> imports module ends -----------------------//

// -----------------<START> Leave Search component starts-----------------------//
const LeaveSearch = (props: any) => {
  const { academicID } = props;

  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { register, handleSubmit, errors } = useForm();

  const [fromDate, setFromDate] = useState<string>(CUR_NEPALI_DATE);
  const [toDate, setToDate] = useState<string>(CUR_NEPALI_DATE);
  const [leaveType, setLeaveType] = useState<Tupple | null>(null);
  const [selectedChild, setSelectedChild] = useState<Tupple | null>(null);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    dispatch(GetLeaveTypes());
    dispatch(GetChildren());
  }, []);

  const leaveTypeState = useSelector((state: RootStore) => state.leave_type);
  const childState = useSelector((state: RootStore) => state.view_child);

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const onSubmit = (data: any) => {
    const searchData = {
      // from: (fromDate && dateConverterBsToAd(fromDate)) || "",
      // to: (toDate && dateConverterBsToAd(toDate)) || "",
      leaveType: leaveType ? leaveType.key : '',
      child: selectedChild ? selectedChild.key : '',
    };
    dispatch(GetSearchedLeaves(searchData));
  };

  const LEAVE_TYPE_CHOICES = leaveTypeState.leave_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  const SELECT_CHILD_CHOICES = childState.view_child.map(
    (item: {
      id: number;
      student_first_name: string;
      student_last_name: string;
    }) => ({
      key: item.id.toString(),
      value: getFullName(item.student_first_name, item.student_last_name),
    })
  );

  const handleReset = () => {
    setLeaveType(null);
    setSelectedChild(null);
    dispatch(GetSearchedLeaves({ leaveType: '', child: '' }));
  };

  return (
    <>
      <Paper
        className={classes.pageAttachContent}
        elevation={0}
        style={{
          borderBottomColor: '#f5f5f5',
          padding: '16px',
          paddingTop: '24px',
        }}
      >
        <SearchFormHeaderLayout
          title="Leave Requests"
          btnTitle="Request New Leave"
          reset={handleReset}
          onClick={() => setOpenPopup(true)}
        />
        {/* <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={2} className={classes.formWrapper}>
              <CustomizedNepaliDatePicker
                label="From"
                value={fromDate}
                setValue={setFromDate}
              />
            </Grid>

            <Grid item xs={2} className={classes.formWrapper}>
              <CustomizedNepaliDatePicker
                label="To"
                value={toDate}
                setValue={setToDate}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedSelect
                label="Leave Type"
                name="leaveType"
                options={LEAVE_TYPE_CHOICES}
                value={leaveType}
                setValue={setLeaveType}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapperRow}>
              <InputBox>Type</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                onChange={(event: React.ChangeEvent<{}>, value: any) =>
                  setLeaveType(value)
                }
                options={LEAVE_TYPE_CHOICES}
                value={leaveType}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="leave_type"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedSelect
                label="Select Child"
                name="child_name"
                options={SELECT_CHILD_CHOICES}
                value={selectedChild}
                setValue={setSelectedChild}
              />
            </Grid>

            <Grid item xs={2} className={classes.formWrapper}>
              <FormButton
                startIcon={<Search />}
                className={classes.searchBoxButton}
              >
                Search
              </FormButton>
            </Grid>

            <SubmitButton style={{ marginTop: "5px" }} />
          </Grid>
        </form> */}

        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
          title="Request Leave"
          maxWidth="sm"
        >
          <LeaveRequestModal onClose={handleClose} academicID={academicID} />
        </Popups>
      </Paper>
    </>
  );
};

//-----------------<ENDS> Leave Search component ends-----------------------//
export default LeaveSearch;
