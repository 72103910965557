import {
  GET_STUDENT_MY_COMPLAIN_LOADING,
  GET_STUDENT_MY_COMPLAIN_ERROR,
  GET_STUDENT_MY_COMPLAIN_SUCCESS,
  ADD_STUDENT_MY_COMPLAIN_LOADING,
  ADD_STUDENT_MY_COMPLAIN_ERROR,
  ADD_STUDENT_MY_COMPLAIN_SUCCESS,
  DELETE_STUDENT_MY_COMPLAIN_LOADING,
  DELETE_STUDENT_MY_COMPLAIN_ERROR,
  DELETE_STUDENT_MY_COMPLAIN_SUCCESS,
  UPDATE_STUDENT_MY_COMPLAIN_LOADING,
  UPDATE_STUDENT_MY_COMPLAIN_ERROR,
  UPDATE_STUDENT_MY_COMPLAIN_SUCCESS,
  StudentMyComplainDispatchTypes,
  StudentMyComplainTypeI,
} from "../../actions/StudentDash/MyComplains/MyComplainsActionTypes";

interface InitialStateI {
  loading: boolean;
  student_my_complains: StudentMyComplainTypeI[];
  errors: any;
}

const initialState: InitialStateI = {
  loading: false,
  student_my_complains: [],
  errors: null,
};

const StudentMyComplainsReducer = (
  state: InitialStateI = initialState,
  action: StudentMyComplainDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STUDENT_MY_COMPLAIN_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };

    case GET_STUDENT_MY_COMPLAIN_ERROR:
      return {
        ...state,
        errors: null,
        loading: false,
      };

    case GET_STUDENT_MY_COMPLAIN_SUCCESS:
      return {
        loading: false,
        errors: null,
        student_my_complains: action.payload,
      };

    case ADD_STUDENT_MY_COMPLAIN_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };
    case ADD_STUDENT_MY_COMPLAIN_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case ADD_STUDENT_MY_COMPLAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        student_my_complains: [...state.student_my_complains, action.payload],
      };

    case UPDATE_STUDENT_MY_COMPLAIN_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };
    case UPDATE_STUDENT_MY_COMPLAIN_ERROR:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case UPDATE_STUDENT_MY_COMPLAIN_SUCCESS:
      const current_data: StudentMyComplainTypeI[] = state.student_my_complains;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        errors: null,
        student_my_complains: current_data,
      };

    case DELETE_STUDENT_MY_COMPLAIN_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };
    case DELETE_STUDENT_MY_COMPLAIN_ERROR:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case DELETE_STUDENT_MY_COMPLAIN_SUCCESS:
      const new_data: StudentMyComplainTypeI[] = state.student_my_complains;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        errors: null,
        loading: false,
        student_my_complains: new_data,
      };

    default:
      return state;
  }
};

export default StudentMyComplainsReducer;
