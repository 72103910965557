export const GET_STUDENT_DASHBOARD_DATA_LOADING =
  "GET_STUDENT_DASHBOARD_DATA_LOADING";
export const GET_STUDENT_DASHBOARD_DATA_ERROR =
  "GET_STUDENT_DASHBOARD_DATA_ERROR";
export const GET_STUDENT_DASHBOARD_DATA_SUCCESS =
  "GET_STUDENT_DASHBOARD_DATA_SUCCESS";

export const GET_STUDENT_DASHBOARD_DATE_LOADING =
  "GET_STUDENT_DASHBOARD_DATE_LOADING";
export const GET_STUDENT_DASHBOARD_DATE_ERROR =
  "GET_STUDENT_DASHBOARD_DATE_ERROR";
export const GET_STUDENT_DASHBOARD_DATE_SUCCESS =
  "GET_STUDENT_DASHBOARD_DATE_SUCCESS";

export interface StudentDashboardAssignmentI {
  [x: string]: any;
}

export interface StudentDashboardLiveClassI {
  id: string;
  start_time: string;
  end_time: string;
  subject_name: string;
  teacher_first_name: string;
  teacher_last_name: string;
  is_completed: boolean;
  is_upcoming: boolean;
  is_ongoing: boolean;
}

export interface StudentDashboardSubjectI {
  id: string;
  subject_name: string;
}

export interface StudentDashboardDataTypeI {
  assignment: StudentDashboardAssignmentI[];
  live_class: StudentDashboardLiveClassI[];
  subject: StudentDashboardSubjectI[];
}
//===============================<START>GET student_leave<START>======================//

export interface GetStudentDashboardDataLoading {
  type: typeof GET_STUDENT_DASHBOARD_DATA_LOADING;
}

export interface GetStudentDashboardDataError {
  type: typeof GET_STUDENT_DASHBOARD_DATA_ERROR;
}

export interface GetStudentDashboardDataSuccess {
  type: typeof GET_STUDENT_DASHBOARD_DATA_SUCCESS;
  payload: StudentDashboardDataTypeI;
}

//===============================<END>GET student_leave<END>======================//

//===============================<START>GET student_leave<START>======================//

export interface GetStudentDashboardDateLoading {
  type: typeof GET_STUDENT_DASHBOARD_DATE_LOADING;
}

export interface GetStudentDashboardDateError {
  type: typeof GET_STUDENT_DASHBOARD_DATE_ERROR;
}

export interface GetStudentDashboardDateSuccess {
  type: typeof GET_STUDENT_DASHBOARD_DATE_SUCCESS;
  payload: StudentDashboardDataTypeI;
}

//===============================<END>GET student_leave<END>======================//

export type StudentDashboardDataDispatchTypes =
  | GetStudentDashboardDataLoading
  | GetStudentDashboardDataError
  | GetStudentDashboardDataSuccess
  | GetStudentDashboardDateLoading
  | GetStudentDashboardDateError
  | GetStudentDashboardDateSuccess;
