import { Grid, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Tupple } from '../../../../../../actions';
import { GetMeetingTypes } from '../../../../../../actions/HumanResource/MeetingType/MeetingTypeAction';
import { AddTeacherMeeting } from '../../../../../../actions/TeacherDash/TeacherMeeting/TeacherMeetingAction';
import { RootStore } from '../../../../../../store';
import { FormSendButon } from '../../../../../../components/Reusable/Buttons/FormButton';
import CustomizedNepaliDatePicker from '../../../../../../components/Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect from '../../../../../../components/Reusable/Inputs/Select';
import CustomizedTextField from '../../../../../../components/Reusable/Inputs/TextField';
import {
  useFormStyles,
  useStyles,
} from '../../../../../../components/Styles/FormStyles';
import { dateConverterBsToAd } from '../../../../../../components/utils/dateConverter';
import firstWordCapital from '../../../../../../components/utils/firstWordCapital';
import { CUR_NEPALI_DATE } from '../../../../../../components/utils/nepaliDateUtils';
import { isObjectEmpty } from '../../../../../../components/utils/utils';

const TeacherMeetingAdd = (props: any) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const [dateFrom, setDateFrom] = useState<string>(CUR_NEPALI_DATE);
  const [meetingDate, setMeetingDate] = useState<string>(CUR_NEPALI_DATE);
  const [meetingType, setMeetingType] = useState<Tupple | null>(null);
  const [serverErrors, setServerErrors] = useState<any>(null);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [errorFields] = useState<string[]>(['error', 'non_field_errors']);

  const { handleSubmit, register, errors, setError, clearErrors } =
    useForm<any>({
      mode: 'onChange',
    });

  useEffect(() => {
    dispatch(GetMeetingTypes());
  }, []);

  const meetingTypeState = useSelector(
    (state: RootStore) => state.meeting_type
  );
  const teacherMeeting = useSelector(
    (state: RootStore) => state.teacher_meeting
  );
  const errorsData = teacherMeeting.errors;
  const loading = teacherMeeting.loading;
  const initialErrorsData = useRef(errorsData);

  const MEETING_TYPE_CHOICES = meetingTypeState.meeting_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  /* Close modal when user has submitted form, if state is not loading,
  has not server errors and has no hook-form errors */
  useEffect(() => {
    if (hasSubmitted && !loading) {
      if (!errorsData && isObjectEmpty(errors)) {
        props.onClose();
      }
    }
  }, [teacherMeeting, hasSubmitted]);

  // Set hook-form error on server error
  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData != null && errorsData?.error) {
        const keys = Object.keys(errorsData.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  // handle form submit
  const onSubmit = (data: any) => {
    if (data && meetingDate && meetingType) {
      const meetingData = {
        meeting_type: meetingType.key,
        meet_date: dateConverterBsToAd(dateFrom),
        meet_time: data.meet_time,
        description: data.description,
      };
      dispatch(AddTeacherMeeting(meetingData));
      setHasSubmitted(true);
    }
  };

  useEffect(() => {
    errorFields.map((field) => {
      clearErrors(field);
    });
  }, [dateFrom, meetingDate]);

  const handleDateFrom = (value: any) => {
    setDateFrom(value?.toString());
    clearErrors('meeting_from');
  };

  const handleDateTo = (value: any) => {
    setMeetingDate(value?.toString());
    clearErrors('meeting_to');
  };

  return (
    <>
      <Paper elevation={0}>
        <div className={classes.modalContent}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            {serverErrors &&
              serverErrors?.error &&
              Object.keys(serverErrors.error)
                .filter((elem: any) => errorFields.includes(elem))
                .map((elem: any, index: number) => {
                  return (
                    <div className={classes.serversidemessages} key={index}>
                      <Alert severity="error">
                        {firstWordCapital(serverErrors?.error[elem][0])}
                      </Alert>
                    </div>
                  );
                })}

            <Grid container>
              <Grid container>
                <Grid item xs={6} className={classes.formWrapper}>
                  <CustomizedSelect
                    label="Meeting Type"
                    name="meeting_type"
                    options={MEETING_TYPE_CHOICES}
                    value={meetingType}
                    setValue={setMeetingType}
                    required
                    error={errors['meeting_type']?.message}
                    inputRef={register({
                      required: 'Meeting type must be selected.',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} item xs={12}>
                <Grid item xs={6} className={classes.formWrapper}>
                  <CustomizedNepaliDatePicker
                    label="Meeting Date"
                    value={dateFrom}
                    setValue={handleDateFrom}
                    name="meet_date"
                    required
                    error={errors['meet_date']?.message}
                    inputRef={register({
                      required: true,
                    })}
                  />
                </Grid>

                <Grid item xs={6} className={classes.formWrapper}>
                  <CustomizedTextField
                    label="Meeting Time"
                    placeholder="Label"
                    name="meet_time"
                    required
                    type="time"
                    error={errors['meet_time']?.message}
                    inputRef={register({
                      required: 'Meeting time is required.',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.formWrapper}>
                <CustomizedTextField
                  label="Description"
                  placeholder="Label"
                  name="description"
                  multiline
                  rows={4}
                  required
                  error={errors['description']?.message}
                  inputRef={register({
                    required: 'Description is required.',
                  })}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-end"
              style={{ paddingBottom: '8px' }}
            >
              <FormSendButon disabled={loading} />
            </Grid>
          </form>
        </div>
      </Paper>
    </>
  );
};

export default TeacherMeetingAdd;
