import { Grid, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { AlertTitle, Alert } from '@material-ui/lab';
import clsx from 'clsx';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { timeFormat } from '../../../../components/utils/dateTimeFormat';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { countdownToday } from '../../../../components/utils/countdownTimer';
import { RootStore } from '../../../../store';
import { URL } from '../../../../config/SERVER_API_URLS';
import { useHistory } from 'react-router-dom';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import LiveClassConfirm from '../../pages/LiveClasses/components/LiveClassConfirm';

interface UserI {
  name: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100% !important',
      backgroundColor: theme.palette.background.paper,
      marginBottom: '0.5rem',
      height: 'auto',
    },
    title: {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    titleText: {
      fontSize: '16px',
      fontWeight: 600,
      display: 'block',
      marginBottom: '-1px',
      color: 'rgba(0,0,0,0.85)',
    },
    titleDate: {
      fontSize: '13px',
      fontWeight: 400,
      color: '#1d4698',
    },
    itemTitle: {
      fontWeight: 500,
      marginBottom: 5,
      display: 'inline-block',
    },
    alertTitle: {
      fontSize: 14,
      marginBottom: 0,
      fontWeight: 600,
    },
    btnGrp: {
      borderColor: 'transparent',
      '& > button': {
        textTransform: 'capitalize',
        borderColor: 'transparent',
        borderRadius: '4px!important',
        borderRight: 'none!important',
      },
    },
    alertRoot: {
      width: '100%',
      color: 'rgba(0,0,0,.8)',
      borderRadius: 8,
      borderLeft: '8px solid #3fc581',
      background: 'rgb(72 197 129 / 10%)',
      '& > div': {
        width: '100%',
        padding: '4px 0',
      },
    },
    alertTime: {
      fontWeight: 600,
      fontSize: 13,
      color: '#1d4698',
      opacity: '0.9',
    },
    alertStatus: {
      display: 'flex',
      alignItems: 'center',
      gap: '3px',
      textDecoration: 'none',
      color: '#FF3F00',
    },
    alertDanger: {
      borderLeft: '8px solid #e96c5a',
      background: 'rgb(233 108 90 / 0.1)',
    },
    alertWarning: {
      borderLeft: '8px solid #f2e82c',
      background: 'rgb(242 232 54 / 15%)',
    },
  })
);

export default function TeacherLiveClassesWidget(props: any) {
  const classes = useStyles();
  const { onlineClass, getToday, student } = props;
  const [today, setToday] = useState<any>([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [title, setTitle] = useState('');
  const [meetLink, setMeetLink] = useState('');
  const history = useHistory();

  useEffect(() => {
    const data: any = [];
    if (onlineClass?.length) {
      onlineClass.map((item: any) => {
        data.push({
          ...item,
          timeRemain: countdownToday(item.start_time),
          endTimeCheck: countdownToday(item.end_time),
        });
        if (getToday) {
          setToday(data);
        } else {
          setToday(onlineClass);
        }
      });
    } else {
      setToday([]);
    }
  }, [onlineClass]);

  const [user, setUser] = useState<UserI | null>(null);
  const auth = useSelector((state: RootStore) => state.auth);
  const teacherProfileState = useSelector(
    (state: RootStore) => state.teacher_profile.teacher_profile
  );

  useEffect(() => {
    if (auth) {
      setUser({ name: auth.user.first_name + ' ' + auth.user.last_name });
    }
  }, [auth]);

  const liveClassTimeCheck = (item: any) => {
    if (item.timeRemain) {
      return (
        <b className={classes.alertTime}>
          {`${
            item.timeRemain.days > 0 ? item.timeRemain.days + ' ' + 'days' : ''
          } ${
            item.timeRemain.hours > 0
              ? item.timeRemain.hours + ' ' + 'hours'
              : ''
          }  ${item.timeRemain.minutes} minutes remaining`}
        </b>
      );
    } else {
      return (
        <span className={classes.alertTime}>
          {timeFormat(item.start_time)} - {timeFormat(item.end_time)}
        </span>
      );
    }
  };

  const liveClassStatusCheck = (getToday: boolean, item: any) => {
    switch (student) {
      case true:
        if (getToday && item.timeRemain) {
          return (
            <span
              style={{
                color: '#e96c5a',
              }}
            >
              Upcoming
            </span>
          );
        } else if (getToday && item.endTimeCheck) {
          return (
            <Tooltip title="Go To Live classes">
              <span
                onClick={() => history.push('./live-classes')}
                className={classes.alertStatus}
                style={{ cursor: 'pointer' }}
              >
                Running
              </span>
            </Tooltip>
          );
        } else {
          return (
            <span
              style={{
                color: '#3fc581',
              }}
            >
              Complete
            </span>
          );
        }
        break;

      case undefined:
        if (getToday && item.timeRemain) {
          return (
            <span
              style={{
                color: '#e96c5a',
              }}
            >
              Upcoming
            </span>
          );
        } else if (getToday && item.endTimeCheck && item.link) {
          return (
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => meetConfirmation(item)}
              className={classes.alertStatus}
            >
              Join Class <ArrowRightAltIcon />
            </a>
          );
        } else {
          return (
            <span
              style={{
                color: '#3fc581',
              }}
            >
              Complete
            </span>
          );
        }
        break;

      default:
        break;
    }
  };

  const handleConfirmClose = (value: boolean) => {
    setConfirmModal(value);
  };

  const meetConfirmation = (item: any) => {
    setItemId(item.id);
    setTitle(item.title);
    setMeetLink(item.link);
    setConfirmModal(true);
  };

  const onConfirmedMeet = (value: boolean) => {
    handleConfirmClose(false);
    if (value) {
      window.open(`${URL}online-class.html?mid=${meetLink}`);
      if (user?.name && teacherProfileState?.photo) {
        localStorage.setItem('user', user.name);
        localStorage.setItem('user_photo', teacherProfileState.photo);
        localStorage.setItem('home_url', window.location.origin);
      }
    }
  };

  return (
    <>
      <List
        className={classes.root}
        component="ul"
        style={{ maxHeight: '230px', overflow: 'auto' }}
      >
        {today?.map((item: any) => {
          return (
            <ListItem key={item.id} role={undefined}>
              <Alert
                icon={false}
                severity="success"
                className={clsx(
                  classes.alertRoot,
                  item.is_upcoming === true && classes.alertWarning,
                  item.is_ongoing === true && classes.alertDanger
                )}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <AlertTitle className={classes.alertTitle}>
                          {item.title}
                        </AlertTitle>

                        <b style={{ fontWeight: 500, fontSize: 13 }}>
                          {item.subject_name}
                        </b>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>{liveClassTimeCheck(item)}</Grid>

                  <Grid item>{liveClassStatusCheck(getToday, item)}</Grid>
                </Grid>
              </Alert>
            </ListItem>
          );
        })}
      </List>
      <Popups
        openPopup={confirmModal}
        setOpenPopup={setConfirmModal}
        onClose={handleConfirmClose}
        title="Confirm a Title for the Meet"
        maxWidth="sm"
      >
        <LiveClassConfirm
          handleConfirmClose={handleConfirmClose}
          itemId={itemId}
          classTitle={title}
          onConfirmedMeet={onConfirmedMeet}
        />
      </Popups>
    </>
    // </List>
  );
}
