export const GET_STUDENT_TIMETABLE_LOADING = "GET_STUDENT_TIMETABLE_LOADING";
export const GET_STUDENT_TIMETABLE_ERROR = "GET_STUDENT_TIMETABLE_ERROR";
export const GET_STUDENT_TIMETABLE_SUCCESS = "GET_STUDENT_TIMETABLE_SUCCESS";

export const GET_STUDENT_PERIOD_LOADING = "GET_STUDENT_PERIOD_LOADING";
export const GET_STUDENT_PERIOD_ERROR = "GET_STUDENT_PERIOD_ERROR";
export const GET_STUDENT_PERIOD_SUCCESS = "GET_STUDENT_PERIOD_SUCCESS";

export interface StudentTimeTable {
  day: number;
  period_info: {
    id: string;
    name: string;
    grade: string;
    start_time: string;
    end_time: string;
  };
  subject: {
    id: string;
    name: string;
  };
  teacher: {
    id: string;
    first_name: string;
    last_name: string;
  };
}

interface Grade {
  created_by: string;
  general_info: number;
  grade_name: string;
  id: string;
  name: string;
}

export interface Period {
  id: string;
  name: string;
  grade: string;
  start_time: string;
  end_time: string;
}
export interface StudentTimeTableI {
  grade: Grade;
  section: {
    created_by: string;
    general_info: number;
    id: string;
    name: string;
  };
  timetable: StudentTimeTable[];
}

export interface StudentPeriods {
  grade: Grade;
  periods: Period[];
}

//===============================<START>GET STUDENT TIMETABLE<START>======================//

export interface GetStudentTimeTableLoading {
  type: typeof GET_STUDENT_TIMETABLE_LOADING;
}

export interface GetStudentTimeTableError {
  type: typeof GET_STUDENT_TIMETABLE_ERROR;
}

export interface GetStudentTimeTableSuccess {
  type: typeof GET_STUDENT_TIMETABLE_SUCCESS;
  payload: StudentTimeTableI;
}

//===============================<END>GET STUDENT TIMETABLE<END>======================//

//===============================<START>GET STUDENT PERIOD<START>======================//

export interface GetStudentPeriodLoading {
  type: typeof GET_STUDENT_PERIOD_LOADING;
}

export interface GetStudentPeriodError {
  type: typeof GET_STUDENT_PERIOD_ERROR;
}

export interface GetStudentPeriodSuccess {
  type: typeof GET_STUDENT_PERIOD_SUCCESS;
  payload: StudentPeriods;
}

//===============================<END>GET STUDENT PERIOD<END>======================//

export type StudentTimeTableDispatchTypes =
  | GetStudentTimeTableLoading
  | GetStudentTimeTableError
  | GetStudentTimeTableSuccess
  | GetStudentPeriodLoading
  | GetStudentPeriodError
  | GetStudentPeriodSuccess;
