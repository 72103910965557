import { makeStyles, Box, Grid } from "@material-ui/core";
import React from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import { EventsAnnounce } from "./ModalView/EventsAnnounce";
import TeacherEventsList from "./ModalView/TeacherEventsList";

export const useTeacherStyles = makeStyles(() => ({
  flexible: {
    flex: 1,
  },
  static: {
    flexBasis: "300px",
  },
}));

const TeacherEventsPage = () => {
  const classes = useTeacherStyles();

  return (
    <>
      <Box>
        <PageHeader title="Events" />
        <PageWrapper>
          <Grid container>
            <Grid item className={classes.flexible}>
              <TeacherEventsList />
            </Grid>

            <Grid item className={classes.static}>
              <EventsAnnounce />
            </Grid>
          </Grid>
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherEventsPage;
