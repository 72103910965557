import React from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import BackDropLoader from "../../Reusable/BackDropLoader";
import LedgerForm from "./LedgerForm";
import TerminalMarksLedger from "./TerminalMarksLedger";

const ResultLedger = () => {
  const { loading } = useSelector((state: RootStore) => state.marksLedger);
  return (
    <div>
      <LedgerForm />
      <TerminalMarksLedger />
      <BackDropLoader open={loading} />
    </div>
  );
};

export default ResultLedger;
