import {
  GET_CHILDREN_ATTENDANCE_LOADING,
  GET_CHILDREN_ATTENDANCE_ERROR,
  GET_CHILDREN_ATTENDANCE_SUCCESS,
  ChildrenAttendanceDispatchTypes,
} from "../../actions/ParentsDash/AttendanceReport/AttendanceReportActionTypes";

const initialState: any = {
  loading: false,
  attendances: [],
};

const ChildrenAttendanceReducer = (
  state: any = initialState,
  action: ChildrenAttendanceDispatchTypes
): any => {
  switch (action.type) {
    case GET_CHILDREN_ATTENDANCE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CHILDREN_ATTENDANCE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CHILDREN_ATTENDANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        attendances: action.payload,
      };

    default:
      return state;
  }
};

export default ChildrenAttendanceReducer;
