import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_CHILDREN_ACADEMIC_INFO_LOADING,
  GET_CHILDREN_ACADEMIC_INFO_ERROR,
  GET_CHILDREN_ACADEMIC_INFO_SUCCESS,
  ChildrenAcademicInfoDispatchTypes,
  GET_CHILDREN_ASSIGNMENT_SUCCESS,
  GET_CHILDREN_ASSIGNMENT_ERROR,
  GET_CHILDREN_ASSIGNMENT_LOADING,
  GET_CHILDREN_ASSIGNMENT_DETAIL_ERROR,
  GET_CHILDREN_ASSIGNMENT_DETAIL_LOADING,
  GET_CHILDREN_ASSIGNMENT_DETAIL_SUCCESS,
  SUBMIT_CHILDREN_ASSIGNMENT_ERROR,
  SUBMIT_CHILDREN_ASSIGNMENT_LOADING,
  SUBMIT_CHILDREN_ASSIGNMENT_SUCCESS,
  GET_CHILDREN_SUBMITTED_ASSIGNMENT_ERROR,
  GET_CHILDREN_SUBMITTED_ASSIGNMENT_LOADING,
  GET_CHILDREN_SUBMITTED_ASSIGNMENT_SUCCESS,
} from './ChildrenAssignmentActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { PARENT_API_URL } from '../../../config/SERVER_API_URLS';

export const GetChildrenAcademicInfo =
  () =>
    async (
      dispatch: Dispatch<ChildrenAcademicInfoDispatchTypes | SetSnackBarI>
    ) => {
      try {
        dispatch({
          type: GET_CHILDREN_ACADEMIC_INFO_LOADING,
        });

        const res = await axios.get(
          `${PARENT_API_URL}/student_academic_info/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_CHILDREN_ACADEMIC_INFO_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_CHILDREN_ACADEMIC_INFO_ERROR,
        });
      }
    };

export const GetChildrenAssignmentList =
  (student_academic_id: string, subject?: string) =>
    async (
      dispatch: Dispatch<ChildrenAcademicInfoDispatchTypes | SetSnackBarI>
    ) => {
      try {
        dispatch({
          type: GET_CHILDREN_ASSIGNMENT_LOADING,
        });
        let url = `${PARENT_API_URL}/children-assignment/?student_academic_id=${student_academic_id}`;
        if (subject) {
          url += `&subject=${subject}`;
        }

        const res = await axios.get(url, HeaderConfig());

        dispatch({
          type: GET_CHILDREN_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
      } catch (error: any) {
        dispatch({
          type: GET_CHILDREN_ASSIGNMENT_ERROR,
          payload: error?.response?.data,
        });
      }
    };

export const GetChildrenAssignmentDetail =
  (student_academic_id: string, assignment_id: string) =>
    async (
      dispatch: Dispatch<ChildrenAcademicInfoDispatchTypes | SetSnackBarI>
    ) => {
      try {
        dispatch({
          type: GET_CHILDREN_ASSIGNMENT_DETAIL_LOADING,
        });
        const url = `${PARENT_API_URL}/children-assignment/${assignment_id}/?student_academic_id=${student_academic_id}`;
        const res = await axios.get(url, HeaderConfig());

        dispatch({
          type: GET_CHILDREN_ASSIGNMENT_DETAIL_SUCCESS,
          payload: res.data,
        });
      } catch (error: any) {
        dispatch({
          type: GET_CHILDREN_ASSIGNMENT_DETAIL_ERROR,
          payload: error?.response?.data,
        });
      }
    };

export const GetChildrenSubmittedAssignmentbyID =
  (student_academic_id: string, assignment_id: string) =>
    async (
      dispatch: Dispatch<ChildrenAcademicInfoDispatchTypes | SetSnackBarI>
    ) => {
      try {
        dispatch({
          type: GET_CHILDREN_SUBMITTED_ASSIGNMENT_LOADING,
        });
        const url = `${PARENT_API_URL}/children-assignment-submission/?student_academic_id=${student_academic_id}&assignment=${assignment_id}`;
        const res = await axios.get(url, HeaderConfig());

        dispatch({
          type: GET_CHILDREN_SUBMITTED_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
      } catch (error: any) {
        dispatch({
          type: GET_CHILDREN_SUBMITTED_ASSIGNMENT_ERROR,
          payload: error?.response?.data,
        });
      }
    };

export const SubmitChildrenAssignment =
  (data: any) =>
    async (
      dispatch: Dispatch<ChildrenAcademicInfoDispatchTypes | SetSnackBarI>
    ) => {
      try {
        dispatch({
          type: SUBMIT_CHILDREN_ASSIGNMENT_LOADING,
        });
        const res = await axios.post(
          `${PARENT_API_URL}/children-assignment-submission/`,
          data,
          HeaderConfig()
        );
        dispatch({
          type: SUBMIT_CHILDREN_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMessage: 'Assignment Submitted Successfully.',
          },
        });
      } catch (error: any) {
        dispatch({
          type: SUBMIT_CHILDREN_ASSIGNMENT_ERROR,
          payload: error.response.data,
        });
      }
    };

export const SubmitChildrenRedoAssignment =
  (assignment_id: string, academic_id: string, data: any) =>
    async (
      dispatch: Dispatch<ChildrenAcademicInfoDispatchTypes | SetSnackBarI>
    ) => {
      try {
        dispatch({
          type: SUBMIT_CHILDREN_ASSIGNMENT_LOADING,
        });
        const res = await axios.put(
          `${PARENT_API_URL}/children-assignment-submission/${assignment_id}/?student_academic_id=${academic_id}`,
          data,
          HeaderConfig()
        );
        dispatch({
          type: SUBMIT_CHILDREN_ASSIGNMENT_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMessage: 'Assignment Submitted Successfully.',
          },
        });
      } catch (error: any) {
        dispatch({
          type: SUBMIT_CHILDREN_ASSIGNMENT_ERROR,
          payload: error.response.data,
        });
      }
    };