import * as React from "react";
import { Add as AddIcon, Search as SearchIcon } from "@material-ui/icons";
import AddNewButton from "./AddNewButton";
import FormButton from "./FormButton";
import { useFormStyles } from "../../Styles/FormStyles";

const FormNewButton = (props: any) => {
  return (
    <AddNewButton
      type="button"
      color="primary"
      variant="outlined"
      startIcon={<AddIcon />}
      title={props.children}
      {...props}
    >
      {props.children}
    </AddNewButton>
  );
};

const FormSearchButton = (props: any) => {
  const classes = useFormStyles();
  return (
    <FormButton
      color="primary"
      className={classes.searchBoxButton}
      startIcon={<SearchIcon />}
      {...props}
    >
      Search
    </FormButton>
  );
};

export { FormNewButton, FormSearchButton };
