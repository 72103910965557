import React, { useState } from 'react';
import { useSidebar } from '../../Reusable';
import { useSidebarStyles } from '../../Styles/SidebarStyles';
import { DonutLargeOutlined } from '@material-ui/icons';
import SidebarTitle from '../../Reusable/Titles/SidebarTitle';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

interface PropsI {
  feeType?: boolean;
  feeConfig?: boolean;
  applyFee?: boolean;
  openingBalance?: boolean;
  collectFee?: boolean;
  miscFee?: boolean;
  billAdjust?: boolean;
  payMethod?: boolean;
}

interface DataSetI {
  text: string;
  url: string;
  activeStatus?: boolean;
}

const AccountantFeeSettingsSidebar = (props: PropsI) => {
  const classes = useSidebarStyles();

  const [feeType] = useState(props.feeType);
  const [feeConfig] = useState(props.feeConfig);
  const [applyFee] = useState(props.applyFee);
  const [openingBalance] = useState(props.openingBalance);
  const [collectFee] = useState(props.collectFee);
  const [miscFee] = useState(props.miscFee);
  const [billAdjust] = useState(props.billAdjust);
  const [payMethod] = useState(props.payMethod);

  const dataSet: DataSetI[] = [
    { text: 'Fee Head', url: 'fee-type', activeStatus: feeType },
    {
      text: 'Fee Configuration',
      url: 'fees',
      activeStatus: feeConfig,
    },
    { text: 'Apply Fee', url: 'apply-fee', activeStatus: applyFee },
    {
      text: 'Opening Balance',
      url: 'opening-balance',
      activeStatus: openingBalance,
    },
    { text: 'Collect Fees', url: 'collect-fees', activeStatus: collectFee },
    {
      text: 'Miscellaneous Fee',
      url: 'miscellaneous-fee',
      activeStatus: miscFee,
    },
    {
      text: 'Bill Adjustment',
      url: 'bill-adjustment',
      activeStatus: billAdjust,
    },
    {
      text: 'Payment Method',
      url: 'payment-method',
      activeStatus: payMethod,
    },
  ];
  const { CustomSidebar } = useSidebar(dataSet, 'FINANCE');
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<AccountBalanceIcon />}>Fee Settings</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default AccountantFeeSettingsSidebar;
