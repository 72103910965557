import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import CustomizedDialogs from "../Reusable/Dialogs/DeleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { useTableStyles } from "../Styles/TableStyles";
import { useTable } from "../Reusable";
import { HeadCellsI } from "../../actions";
import {
  ItemViewButton,
  ItemEditButton,
  ItemDeleteButton,
} from "../Reusable/Buttons/TableButton";
import { RootStore } from "../../store";
import { AdminAssignmentTypeI } from "../../actions/Classroom/AdminAssignment/AdminAssignmentActionTypes";
import Popups from "../Reusable/Dialogs/Popups";
import AssignmentView from "../../dashboard/StudentDashboard/pages/Assignment/StudentAssignmentView";
import { Tupple, assignmentCategoryOptions } from "../../common/json.constant";

const headCells: HeadCellsI[] = [
  { id: "s.n", label: "S.N." },
  { id: "subject", label: "Subject" },
  { id: "title", label: "Title" },
  { id: "assign_category", label: "Assignment Category" },
  { id: "submission_type", label: "Submission Type" },
  { id: "submitted_count", label: "Submitted By" },
  { id: "assign_date", label: "Assign Date" },
  { id: "deadline_date", label: "Deadline Date" },
  { id: "action", label: "Action" },
];

const AdminAssignmentTable = () => {
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<AdminAssignmentTypeI[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [singleAssignment, setSingleAssignment] = useState({});

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const assingmentSelector = useSelector(
    (state: RootStore) => state.admin_assignment
  );

  useEffect(() => {
    const data = assingmentSelector.assignments;
    setTableData(data);
    setLoading(assingmentSelector.loading);
  }, [assingmentSelector]);

  const handleDeleteModal = (id: string) => {
    setItemId(id);
    console.log("id", id);
    setDeleteModalOpen(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    // confirmed &&
    //   itemId != null &&
    //   dispatch(DeleteTeacherAssignment(Number(itemId)));
    // setDeleteModalOpen(false);
  };

  const handleEditModal = (id: any) => {
    // setAssignmentID(id);
    // setAssignmentViewClicked(true);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleViewModal = (item: any) => {
    // dispatch(GetAdminAssignmentByID(item.id));
    // setLeaveData(item);
    setSingleAssignment(item);
    setOpenPopup(true);
    // setItemId(parseInt(item.id));
  };

  const getCustomTableRow = (item: AdminAssignmentTypeI, index: number) => {
    const assignCategory = assignmentCategoryOptions.filter(
      (assign: Tupple) => item.assignment_type === assign.key
    )[0].value;
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.subject.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {assignCategory}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.submission_type}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.submitted_students} / {item.total_students}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {new Date(item.assign_on).toLocaleString()}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {new Date(item.deadline).toLocaleString()}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleViewModal(item)} />
          {/* <ItemEditButton onClick={() => handleEditModal(item.id)} /> */}
          {/* <ItemDeleteButton onClick={() => handleDeleteModal(item.id)} /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper elevation={0} style={{ padding: "0 1rem" }}>
        <Typography variant="h6">Assignment</Typography>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          rowsPerPage={5}
          getCustomTableRow={getCustomTableRow}
        />

        <CustomizedDialogs
          open={deleteModalOpen}
          onClose={handleModalClose}
          deleteConfirmed={onDeleteConfirmed}
        />
      </Paper>
      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        maxWidth="md"
        title="Assignment Details"
        colored
      >
        <AssignmentView singleAssignment={singleAssignment} />
      </Popups>
    </>
  );
};

export default AdminAssignmentTable;
