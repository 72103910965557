import React from 'react';
import { FullSubModuleLayout } from '../../../../components/Reusable/Layouts/SubModuleLayout';
import ParentsDashSidebar from '../../../../components/ParentsDash/ParentsDashSidebar';
import MeetingList from './MeetingList';
import MeetingSearch from './MeetingSearch';
import { Box } from '@material-ui/core';
import PageHeader from '../../components/Header/PageHeader';
import PageWrapper from '../../components/PageWrapper';
import { tblStyle } from '../../ParentDashboardStyles';
import ChildrenListBar from '../LiveClasses/Components/ChildrenListBar';

const ParentMeetingRequestPage = () => {
  const nav = <ParentsDashSidebar MeetingRequest />;

  return (
    <Box>
      <PageHeader title="Meeting Request" />

      <PageWrapper>
        {/* <ChildrenListBar meetingRequest /> */}
        <Box style={{ ...tblStyle, padding: 0 }}>
          <MeetingSearch />
          <MeetingList />
        </Box>
      </PageWrapper>
    </Box>
  );
};

export default ParentMeetingRequestPage;
