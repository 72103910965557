import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_MISC_FEE_TYPE_ERROR,
  GET_MISC_FEE_TYPE_LOADING,
  GET_MISC_FEE_TYPE_SUCCESS,
  CREATE_MISC_FEE_TYPE_ERROR,
  CREATE_MISC_FEE_TYPE_LOADING,
  CREATE_MISC_FEE_TYPE_SUCCESS,
  UPDATE_MISC_FEE_TYPE_ERROR,
  UPDATE_MISC_FEE_TYPE_LOADING,
  UPDATE_MISC_FEE_TYPE_SUCCESS,
  DELETE_MISC_FEE_TYPE_ERROR,
  DELETE_MISC_FEE_TYPE_LOADING,
  DELETE_MISC_FEE_TYPE_SUCCESS,
  APPLY_MISC_FEE_TYPE_ERROR,
  APPLY_MISC_FEE_TYPE_LOADING,
  APPLY_MISC_FEE_TYPE_SUCCESS,
  MiscFeeTypeDispatchTypes,
} from "./MiscFeeTypeActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  name: string;
  amount: number;
  grades: Array<string>;
  apply_to_all_grades: boolean;
}

interface RequestDataApplyI {
  students: Array<string>;
  fees: Array<string>;
}

export const GetMiscFeeType =
  () =>
    async (dispatch: Dispatch<MiscFeeTypeDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_MISC_FEE_TYPE_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/miscellaneous-fee/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_MISC_FEE_TYPE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_MISC_FEE_TYPE_ERROR,
        });
      }
    };

export const GetMiscFeeTypeWithFilters =
  (grade: string) =>
    async (dispatch: Dispatch<MiscFeeTypeDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_MISC_FEE_TYPE_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/miscellaneous-fee/?grades=${grade}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_MISC_FEE_TYPE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_MISC_FEE_TYPE_ERROR,
        });
      }
    };

export const CreateMiscFeeType =
  (data: RequestDataI, func: any) =>
    async (dispatch: Dispatch<MiscFeeTypeDispatchTypes | SetSnackBarI>) => {
      dispatch({
        type: CREATE_MISC_FEE_TYPE_LOADING,
      });

      try {
        const res = await axios.post(
          `${ADMIN_API_URL}/miscellaneous-fee/`,
          data,
          HeaderConfig()
        );

        dispatch({
          type: CREATE_MISC_FEE_TYPE_SUCCESS,
          payload: res.data,
        });

        func();

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Fee Type Added Successfully",
          },
        });
      } catch (err: any) {
        console.log({ err });
        dispatch({
          type: CREATE_MISC_FEE_TYPE_ERROR,
          payload: err.response && err.response?.data,
        });

        // dispatch({
        //   type: SET_SNACKBAR,
        //   payload: {
        //     snackbarOpen: true,
        //     snackbarType: "error",
        //     snackbarMessage: "Fee Type Add Failed",
        //   },
        // });
      } finally {
        try {
          dispatch({
            type: GET_MISC_FEE_TYPE_LOADING,
          });

          const res = await axios.get(
            `${ADMIN_API_URL}/miscellaneous-fee/`,
            HeaderConfig()
          );


          dispatch({
            type: GET_MISC_FEE_TYPE_SUCCESS,
            payload: res.data,
          });
          dispatch({
            type: SET_SNACKBAR,
            payload: {
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: "Fee Type Added Successfully",
            },
          });
        } catch (error) {
          dispatch({
            type: GET_MISC_FEE_TYPE_ERROR,
          });
        }
      }
    };

export const UpdateMiscFeeType =
  (id: string, data: RequestDataI) =>
    async (dispatch: Dispatch<MiscFeeTypeDispatchTypes | SetSnackBarI>) => {
      dispatch({
        type: UPDATE_MISC_FEE_TYPE_LOADING,
      });

      try {
        const res = await axios.put(
          `${ADMIN_API_URL}/miscellaneous-fee/`,
          data,
          HeaderConfig()
        );

        dispatch({
          type: UPDATE_MISC_FEE_TYPE_SUCCESS,
          payload: res.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Fee Type Updated Successfully",
          },
        });
      } catch (err: any) {
        console.log({ err });
        dispatch({
          type: UPDATE_MISC_FEE_TYPE_ERROR,
          payload: err.response && err.response?.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Fee Type Update Failed",
          },
        });
      } finally {
        try {
          dispatch({
            type: GET_MISC_FEE_TYPE_LOADING,
          });

          const res = await axios.get(
            `${ADMIN_API_URL}/miscellaneous-fee/`,
            HeaderConfig()
          );

          dispatch({
            type: GET_MISC_FEE_TYPE_SUCCESS,
            payload: res.data,
          });
        } catch (error) {
          dispatch({
            type: GET_MISC_FEE_TYPE_ERROR,
          });
        }
      }
    };

export const DeleteMiscFeeType =
  (id: string) =>
    async (dispatch: Dispatch<MiscFeeTypeDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: DELETE_MISC_FEE_TYPE_LOADING,
        });

        await axios.delete(`${ADMIN_API_URL}/miscellaneous-fee//${id}/`, HeaderConfig());

        dispatch({
          type: DELETE_MISC_FEE_TYPE_SUCCESS,
          payload: { id: id },
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Fee Type Deleted Successfully",
          },
        });
      } catch (error) {
        dispatch({
          type: DELETE_MISC_FEE_TYPE_ERROR,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Fee Type Delete Failed",
          },
        });
      }
    };


export const ApplyMiscFeeType =
  (grade: string, section: string, data: RequestDataApplyI) =>
    async (dispatch: Dispatch<MiscFeeTypeDispatchTypes | SetSnackBarI>) => {
      dispatch({
        type: APPLY_MISC_FEE_TYPE_LOADING,
      });

      try {
        const res = await axios.post(
          `${ADMIN_API_URL}/generate-miscellaneous-fee/?grade=${grade}&section=${section}`,
          data,
          HeaderConfig()
        );

        dispatch({
          type: APPLY_MISC_FEE_TYPE_SUCCESS,
          payload: res.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Applied Successfully",
          },
        });
      } catch (err: any) {
        console.log({ err });
        dispatch({
          type: APPLY_MISC_FEE_TYPE_ERROR,
          payload: err.response && err.response?.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Applying Failed",
          },
        });
      }

    };