import { Box, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    position: "relative",
    marginLeft: "240px",
    background: "rgba(0,0,0,0.08)",
    minHeight: "calc(100vh - 95px)",
    boxShadow: "-1px -1px 49px 5px rgb(0 0 0 / 6%)",
    padding: "1px 24px",
  },
}));

const PageWrapper = (props: any) => {
  const classes = useStyles();
  return <Box className={classes.content}>{props.children}</Box>;
};

export default PageWrapper;
