export const GET_LESSON_PLAN_LOADING = "GET_LESSON_PLAN_LOADING";
export const GET_LESSON_PLAN_ERROR = "GET_LESSON_PLAN_ERROR";
export const GET_LESSON_PLAN_SUCCESS = "GET_LESSON_PLAN_SUCCESS";

export const ADD_LESSON_PLAN_LOADING = "ADD_LESSON_PLAN_LOADING";
export const ADD_LESSON_PLAN_ERROR = "ADD_LESSON_PLAN_ERROR";
export const ADD_LESSON_PLAN_SUCCESS = "ADD_LESSON_PLAN_SUCCESS";

export const UPDATE_LESSON_PLAN_LOADING = "UPDATE_LESSON_PLAN_LOADING";
export const UPDATE_LESSON_PLAN_ERROR = "UPDATE_LESSON_PLAN_ERROR";
export const UPDATE_LESSON_PLAN_SUCCESS = "UPDATE_LESSON_PLAN_SUCCESS";

export const DELETE_LESSON_PLAN_LOADING = "DELETE_LESSON_PLAN_LOADING";
export const DELETE_LESSON_PLAN_ERROR = "DELETE_LESSON_PLAN_ERROR";
export const DELETE_LESSON_PLAN_SUCCESS = "DELETE_LESSON_PLAN_SUCCESS";

export const COMPLETE_LESSON_PLAN_LOADING = "COMPLETE_LESSON_PLAN_LOADING";
export const COMPLETE_LESSON_PLAN_ERROR = "COMPLETE_LESSON_PLAN_ERROR";
export const COMPLETE_LESSON_PLAN_SUCCESS = "COMPLETE_LESSON_PLAN_SUCCESS";

export interface LessonPlanDocumentTypeI {
  id: number;
  lesson_plan: number;
  document: string;
}

export interface TeacherLessonPlanTypeI {
  id: number;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  due_date: string;
  completion_date: string | null;
  unit_title: string;
  sub_unit: number;
  sub_unit_title: string;
  reference_links: any;
  created_by: number;
  general_info: number;
  lesson_plan_document: LessonPlanDocumentTypeI[];
}

//===============================<START>GET LESSON PLAN<START>======================//

export interface GetLessonPlanLoading {
  type: typeof GET_LESSON_PLAN_LOADING;
}

export interface GetLessonPlanError {
  type: typeof GET_LESSON_PLAN_ERROR;
}

export interface GetLessonPlanSuccess {
  type: typeof GET_LESSON_PLAN_SUCCESS;
  payload: TeacherLessonPlanTypeI[];
}

//===============================<END>GET LESSON PLAN<END>======================//

//===============================<START>ADD LESSON_PLAN<START>======================//

export interface AddLessonPlanLoading {
  type: typeof ADD_LESSON_PLAN_LOADING;
}

export interface AddLessonPlanError {
  type: typeof ADD_LESSON_PLAN_ERROR;
  payload: any;
}

export interface AddLessonPlanSuccess {
  type: typeof ADD_LESSON_PLAN_SUCCESS;
  payload: TeacherLessonPlanTypeI;
}

//===============================<END>GET LESSON_PLAN<END>======================//
//===============================<START>update LESSON PLAN<START>======================//

export interface UpdateLessonPlanLoading {
  type: typeof UPDATE_LESSON_PLAN_LOADING;
}

export interface UpdateLessonPlanError {
  type: typeof UPDATE_LESSON_PLAN_ERROR;
  payload: any;
}

export interface UpdateLessonPlanSuccess {
  type: typeof UPDATE_LESSON_PLAN_SUCCESS;
  payload: TeacherLessonPlanTypeI;
}

//===============================<END>GET LESSON PLAN<END>======================//
//===============================<START>update LESSON_PLAN<START>======================//

export interface DeleteLessonPlanLoading {
  type: typeof DELETE_LESSON_PLAN_LOADING;
}

export interface DeleteLessonPlanError {
  type: typeof DELETE_LESSON_PLAN_ERROR;
}

export interface DeleteLessonPlanSuccess {
  type: typeof DELETE_LESSON_PLAN_SUCCESS;
  payload: { id: number };
}

//===============================<END>delete LESSON_PLAN<END>======================//
//===============================<START>complete LESSON_PLAN<START>======================//

export interface CompleteLessonPlanLoading {
  type: typeof COMPLETE_LESSON_PLAN_LOADING;
}

export interface CompleteLessonPlanError {
  type: typeof COMPLETE_LESSON_PLAN_ERROR;
}

export interface CompleteLessonPlanSuccess {
  type: typeof COMPLETE_LESSON_PLAN_SUCCESS;
  payload: any;
}

//===============================<END>complete LESSON_PLAN<END>======================//

export type LessonPlanDispatchTypes =
  | GetLessonPlanLoading
  | GetLessonPlanError
  | GetLessonPlanSuccess
  | AddLessonPlanLoading
  | AddLessonPlanError
  | AddLessonPlanSuccess
  | UpdateLessonPlanLoading
  | UpdateLessonPlanError
  | UpdateLessonPlanSuccess
  | DeleteLessonPlanLoading
  | DeleteLessonPlanError
  | DeleteLessonPlanSuccess
  | CompleteLessonPlanLoading
  | CompleteLessonPlanError
  | CompleteLessonPlanSuccess;
