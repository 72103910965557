import {
  Grid,
  Typography,
  TextField,
  InputLabel,
  Box,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { ImageUpload } from "../../../../../components/Reusable";
import CustomizedTextField from "../../../../../components/Reusable/Inputs/TextField";
import { useFormStyles } from "../../../../../components/Styles/FormStyles";
import formsValidationCheckup from "../../../../../components/utils/formsValidationCheckUp";
import {
  AddSchools,
  UpdateSchool,
} from "../../../../../actions/SuperAdmin/School/SchoolAction";
import UploadImageFile from "../../../../../components/Reusable/Upload/ImageUpload/UploadImageFile";
import { RootStore } from "../../../../../store";
import { FormSaveLoadingButton } from "../../../../../components/Reusable/Buttons/FormButton";
import LoaderIcon from "../../../../../components/Reusable/LoarderIcon";

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: "14px",
    "& > span": {
      color: "red",
    },
  },
}));

const Title = ({ children }: any) => (
  <Typography
    variant="h6"
    color="primary"
    style={{ marginBottom: "12px", fontSize: "18px" }}
  >
    {children}
  </Typography>
);

const SchoolForm = (props: any) => {
  const { schoolData } = props;
  const classes = useFormStyles();
  const classes2 = useStyles();
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, setValue } = useForm();
  const schoolState = useSelector((state: RootStore) => state.sa_schools);
  const [image, setImage] = useState<any | null>(null);

  useEffect(() => {
    if (schoolData != null) {
      handleFormInitialize();
    }
  }, [schoolData]);

  //Initializing form data
  const handleFormInitialize = () => {
    setImage(schoolData?.logo);
    setValue("full_name", schoolData?.admin_full_name);
    setValue("phone", schoolData?.admin_phone);
    setValue("email", schoolData?.admin_email);
    setValue("school_name", schoolData?.school_name);
    setValue("school_reg_number", schoolData?.school_reg_number);
    setValue("school_email", schoolData?.school_email);
    setValue("phone_number", schoolData?.phone_number);
    setValue("address", schoolData?.address);
    setValue("slogan", schoolData?.slogan);
  };

  const onSubmit = (data: any) => {
    console.log({ image });
    const formData = new FormData();
    image != null && formData.append("logo", image);

    const finalValues = {
      user_info: {
        full_name: data.full_name,
        phone: data.phone,
        email: data.email,
      },
      school_name: data.school_name,
      school_reg_number: data.school_reg_number,
      address: data.address,
      email: data.school_email,
      phone_number: data.phone_number,
      slogan: data.slogan,
    };
    for (const key in finalValues) {
      if (typeof finalValues[key] === "object") {
        for (const subKey in finalValues[key]) {
          formData.append(`${key}.${subKey}`, finalValues[key][subKey]);
        }
      } else {
        formData.append(key, finalValues[key]);
      }
    }
    schoolData != null
      ? dispatch(UpdateSchool(schoolData.id, formData))
      : dispatch(AddSchools(formData));
  };

  if (schoolState?.add_or_update) {
    props.onCancel();
    props.setSchoolData(null);
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box style={{ padding: "16px" }}>
          <Title>Admin Information</Title>
          <Grid container spacing={2}>
            <Grid item md={4} className={classes.formWrapper}>
              <CustomizedTextField
                label="Administrator Full Name"
                placeholder="Label"
                name="full_name"
                required
                error={errors["full_name"] && true}
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "full_name",
                requiredMessage: "Full Name is Required",
                serverSideMessage:
                  errors.full_name?.type === "serverSideError" &&
                  errors.full_name.message,
              })}
            </Grid>

            <Grid item md={4} className={classes.formWrapper}>
              <CustomizedTextField
                label="Phone Number"
                placeholder="Label"
                name="phone"
                required
                error={errors["phone"] && true}
                inputRef={register({
                  required: true,
                  maxLength: 10,
                  minLength: 10,
                })}
                type="number"
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "phone",
                requiredMessage: "Phone Number is Required",
                maxLengthMessage: "Invalid Number Length",
                minLengthMessage: "Invalid Number Length",
                serverSideMessage:
                  errors.phone?.type === "serverSideError" &&
                  errors.phone.message,
              })}
            </Grid>

            <Grid item md={4} className={classes.formWrapper}>
              <CustomizedTextField
                label="Email"
                name="email"
                placeholder="Label"
                inputRef={register({
                  required: false,
                  pattern: {
                    value: /^[a-z0-9._%+_]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: "Enter a valid email (e.g. yourname@gmail.com)",
                  },
                })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "email",
                serverSideMessage:
                  errors.email?.type === "serverSideError" &&
                  errors.email.message,
              })}
              <span className={classes.validationErrorInfo}>
                {errors.email && errors.email.message}
              </span>
            </Grid>
          </Grid>
        </Box>

        <Box style={{ padding: "16px" }}>
          <Title>Add School Information</Title>

          <Grid container spacing={2}>
            <Grid item md={8} className={classes.formWrapper}>
              <CustomizedTextField
                label="School Name"
                placeholder="Label"
                name="school_name"
                required
                error={errors["school_name"] && true}
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "school_name",
                requiredMessage: "Full Name is Required",
                serverSideMessage:
                  errors.school_name?.type === "serverSideError" &&
                  errors.school_name.message,
              })}
            </Grid>

            <Grid item md={4} className={classes.formWrapper}>
              <CustomizedTextField
                label="School Reg. No. "
                placeholder="Label"
                name="school_reg_number"
                required
                error={errors["school_reg_number"] && true}
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "school_reg_number",
                requiredMessage: "School Reg. No. is Required",
                serverSideMessage:
                  errors.school_reg_number?.type === "serverSideError" &&
                  errors.school_reg_number.message,
              })}
            </Grid>

            <Grid item md={6} className={classes.formWrapper}>
              <CustomizedTextField
                label="Email"
                name="school_email"
                placeholder="Label"
                inputRef={register({
                  required: false,
                  pattern: {
                    value: /^[a-z0-9._%+_]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: "Enter a valid email (e.g. yourname@gmail.com)",
                  },
                })}
              />
            </Grid>

            <Grid item md={6} className={classes.formWrapper}>
              <CustomizedTextField
                label="School Phone Number"
                placeholder="Label"
                name="phone_number"
                required
                error={errors["phone_number"] && true}
                inputRef={register({
                  required: true,
                  maxLength: 10,
                  minLength: 10,
                })}
                type="number"
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "phone_number",
                requiredMessage: "Phone Number is Required",
                maxLengthMessage: "Invalid Number Length",
                minLengthMessage: "Invalid Number Length",
                serverSideMessage:
                  errors.phone?.type === "serverSideError" &&
                  errors.phone.message,
              })}
            </Grid>

            <Grid item md={12} className={classes.formWrapper}>
              <CustomizedTextField
                label="Full Address of School "
                placeholder="Label"
                name="address"
                required
                error={errors["address"] && true}
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "address",
                requiredMessage: "Full Address of School is Required",
                serverSideMessage:
                  errors.address?.type === "serverSideError" &&
                  errors.address.message,
              })}
            </Grid>

            <Grid item md={12} className={classes.formWrapper}>
              <CustomizedTextField
                label="School Slogan "
                placeholder="Label"
                name="slogan"
                required
                error={errors["slogan"] && true}
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "slogan",
                requiredMessage: "School Slogan is Required",
                serverSideMessage:
                  errors.slogan?.type === "serverSideError" &&
                  errors.slogan.message,
              })}
            </Grid>
          </Grid>
        </Box>

        <Box style={{ padding: "16px 0 0 16px" }}>
          <Title>Photo Upload</Title>

          <Grid container spacing={2}>
            <Grid item md={3} className={classes.formWrapper}>
              <InputLabel className={classes2.inputLabel}>
                System Logo <span>*</span>
              </InputLabel>

              <UploadImageFile
                setImage={setImage}
                image={image}
                id="upload-input2"
              />
            </Grid>
          </Grid>
        </Box>

        <Box style={{ padding: "10px 17px" }}>
          <FormSaveLoadingButton
            disabled={schoolState?.loading ? true : false}
            text={schoolState?.loading ? "Adding" : "Add"}
            type="submit"
            icon={schoolState?.loading && <LoaderIcon />}
          />

          <Button
            color="default"
            variant="outlined"
            style={{ marginLeft: "16px" }}
            onClick={() => props.onCancel()}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default SchoolForm;
