import React, { useState, useEffect } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootStore } from "../../../../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, 2),
      backgroundColor: "#F2F3F6",
    },
    roota: {
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    parentImg: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      height: "100%",
      padding: "1rem 0",
    },
    table: {
      color: "#696969",
      "& .MuiTableRow-root": {
        height: "30px",
        maxHeight: "30px",
      },
      "&.MuiTableCell-root": {
        width: "50%",
      },
    },
    infobar: {
      marginRight: theme.spacing(4),
      padding: theme.spacing(2),
    },
  })
);

export default function ChildOtherInfo(props: any) {
  const classes = useStyles();
  // const { transportation_detail } = props.studentData;
  // const {
  //   route_from,
  //   route_to,
  //   pickup_time,
  //   drop_time,
  //   driver,
  //   bus_number,
  //   fare,
  // } = transportation_detail;

  const rows = [
    createData(
      "Student House",
      props.studentData?.academic_info &&
      props.studentData?.academic_info[0]?.house_name
    ),
    createData(
      "Previous School",
      props.studentData?.academic_info &&
      props.studentData?.academic_info[0]?.last_school_name
    ),
    createData(
      "Sports",
      props.studentData?.academic_info[0]?.sports
        ? props.studentData?.academic_info[0]?.sports
        : "-"
    ),
  ];
  // const rows1 = [
  //   createData("Route", `${route_from} - ${route_to}`),
  //   createData("Time", `${pickup_time} - ${drop_time}`),
  //   createData("Driver", driver),
  //   createData("Fare", `Rs. ${fare}`),
  // ];

  function createData(name: string, infoData: string) {
    return { name, infoData };
  }

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Paper className={classes.infobar} elevation={1}>
            <TableContainer>
              <Typography align="left" variant="h5" component="h3">
                Transportation Details
              </Typography>
              <Table
                size="small"
                className={classes.table}
                aria-label="simple table"
              >
                {/* <TableBody>
                  {rows1.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell
                        className={classes.table}
                        component="th"
                        scope="row"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.infoData}</TableCell>
                    </TableRow>
                  ))}
                </TableBody> */}
              </Table>
              <TableContainer className={classes.roota}>
                <Typography align="left" variant="h5" component="h3">
                  Other Informations
                </Typography>
                <Table
                  size="small"
                  className={classes.table}
                  aria-label="simple table"
                >
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell
                          className={classes.table}
                          component="th"
                          scope="row"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.infoData}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
