import React, { useEffect, useState } from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetEvents } from '../../../actions/Event/Events/EventsAction';
import {
  fromToConverter,
  fromToDaysConverter,
} from '../../../components/utils/dateTimeFormat';
import { GetTeacherDashboardEvents } from '../../../actions/TeacherDash/Dasbhoard/TeacherDashboardAction';
import { exludeYear } from '../../../components/utils/excludeYear';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import NepaliDate from 'nepali-date-converter';
import { GetTeacherEvent } from '../../../actions/TeacherDash/TeacherEvents/TeacherEventAction';

const useStyles = makeStyles({
  root: {
    paddingBottom: 0,
    paddingLeft: 0,
    '&  .MuiTimelineItem-missingOppositeContent': {
      '&::before': {
        flex: 0,
        padding: 0,
      },
      marginBottom: 0,
    },

    '& .MuiTimelineItem-root': {
      minHeight: 'auto',
    },
  },
});

interface EventI {
  academic_session: string;
  created_by: string;
  description: string;
  event_type: string;
  event_type_name: string;
  from_date: string;
  general_info: string;
  id: string;
  is_holiday: boolean;
  location: string;
  time: string;
  title: string;
  to_date: string;
}

export default function TeacherEventCard() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [event, setEvent] = useState<any[]>([]);

  const date5 = new NepaliDate();
  const month = date5.format('M');

  useEffect(() => {
    // dispatch(GetTeacherDashboardEvents(parseInt(month)));
    dispatch(GetTeacherEvent());
  }, []);

  // Events reducer
  const eventsState = useSelector(
    (state: RootStore) => state.teacher_dashboard.events
  );

  const teacherEvents = useSelector(
    (state: RootStore) => state.teacher_event.teacher_event
  );

  const getTodaysDate = () => {
    const date = new Date();
    let EngMonth: any = date.getMonth() + 1;
    EngMonth = EngMonth < 10 ? `0${EngMonth}` : EngMonth;
    return EngMonth;
  };

  // Fetch data
  useEffect(() => {
    const newEvents: any = [];
    if (teacherEvents.length) {
      teacherEvents.map((elem: any) => {
        const check = elem.from_date.split('-');
        if (check[1] == getTodaysDate()) {
          newEvents.push({
            id: elem.id,
            from_date: elem.from_date,
            title: elem.title,
            to_date: elem.to_date,
            description: elem.description,
          });
        }
      });
      setEvent(newEvents);
    }
  }, [teacherEvents]);

  return (
    <>
      <div style={{ maxHeight: 250, overflow: 'auto' }}>
        {event
          ? event.map((item: any, index: number) => (
              <Timeline key={item.id} align="left" className={classes.root}>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      style={{
                        backgroundColor: index % 2 == 0 ? '#2979ff' : '#ff5722',
                        boxShadow: 'none',
                      }}
                    />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Grid container justifyContent="space-between">
                      <Typography
                        align="left"
                        variant="subtitle2"
                        style={{ fontWeight: 500, marginBottom: '2px' }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        align="right"
                        variant="subtitle2"
                        style={{ fontWeight: 500, marginBottom: '2px' }}
                      >
                        {fromToConverter(item.from_date, item.to_date)} गते
                      </Typography>
                    </Grid>

                    <Typography
                      variant="body1"
                      style={{
                        color: '#555',
                        borderBottom: '1px solid rgba(0,0,0,0.1)',
                        paddingBottom: '8px',
                        width: '100%',
                      }}
                    >
                      {item.description}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            ))
          : ''}
      </div>
    </>
  );
}
