export const GET_PARENT_SYLLABUS_LOADING = "GET_PARENT_SYLLABUS_LOADING";
export const GET_PARENT_SYLLABUS_ERROR = "GET_PARENT_SYLLABUS_ERROR";
export const GET_PARENT_SYLLABUS_SUCCESS = "GET_PARENT_SYLLABUS_SUCCESS";

export interface ParentSyllabusI {
  id: number;
  name: string;
  subject: number;
  academic_class: number;
  teaching_hours: string;
  general_info: number;
}

//===============================<START>GET LESSON PLAN<START>======================//

export interface GetParentSyllabusLoading {
  type: typeof GET_PARENT_SYLLABUS_LOADING;
}

export interface GetParentSyllabusError {
  type: typeof GET_PARENT_SYLLABUS_ERROR;
}

export interface GetParentSyllabusSuccess {
  type: typeof GET_PARENT_SYLLABUS_SUCCESS;
  payload: ParentSyllabusI[];
}

//===============================<END>GET LESSON PLAN<END>======================//

export type ParentSyllabusDispatchTypes =
  | GetParentSyllabusLoading
  | GetParentSyllabusError
  | GetParentSyllabusSuccess;
