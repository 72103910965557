import { Paper, Box } from '@material-ui/core';
import NepaliCalendarPage from '../../../../pages/Dashboard/Calendar/NepaliCalendar';
import BoardHeader from '../Calendar/BoardHeader';
import DueAlertsList from './RecentTransactionList';

const AccountantCalendar = () => {
  const date = new Date();

  const EnglishMonth = date.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <Paper elevation={1}>
      <Box p={[2.5, 3, 3, 3]}>
        <BoardHeader title="Nepali Calendar" date={EnglishMonth} to="/events" />
        <NepaliCalendarPage />
      </Box>
    </Paper>
  );
};

export default AccountantCalendar;
