import axios from "axios";
import { Dispatch } from "redux";

import {
  ADD_TEACHER_EXAM_MARKS_ERROR,
  ADD_TEACHER_EXAM_MARKS_LOADING,
  ADD_TEACHER_EXAM_MARKS_SUCCESS,
  GET_TEACHER_EXAMS_ERROR,
  GET_TEACHER_EXAMS_LOADING,
  GET_TEACHER_EXAMS_SUCCESS,
  GET_TEACHER_EXAM_MARKS_ERROR,
  GET_TEACHER_EXAM_MARKS_LOADING,
  GET_TEACHER_EXAM_MARKS_SUCCESS,
  GET_TEACHER_EXAM_SUBJECTS_ERROR,
  GET_TEACHER_EXAM_SUBJECTS_LOADING,
  GET_TEACHER_EXAM_SUBJECTS_SUCCESS,
  TeacherAddMarksDispatchTypes,
  CHANGE_FIELDS,
  RELOAD,
} from "./AddMarksActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

interface GetSubjectsRequestParams {
  grade: string;
  exam_info: string;
}
interface GetMarksRequestParams {
  grade: string;
  exam_subject: string;
  exam: string;
}
interface AddMarksRequestParams {
  grade: string;
  exam_subject: string;
  exam_info: string;
  is_practical: boolean;
}
export interface PostBulkDataI {
  student_info: string;
  marks_obtained_th: number | null;
  marks_obtained_pr: number | null;
  remarks: string;
}
interface ChangeFieldsParams {
  id: string;
  field: "marksTheory" | "marksPractical" | "remarks",
  value: string,
}

export const GetTeacherExams = () => async (dispatch: Dispatch<TeacherAddMarksDispatchTypes | SetSnackBarI>) => {
  try {
    dispatch({
      type: GET_TEACHER_EXAMS_LOADING,
    });
    const res = await axios.get(`${TEACHER_API_URL}/exam`, HeaderConfig())
    dispatch({
      type: GET_TEACHER_EXAMS_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    dispatch({
      type: GET_TEACHER_EXAMS_ERROR,
    })
  }
}

export const GetTeacherExamSubjects = (request_param: GetSubjectsRequestParams) => async (dispatch: Dispatch<TeacherAddMarksDispatchTypes | SetSnackBarI>) => {
  const { exam_info, grade } = request_param;
  try {
    dispatch({
      type: GET_TEACHER_EXAM_SUBJECTS_LOADING,
    });
    const res = await axios.get(`${TEACHER_API_URL}/exam_subject?grade=${grade}&exam_info=${exam_info}`, HeaderConfig())
    dispatch({
      type: GET_TEACHER_EXAM_SUBJECTS_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    dispatch({
      type: GET_TEACHER_EXAM_SUBJECTS_ERROR,
    })
    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "The exam you have selected does not have any subjects.",
      },
    });
  }
}

export const GetTeacherExamMarks = (request_param: GetMarksRequestParams) => async (dispatch: Dispatch<TeacherAddMarksDispatchTypes | SetSnackBarI>) => {
  const { exam_subject, grade, exam } = request_param;
  try {
    dispatch({
      type: GET_TEACHER_EXAM_MARKS_LOADING,
    });
    const res = await axios.get(`${TEACHER_API_URL}/exam-marks?grade=${grade}&exam_subject=${exam_subject}&exam=${exam}`, HeaderConfig())
    dispatch({
      type: GET_TEACHER_EXAM_MARKS_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    dispatch({
      type: GET_TEACHER_EXAM_MARKS_ERROR,
    })
  }
}

export const AddTeacherExamMarks = (bulk_data: PostBulkDataI[], request_params: AddMarksRequestParams) => async (dispatch: Dispatch<TeacherAddMarksDispatchTypes | SetSnackBarI>) => {
  const { exam_info, exam_subject, grade, is_practical } = request_params;
  try {
    dispatch({
      type: ADD_TEACHER_EXAM_MARKS_LOADING,
    })
    const res = await axios.post(`${TEACHER_API_URL}/exam-marks/save_bulk_student_marks_obtained/?grade=${grade}&exam_subject=${exam_subject}&exam_info=${exam_info}&is_practical=${is_practical}`, bulk_data, HeaderConfig())
    dispatch({
      type: ADD_TEACHER_EXAM_MARKS_SUCCESS,
      payload: res.data,
    })
    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Marks Updated Successfully",
      },
    });
  } catch (error: any) {
    dispatch({
      type: ADD_TEACHER_EXAM_MARKS_ERROR,
    })
    const msg = error.response.data.error[0];
    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: msg,
      },
    });
  }
}

export const ChangeFields = (request_params: ChangeFieldsParams) => async (dispatch: Dispatch<TeacherAddMarksDispatchTypes | SetSnackBarI>) => {
  dispatch({
    type: CHANGE_FIELDS,
    payload: request_params
  })
}

export const Reload = () => async (dispatch: Dispatch<TeacherAddMarksDispatchTypes | SetSnackBarI>) => {
  dispatch({
    type: RELOAD,
  })
}

