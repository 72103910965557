import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Grid,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import BraftEditor from 'braft-editor';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { SubmitStudentAssignment, SubmitStudentRedoAssignment, GetStudentSubmittedAssignmentbyID } from '../../../../../actions/StudentDash/StudentAssignment/StudentAssignmentAction';
import { TabPanel } from '../../../../../components/Student/StudentProfile/File/NewTabs';
import FileUploadZone from '../../../../../components/TeacherDash/Assignments/AssignmentForm/FileUploadZone';
import { RootStore } from '../../../../../store';
import { GetStudentProfile } from '../../../../../actions/StudentDash/StudentProfile/StudentProfileAction';
import { SubmitChildrenAssignment, GetChildrenSubmittedAssignmentbyID, SubmitChildrenRedoAssignment } from '../../../../../actions/ParentsDash/ChildrenAssignment/ChildrenAssignmentAction'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '1rem',
    },
    tabRoot: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      position: 'relative',
      minHeight: 200,
      '& .MuiPaper-elevation4': {
        boxShadow: 'none',
      },
      '& .MuiAppBar-colorDefault': {
        backgroundColor: '#fff',
      },
    },
    editor: {
      margin: theme.spacing(0, 2),
    },
    upload: {
      padding: theme.spacing(2),
    },
  })
);

interface Props {
  academicId?: string;
}

const ROLE = localStorage.getItem("role");

const SubmitAssignment = ({ academicId }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [editorState, setEditorState] = useState<any>();
  const [value, setValue] = React.useState(0);
  const [assignmentFiles, setAssignmentFiles] = useState<any>([]);

  const { assignmentbyID, redo_assignment, has_submitted } = useSelector(
    (state: RootStore) => state.student_assignment
  );
  const { assignment_detail, has_submitted: ParentSubmitted, redo_assignment: ParentRedo } = useSelector((state: RootStore) => state.children_assignment)

  useEffect(() => {
    ROLE === "Student" && dispatch(GetStudentProfile());
  }, []);

  useEffect(() => {
    assignmentbyID && assignmentbyID.review_status === 'Redo' && dispatch(GetStudentSubmittedAssignmentbyID(assignmentbyID.id))
  }, [assignmentbyID])

  console.log("detail", assignment_detail)

  useEffect(() => {
    assignment_detail && assignment_detail.review_status === 'Redo' && academicId && dispatch(GetChildrenSubmittedAssignmentbyID(academicId, assignment_detail.id))
  }, [assignment_detail])

  useEffect(() => {
    if (has_submitted) {
      ROLE === 'Student' && history.push('/assignment');
    }
  }, [has_submitted])

  useEffect(() => {
    if (ParentSubmitted) {
      ROLE === "Parent" && history.push('/children-assignment');
    }
  }, [ParentSubmitted])


  const { student } = useSelector((state: RootStore) => state.student_profile);

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };

  // Form submit
  const handleSubmit = () => {
    Promise.all(
      assignmentFiles.map(
        (item: any) =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = (file) => {
              resolve({
                file: file?.target?.result,
              });
            };
            fileReader.onerror = (error) => reject(error);
            fileReader.readAsDataURL(item);
          })
      )
    ).then((base64) => {
      if (assignmentbyID && ROLE === "Student") {
        const submitData = {
          assignment: assignmentbyID.id,
          student_academic: student.academic_info[0].id,
          ...(assignmentFiles.length === 0 &&
            editorState && { content: editorState.toHTML() }),
          submitted: true,
          ...(assignmentFiles.length > 0 &&
            !editorState && { attached_file: base64 }),
        };
        assignmentbyID.review_status === 'Redo' ? dispatch(SubmitStudentRedoAssignment(redo_assignment?.[0]?.id, submitData)) : dispatch(SubmitStudentAssignment(submitData));
      } else if (assignment_detail && ROLE === "Parent") {
        const submitData = {
          assignment: assignment_detail.id,
          student_academic: academicId,
          ...(assignmentFiles.length === 0 &&
            editorState && { content: editorState.toHTML() }),
          submitted: true,
          ...(assignmentFiles.length > 0 &&
            !editorState && { attached_file: base64 }),
        };
        assignment_detail.review_status === "Redo" && academicId ? dispatch(SubmitChildrenRedoAssignment(ParentRedo?.[0]?.id, academicId, submitData)) : dispatch(SubmitChildrenAssignment(submitData));
      }
    });
  };

  return (
    <Paper
      elevation={1}
      className={classes.root}
      style={{
        boxShadow: 'rgb(0 0 0 / 6%) 1px 1px 18px 1px',
        marginLeft: 0,
        marginTop: 20,
        borderRadius: 8,
        padding: '24px 16px',
      }}
    >
      <Grid container>
        <Grid xs={12} item spacing={3}>
          <div className={classes.tabRoot}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Choose your file" />
                <Tab label="Write on your own" />
              </Tabs>
              <TabPanel value={value} index={1}>
                <Paper className={classes.editor} elevation={0}>
                  <div
                    style={{
                      marginTop: '10px',
                      marginBottom: '24px',
                      border: '1px solid #ccc',
                    }}
                  >
                    <BraftEditor
                      language="en"
                      value={editorState}
                      onChange={(data) => setEditorState(data)}
                    />
                  </div>
                </Paper>
              </TabPanel>
              <TabPanel value={value} index={0}>
                <Paper className={classes.upload} elevation={0}>
                  {/* Implement Drag and Drop File upload */}
                  <FileUploadZone
                    files={assignmentFiles}
                    setFiles={setAssignmentFiles}
                  />
                </Paper>
              </TabPanel>
            </AppBar>

            <div style={{ padding: '4px 16px 12px 16px' }}>
              <Button
                variant="contained"
                color="primary"
                component="label"
                startIcon={<Send />}
                onClick={handleSubmit}
              >
                Submit Assignment
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SubmitAssignment;
