import {
  STUDENT_SEARCH_LOADING,
  STUDENT_SEARCH_ERROR,
  STUDENT_SEARCH_SUCCESS,
  StudentDispatchTypes,
  StudentListTypeI,
} from "../../actions/TeacherDash/TeacherStudentList/TeacherStudentListActionTypes";

interface InitialStateI {
  loading: boolean;
  student_list: StudentListTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  student_list: [],
};

const TeacherStudentListReducer = (
  state: InitialStateI = initialState,
  action: StudentDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case STUDENT_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };

    case STUDENT_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
      };

    case STUDENT_SEARCH_SUCCESS:
      return {
        loading: false,
        student_list: action.payload,
      };

    default:
      return state;
  }
};

export default TeacherStudentListReducer;
