import { Box, Grid } from "@material-ui/core";
import React, { useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import TeacherDashEnoteList from "./components/EnoteList";
import EnoteNav from "./components/EnoteNav";

interface CategoryNavI {
  id: string;
  name: string;
}

const TeacherEnotesPage = () => {
  const [categoryNav, setCategoryNav] = useState<CategoryNavI | null>(null);

  const onCategoryClick = (val: CategoryNavI) => {
    setCategoryNav(val);
  };
  return (
    <>
      <Box>
        <PageHeader title="Enotes" />
        <PageWrapper>
          <Grid container>
            <Grid item xs={3}>
              <EnoteNav categoryNav={onCategoryClick} />
            </Grid>
            <Grid item xs={9}>
              <TeacherDashEnoteList enoteCategory={categoryNav} />
            </Grid>
          </Grid>
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherEnotesPage;
