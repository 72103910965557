import {
  ChildrenAcademicInfoDispatchTypes,
  GET_CHILDREN_ASSIGNMENT_SUCCESS,
  GET_CHILDREN_ASSIGNMENT_ERROR,
  GET_CHILDREN_ASSIGNMENT_LOADING,
  GET_CHILDREN_ASSIGNMENT_DETAIL_ERROR,
  GET_CHILDREN_ASSIGNMENT_DETAIL_LOADING,
  GET_CHILDREN_ASSIGNMENT_DETAIL_SUCCESS,
  SUBMIT_CHILDREN_ASSIGNMENT_ERROR,
  SUBMIT_CHILDREN_ASSIGNMENT_LOADING,
  SUBMIT_CHILDREN_ASSIGNMENT_SUCCESS,
  GET_CHILDREN_SUBMITTED_ASSIGNMENT_LOADING,
  GET_CHILDREN_SUBMITTED_ASSIGNMENT_ERROR,
  GET_CHILDREN_SUBMITTED_ASSIGNMENT_SUCCESS,
} from "../../actions/ParentsDash/ChildrenAssignment/ChildrenAssignmentActionTypes";

interface InitialStateI {
  loading: boolean;
  children_assignments: any;
  errors: any;
  assignment_detail: any;
  submit_assignment: any;
  has_submitted: boolean;
  redo_assignment: any;
}

const initialState: InitialStateI = {
  loading: false,
  children_assignments: [],
  errors: null,
  assignment_detail: null,
  submit_assignment: [],
  has_submitted: false,
  redo_assignment: [],
};

const ChildrenAssignmentReducer = (
  state: InitialStateI = initialState,
  action: ChildrenAcademicInfoDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_CHILDREN_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        children_assignments: [],
      };
    case GET_CHILDREN_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case GET_CHILDREN_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        children_assignments: action.payload,
      };

    case GET_CHILDREN_ASSIGNMENT_DETAIL_LOADING:
      return {
        ...state,
        loading: true,
        assignment_detail: null,
      };
    case GET_CHILDREN_ASSIGNMENT_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case GET_CHILDREN_ASSIGNMENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        assignment_detail: action.payload,
      };

    case SUBMIT_CHILDREN_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        has_submitted: false,
      };

    case SUBMIT_CHILDREN_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        has_submitted: false,
      };

    case SUBMIT_CHILDREN_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        submit_assignment: action.payload,
        has_submitted: true,
      };

    case GET_CHILDREN_SUBMITTED_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        redo_assignment: [],
      };
    case GET_CHILDREN_SUBMITTED_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        redo_assignment: [],
      };
    case GET_CHILDREN_SUBMITTED_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        redo_assignment: action.payload,
      };

    default:
      return state;
  }
};

export default ChildrenAssignmentReducer;
