import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Paper,
    TextField,
    Grid,
    Button,
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { RootStore } from '../../../../store';
import { tblStyle } from '../../components/Styles/TeacherDashboardStyles';
import useTable from '../../../../components/Reusable/useStudentTable';
import { useTableStyles } from '../../../../components/Styles/TableStyles';
import getFullName from '../../../../components/utils/getFullName';
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import { ApplyStudentRubric } from "../../../../actions/TeacherDash/TeacherRubric/TeacherRubricAction"
import { StudentI, ApplyRubricPayload, StudentII } from "../../../../actions/TeacherDash/TeacherRubric/TeacherRubricActionTypes"
import { rubricGradeOptions, Tupple } from "../../../../common/json.constant"
import { RubricCategoryTypeI } from '../../../../actions/Academics/RubricCategory/RubricCategoryActionTypes'
import { TeachingClassTypeI } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";

interface HeadCellsI {
    id: string;
    label: string;
    filter?: boolean;
}
interface RubricTableProps {
    stateProps: {
        selectedRubricCategory: RubricCategoryTypeI | null;
        selectedClass: TeachingClassTypeI | null,
        tableData: StudentI[],
        setTableData: React.Dispatch<React.SetStateAction<StudentI[]>>
    }
}
// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
    { id: 'sn', label: 'S.N' },
    { id: 'student_name', label: 'Student Name' },
    { id: 'grade', label: 'Grade' },
    { id: 'remarks', label: 'Remarks' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

const RubricTable = ({ stateProps: { selectedRubricCategory, selectedClass, tableData, setTableData } }: RubricTableProps) => {
    const classes = useTableStyles();
    const styles = useFormStyles();
    const dispatch = useDispatch()

    const [studentData, setStudentData] = useState<StudentII[]>([])
    const [editMode, setEditMode] = useState<boolean>(false);
    const { loading, rubric_students } = useSelector((state: RootStore) => state.teacher_rubric)

    useEffect(() => {
        const temp: StudentII[] = []
        if (selectedClass !== null || selectedRubricCategory !== null) {
            setTableData(rubric_students)
            rubric_students.filter((student: StudentI) => student.student_rubric_grade.length > 0).map((item: StudentI) => {
                temp.push({
                    grade: item?.student_rubric_grade?.[0]?.grade,
                    remarks: item?.student_rubric_grade?.[0]?.remarks,
                    student: item?.id,
                })
                setStudentData(temp)
            })
        }
    }, [rubric_students])

    const { StyledTableCell, StyledTableRow, TableContainer } = useTable(headCells);
    const handleInputChange = (value: string, id: string) => {
        if (studentData.length > 0) {
            studentData.map((student: StudentII, index: number) => {
                if (student.student === id) {
                    const temp_state = [...studentData]
                    const temp_element = { ...temp_state[index] }
                    temp_element.remarks = value
                    temp_state[index] = temp_element;
                    setStudentData(temp_state)
                    return;
                }
            })
        }
    }
    const handleDropDownChange = (value: Tupple | null, id: string) => {
        if (value) {
            if (studentData.length === 0) {
                setStudentData(current => [...current, {
                    grade: value.value,
                    remarks: "",
                    student: id,
                }])
            } else {
                const filteredStudent: StudentII | undefined = studentData.filter((student: StudentII) => student.student === id)[0]
                if (filteredStudent) {
                    const index: number = studentData.findIndex((el: StudentII) => el.student === filteredStudent.student)
                    const temp_state = [...studentData]
                    const temp_element = { ...temp_state[index] }
                    temp_element.grade = value.value
                    temp_state[index] = temp_element;
                    setStudentData(temp_state)
                } else {
                    setStudentData(current => [...current, {
                        grade: value.value,
                        remarks: "",
                        student: id,
                    }])
                }
            }
        }
    }
    const handleEditClick = () => {
        setEditMode(true);
    }
    const handleSaveClick = () => {
        if (selectedRubricCategory && selectedClass) {
            const submitData: ApplyRubricPayload = {
                rubric: selectedRubricCategory.id,
                students: studentData,
            }
            dispatch(ApplyStudentRubric(submitData))
        }
        setEditMode(false);
    }

    const getCustomTableRow = (item: StudentI, index: number) => {
        const student = studentData.filter((student: StudentII) => item.id === student.student)[0]
        const gradeValue = rubricGradeOptions.filter((grade: Tupple) => grade.value === student?.grade)[0]
        const remarksValue = student?.remarks
        const fullName = getFullName(item.student.student_user.first_name, item.student.student_user.last_name)
        return (
            <StyledTableRow key={item.id}>
                <StyledTableCell align="center" className={classes.cell}>
                    {index + 1}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {fullName}
                </StyledTableCell>
                {editMode ?
                    <Autocomplete
                        classes={{
                            input: styles.smallfont,
                            option: styles.smallfont,
                        }}
                        onChange={(
                            event: React.ChangeEvent<{}>,
                            value: Tupple | null
                        ) => handleDropDownChange(value, item.id)}
                        options={rubricGradeOptions}
                        defaultValue={gradeValue}
                        getOptionLabel={(option) => option.key}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Rubric Grade"
                                variant="outlined"
                                name="grade"
                            />
                        )}
                    />
                    :
                    <StyledTableCell align="center" className={classes.cell}>
                        {gradeValue?.key || "N/A"}
                    </StyledTableCell>}
                {editMode ? (
                    <StyledTableCell align="center" className={classes.cell}>
                        <TextField
                            type="text"
                            onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                                handleInputChange(event.target.value, item.id)
                            }
                            id={String(item.id)}
                            key={String(item.id)}
                            defaultValue={remarksValue}
                            variant="outlined"
                            disabled={gradeValue ? false : true}
                        />
                    </StyledTableCell>
                ) : (
                    <StyledTableCell align="center" className={classes.cell}>
                        {remarksValue}
                    </StyledTableCell>
                )}
            </StyledTableRow>
        );
    };

    return (
        <Paper style={tblStyle}>
            <TableContainer
                label="Rubric List"
                loading={loading}
                items={tableData}
                disableSearch
                headCells={headCells}
                getCustomTableRow={getCustomTableRow}
            />
            {tableData.length ? (
                <Grid container justifyContent="flex-end">
                    <Grid item xs={3}>
                        <Grid container justifyContent="flex-end">
                            {!editMode ? (
                                <Button
                                    onClick={handleEditClick}
                                    className={classes.addMarksButton}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<EditIcon />}
                                >
                                    Edit
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleSaveClick}
                                    className={classes.addMarksButton}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveIcon />}
                                >
                                    Save
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
        </Paper>
    )
}

export default RubricTable