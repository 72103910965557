import { Box } from "@material-ui/core";
import StudentLiveClassList from "./StudentLiveClassList";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import { crumbs } from "../MyTeacher/MyTeacherPage";

const MyLiveClassesPage = () => {
  return (
    <Box>
      <PageHeader title="Live Classes" module={crumbs} />
      <PageWrapper>
        <StudentLiveClassList />
      </PageWrapper>
    </Box>
  );
};

export default MyLiveClassesPage;
