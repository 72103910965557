import StudentAdmissionForm from '../../../../components/Student/StudentAdmission/StudentAdmissionForm';
import { Box } from "@material-ui/core";
import PageHeader from '../../components/Header/PageHeader';
import PageWrapper from '../../components/PageWrapper';
import { GetStudentProfile2 } from '../../../../actions/Student/StudentProfile/StudentProfileAction'
import { RootStore } from '../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

const StudentProfileUpdate = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetStudentProfile2());
    }, []);

    const student = useSelector(
        (state: RootStore) => state.student_dash_profile.student_profile
    );

    return (
        <Box>
            <PageHeader title="Update Profile" />
            <PageWrapper>
                <StudentAdmissionForm student={student} />
            </PageWrapper>
        </Box>
    )
};

export default StudentProfileUpdate;