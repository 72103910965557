import React, { useState } from 'react';
import { useSidebar } from '../../../components/Reusable';
import { useSidebarStyles } from '../../../components/Styles/SidebarStyles';
import { DonutLargeOutlined, MenuBookOutlined } from '@material-ui/icons';
import SidebarTitle from '../../../components/Reusable/Titles/SidebarTitle';

const AccountantEventSidebar = (props: any) => {
  const classes = useSidebarStyles();
  const [events] = useState(props.events);

  const dataSet = [{ text: 'Events', url: 'events', activeStatus: events }];
  const { CustomSidebar } = useSidebar(dataSet, 'FINANCE');
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<DonutLargeOutlined />}>Event</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default AccountantEventSidebar;
