// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import 'nepali-datepicker-reactjs/dist/index.css';
import AddNewButton from '../../../../../components/Reusable/Buttons/AddNewButton.test';
import CustomizedDialogs from '../../../../../components/Reusable/Dialogs/DeleteDialog';
import { SmsTemplateTypeI } from '../../../../../actions/Communication/SmsTemplate/SmsTemplateActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import {
  DeleteSmsTemplate,
  GetSmsTemplates,
} from '../../../../../actions/Communication/SmsTemplate/SmsTemplateAction';
import { Link } from 'react-router-dom';
import { TeacherEnotesI } from '../../../../../actions/TeacherDash/TeacherEnotes/TeacherEnotesActionTypes';
import { GetTeacherEnotes } from '../../../../../actions/TeacherDash/TeacherEnotes/TeacherEnotesAction';
import { Autocomplete } from '@material-ui/lab';
import { GetTeachingClass } from '../../../../../actions/TeacherDash/TeachingClass/TeachingClassAction';
import { TeacherClassSectionI } from '../../../../../actions/TeacherDash/TeacherClass/TeacherClassActionTypes';
import { GetTeachingSubject } from '../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectAction';
import { TeacherSubjectByClass } from '../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes';
import { StudentEnotesI } from '../../../../../actions/StudentDash/StudentEnote/StudentEnoteActionTypes';
import Popups from '../../../../../components/Reusable/Dialogs/Popups';
import TeacherEnotesModal from './EnoteModalView';

import EnoteCard from './EnoteCard';
import { tableStyle } from '../../../components/Styles/TeacherDashboardStyles';

// -----------------<END> imports module ends -----------------------//
interface PropsI {
  enoteCategory: EnoteCategoryI | null;
}

interface EnoteCategoryI {
  id: string;
  name: string;
}

interface SectionII {
  id: string;
  name: string;
}
interface ClassII {
  id: string;
  grade: string;
  grade_name: string;
  section: SectionII[] | null | string;
  section_name?: string;
}

// -----------------<START> Leave Search component starts-----------------------//
const TeacherDashEnoteList = (props: PropsI) => {
  const { enoteCategory } = props;
  const classes = useFormStyles();

  const [enoteModalOpen, setEnoteModalOpen] = useState<boolean>(false);
  const [modalItem, setModalItem] = useState<StudentEnotesI | null>(null);
  const [itemId, setItemId] = useState<number | null>(null);

  const [classChoices, setClassChoices] = useState<ClassII[]>([]);
  const [selectedClass, setSelectedClass] = useState<ClassII | null>(null);

  const [subjectList, setSubjectList] = useState<TeacherSubjectByClass[]>([]);
  const [selectedSubject, setSelectedSubject] =
    useState<TeacherSubjectByClass | null>(null);

  const [subjectDisabler, setSubjectDisabler] = useState<boolean>(true);
  const [templatesData, setTemplatesData] = useState<any[]>([]);

  // ======================== <START> REACT HOOKS <START> ==================================//
  const dispatch = useDispatch();
  const templateSelector = useSelector(
    (state: RootStore) => state.sms_template
  );
  const enoteSelector = useSelector((state: RootStore) => state.teacher_enotes);
  const classSelector = useSelector((state: RootStore) => state.teaching_class);
  const subjectSelector = useSelector(
    (state: RootStore) => state.teacher_subject
  );

  useEffect(() => {
    setSelectedClass(null);
    setSelectedSubject(null);
    setSubjectDisabler(true);
    setTemplatesData([]);
  }, [enoteCategory]);

  useEffect(() => {
    const data: StudentEnotesI[] = enoteSelector.enotes;
    setTemplatesData(data);
  }, [enoteSelector]);

  useEffect(() => {
    if (selectedClass) {
      const section: any = selectedClass.section ? selectedClass.section : '';
      dispatch(GetTeachingSubject(selectedClass.grade, section));
    }
  }, [selectedClass]);

  useEffect(() => {
    setSubjectList(subjectSelector.subject_by_class);
  }, [subjectSelector.subject_by_class]);

  useEffect(() => {
    dispatch(GetTeachingClass());
  }, []);

  useEffect(() => {
    const classes = classSelector.teaching_class;
    const finalArray: ClassII[] = [];
    classes.forEach((item: any) => {
      if (item.section === null) {
        finalArray.push(item);
      } else {
        for (let i = 0; i < item.section.length; i++) {
          const data1 = {
            ...item,
            section: item.section[i].id,
            section_name: item.section[i].name,
          };
          finalArray.push(data1);
        }
      }
    });
    setClassChoices(finalArray);
  }, [classSelector.teaching_class]);

  // ======================== <END> REACT HOOKS <END> ==================================//

  const handleClassChange = (value: ClassII | null) => {
    setSelectedClass(value);
    setSelectedSubject(null);
    setTemplatesData([]);

    if (value) {
      const section: any = value.section ? value.section : '';
      dispatch(GetTeachingSubject(value.grade, section));
      setSubjectDisabler(false);
    } else {
      setSubjectDisabler(true);
    }
  };

  const handleSubjectChange = (value: TeacherSubjectByClass | null) => {
    setSelectedSubject(value);
    if (value && selectedClass && enoteCategory) {
      dispatch(
        GetTeacherEnotes(selectedClass.grade, value.id, enoteCategory.id)
      );
    }
    !value && setTemplatesData([]);
  };

  const handleViewModalOpen = (data: StudentEnotesI) => {
    setModalItem(data);
    setEnoteModalOpen(true);
  };

  return (
    <Paper style={tableStyle}>
      <Grid container justifyContent="space-between">
        <Grid item xs={6} className={classes.formTitle}>
          <Typography variant="h6">
            {enoteCategory ? enoteCategory.name : ''}
          </Typography>
        </Grid>
        <Grid item>
          <Link to="/enote-add" style={{ textDecoration: 'none' }}>
            <AddNewButton
              variant="outlined"
              color="primary"
              title="Add New Enote"
            >
              New Enote
            </AddNewButton>
          </Link>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {/* <Grid item>
          <Button variant="contained" color="secondary">
            My section (A)
          </Button>
        </Grid>

        <Grid item>
          <Button variant="outlined" color="secondary">
            Section B
          </Button>
        </Grid>

        <Grid item>
          <Button variant="outlined" color="secondary">
            Section C
          </Button>
        </Grid> */}
        <Grid item xs={5} className={classes.formWrapper}>
          <Autocomplete
            onChange={(event: React.ChangeEvent<{}>, value: ClassII | null) =>
              handleClassChange(value)
            }
            options={classChoices}
            value={selectedClass}
            getOptionLabel={(option) =>
              `Class ${option.grade_name} ${
                option.section_name !== undefined
                  ? `(${option?.section_name})`
                  : ''
              }`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Class"
                name="class_name"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={5} className={classes.formWrapper}>
          <Autocomplete
            onChange={(
              event: React.ChangeEvent<{}>,
              value: TeacherSubjectByClass | null
            ) => handleSubjectChange(value)}
            options={subjectList}
            value={selectedSubject}
            disabled={subjectDisabler}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Subject"
                name="class_name"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {templatesData.map((template: StudentEnotesI, index: number) => (
          <Grid item md={6} key={index}>
            <EnoteCard item={template} handleView={handleViewModalOpen} />
          </Grid>
        ))}
      </Grid>

      {/* Delete modal dialog
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      /> */}

      <Popups
        openPopup={enoteModalOpen}
        setOpenPopup={setEnoteModalOpen}
        onClose={() => setEnoteModalOpen(false)}
        fullWidth={true}
        maxWidth="md"
        title="Enote"
        colored
      >
        <TeacherEnotesModal
          item={modalItem}
          handleModalClose={setEnoteModalOpen}
        />
      </Popups>
    </Paper>
  );
};

//-----------------<ENDS> Leave Search component ends-----------------------//
export default TeacherDashEnoteList;
