import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_LESSON_PLAN_LOADING,
  GET_LESSON_PLAN_ERROR,
  GET_LESSON_PLAN_SUCCESS,
  ADD_LESSON_PLAN_LOADING,
  ADD_LESSON_PLAN_ERROR,
  ADD_LESSON_PLAN_SUCCESS,
  DELETE_LESSON_PLAN_LOADING,
  DELETE_LESSON_PLAN_ERROR,
  DELETE_LESSON_PLAN_SUCCESS,
  UPDATE_LESSON_PLAN_LOADING,
  UPDATE_LESSON_PLAN_ERROR,
  UPDATE_LESSON_PLAN_SUCCESS,
  COMPLETE_LESSON_PLAN_LOADING,
  COMPLETE_LESSON_PLAN_ERROR,
  COMPLETE_LESSON_PLAN_SUCCESS,
  LessonPlanDispatchTypes,
} from "./LessonPlanActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetLessonPlan =
  (subject: string, grade: string) =>
  async (dispatch: Dispatch<LessonPlanDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_LESSON_PLAN_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/lesson_plan/?subject=${subject}&grade=${grade}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_LESSON_PLAN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LESSON_PLAN_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Lesson Plan Loading Failed",
      //   },
      // });
    }
  };

export const AddLessonPlan =
  (data: any) =>
  async (dispatch: Dispatch<LessonPlanDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_LESSON_PLAN_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/lesson_plan/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_LESSON_PLAN_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Lesson Plan Added Successsfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_LESSON_PLAN_ERROR,
        payload: error?.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Lesson Plan Adding Failed",
        },
      });
    }
  };

export const UpdateLessonPlan =
  (id: number, data: any) =>
  async (dispatch: Dispatch<LessonPlanDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_LESSON_PLAN_LOADING,
      });
      const res = await axios.put(
        `${TEACHER_API_URL}/lesson_plan/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_LESSON_PLAN_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Lesson Plan Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_LESSON_PLAN_ERROR,
        payload: error?.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Lesson Plan Update Failed",
        },
      });
    }
  };

export const DeleteLessonPlan =
  (id: number) =>
  async (dispatch: Dispatch<LessonPlanDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_LESSON_PLAN_LOADING,
      });

      const res = await axios.delete(
        `${TEACHER_API_URL}/lesson_plan/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_LESSON_PLAN_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Lesson Plan Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_LESSON_PLAN_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Lesson Plan Delete Failed",
        },
      });
    }
  };

export const CompleteLessonPlan =
  (id: number, data: any) =>
  async (dispatch: Dispatch<LessonPlanDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: COMPLETE_LESSON_PLAN_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/lesson_plan/completion_course/?lesson_plan=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: COMPLETE_LESSON_PLAN_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Lesson Plan Completed Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: COMPLETE_LESSON_PLAN_ERROR,
      });
    } finally {
      try {
        dispatch({
          type: GET_LESSON_PLAN_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/lesson_plan/?subject=${data.subID}&grade=${data.classID.grade}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_LESSON_PLAN_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_LESSON_PLAN_ERROR,
        });

        // dispatch({
        //   type: SET_SNACKBAR,
        //   payload: {
        //     snackbarOpen: true,
        //     snackbarType: "error",
        //     snackbarMessage: "Lesson Plan Loading Failed",
        //   },
        // });
      }
    }
  };
