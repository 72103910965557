import { Paper, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../store";
import StaffDocument from "../../../../components/HumanResource/StaffList/StaffProfile/StaffTabs/StaffDocument";
import { TabPanel } from "../../../../components/Student/StudentProfile/File/NewTabs";
import { usefullCardStyles } from "../../../../components/Styles/CardStyles";
import StudentAcademic from "./StudentTabs/StudentAcademic";
import StudentDashTransportation from "./StudentTabs/Transportation";
// import { Switch, Redirect } from "react-router-dom";
// import PrivateRoute from '../../../../components/utils/PrivateRoute';
// import StudentProfileUpdate from './StudentProfileUpdate';

const StudentTabs = (props: any) => {
  const classes = usefullCardStyles();
  const [value, setValue] = React.useState(0);
  const [staff, setStaff] = useState<any>([]);

  const staffList = useSelector(
    (state: RootStore) => state.staffProfile.staff_profile
  );

  useEffect(() => {
    if (staffList != null) {
      setStaff(staffList);
    }
  }, [staffList]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  console.log(props.studentData);


  return (
    <>
      <Paper elevation={0} className={classes.tabRoot}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Academic Info" />
          <Tab label="Documents" />
          <Tab label="Transportation" />
          {/* <Tab label="Update Profile" /> */}
        </Tabs>

        <TabPanel value={value} index={0}>
          <StudentAcademic student={props.studentData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StaffDocument
            hasUpload={false}
            hasDeleteBtn={false}
            Documents={props.studentData?.student_document}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <StudentDashTransportation student={props.studentData} />
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <StudentProfileUpdate student={props.studentData} />
        </TabPanel> */}
      </Paper>
    </>
  );
};

export default StudentTabs;
