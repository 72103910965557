import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Grid,
    Paper,
    TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useForm } from "react-hook-form";
import { filterStyle } from "../../../StudentDashboard/StudentDashboardStyles";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import { InputBox } from "../../../../components/Reusable/Inputs/Select";
import { TeachingClassTypeI } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";
import { SectionTypeI } from "../../../../actions/Academics/Section/SectionActionTypes";
import { SectionI } from '../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes'
import { RubricCategoryTypeI } from '../../../../actions/Academics/RubricCategory/RubricCategoryActionTypes'
import { SubmitButton, ResetButton } from "../../../../components/Reusable/Buttons/SearchButton";
import { GetTeachingClass } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassAction";
import { RootStore } from "../../../../store";
import { GetApplicableRubric, GetStudentRubric } from "../../../../actions/TeacherDash/TeacherRubric/TeacherRubricAction"
import { StudentI } from "../../../../actions/TeacherDash/TeacherRubric/TeacherRubricActionTypes"

interface RubricSearchProps {
    stateProps: {
        selectedRubricCategory: RubricCategoryTypeI | null,
        setSelectedRubricCategory: React.Dispatch<React.SetStateAction<RubricCategoryTypeI | null>>,
        selectedClass: TeachingClassTypeI | null,
        setSelectedClass: React.Dispatch<React.SetStateAction<TeachingClassTypeI | null>>,
        selectedSection: SectionTypeI | null,
        setSelectedSection: React.Dispatch<React.SetStateAction<SectionTypeI | null>>,
        setTableData: React.Dispatch<React.SetStateAction<StudentI[]>>
    }
}

const RubricSearch = ({ stateProps: { selectedClass, setSelectedClass, selectedSection, setSelectedSection, selectedRubricCategory, setSelectedRubricCategory, setTableData } }: RubricSearchProps) => {
    const dispatch = useDispatch();
    const classes = useFormStyles();
    const { register, handleSubmit } = useForm();

    // State Declaration
    const [classChoices, setClassChoices] = useState<TeachingClassTypeI[]>([])
    const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
    const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
    const [rubricCategoryChoices, setRubricCategoryChoices] = useState<RubricCategoryTypeI[]>([])

    // const [rubricSubCategoryChoices, setRubricSubCategoryChoices] = useState<RubricCategoryTypeI[] | []>([]);
    // const [selectedRubricSubCategory, setSelectedRubricSubCategory] = useState<RubricCategoryTypeI | null>(null)
    // const [rubricSubCategoryDisabler, setRubricSubCategoryDisabler] = useState<boolean>(true);

    // UseEffect & UseSelector hooks
    const classSelector = useSelector((state: RootStore) => state.teaching_class);
    const { applicable_rubric, action_performed } = useSelector((state: RootStore) => state.teacher_rubric)
    useEffect(() => {
        dispatch(GetApplicableRubric())
        dispatch(GetTeachingClass());
    }, []);
    useEffect(() => {
        action_performed && apiCall()
    }, [action_performed])
    useEffect(() => {
        classSelector.teaching_class && setClassChoices(classSelector.teaching_class);
    }, [classSelector]);
    useEffect(() => {
        applicable_rubric && setRubricCategoryChoices(applicable_rubric?.[0]?.applicable_rubric_category)
    }, [applicable_rubric])


    const populateSectionChoices = (sections: SectionI[] | null) => {
        if (sections === null) return;
        if (sections.length > 0) {
            setSectionDisabler(false);
            setSectionChoices(sections);
        } else {
            setSectionChoices([]);
            setSectionDisabler(true);
        }
    };

    // const populateRubricSubCategoryChoices = (value: RubricCategoryTypeI[] | null) => {
    //     if (value === null) return;
    //     if (value.length > 0) {
    //         setRubricSubCategoryDisabler(false)
    //         setRubricSubCategoryChoices(value)
    //     } else {
    //         setRubricSubCategoryChoices([])
    //         setRubricSubCategoryDisabler(true)
    //     }
    // }

    const handleClassChange = (value: TeachingClassTypeI | null) => {
        setSelectedClass(value);
        setSelectedSection(null);
        if (value) {
            populateSectionChoices(value.section);
        } else {
            setSelectedSection(null);
            setSectionDisabler(true);
        }
    };

    const handleSectionChange = (value: SectionTypeI | null) => {
        setSelectedSection(value);
    };

    const handleRubricCategoryChange = (value: RubricCategoryTypeI | null) => {
        setSelectedRubricCategory(value)
        // setSelectedRubricSubCategory(null);
        // if (value) {
        //     populateRubricSubCategoryChoices(value?.subCategory ? value.subCategory : []);
        // } else {
        //     setSelectedRubricSubCategory(null);
        //     setRubricSubCategoryDisabler(true);
        // }
    }

    // const handleRubricSubCategoryChange = (value: RubricCategoryTypeI | null) => {
    //     setSelectedRubricSubCategory(value);
    // }
    const onSearchClick = () => {
        apiCall()

    };
    const apiCall = () => {
        if (selectedClass && selectedRubricCategory) {
            const section = selectedSection ? selectedSection.id : null;
            dispatch(GetStudentRubric(selectedClass.grade, section, selectedRubricCategory.id))
        }
    }
    const handleReset = () => {
        setSelectedClass(null);
        setSelectedSection(null);
        setSelectedRubricCategory(null);
        setTableData([])
    };

    return (
        <Paper style={filterStyle}>
            <Box className={classes.formTitle}>
                <span>Search Class</span>
            </Box>
            <form className={classes.form} onSubmit={handleSubmit(onSearchClick)}>
                <Grid container>
                    <Grid item md={2} className={classes.formWrapperRow}>
                        <InputBox>Class</InputBox>
                        <Autocomplete
                            classes={{
                                input: classes.smallfont,
                                option: classes.smallfont,
                            }}
                            fullWidth
                            onChange={(
                                event: React.ChangeEvent<{}>,
                                value: TeachingClassTypeI | null
                            ) => handleClassChange(value)}
                            options={classChoices}
                            value={selectedClass}
                            getOptionLabel={(option) => option.grade_name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Label"
                                    variant="outlined"
                                    name="grade"
                                    inputRef={register({ required: true })}
                                />
                            )}
                        />
                        <span className={classes.validationErrorInfo} />
                    </Grid>
                    <Grid item md={2} className={classes.formWrapperRow}>
                        <InputBox>Section</InputBox>
                        <Autocomplete
                            classes={{
                                input: classes.smallfont,
                                option: classes.smallfont,
                            }}
                            fullWidth
                            value={selectedSection}
                            onChange={(
                                event: React.ChangeEvent<{}>,
                                value: SectionTypeI | null
                            ) => handleSectionChange(value)}
                            options={sectionChoices}
                            getOptionLabel={(option) => option.name}
                            disabled={sectionDisabler}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Label"
                                    variant="outlined"
                                    name="section"
                                    inputRef={register}
                                />
                            )}
                        />
                        <span className={classes.validationErrorInfo} />
                    </Grid>

                    {/* Rubric */}
                    <Grid item md={3} className={classes.formWrapperRow}>
                        <InputBox>Category</InputBox>
                        <Autocomplete
                            classes={{
                                input: classes.smallfont,
                                option: classes.smallfont,
                            }}
                            fullWidth
                            onChange={(
                                event: React.ChangeEvent<{}>,
                                value: RubricCategoryTypeI | null
                            ) => handleRubricCategoryChange(value)}
                            options={rubricCategoryChoices}
                            value={selectedRubricCategory}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Label"
                                    variant="outlined"
                                    name="Rubric Category"
                                    inputRef={register({ required: true })}
                                />
                            )}
                        />
                        <span className={classes.validationErrorInfo} />
                    </Grid>
                    {/* <Grid item md={3} className={classes.formWrapperRow}>
                        <InputBox>Sub-Category</InputBox>
                        <Autocomplete
                            classes={{
                                input: classes.smallfont,
                                option: classes.smallfont,
                            }}
                            fullWidth
                            value={selectedRubricSubCategory}
                            onChange={(
                                event: React.ChangeEvent<{}>,
                                value: RubricCategoryTypeI | null
                            ) => handleRubricSubCategoryChange(value)}
                            options={rubricSubCategoryChoices}
                            getOptionLabel={(option) => option.name}
                            disabled={rubricSubCategoryDisabler}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Label"
                                    variant="outlined"
                                    name="Rubric Sub-Category"
                                    inputRef={register}
                                />
                            )}
                        />
                        <span className={classes.validationErrorInfo} />
                    </Grid> */}
                    <SubmitButton style={{ marginTop: "5px" }} />
                    <ResetButton style={{ marginTop: "5px" }} onClick={handleReset} />
                </Grid>
            </form>
        </Paper>
    )
}

export default RubricSearch