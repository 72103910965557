import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { ApplyFeeToStudents } from '../../../actions/Fees/FeeType/FeeAction';
import { ApplyMiscFeeType } from '../../../actions/Fees/MiscFee/MiscFeeTypeAction';
import { setSnackbar } from '../../../actions/SnackbarAction';
import { RootStore } from '../../../store';
import AccountantFeeSettingsSidebar from '../../../components/Event/EventSidebar/AccountantFeeSettingsSidebar';
import BackDropLoader from '../../../components/Reusable/BackDropLoader';
import SubModuleLayout from '../../../components/Reusable/Layouts/SubModuleLayout';
import FinanceSidebar from '../../../components/Finance/FinanceSidebar';
import MiscFeeForm from '../../../components/Finance/Misc/MiscFeeForm';
import MiscFeeList from '../../../components/Finance/Misc/MiscFeeList';
import MiscStudentTable from '../../../components/Finance/Misc/MiscStudentTable';

export default function AccountMiscFeePage() {
  const [fees, setFees] = useState<string[]>([]);
  const [students, setStudents] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<any | null>(null);
  const [count, setCount] = useState<number>(0);
  const [edit, setEdit] = useState<any>();
  const [grade, setGrade] = useState<any>(null);
  const [section, setSection] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const gradeLoading = useSelector((state: RootStore) => state.class.loading);
  const feeLoading = useSelector((state: RootStore) => state.fee_type.loading);
  const studentLoading = useSelector(
    (state: RootStore) => state.student.loading
  );

  useEffect(() => {
    if (!gradeLoading && !feeLoading && !studentLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [gradeLoading, feeLoading, studentLoading]);

  const nav = <AccountantFeeSettingsSidebar miscFee />;

  const handleFeeChange = (ids: string[]) => {
    setFees([...ids]);
  };

  const handleStudentChange = (ids: string[]) => {
    setStudents([...ids]);
  };

  const onGradeSection = (grade: string, section: string) => {
    setGrade(grade);
    setSection(section);
  };

  const onEditCheck = (value: boolean) => {
    setEdit({ check: value, count: count });
  };

  const onApplyFee = () => {
    const post_data = {
      students,
      fees,
    };
    setIsBtnLoading(true);
    dispatch(ApplyMiscFeeType(grade?.grade, section, post_data));
  };

  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={12}></Grid>
        <Grid container>
          <Grid item xs={4}>
            <MiscFeeForm
              onEditCheck={onEditCheck}
              editData={formData}
              setGrade={setGrade}
            />
          </Grid>
          <Grid item xs={3}>
            <MiscFeeList
              grade={grade}
              setGrade={setGrade}
              onFeeChange={handleFeeChange}
              onGradeSection={onGradeSection}
            />
          </Grid>
          <Grid item xs={5}>
            <MiscStudentTable
              isBtnLoading={isBtnLoading}
              setIsBtnLoading={setIsBtnLoading}
              grade={grade}
              onStudentChange={handleStudentChange}
              onApplyFee={onApplyFee}
            />
          </Grid>
        </Grid>
      </Grid>
      <BackDropLoader open={loading} />
    </SubModuleLayout>
  );
}
