export const GET_PARENT_STUDENT_TEACHERS_LOADING =
  "GET_PARENT_STUDENT_TEACHERS_LOADING";
export const GET_PARENT_STUDENT_TEACHERS_ERROR =
  "GET_PARENT_STUDENT_TEACHERS_ERROR";
export const GET_PARENT_STUDENT_TEACHERS_SUCCESS =
  "GET_PARENT_STUDENT_TEACHERS_SUCCESS";

export interface ParentStudentTeachersI {
  teacher__id: number;
  teacher_first_name: string;
  teacher_last_name: string;
  subject__id: number;
  subject_name: string;
}

//===============================<START>GET LESSON PLAN<START>======================//

export interface GetParentStudentTeachersLoading {
  type: typeof GET_PARENT_STUDENT_TEACHERS_LOADING;
}

export interface GetParentStudentTeachersError {
  type: typeof GET_PARENT_STUDENT_TEACHERS_ERROR;
}

export interface GetParentStudentTeachersSuccess {
  type: typeof GET_PARENT_STUDENT_TEACHERS_SUCCESS;
  payload: ParentStudentTeachersI[];
}

//===============================<END>GET LESSON PLAN<END>======================//

export type ParentStudentTeachersDispatchTypes =
  | GetParentStudentTeachersLoading
  | GetParentStudentTeachersError
  | GetParentStudentTeachersSuccess;
