import {
  GET_CHILDREN_FEE_INVOICE_ERROR,
  GET_CHILDREN_FEE_INVOICE_LOADING,
  GET_CHILDREN_FEE_INVOICE_SUCCESS,
  GET_CHILDREN_FEE_PAID_INVOICE_ERROR,
  GET_CHILDREN_FEE_PAID_INVOICE_LOADING,
  GET_CHILDREN_FEE_PAID_INVOICE_SUCCESS,
  ChildrenFeeDispatchTypes,
} from "../../actions/ParentsDash/ChildrenFee/ChildrenFeeActionTypes";

interface InitialStateI {
  loading: boolean;
  invoice: any;
  paid_invoice: any[];
}

const initialState: InitialStateI = {
  loading: false,
  invoice: null,
  paid_invoice: [],
};

const ChildrenFeeReducer = (
  state: InitialStateI = initialState,
  action: ChildrenFeeDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_CHILDREN_FEE_INVOICE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CHILDREN_FEE_INVOICE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CHILDREN_FEE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: action.payload,
      };

    case GET_CHILDREN_FEE_PAID_INVOICE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CHILDREN_FEE_PAID_INVOICE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CHILDREN_FEE_PAID_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        paid_invoice: action.payload,
      };

    default:
      return state;
  }
};

export default ChildrenFeeReducer;
