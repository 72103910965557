import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";
import React from "react";
import defaultPlaceholder from "../../../assets/images/defaultPlaceholder";
import { useCardStyles } from "../../Styles/CardStyles";

function CardLayout(props: any) {
  const classes = useCardStyles();

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea className={classes.cardbg}>
          <Avatar className={classes.profileImg} alt="" src={props.imgUrl} />
        </CardActionArea>
        <CardContent>
          <Typography
            className={classes.title}
            align="left"
            gutterBottom
            color="textPrimary"
            variant="h4"
            component="h3"
          >
            {props.fullName}
          </Typography>
          {props.headData.map((item: any) => {
            return (
              <div key={item.id} className={classes.content}>
                <Typography
                  variant="body2"
                  color="inherit"
                  component="p"
                  style={{ minWidth: "90px" }}
                >
                  {item.headCells}
                </Typography>
                <Typography variant="body2" component="p">
                  {item.cellData}
                </Typography>
              </div>
            );
          })}
        </CardContent>
      </Card>
    </>
  );
}

export default CardLayout;
