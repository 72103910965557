import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_TIMETABLE_LOADING,
  GET_STUDENT_TIMETABLE_ERROR,
  GET_STUDENT_TIMETABLE_SUCCESS,
  GET_STUDENT_PERIOD_ERROR,
  GET_STUDENT_PERIOD_LOADING,
  GET_STUDENT_PERIOD_SUCCESS,
  StudentTimeTableDispatchTypes,
} from "./StudentTimeTableActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { STUDENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetStudentTimeTable =
  () =>
  async (dispatch: Dispatch<StudentTimeTableDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_TIMETABLE_LOADING,
      });

      const res = await axios.get(
        `${STUDENT_API_URL}/timetable/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STUDENT_TIMETABLE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_TIMETABLE_ERROR,
      });
    }
  };

export const GetStudentPeriods =
  () =>
  async (dispatch: Dispatch<StudentTimeTableDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_PERIOD_LOADING,
      });

      const res = await axios.get(
        `${STUDENT_API_URL}/periods/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STUDENT_PERIOD_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_PERIOD_ERROR,
      });
    }
  };
