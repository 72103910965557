import {
  Grid,
  Paper,
  InputLabel,
  FormControl,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Tupple } from '../../../../actions';
import {
  AddStudentLeave,
  GetStudentDashLeave,
  UpdateStudentLeave,
} from '../../../../actions/StudentDash/StudentLeave/StudentDashLeaveAction';
import { StudentLeaveTypeI } from '../../../../actions/StudentDash/StudentLeave/StudentDashLeaveActionTypes';
import { RootStore } from '../../../../store';
import { FormSendButon } from '../../../../components/Reusable/Buttons/FormButton';
import CustomizedNepaliDatePicker from '../../../../components/Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect from '../../../../components/Reusable/Inputs/Select';
import CustomizedTextField from '../../../../components/Reusable/Inputs/TextField';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import { dateConverterBsToAd } from '../../../../components/utils/dateConverter';
import firstWordCapital from '../../../../components/utils/firstWordCapital';
import formsValidationCheckup from '../../../../components/utils/formsValidationCheckUp';
import { CUR_NEPALI_DATE } from '../../../../components/utils/nepaliDateUtils';
import { isObjectEmpty } from '../../../../components/utils/utils';
import { CusRadioGroup } from '../../../../components/Reusable/Inputs/Radio';
import { Send as SendIcon } from '@material-ui/icons';

const StudentLeaveEditModal = (props: any) => {
  const { editable, item } = props;
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, setError, clearErrors } =
    useForm<any>();

  const [dateFrom, setDateFrom] = useState<string>(CUR_NEPALI_DATE);
  const [dateTo, setDateTo] = useState<string>(CUR_NEPALI_DATE);
  const [leaveType, setLeaveType] = useState<Tupple | null>(null);
  const [serverErrors, setServerErrors] = useState<any>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  // const leaveTypeState = useSelector((state: RootStore) => state.leave_type);
  const studentLeave = useSelector(
    (state: RootStore) => state.studentDash_leave
  );
  const errorsData = studentLeave.errors;
  const loading = studentLeave.loading;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    dispatch(GetStudentDashLeave());
  }, []);

  /* Close modal when user has submitted form, if state is not loading,
  has not server errors and has no hook-form errors */
  useEffect(() => {
    if (hasSubmitted && !loading) {
      if (!errorsData && isObjectEmpty(errors)) {
        props.onClose();
      }
    }
  }, [studentLeave, hasSubmitted]);

  // Set hook-form error on server error
  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData != null && errorsData?.error) {
        const keys = Object.keys(errorsData.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  // const LEAVE_TYPE_CHOICES = leaveTypeState.leave_types.map((item) => ({
  //   key: item.id.toString(),
  //   value: item.name,
  // }));

  useEffect(() => {
    if (editable) {
      setDateFrom(item.leave_date);
      setDateTo(item.leave_to);
      setValue('reason_for_leave', item.reason_for_leave);
      // setLeaveType(
      //   LEAVE_TYPE_CHOICES.find(
      //     (childItem: any) => childItem.key === item.leave_type_id
      //   ) || LEAVE_TYPE_CHOICES[0]
      // );
    }
  }, [editable]);

  //------------------------------VALIDATION ------------------------------------//
  useEffect(() => {
    if (errorsData?.error != null) {
      const keys = Object.keys(errorsData?.error);
      keys.map((elem: any) => {
        setError(elem, {
          type: 'serverSideError',
          message: errorsData.error[elem] && errorsData.error[elem][0],
        });
      });
    }
  }, [errorsData]);

  //-------------------<START> React hooks forms starts ----------------------//

  const onSubmit = (data: any) => {
    if (data && dateFrom && dateTo /* && leaveType */) {
      const leaveData: any = {
        // leave_type: leaveType.key,
        leave_from: dateConverterBsToAd(dateFrom),
        leave_to: dateConverterBsToAd(dateTo),
        reason_for_leave: data.reason_for_leave,
        student_academic: data.student_academic,
      };

      if (editable) {
        dispatch(UpdateStudentLeave(item.id, leaveData));
      } else {
        dispatch(AddStudentLeave(leaveData));
        dispatch(GetStudentDashLeave());
      }
      setHasSubmitted(true);
    }
  };

  const handleDateFrom = (value: any) => {
    setDateFrom(value?.toString());
    clearErrors('leave_from');
    clearErrors('error');
  };

  const handleDateTo = (value: any) => {
    setDateTo(value?.toString());
    clearErrors('leave_to');
    clearErrors('error');
  };

  return (
    <>
      <Paper elevation={0}>
        <div className={classes.modalContent} style={{ marginTop: '12px' }}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            {serverErrors &&
              serverErrors?.error &&
              Object.keys(serverErrors.error)
                .filter((elem: any) => elem === 'error')
                .map((elem: any, index: number) => {
                  return (
                    <div className={classes.serversidemessages} key={index}>
                      <Alert severity="error">
                        {firstWordCapital(serverErrors?.error[elem][0])}
                      </Alert>
                    </div>
                  );
                })}

            <Grid container>
              {/* <Grid container spacing={1}>
                <Grid item xs={6} className={classes.formWrapper}>
                  <CustomizedSelect
                    label="Leave Type"
                    name="leave_type"
                    options={LEAVE_TYPE_CHOICES}
                    value={leaveType}
                    setValue={setLeaveType}
                    error={errors['leave_type'] && true}
                    inputRef={register({ required: true })}
                    required
                  />
                  {formsValidationCheckup({
                    errors: errors,
                    inputName: 'leave_type',
                    requiredMessage: 'Leave type must be selected',
                    serverSideMessage:
                      errors.leave_type?.type === 'serverSideError' &&
                      errors.leave_type.message,
                  })}
                </Grid>
              </Grid> */}

              <Grid container spacing={1} item xs={12}>
                <Grid item xs={6} className={classes.formWrapper}>
                  <CustomizedNepaliDatePicker
                    label="Leave from Date"
                    value={dateFrom}
                    setValue={handleDateFrom}
                    name="leave_from"
                    required
                  />
                  {formsValidationCheckup({
                    errors: errors,
                    inputName: 'leave_from',
                    requiredMessage: 'Leave from date is Required',
                    serverSideMessage:
                      errors.leave_from?.type === 'serverSideError' &&
                      errors.leave_from.message,
                  })}
                </Grid>

                <Grid item xs={6} className={classes.formWrapper}>
                  <CustomizedNepaliDatePicker
                    label="Leave to Date"
                    value={dateTo}
                    setValue={handleDateTo}
                    name="leave_to"
                    required
                  />
                  {formsValidationCheckup({
                    errors: errors,
                    inputName: 'leave_to',
                    requiredMessage: 'Leave to date is Required',
                    serverSideMessage:
                      errors.leave_to?.type === 'serverSideError' &&
                      errors.leave_to.message,
                  })}
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.formWrapper}>
                <CustomizedTextField
                  label="Reason for Leave"
                  placeholder="Label"
                  name="reason_for_leave"
                  required
                  multiline
                  rows={4}
                  error={errors['reason_for_leave'] && true}
                  inputRef={register({ required: true })}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'leave_type',
                  requiredMessage: 'Required Field.',
                  serverSideMessage:
                    errors.leave_type?.type === 'serverSideError' &&
                    errors.leave_type.message,
                })}
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-end"
              style={{ paddingBottom: '8px' }}
            >
              <Button
                startIcon={<SendIcon />}
                variant="contained"
                color="primary"
                type="submit"
                style={{ minWidth: 'auto', width: 'auto' }}
              >
                {editable && 'Update Leave Request'}
                {!editable && 'Send Leave Request'}
              </Button>
            </Grid>
          </form>
        </div>
      </Paper>
    </>
  );
};

export default StudentLeaveEditModal;
