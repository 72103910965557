import {
  Box,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import StudentProfileCard from '../Card/StudentProfileCard';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../Breadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
  content1: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '240px',
    background: '#f0f0fc',
  },
  widget: {
    padding: '15px 20px 20px 20px',
    position: 'relative',
    width: '370px',
    background: '#f0f0fc',
  },
  classbutton: {
    opacity: 1,
    fontSize: '10px',
    backgroundColor: '#3FC581',
    color: '#fff',
    textTransform: 'none',
    fontWeight: 600,
    borderRadius: '4px',
    padding: '6px 10px',
    minHeight: '20px',
    minWidth: '120px',
    marginRight: '16px',
    '& .MuiTab-root': {
      padding: 0,
      minWidth: '120px',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
  },
}));

const PageHeader = (props: any) => {
  const classes = useStyles();

  return (
    <Box className={classes.content1}>
      <Box style={{ flex: '1', padding: '16px 24px' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              variant="h5"
              color="primary"
              style={{ marginTop: '10px' }}
            >
              <span style={{ fontWeight: 600 }}>{props.title}</span>
            </Typography>
            <BreadCrumbs module={props.module} />
          </Grid>

          <Grid item>
            <Button
              component={Link}
              to="/classroom/online-class"
              className={classes.classbutton}
            >
              Classroom
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.widget}>
        <StudentProfileCard />
      </Box>
    </Box>
  );
};

export default PageHeader;
