import {
  GET_TEACHER_MEETING_LOADING,
  GET_TEACHER_MEETING_ERROR,
  GET_TEACHER_MEETING_SUCCESS,
  ADD_TEACHER_MEETING_LOADING,
  ADD_TEACHER_MEETING_ERROR,
  ADD_TEACHER_MEETING_SUCCESS,
  DELETE_TEACHER_MEETING_LOADING,
  DELETE_TEACHER_MEETING_ERROR,
  DELETE_TEACHER_MEETING_SUCCESS,
  TeacherMeetingDispatchTypes,
  MeetingPropsI,
  TEACHER_APPROVE_OR_DECLINE_ERROR,
  TEACHER_APPROVE_OR_DECLINE_LOADING,
  TEACHER_APPROVE_OR_DECLINE_SUCCESS,
} from "../../../actions/TeacherDash/TeacherMeeting/TeacherMeetingActionType";

interface InitialStateI {
  loading: boolean;
  teacher_meeting: MeetingPropsI[];
  errors: any;
}

const initialState: InitialStateI = {
  loading: false,
  teacher_meeting: [],
  errors: null,
};

const TeacherMeetingReducer = (
  state: InitialStateI = initialState,
  action: TeacherMeetingDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_MEETING_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case GET_TEACHER_MEETING_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case GET_TEACHER_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        teacher_meeting: action.payload,
      };

    case ADD_TEACHER_MEETING_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };

    case ADD_TEACHER_MEETING_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case ADD_TEACHER_MEETING_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false,
        teacher_meeting: [...state.teacher_meeting, action.payload],
      };

    case DELETE_TEACHER_MEETING_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };

    case DELETE_TEACHER_MEETING_ERROR:
      return {
        ...state,
        errors: null,
        loading: false,
      };

    case TEACHER_APPROVE_OR_DECLINE_SUCCESS:
      const temp_data = state.teacher_meeting;
      const indexNum: number = temp_data.findIndex(
        (element) => element.id === action.payload.id
      );
      const temp_meeting = {
        ...temp_data[indexNum],
        status: action.payload.status,
      };
      temp_data[indexNum] = temp_meeting;

      return {
        ...state,
        loading: false,
        teacher_meeting: temp_data,
        errors: null,
      };
    case TEACHER_APPROVE_OR_DECLINE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case TEACHER_APPROVE_OR_DECLINE_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case DELETE_TEACHER_MEETING_SUCCESS:
      const new_data: MeetingPropsI[] = state.teacher_meeting;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        errors: null,
        teacher_meeting: new_data,
      };

    default:
      return state;
  }
};

export default TeacherMeetingReducer;
