import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_SYLLABUS_LOADING,
  GET_SYLLABUS_ERROR,
  GET_SYLLABUS_SUCCESS,
  GET_SUB_UNIT_LOADING,
  GET_SUB_UNIT_ERROR,
  GET_SUB_UNIT_SUCCESS,
  SyllabusDispatchTypes,
  StudentSyllabusTypeI,
} from "./StudentSyllabusActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import {
  STUDENT_API_URL,
  TEACHER_API_URL,
} from "../../../config/SERVER_API_URLS";

export const GetStudentSyllabus = (subject: number) => async (
  dispatch: Dispatch<SyllabusDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_SYLLABUS_LOADING,
    });

    const res = await axios.get(
      `${STUDENT_API_URL}/unit/?subject=${subject}`,
      HeaderConfig()
    );

    dispatch({
      type: GET_SYLLABUS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SYLLABUS_ERROR,
    });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     snackbarMessage: "Syllabus Loading Failed",
    //   },
    // });
  }
};

export const GetStudentSubUnit = (id: number) => async (
  dispatch: Dispatch<SyllabusDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_SUB_UNIT_LOADING,
    });

    const res = await axios.get(
      `${STUDENT_API_URL}/unit_content/?unit=${id}`,
      HeaderConfig()
    );

    dispatch({
      type: GET_SUB_UNIT_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SUB_UNIT_ERROR,
    });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     snackbarMessage: "Sub Unit Loading Failed",
    //   },
    // });
  }
};
