import { Paper, TableCell, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteTeacherComplaint,
  GetTeacherComplaint,
} from "../../../../actions/TeacherDash/TeacherComplain/TeacherComplainAction";
import { TeacherComplaintTypeI } from "../../../../actions/TeacherDash/TeacherComplain/TeacherComplainActionTypes";
import { StudentSearchByTeacher } from "../../../../actions/TeacherDash/TeacherStudentList/TeacherStudentListAction";
import {
  ItemViewButton,
  ItemEditButton,
  ItemDeleteButton,
} from "../../../../components/Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../../../components/Reusable/Dialogs/DeleteDialog";
import Popups from "../../../../components/Reusable/Dialogs/Popups";
import useTable from "../../../../components/Reusable/useStudentTable";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { dateConverterAdToBs } from "../../../../components/utils/dateConverter";
import getFullName from "../../../../components/utils/getFullName";
import { RootStore } from "../../../../store";
import { tableStyle } from "../../components/Styles/TeacherDashboardStyles";
import TeacherComplainView from "./TeacherComplainView";

interface PropsI {
  onEditData: (value: TeacherComplaintTypeI) => void;
  editCheck: { check: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "student", label: "Student" },
  { id: "class", label: "Class" },
  { id: "date", label: "Date" },
  { id: "status", label: "Status" },
  { id: "description", label: "Description" },
  { id: "action", label: "Action" },
];

const TeacherComplainTable = (props: PropsI) => {
  const { onEditData, editCheck } = props;
  const classes = useTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [viewData, setViewData] = useState<any[] | null>(null);
  const [editMode, setEditMode] = useState(false);

  const [tableData, setTableData] = useState<TeacherComplaintTypeI[]>([]);

  //========================= <START> REDUX HOOKS <START> ==============================//
  const dispatch = useDispatch();
  const teacherComplainSelector = useSelector(
    (state: RootStore) => state.teacher_complain
  );
  const teacherComplainLoading = useSelector(
    (state: RootStore) => state.teacher_complain.loading
  );

  // useEffect(() => {
  //   dispatch(GetTeacherComplaint());
  // }, []);
  useEffect(() => {
    setLoading(teacherComplainLoading);
  }, [teacherComplainLoading]);

  useEffect(() => {
    if (teacherComplainSelector.student_complaint != null) {
      const teacherComplainList = teacherComplainSelector.student_complaint;
      setTableData(teacherComplainList);
    }
  }, [teacherComplainSelector]);
  console.log(tableData);
  //========================= <END> REDUX HOOKS <END> ==============================//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  useEffect(() => {
    editCheck && setEditMode(editCheck?.check);
  }, [editCheck]);

  //==================================== <START> EVENT HANDLERS <START> ==========================//
  const handleEditClicked = (item: TeacherComplaintTypeI) => {
    setEditMode(true);
    onEditData(item);
  };

  const handleDeleteModal = (value: boolean, id: number) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteTeacherComplaint(itemId));
    setDeleteModalOpen(false);
  };

  const [openPopup, setOpenPopup] = useState(false);

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleComplainView = (item: any) => {
    setOpenPopup(true);
    setViewData(item);
  };

  //==================================== <END> EVENT HANDLERS <END> ==========================//
  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(
            item.student_info.first_name,
            item.student_info.last_name
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(item.student_info.grade, item.student_info.section)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.created_on)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.status}
        </StyledTableCell>
        <TableCell align="left" className={classes.cell}>
          {item.description.slice(0, 20) + "..."}
        </TableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleComplainView(item)} />
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper style={tableStyle}>
        <Typography variant="h6">Feedback List</Typography>

        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="student_name"
          search_param="teacher_feedback"
        />
        <CustomizedDialogs
          open={deleteModalOpen}
          onClose={handleModalClose}
          deleteConfirmed={onDeleteConfirmed}
        />
        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          title="Student Feedback"
          colored
        >
          <TeacherComplainView viewData={viewData} />
        </Popups>
      </Paper>
    </>
  );
};

export default TeacherComplainTable;
