import { Box } from '@material-ui/core';
import React from 'react';
import PageHeader from '../../dashboard/TeacherDashboard/components/Header/PageHeader';
import PageWrapper from '../../dashboard/TeacherDashboard/components/PageWrapper';
import MyAttendanceReport from '../../dashboard/TeacherDashboard/pages/Attendance/MyAttendanceReport';
import SearchReport from '../../dashboard/TeacherDashboard/pages/Attendance/SearchReport';
import TeacherDashSidebar from '../../dashboard/TeacherDashboard/components/TeacherDashSidebar';

const MyAttendancePage = () => {
  return (
    <Box>
      <PageHeader
        title="My Attendance"
        module={{ name: 'Academics', to: '/my-subjects' }}
      />
      <PageWrapper>
        <SearchReport />
        <MyAttendanceReport />
      </PageWrapper>
    </Box>
  );
};

export default MyAttendancePage;
