import {
  Box,
  Button,
  Chip,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
  createStyles,
  List,
  ListItem,
  ListItemText,
  ListItemProps,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Divider,
} from '@material-ui/core';
import { Add, ChevronRight } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { GetTeachingSubject } from '../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectAction';
import { TeacherSubjectByClass } from '../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes';
import { GetTeachingClass } from '../../../../../actions/TeacherDash/TeachingClass/TeachingClassAction';
import { TeachingClassTypeI } from '../../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes';

import { RootStore } from '../../../../../store';
import Popups from '../../../../../components/Reusable/Dialogs/Popups';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import SyllabusNewCreate from './SyllabusNewCreate';
import SyllabusTable from './SyllabusTable';
import {
  filterStyle,
  tableStyle,
} from '../../../components/Styles/TeacherDashboardStyles';

interface FormI {
  class: number | null;
  gradeID: number | null;
  grade: string;
}

interface SectionII {
  id: string;
  name: string;
}
interface ClassII {
  id: string;
  grade: string;
  grade_name: string;
  section: SectionII[] | null | string;
  section_name?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // margin: theme.spacing(2, 0, 2, 4),
      backgroundColor: theme.palette.background.paper,
      '& .MuiTypography-body1': {
        fontSize: '15px',
        textAlign: 'left',
      },
      '& .MuiTypography-body2': {
        fontSize: '11px',
      },
      '& .Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        '& p': {
          color: theme.palette.secondary.main,
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        },
      },
    },
  })
);

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

const SyllabusManage = () => {
  const classess = useFormStyles();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedClass, setSelectedClass] = useState<ClassII | null>(null);
  const [classChoices, setClassChoices] = useState<ClassII[]>([]);
  const [subjectList, setSubjectList] = useState<TeacherSubjectByClass[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [tempSubject, setTempSubject] = useState<TeacherSubjectByClass>();
  const [openCreateModalPopup, setOpenCreateModalPopup] = useState(false);
  const { register, handleSubmit, errors } = useForm<FormI>();

  const classSelector = useSelector((state: RootStore) => state.teaching_class);
  const subjectSelector = useSelector(
    (state: RootStore) => state.teacher_subject
  );
  const syllabusSelector = useSelector(
    (state: RootStore) => state.teacher_syllabus.add_unit
  );

  useEffect(() => {
    if (selectedClass) {
      const section: any = selectedClass.section ? selectedClass.section : '';
      dispatch(GetTeachingSubject(selectedClass.grade, section));
    }
  }, [selectedClass]);

  useEffect(() => {
    setSubjectList(subjectSelector.subject_by_class);
    subjectSelector?.subject_by_class &&
      setTempSubject(subjectSelector?.subject_by_class[0]);
  }, [selectedClass, subjectSelector]);

  useEffect(() => {
    dispatch(GetTeachingClass());
  }, []);

  useEffect(() => {
    const classes = classSelector.teaching_class;
    const finalArray: ClassII[] = [];
    classes.forEach((item: any) => {
      if (item.section === null) {
        finalArray.push(item);
      } else {
        for (let i = 0; i < item.section.length; i++) {
          const data1 = {
            ...item,
            section: item.section[i].id,
            section_name: item.section[i].name,
          };
          finalArray.push(data1);
        }
      }
    });
    setClassChoices(finalArray);
    setSelectedClass(finalArray[0]);
  }, [classSelector.teaching_class]);

  const handleCreateModalClose = (value: boolean) => {
    setOpenCreateModalPopup(value);
  };

  const handleClassChange = (value: ClassII | null) => {
    value != null && setSelectedClass(value);
    setTempSubject(undefined);
    setSelectedIndex(0);
  };

  const handleCreateUnit = () => {
    setOpenCreateModalPopup(true);
  };

  const handleListItemClick = (
    event: any,
    index: number,
    item: TeacherSubjectByClass
  ) => {
    setSelectedIndex(index);
    setTempSubject(item);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <Paper style={filterStyle}>
            <div className={classes.root}>
              <Typography variant="h6">Syllabus</Typography>
              <Divider />
              {subjectList &&
                subjectList.map(
                  (item: TeacherSubjectByClass, index: number) => {
                    return (
                      <List
                        key={index}
                        component="nav"
                        aria-label="subject list"
                      >
                        <ListItemLink
                          selected={selectedIndex === index}
                          onClick={(event) =>
                            handleListItemClick(event, index, item)
                          }
                        >
                          <ListItemText primary={item.name} />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="comments">
                              <ChevronRight />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItemLink>
                      </List>
                    );
                  }
                )}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Paper style={tableStyle}>
            <form className={classess.form}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={4} className={classess.formWrapper}>
                  <Autocomplete
                    classes={{
                      input: classess.smallfont,
                      option: classess.smallfont,
                    }}
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: ClassII | null
                    ) => handleClassChange(value)}
                    options={classChoices}
                    value={selectedClass}
                    getOptionLabel={(option) =>
                      `Class ${option.grade_name} ${
                        option.section_name !== undefined
                          ? `(${option?.section_name})`
                          : ''
                      }`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Label"
                        name="class_name"
                        inputRef={register({ required: true })}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Chip
                    style={{ marginTop: '10px' }}
                    variant="default"
                    size="small"
                    label={`${syllabusSelector?.results?.length || 0} Units`}
                    color="primary"
                  />
                </Grid>
                <Grid style={{ marginTop: '5px' }} item xs={3}>
                  <Button
                    startIcon={<Add />}
                    variant="outlined"
                    color="primary"
                    onClick={handleCreateUnit}
                  >
                    New Syllabus
                  </Button>
                </Grid>
              </Grid>
            </form>
            <SyllabusTable subject={tempSubject} grade={selectedClass?.id} />
          </Paper>
        </Grid>
      </Grid>

      <Popups
        openPopup={openCreateModalPopup}
        setOpenPopup={setOpenCreateModalPopup}
        onClose={handleCreateModalClose}
        fullWidth={true}
        maxWidth="md"
        title={`Create Unit`}
        colored
      >
        {/* <SyllabusCreate onClose={handleCreateModalClose} /> */}
        <SyllabusNewCreate
          subject={tempSubject}
          grade={selectedClass?.id}
          onClose={handleCreateModalClose}
        />
      </Popups>
    </>
  );
};

export default SyllabusManage;
