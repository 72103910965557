import {
  GET_SYLLABUS_LOADING,
  GET_SYLLABUS_ERROR,
  GET_SYLLABUS_SUCCESS,
  GET_SUB_UNIT_LOADING,
  GET_SUB_UNIT_ERROR,
  GET_SUB_UNIT_SUCCESS,
  SyllabusDispatchTypes,
  StudentSyllabusTypeI,
  StudentSubUnitTypeI,
} from "../../actions/StudentDash/StudentSyllabus/StudentSyllabusActionTypes";

interface InitialStateI {
  loading: boolean;
  add_unit: any;
  sub_unit: any;
}

const initialState: InitialStateI = {
  loading: false,
  add_unit: [],
  sub_unit: [],
};

const StudentSyllabusReducer = (
  state: InitialStateI = initialState,
  action: SyllabusDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_SYLLABUS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SYLLABUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SYLLABUS_SUCCESS:
      return {
        ...state,
        loading: false,
        add_unit: action.payload,
      };

    case GET_SUB_UNIT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SUB_UNIT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SUB_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        sub_unit: action.payload,
      };

    default:
      return state;
  }
};

export default StudentSyllabusReducer;
