import { BsCardChecklist } from 'react-icons/bs';
import { FaChalkboardTeacher, FaUsers } from 'react-icons/fa';
import { GiTeacher } from 'react-icons/gi';
import { GoTasklist, GoReport } from 'react-icons/go';
import { HiSpeakerphone, HiAcademicCap } from 'react-icons/hi';
import { IoMdHand } from 'react-icons/io';
import { MdEventNote, MdAssignment } from 'react-icons/md';
import { SiGooglehangouts } from "react-icons/si"
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NoteIcon from '@material-ui/icons/Note';
import EnhancedSidebar from '../../components/NewSideBar/EnhancedSidebar';

const dataSet: any = [
	{
		text: "Dashboard",
		url: "/",
		icon: DashboardIcon,
	},
	{
		text: "My Children",
		url: "/view-child",
		icon: FaUsers,
	},
	{
		text: "Child's Live Class",
		url: "/live-class",
		icon: GiTeacher,
	},
	{
		text: "Child's Lesson Plan",
		url: "/lesson-plan",
		icon: GoTasklist,
	},
	{
		text: "Academics",
		url: "/",
		activeStatus: false,
		icon: HiAcademicCap,
		dataSet: [
			{
				text: "Teachers",
				url: "/child-teachers",
				icon: FaChalkboardTeacher,

			},
			{
				text: "TimeTable",
				url: "/timetable",
				icon: MdEventNote,

			},
			{
				text: "Assignment",
				url: "/children-assignment",
				icon: MdAssignment,

			},
			{
				text: "Syllabus",
				url: "/syllabus",
				icon: BsCardChecklist,

			},
		],
	},
	{
		text: "Enotes",
		url: "/enotes",
		icon: NoteIcon,
	},
	{
		text: "Announcement",
		url: "/announcement",
		icon: HiSpeakerphone,
	},
	{
		text: "Events",
		url: "/events",
		icon: EventNoteIcon,
	},
	{
		text: "Leave Request",
		url: "/leave-request",
		icon: ExitToAppIcon,
	},
	{
		text: "Meeting Request",
		url: "/meeting-request",
		icon: SiGooglehangouts,
	},
	{
		text: "Reports",
		url: "/",
		activeStatus: false,
		icon: GoReport,
		dataSet: [
			{
				text: "Academic",
				url: "/academic-report",
				icon: HiAcademicCap,
			},
			{
				text: "Attendance",
				url: "/attendance-report",
				icon: IoMdHand,
			},
		],
	},
];

const ParentDashboardSidebar = () => {
	return <EnhancedSidebar open={false} items={dataSet} />;
};

export default ParentDashboardSidebar;
