import { Paper, Box } from '@material-ui/core';
import BoardHeader from '../Calendar/BoardHeader';
import DueAlertsList from './DueAlertsList';

const DueAlertsWidget = (props: any) => {
  const date = new Date();

  const EnglishMonth = date.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <Paper elevation={1}>
      <Box p={[2.5, 3, 3, 3]}>
        <BoardHeader title="Due Alerts" date={EnglishMonth} to="/finance" />
        <DueAlertsList />
      </Box>
    </Paper>
  );
};

export default DueAlertsWidget;
