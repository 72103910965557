export const GET_STUDENT_SUBJECTS_LOADING = "GET_STUDENT_SUBJECTS_LOADING";
export const GET_STUDENT_SUBJECTS_ERROR = "GET_STUDENT_SUBJECTS_ERROR";
export const GET_STUDENT_SUBJECTS_SUCCESS = "GET_STUDENT_SUBJECTS_SUCCESS";

export interface StudentSubjectI {
  grade: string;
  section: string;
  grade_name: string;
  section_name: string | undefined;
  subject: string;
  subject_type: string;
  subject_name: string;
  subject_credit_hour: string;
}

//===============================<START>GET STUDENT SUBJECTS<START>======================//

export interface GetStudentSubjectsLoading {
  type: typeof GET_STUDENT_SUBJECTS_LOADING;
}

export interface GetStudentSubjectsError {
  type: typeof GET_STUDENT_SUBJECTS_ERROR;
}

export interface GetStudentSubjectsSuccess {
  type: typeof GET_STUDENT_SUBJECTS_SUCCESS;
  payload: StudentSubjectI[];
}

//===============================<END>GET STUDENT SUBJECTS<END>======================//

export type StudentSubjectsDispatchTypes =
  | GetStudentSubjectsLoading
  | GetStudentSubjectsError
  | GetStudentSubjectsSuccess;
