import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_CHECKIN_CHECKOUT_LOADING,
  GET_CHECKIN_CHECKOUT_ERROR,
  GET_CHECKIN_CHECKOUT_SUCCESS,
  POST_CHECKIN_CHECKOUT_ERROR,
  CheckinCheckoutDispatchTypes,
  POST_CHECKIN_CHECKOUT_SUCCESS,
  POST_CHECKIN_CHECKOUT_LOADING,
  GetCheckinCheckoutTypeI,
} from "./TeacherAttendanceActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetCheckinCheckoutCommon =
  (from: string, to: string) =>
  async (dispatch: Dispatch<CheckinCheckoutDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_CHECKIN_CHECKOUT_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/staff_attendance/?from_date=${from}&to_date=${to}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_CHECKIN_CHECKOUT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CHECKIN_CHECKOUT_ERROR,
      });
    }
  };

export const GetCheckinCheckout =
  (from: string, to: string) =>
  async (dispatch: Dispatch<CheckinCheckoutDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_CHECKIN_CHECKOUT_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/staff_attendance/?from_date=${from}&to_date=${to}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_CHECKIN_CHECKOUT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CHECKIN_CHECKOUT_ERROR,
      });
    }
    // finally {
    //   try {
    //     dispatch({
    //       type: GET_CHECKIN_CHECKOUT_LOADING,
    //     });

    //     const res = await axios.get(
    //       `${TEACHER_API_URL}/staff_attendance/?from_date=${from}&to_date=${to}`,
    //       HeaderConfig()
    //     );

    //     dispatch({
    //       type: GET_CHECKIN_CHECKOUT_SUCCESS,
    //       payload: res.data,
    //     });
    //   } catch (error) {
    //     dispatch({
    //       type: GET_CHECKIN_CHECKOUT_ERROR,
    //     });
    //   }
    // }
  };

export const PostCheckinCheckout =
  (data: any, date: string) =>
  async (dispatch: Dispatch<CheckinCheckoutDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: POST_CHECKIN_CHECKOUT_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/staff_attendance/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: POST_CHECKIN_CHECKOUT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: POST_CHECKIN_CHECKOUT_ERROR,
      });
    } finally {
      try {
        dispatch({
          type: GET_CHECKIN_CHECKOUT_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/staff_attendance/?from_date=${date}&to_date=${date}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_CHECKIN_CHECKOUT_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_CHECKIN_CHECKOUT_ERROR,
        });
      }
    }
  };
