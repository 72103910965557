import { Box } from "@material-ui/core";
import { useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import RubricSearch from "./RubricSearch"
import RubricTable from "./RubricTable"
import { RubricCategoryTypeI } from '../../../../actions/Academics/RubricCategory/RubricCategoryActionTypes'
import { TeachingClassTypeI } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";
import { SectionTypeI } from "../../../../actions/Academics/Section/SectionActionTypes";
import { StudentI } from "../../../../actions/TeacherDash/TeacherRubric/TeacherRubricActionTypes"


const RubricPage = () => {
    const [selectedRubricCategory, setSelectedRubricCategory] = useState<RubricCategoryTypeI | null>(null)
    const [selectedClass, setSelectedClass] = useState<TeachingClassTypeI | null>(null)
    const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(null)
    const [tableData, setTableData] = useState<StudentI[]>([]);
    return (
        <>
            <Box>
                <PageHeader
                    title="Rubric"
                />
                <PageWrapper>
                    <RubricSearch stateProps={{ selectedRubricCategory, setSelectedRubricCategory, selectedClass, setSelectedClass, selectedSection, setSelectedSection, setTableData }} />
                    <RubricTable stateProps={{ selectedRubricCategory, selectedClass, tableData, setTableData }} />
                </PageWrapper>
            </Box>
        </>
    )
}

export default RubricPage