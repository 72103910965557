export const MY_ATTENDANCE_LOADING = "MY_ATTENDANCE_LOADING";
export const MY_ATTENDANCE_ERROR = "MY_ATTENDANCE_ERROR";
export const MY_ATTENDANCE_SUCCESS = "MY_ATTENDANCE_SUCCESS";

export interface MyAttendanceI {
  id: number;
  student_academic: number;
  date: string;
  statue: string;
}

export interface QueryI {
  from_date: string;
  to_date: string;
}

//===============================<START>GET My_ATTENDANCE<START>======================//

export interface MyAttendanceLoading {
  type: typeof MY_ATTENDANCE_LOADING;
}

export interface MyAttendanceError {
  type: typeof MY_ATTENDANCE_ERROR;
}

export interface MyAttendanceSuccess {
  type: typeof MY_ATTENDANCE_SUCCESS;
  payload: MyAttendanceI;
}

export type MyAttendanceDispatchTypes =
  | MyAttendanceLoading
  | MyAttendanceError
  | MyAttendanceSuccess;
