import { Button, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { useForm } from "react-hook-form";
import { useFormStyles } from "../../../../../components/Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import { GetStudentAcademicMark } from "../../../../../actions/ParentsDash/AcademicInfo/StudentAcademicInfoAction";
import { GetChildren } from "../../../../../actions/ParentsDash/ViewChild/ViewChildAction";
import getFullName from "../../../../../components/utils/getFullName";
import CustomizedSelect from "../../../../../components/Reusable/Inputs/Select";
import formsValidationCheckup from "../../../../../components/utils/formsValidationCheckUp";
import { GetExams } from "../../../../../actions/Examination/Exam/ExamAction";
import SearchFormHeaderLayout from "../../../../../components/Reusable/Layouts/SearchFormHeaderLayout";
import { filterStyle } from "../../../ParentDashboardStyles";
import { SubmitButton } from "../../../../../components/Reusable/Buttons/SearchButton";
// -----------------<start> Interfaces <start>-----------------------//

interface FormI {
  academic_year: string | null;
  class: number | null;
  section: string | null;
  rollNumber: string | number | null;
}

interface DataI {
  academic_year: string | number | null | undefined;
  class: number | null | undefined;
  section: number | string | null | undefined;
}

interface AcademicYearI {
  id: number;
  year: string;
}

interface SectionI {
  id: number;
  title: string;
}

interface ClassI {
  id: number;
  title: string;
  sections: SectionI[] | [];
}

// -----------------<end> States <end>-----------------------//

const ExamReportSearch = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm<FormI>({
    mode: "onChange",
  });
  const [children, setChildren] = useState<any | null>(null);
  const [subject, setSubject] = useState<any | null>(null);

  useEffect(() => {
    dispatch(GetChildren());
    dispatch(GetExams());
  }, []);

  const classState = useSelector((state: RootStore) => state.teacher_class);
  const allExams = useSelector((state: RootStore) => state.exam);
  const { view_child } = useSelector((state: RootStore) => state.view_child);
  console.log(children)
  const studentAcademicState = useSelector(
    (state: RootStore) => state.student_academic
  );

  const SELECT_CHILDREN: any[] = view_child.map(
    (elem: any) => ({
      key: elem.academic_info[0].id.toString(),
      value: getFullName(elem.student_user.first_name, elem.student_user.last_name),
    })
  );

  const SELECT_SUBJECT_CHOICES: any[] = allExams.exams.map(
    (subjectItem: any) => ({
      key: subjectItem.id.toString(),
      value: subjectItem.exam_name,
    })
  );

  const handleChildren = (value: ClassI | null) => {
    setChildren(value);
  };

  const onSubmit = () => {
    const searchData = {
      student_academic_id: children.key,
      exam_info: 3,
    };
    dispatch(GetStudentAcademicMark(searchData));
  };

  const handleReset = () => {
    setChildren(null);
    setSubject(null);
    dispatch(
      GetStudentAcademicMark({ student_academic_id: "", exam_info: "" })
    );
  };

  return (
    <>
      <Paper className={classes.pageContentBox} style={filterStyle}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <SearchFormHeaderLayout
            title="Exam Report Search"
            reset={handleReset}
          />
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedSelect
                label="Select Children"
                name="children_name"
                options={SELECT_CHILDREN}
                value={children}
                setValue={handleChildren}
                required
                error={errors["children_name"] && true}
                inputRef={register({ required: true })}
                placeholder="Label"
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "children_name",
                requiredMessage: "You must select children",
              })}
            </Grid>
            <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedSelect
                label="Select Examination"
                name="examination"
                options={SELECT_SUBJECT_CHOICES}
                value={subject}
                setValue={setSubject}
                required
                error={errors["examination"] && true}
                inputRef={register({ required: false })}
                placeholder="Label"
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "examination",
                requiredMessage: "Select Examination",
              })}
            </Grid>
            <SubmitButton style={{ marginTop: "5px" }} />
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default ExamReportSearch;
