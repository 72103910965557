//---------------------------<START> import modules starts-----------------------//
import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { GetLeaveTypes } from '../../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import CustomizedTextField from '../../../../components/Reusable/Inputs/TextField';
import CustomizedNepaliDatePicker from '../../../../components/Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect from '../../../../components/Reusable/Inputs/Select';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../../../components/utils/dateConverter';
import { FormSendButon } from '../../../../components/Reusable/Buttons/FormButton';
import { Tupple } from '../../../../actions';
import getFullName from '../../../../components/utils/getFullName';
import { ParentLeaveII } from '../../../../actions/ParentsDash/ParentLeave/ParentLeaveActionTypes';
import {
  AddLeave,
  GetLeaves,
  UpdateLeave,
} from '../../../../actions/ParentsDash/ParentLeave/ParentLeaveAction';
import { CUR_NEPALI_DATE } from '../../../../components/utils/nepaliDateUtils';
//-------------------------<END> import modules ends ----------------------------//

//--------------------------<START> Add Annoucement Modal components starts---------------------------------------------------//
const LeaveRequestModal = (props: any) => {
  const { academicID } = props;
  const { editable, item } = props;
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const [dateFrom, setDateFrom] = useState<any>(CUR_NEPALI_DATE);
  const [dateTo, setDateTo] = useState<any>(CUR_NEPALI_DATE);
  const [leaveType, setLeaveType] = useState<Tupple | null>(null);
  const [selectedChild, setSelectedChild] = useState<Tupple | null>(null);

  const leaveTypeState = useSelector((state: RootStore) => state.leave_type);
  const childState = useSelector((state: RootStore) => state.view_child);

  useEffect(() => {
    dispatch(GetLeaveTypes());
  }, []);

  const { register, handleSubmit, errors, setValue } = useForm<any>();

  const SELECT_CHILD_CHOICES = childState.view_child.map(
    (item: {
      id: number;
      student_first_name: string;
      student_last_name: string;
    }) => ({
      key: item.id.toString(),
      value: getFullName(item.student_first_name, item.student_last_name),
    })
  );

  const LEAVE_TYPE_CHOICES = leaveTypeState.leave_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  useEffect(() => {
    if (editable) {
      setDateFrom(item.leave_date);
      setDateTo(item.leave_to);
      setSelectedChild(
        SELECT_CHILD_CHOICES.find(
          (childItem: any) => childItem.key === item.student_academic
        ) || SELECT_CHILD_CHOICES[0]
      );
      setValue('description', item.description);
      setLeaveType(
        LEAVE_TYPE_CHOICES.find(
          (childItem: any) => childItem.key === item.leave_type_id
        ) || LEAVE_TYPE_CHOICES[0]
      );
    }
  }, [editable]);

  //-------------------<START> React hooks forms starts ----------------------//

  const onSubmit = (data: any) => {
    if (data && dateFrom && dateTo && academicID) {
      const leaveData: any = {
        leave_from: dateConverterBsToAd(dateFrom),
        leave_to: dateConverterBsToAd(dateTo),
        reason_for_leave: data.description,
        student_academic: academicID,
      };

      if (editable) {
        dispatch(UpdateLeave(item.id, leaveData));
      } else {
        dispatch(AddLeave(leaveData));
        academicID && dispatch(GetLeaves(academicID));
      }
    }

    props.onClose();
  };

  //------------------------<END> React Hooks form ends -------------------------//
  return (
    <Paper elevation={0}>
      <div className={classes.modalContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} className={classes.formWrapper}>
                <CustomizedSelect
                  label="Leave Type"
                  name="leave_type"
                  options={LEAVE_TYPE_CHOICES}
                  value={leaveType}
                  setValue={setLeaveType}
                  error={
                    errors["leave_type"] ? "Leave type must be selected" : ""
                  }
                  inputRef={register({ required: true })}
                />
              </Grid> */}

              {/* <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedSelect
                  label="Select Child"
                  name="child_name"
                  options={SELECT_CHILD_CHOICES}
                  value={selectedChild}
                  setValue={setSelectedChild}
                />
              </Grid> */}
            </Grid>

            <Grid container spacing={1} item xs={12}>
              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Leave from Date"
                  value={dateFrom}
                  setValue={setDateFrom}
                />
              </Grid>

              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Leave to Date"
                  value={dateTo}
                  setValue={setDateTo}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formWrapper}>
              <CustomizedTextField
                label="Description"
                placeholder="Label"
                name="description"
                required
                multiline
                rows={4}
                error={errors['description'] ? 'Required Field.' : ''}
                inputRef={register({ required: true })}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            style={{ paddingBottom: '8px' }}
          >
            <FormSendButon />
          </Grid>
        </form>
      </div>
    </Paper>
  );
};
//---------------------------<END> Add Annoucement Modal components end ---------------------------//
export default LeaveRequestModal;
