import { Box } from "@material-ui/core";
import React from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import TimetableSchedule from "./TimetableSchedule";

const TeacherTimeTablePage = () => {
  return (
    <>
      <Box>
        <PageHeader
          title="TimeTable"
          module={{ name: "Academics", to: "/timetable" }}
        />
        <PageWrapper>
          <TimetableSchedule />
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherTimeTablePage;
