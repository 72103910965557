export const GET_SYLLABUS_LOADING = "GET_SYLLABUS_LOADING";
export const GET_SYLLABUS_ERROR = "GET_SYLLABUS_ERROR";
export const GET_SYLLABUS_SUCCESS = "GET_SYLLABUS_SUCCESS";

export const ADD_SYLLABUS_LOADING = "ADD_SYLLABUS_LOADING";
export const ADD_SYLLABUS_ERROR = "ADD_SYLLABUS_ERROR";
export const ADD_SYLLABUS_SUCCESS = "ADD_SYLLABUS_SUCCESS";

export const UPDATE_SYLLABUS_LOADING = "UPDATE_SYLLABUS_LOADING";
export const UPDATE_SYLLABUS_ERROR = "UPDATE_SYLLABUS_ERROR";
export const UPDATE_SYLLABUS_SUCCESS = "UPDATE_SYLLABUS_SUCCESS";

export const DELETE_SYLLABUS_LOADING = "DELETE_SYLLABUS_LOADING";
export const DELETE_SYLLABUS_ERROR = "DELETE_SYLLABUS_ERROR";
export const DELETE_SYLLABUS_SUCCESS = "DELETE_SYLLABUS_SUCCESS";

export const GET_SUB_UNIT_LOADING = "GET_SUB_UNIT_LOADING";
export const GET_SUB_UNIT_ERROR = "GET_SUB_UNIT_ERROR";
export const GET_SUB_UNIT_SUCCESS = "GET_SUB_UNIT_SUCCESS";

export const ADD_SUB_UNIT_LOADING = "ADD_SUB_UNIT_LOADING";
export const ADD_SUB_UNIT_ERROR = "ADD_SUB_UNIT_ERROR";
export const ADD_SUB_UNIT_SUCCESS = "ADD_SUB_UNIT_SUCCESS";

export const UPDATE_SUB_UNIT_LOADING = "UPDATE_SUB_UNIT_LOADING";
export const UPDATE_SUB_UNIT_ERROR = "UPDATE_SUB_UNIT_ERROR";
export const UPDATE_SUB_UNIT_SUCCESS = "UPDATE_SUB_UNIT_SUCCESS";

export const DELETE_SUB_UNIT_LOADING = "DELETE_SUB_UNIT_LOADING";
export const DELETE_SUB_UNIT_ERROR = "DELETE_SUB_UNIT_ERROR";
export const DELETE_SUB_UNIT_SUCCESS = "DELETE_SUB_UNIT_SUCCESS";

export interface SyllabusTypeI {
  id: string;
  name: string;
  subject: string | undefined;
  academic_class: string | undefined;
  teaching_hours: string;
}

export interface subArrayI {
  id?: string | undefined;
  sub_unit: string;
  title: string;
}
export interface SubUnitTypeI {
  id?: string;
  unit: SyllabusTypeI;
  sub_unit: string | subArrayI[];
  title: string;
  completed?: boolean;
}
//===============================<START>GET SYLLABUS<START>======================//

export interface GetSyllabusLoading {
  type: typeof GET_SYLLABUS_LOADING;
}

export interface GetSyllabusError {
  type: typeof GET_SYLLABUS_ERROR;
}

export interface GetSyllabusSuccess {
  type: typeof GET_SYLLABUS_SUCCESS;
  payload: SyllabusTypeI[];
}

//===============================<END>GET SYLLABUS<END>======================//

//===============================<START>ADD SYLLABUS<START>======================//

export interface AddSyllabusLoading {
  type: typeof ADD_SYLLABUS_LOADING;
}

export interface AddSyllabusError {
  type: typeof ADD_SYLLABUS_ERROR;
}

export interface AddSyllabusSuccess {
  type: typeof ADD_SYLLABUS_SUCCESS;
  payload: any;
}

//===============================<END>GET SYLLABUS<END>======================//
//===============================<START>update SYLLABUS<START>======================//

export interface UpdateSyllabusLoading {
  type: typeof UPDATE_SYLLABUS_LOADING;
}

export interface UpdateSyllabusError {
  type: typeof UPDATE_SYLLABUS_ERROR;
}

export interface UpdateSyllabusSuccess {
  type: typeof UPDATE_SYLLABUS_SUCCESS;
  payload: SyllabusTypeI;
}

//===============================<END>GET SYLLABUS<END>======================//
//===============================<START>update SYLLABUS<START>======================//

export interface DeleteSyllabusLoading {
  type: typeof DELETE_SYLLABUS_LOADING;
}

export interface DeleteSyllabusError {
  type: typeof DELETE_SYLLABUS_ERROR;
}

export interface DeleteSyllabusSuccess {
  type: typeof DELETE_SYLLABUS_SUCCESS;
  payload: { id: string };
}

//===============================<END>delete SYLLABUS<END>======================//
//===============================<START>GET sub unit<START>======================//

export interface GetSubUnitLoading {
  type: typeof GET_SUB_UNIT_LOADING;
}

export interface GetSubUnitError {
  type: typeof GET_SUB_UNIT_ERROR;
}

export interface GetSubUnitSuccess {
  type: typeof GET_SUB_UNIT_SUCCESS;
  payload: SubUnitTypeI[];
}

//===============================<END>GET SubUnit<END>======================//

//===============================<START>ADD SUBuNIT<START>======================//

export interface AddSubUnitLoading {
  type: typeof ADD_SUB_UNIT_LOADING;
}

export interface AddSubUnitError {
  type: typeof ADD_SUB_UNIT_ERROR;
}

export interface AddSubUnitSuccess {
  type: typeof ADD_SUB_UNIT_SUCCESS;
  payload: SubUnitTypeI;
}

//===============================<END>GET SUBuNIT<END>======================//
//===============================<START>UPDATE SUBuNIT<START>======================//

export interface UpdateSubUnitLoading {
  type: typeof UPDATE_SUB_UNIT_LOADING;
}

export interface UpdateSubUnitError {
  type: typeof UPDATE_SUB_UNIT_ERROR;
}

export interface UpdateSubUnitSuccess {
  type: typeof UPDATE_SUB_UNIT_SUCCESS;
  payload: SubUnitTypeI;
}

//===============================<END>UPDATE SUBuNIT<END>======================//

//===============================<START>update SUB uNIT<START>======================//

export interface DeleteSubUnitLoading {
  type: typeof DELETE_SUB_UNIT_LOADING;
}

export interface DeleteSubUnitError {
  type: typeof DELETE_SUB_UNIT_ERROR;
}

export interface DeleteSubUnitSuccess {
  type: typeof DELETE_SUB_UNIT_SUCCESS;
  payload: { id: string };
}

//===============================<END>delete SUB uNIT<END>======================//

export type SyllabusDispatchTypes =
  | GetSyllabusLoading
  | GetSyllabusError
  | GetSyllabusSuccess
  | AddSyllabusLoading
  | AddSyllabusError
  | AddSyllabusSuccess
  | UpdateSyllabusLoading
  | UpdateSyllabusError
  | UpdateSyllabusSuccess
  | DeleteSyllabusLoading
  | DeleteSyllabusError
  | DeleteSyllabusSuccess
  | GetSubUnitLoading
  | GetSubUnitError
  | GetSubUnitSuccess
  | AddSubUnitLoading
  | AddSubUnitError
  | AddSubUnitSuccess
  | UpdateSubUnitLoading
  | UpdateSubUnitError
  | UpdateSubUnitSuccess
  | DeleteSubUnitLoading
  | DeleteSubUnitError
  | DeleteSubUnitSuccess;
