// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import { Paper, TableCell } from '@material-ui/core';
import moment from 'moment';
import { useTableStyles } from '../../../../components/Styles/TableStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import CustomizedDialogs from '../../../../components/Reusable/Dialogs/DeleteDialog';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import LeaveRequestView from './LeaveRequestView';

import { PRIMARY_DATE_FORMAT } from '../../../../common/constant';
import cx from 'clsx';
import { LeaveTypeI } from '../../../../actions/HumanResource/Leave/LeaveActionTypes';
import getFullName from '../../../../components/utils/getFullName';
import GetStatus from '../../../../common/GetStatus';
import { HeadCellsI } from '../../../../actions';
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from '../../../../components/Reusable/Buttons/TableButton';
import { dateConverterAdToBs } from '../../../../components/utils/dateConverter';
import { GetLeaveTypes } from '../../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import useLoading from '../../../../useHook/useLoading';
import {
  DeleteLeave,
  GetLeaves,
} from '../../../../actions/ParentsDash/ParentLeave/ParentLeaveAction';
import { ParentLeaveI } from '../../../../actions/ParentsDash/ParentLeave/ParentLeaveActionTypes';
import LeaveRequestModal from './LeaveRequestModal';
import useTable from '../../../../components/Reusable/useStudentTable';
import useTableWithPagination from '../../../../components/Reusable/useTableWithPagination';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'name', label: 'Student Name', align: 'left' },
  // { id: 'leave_type', label: 'Leave Type', align: 'left', filter: true },
  { id: 'leave_date', label: 'Leave Date', align: 'left' },
  { id: 'days', label: 'Days' },
  { id: 'applied_date', label: 'Applied Date' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const LeaveList = (props: any) => {
  const { academicID } = props;

  const dispatch = useDispatch();
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<LeaveTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openFormPopup, setOpenFormPopup] = useState<boolean>(false);
  const [leaveData, setLeaveData] = useState([]);
  const [item, setItem] = useState<object | null>(null);

  const leaveSelector = useSelector((state: RootStore) => state.parent_leave);
  const { loading } = useLoading(leaveSelector.loading);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  useEffect(() => {
    dispatch(GetLeaveTypes());
    if (academicID) {
      dispatch(GetLeaves(academicID));
    }
  }, [academicID]);

  useEffect(() => {
    if (
      academicID &&
      leaveSelector.parent_leaves &&
      leaveSelector.parent_leaves.results
    ) {
      const data = {
        ...leaveSelector.parent_leaves,
        results: leaveSelector.parent_leaves.results.filter(
          (el: any) => el.student_academic.id === academicID
        ),
      };

      const items = formatData(data);
      setTableData(items);

      console.log('academicID', academicID);
      console.log('parent leaves', leaveSelector.parent_leaves);
    }
  }, [leaveSelector, academicID]);

  const formatData = (items: any) => {
    if (items && items.results) {
      return items.results.map((item: any) => {
        const { first_name, last_name } =
          item.student_academic.student.student_user;

        return {
          id: item.id,
          name: first_name + ' ' + last_name,
          student_academic: item.student_academic,
          leave_type: 'Unknown Leave Type',
          leave_from: dateConverterAdToBs(item.leave_from),
          leave_to: dateConverterAdToBs(item.leave_to),
          leave_date: dateConverterAdToBs(item.leave_from),
          days: dateConverterAdToBs(item.leave_to),
          applied_date: moment(item.created_on).format(PRIMARY_DATE_FORMAT),
          description: item.reason_for_leave,
          no_of_days: moment(item.leave_to).diff(item.leave_from, 'days') + 1,
          status: item.status,
          remarks: item.remarks,
          photo: '/favicons.png',
        };
      });
    }

    return [];
  };

  const handleDeleteModal = (id: string) => {
    setItemId(id);
    setDeleteModalOpen(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteLeave(itemId));
    setDeleteModalOpen(false);
  };

  const handleViewModal = (item: any) => {
    setLeaveData(item);
    setItemId(item.id);
    setOpenPopup(true);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleFormClose = (value: boolean) => {
    setOpenFormPopup(value);
  };

  const handleEditModal = (item: any) => {
    setOpenFormPopup(true);
    setItem(item);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        {/* <StyledTableCell align="left" className={classes.cell}>
          {item.leave_type}
        </StyledTableCell> */}
        <StyledTableCell align="left" className={classes.cell}>
          {moment(item.leave_date).format(PRIMARY_DATE_FORMAT)} -{' '}
          {moment(item.days).format(PRIMARY_DATE_FORMAT)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.days).diff(item.leave_date, 'days')}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.applied_date).format(PRIMARY_DATE_FORMAT)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <GetStatus status={item.status} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleViewModal(item)} />
          <ItemEditButton onClick={() => handleEditModal(item)} />
          <ItemDeleteButton onClick={() => handleDeleteModal(item.id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handlePaginationChange = (page: number) => {
    const params = { page, perPage: 5 };
    // dispatch(GetLeaves(params));
  };

  return (
    <Paper
      className={cx(classes.root, classes.attachFormTableRoot)}
      style={{ padding: 16 }}
      elevation={0}
    >
      {/* <TableContainer
        label="Leave List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        rowsPerPage={4}
        getCustomTableRow={getCustomTableRow}
      /> */}

      <TableContainer
        label="Student Leave List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        forPagination={leaveSelector.parent_leaves}
        handlePaginationChange={handlePaginationChange}
        getCustomTableRow={getCustomTableRow}
        // search_name="name"
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />

      <Popups
        openPopup={openFormPopup}
        setOpenPopup={setOpenFormPopup}
        onClose={handleFormClose}
        title="Update Request Leave"
        maxWidth="sm"
      >
        <LeaveRequestModal
          onClose={handleFormClose}
          item={item}
          academicID={academicID}
          editable
        />
      </Popups>

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        maxWidth="sm"
        title="Leave Request"
        colored
      >
        <LeaveRequestView items={leaveData} academicID={academicID} />
      </Popups>
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default LeaveList;
