import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { PRIMARY_DATE_FORMAT } from "../../../../common/constant";
import { dateConverterAdToBs } from "../../../../components/utils/dateConverter";

const MyComplaintView = (props: any) => {
  const { viewData: singleComplain } = props;

  return (
    <>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Feedbacker Name:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {singleComplain.complainer}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Feedback Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {dateConverterAdToBs(
              moment(singleComplain?.created_on).format(PRIMARY_DATE_FORMAT)
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Description:
        </Grid>

        <Grid item xs={8}>
          {singleComplain?.description}
        </Grid>
      </Grid>
    </>
  );
};

export default MyComplaintView;
