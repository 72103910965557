import React from "react";
import { Button, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

interface DialogI {
  children: React.ReactNode;
  onClick?: boolean;
  title?: string;
  variant?: string;
  color?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  Btn: {
    padding: "0 4px",
  },
  viewIcon: {
    color: "#118FCF",
    border: "2px solid #118FCF",
    opacity: 0,
    borderRadius: "20px",
    padding: "2px",
  },
}));

const ActionButton = (props: any) => {
  const { children, onClick, title, variant, color, disabled, ...rest } = props;
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <span>
        <IconButton
          onClick={onClick}
          disabled={disabled}
          className={classes.Btn}
          {...rest}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ActionButton;
