import { Paper, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "../../../../components/Student/StudentProfile/File/NewTabs";
import UserInfo from './ProfileTabs/UserInfo'
import { usefullCardStyles } from "../../../../components/Styles/CardStyles";
import React from 'react';

const ParentTabs = (props: any) => {
    const classes = usefullCardStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    return (
        <>
            <Paper elevation={0} className={classes.tabRoot}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Info" />
                </Tabs>

                <TabPanel value={value} index={0}>
                    <UserInfo guardian={props.guardian} />
                </TabPanel>
            </Paper>
        </>
    )
}

export default ParentTabs