import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  makeStyles,
  OutlinedInput,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import StudentProfileCard from '../../components/Card/StudentProfileCard';
import generateGreetings from '../../../../components/utils/generateGreetings';
import SchoolCard from '../../components/Card/SchoolCard';
import data from '../../data';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetSchools,
  SwitchToSchool,
} from '../../../../actions/SuperAdmin/School/SchoolAction';
import { RootStore } from '../../../../store';
import { Pagination } from '@material-ui/lab';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: '240px',
    minHeight: '100vh',
    borderRadius: '30px 0 0 30px',
    background: '#f9f9f9',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  main: {
    flex: '1',
    padding: '16px 24px',
    '& b': {
      fontWeight: 600,
    },
  },
  title: { marginTop: '10px', fontWeight: 400 },
  widget: {
    padding: '20px',
    position: 'relative',
    width: '370px',
  },
  paper: {
    borderRadius: 18,
    boxShadow: 'rgb(0 0 0 / 6%) 1px 1px 18px 1px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const SuperAdminDashboardPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const greeting = generateGreetings();
  const [filterData, setFilterData] = useState<any>([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [switched, setSwitched] = useState(false);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const schoolList = useSelector((state: RootStore) => state.sa_schools);
  const general_info = useSelector(
    (state: RootStore) => state.auth.general_info
  );
  const user = useSelector((state: RootStore) => state.auth.user);
  const switch_success = useSelector(
    (state: RootStore) => state.auth.switch_success
  );

  useEffect(() => {
    dispatch(GetSchools(1));
  }, []);

  useEffect(() => {
    if (schoolList.schools.results != null) {
      const data: any = [];
      schoolList.schools.results?.map((elem: any, index: number) => {
        data.push({
          id: elem.id,
          name: elem.school_name,
          address: elem.address,
          logo: elem.logo,
          student: {
            value: elem.total_students,
            total: '000',
          },
          teacher: {
            value: elem.total_teachers,
            total: '000',
          },
          staff: {
            value: elem.total_staffs,
            total: '000',
          },
          user_general_info: elem.user_general_info,
        });
      });
      setFilterData(data);
      setTotalRecord(schoolList.schools.total);
      setPageCount(schoolList.schools.total_pages);
      setRecordFrom(
        schoolList.schools.current_page === 1
          ? schoolList.schools.current_page
          : (schoolList.schools.current_page - 1) *
              schoolList.schools.per_page +
              1
      );
      setRecordTo(
        schoolList.schools.current_page === 1
          ? schoolList.schools.current_page * schoolList.schools.per_page
          : schoolList.schools.current_page * schoolList.schools.per_page
      );
    }
  }, [schoolList.schools.results]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filter = event.target.value;

    if (!filter) {
      setFilterData(data);
    } else {
      const data = filterData.filter((item: any) => item.name.includes(filter));
      setFilterData(data);
    }
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    dispatch(GetSchools(newPage));
    setPage(newPage);
  };

  const handleSwitchSchool = (schoold_id: string) => {
    const data = {
      user_id: schoold_id,
      organization_user_id: user?.id,
    };
    dispatch(SwitchToSchool(data));
    setSwitched(true);
  };

  if (switched && switch_success) {
    <Redirect to="/login" />;
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Box className={classes.main}>
            <Typography variant="h5" color="primary" className={classes.title}>
              <b>{greeting}</b> {general_info?.name}
            </Typography>

            <Typography variant="subtitle2">
              Welcome back to your Dashboard.
            </Typography>
          </Box>

          <Box className={classes.widget}>
            <StudentProfileCard />
          </Box>
        </Box>

        <Container maxWidth="lg" style={{ paddingBottom: 24 }}>
          <Grid
            container
            spacing={4}
            justifyContent="flex-end"
            style={{ marginBottom: '4px' }}
          >
            <Grid item md={4}>
              <FormControl size="small">
                <OutlinedInput
                  placeholder="Search School..."
                  inputProps={{ 'aria-label': 'description' }}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {filterData.map((school: any, index: number) => (
              <Grid
                item
                md={4}
                key={index}
                onClick={() =>
                  handleSwitchSchool(school.user_general_info[0].id)
                }
              >
                <SchoolCard school={school} />
              </Grid>
            ))}
          </Grid>

          {totalRecord > 0 && !schoolList?.loading && (
            <Grid
              container
              justifyContent="center"
              style={{ marginTop: '35px' }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </Grid>
          )}
          <Grid container justifyContent="center" style={{ marginTop: '15px' }}>
            {totalRecord > 0 && (
              <Typography style={{ fontSize: '0.7rem' }}>
                Showing {recordFrom} to{' '}
                {recordTo > totalRecord ? totalRecord : recordTo} of{' '}
                {totalRecord} entries
              </Typography>
            )}
            {totalRecord <= 0 && !schoolList?.loading && (
              <Typography style={{ fontSize: '1rem' }}>
                No records found. Please add some schools to get the data on
                dashboard.
              </Typography>
            )}
          </Grid>
        </Container>
      </Box>
      <Backdrop
        open={schoolList?.loading ? true : false}
        className={classes.backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SuperAdminDashboardPage;
