import React, { useState } from 'react';
import { Grid, makeStyles, Button, IconButton } from '@material-ui/core';
import BreadCrumbs from '../BreadCrumbs';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ErrorBoundary } from 'react-error-boundary';
import AdminStudentFallback from '../../../common/FallBack/AdminStudentFallBack';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    marginTop: '90px',
    overflow: 'hidden',
  },
  sidenav: {
    marginTop: '104px',
    position: 'fixed',
    minHeight: 'calc(100vh - 200px)',
    top: 0,
    left: '20px',
    width: '240px',
    zIndex: 99,
  },
  sidenavFull: {
    marginTop: '85px',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '220px',
    zIndex: 99,
  },
  module: {
    position: 'relative',
    paddingLeft: '260px',
    width: '100%',
    transition: '0.075s ease all',
  },
});

const useStylesFull = makeStyles({
  container: {
    position: 'relative',
    marginTop: '-6px',
    overflow: 'hidden',
  },
  sidenav: {
    marginTop: '104px',
    position: 'fixed',
    top: 0,
    left: '20px',
    width: '220px',
    zIndex: 99,

    '& > div': {
      height: 'auto',
    },
  },
  module: {
    position: 'relative',
    paddingLeft: '240px',
    width: '100%',
  },
});

const StyledToggleButton = styled(({ children, ...rest }: any) => (
  <IconButton {...rest}>{children}</IconButton>
))`
  padding: 8px;
  position: absolute;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  top: 50%;
  background: ${(props) => props.theme.palette.grey['300']};

  & > span > svg {
    position: relative;
    left: 4px;
    font-size: 16px;
  }

  ${(props: any) =>
    props.isOpen &&
    `
    right: unset;
    left: -2px;
    transform: translate(-50%, -50%);

    span svg {left: 0px}
  `}

  &:hover {
    background: ${(props) => props.theme.palette.grey['400']};
  }
`;

// ---------------- <START> Header + Sidenav + Main Module layout start ------------------//
const SubModuleLayout = (props: any) => {
  const classes = useStyles();
  const { sideNav, children, ...rest } = props;
  const [isSidebarPop, setIsSidebarPop] = useState(false);

  const handleToggle = () => {
    setIsSidebarPop((active) => !active);
  };

  const errorHandler = (error: any, errorInfo: any) => {
    console.log(error, errorInfo);
  };


  return (
    <>
      <Grid container className={classes.container}>
        <Grid
          item
          className={classes.sidenav}
          style={isSidebarPop ? { width: 0 } : {}}
        >
          {isSidebarPop === false && sideNav}

          <StyledToggleButton onClick={handleToggle} isOpen={isSidebarPop}>
            {isSidebarPop ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
          </StyledToggleButton>
        </Grid>

        <Grid
          item
          className={classes.module}
          style={isSidebarPop ? { paddingLeft: 0 } : {}}
        >
          <Grid container justifyContent="center">
            <Grid
              item
              style={{
                padding: '20px 24px 0px 16px',
                width: '100%',
              }}
            >
              <BreadCrumbs module={props.module} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SubModuleLayout;

const FullSubModuleLayout = (props: any) => {
  const classes = useStylesFull();
  const { sideNav, children, ...rest } = props;


  return (
    <>
      <Grid container className={classes.container}>
        <Grid item className={classes.sidenav}>
          {sideNav}
        </Grid>
        <Grid item className={classes.module}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { FullSubModuleLayout };
