import { createStyles, makeStyles, Theme } from "@material-ui/core";
import sky from "../../images/sky.jpg";
import study from "../../images/study.jpg";
import student from "../../images/student.jpg";

export const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      margin: "2rem 1rem",
      backgroundColor: "#fff",
    },
    mainRoot: {
      margin: "2rem 1rem 2rem 0rem",
      padding: "0rem 1rem",
    },
    cardbg: {
      // background: "white",
      background:
        "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 70%, rgba(7,50,148,1) 100%, rgba(0,212,255,1) 100%)",
    },
    profileImg: {
      width: "120px",
      height: "120px",
      // alignItems: "center",
      margin: "0 0 0 16px",
      top: "50px",
    },
    title: {
      marginTop: "45px",
      marginBottom: "16px",
    },
    content: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      padding: "0.5rem 0",
    },
    tabRoot: {
      marginTop: "35px",
      marginRight: "16px",
      padding: " 0 1rem",
      "& .MuiTab-labelIcon": {
        minHeight: "40px",
        paddingTop: "6px!important",
      },
      "& .MuiTab-root": {
        fontSize: "14px",
        textTransform: "none",
        minWidth: "110px",
        maxWidth: "200px",
        height: "30px",
        letterSpacing: "0.01071em",
      },
      "& .MuiTabs-flexContainer": {
        borderBottom: "1px solid #ddd",
      },
      "& .MuiBox-root": {
        padding: "10px 0px 0px 0px",
      },
    },
    table: {
      color: "#696969",
      paddingTop: "10px",
      "& .MuiTableRow-root": {
        height: "30px",
        maxHeight: "30px",
      },
      "&.MuiTableCell-root": {
        width: "50%",
      },
    },
    infobar: {
      marginRight: theme.spacing(4),
      padding: theme.spacing(2),
    },
    roota: {
      marginBottom: "1rem",
    },
  })
);

export const usefullCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      margin: "2rem 1rem",
      // height: "40vh",
      backgroundColor: "#fff",
    },
    cardroot: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#fff",
      "&.MuiCard-root": {
        overflow: "hidden !important",
      },
    },
    cardbg: {
      height: "200px",
      // backgroundImage:
      //   "url(https://i.ibb.co/yYzYXhk/benjamin-child-6ms-S8v-T5pzw-unsplash.jpg)",
      background: `url(${student})`,
      backgroundRepeat: "no-repeat",
      // backgroundColor:
      //   theme.palette.type === "light"
      //     ? theme.palette.grey[50]
      //     : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      objectFit: "contain",
    },
    contentbg: {
      background:
        "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(247,239,247,1) 100%, rgba(234,242,244,1) 100%)",
    },
    profileImg: {
      width: "150px",
      height: "150px",
      alignItems: "center",
      margin: "auto",
      top: "-75px",
    },
    title: {
      marginTop: "-80px",
    },
    content: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      padding: "0.5rem 0",
    },
    tabRoot: {
      // marginTop: "35px",
      // marginRight: "16px",
      padding: " 0 1rem",
      "& .MuiTab-labelIcon": {
        minHeight: "40px",
        paddingTop: "6px!important",
      },
      "& .MuiTab-root": {
        fontSize: "14px",
        textTransform: "none",
        minWidth: "110px",
        maxWidth: "200px",
        height: "30px",
        letterSpacing: "0.01071em",
      },
      "& .MuiTabs-flexContainer": {
        borderBottom: "1px solid #ddd",
      },
      "& .MuiBox-root": {
        padding: "10px 0px 0px 0px",
      },
    },
    table: {
      color: "#696969",
      paddingTop: "10px",
      "& .MuiTableRow-root": {
        height: "30px",
        maxHeight: "30px",
      },
      "&.MuiTableCell-root": {
        width: "50%",
      },
    },
    infobar: {
      marginRight: theme.spacing(4),
      padding: theme.spacing(2),
    },
    roota: {
      marginBottom: "1rem",
    },
  })
);
