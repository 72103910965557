//-------------------<Start> module imports starts ----------------------//
import React, { useEffect, useState } from "react";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { useTable } from "../../../../components/Reusable";
import {
  Paper,
  TableBody,
  TextField,
  Grid,
  Typography,
  LinearProgress,
  ListItem,
  List,
  Card,
  CardContent,
  makeStyles,
  Theme,
} from "@material-ui/core";
import SearchTimetable from "./ParentTimeTableSearch";
import { RootStore } from "../../../../store";
import PersonIcon from "@material-ui/icons/Person";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { ChildrenTimeTableI } from "../../../../actions/ParentsDash/ChildrenAcademicInfo/ChildrenAcademicInfoActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { tblStyle } from "../../ParentDashboardStyles";
import { GetChildrenTimeTable } from "../../../../actions/ParentsDash/ChildrenAcademicInfo/ChildrenAcademicInfoAction";
import { yellow, indigo } from "@material-ui/core/colors";
import ReusableTimetable from "../../../../components/Academic/TimeTable/ReusableTimetable"

// -------------------<END> module imports end ----------------------//

// ---------------<START> Timetable Schedule component starts -----------------//
const ParentTimetableView = (props: any) => {
  const classes = useTableStyles();

  console.log("parent", props)

  return (
    <>
      <Paper
        className={classes.rootTableBox}
        style={{ ...tblStyle, padding: "auto" }}
      >
        <ReusableTimetable role="Parent" academicID={props.academicID} />
      </Paper>
    </>
  );
};
// --------------------<ENDS> Time table Schedule ends ----------------------//
export default ParentTimetableView;
