import { makeStyles } from '@material-ui/core/styles';
import { useTable } from '../../../../components/Reusable';
import { useTableStyles } from '../../../../components/Styles/TableStyles';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',

    '& th, & td': {
      padding: '12px 24px 12px 16px',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    '& tbody tr:last-child td ': {
      borderBottom: 'none',
    },
  },
});

const tempData = [
  {
    id: 1,
    receipt_no: 'ABC 123',
    student_name: 'Aashish Maharjan',
    class: '3(A)',
    total_payable: 'Rs. 50,000',
    discount: 'Rs. 5,000',
    paid_amount: 'Rs. 30,000',
    due_amount: 'Rs. 15,000',
  },
  {
    id: 2,
    receipt_no: 'XYZ 987',
    student_name: 'Lumus Shah',
    class: '5(C)',
    total_payable: 'Rs. 60,000',
    discount: 'Rs. 10,000',
    paid_amount: 'Rs. 30,000',
    due_amount: 'Rs. 20,000',
  },
  {
    id: 3,
    receipt_no: 'ABC 123',
    student_name: 'Aashish Maharjan',
    class: '3(A)',
    total_payable: 'Rs. 50,000',
    discount: 'Rs. 5,000',
    paid_amount: 'Rs. 30,000',
    due_amount: 'Rs. 15,000',
  },
  {
    id: 4,
    receipt_no: 'XYZ 987',
    student_name: 'Lumus Shah',
    class: '5(C)',
    total_payable: 'Rs. 60,000',
    discount: 'Rs. 10,000',
    paid_amount: 'Rs. 30,000',
    due_amount: 'Rs. 20,000',
  },
  {
    id: 5,
    receipt_no: 'ABC 123',
    student_name: 'Aashish Maharjan',
    class: '3(A)',
    total_payable: 'Rs. 50,000',
    discount: 'Rs. 5,000',
    paid_amount: 'Rs. 30,000',
    due_amount: 'Rs. 15,000',
  },
  {
    id: 6,
    receipt_no: 'XYZ 987',
    student_name: 'Lumus Shah',
    class: '5(C)',
    total_payable: 'Rs. 60,000',
    discount: 'Rs. 10,000',
    paid_amount: 'Rs. 30,000',
    due_amount: 'Rs. 20,000',
  },
  {
    id: 7,
    receipt_no: 'ABC 123',
    student_name: 'Aashish Maharjan',
    class: '3(A)',
    total_payable: 'Rs. 50,000',
    discount: 'Rs. 5,000',
    paid_amount: 'Rs. 30,000',
    due_amount: 'Rs. 15,000',
  },
  {
    id: 8,
    receipt_no: 'XYZ 987',
    student_name: 'Lumus Shah',
    class: '5(C)',
    total_payable: 'Rs. 60,000',
    discount: 'Rs. 10,000',
    paid_amount: 'Rs. 30,000',
    due_amount: 'Rs. 20,000',
  },
];

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells: HeadCellsI[] = [
  { id: 'receipt_no', label: 'Receipt No.' },
  { id: 'student_name', label: 'Student Name' },
  { id: 'class', label: 'Class' },
  { id: 'total_payable', label: 'Total Payable' },
  { id: 'discount', label: 'Discount' },
  { id: 'paid_amount', label: 'Paid Amount' },
  { id: 'due_amount', label: 'Due Amount' },
];

export default function RecentTransactionList() {
  const classes = useStyles();
  const tblClasses = useTableStyles();
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={tblClasses.cell}>
          {item.receipt_no}
        </StyledTableCell>
        <StyledTableCell align="center" className={tblClasses.cell}>
          {item.student_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={tblClasses.cell}>
          {item.class}
        </StyledTableCell>
        <StyledTableCell align="center" className={tblClasses.cell}>
          {item.total_payable}
        </StyledTableCell>
        <StyledTableCell align="center" className={tblClasses.cell}>
          {item.discount}
        </StyledTableCell>
        <StyledTableCell align="center" className={tblClasses.cell}>
          {item.paid_amount}
        </StyledTableCell>
        <StyledTableCell align="center" className={tblClasses.cell}>
          {item.due_amount}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <TableContainer
      items={tempData}
      headCells={headCells}
      disablePrint
      disableSearch
      getCustomTableRow={getCustomTableRow}
    />
  );
}
