export const GET_TEACHER_ENOTES_CATEGORIES_LOADING =
  "GET_TEACHER_ENOTES_CATEGORIES_LOADING";
export const GET_TEACHER_ENOTES_CATEGORIES_ERROR =
  "GET_TEACHER_ENOTES_CATEGORIES_ERROR";
export const GET_TEACHER_ENOTES_CATEGORIES_SUCCESS =
  "GET_TEACHER_ENOTES_CATEGORIES_SUCCESS";

export const GET_TEACHER_ENOTES_LOADING = "GET_TEACHER_ENOTES_LOADING";
export const GET_TEACHER_ENOTES_ERROR = "GET_TEACHER_ENOTES_ERROR";
export const GET_TEACHER_ENOTES_SUCCESS = "GET_TEACHER_ENOTES_SUCCESS";

export const CREATE_TEACHER_ENOTES_LOADING = "CREATE_TEACHER_ENOTES_LOADING";
export const CREATE_TEACHER_ENOTES_ERROR = "CREATE_TEACHER_ENOTES_ERROR";
export const CREATE_TEACHER_ENOTES_SUCCESS = "CREATE_TEACHER_ENOTES_SUCCESS";

export type TeacherEnotesI = {
  id: string;
  grade: string;
  grade_name: string;
  section: string | null;
  section_name: string | null;
  subject: string;
  subject_name: string;
  category: string;
  category_name: string;
  title: string;
  meta_description: string;
  enote_description: string;
  teacher: number;
  teacher_first_name: string;
  teacher_last_name: string;
  reference_links: string[];
  enote_documents: EnotesDocsI[];
};

export type TeacherEnotesCategoryI = {
  id: string;
  name: string;
  description: string;
};

interface EnotesDocsI {
  id: string;
  enote: string;
  document: string;
}
//===============================<START>GET Teacher Enotes <START>======================//

export interface GetTeacherEnotesLoading {
  type: typeof GET_TEACHER_ENOTES_LOADING;
}

export interface GetTeacherEnotesError {
  type: typeof GET_TEACHER_ENOTES_ERROR;
}

export interface GetTeacherEnotesSuccess {
  type: typeof GET_TEACHER_ENOTES_SUCCESS;
  payload: TeacherEnotesI[];
}

//===============================<END>GET Teacher Enotes <END>======================//

//===============================<START>GET Teacher Enotes Categories <START>======================//

export interface GetTeacherEnotesCategoriesLoading {
  type: typeof GET_TEACHER_ENOTES_CATEGORIES_LOADING;
}

export interface GetTeacherEnotesCategoriesError {
  type: typeof GET_TEACHER_ENOTES_CATEGORIES_ERROR;
}

export interface GetTeacherEnotesCategoriesSuccess {
  type: typeof GET_TEACHER_ENOTES_CATEGORIES_SUCCESS;
  payload: TeacherEnotesCategoryI[];
}

//===============================<END>GET Teacher Enotes Categories <END>======================//

//===============================<START>Create Teacher Enotes <START>======================//

export interface CreateTeacherEnotesLoading {
  type: typeof CREATE_TEACHER_ENOTES_LOADING;
}

export interface CreateTeacherEnotesError {
  type: typeof CREATE_TEACHER_ENOTES_ERROR;
  payload: any;
}

export interface CreateTeacherEnotesSuccess {
  type: typeof CREATE_TEACHER_ENOTES_SUCCESS;
}

//===============================<END>Create Teacher Enotes <END>======================//

export type TeacherEnotesCategoriesDispatchTypes =
  | GetTeacherEnotesCategoriesLoading
  | GetTeacherEnotesCategoriesError
  | GetTeacherEnotesCategoriesSuccess
  | GetTeacherEnotesLoading
  | GetTeacherEnotesError
  | GetTeacherEnotesSuccess
  | CreateTeacherEnotesLoading
  | CreateTeacherEnotesError
  | CreateTeacherEnotesSuccess;
