import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { GetTeacherEvent } from '../../../../../actions/TeacherDash/TeacherEvents/TeacherEventAction';
import { RootStore } from '../../../../../store';
import { ItemViewButton } from '../../../../../components/Reusable/Buttons/TableButton';
import Popups from '../../../../../components/Reusable/Dialogs/Popups';
import useTable from '../../../../../components/Reusable/useStudentTable';
import { useTableStyles } from '../../../../../components/Styles/TableStyles';
import { ViewEventModal } from './ViewEventModal';
import { tblStyle } from '../../../components/Styles/TeacherDashboardStyles';

interface HeadCellsI {
  id: string;
  label: string;
  align?: 'left' | 'right' | 'center';
}

const tempData = [
  {
    id: '1',
    title: 'Dashain Festival 2022',
    location: 'Uptechsys Pvt. Ltd.',
    date: '2078-10-05',
    time: '10:30',
  },
  {
    id: '2',
    title: 'Tihar Festival 2022',
    location: 'Uptechsys Pvt. Ltd.',
    date: '2078-11-05',
    time: '9:30',
  },
  {
    id: '3',
    title: 'Quiz Competition March 2022',
    location: 'Uptechsys Pvt. Ltd.',
    date: '2078-11-05',
    time: '9:30',
  },
  {
    id: '4',
    title: 'Dashain Festival 2022',
    location: 'Uptechsys Pvt. Ltd.',
    date: '2078-10-05',
    time: '10:30',
  },
  {
    id: '5',
    title: 'Tihar Festival 2022',
    location: 'Uptechsys Pvt. Ltd.',
    date: '2078-11-05',
    time: '9:30',
  },
  {
    id: '6',
    title: 'Quiz Competition March 2022',
    location: 'Uptechsys Pvt. Ltd.',
    date: '2078-11-05',
    time: '9:30',
  },
];

const headCells: HeadCellsI[] = [
  { id: 'title', label: 'Title', align: 'left' },
  { id: 'location', label: 'Location', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'time', label: 'Time', align: 'right' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'action', label: 'Action' },
];

const TeacherEventsList = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<any[]>([]);

  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [item, setItem] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const teacherEvents = useSelector((state: RootStore) => state.teacher_event);

  useEffect(() => {
    dispatch(GetTeacherEvent());
  }, []);

  useEffect(() => {
    const data = teacherEvents.teacher_event;
    setTableData(data);
    setLoading(teacherEvents.loading);
  }, [teacherEvents]);

  const handleViewModal = (item: any) => {
    setItem(item);
    setItemId(item.id);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.location}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.date}
        </StyledTableCell>
        <StyledTableCell align="right" className={classes.cell}>
          {item.time}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {['Attended', 'Not Attended', '--'][Math.floor(Math.random() * 3)]}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton
            title="View"
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewModal(item)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper style={tblStyle}>
        <TableContainer
          label="Extra Curicular Activities (ECA) List"
          loading={loading}
          items={tempData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="title"
          rowsPerPage={7}
        />
      </Paper>
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        fullWidth={true}
        maxWidth="sm"
        title="Events Detail"
        colored
      >
        <ViewEventModal item={item} handleClose={handleViewModalClose} />
      </Popups>
    </>
  );
};

export default TeacherEventsList;
