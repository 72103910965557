import {
  MY_ATTENDANCE_LOADING,
  MY_ATTENDANCE_ERROR,
  MY_ATTENDANCE_SUCCESS,
  MyAttendanceDispatchTypes,
} from "../../actions/StudentDash/MyAttendance/MyAttendanceActionTypes";

const initialState: any = {
  loading: false,
  my_attendance: [],
};

const MyAttendanceReducer = (
  state: any = initialState,
  action: MyAttendanceDispatchTypes
): any => {
  switch (action.type) {
    case MY_ATTENDANCE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case MY_ATTENDANCE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case MY_ATTENDANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        my_attendance: action.payload,
      };

    default:
      return state;
  }
};

export default MyAttendanceReducer;
