import React from "react";
import EnhancedSidebar from "../../components/NewSideBar/EnhancedSidebar";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ViewListIcon from "@material-ui/icons/ViewList";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import { SchoolListI } from "../../actions/SuperAdmin/School/SchoolActionTypes";
import { RootStore } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetAllSchools } from "../../actions/SuperAdmin/School/SchoolAction";

const StudentDashboardSidebar = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = React.useState<SchoolListI[]>([]);

  const schoolList = useSelector(
    (state: RootStore) => state.sa_schools.all_schools.results
  );

  React.useEffect(() => {
    dispatch(GetAllSchools(1));
  }, []);

  React.useEffect(() => {
    if (schoolList != null) {
      const data: any = [];
      schoolList?.map((elem: any, index: number) => {
        data.push({
          key: index + 1,
          id: elem.id,
          school_name: elem.school_name,
          address: elem.address,
          admin: elem.administrator_user,
          phone: elem.phone_number,
          students: elem.total_students,
          staffs: elem.total_staffs,
        });
      });
      setTableData(data);
    }
  }, [schoolList]);

  const dataSet: any = [
    {
      text: "Dashboard",
      url: "/",
      icon: DashboardIcon,
      activeStatus: location.pathname === "/",
    },
    {
      text: "Schools",
      url: "",
      icon: LocationCityIcon,
      dataSet: tableData.map((school, index: number) => ({
        text: school.school_name,
        url: `/school/${school.id}`,
        avatar: school.logo,
        activeStatus: location.pathname === `/school/${school.id}`,
      })),
    },
    {
      text: "School List",
      url: "/school-list",
      icon: ViewListIcon,
      activeStatus: location.pathname === "/school-list",
    },
  ];

  return <EnhancedSidebar items={dataSet} extended />;
};

export default StudentDashboardSidebar;
