import { Box } from "@material-ui/core";
import TimeTableViewS from "./StudentTimeTableView";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import { crumbs } from "../MyTeacher/MyTeacherPage";

const MyTimeTablePage = () => {
  return (
    <Box>
      <PageHeader title="My TimeTable" module={crumbs} />
      <PageWrapper>
        <TimeTableViewS />
      </PageWrapper>
    </Box>
  );
};

export default MyTimeTablePage;
