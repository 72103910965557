export const GET_CLASS_ATTENDANCE_LOADING = "GET_CLASS_ATTENDANCE_LOADING";
export const GET_CLASS_ATTENDANCE_ERROR = "GET_CLASS_ATTENDANCE_ERROR";
export const GET_CLASS_ATTENDANCE_SUCCESS = "GET_CLASS_ATTENDANCE_SUCCESS";

export interface ClassAttendanceI {
  id: number;
  student: number;
  student_first_name: string;
  student_last_name: string;
  grade?: number;
  grade_name?: string;
  section?: number;
  section_name?: string;
  roll_number?: number;
  house?: number;
  last_school_name?: string;
  last_percentage?: string;
  last_grade?: number;
  sports?: string;
  academic_session?: number;
}

export interface QueryI {
  student_id: number;
  from_date: string;
  to_date: string;
}

//===============================<START>GET Class_ATTENDANCE<START>======================//

export interface ClassAttendanceLoading {
  type: typeof GET_CLASS_ATTENDANCE_LOADING;
}

export interface ClassAttendanceError {
  type: typeof GET_CLASS_ATTENDANCE_ERROR;
}

export interface ClassAttendanceSuccess {
  type: typeof GET_CLASS_ATTENDANCE_SUCCESS;
  payload: any;
}

//===============================<END>DELETE Class_ATTENDANCE<END>======================//

export type ClassAttendanceDispatchTypes =
  | ClassAttendanceLoading
  | ClassAttendanceError
  | ClassAttendanceSuccess;
