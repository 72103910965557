// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { ItemViewButton } from "../../../../components/Reusable/Buttons/TableButton";
import LinearWithValueLabel from "../../../../components/Reusable/Bars/LinearProgressWithLabel";
import { useDispatch, useSelector } from "react-redux";
import { GetMySubjects } from "../../../../actions/TeacherDash/MySubjects/MySubjectsAction";
import { RootStore } from "../../../../store";
import { SUBJECT_TYPE_CHOICES } from "../../../../common/constant";
import { HeadCellsI } from "../../../../actions";
import { MySubjectTypeI } from "../../../../actions/TeacherDash/MySubjects/MySubjectsActionTypes";
import useTable from "../../../../components/Reusable/useStudentTable";
import { tblStyle } from "../../components/Styles/TeacherDashboardStyles";
import { TeacherSubjectByClass } from "../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes"
// ----------------------------<END> module imports end ----------------------------------//

//---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  // { id: "class", label: "Class" },
  { id: "sub_name", label: "Subject Name" },
  { id: "sub_type", label: "Type" },
  { id: "credit_hour", label: "Credit Hour" },
  {
    id: "sub_status",
    label: "Subject Status (Remaining)",
    filter: true,
  },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> My Subject List Starts ---------------------------//
const MySubjectsList: React.FC = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<any>([]);
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const { loading, subject_by_class } = useSelector((state: RootStore) => state.teacher_subject);

  useEffect(() => {
    const items = formatData(subject_by_class);
    setTableData(items);
  }, [subject_by_class]);

  const formatData = (items: TeacherSubjectByClass[]) => {
    return items.map((item) => ({
      // grade_name: item.grade_name,
      sub_name: item.name,
      sub_type: item.subject_type,
      credit_hour: item.credit_hour,
      sub_status: Math.floor(Math.random() * 100 + 1),
    }));
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
          {item.grade_name}
        </StyledTableCell> */}
        <StyledTableCell align="center" className={classes.cell}>
          {item.sub_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.sub_type}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.credit_hour}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <LinearWithValueLabel value={item.sub_status} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper style={tblStyle}>
      <TableContainer
        label="Teaching Subjects"
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        search_name="sub_name"
      />
    </Paper>
  );
};
// ---------------------------- <END> My Subject List Ends -------------------------------//

export default MySubjectsList;
