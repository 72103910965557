import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableHead,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState, useEffect } from "react";
import { useTable } from "../../Reusable";
import "nepali-datepicker-reactjs/dist/index.css";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { GradeTypeI } from "../../../actions/Academics/Grade/GradeActionTypes";
import { GetGrades } from "../../../actions/Academics/Grade/GradeAction";
import styled from "styled-components";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  changeExamScheduleData,
  presetDate,
  switchRow,
} from "../../../rtk/features/exam/examSchedule/examScheduleSlice";
import { ExamTypeI } from "../../../actions/Examination/Exam/ExamActionTypes";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { updateExamSchedule } from "../../../rtk/features/exam/examSchedule/examScheduleApi";
import { returnNepaliDateString, timeConvertor } from "./ExamSchedule";

//= ====================== <START> Styles <START> ===================================//

export const nepaliDatePicker = {
  border: "1px solid #c4c4c4",
  borderRadius: "3px",
  padding: "10px",
  margin: "0 auto",
  width: "180px",
  "&:hover": {
    border: "1px solid #132E98",
  },
  "&:focused": {
    border: "1px solid #132E98",
  },
};

const DownArrow = styled(ArrowDropDownIcon)<{ disabled?: boolean }>`
  && {
    width: 30px;
    height: 30px;
    fill: ${(props) => (props.disabled ? "#aaaaaa" : "#000")};
    border-radius: 50%;

    &:hover {
      background-color: ${(props) => !props.disabled && "#f1f1f1"};
      cursor: ${(props) => !props.disabled && "pointer"};
    }
  }
`;

const UpArrow = styled(ArrowDropUpIcon)<{ disabled?: boolean }>`
  && {
    width: 30px;
    height: 30px;
    fill: ${(props) => (props.disabled ? "#aaaaaa" : "#000")};
    border-radius: 50%;

    &:hover {
      background-color: ${(props) => !props.disabled && "#f1f1f1"};
      cursor: ${(props) => !props.disabled && "pointer"};
    }
  }
`;

const CellInput = styled.input<{ fullWidth?: boolean; error?: boolean }>`
  && {
    width: ${(props) => (props.fullWidth ? `120px` : "60px")};
    padding: 5px;
    border-radius: 3px;
    border: ${(props) => (props.error ? `1px solid red` : `1px solid #000`)};
    color: ${(props) => (props.error ? `red` : `#000`)};

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: #d1d1d1;
    }
  }
`;

const ExamDetail = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      position: "absolute",
      top: 50,
    },
    container: {
      padding: theme.spacing(2),

      "& .MuiFormControl-root": {
        width: "95%",
        margin: theme.spacing(1, 1, 0.4, 1),
      },
      "& .MuiInputLabel-root": {
        marginLeft: theme.spacing(1),
        fontSize: "14px",
        color: "#000",
        opacity: "0.7",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          border: "1px solid #132E98",
        },
        "&:hover fieldset": {
          borderColor: "#132E98 !important",
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.2, 2),
      },
      "& .MuiAutocomplete-inputRoot": {
        padding: theme.spacing(0, 1),
      },
      "& .MuiButton-text": {
        width: "100%",
        margin: theme.spacing(1),
      },
    },
    cell: {
      borderRight: "1px solid #D6D6D6",
    },
    containedButton: {
      width: "90%",
      margin: theme.spacing(2, 0),
    },
    Btn: {
      padding: "2px",
    },
    deleteIcon: {
      color: "#E96C5A",
      padding: "2px",
      marginLeft: "15px",
    },
    examLabel: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    tableSection: {
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.5, 2),
      },
      "& .MuiAutocomplete-inputRoot": {
        padding: "2px",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    nepaliDatePicker: {
      zIndex: "auto",
      ...nepaliDatePicker,
    },
    wrapper: {
      padding: theme.spacing(1),
      margin: theme.spacing(1, 2, 3),
    },
  })
);

//= ====================== <END> Styles <END> ===================================//

interface HeadCellsI {
  id: string;
  label: string;
}

//= ====================== <START> Interfaces <START> ===================================//

//= ====================== <END> Interfaces <END> ===================================//

const headCells: HeadCellsI[] = [
  { id: "sort", label: "" },
  { id: "subject", label: "Subject" },
  { id: "date", label: "Date" },
  { id: "start_time", label: "Start Time" },
  { id: "duration", label: "Duration (Hrs)" },
  { id: "full_marks", label: "Full Marks" },
  { id: "pass_marks", label: "Pass Marks" },
  { id: "pass_marks", label: "Pass Marks" },
  { id: "pass_marks", label: "Pass Marks" },
];

const ExamGroupScheduleModal = (props: {
  gradeId: GradeTypeI | null;
  examId: ExamTypeI | null;
}) => {
  const { gradeId, examId } = props;

  const classes = useStyles();
  const { TblContainer, TblHead, StyledTableCell, StyledTableRow } =
    useTable(headCells);

  //= ====================== <START> Component States <START> ===================================//

  const [editMode, setEditMode] = useState<boolean>(false);
  //= ====================== <END> Component States <END> ===================================//

  //= ====================== <START> REACT HOOKS <START> ===================================//

  const dispatch = useDispatch();

  const { examSchedule, actionPerformed } = useSelector(
    (state: RootStore) => state.examSubjectSchedule
  );

  useEffect(() => {
    if (actionPerformed) {
      setEditMode(false);
    }
  }, [actionPerformed]);

  useEffect(() => {
    setEditMode(false);
  }, [gradeId, examId]);

  // = ====================== <END> REACT HOOKS <END> ===================================//

  //= ====================== <START> Event Handlers Table <START> ===================================//

  const handleEditClick = () => {
    setEditMode(true);
    examId && dispatch(presetDate(examId.start_date));
  };

  const handleSaveClick = () => {
    if (examId && gradeId) {
      const post_data: any[] = [];

      examSchedule.forEach((el) => {
        el.exam_subject_info.id
          ? post_data.push({
              id: el.exam_subject_info.id,
              grade: gradeId.id,
              exam_info: examId.id,
              subject: el.id,
              exam_date: el.exam_subject_info.date,
              start_time: el.exam_subject_info.time,
              duration: el.exam_subject_info.duration,
              full_marks_theory: el.exam_subject_info.full_marks_theory,
              full_marks_practical:
                el.exam_subject_info.full_marks_practical || null,
              pass_marks_theory: el.exam_subject_info.pass_marks_theory,
              pass_marks_practical:
                el.exam_subject_info.pass_marks_practical || null,
            })
          : post_data.push({
              grade: gradeId.id,
              exam_info: examId.id,
              subject: el.id,
              exam_date: el.exam_subject_info.date,
              start_time: el.exam_subject_info.time,
              duration: el.exam_subject_info.duration,
              full_marks_theory: el.exam_subject_info.full_marks_theory,
              full_marks_practical:
                el.exam_subject_info.full_marks_practical || null,
              pass_marks_theory: el.exam_subject_info.pass_marks_theory,
              pass_marks_practical:
                el.exam_subject_info.pass_marks_practical || null,
            });
      });

      let valid = true;

      loopOne: for (let i = 0; i < post_data.length; i++) {
        const attrs = Object.keys(post_data[i]);

        for (let j = 0; j < attrs.length; j++) {
          if (post_data[i][attrs[j]] === "") {
            valid = false;
            break loopOne;
          }
        }
      }

      if (valid) {
        dispatch(
          updateExamSchedule({
            gradeId: gradeId.id,
            examId: examId.id,
            post_data: post_data,
          })
        );
      } else {
        dispatch(setSnackbar(true, "warning", "Cannot save empty fields."));
      }
    } else {
      dispatch(
        setSnackbar(true, "warning", "Please select exam and grade again.")
      );
    }
  };

  //= ====================== <END> Event Handlers Table <END> ===================================//

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.container}>
        <Grid container className={classes.tableSection}>
          <Grid container style={{ marginBottom: "10px" }}>
            <Grid item xs={12}>
              <ExamDetail>
                Exam:{" "}
                {examId
                  ? `${examId.exam_name} (${dateConverterAdToBs(
                      examId.start_date
                    )} - ${dateConverterAdToBs(examId.end_date)})`
                  : ""}
              </ExamDetail>
              <ExamDetail className={classes.examLabel}>&nbsp;</ExamDetail>
            </Grid>
            <Grid item xs={3}>
              <ExamDetail>Class: {gradeId?.grade_name || ""}</ExamDetail>
              <ExamDetail className={classes.examLabel}>&nbsp;</ExamDetail>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <StyledTableRow>
                {editMode && <StyledTableCell align="left"></StyledTableCell>}
                <StyledTableCell align="center">Subject</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">Time</StyledTableCell>
                <StyledTableCell align="center">Duration (Hrs)</StyledTableCell>
                <StyledTableCell align="center">
                  <span>
                    Full Marks <br />
                    (Theory)
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span>
                    Pass Marks <br />
                    (Theory)
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span>
                    Full Marks <br />
                    (Practical)
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span>
                    Pass Marks <br />
                    (Practical)
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {examSchedule.map((item, index) => (
                <StyledTableRow key={item.id}>
                  {editMode && (
                    <StyledTableCell align="left" style={{ width: "40px" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <UpArrow
                          disabled={index === 0}
                          onClick={() =>
                            dispatch(
                              switchRow({ id: item.id, direction: "up" })
                            )
                          }
                        />
                        <DownArrow
                          onClick={() =>
                            dispatch(
                              switchRow({ id: item.id, direction: "down" })
                            )
                          }
                          disabled={examSchedule.length === index + 1}
                        />
                      </div>
                    </StyledTableCell>
                  )}

                  <StyledTableCell align="center" className={classes.cell}>
                    {item.subject}
                  </StyledTableCell>

                  {editMode ? (
                    <StyledTableCell align="center" className={classes.cell}>
                      <NepaliDatePicker
                        className={classes.nepaliDatePicker}
                        onChange={(date: string) =>
                          dispatch(
                            changeExamScheduleData({
                              id: item.id,
                              field: "date",
                              value: date,
                            })
                          )
                        }
                        value={
                          item.exam_subject_info.date
                            ? dateConverterAdToBs(item.exam_subject_info.date)
                            : ""
                        }
                        options={{ calenderLocale: "en", valueLocale: "en" }}
                        inputClassName="inputFormControl"
                      />
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.exam_subject_info.date
                        ? returnNepaliDateString(
                            dateConverterAdToBs(item.exam_subject_info.date)
                          )
                        : "--"}
                    </StyledTableCell>
                  )}
                  {editMode ? (
                    <StyledTableCell align="center" className={classes.cell}>
                      <CellInput
                        type="time"
                        value={item.exam_subject_info.time}
                        onChange={(e) =>
                          dispatch(
                            changeExamScheduleData({
                              id: item.id,
                              field: "time",
                              value: e.target.value,
                            })
                          )
                        }
                        fullWidth
                      />
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.exam_subject_info.time
                        ? timeConvertor(item.exam_subject_info.time)
                        : "--"}
                    </StyledTableCell>
                  )}
                  {editMode ? (
                    <StyledTableCell align="center" className={classes.cell}>
                      <CellInput
                        value={item.exam_subject_info.duration}
                        onChange={(e) =>
                          dispatch(
                            changeExamScheduleData({
                              id: item.id,
                              field: "duration",
                              value: e.target.value,
                            })
                          )
                        }
                      />
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.exam_subject_info.duration || "--"}
                    </StyledTableCell>
                  )}

                  {editMode ? (
                    <StyledTableCell align="center" className={classes.cell}>
                      <CellInput
                        value={item.exam_subject_info.full_marks_theory}
                        onChange={(e) =>
                          dispatch(
                            changeExamScheduleData({
                              id: item.id,
                              field: "full_marks_theory",
                              value: e.target.value,
                            })
                          )
                        }
                      />
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.exam_subject_info.full_marks_theory || "--"}
                    </StyledTableCell>
                  )}

                  {editMode ? (
                    <StyledTableCell align="center" className={classes.cell}>
                      <CellInput
                        value={item.exam_subject_info.pass_marks_theory}
                        onChange={(e) =>
                          dispatch(
                            changeExamScheduleData({
                              id: item.id,
                              field: "pass_marks_theory",
                              value: e.target.value,
                            })
                          )
                        }
                      />
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.exam_subject_info.pass_marks_theory || "--"}
                    </StyledTableCell>
                  )}

                  {editMode ? (
                    <StyledTableCell align="center" className={classes.cell}>
                      <CellInput
                        value={item.exam_subject_info.full_marks_practical}
                        onChange={(e) =>
                          dispatch(
                            changeExamScheduleData({
                              id: item.id,
                              field: "full_marks_practical",
                              value: e.target.value,
                            })
                          )
                        }
                        disabled={!item.exam_subject_info.has_practical}
                      />
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.exam_subject_info.full_marks_practical || "--"}
                    </StyledTableCell>
                  )}

                  {editMode ? (
                    <StyledTableCell align="center" className={classes.cell}>
                      <CellInput
                        value={item.exam_subject_info.pass_marks_practical}
                        onChange={(e) =>
                          dispatch(
                            changeExamScheduleData({
                              id: item.id,
                              field: "pass_marks_practical",
                              value: e.target.value,
                            })
                          )
                        }
                        disabled={!item.exam_subject_info.has_practical}
                      />
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.exam_subject_info.pass_marks_practical || "--"}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <Grid item xs={2}>
              {examSchedule.length > 0 && (
                <Grid container justifyContent="flex-end">
                  {!editMode ? (
                    <Button
                      onClick={handleEditClick}
                      className={classes.containedButton}
                      color="primary"
                      variant="contained"
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      onClick={handleSaveClick}
                      className={classes.containedButton}
                      color="primary"
                      variant="contained"
                      startIcon={<SaveIcon />}
                    >
                      Save
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default ExamGroupScheduleModal;
