import React from "react";
import {
  Chip,
  createStyles,
  Divider,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { DownloadButton } from "../../../../../components/Reusable/Buttons/TableButton";
import { downloadEmployeeData } from "../../../../../components/HumanResource/StaffList/StaffProfile/StaffTabs/StaffDocument";
import parse from "html-react-parser";

const useStyles = makeStyles(
  createStyles({
    containers: {
      height: 64,
      flexGrow: 1,
      marginTop: 10,
      // background:
      //   "linear-gradient(90deg, rgba(235,178,242,1) 0%, rgba(245,230,230,1) 100%)",
    },
  })
);

const LessonPlanView = (props: any) => {
  const { item, indexN } = props;
  const classes = useStyles();

  const parseHtmlContent = (content: string) => {
    const doc1 = parse(content);
    return doc1;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" color="primary">
            Lesson Plan Title:
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            {item.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.containers} container spacing={0}>
        <Grid item xs={3}>
          <Grid container direction="column">
            <Typography variant="subtitle1" align="center">
              Unit:
            </Typography>
            <Typography variant="subtitle2" align="center" color="inherit">
              {item.unit_title}
            </Typography>
          </Grid>
        </Grid>
        <Divider
          style={{ marginLeft: "0" }}
          orientation="vertical"
          variant="inset"
        />
        <Grid item xs={2}>
          <Grid container direction="column">
            <Typography variant="subtitle1" align="center">
              Sub Unit:
            </Typography>
            <Typography variant="subtitle2" align="center" color="inherit">
              {item.sub_unit_title ? item.sub_unit_title : item.sub_unit_name}
            </Typography>
          </Grid>
        </Grid>
        <Divider
          style={{ marginLeft: "0" }}
          orientation="vertical"
          variant="inset"
        />
        <Grid item xs={2}>
          <Grid container direction="column">
            <Typography variant="subtitle1" align="center">
              Start Date:
            </Typography>
            <Typography variant="subtitle2" align="center" color="inherit">
              {item.start_date}
            </Typography>
          </Grid>
        </Grid>
        <Divider
          style={{ marginLeft: "0" }}
          orientation="vertical"
          variant="inset"
        />
        <Grid item xs={2}>
          <Grid container direction="column">
            <Typography variant="subtitle1" align="center">
              End Date:
            </Typography>
            <Typography variant="subtitle2" align="center" color="inherit">
              {item.end_date}
            </Typography>
          </Grid>
        </Grid>
        <Divider
          style={{ marginLeft: "0" }}
          orientation="vertical"
          variant="fullWidth"
        />
        <Grid item xs={2}>
          <Grid container direction="column">
            <Typography variant="subtitle1" align="center">
              Due Date:
            </Typography>
            <Typography variant="subtitle2" align="center" color="inherit">
              {item.due_date}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "10px" }} container>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" color="primary">
            Description:
          </Typography>
          <Typography variant="h6" align="center">
            {item?.description && parseHtmlContent(item.description)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginBottom: "10px" }}
            variant="body1"
            align="left"
            color="primary"
          >
            Useful links:
          </Typography>
          {item.reference_links &&
            item.reference_links.map((elem: any, index: number) => (
              <Link key={index} onClick={() => window.open(elem, "_blank")}>
                <Chip
                  style={{
                    marginLeft: "10px",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  key={index}
                  size="small"
                  label={elem}
                />
              </Link>
            ))}
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginBottom: "10px" }}
            variant="body1"
            align="left"
            color="primary"
          >
            Lesson Plan Documents:
          </Typography>
          {item.lesson_plan_document &&
            item.lesson_plan_document.map((elem: any, index: number) => (
              <>
                <Chip
                  style={{ margin: "10px" }}
                  key={index}
                  size="small"
                  label={elem.document.split("/").pop()}
                />
                <DownloadButton
                  onClick={(
                    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ) => downloadEmployeeData(elem.document)}
                />
              </>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default LessonPlanView;
