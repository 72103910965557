import React from 'react';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { PRIMARY_DATE_FORMAT } from '../../../../common/constant';
import GetStatus from '../../../../common/GetStatus';

const StudentLeaveView = (props: any) => {
  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Apply Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {props.items.applied_date}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Leave Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
            {props.items.leave_date} - {props.items.days}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Number of Days:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
            {moment(props.items.days).diff(props.items.leave_date, 'days') + 1}{' '}
            days
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Status:
        </Grid>

        <Grid item xs={8}>
          <GetStatus status={props.items.status} />
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Reason:
        </Grid>

        <Grid item xs={8}>
          {props.items.reason_for_leave || 'No Description'}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default StudentLeaveView;
