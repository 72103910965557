import {
    Button,
    Grid,
    InputBase,
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useStyles } from '../../../../components/Styles/FormStyles';
import { RootStore } from '../../../../store';
import { AddAssignmentFiles, GetAssignmentFiles } from "../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction"

function UploadFile() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const [docName, setDocName] = useState<string | null>('');
    const [file, setFile] = useState<any | null>(null);

    const { register, handleSubmit, setValue, reset, errors } =
        useForm<any>({
            mode: 'onChange',
        });

    const handleDocumetUpload = (value: any) => {
        const fileTypes = ['jpg', 'png', 'jpeg', 'pdf', 'docx', 'doc'];
        const fileType = value[0]?.name.split('.')[1];
        setDocName(value[0].name);
        setFile(value[0]);

        // if (!fileTypes.includes(fileType)) {
        //     return `please upload a valid file format. (${fileTypes})`;
        // } else if (value[0].size > 2000000000000) {
        //     return "Photo size must be lower than 200MB";
        // }
    };

    const handleUploadFile = (elem: any) => {
        const finalValue = new FormData();
        finalValue.append('assignment', id)
        finalValue.append('file', file);
        dispatch(AddAssignmentFiles(finalValue));
        reset();
        setFile(null);
        setTimeout(() => {
            dispatch(GetAssignmentFiles(id))
        }, 2000)
    };

    return (
        <Grid container className={classes.formWrapper}>
            <form>
                <Grid container style={{ display: 'flex' }}>
                    <Grid item xs={5}>
                        <InputBase
                            className={classes.input}
                            id={`id${0}`}
                            type="file"
                            style={{ opacity: 0, position: 'absolute' }}
                            name="document"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleDocumetUpload(event.target.files);
                            }}
                            inputRef={register}
                        />
                        <label htmlFor={`id${0}`}>
                            <Button
                                className={classes.browseButton}
                                color="primary"
                                component="span"
                                style={{ width: '200px' }}
                            >
                                {file != null ? docName : 'Browse'}
                            </Button>
                        </label>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent="flex-end"
                    className={classes.searchBoxButton}
                >
                    <Button
                        startIcon={<CloudUpload />}
                        color="primary"
                        variant="outlined"
                        type="submit"
                        onClick={handleSubmit(handleUploadFile)}
                    >
                        Upload
                    </Button>
                </Grid>
            </form>
        </Grid>
    );
}

export default UploadFile;
