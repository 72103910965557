import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Header from '../Header';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RootStore } from '../../../store';
import {
  Avatar,
  Button,
  CircularProgress,
  Fade,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Container,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { formatNotification } from './NotificationPopover';
import { HRsidebar, NewNavbar } from '../..';
import SubModuleLayout from '../../Reusable/Layouts/SubModuleLayout';
import NotificationSidebar from '../../HumanResource/HrSidebar/NotificationSidebar';
import { GetNotifications } from '../../../actions/HumanResource/Notification/NotificationAction';
import { EventResultCelebrate } from '../../../dashboard/TeacherDashboard/pages/Dashboard/TeacherDashboardPage';
import { Pagination } from '@material-ui/lab';
import { getQueryVariable } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  root: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: '12px',
    backgroundColor: theme.palette.background.paper,
  },
  timelineRoot: {
    '& .MuiTimelineOppositeContent-root': {
      flex: '0',
      flexBasis: '180px',
    },
  },
  inline: {
    display: 'inline',
  },
  paperRoot: {
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
}));

const NotificationPaper = styled(Paper)`
  padding: 8px 16px;
  /* margin: 16px 24px; */
`;

const NotificationItemPaper = styled(Paper)`
  /* padding: 6px 16px; */
  box-shadow: 0px 2px 15px -15px #00000040;
`;

const NotiSubTypo = styled(Typography)`
  font-weight: 500;
  color: #444;
  padding-top: 4px;
  padding-bottom: 2px;
`;

const StyledContainer = styled(Container)`
  /* background: #fff; */
`;
const StyledGridContainer = styled(Box)`
  display: grid;
  grid-template-columns: 240px 3fr 2fr;
`;
const StyledNavGridItem = styled(Box)``;
const StyledAsideGridItem = styled(Box)`
  /* background: teal; */
`;
const StyledContentGridItem = styled(Box)``;
const StyledFormControlLabel = styled(FormControlLabel)`
  display: block;
`;

const NotificationItem = (props: any) => {
  const classes = useStyles();
  const { notification } = props;
  return (
    <NotificationItemPaper>
      <ListItem
        alignItems="flex-start"
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '4px',
          marginBottom: '4px',
          borderLeft: '3px solid #48c126',
          borderRadius: 3,
        }}
      >
        {/* <ListItemAvatar>
          <Avatar alt={''} src={'/favicon.png'} />
        </ListItemAvatar> */}
        <ListItemText
          primary={
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="subtitle2">
                    {''}{' '}
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      style={{ color: 'rgba(0,0,0,0.8)' }}
                    >
                      {notification.text}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="caption" style={{ color: '#777' }}>
                    {moment(notification.created_on).format('LT')}
                  </Typography>
                </Grid>
              </Grid>
            </>
          }
        />
      </ListItem>
    </NotificationItemPaper>
  );
};

const NotificationTimelineItem = (props: any) => {
  const { notification, color } = props;

  return <NotificationItem notification={notification} />;
};

export default function NotificationsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory<any>();
  const [notifications, setNotifications] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const colors = ['default', 'primary', 'secondary'];

  const searchAction = Number(getQueryVariable('action'));

  useEffect(() => {
    dispatch(
      GetNotifications({
        action: searchAction,
      })
    );
  }, []);

  const notificationState = useSelector(
    (state: RootStore) => state.notification
  );

  useEffect(() => {
    const { notifications } = notificationState;
    if (notifications) {
      const tempNotifications = notifications.results.reduce(
        formatNotification,
        {}
      );
      setNotifications(tempNotifications);
    }
  }, [notificationState]);

  const nav = <NotificationSidebar Department action={searchAction} />;

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    // setPage(value);
    console.log('value', value);
    dispatch(GetNotifications({ page: value, action: searchAction }));
  };

  console.log('notifications', notifications);
  return (
    <React.Fragment>
      <NewNavbar />

      <SubModuleLayout sideNav={nav}>
        <div style={{ margin: '16px 24px' }}>
          <Grid container spacing={2}>
            <Grid item md={7}>
              <NotificationPaper>
                {notifications &&
                  Object.keys(notifications).map((notification: any, index) => {
                    return (
                      <div key={index} style={{ marginBottom: 8 }}>
                        <NotiSubTypo>{notification}</NotiSubTypo>
                        {notifications[notification].map((item: any) => {
                          return (
                            <NotificationTimelineItem
                              key={item.id}
                              notification={item}
                              color={colors[index % colors.length]}
                            />
                          );
                        })}
                      </div>
                    );
                  })}

                {notifications &&
                  Object.keys(notifications).length === 0 &&
                  'No Notifications'}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0.5rem',
                  }}
                >
                  {notificationState.notifications && (
                    <Pagination
                      count={notificationState.notifications.total_pages}
                      page={notificationState.notifications.current_page}
                      onChange={handleChange}
                    />
                  )}
                </div>
              </NotificationPaper>
            </Grid>

            <Grid item md={5}>
              <EventResultCelebrate />
            </Grid>
          </Grid>
        </div>
      </SubModuleLayout>
    </React.Fragment>
  );
}
