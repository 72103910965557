import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { Clear, Done } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { PRIMARY_DATE_FORMAT } from "../../../../common/constant";
import StaffCard, {
  StaffCardTypeI,
} from "../../../../components/Reusable/Dialogs/StaffCard.Modal";
import GetStatus from "../../../../common/GetStatus";

const MeetingRequestView = (props: any) => {
  const [staff, setStaff] = useState<StaffCardTypeI | null>(null);

  useEffect(() => {
    const item: StaffCardTypeI = {
      name: props.items.name,
      avatar:
        "https://www.fliplearn.com/wp-content/uploads/2018/11/mrinmoy-roy-1.jpg",
      id: "123",
      designation: "Teacher",
    };

    setStaff(item);
  }, []);

  return (
    <React.Fragment>
      {staff && <StaffCard staff={staff} />}
      <Divider style={{ marginBottom: "20px" }} />
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Meeting Type:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {props.items.meeting_type}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Apply Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {moment(props.items.applied_date).format(PRIMARY_DATE_FORMAT)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Meeting Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {moment(props.items.meeting_date).format(PRIMARY_DATE_FORMAT)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Description:
        </Grid>

        <Grid item xs={8}>
          {props.items.description || "No Description"}
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Status:
        </Grid>

        <Grid item xs={8}>
          {/* {props.items.description || "No Description"} */}
          <GetStatus status={props.items.status} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MeetingRequestView;
