import React, { useState } from "react";
import { ExaminationSideBar } from "../../components";
import ResultLedger from "../../components/Examination/Ledger/ResultLedger";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const LedgerPage = () => {
  const nav = <ExaminationSideBar ledger />;

  return (
    <SubModuleLayout examinationMenuActive sideNav={nav}>
      <ResultLedger />
    </SubModuleLayout>
  );
};

export default LedgerPage;
