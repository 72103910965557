import React, { useEffect, useState } from "react";
import {
  ListSubheader,
  Box,
  Typography,
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
  Collapse,
  makeStyles,
  Theme,
  Avatar,
} from "@material-ui/core";
import { StarBorder, ExpandLess, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import { useLocation, Link } from "react-router-dom";
import checkBrands from "../../config/checkBrands";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
  },
  nested: {
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(2),
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: "40px 0 0 40px",

    "&.Mui-selected": {
      color: "white !important",
      background: "rgba(255, 255, 255, 0.2) !important",
      "& > div > svg": {
        color: "white !important",
      },
    },
  },
  header: {
    paddingTop: 24,
    textAlign: "center",
    color: "white",
    marginBottom: 30,
  },
  sidenav: {
    color: "white",
    position: "fixed",
    top: 0,
    left: 0,
    width: "240px",
    minHeight: "100vh",
  },
  listContainer: {
    paddingLeft: "25px",
    maxHeight: "calc(100vh - 170px)",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  listItem: {
    borderRadius: "40px 0 0 40px",
    "&.Mui-selected": {
      background: `${theme.palette.secondary.main}!important`,
      color: theme.parentPalette.primary,
      "& > div > svg": {
        color: `${theme.parentPalette.primary}!important`,
      },
    },
  },
  listItemText: {
    "& > span": {
      fontSize: 13,
      fontWeight: 500,
    },
  },
  footer: {
    padding: "16px 16px 24px 24px",
    marginTop: "auto",
    color: "white",
  },
  logoAvatar: {
    width: "30px",
    height: "30px",
    marginRight: 8,
  },
  maxHeight: {
    maxHeight: "350px",
    overflowY: "auto",
  },
}));

const EnhancedSidebar = ({
  items,
  open: defaultNestedNavOpen = true,
  extended,
}: any) => {
  const classes = useStyles();
  const [navItems, setNavItems] = useState<any>([]);
  const location = useLocation();

  useEffect(() => {
    const mapNestedActiveStatus = (item: any) => ({
      ...item,
      activeStatus: location.pathname === item.url,
    });

    const mapActiveStatus = (item: any) => {
      if (item.dataSet) {
        return { ...item, dataSet: item.dataSet.map(mapNestedActiveStatus) };
      }
      return { ...item, activeStatus: location.pathname === item.url };
    };

    const dataSet = items.map(mapActiveStatus);
    setNavItems(dataSet);
  }, [location.pathname, items]);

  const SimpleList = ({ item }: any) => {
    return (
      <ListItem
        button
        to={item.url}
        component={Link}
        selected={item.activeStatus}
        className={classes.listItem}
      >
        <Icon icon={item.icon} />
        <ListItemText primary={item.text} className={classes.listItemText} />
      </ListItem>
    );
  };

  const NestedList = ({ item }: any) => {
    const [open, setOpen] = React.useState(
      defaultNestedNavOpen
        ? true
        : item.dataSet.map((d: any) => d.activeStatus).includes(true)
    );
    const classes = useStyles();
    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <>
        <ListItem
          button
          onClick={handleClick}
          className={classes.listItem}
          selected={item.dataSet.map((d: any) => d.activeStatus).includes(true)}
        >
          <Icon icon={item.icon} />

          <ListItemText primary={item.text} className={classes.listItemText} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className={classes.maxHeight}
        >
          <List component="div" disablePadding>
            {item.dataSet &&
              item.dataSet.length > 0 &&
              item.dataSet.map((item: any, index: number) => (
                <ListItem
                  key={index}
                  button
                  selected={item.activeStatus}
                  className={clsx(classes.nested)}
                  style={{ paddingLeft: extended ? "8px" : "16px" }}
                  to={item.url}
                  component={Link}
                >
                  {item.icon ? (
                    <Icon icon={item.icon} />
                  ) : (
                    <Avatar
                      alt="Remy Sharp"
                      src={item.avatar}
                      className={classes.logoAvatar}
                    />
                  )}

                  <ListItemText
                    primary={item.text}
                    className={classes.listItemText}
                  />
                </ListItem>
              ))}
          </List>
        </Collapse>
      </>
    );
  };

  const Header = () => (
    <ListSubheader component="div" style={{ position: "relative" }}>
      <Box className={classes.header}>
        <Typography variant="h6">
          <span style={{ fontWeight: 400 }}>Hamro</span>{" "}
          <span style={{ fontWeight: 600 }}>Academy</span>
        </Typography>
      </Box>
    </ListSubheader>
  );

  const Footer = () => (
    <Box className={classes.footer}>
      <Typography variant="body2" style={{ fontSize: "12px" }}>
        2021{" "}
        {checkBrands("nakshatraacademy")
          ? "Nakshatra Academy"
          : "Hamro Academy"}
        . <br />
        All Rights Reserved
      </Typography>
    </Box>
  );

  const Icon = (props: { icon: any }) => {
    const Icon = props.icon;
    return (
      <ListItemIcon style={{ minWidth: "35px" }}>
        {Icon ? (
          <Icon style={{ color: "white", fontSize: "22px" }} />
        ) : (
          <StarBorder style={{ color: "white !important", fontSize: "22px" }} />
        )}
      </ListItemIcon>
    );
  };

  return (
    <Box className={classes.sidenav}>
      <Box display="flex" flexDirection="column" height="100vh">
        <List component="nav" subheader={<Header />} className={classes.root}>
          <Box
            className={classes.listContainer}
            style={{ paddingLeft: extended ? "8px" : "25px" }}
          >
            {navItems.map((item: any, index: number) => {
              return item.dataSet ? (
                <NestedList
                  key={index}
                  item={item}
                  className={classes.maxHeight}
                />
              ) : (
                <SimpleList
                  key={index}
                  item={item}
                  className={classes.maxHeight}
                />
              );
            })}
          </Box>
        </List>

        <Footer />
      </Box>
    </Box>
  );
};

export default EnhancedSidebar;
