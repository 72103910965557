import { Button, Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { fromToConverter } from "../../../../../components/utils/dateTimeFormat";

export const ViewEventModal = (props: any) => {
  return (
    <>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Title:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {props.item?.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Type:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {props.item?.event_type_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Location:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {props.item?.location}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Date:
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {fromToConverter(props.item?.from_date, props.item?.to_date)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Time:
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {props.item?.time}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Description:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {props.item?.description}
          </Typography>
        </Grid>
      </Grid>

      {/* <Grid container style={{ marginTop: "25px", marginBottom: 15 }}>
        <Grid item xs={12}>
          <Button>Close</Button>
        </Grid>
      </Grid> */}
    </>
  );
};
