import {
  GET_STUDENT_ENOTES_CATEGORIES_LOADING,
  GET_STUDENT_ENOTES_CATEGORIES_ERROR,
  GET_STUDENT_ENOTES_CATEGORIES_SUCCESS,
  GET_STUDENT_ENOTES_LOADING,
  GET_STUDENT_ENOTES_ERROR,
  GET_STUDENT_ENOTES_SUCCESS,
  StudentEnotesCategoryI,
  StudentEnotesI,
  StudentEnotesDispatchTypes,
} from "../../actions/StudentDash/StudentEnote/StudentEnoteActionTypes";

interface InitialStateI {
  loading: boolean;
  enotes: StudentEnotesI[];
  enotes_categories: StudentEnotesCategoryI[];
}

const initialState: InitialStateI = {
  loading: false,
  enotes: [],
  enotes_categories: [],
};

const StudentEnotesReducer = (
  state: InitialStateI = initialState,
  action: StudentEnotesDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STUDENT_ENOTES_CATEGORIES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_ENOTES_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_ENOTES_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        enotes_categories: action.payload,
      };

    case GET_STUDENT_ENOTES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_ENOTES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_ENOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        enotes: action.payload,
      };

    default:
      return state;
  }
};

export default StudentEnotesReducer;
