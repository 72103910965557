import React from "react";
import { FullSubModuleLayout } from "../../../../components/Reusable/Layouts/SubModuleLayout";
import ParentsDashSidebar from "../../../../components/ParentsDash/ParentsDashSidebar";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import { Box } from "@material-ui/core";
import ChildrenListBar from "../LiveClasses/Components/ChildrenListBar";

const ParentSyllabusPage = () => {
  return (
    <Box>
      <PageHeader title="Child Syllabus" />

      <PageWrapper>
        <ChildrenListBar syllabusList />
      </PageWrapper>
    </Box>
  );
};

export default ParentSyllabusPage;
