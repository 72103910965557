import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STAFF_LOADING,
  GET_STAFF_ERROR,
  GET_STAFF_SUCCESS,
  STAFF_BY_ID_LOADING,
  STAFF_BY_ID_ERROR,
  STAFF_BY_ID_SUCCESS,
  GET_TEACHERS_LOADING,
  GET_TEACHERS_ERROR,
  GET_TEACHERS_SUCCESS,
  GET_ALL_TEACHERS_LOADING,
  GET_ALL_TEACHERS_ERROR,
  GET_ALL_TEACHERS_SUCCESS,
  StaffDispatchTypes,
  ADD_STAFF_LOADING,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_ERROR,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_ERROR,
  DELETE_STAFF_LOADING,
  UPDATE_STAFF_LOADING,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_ERROR,
  DISABLE_STAFF_SUCCESS,
  DISABLE_STAFF_ERROR,
  DISABLE_STAFF_LOADING,
  IMPORT_STAFF_LOADING,
  IMPORT_STAFF_SUCCESS,
  IMPORT_STAFF_ERROR,
  GET_DRIVERS_LOADING,
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_ERROR,
  GET_DISABLED_STAFF_LOADING,
  GET_DISABLED_STAFF_ERROR,
  GET_DISABLED_STAFF_SUCCESS,
} from "./StaffActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import { setSnackbar } from "../../SnackbarAction";

/*
interface RequestDataI {
  id?: string;
  first_name: string;
  last_name: string;
  address: string;
  phone: string;
  email: string;
  dob: string;
  gender: string;
  religion: string;
  blood_group: string;
  pan_string: string;
  date_of_joining: string;
  working_days: string;
  leave_days: string;
  highest_degree: string;
}
*/

export const GetStaffs =
  (id: string | number) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STAFF_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/staff/?page=${id}&per_page=1000`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STAFF_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STAFF_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Staffs Loading Failed",
      //   },
      // });
    }
  };

export const GetPaginatedStaffs =
  (
    id: string | number,
    highest: number,
    department = "",
    designation = "",
    search = ""
  ) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STAFF_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/staff/?page=${id}&per_page=${highest}&department_id=${department}&designation_id=${designation}&search=${search}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STAFF_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STAFF_ERROR,
      });
    }
  };

export const GetTeachers =
  (search: string) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHERS_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/staff/?designation=teacher&page=1&per_page=1000`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHERS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHERS_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Staffs Loading Failed",
      //   },
      // });
    }
  };

export const GetTeachersByName =
  (search: string) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHERS_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/staff/?designation=teacher&search=${search}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHERS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHERS_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Staffs Loading Failed",
      //   },
      // });
    }
  };

export const GetAllTeachers =
  (search: string) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ALL_TEACHERS_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/staff/?designation=teacher&page=1&per_page=1000`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ALL_TEACHERS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_TEACHERS_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Staffs Loading Failed",
      //   },
      // });
    }
  };

export const GetStaffByID =
  (id: string) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STAFF_BY_ID_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/staff/${id}`,
        HeaderConfig()
      );

      dispatch({
        type: STAFF_BY_ID_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_BY_ID_ERROR,
      });
    }
  };

export const AddStaff =
  (data: any) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_STAFF_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/staff/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_STAFF_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Staff Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_STAFF_ERROR,
        payload: error.response?.data,
      });
    }
  };

export const UpdateStaff =
  (id: string, data: any) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_STAFF_LOADING,
      });

      const res = await axios.patch(
        `${ADMIN_API_URL}/staff/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_STAFF_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Staff Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_STAFF_ERROR,
        payload: error.response?.data,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Staff Update Failed",
      //   },
      // });
    }
  };

export const DeleteStaff =
  (id: string) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_STAFF_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/staff/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_STAFF_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Staff Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_STAFF_ERROR,
      });
    } finally {
      try {
        dispatch({
          type: GET_STAFF_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/staff/?page=${1}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_STAFF_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_STAFF_ERROR,
        });
      }
    }
  };

export const ImportStaff =
  (data: any) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: IMPORT_STAFF_LOADING,
      });

      await axios.post(
        `${ADMIN_API_URL}/upload_staffs/?parse_sheet`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: IMPORT_STAFF_SUCCESS,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Staff Data Imported Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: IMPORT_STAFF_ERROR,
      });
      dispatch<any>(setSnackbar(true, "warning", "Failed to import data"));
    }
  };

export const GetDrivers =
  () => async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_DRIVERS_LOADING,
      });

      const res = await axios.get(`${ADMIN_API_URL}/drivers`, HeaderConfig());

      dispatch({
        type: GET_DRIVERS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_DRIVERS_ERROR,
      });
    }
  };

export const DisableStaff =
  (data: { staff: string; remarks: string }) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DISABLE_STAFF_LOADING,
      });

      await axios.post(`${ADMIN_API_URL}/update_status/`, data, HeaderConfig());

      dispatch({
        type: DISABLE_STAFF_SUCCESS,
        payload: { id: data.staff },
      });
    } catch (error) {
      dispatch({
        type: DISABLE_STAFF_ERROR,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Failed to disable staff",
        },
      });
    }
  };

export const GetDisabledStaffs =
  (params: { per_page: number; page: number }) =>
  async (dispatch: Dispatch<StaffDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_DISABLED_STAFF_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/disable_staff/?per_page=${params.per_page}&page=${params.page}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_DISABLED_STAFF_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_DISABLED_STAFF_ERROR,
      });
    }
  };
