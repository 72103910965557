import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import { default as Tab } from '@material-ui/core/Tab';
import {
  DashboardOutlined,
  SchoolOutlined,
  LibraryBooksOutlined,
  WcOutlined,
  MenuBookOutlined,
  Assignment,
  SmsOutlined,
  DonutLargeOutlined,
  DirectionsBusOutlined,
  QueueOutlined,
  PaymentOutlined,
  ArrowBack,
  Settings,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import {
  Button,
  ClickAwayListener,
  IconButton,
  SvgIconTypeMap,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { ADMINISTRATOR_MAIN_ROUTE } from './RouteAdministrator';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '300px',
    marginLeft: '24px',
    marginRight: '24px',
    backgroundColor: theme.palette.background.paper,

    '& .MuiTab-labelIcon': {
      minHeight: '40px',
      paddingTop: '6px!important',
    },
    '& .MuiTab-root': {
      fontSize: '14px',
      textTransform: 'none',
      minWidth: '110px',
      maxWidth: '200px',
      height: '30px',
      letterSpacing: '0.01071em',
    },
    '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
      marginRight: '4px',
      marginBottom: '0px',
    },
    '& .MuiTabs-flexContainer': {
      // margin: theme.spacing(0, 2),
    },
    '& .MuiTypography-root': {
      color: '#000',
    },
    '& .MuiPaper-elevation4': {
      // boxShadow: "2px 1px 3px 0px rgba(0,0,0,0.1)",
      boxShadow: '1px 1px 30px 6px rgb(0 0 0 / 10%)',
    },
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-labelIcon':
      {
        overflow: 'visible',
      },
    '& a.Mui-selected::after': {
      content: "''",
      position: 'absolute',
      bottom: '-4px',
      left: '0px',
      width: '100%',
      borderBottom: '2px solid',
    },

    '& span.MuiTabs-indicator': {
      display: 'none!important',
    },
  },
  classTabs: {
    '& a.Mui-selected::after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: '0px',
      width: '100%',
      borderBottom: '2px solid',
    },
  },

  classroom: {
    textDecoration: 'none',
  },
  classbutton: {
    // height: "20px !important",
    opacity: 1,
    fontSize: '10px',
    backgroundColor: '#3FC581',
    color: '#fff',
    textTransform: 'none',
    fontWeight: 600,
    borderRadius: '4px',
    padding: '6px 10px',
    minHeight: '20px',
    minWidth: '160px',
    marginRight: '16px',
    '& .MuiTab-root': {
      padding: 0,
      minWidth: '120px',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
  },
  navbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // height: "48px",
    '& > .MuiTabs-root': {
      // overflow: "visible",
    },
    '& .MuiTabs-scroller': {
      display: 'flex',
      alignItems: 'center',
      // overflow: "visible",
    },
  },
  setting: {
    backgroundColor: '#fff !important',
    '& .MuiSvgIcon-root': {
      color: '#000',
    },
  },
  backIcon: {
    color: '#132E98',
    fontWeight: 700,
  },
  hideTabButton: {
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: '-2px',
      background: 'white',
      zIndex: '9999',
    },
  },
  removeTabButon: {
    minWidth: '0px!important',
    height: '0px!important',
  },

  moreBtn: {
    '&:hover .dropdown-content': {
      display: 'block',
    },
  },
  dropdown: {
    display: 'inline-block',
    position: 'relative',

    '& .dropdown-content': {
      color: 'rgba(0, 0, 0, 0.54)!important',
      // display: "none",
      position: 'absolute',
      width: '200px',
      left: '-134px',
      top: '52px',
      paddingTop: '5px',
      paddingBottom: '5px',
      background: 'white',
      textAlign: 'left',
      boxShadow: '1px 1px 25px -4px rgb(0 0 0 / 10%)',
      borderRadius: '4px',

      '& .active': {
        color: '#132E98',
      },

      '& .MuiTab-wrapper': {
        justifyContent: 'flex-start',
        gap: '2px',
      },
    },
  },

  tabs: {
    padding: '0px 22px 0 0',

    '& > .MuiTabScrollButton-root': {
      boxShadow: '-15px 0px 10px 10px rgb(255 255 255)',
      '&:first-child': {
        boxShadow: '15px 0px 10px 10px rgb(255 255 255)',
      },
      width: '30px',
      zIndex: 9,
      '& > *': {
        fontSize: '16px',
      },
    },
  },
}));

function NavItem(props: { item: NavItemTypeI }) {
  return (
    <Tab
      value={props.item.id}
      to={props.item.href}
      component={Link}
      icon={props.item.icon && <props.item.icon />}
      label={props.item.name}
    />
  );
}

export default function NewNavbar() {
  const classes = useStyles();
  const [value, setValue] = React.useState<number>(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [itemsInDropdown, setItemsInDropdown] = useState(4);
  const [mQuery, setMQuery] = React.useState<any>({
    matches: window.innerWidth > 1750 ? true : false,
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1750px)');
    mediaQuery.addListener(setMQuery);

    // this is the cleanup function to remove the listener
    return () => mediaQuery.removeListener(setMQuery);
  }, []);

  useEffect(() => {
    if (mQuery.matches) {
      setItemsInDropdown(0);
    } else {
      setItemsInDropdown(0);
    }
  }, [mQuery]);

  const path = location.pathname;
  useEffect(() => {
    if (path === '/') setValue(0);
    else if (
      path === '/student' ||
      path === '/student-admission' ||
      path === '/disabled-student' ||
      path === '/bulk-delete' ||
      path === '/student-categories' ||
      path === '/student-house' ||
      path === '/parent-details' ||
      path === '/import-student-info' ||
      path === '/studentprofile'
    )
      setValue(1);
    else if (
      path === '/section' ||
      path === '/class' ||
      path === '/subject' ||
      path === '/subject-group' ||
      path === '/timetable' ||
      path === '/assign-class-teacher' ||
      path === '/syllabus'
    )
      setValue(2);
    else if (
      path === '/designation' ||
      path === '/department' ||
      path === '/staff' ||
      path === '/add-staff'
    )
      setValue(3);
    else if (
      path === '/examination' ||
      path === '/exam-schedule' ||
      path === '/add-marks' ||
      path === '/grades' ||
      path === '/results'
    )
      setValue(4);
    else if (
      path === '/communication' ||
      path === '/sms' ||
      path === '/sms-log' ||
      path === '/sms-templates' ||
      path === '/send-results'
    )
      setValue(5);
    else if (
      path === '/report' ||
      path === '/admit-card' ||
      path === '/marksheet' ||
      path === '/gradesheet' ||
      path === '/character-certificate' ||
      path === '/marksheet-certificate' ||
      path === '/gradesheet-certificate' ||
      path === '/id-card-template'
    )
      setValue(6);
    else if (
      path === '/income' ||
      path === '/add-income' ||
      path === '/expense' ||
      path === '/add-expenses' ||
      path === '/collect-fees' ||
      path === '/fee-ledger' ||
      path === '/fee-type' ||
      path === '/due-fee' ||
      path === '/discount-type' ||
      path === '/payment-method' ||
      path === '/fine-type' ||
      path === '/send-due-fee' ||
      path === '/due-report' ||
      path === '/fee-type-report' ||
      path === '/monthly-report'
    )
      setValue(7);
    else if (path === '/event' || path === '/event-type') setValue(8);
    else if (path === '/routes' || path === '/bus-user' || path === '/vehicles')
      setValue(9);
    else if (
      path === '/classroom/online-class' ||
      path === '/classroom/teacher'
    )
      setValue(11);
    else if (path === '/scholarship-type' || path === '/apply-scholarship')
      setValue(10);
    else if (path === '/classroom/assignment') setValue(12);
    else if (path === '/classroom/enotes') setValue(13);
    else if (path === '/classroom/syllabus') setValue(14);
    else if (path === '/classroom/lesson-plan') setValue(15);
    else if (
      path === '/session' ||
      path === '/general-setting' ||
      path === '/email-setting' ||
      path === '/sms-template' ||
      path === '/roles' ||
      path === '/manage-password'
    )
      setValue(16);
  }, [path]);

  const [url, setUrl] = useState(false);
  useEffect(() => {
    const a = window.location.pathname;
    const b = a.split('/');
    if (b.includes('classroom')) {
      setUrl(true);
    } else {
      setUrl(false);
    }
  }, [window.location.pathname]);

  const handleClickAway = () => {
    setIsDropdownOpen(false);
  };
  // console.log({ value });

  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed" color="inherit">
          <Header />
          {url == true ? (
            <div className={classes.navbar}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
                className={classes.classTabs}
              >
                {classNavItemsData &&
                  classNavItemsData.length > 0 &&
                  classNavItemsData.map((item) => (
                    <Tab
                      key={item.id}
                      value={item.id}
                      to={item.href}
                      component={Link}
                      icon={item.icon && <item.icon />}
                      label={item.name}
                    />
                  ))}
              </Tabs>

              <div className={classes.root}>
                <Button
                  style={{ marginTop: '5px' }}
                  className={classes.backIcon}
                  to="/"
                  component={Link}
                >
                  <ArrowBack /> Back to Dashboard
                </Button>
              </div>
            </div>
          ) : (
            <div className={classes.navbar}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
                className={classes.tabs}
              >
                {adminMainNavItems &&
                  adminMainNavItems.length > 0 &&
                  adminMainNavItems
                    .slice(0, adminMainNavItems.length - itemsInDropdown)
                    .map((item) => (
                      <Tab
                        key={item.id}
                        value={item.id}
                        to={item.href}
                        component={Link}
                        icon={item.icon && <item.icon />}
                        label={item.name}
                      />
                    ))}
              </Tabs>
              {itemsInDropdown ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div style={{ flex: 1 }}>
                    <div className={classes.dropdown}>
                      <IconButton
                        className={classes.setting}
                        onClick={() => setIsDropdownOpen((open) => !open)}
                      >
                        <MoreVertIcon style={{ padding: 0 }} />
                      </IconButton>

                      {isDropdownOpen && (
                        <div
                          className="dropdown-content"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          {adminMainNavItems &&
                            adminMainNavItems.length > 0 &&
                            adminMainNavItems
                              .slice(adminMainNavItems.length - itemsInDropdown)
                              .map((item) => (
                                <NavItem key={item.id} item={item} />
                              ))}
                        </div>
                      )}
                    </div>
                  </div>
                </ClickAwayListener>
              ) : null}
              <>
                {/* <div className={classes.root}>
                  <Button
                    component={Link}
                    to="/library"
                    className={classes.classbutton}
                  >
                    Go To Library
                  </Button>
                </div>
                {localStorage.getItem("online_class") === "true" && (
                  <div className={classes.root}>
                    <Button
                      component={Link}
                      to="/classroom/online-class"
                      className={classes.classbutton}
                    >
                      Go To Classroom
                    </Button>
                  </div>
                )} */}
              </>
            </div>
          )}
        </AppBar>
      </div>
    </>
  );
}

// console.log('ADMINISTRATOR_MAIN_ROUTE', ADMINISTRATOR_MAIN_ROUTE);

interface NavItemTypeI {
  id: number;
  name: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  href: string;
}

export const adminMainNavItems: NavItemTypeI[] = [
  {
    id: 0,
    name: 'Dashboard',
    icon: DashboardOutlined,
    href: '/',
  },
  {
    id: 1,
    name: 'Student',
    icon: SchoolOutlined,
    href: '/student',
  },
  {
    id: 2,
    name: 'Academic',
    icon: LibraryBooksOutlined,
    href: '/section',
  },
  {
    id: 3,
    name: 'Human Resource',
    icon: WcOutlined,
    href: '/designation',
  },
  {
    id: 4,
    name: 'Examinations',
    icon: MenuBookOutlined,
    href: '/examination',
  },
  {
    id: 5,
    name: 'Communications',
    icon: SmsOutlined,
    href: '/communication',
  },
  {
    id: 6,
    name: 'Reports',
    icon: Assignment,
    href: '/report',
  },
  {
    id: 7,
    name: 'Finance',
    icon: QueueOutlined,
    href: '/collect-fees',
  },
  {
    id: 8,
    name: 'Event',
    icon: DonutLargeOutlined,
    href: '/event',
  },
  {
    id: 9,
    name: 'Transport',
    icon: DirectionsBusOutlined,
    href: '/vehicles',
  },
  {
    id: 10,
    name: 'Scholarship',
    icon: SchoolOutlined,
    href: '/scholarship-type',
  },
];

const classNavItemsData: NavItemTypeI[] = [
  {
    id: 11,
    name: 'Online Class',
    href: '/classroom/online-class',
  },
  {
    id: 12,
    name: 'Assignment',
    href: '/classroom/assignment',
  },
  {
    id: 13,
    name: 'E-Notes',
    href: '/classroom/enotes',
  },
  {
    id: 14,
    name: 'Syllabus',
    href: '/classroom/syllabus',
  },
  {
    id: 15,
    name: 'Lesson Plan',
    href: '/classroom/lesson-plan',
  },
];
