// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import {
  AddTeacherAssignment,
  GetTeacherAssignment,
  UpdateTeacherAssignment,
} from "../../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction";
import {
  GetTeacherAllSubjects,
  GetTeacherSubject,
} from "../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectAction";
import { GetTeacherClassSection } from "../../../../../actions/TeacherDash/TeacherClass/TeacherClassAction";
import FormLayout from "../../../../../components/Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../../../../components/Reusable/Inputs/TextField";
import { DepartmentTypeI } from "../../../../../actions/HumanResource/Department/DepartmentActionTypes";
import useLoading from "../../../../../useHook/useLoading";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Chip,
  Grid,
  InputLabel,
  Paper,
  Typography,
  makeStyles,
  Theme,
  Checkbox,
  TextField,
} from "@material-ui/core";

import { CloudUpload } from "@material-ui/icons";
import { useFormStyles } from "../../../../../components/Styles/FormStyles";
import CustomizedSelect from "../../../../../components/Reusable/Inputs/Select";

import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import { FormSaveButton } from "../../../../../components/Reusable/Buttons/FormButton";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../../../../components/utils/dateConverter";
import { Autocomplete } from "@material-ui/lab";
import { SUBJECT_TYPE_CHOICES } from "../../../../../common/constant";
import {
  CreateTeacherEnotes,
  GetTeacherEnotesCategories,
} from "../../../../../actions/TeacherDash/TeacherEnotes/TeacherEnotesAction";
import { TeacherEnotesCategoryI } from "../../../../../actions/TeacherDash/TeacherEnotes/TeacherEnotesActionTypes";
import { setSnackbar } from "../../../../../actions/SnackbarAction";
import { GetLessonPlan } from "../../../../../actions/TeacherDash/LessonPlan/LessonPlanAction";
import { GetTeachingClass } from "../../../../../actions/TeacherDash/TeachingClass/TeachingClassAction";
import { TeachingClassTypeI } from "../../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";
import { SectionTypeI } from "../../../../../actions/Academics/Section/SectionActionTypes";
import { isObjectEmpty } from "../../../../../components/utils/utils";
import {
  filterStyle,
  tableStyle,
} from "../../../components/Styles/TeacherDashboardStyles";
import { SectionI } from "../../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";

// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//

// interface SectionI {
//   key: string;
//   value: string;
// }

interface ModClassChoicesI {
  grade: number;
  grade_name: string;
  section: ModSectionsI[];
}

interface ModSectionsI {
  section: number;
  section_name: string;
}

interface SubjectChoiceI {
  key: string;
  value: string;
}

interface SectionII {
  id: string;
  name: string;
}
interface ClassII {
  id: string;
  grade: string;
  grade_name: string;
  section: SectionII[] | null | string;
  section_name?: string;
}

// ---------------- <END> Interface ------------------//

const useUploadStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  faceImage: {
    color: theme.palette.primary.light,
  },
}));

// ---------------- <START> Department Form Component ------------------//
const TeacherEnoteForm = (props: any) => {
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const uploadClasses = useUploadStyles();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    setError,
    clearErrors,
  } = useForm({ mode: "onChange" });
  const history = useHistory();
  const [classChoice, setClassChoice] = useState<any | null>(null);
  const [selectedClass, setSelectedClass] = useState<ClassII | null>(null);
  const [classChoices, setClassChoices] = useState<ClassII[]>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [subjectDisabler, setSubjectDisabler] = useState<boolean>(true);
  const [lessonPlanDisabler, setLessonPlanDisabler] = useState<boolean>(true);
  const [sectionChoice, setSectionChoice] = useState<any | null>(null);
  const [selectedSection, setSelectedSection] = useState<any | null>(null);
  const [enoteCategoryChoice, setEnoteCategoryChoice] = useState<any>(null);
  const [lessonPlanChoice, setLessonPlanChoice] = useState<any>(null);
  const [sectionChoices, setSectionChoices] = useState<any>([]);
  const [subjectChoice, setSubjectChoice] = useState<any | null>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [assignDate, setAssignDate] = useState<any>();
  const [deadlineDate, setDeadlineDate] = useState<any>();
  const [editorState, setEditorState] = useState<any>("");
  const { assignmentID } = useParams<{ assignmentID: string }>();
  const [isFile, setIsFile] = useState<boolean>(false);
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const [today] = useState<any>(new Date());
  const [isOwnDocument, setIsOwnDocument] = useState(false);
  const [linkChipText, setLinkChipText] = useState("");

  const [enoteFiles, setEnoteFiles] = useState<any>([]);
  const [enoteDocs, setEnoteDocs] = useState<any[]>([]);

  const [redirect, setRedirect] = useState<boolean>(false);

  // REACT HOOKS /////////////////////////////////////////////////

  const classSelector = useSelector((state: RootStore) => state.teaching_class);

  useEffect(() => {
    dispatch(GetTeachingClass());
    dispatch(GetTeacherEnotesCategories());
  }, []);

  useEffect(() => {
    const classes = classSelector.teaching_class;
    const finalArray: ClassII[] = [];
    classes.forEach((item: any) => {
      if (item.section === null) {
        finalArray.push(item);
      } else {
        for (let i = 0; i < item.section.length; i++) {
          const data1 = {
            ...item,
            section: item.section[i].id,
            section_name: item.section[i].name,
          };
          finalArray.push(data1);
        }
      }
    });
    setClassChoices(finalArray);
    setSelectedClass(finalArray[0]);
    // const mod_class: ModClassChoicesI[] = [];
    // console.log("data: ", data);

    // if (data.length) {
    //   data.map((element) => {
    //     if (mod_class.find((mod) => mod.grade === element.grade)) {
    //       if (element.section && element.section_name) {
    //         mod_class
    //           .find((mod) => mod.grade === element.grade)
    //           ?.section.push({
    //             section: element.section,
    //             section_name: element.section_name,
    //           });
    //       }
    //     } else {
    //       if (element.section && element.section_name) {
    //         mod_class.push({
    //           grade: element.grade,
    //           grade_name: element.grade_name,
    //           section: [
    //             {
    //               section: element.section,
    //               section_name: element.section_name,
    //             },
    //           ],
    //         });
    //       } else {
    //         mod_class.push({
    //           grade: element.grade,
    //           grade_name: element.grade_name,
    //           section: [],
    //         });
    //       }
    //     }
    //   });
    // }
    // setClassChoices(data);
  }, [classSelector.teaching_class]);

  useEffect(() => {
    if (subjectChoice) {
      setLessonPlanDisabler(false);
      if (selectedClass) {
        dispatch(GetLessonPlan(subjectChoice.subject_id, selectedClass.grade));
      }
    } else {
      setLessonPlanDisabler(true);
    }
  }, [subjectChoice]);
  ////////////////////////////////////////////////////////////////

  useEffect(() => {
    // dispatch(GetTeacherSubject());
    // assignmentID != undefined &&
    // dispatch(GetTeacherAssignment(Number(assignmentID)));
    let temp = moment(today).format("YYYY-MM-DD");
    temp = dateConverterAdToBs(temp);
    setAssignDate(temp);
    setDeadlineDate(temp);
  }, []);

  // checking file state data is File Object or not.
  const isFilex = (input: any) => {
    if ("File" in window && input instanceof Blob) return true;
    else return false;
  };
  useEffect(() => {
    setIsFile(isFilex(selectedFile && selectedFile[0]));
  }, [selectedFile]);

  const departmentState = useSelector((state: RootStore) => state.department);
  const subjectState = useSelector((state: RootStore) => state.teacher_subject);
  const enoteState = useSelector((state: RootStore) => state.teacher_enotes);
  const lessonPlanState = useSelector(
    (state: RootStore) => state.teacher_lessonPlan
  );
  const errorsData = useSelector(
    (state: RootStore) => state.teacher_assignment.errors
  );
  const teacherAssignmentState = useSelector(
    (state: RootStore) => state.teacher_assignment
  );
  const singleAssignmentState = useSelector(
    (state: RootStore) => state.teacher_assignment.assignment
  );
  const { loading } = useLoading(departmentState.loading);

  useEffect(() => {
    if (classChoice != null) {
      const temp = classSelector.teaching_class.filter(
        (item: any) => item.grade == classChoice.key
      );
      const choices: any[] = temp.map((elem: any) => ({
        key: elem.section.toString(),
        value: `${elem.section_name.toUpperCase()}`,
      }));
      setSectionChoices(choices);
    }
  }, [classChoice]);

  // When there is server side error while submiting assignment form
  useEffect(() => {
    if (errorsData != null) {
      const keys = Object.keys(errorsData.error);
      keys.map((elem: any) => {
        setError(elem, {
          type: "serverSideError",
          message: errorsData.error[elem] && errorsData.error[elem][0],
        });
      });
    }
  }, [errorsData]);

  const SELECT_CLASS_CHOICES: any[] = classSelector.teaching_class.map(
    (classItem: any) => ({
      key: classItem.grade.toString(),
      value: `Class ${classItem.grade_name.toUpperCase()}`,
    })
  );

  const SELECT_SUBJECT_CHOICES: any[] = subjectState.subjects.map(
    (subjectItem: any) => ({
      key: subjectItem.subject.toString(),
      value: subjectItem.subject_name,
      subject_id: subjectItem.subject,
    })
  );

  const SELECT_ENOTES_CATEGORIES_CHOICES: any[] =
    enoteState.enotes_categories.map((enoteItem: TeacherEnotesCategoryI) => ({
      key: enoteItem.id.toString(),
      value: enoteItem.name,
      enote_id: enoteItem.id,
    }));

  const SELECT_LESSON_PLAN_CHOICES: any[] = lessonPlanState.lesson_plan.map(
    (lessonPlan: TeacherEnotesCategoryI) => ({
      key: lessonPlan?.id.toString(),
      value: lessonPlan?.name,
      lesson_id: lessonPlan?.id,
    })
  );

  const checkValidityString = (value: string, field_name: string) => {
    if (value === "" || value === null || value == undefined) {
      setError(field_name, {
        type: "required",
        message: "This field is required",
      });
      return null;
    } else {
      return dateConverterBsToAd(value);
    }
  };

  const selectedAssignment: any = teacherAssignmentState.assignment;

  // Populate initial filled data for input field ( in update case)
  useEffect(() => {
    if (singleAssignmentState !== (null || undefined)) {
      setValue("title", selectedAssignment?.title);
      // setDeadlineDate(dateConverterAdToBs(selectedAssignment?.deadline));
      // setAssignDate(dateConverterAdToBs(selectedAssignment?.assign_on));
      setEditorState(
        BraftEditor.createEditorState(selectedAssignment?.description)
      );
      setSubjectChoice(
        SELECT_SUBJECT_CHOICES.find(
          (item: any) =>
            (item.id === selectedAssignment.subject) != null &&
            selectedAssignment.subject
        )
      );
      setClassChoice(
        SELECT_CLASS_CHOICES.find(
          (item: any) =>
            (item.id === selectedAssignment.subject) != null &&
            selectedAssignment.subject
        )
      );
      setSelectedFile(selectedAssignment.attached_file);
    }
  }, [singleAssignmentState]);

  useEffect(() => {
    const arr: any[] = [];
    enoteFiles.map((data: File) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(data);

      fileReader.onload = () => {
        arr.push({ title: data.name, document: fileReader.result as string });
      };
    });
    setEnoteDocs(arr);
    enoteFiles.length && clearErrors("files");
  }, [enoteFiles]);

  const onSubmit = async (data: any) => {
    if (selectedClass && subjectChoice && enoteCategoryChoice) {
      const links = chipData.map((element: any) => element.label);

      let post_data: any = {
        title: data.title,
        meta_description: data.meta_description,
        grade: selectedClass.grade,
        section: selectedSection ? selectedSection.id : null,
        subject: subjectChoice.subject_id,
        category: enoteCategoryChoice.enote_id,
        reference_links: links,
        lesson_plan: lessonPlanChoice?.lesson_id,
      };

      if (isOwnDocument) {
        if (editorState) {
          const editorData = editorState.toHTML();
          post_data = { ...post_data, enote_description: editorData };
          dispatch(CreateTeacherEnotes(post_data));
          setIsAdded(true);
        } else {
          dispatch(
            setSnackbar(true, "warning", "Editor field cannot be empty")
          );
        }
      } else {
        if (enoteDocs.length) {
          post_data = { ...post_data, documents: enoteDocs };

          dispatch(CreateTeacherEnotes(post_data));
          setIsAdded(true);
        } else {
          setError("files", {
            type: "required",
            message: "Upload some documents",
          });
        }
      }
    }
  };

  const inputStyle = { marginBottom: "0px" };

  const handleFileUpload = ({ target }: any) => {
    setSelectedFile(target.files[0]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOwnDocument(event.target.checked);
    setEnoteFiles([]);
  };

  const [chipData, setChipData] = React.useState<any>([]);

  const handleDelete = (chipToDelete: any) => {
    setChipData((chips: any) =>
      chips.filter((chip: any) => chip.key !== chipToDelete.key)
    );
  };

  const handleLinkChipChange = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const linkVal: string = event.target.value;

      const checkStart = () => {
        if (linkVal.startsWith("http://") || linkVal.startsWith("https://")) {
          return true;
        }
        return false;
      };

      if (checkStart()) {
        setChipData([
          ...chipData,
          { key: chipData.length + 1, label: event.target.value },
        ]);
        setLinkChipText("");
      } else {
        dispatch(
          setSnackbar(
            true,
            "warning",
            `invalid url, eg. "http://www.google.com"`
          )
        );
      }
    }
  };

  const handleLinkChipText = (event: any) =>
    setLinkChipText(event.target.value);

  const handleEnoteFileUpload = (event: any) => {
    const filesArray = Array.from(event.target.files);

    setEnoteFiles([...enoteFiles, ...filesArray]);
  };

  const handleFileDelete = (file: any) => {
    const files = enoteFiles
      ? Array.from(enoteFiles).filter((item: any) => item.name !== file.name)
      : null;
    setEnoteFiles(files);
  };
  const OPTIONS_DEMO = [
    { key: "1", value: "Option 1" },
    { key: "2", value: "Option 2" },
    { key: "3", value: "Option 3" },
  ];

  const handleClassChange = (value: ClassII | null) => {
    setSelectedClass(value);
    setSelectedSection(null);
    setSubjectChoice(null);
    setLessonPlanChoice(null);
    if (value) {
      populateSectionChoices(value.section);
      setSubjectDisabler(false);
      dispatch(GetTeacherSubject(value.grade, ""));
    } else {
      setSectionDisabler(true);
      setSubjectDisabler(true);
      setLessonPlanDisabler(true);
    }
    clearErrors(["section_name"]);
  };

  useEffect(() => {
    if (selectedClass != null && selectedSection != null) {
      dispatch(
        GetTeacherSubject(selectedClass.grade as string, selectedSection.id)
      );
    }
  }, [selectedSection]);

  const populateSectionChoices = (sections: SectionII[] | null | string) => {
    if (sections === null) return;
    if (sections.length > 0) {
      setSectionDisabler(false);
      setSectionChoices(sections);
    } else {
      setSectionChoices([]);
      setSectionDisabler(true);
    }
  };

  // Redirecting to assignment list after adding or updating of assignment
  // if (isAdded && errorsData === null) {
  //   return <Redirect to="/enote" />;
  // }

  /* Close redirect when user has submitted form, if state is not loading,
  has not server errors and has no hook-form errors */
  useEffect(() => {
    if (isAdded && !enoteState.loading) {
      if (!errorsData && isObjectEmpty(errors)) {
        history.push("/enote");
      }
    }
  }, [enoteState, isAdded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Grid container>
        <Grid item xs={8}>
          <Paper style={filterStyle}>
            <Typography variant="h6" style={{ marginBottom: "12px" }}>
              Enotes
            </Typography>

            <Grid container spacing={3}>
              <Grid
                item
                xs={4}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <InputLabel>Select Class</InputLabel>
                <Autocomplete
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: ClassII | null
                  ) => handleClassChange(value)}
                  options={classChoices}
                  value={selectedClass}
                  getOptionLabel={(option) =>
                    `Class ${option.grade_name} ${
                      option.section_name !== undefined
                        ? `(${option.section_name})`
                        : ""
                    }`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Label"
                      name="class_name"
                      helperText={
                        errors?.class_name ? "Class must be selected" : ""
                      }
                      inputRef={register({ required: true })}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={4}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <InputLabel>Select Section</InputLabel>
                <Autocomplete
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: SectionTypeI | null
                  ) => setSelectedSection(value)}
                  options={sectionChoices}
                  value={selectedSection}
                  getOptionLabel={(option) => option.name}
                  disabled={sectionDisabler}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Label"
                      name="section_name"
                      inputRef={register}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={4}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <CustomizedSelect
                  label="Select Subject"
                  options={SELECT_SUBJECT_CHOICES}
                  value={subjectChoice}
                  setValue={setSubjectChoice}
                  disabled={subjectDisabler}
                  name="subject_choice"
                  error={
                    errors["subject_choice"] ? "Subject must be selected" : ""
                  }
                  required
                  inputRef={register({ required: true })}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <CustomizedTextField
                  label="Title"
                  placeholder="Label"
                  name="title"
                  error={errors["title"] ? "Title is required" : ""}
                  required
                  inputRef={register({ required: true })}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <CustomizedTextField
                  label="Short Description"
                  placeholder="Label"
                  name="meta_description"
                  multiline
                  rows={2}
                  error={
                    errors["meta_description"]
                      ? "Short description is required"
                      : ""
                  }
                  required
                  inputRef={register({ required: true })}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <CustomizedTextField
                  label="Add useful links"
                  placeholder={
                    chipData.length >= 3 ? "Only 3 links are allowed" : "Label"
                  }
                  name="useful_links"
                  type="url"
                  value={linkChipText}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLinkChipText(event.target.value);
                  }}
                  disabled={chipData.length >= 3}
                  onKeyDown={handleLinkChipChange}
                />
              </Grid>

              {chipData.map((data: any) => {
                return (
                  <Chip
                    key={data.key}
                    label={data.label}
                    onDelete={() => handleDelete(data)}
                    className={classes.chip}
                    size="small"
                    style={{
                      marginTop: "-8px",
                      marginBottom: "24px",
                      marginLeft: "16px",
                    }}
                  />
                );
              })}
            </Grid>

            <Grid container spacing={2}>
              {isOwnDocument ? (
                ""
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    className={classes.formWrapper}
                    style={inputStyle}
                  >
                    <InputLabel>Add enote files</InputLabel>

                    <Button
                      variant="contained"
                      color="primary"
                      component="label"
                      style={{
                        marginBottom: "16px",
                        marginTop: "8px",
                        minWidth: "200px",
                      }}
                      startIcon={<CloudUpload />}
                    >
                      Upload File
                      <input
                        type="file"
                        hidden
                        multiple
                        onChange={handleEnoteFileUpload}
                      />
                    </Button>
                    <span style={{ color: "#f44336", fontSize: "12px" }}>
                      {errors["files"] ? "Upload Files" : ""}
                    </span>
                  </Grid>
                  {enoteFiles &&
                    Array.from(enoteFiles).map((data: any, index: number) => {
                      return (
                        <div key={index}>
                          <Chip
                            label={data.name}
                            onDelete={() => handleFileDelete(data)}
                            className={classes.chip}
                            size="small"
                            style={{
                              marginTop: "-8px",
                              marginBottom: "24px",
                              marginLeft: "16px",
                            }}
                          />
                        </div>
                      );
                    })}
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <InputLabel>
                  <Checkbox
                    checked={isOwnDocument}
                    onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                  Create your own document{" "}
                </InputLabel>

                {isOwnDocument && (
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "24px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <BraftEditor
                      language="en"
                      value={editorState}
                      onChange={(data) => setEditorState(data)}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper style={tableStyle}>
            <Typography variant="h6" style={{ marginBottom: "12px" }}>
              Publish
            </Typography>

            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <CustomizedSelect
                  label="Lesson Plan"
                  options={SELECT_LESSON_PLAN_CHOICES}
                  value={lessonPlanChoice}
                  setValue={setLessonPlanChoice}
                  name="lesson_plan"
                  disabled={lessonPlanDisabler}
                  error={
                    errors["lesson_plan"] ? "Lesson plan must be selected" : ""
                  }
                  inputRef={register({ required: false })}
                />
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <CustomizedSelect
                  label="Category"
                  options={SELECT_ENOTES_CATEGORIES_CHOICES}
                  value={enoteCategoryChoice}
                  setValue={setEnoteCategoryChoice}
                  name="category"
                  error={errors["category"] ? "Category must be selected" : ""}
                  required
                  inputRef={register({ required: true })}
                />
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.formWrapper}
                style={{ marginTop: "16px" }}
              >
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormSaveButton />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};
// ---------------- <END> Department Form Component ------------------//

export default TeacherEnoteForm;
