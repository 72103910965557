export const GET_STUDENT_EXAM_SCHEDULE_LOADING =
  "GET_STUDENT_EXAM_SCHEDULE_LOADING";
export const GET_STUDENT_EXAM_SCHEDULE_ERROR =
  "GET_STUDENT_EXAM_SCHEDULE_ERROR";
export const GET_STUDENT_EXAM_SCHEDULE_SUCCESS =
  "GET_STUDENT_EXAM_SCHEDULE_SUCCESS";

export interface StudentExamScheduleI {
  id: number;
  exam_info: number;
  exam_type: string;
  exam_date: string;
  grade_name: string;
  section_name: string;
  subject_name: string;
  start_time: string;
  end_time: string;
  full_marks: number;
  pass_marks: number;
}

//===============================<START>GET STUDENT EXAM SCHEDULE<START>======================//

export interface GetStudentExamScheduleLoading {
  type: typeof GET_STUDENT_EXAM_SCHEDULE_LOADING;
}

export interface GetStudentExamScheduleError {
  type: typeof GET_STUDENT_EXAM_SCHEDULE_ERROR;
}

export interface GetStudentExamScheduleSuccess {
  type: typeof GET_STUDENT_EXAM_SCHEDULE_SUCCESS;
  payload: StudentExamScheduleI[];
}

//===============================<END>GET STUDENT EXAM SCHEDULE<END>======================//

export type StudentExamScheduleDispatchTypes =
  | GetStudentExamScheduleLoading
  | GetStudentExamScheduleError
  | GetStudentExamScheduleSuccess;
