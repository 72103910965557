import {
    Button,
    ButtonGroup,
    Grid,
    InputLabel,
    TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import { useEffect, useState } from "react";
import CustomizedSelect from "../../../../../components/Reusable/Inputs/Select";
import { CUR_NEPALI_DATE } from "../../../../../components/utils/nepaliDateUtils";
import Khalti from "../../../../../components/DigitalPayment/Khalti/Khalti";
import { useDispatch, useSelector } from 'react-redux';
import { KhaltiGuardianPay } from "../../../../../actions/Fees/DigitalPayment/Khalti/KhaltiAction";
import PaymentDialog from "./paymentDialog";
import { inputLabel } from "../../../../../components/Styles/ReusableStyles";
import { useStyles } from "../../../../../components/Styles/FormStyles";
import { SET_SNACKBAR } from "../../../../../actions/SnackbarActionTypes";
import { digitalPaymentOptions } from "../../../../../common/json.constant";


const useComponentStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            paddingTop: "0",
            paddingBottom: "8px",
            backgroundColor: theme.palette.background.paper,
        },
        title: {
            paddingTop: "12px",
            paddingBottom: "12px",
        },
        titleText: {
            fontSize: "16px",
            fontWeight: 600,
            display: "block",
            marginBottom: "-1px",
            color: "rgba(0,0,0,0.85)",
        },
        amountText: {
            fontSize: "16px",
            fontWeight: 600,
            display: "block",
            marginBottom: "-1px",
            color: "#132e98"
        },
        titleDate: {
            fontSize: "13px",
            fontWeight: 400,
            color: "#1d4698",
        },
        itemTitle: {
            fontWeight: 500,
            marginBottom: 5,
            display: "inline-block",
        },
        alertTitle: {
            fontSize: 14,
            marginBottom: 0,
            fontWeight: 600,
        },
        btnGrp: {
            borderColor: "transparent",
            "& > button": {
                textTransform: "capitalize",
                borderColor: "transparent",
                borderRadius: "4px!important",
                borderRight: "none!important",
            },
        },
        alertRoot: {
            width: "100%",
            color: "rgba(0,0,0,.8)",
            borderRadius: 8,
            borderLeft: "8px solid #3fc581",
            background: "rgb(72 197 129 / 10%)",
            "& > div": {
                width: "100%",
                padding: "4px 0",
            },
        },
        alertTime: {
            fontWeight: 600,
            fontSize: 13,
            color: "#1d4698",
            opacity: "0.9",
        },
        alertStatus: {
            display: "flex",
            alignItems: "center",
            gap: "3px",
            textDecoration: "none",
            color: "#1d4698",
        },
        alertDanger: {
            borderLeft: "8px solid #e96c5a",
            background: "rgb(233 108 90 / 0.1)",
        },
        alertWarning: {
            borderLeft: "8px solid green",
            background: "rgb(242 232 54 / 40%)",
        },
    })
);

export default function GuardianPayFee(props: any) {
    const { selected, setSelected, statementTableData, student } = props;
    const classes = useComponentStyles();
    const formStyles = useStyles()
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [remarks, setRemarks] = useState<string>("Paying My Children Fee");

    // const [paymentMethod, setPaymentMethod] = useState<any | null>([
    //     {
    //         key: "0460d7b3-4dc5-4ed1-b9d2-e2ecba9679c5",
    //         value: "Khalti",
    //     }
    // ]);
    const [payment, setPayment] = useState<any>(null)
    const [totalAmount, setTotalAmount] = useState<number>(0)
    const [currentDate, setCurrentDate] = useState(CUR_NEPALI_DATE);

    const dispatch = useDispatch()

    // useEffect(() => {
    //     const temp = postData
    //     const temp__ = {
    //         ...temp,
    //         student: student?.academic_info?.[0]?.id
    //     }
    //     setPostData(temp__)
    // }, [student])
    const handleModalClose = (value: boolean) => {
        setModalOpen(value);
    };

    const handleModalOpen = () => {
        console.log({ payment })
        if (payment === null) {
            dispatch({
                type: SET_SNACKBAR,
                payload: {
                    snackbarOpen: true,
                    snackbarType: "error",
                    snackbarMessage: "Please select payment method",
                },
            });
        } else if (selected?.length === 0) {
            dispatch({
                type: SET_SNACKBAR,
                payload: {
                    snackbarOpen: true,
                    snackbarType: "error",
                    snackbarMessage: "Please select at least one fee head",
                },
            });
        }
        else {
            setModalOpen(true);

        }
    };

    const onModelConfirmed = (confirmed: boolean) => {
        // dispatch(DisableStudent(itemId, { remarks }));
        setRemarks("Paying My Children Fee");
        setModalOpen(false);
    };

    const handleReset = () => {
        setRemarks("Paying My Children Fee");
        setSelected([])
    }


    useEffect(() => {
        if (selected !== null && statementTableData !== null) {
            let total = 0;
            const myArray = statementTableData?.filter((elem: any) => selected?.find((rm: any) => rm == elem.id))

            myArray?.map((elem: any) => {
                total += Number(elem?.balance)
            })
            setTotalAmount(total)
        }

    }, [selected, statementTableData])

    const onPayClick = (token: string) => {
        const finalValues = {
            student: student?.academic_info?.[0]?.id,
            receipt_id: "1234",
            issued_date: currentDate,
            applied_opening_balance: false,
            remarks: remarks || "None",
            payment_method: payment?.key,
            invoices: selected,
            miscellaneous_invoices: [],
            dues: [],
            khalti_token: token,
            khalti_amount: totalAmount * 100
        }
        console.log(finalValues)
        dispatch(KhaltiGuardianPay(finalValues))
    }


    return (
        <>
            <List className={clsx(
                classes.alertRoot,
                classes.alertWarning
            )}>
                <ListItem dense className={classes.title}>
                    <ListItemText
                        primary={
                            <div>
                                <span className={classes.titleText}>Your have selected {selected?.length || 0} fees head with Total Amount Rs. {totalAmount}</span>
                                <span className={classes.titleDate}>{currentDate}</span>
                                <CustomizedSelect
                                    placeholder="-- Payment Method --"
                                    style={{ width: '250px' }}
                                    name="gender"
                                    options={digitalPaymentOptions || []}
                                    value={payment}
                                    setValue={
                                        (value: any) => setPayment(value)}
                                />
                            </div>
                        }
                    />

                    <ListItemSecondaryAction>
                        <ButtonGroup
                            variant="contained"
                            color="default"
                            aria-label="contained primary button group"
                            size="small"
                            className={classes.btnGrp}
                            disableElevation
                        >
                            <Button style={{ marginRight: '10px' }} onClick={() => handleReset()}>Reset</Button>
                            <Button style={{ margin: "0px 6px" }} color="primary" onClick={() => handleModalOpen()}>
                                Pay Rs. {totalAmount}
                            </Button>
                            {/* <Khalti amount={totalAmount * 100} title={`Pay Rs. ${totalAmount}`} onPaymentConfirm={(token: string) => onPayClick(token)} postData={postData} /> */}
                        </ButtonGroup>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            <PaymentDialog
                open={modalOpen}
                onClose={handleModalClose}
                deleteConfirmed={onModelConfirmed}
                amount={totalAmount}
                onPayClick={onPayClick}
                payment={payment}
            >
                <Grid container justifyContent="center">
                    <Grid className={formStyles.formWrapper} style={{ marginBottom: "2rem", padding: '1rem' }}>
                        <InputLabel>Remarks</InputLabel>
                        <TextField
                            variant="outlined"
                            multiline
                            rows={3}
                            name="remarks"
                            placeholder="Remarks"
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </PaymentDialog>
        </>
    );
}