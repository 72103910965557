import React, { useEffect, useState } from 'react';
import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { InputBox } from '../../../../components/Reusable/Inputs/Select';
import { MONTH_CHOICES, Tupple } from '../../../../common/json.constant';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import { GetDates } from '../../../../actions/Dates/DatesAction';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../../../components/utils/dateConverter';
import { MyAttendances } from '../../../../actions/StudentDash/MyAttendance/MyAttendanceAction';
import { Autocomplete } from '@material-ui/lab';
import { filterStyle } from '../../StudentDashboardStyles';

const SearchAttendace = (props: any) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const [month, setMonth] = useState<Tupple | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number | null>(null);

  const dateSelector = useSelector(
    (state: RootStore) => state.month_dates.data
  );

  useEffect(() => {
    const utc = new Date().toJSON().slice(0, 10);
    setCurrentMonth(parseInt(dateConverterAdToBs(utc).substring(5, 7)) - 1);
    dispatch(GetDates(parseInt(dateConverterAdToBs(utc).substring(5, 7))));
  }, []);

  useEffect(() => {
    if (currentMonth != null) {
      dispatch(GetDates(currentMonth + 1));
      setMonth(MONTH_CHOICES[currentMonth]);
    }
  }, [currentMonth]);

  useEffect(() => {
    if (dateSelector.dates != null) {
      if (dateSelector.dates != null) {
        const searchData = {
          from_date: dateConverterBsToAd(dateSelector.dates[0]),
          to_date: dateConverterBsToAd(
            dateSelector.dates[dateSelector.dates.length - 1]
          ),
        };
        dispatch(MyAttendances(searchData));
      }
    }
  }, [dateSelector.dates]);

  useEffect(() => {
    props.setMonthName(month?.value);
  }, [month]);

  const handleMonthChange = (value: Tupple) => {
    if (value != null) {
      setMonth(value);
      dispatch(GetDates(value.key));
    } else {
      setMonth(null);
    }
  };

  return (
    <>
      <Paper className={classes.pageAttachContent} style={filterStyle}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6} className={classes.formTitle}>
            <Typography variant="h6">My Attendance</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4} className={classes.formWrapperRow}>
            <InputBox width="130px">Choose Month</InputBox>
            <Autocomplete
              classes={{
                input: classes.smallfont,
                option: classes.smallfont,
              }}
              fullWidth
              onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                handleMonthChange(value)
              }
              value={month}
              options={MONTH_CHOICES}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="month_name"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default SearchAttendace;
