import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useForm } from "react-hook-form";
import { filterStyle } from "../../../StudentDashboard/StudentDashboardStyles";
import { useFormStyles } from "../../../../components/Styles/FormStyles";
import { InputBox } from "../../../../components/Reusable/Inputs/Select";
import { TeachingClassTypeI } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";
import { SectionTypeI } from "../../../../actions/Academics/Section/SectionActionTypes";
import { SectionI } from '../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes'
import { SubmitButton, ResetButton } from "../../../../components/Reusable/Buttons/SearchButton";
import { GetTeachingClass } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassAction";
import { RootStore } from "../../../../store";
import { StudentI } from "../../../../actions/TeacherDash/TeacherRubric/TeacherRubricActionTypes"
import { TeacherSubjectByClass } from "../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes"
import { T_ExamMarksI, T_ExamI, T_ExamSubjectsI } from "../../../../actions/TeacherDash/AddMarks/AddMarksActionTypes"
import { GetTeacherExams, GetTeacherExamSubjects, GetTeacherExamMarks, Reload } from "../../../../actions/TeacherDash/AddMarks/AddMarksAction"

interface AddMarksSearchProps {
  stateProps: {
    selectedClass: TeachingClassTypeI | null,
    setSelectedClass: React.Dispatch<React.SetStateAction<TeachingClassTypeI | null>>,
    selectedSection: SectionTypeI | null,
    setSelectedSection: React.Dispatch<React.SetStateAction<SectionTypeI | null>>,
    selectedExamType: T_ExamI | null;
    setSelectedExamType: React.Dispatch<React.SetStateAction<T_ExamI | null>>,
    selectedSubject: T_ExamSubjectsI | null;
    setSelectedSubject: React.Dispatch<React.SetStateAction<T_ExamSubjectsI | null>>
  }
}

const AddMarksSearch = ({ stateProps: { selectedClass, setSelectedClass, selectedSection, setSelectedSection, selectedExamType, setSelectedExamType, selectedSubject, setSelectedSubject } }: AddMarksSearchProps) => {
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { register, handleSubmit } = useForm();

  // State Declaration
  const [examTypeChoices, setExamTypeChoices] = useState<T_ExamI[]>([]);
  const [classChoices, setClassChoices] = useState<TeachingClassTypeI[]>([])
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [subjectChoices, setSubjectChoices] = useState<T_ExamSubjectsI[]>([]);
  const [subjectDisabler, setSubjectDisabler] = useState<boolean>(true);

  // UseSelector
  const teacherMarksSelector = useSelector((state: RootStore) => state.teacher_add_marks);
  const classSelector = useSelector((state: RootStore) => state.teaching_class);

  // UseEffect
  useEffect(() => {
    dispatch(GetTeacherExams());
    dispatch(GetTeachingClass());
    dispatch(Reload())
  }, []);

  useEffect(() => {
    setExamTypeChoices(teacherMarksSelector.exams);
    setSubjectChoices(teacherMarksSelector.subjects)
  }, [teacherMarksSelector]);

  useEffect(() => {
    classSelector.teaching_class && setClassChoices(classSelector.teaching_class);
  }, [classSelector]);


  // Functions
  const handleExamTypeChange = (value: T_ExamI | null) => {
    if (value === null) return;
    dispatch(Reload())
    setSelectedExamType(value)
    setSelectedClass(null)
    setSelectedSection(null)
    setSelectedSubject(null)
  };

  const handleClassChange = (value: TeachingClassTypeI | null) => {
    dispatch(Reload())
    setSelectedClass(value);
    setSelectedSection(null);
    setSelectedSubject(null)
    if (value) {
      populateSectionChoices(value.section);
    } else {
      setSelectedSection(null);
      setSectionDisabler(true);
    }
  };

  const populateSectionChoices = (sections: SectionI[]) => {
    setSectionDisabler(false);
    setSectionChoices(sections);
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    if (!selectedExamType || !selectedClass) return;
    dispatch(Reload())
    const reqParams = {
      exam_info: selectedExamType.id,
      grade: selectedClass.grade
    }
    selectedClass && dispatch(GetTeacherExamSubjects(reqParams))
    setSelectedSection(value);
    setSubjectDisabler(false)
  };

  const handleSubjectChange = (value: T_ExamSubjectsI | null) => {
    if (value === null) return;
    setSelectedSubject(value)
  }

  const onSearchClick = () => {
    apiCall()
  };

  const apiCall = () => {
    if (!selectedClass || !selectedSubject || !selectedExamType) return;
    const requestParams = {
      grade: selectedClass.grade,
      exam_subject: selectedSubject.exam_subject_info.id,
      exam: selectedExamType.id,
    }
    dispatch(GetTeacherExamMarks(requestParams))
  }

  return (
    <Paper style={filterStyle}>
      <Box className={classes.formTitle}>
        <span>Search Class</span>
      </Box>
      <form className={classes.form} onSubmit={handleSubmit(onSearchClick)}>
        <Grid container>
          <Grid item md={3} className={classes.formWrapperRow}>
            <InputBox>Exam</InputBox>
            <Autocomplete
              classes={{
                input: classes.smallfont,
                option: classes.smallfont,
              }}
              fullWidth
              onChange={(
                event: React.ChangeEvent<{}>,
                value: T_ExamI | null
              ) => handleExamTypeChange(value)}
              options={examTypeChoices}
              value={selectedExamType}
              getOptionLabel={(option) => option.exam_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  variant="outlined"
                  name="exam_type"
                  inputRef={register({ required: true })}
                />
              )}
            />
            <span className={classes.validationErrorInfo} />
          </Grid>
          <Grid item md={2} className={classes.formWrapperRow}>
            <InputBox>Class</InputBox>
            <Autocomplete
              classes={{
                input: classes.smallfont,
                option: classes.smallfont,
              }}
              fullWidth
              onChange={(
                event: React.ChangeEvent<{}>,
                value: TeachingClassTypeI | null
              ) => handleClassChange(value)}
              options={classChoices}
              value={selectedClass}
              getOptionLabel={(option) => option.grade_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  variant="outlined"
                  name="grade"
                  inputRef={register({ required: true })}
                />
              )}
            />
            <span className={classes.validationErrorInfo} />
          </Grid>
          <Grid item md={2} className={classes.formWrapperRow}>
            <InputBox>Section</InputBox>
            <Autocomplete
              classes={{
                input: classes.smallfont,
                option: classes.smallfont,
              }}
              fullWidth
              value={selectedSection}
              onChange={(
                event: React.ChangeEvent<{}>,
                value: SectionTypeI | null
              ) => handleSectionChange(value)}
              options={sectionChoices}
              getOptionLabel={(option) => option.name}
              disabled={sectionDisabler}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  variant="outlined"
                  name="section"
                  inputRef={register}
                />
              )}
            />
            <span className={classes.validationErrorInfo} />
          </Grid>
          <Grid
            item
            style={{ maxWidth: "20%" }}
            md={3}
            className={classes.formWrapperRow}
          >
            <InputBox>Subject</InputBox>
            <Autocomplete
              classes={{
                input: classes.smallfont,
                option: classes.smallfont,
              }}
              fullWidth
              value={selectedSubject}
              onChange={(
                event: React.ChangeEvent<{}>,
                value: T_ExamSubjectsI | null
              ) => handleSubjectChange(value)}
              options={subjectChoices}
              getOptionLabel={(option) =>
                `${option.name}`
              }
              disabled={subjectDisabler}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  variant="outlined"
                  name="subject"
                  inputRef={register({ required: true })}
                />
              )}
            />
            <span className={classes.validationErrorInfo} />
          </Grid>
          <SubmitButton style={{ marginTop: "5px" }} />
        </Grid>
      </form>
    </Paper>
  )
}

export default AddMarksSearch