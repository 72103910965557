import { Box } from "@material-ui/core";
import React, { useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import AddMarksSearch from "./AddMarksSearch";
import AddMarksTable from "./AddMarksTable";

interface SearchParamI {
  exam_info: string;
  exam_subject: string;
  subject: string;
  grade: string;
  grade_id: string;
  section: string;
  section_id: string;
}

const ExamMarksPage = () => {
  const [tableTitle, setTableTitle] = useState<SearchParamI | null>(null);

  const handleSearch = (data: SearchParamI) => {
    setTableTitle(data);
  };
  return (
    <>
      <Box>
        <PageHeader
          title="Exam Marks"
          module={{ name: "Academics", to: "/exam-marks" }}
        />
        <PageWrapper>
          <AddMarksSearch onSearch={handleSearch} />
          <AddMarksTable tableTitle={tableTitle} />
        </PageWrapper>
      </Box>
    </>
  );
};

export default ExamMarksPage;
