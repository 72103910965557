import React from "react";
import { FullSubModuleLayout } from "../../../../../components/Reusable/Layouts/SubModuleLayout";
import ParentsDashSidebar from "../../../../../components/ParentsDash/ParentsDashSidebar";
import ExamReportSearch from "./ExamReportSearch";
import ExamReportTable from "./ExamReportTable";
import { Box } from "@material-ui/core";
import PageWrapper from "../../../components/PageWrapper";
import PageHeader from "../../../components/Header/PageHeader";

const ExamReportPage = () => {
  const nav = <ParentsDashSidebar AcademicReport />;
  return (
    <Box>
      <PageHeader title="Exam Report" />

      <PageWrapper>
        <ExamReportSearch />
        <ExamReportTable />
      </PageWrapper>
    </Box>
  );
};

export default ExamReportPage;
