import { Box } from "@material-ui/core";
import SearchLessonPlan from "./SearchLessonPlan";
import StudentLessonPlanTable from "./StudentLessonPlanTable";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import { crumbs } from "../MyTeacher/MyTeacherPage";

const MyLessonPlanPage = () => {
  return (
    <Box>
      <PageHeader title="Lesson Plan" module={crumbs} />
      <PageWrapper>
        <SearchLessonPlan />
        <StudentLessonPlanTable />
      </PageWrapper>
    </Box>
  );
};
export default MyLessonPlanPage;
