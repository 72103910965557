import { Box } from "@material-ui/core";
import React from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import TeacherEnoteForm from "./components/EnoteForm";

const TeacherEnoteCreatePage = () => {
  return (
    <>
      <Box>
        <PageHeader title="Add Enote" />
        <PageWrapper>
          <TeacherEnoteForm />
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherEnoteCreatePage;
