export const GET_PARENT_MEETING_LOADING = 'GET_PARENT_MEETING_LOADING';
export const GET_PARENT_MEETING_ERROR = 'GET_PARENT_MEETING_ERROR';
export const GET_PARENT_MEETING_SUCCESS = 'GET_PARENT_MEETING_SUCCESS';

export const ADD_PARENT_MEETING_LOADING = 'ADD_PARENT_MEETING_LOADING';
export const ADD_PARENT_MEETING_ERROR = 'ADD_PARENT_MEETING_ERROR';
export const ADD_PARENT_MEETING_SUCCESS = 'ADD_PARENT_MEETING_SUCCESS';

export const UPDATE_PARENT_MEETING_LOADING = 'UPDATE_PARENT_MEETING_LOADING';
export const UPDATE_PARENT_MEETING_ERROR = 'UPDATE_PARENT_MEETING_ERROR';
export const UPDATE_PARENT_MEETING_SUCCESS = 'UPDATE_PARENT_MEETING_SUCCESS';

export const DELETE_PARENT_MEETING_LOADING = 'DELETE_PARENT_MEETING_LOADING';
export const DELETE_PARENT_MEETING_ERROR = 'DELETE_PARENT_MEETING_ERROR';
export const DELETE_PARENT_MEETING_SUCCESS = 'DELETE_PARENT_MEETING_SUCCESS';

export interface ParentMeetingI {
  id: string;
  meeting_type: {
    id: string;
    name: string;
  };
  meet_date: string;
  meet_time: string;
  status: string;
  status_name: string;
  description: string;
  created_on: string;
}

export interface ParentMeetingII {
  id: string;
  meeting_type_id: string;
  meeting_type: string;
  meeting_date: string;
  meeting_time: string;
  applied_date: string;
  description: string;
  status: string;
}

//===============================<START>GET PARENT_MEETING<START>======================//

export interface GetMeetingLoading {
  type: typeof GET_PARENT_MEETING_LOADING;
}

export interface GetMeetingError {
  type: typeof GET_PARENT_MEETING_ERROR;
}

export interface GetMeetingSuccess {
  type: typeof GET_PARENT_MEETING_SUCCESS;
  payload: ParentMeetingI[];
}

//===============================<END>GET PARENT_MEETING<END>======================//

//===============================<START>ADD PARENT_MEETING<START>======================//

export interface AddMeetingLoading {
  type: typeof ADD_PARENT_MEETING_LOADING;
}

export interface AddMeetingError {
  type: typeof ADD_PARENT_MEETING_ERROR;
}

export interface AddMeetingSuccess {
  type: typeof ADD_PARENT_MEETING_SUCCESS;
  payload: ParentMeetingI;
}

//===============================<END>ADD PARENT_MEETING<END>======================//

//===============================<START>UPDATE PARENT_MEETING<START>======================//

export interface UpdateMeetingLoading {
  type: typeof UPDATE_PARENT_MEETING_LOADING;
}

export interface UpdateMeetingError {
  type: typeof UPDATE_PARENT_MEETING_ERROR;
}

export interface UpdateMeetingSuccess {
  type: typeof UPDATE_PARENT_MEETING_SUCCESS;
  payload: ParentMeetingI;
}

//===============================<END>UPDATE PARENT_MEETING<END>======================//

//===============================<START>DELETE PARENT_MEETING<START>======================//

export interface DeleteMeetingLoading {
  type: typeof DELETE_PARENT_MEETING_LOADING;
}

export interface DeleteMeetingError {
  type: typeof DELETE_PARENT_MEETING_ERROR;
}

export interface DeleteMeetingSuccess {
  type: typeof DELETE_PARENT_MEETING_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE PARENT_MEETING<END>======================//

export type MeetingDispatchTypes =
  | GetMeetingLoading
  | GetMeetingError
  | GetMeetingSuccess
  | AddMeetingLoading
  | AddMeetingError
  | AddMeetingSuccess
  | UpdateMeetingLoading
  | UpdateMeetingError
  | UpdateMeetingSuccess
  | DeleteMeetingLoading
  | DeleteMeetingError
  | DeleteMeetingSuccess;
