import {
  ListSubheader,
  Box,
  Typography,
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import {
  StarBorder,
  ExpandLess,
  ExpandMore,
  Dashboard,
  Note,
  ExitToApp,
  EventNote,
} from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { BiBookAdd, BiCommentError, BiBook } from 'react-icons/bi';
import { BsCardChecklist, BsPencilSquare } from 'react-icons/bs';
import {
  FaBookReader,
  FaChalkboardTeacher,
  FaClipboardList,
} from 'react-icons/fa';
import { GiHand, GiTeacher, GiSchoolBag } from 'react-icons/gi';
import { GoTasklist } from 'react-icons/go';
import { HiAcademicCap, HiSpeakerphone } from 'react-icons/hi';
import { IoMdHand } from 'react-icons/io';
import { MdEventNote, MdAssignment } from 'react-icons/md';
import { useLocation, Link } from 'react-router-dom';
import { useDashboardStyles } from '../../../../components/Styles/DashboardStyles';
import checkBrands from '../../../../config/checkBrands';

export const teacherDataSet = [
  {
    text: 'Dashboard',
    url: '',
    icon: Dashboard,
    activeStatus: location.pathname === '/',
  },
  {
    text: 'Academics',
    url: '',
    icon: HiAcademicCap,
    dataSet: [
      {
        text: 'My Subjects',
        url: '/my-subjects',
        icon: FaBookReader,
        activeStatus: location.pathname === '/my-subjects',
      },
      {
        text: 'Student Attendances',
        url: '/student-attendance',
        icon: IoMdHand,
        activeStatus: location.pathname === '/student-attendance',
      },
      {
        text: 'My TimeTable',
        url: '/timetable',
        icon: MdEventNote,
        activeStatus: location.pathname === '/timetable',
      },
      {
        text: 'Exam Schedule',
        url: '/exam-schedules',
        icon: FaClipboardList,
        activeStatus: location.pathname === '/exam-schedules',
      },
      {
        text: 'Exam Marks',
        url: '/exam-marks',
        icon: BsPencilSquare,
        activeStatus: location.pathname === '/exam-marks',
      },
      {
        text: 'Exam APEs',
        url: '/exam-ape-factor',
        icon: BsPencilSquare,
        activeStatus: location.pathname === '/exam-ape-factor',
      },
      {
        text: 'Feedback',
        url: '/complaint',
        icon: BiCommentError,
        activeStatus: location.pathname === '/complaint',
      },
      {
        text: 'Add Marks',
        url: '/add-marks',
        icon: BiBook,
        activeStatus: location.pathname === '/add-marks',
      },
      {
        text: 'My Syllabus',
        url: '/syllabus',
        icon: BsCardChecklist,
        activeStatus: location.pathname === '/syllabus',
      },
      {
        text: 'Lesson Plan',
        url: '/lesson-plan',
        icon: GoTasklist,
        activeStatus: location.pathname === '/lesson-plan',
      },
      {
        text: 'Online Classes',
        url: '/online-classes',
        icon: GiTeacher,
        activeStatus: location.pathname === '/online-classes',
      },
      {
        text: 'Rubric',
        url: '/rubric',
        icon: GiSchoolBag,
        activeStatus: location.pathname === '/rubric',
      }
    ],
  },
  {
    text: 'Assignment',
    url: '/assignment',
    icon: MdAssignment,
    activeStatus: location.pathname.includes('/assignment'),
  },
  {
    text: 'My Attendance',
    url: '/checkin-checkout',
    icon: ExitToApp,
    dataSet: [
      {
        text: 'Checkin/Checkout',
        url: '/checkin-checkout',
        icon: ExitToApp,
        activeStatus: location.pathname === '/checkin-checkout',
      },
      {
        text: 'Attendance Report',
        url: '/my-attendance-report',
        icon: BiBookAdd,
        activeStatus: location.pathname === '/my-attendance-report',
      },
    ],
  },
  {
    text: 'Enotes',
    url: '/enote',
    icon: Note,
    activeStatus:
      location.pathname === '/enote' || location.pathname === '/enote-add',
  },
  {
    text: 'Leave',
    url: '/leave',
    icon: ExitToApp,
    activeStatus: location.pathname === '/leave',
  },
  {
    text: 'Meeting Request',
    url: '/meeting',
    icon: ExitToApp,
    activeStatus: location.pathname === '/meeting',
  },
  {
    text: 'Announcement',
    url: '/announcement',
    icon: HiSpeakerphone,
    activeStatus: location.pathname === '/announcement',
  },
  {
    text: 'Events',
    url: '',
    icon: EventNote,
    dataSet: [
      {
        text: 'Event List',
        url: 'events',
        icon: EventNote,
        activeStatus: location.pathname === '/events',
      },
      {
        text: 'ECA Events',
        url: 'eca-events',
        icon: EventNote,
        activeStatus: location.pathname === '/eca-events',
      },
    ],
  },
  {
    text: 'Reports',
    url: '',
    icon: EventNote,
    dataSet: [
      {
        text: 'Class Attendance',
        url: '/attendance-report',
        icon: GiHand,
        activeStatus: location.pathname === '/attendance-report',
      },
    ],
  },
];

const TeacherSidebar = () => {
  const classes = useDashboardStyles();
  const [navItems, setNavItems] = useState([]);
  const location = useLocation();

  console.log('navItems', navItems);
  useEffect(() => {
    const getActiveStatus = (pathname: string) => {
      const newPathname = '/' + pathname.replace('/', '');

      if (newPathname === '/') return location.pathname === newPathname;
      return location.pathname.includes(newPathname);
    };

    // Refresh nav links on page change
    const dataSet: any = teacherDataSet.map((navItem) => {
      if (navItem.dataSet) {
        const tempNavItem = navItem.dataSet.map((nestedNavItem) => {
          return {
            ...nestedNavItem,
            activeStatus: getActiveStatus(nestedNavItem.url),
          };
        });
        return { ...navItem, dataSet: tempNavItem };
      }

      return {
        ...navItem,
        activeStatus: getActiveStatus(navItem.url),
      };
    });
    setNavItems(dataSet);
  }, [location.pathname]);

  const NestedList = (props: any) => {
    const { item, index } = props;
    const classes = useDashboardStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    const isSelected = item.dataSet
      .map((d: any) => d.activeStatus)
      .includes(true);

    useEffect(() => {
      setOpen(isSelected);
    }, [item.dataSet]);

    return (
      <>
        <ListItem
          button
          key={index}
          onClick={handleClick}
          className={classes.listItem}
          selected={isSelected}
        >
          <Icon icon={item.icon} />
          <ListItemText primary={item.text} className={classes.listItemText} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.dataSet &&
              item.dataSet.length > 0 &&
              item.dataSet.map((item: any, index: number) => (
                <ListItem
                  key={index}
                  button
                  selected={item.activeStatus}
                  className={clsx(classes.nested)}
                  to={item.url}
                  component={Link}
                >
                  <Icon icon={item.icon} />
                  <ListItemText
                    primary={item.text}
                    className={classes.listItemText}
                  />
                </ListItem>
              ))}
          </List>
        </Collapse>
      </>
    );
  };

  const SimpleList = (props: any) => {
    const { item, index } = props;
    return (
      <ListItem
        button
        to={item.url}
        component={Link}
        key={index}
        selected={item.activeStatus}
        className={classes.listItem}
      >
        <Icon icon={item.icon} />
        <ListItemText primary={item.text} className={classes.listItemText} />
      </ListItem>
    );
  };

  const Header = () => (
    <ListSubheader component="div" style={{ position: 'relative' }}>
      <Box className={classes.header}>
        <Typography variant="h6">
          <span style={{ fontWeight: 400 }}>Hamro</span>{' '}
          <span style={{ fontWeight: 600 }}>Academy</span>
        </Typography>
      </Box>
    </ListSubheader>
  );

  const Footer = () => (
    <Box className={classes.footer}>
      <Typography variant="body2" style={{ fontSize: '12px' }}>
        2021{' '}
        {checkBrands('nakshatraacademy')
          ? 'Nakshatra Academy'
          : 'Hamro Academy'}
        . <br />
        All Rights Reserved
      </Typography>
    </Box>
  );

  const Icon = (props: { icon: any }) => {
    const Icon = props.icon;
    return (
      <ListItemIcon style={{ minWidth: '35px' }}>
        {Icon ? (
          <Icon style={{ fontSize: '22px' }} />
        ) : (
          <StarBorder style={{ fontSize: '22px' }} />
        )}
      </ListItemIcon>
    );
  };

  return (
    <>
      <Box className={classes.sidenav}>
        <Box display="flex" flexDirection="column" height="100vh">
          <List component="nav" className={classes.root}>
            <Box className={classes.listContainer}>
              {navItems.map((item: any, index: number) => {
                return item.dataSet ? (
                  <NestedList key={index} item={item} />
                ) : (
                  <SimpleList key={index} item={item} />
                );
              })}
            </Box>
          </List>

          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default TeacherSidebar;
