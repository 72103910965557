import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import {
  Box,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  EventsAnnounceTypeI,
  WrapperI,
} from '../../../../components/ParentsDash/ParentsEvents/Components/ParentEventAnnounce';
import { dateConverterAdToBs } from '../../../../components/utils/dateConverter';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import { getDateOccurrence } from '../../../TeacherDashboard/pages/TeacherAnnouncement/ModalView/TeacherAnnounce';

import { tblStyle } from '../../StudentDashboardStyles';
import { fromToConverter } from '../../../../components/utils/dateTimeFormat';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

const StudentEventsNotification = () => {
  const classes = useFormStyles();
  const cls = useStyles();

  const studentEventSelector = useSelector(
    (state: RootStore) => state.student_event
  );
  const [todayEvents, setTodayEvents] = useState<EventsAnnounceTypeI[]>([]);
  const [tomorrowEvents, setTomorrowEvents] = useState<EventsAnnounceTypeI[]>(
    []
  );
  const [upcomingEvents, setUpcomingEvents] = useState<EventsAnnounceTypeI[]>(
    []
  );

  const [dataSliced, setDataSliced] = useState({
    today: false,
    tomorrow: false,
    upcoming: false,
  });

  useEffect(() => {
    const data = studentEventSelector.student_event;

    const todays: EventsAnnounceTypeI[] = [];
    const tomorrows: EventsAnnounceTypeI[] = [];
    const upcomings: EventsAnnounceTypeI[] = [];

    if (data.length) {
      data.map((element: EventsAnnounceTypeI) => {
        switch (getDateOccurrence(element.from_date)) {
          case 'today':
            todays.push(element);
            break;

          case 'tomorrow':
            tomorrows.push(element);
            break;

          case 'upcoming':
            upcomings.push(element);
            break;

          default:
            break;
        }
      });
    }
    setTodayEvents(todays);
    setTomorrowEvents(tomorrows);
    setUpcomingEvents(upcomings);
  }, [studentEventSelector]);

  //EVENT HANDLER///////////////////////////////////////////

  const changeViewState = (state: string) => {
    switch (state) {
      case 'today':
        setDataSliced({
          ...dataSliced,
          today: !dataSliced.today,
        });
        break;

      case 'tomorrow':
        setDataSliced({
          ...dataSliced,
          tomorrow: !dataSliced.tomorrow,
        });
        break;

      case 'upcoming days':
        setDataSliced({
          ...dataSliced,
          upcoming: !dataSliced.upcoming,
        });
        break;

      default:
        break;
    }
  };

  const getViewStatus = (state: string): string => {
    switch (state) {
      case 'today':
        return dataSliced.today ? 'View Less' : 'View More';

      case 'tomorrow':
        return dataSliced.tomorrow ? 'View Less' : 'View More';

      case 'upcoming days':
        return dataSliced.upcoming ? 'View Less' : 'View More';

      default:
        return '';
    }
  };

  const checkOccurrence = (card: string): boolean => {
    switch (card) {
      case 'today':
        return dataSliced.today;

      case 'tomorrow':
        return dataSliced.tomorrow;

      case 'upcoming days':
        return dataSliced.upcoming;

      default:
        return false;
    }
  };

  const EventsWrapper = (props: WrapperI) => {
    const { heading, data, occurrence } = props;
    const margin = {
      marginLeft: `5px`,
      color: !props.selected ? '#9daef1' : 'white',
    };
    const mainStyle = {
      maxHeight: '400px',
      overflow: 'auto',
    };
    return (
      <Paper
        className={classes.pageContent}
        style={{
          ...tblStyle,
          backgroundColor: props.selected ? '#132e98' : 'white',
          boxShadow: props.selected
            ? 'none'
            : 'rgb(0 0 0 / 7%) 1px 1px 20px 1px',
        }}
      >
        <Box
          className={classes.formTitle}
          style={{
            position: 'static',
            margin: 0,
            marginBottom: 4,
            fontSize: '18px',
            color: 'white',
            fontWeight: 600,
          }}
        >
          <span style={{ color: props.selected ? 'white' : '#132e98' }}>
            {' '}
            {heading}{' '}
          </span>
        </Box>
        <Box style={mainStyle}>
          {data.length ? (
            data
              .slice(0, checkOccurrence(occurrence) ? data.length : 3)
              .map((element: EventsAnnounceTypeI) => (
                <>
                  <Chip
                    color="primary"
                    label={fromToConverter(element.from_date, element.to_date)}
                    size="small"
                    style={{ fontSize: '11px' }}
                  />
                  <Grid>
                    <Typography
                      gutterBottom
                      variant="caption"
                      style={{
                        fontWeight: 500,
                        color: props.selected ? 'white' : '#132e98',
                      }}
                    >
                      Title:
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="caption"
                      style={{ ...margin }}
                    >
                      {element.title}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      gutterBottom
                      variant="caption"
                      style={{
                        fontWeight: 500,
                        color: props.selected ? 'white' : '#132e98',
                      }}
                    >
                      Description:
                    </Typography>
                    <Typography gutterBottom variant="caption" style={margin}>
                      {element.description}
                    </Typography>
                  </Grid>
                  <Divider
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />
                </>
              ))
          ) : (
            <Typography
              gutterBottom
              variant="caption"
              style={{
                margin: 0,
                fontSize: '13px',
                color: props.selected ? 'white' : '#132e98',
                fontWeight: 500,
              }}
            >
              No events for {occurrence}
            </Typography>
          )}
        </Box>
        {data.length > 3 && (
          <Grid container justifyContent="center">
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => changeViewState(occurrence)}
            >
              {getViewStatus(occurrence)}
            </a>
          </Grid>
        )}
      </Paper>
    );
  };

  return (
    <>
      <EventsWrapper
        data={todayEvents}
        heading="Today's Events"
        selected
        occurrence="today"
      />
      <EventsWrapper
        data={tomorrowEvents}
        heading="Tomorrow's Events"
        occurrence="tomorrow"
      />
      <EventsWrapper
        data={upcomingEvents}
        heading="Upcoming Events"
        occurrence="upcoming days"
      />
    </>
  );
};

export default StudentEventsNotification;
