import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import PageHeader from "../../../components/Header/PageHeader";
import PageWrapper from "../../../components/PageWrapper";
import SingleSubjectTable from "./SingleSubjectTable"
import SingleSubjectSearch from "./SingleSubjectSearch"
import { TeacherClassSubjectsI } from '../../../../../rtk/features/timetable/timetable'
import NewTabs from "./NewTabs"

const SingleSubjectPage = () => {
    const location = useLocation<{ detail: TeacherClassSubjectsI }>();
    const [data, setData] = useState<TeacherClassSubjectsI>();

    useEffect(() => {
        setData(location.state.detail)
    }, [location]);
    return (
        <>
            <Box>
                <PageHeader
                    title="Assignment Details"
                />
                <PageWrapper>
                    <NewTabs data={data} />
                </PageWrapper>
            </Box>
        </>
    )
}

export default SingleSubjectPage