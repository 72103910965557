//---------------------------<START> import modules starts-----------------------//
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Chip,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Switch,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import CustomizedTextField from '../../../../../components/Reusable/Inputs/TextField';
import { FormSaveButton } from '../../../../../components/Reusable/Buttons/FormButton';
import { Tupple } from '../../../../../actions';
import CustomizedNepaliDatePicker from '../../../../../components/Reusable/Inputs/NepaliDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import { GetTeacherSubject } from '../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectAction';
import { TeacherSubjectsI } from '../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../../../../components/utils/dateConverter';
import {
  AddLiveClass,
  UpdateLiveClass,
} from '../../../../../actions/TeacherDash/TeacherLiveClass/TeacherLiveAction';
import { setSnackbar } from '../../../../../actions/SnackbarAction';
import firstWordCapital from '../../../../../components/utils/firstWordCapital';
import { CUR_NEPALI_DATE } from '../../../../../components/utils/nepaliDateUtils';
import { FormCheckBox } from '../../../../../components/Reusable/Inputs/Checkbox';
import { CheckOutlined } from '@material-ui/icons';
import SelectDaysDialog from './SelectDaysClasses';
import { URL } from '../../../../../config/SERVER_API_URLS';
//-------------------------<END> import modules ends ----------------------------//

export interface DaysI {
  id: string;
  day: string;
  checked: boolean;
}

//--------------------------<START> Live classes form components starts--------------------------------------------//
const LiveClassesForm = (props: any) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { onClose, classInfo, editItem } = props;
  const { register, handleSubmit, setValue, setError, clearErrors, errors } =
    useForm<any>();

  //* ---------------------------------STATE MANAGEMENT --------------------------------*//
  const [classDate, setClassDate] = useState<string>(CUR_NEPALI_DATE);
  const [selectedSubjects, setSelectedSubjects] =
    useState<TeacherSubjectsI | null>(null);
  const [subjectChoices, setSubjectChoices] = useState<TeacherSubjectsI[]>([]);
  const [serverErrors, setServerErrors] = useState<any>(null);
  const [title, setTitle] = useState('');
  const [isDaily, setIsDaily] = useState<boolean>(false);
  const [daysDialog, setDaysDialog] = useState<boolean>(false);
  const [dailyDays, setDailyDays] = useState<DaysI[]>([]);
  const [getDays, setGetDays] = React.useState<string[]>([]);
  const [editDays, setEditDays] = useState<DaysI[]>([]);
  const [isExternalLink, setIsExternalLink] = useState(false);

  //* ---------------------------------------------REDUX MANAGEMENT --------------------------*//
  const subjectSelector = useSelector(
    (state: RootStore) => state.teacher_subject
  );

  const recentSelector = useSelector(
    (state: RootStore) => state.teacher_liveClass.recent
  );

  const errorsData = useSelector(
    (state: RootStore) => state.teacher_liveClass.errors
  );
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    recentSelector && onClose();
  }, [recentSelector]);

  useEffect(() => {
    classInfo &&
      dispatch(GetTeacherSubject(classInfo.grade, classInfo.section_id));
  }, [classInfo]);

  useEffect(() => {
    const subjects = subjectSelector.subjects;
    setSubjectChoices(subjects);
  }, [subjectSelector]);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (errors) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      // props.setSelectedRadioValue("already_added");
    }
  }, [errors]);

  //*------------------------------------FUNCTIONS -------------------------------*//

  const handleClassDate = (value: any) => {
    setClassDate(value);
    clearErrors('class_date');
  };

  const days: DaysI[] = [
    { id: '7', day: 'Sunday', checked: true },
    { id: '1', day: 'Monday', checked: true },
    { id: '2', day: 'Tuesday', checked: true },
    { id: '3', day: 'Wednesday', checked: true },
    { id: '4', day: 'Thursday', checked: true },
    { id: '5', day: 'Friday', checked: true },
  ];

  useEffect(() => {
    if (editItem) {
      editItem?.class_date &&
        setClassDate(dateConverterAdToBs(editItem.class_date));
      if (editItem.days.length) {
        setIsDaily(true);
      }
      const final = [];
      for (let i = 0; i < editItem.days.length; i++) {
        const findDays = days.find(
          (elem: DaysI) => elem.id == editItem.days[i]
        );
        if (findDays != null) {
          final.push(findDays);
        }
      }
      setDailyDays(final);
      setGetDays(final.map((elem) => elem.id));
      setEditDays(final);
      setValue('start_time', editItem.start_time);
      setValue('title', editItem?.title);
      setValue('end_time', editItem.end_time);
      setValue('meeting_link', editItem.link);
      setSelectedSubjects(
        subjectChoices.find(
          (childItem: any) => childItem.subject === editItem.subject
        ) || subjectChoices[0]
      );
    }
  }, [editItem, subjectChoices]);

  const onSubmit = (data: any) => {
    // const newTitle = data.title.replace(/\s+/g, "-");
    if (selectedSubjects && classDate && classInfo) {
      const new_data = {
        title: data.title,
        subject: selectedSubjects.subject,
        start_time: data.start_time,
        end_time: data.end_time,
        grade: classInfo.grade,
        section: classInfo.section_id,
        link: isExternalLink ? data.meeting_link : `${Date.now()}`,
        days: getDays.length ? getDays : [],
        class_date: getDays.length ? null : dateConverterBsToAd(classDate),
      };
      if (editItem) {
        dispatch(UpdateLiveClass(editItem.id, new_data));
        // onClose();
      } else {
        new_data && dispatch(AddLiveClass(new_data));
        // onClose();
      }
    } else {
      dispatch(setSnackbar(true, 'warning', 'Fields cannot be empty'));
    }
  };

  const handleChange = () => {
    clearErrors('error');
  };

  // useEffect(() => {
  //   if (editItem) {
  //     setValue("title", editItem.title);
  //   } else if (title == "" && subjectChoices.length) {
  //     setValue("title", subjectChoices[0]?.subject_name);
  //   }
  // }, [subjectChoices, editItem]);

  const applyToDays = (val: DaysI[]) => {
    setDailyDays(val);
    setDaysDialog(false);
    setGetDays(val.map((elem) => elem.id));
  };

  const handleRegularChange = () => {
    setIsDaily(!isDaily);
    setDailyDays([]);
  };

  const handleExternalLink = () => {
    setIsExternalLink(!isExternalLink);
  };

  //------------------------<END> React Hooks form ends -------------------------//
  return (
    <Paper elevation={0}>
      <div className={classes.modalContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          {serverErrors &&
            serverErrors?.error &&
            Object.keys(serverErrors.error)
              .filter((elem) => elem === 'error')
              .map((elem: any, index: number) => {
                return (
                  <div className={classes.serversidemessages} key={index}>
                    <Alert severity="error">
                      {firstWordCapital(serverErrors?.error[elem][0])}
                    </Alert>
                  </div>
                );
              })}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CustomizedTextField
                label="Title"
                placeholder="Label"
                name="title"
                onChange={(event: React.ChangeEvent<{}>, value: string) =>
                  setTitle(value)
                }
                // value={title}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12}>
              <FormCheckBox
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => handleRegularChange()}
                checked={isDaily}
                name="is_daily"
                label="This is a regular class"
              />
            </Grid>
            <Grid item xs={6} className={classes.formWrapper}>
              <InputLabel>Select Subject</InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: TeacherSubjectsI | null
                ) => setSelectedSubjects(value)}
                options={subjectChoices}
                value={selectedSubjects}
                getOptionLabel={(option) => option.subject_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="subject_name"
                    inputRef={register({ required: true })}
                    variant="outlined"
                    helperText={
                      errors?.subject_name ? 'Subject must be selected' : ''
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} className={classes.formWrapper}>
              {isDaily ? (
                <>
                  <Button
                    onClick={() => {
                      setDaysDialog(true);
                    }}
                    color="primary"
                    variant="outlined"
                    startIcon={<CheckOutlined />}
                    style={{
                      marginTop: '22px',
                    }}
                  >
                    Apply To
                  </Button>
                </>
              ) : (
                <>
                  <CustomizedNepaliDatePicker
                    label="Class Date"
                    value={classDate}
                    name="class_date"
                    setValue={handleClassDate}
                    required
                  />
                  <span className={classes.validationErrorInfo}>
                    {errors.class_date?.type === 'required' &&
                      errors.class_date.message}
                  </span>
                  <span className={classes.validationErrorInfo}>
                    {errors.class_date?.type === 'serverSideError' &&
                      errors.class_date.message}
                  </span>
                </>
              )}
            </Grid>
            {dailyDays.length
              ? dailyDays.map((item) => {
                  if (item.checked) {
                    return (
                      <Chip
                        style={{ margin: '5px 10px 15px' }}
                        key={item.id}
                        variant="outlined"
                        size="small"
                        color="primary"
                        label={item.day}
                      />
                    );
                  }
                })
              : ''}
          </Grid>

          <Grid container spacing={1} item xs={12}>
            <Grid item xs={6} className={classes.formWrapper}>
              <CustomizedTextField
                label="Start time"
                placeholder="Label"
                name="start_time"
                required
                type="time"
                onChange={handleChange}
                error={errors['start_time'] ? 'Required Field.' : ''}
                inputRef={register({ required: true })}
              />
            </Grid>

            <Grid item xs={6} className={classes.formWrapper}>
              <CustomizedTextField
                label="End time"
                placeholder="Label"
                name="end_time"
                onChange={handleChange}
                required
                type="time"
                error={errors['end_time'] ? 'Required Field.' : ''}
                inputRef={register({ required: true })}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormCheckBox
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => handleExternalLink()}
              checked={isExternalLink}
              name="is_link"
              label="This has external meeting link"
            />
          </Grid>
          {isExternalLink && (
            <Grid item xs={12} className={classes.formWrapper}>
              <CustomizedTextField
                label="Meeting Link"
                placeholder="Label"
                name="meeting_link"
                type="url"
                required
                error={errors['meeting_link'] ? 'Required Field.' : ''}
                inputRef={register({ required: true })}
              />
            </Grid>
          )}
          <Grid
            container
            justifyContent="flex-end"
            style={{ paddingBottom: '8px' }}
          >
            <FormSaveButton />
          </Grid>
        </form>
      </div>
      <SelectDaysDialog
        editDays={editDays}
        modalState={daysDialog}
        handleClose={setDaysDialog}
        handleApply={applyToDays}
      />
    </Paper>
  );
};
//---------------------------<END> Live classes form components end ---------------------------//
export default LiveClassesForm;
