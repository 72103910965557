// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import 'nepali-datepicker-reactjs/dist/index.css';
import MeetingRequestModal from './MeetingRequestModal';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import CustomizedTextField from '../../../../components/Reusable/Inputs/TextField';
import CustomizedNepaliDatePicker from '../../../../components/Reusable/Inputs/NepaliDatePicker';
import AddNewButton from '../../../../components/Reusable/Buttons/AddNewButton.test';
import FormButton from '../../../../components/Reusable/Buttons/FormButton';
import CustomizedSelect from '../../../../components/Reusable/Inputs/Select';
import { RootStore } from '../../../../store';
import { GetMeetingTypes } from '../../../../actions/HumanResource/MeetingType/MeetingTypeAction';
import { Tupple } from '../../../../actions';
import { dateConverterBsToAd } from '../../../../components/utils/dateConverter';
import { GetChildren } from '../../../../actions/ParentsDash/ViewChild/ViewChildAction';
import getFullName from '../../../../components/utils/getFullName';
import { GetSearchedMeetings } from '../../../../actions/ParentsDash/ParentMeeting/ParentMeetingAction';
import { CUR_NEPALI_DATE } from '../../../../components/utils/nepaliDateUtils';
import SearchFormHeaderLayout from '../../../../components/Reusable/Layouts/SearchFormHeaderLayout';
import { SubmitButton } from '../../../../components/Reusable/Buttons/SearchButton';

// -----------------<END> imports module ends -----------------------//

interface MeetingSearchProps {
  academicID?: string;
}

// -----------------<START> Meeting Search component starts-----------------------//
const MeetingSearch = (props: MeetingSearchProps) => {
  const { academicID } = props;
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { register, handleSubmit, errors } = useForm();

  const [fromDate, setFromDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [toDate, setToDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [meetingType, setMeetingType] = useState<Tupple | null>(null);
  const [selectedChild, setSelectedChild] = useState<Tupple | null>(null);
  const [openPopup, setOpenPopup] = useState(false);

  console.log('academicID', academicID);

  useEffect(() => {
    dispatch(GetMeetingTypes());
    dispatch(GetChildren());
  }, []);

  const meetingTypeState = useSelector(
    (state: RootStore) => state.meeting_type
  );
  const childState = useSelector((state: RootStore) => state.view_child);

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const onSubmit = (data: any) => {
    const searchData = {
      date_first: (fromDate && dateConverterBsToAd(fromDate)) || '',
      date_second: (toDate && dateConverterBsToAd(toDate)) || '',
      meetingType: meetingType ? meetingType.key : '',
    };

    dispatch(GetSearchedMeetings(searchData));
  };

  const MEETING_TYPE_CHOICES = meetingTypeState.meeting_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  const SELECT_CHILD_CHOICES = childState.view_child.map(
    (item: {
      id: number;
      student_first_name: string;
      student_last_name: string;
    }) => ({
      key: item.id.toString(),
      value: getFullName(item.student_first_name, item.student_last_name),
    })
  );

  const handleReset = () => {
    setMeetingType(null);
    setSelectedChild(null);

    dispatch(GetSearchedMeetings({ meetingType: '' }));
  };

  return (
    <>
      <Paper
        className={classes.pageAttachContent}
        elevation={0}
        style={{
          borderBottomColor: '#f5f5f5',
          padding: '16px',
          paddingTop: '24px',
        }}
      >
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <SearchFormHeaderLayout
            title="Meeting Requests"
            btnTitle="Request New Meeting"
            reset={handleReset}
            onClick={() => setOpenPopup(true)}
          />

          <Grid container spacing={3}>
            {/* <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedNepaliDatePicker
                label="From"
                value={fromDate}
                setValue={setFromDate}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedNepaliDatePicker
                label="To"
                value={toDate}
                setValue={setToDate}
              />
            </Grid> */}

            <Grid item xs={4} className={classes.formWrapper}>
              <CustomizedSelect
                label="Meeting Type"
                name="meetingType"
                options={MEETING_TYPE_CHOICES}
                value={meetingType}
                setValue={setMeetingType}
              />
            </Grid>

            {/* <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedSelect
                label="Select Child"
                name="child_name"
                options={SELECT_CHILD_CHOICES}
                value={selectedChild}
                setValue={setSelectedChild}
              />
            </Grid> */}

            {/* <Grid item xs={3} className={classes.formWrapper}>
              <FormButton
                startIcon={<Search />}
                className={classes.searchBoxButton}
              >
                Search
              </FormButton>
            </Grid> */}
            <SubmitButton style={{ marginTop: '5px' }} />
          </Grid>
        </form>

        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
          title="Request Meeting"
          maxWidth="sm"
        >
          <MeetingRequestModal onClose={handleClose} />
        </Popups>
      </Paper>
    </>
  );
};

//-----------------<ENDS> Meeting Search component ends-----------------------//
export default MeetingSearch;
