import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import BackDropLoader from "../../Reusable/BackDropLoader";
import SubModuleLayout from "../../Reusable/Layouts/SubModuleLayout";
import FinanceSidebar from "../FinanceSidebar";
import OpeningBalanceTable from "./OpeningBalanceTable";

interface SelectedFeesI {
    id: string;
    title: string;
    isMonthly: boolean;
}

export default function OpeningBalancePage() {
    const [fees, setFees] = useState<SelectedFeesI[]>([]);
    const [months, setMonths] = useState<string[]>([]);
    const [students, setStudents] = useState<string[]>([]);
    const [grade, setGrade] = React.useState<string | null>();
    const [section, setSection] = React.useState<string | null>();
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    const gradeLoading = useSelector((state: RootStore) => state.class.loading);
    const feeLoading = useSelector((state: RootStore) => state.fee_type.loading);
    const studentLoading = useSelector(
        (state: RootStore) => state.student.loading
    );

    const authState = useSelector((state: RootStore) => state.auth);

    useEffect(() => {
        if (!gradeLoading && !feeLoading && !studentLoading) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [gradeLoading, feeLoading, studentLoading]);

    const nav = <FinanceSidebar opening_balance />;

    const handleFeeChange = (fees: SelectedFeesI[]) => {
        setFees([...fees]);
    };

    const handleMonthChange = (ids: string[]) => {
        setMonths([...ids]);
    };

    const handleStudentChange = (ids: string[]) => {
        setStudents([...ids]);
    };

    return (
        <SubModuleLayout sideNav={nav}>
            <Grid container>
                <Grid item xs={12}>
                    <OpeningBalanceTable title="Opening Balance" onStudentChange={handleStudentChange} grade={grade} setGrade={setGrade} setSection={setSection} section={section} />
                </Grid>
            </Grid>
            <BackDropLoader open={loading} />
        </SubModuleLayout>
    );
}