import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Grid, Button, TextField } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { tblStyle } from "../../../components/Styles/TeacherDashboardStyles";
import { HeadCellsI } from "../../../../../actions";
import useTable from "../../../../../components/Reusable/useStudentTable";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import { ItemSubmitButton, ItemEditButton } from "../../../../../components/Reusable/Buttons/TableButton";
import CustomizedPopUp from "../../../../../components/Reusable/Dialogs/CustomizedPopUp";
import { RootStore } from "../../../../../store"
import { Tupple } from "../../../../../common/json.constant"
import { GetTeachingStatus } from "../../../../../common/GetStatus"
import getFullName from "../../../../../components/utils/getFullName";
import AssignMarks from "./AssignMarks";
import CheckAssignment from "./CheckAssignment";
import { AssignPhysicalAssignmentMarks } from "../../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction"

const headCells: HeadCellsI[] = [
    { id: "sn", label: "S.N" },
    { id: "assignment_student_name", label: "Student Name" },
    { id: "status", label: "Submission Status" },
    { id: "submitted_on", label: "Submitted On" },
    { id: "marks", label: "Obtained Marks" },
    { id: "action", label: "Action" },
];

interface SubmissinDataI {
    student_academic: string;
    remarks: string;
    assignment_status: string;
    marks: number
}
interface PostBulkDataI {
    assignment: string;
    submitted: boolean;
    is_checked: boolean;
    submissions: SubmissinDataI[],
}

const AssignmentTable = ({ selectedStatus }: { selectedStatus: Tupple | null }) => {
    const dispatch = useDispatch()
    const classes = useTableStyles();
    const [tableData, setTableData] = useState<any>([]);
    const [marksModal, setMarksModal] = useState<boolean>(false);
    const [checkModal, setCheckModal] = useState<boolean>(false);
    const [itemID, setItemID] = useState<string>("")
    const [file, setFile] = useState<any>();
    const [editMode, setEditMode] = useState<boolean>(false);
    const { StyledTableCell, StyledTableRow, TableContainer } =
        useTable(headCells);

    const { loading, submitted_assignment, unsubmitted_assignment, assignmentById } = useSelector((state: RootStore) => state.teacher_assignment)

    useEffect(() => {
        if (selectedStatus?.key === "S") {
            const items = submitted_assignment && formatData(submitted_assignment);
            setTableData(items)
        } else if (selectedStatus?.key === "U") {
            const items = unsubmitted_assignment && formatData(unsubmitted_assignment);
            setTableData(items)
        } else {
            setTableData([])
        }
    }, [selectedStatus, submitted_assignment, unsubmitted_assignment])


    const formatData = (data: any) => {
        const test = data.map((item: any) => {
            return {
                ...item,
                student_name: getFullName(item.student_first_name, item.student_last_name)
            }
        })
        return test;
    }

    // Assign Marks Modal
    const handleMarksModal = (id: string, attached_file: any) => {
        setItemID(id)
        setFile(attached_file)
        setMarksModal(true);
    }
    const handleMarksModalOpen = (val: boolean) => {
        setMarksModal(val);
        setItemID("");
    };
    const handleAssignMarks = () => {
        setMarksModal(false);
        setItemID("");
    };

    // Assignment Check Modal
    const handleCheckModal = (id: string, attached_file: any) => {
        setItemID(id)
        setFile(attached_file)
        setCheckModal(true)
    }
    const handleCheckModalOpen = (val: boolean) => {
        setCheckModal(val);
        setItemID("");
    };
    const handleCheckAssignment = () => {
        setCheckModal(false);
        setItemID("");
    };

    // Physical Assignment UI
    let updatedData: any = [];
    const handleEditClick = () => {
        setEditMode(true);
        updatedData = [];
    };
    const handleSaveClick = () => {
        updatedData.length && setTableData(updatedData);
        const post_data: PostBulkDataI = {
            assignment: assignmentById.id,
            is_checked: true,
            submitted: true,
            submissions: [],
        };
        updatedData.map((item: any) => {
            item.marks_obtained && post_data.submissions.push({
                assignment_status: "Completed",
                remarks: "Remarks",
                student_academic: item.id,
                marks: parseInt(item.marks_obtained)
            })
        })
        updatedData.length && dispatch(AssignPhysicalAssignmentMarks(post_data))
        setEditMode(false);
    }
    const handleInputChange = (value: string, item_id: string) => {
        const tempData: any[] = updatedData.length > 0 ? updatedData : tableData;
        const updated: any[] = [];
        tempData.map((element: any) => {
            element.student === item_id ? updated.push({
                ...element,
                marks_obtained: value,
            }) : updated.push(element);
        });
        updatedData = updated;
    }

    const getCustomTableRow = (item: any, index: number) => {
        let disabled;
        if (selectedStatus?.key === "U" || assignmentById.submission_type === "Physical" || (item.submitted && item.marks)) {
            disabled = true;
        } else {
            disabled = false;
        }
        return (
            <StyledTableRow key={item.id}>
                <StyledTableCell align="center" className={classes.cell}>
                    {index + 1}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.student_name}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    <GetTeachingStatus status={selectedStatus?.key === "S" ? "completed" : "remaining"} />
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item?.created_on ? new Date(item.created_on).toLocaleString() : "N/A"}
                </StyledTableCell>
                {editMode ? (
                    <StyledTableCell align="center" className={classes.cell}>
                        <TextField
                            type="number"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(event.target.value, item.student)
                            }
                            id={String(item.id)}
                            defaultValue={item.marks_obtained}
                            placeholder="Marks"
                            variant="outlined"
                        />
                    </StyledTableCell>
                ) : (<StyledTableCell align="center" className={classes.cell}>
                    {item.marks ? item.marks : "N/A"}
                </StyledTableCell>)}
                <StyledTableCell align="center" className={classes.cell}>
                    <ItemSubmitButton disabled={disabled} onClick={() => handleMarksModal(item.id, item.attached_file)} />
                    <ItemEditButton disabled={disabled} onClick={() => handleCheckModal(item.id, item.attached_file)} />
                </StyledTableCell>
            </StyledTableRow>
        );
    };
    return (
        <Paper style={tblStyle}>
            <TableContainer
                label={assignmentById?.title}
                loading={loading}
                items={tableData}
                headCells={headCells}
                getCustomTableRow={getCustomTableRow}
                search_name="student_name"
                search_param="student_name"
            />
            {tableData?.length && assignmentById?.submission_type === "Physical" && <Grid container justifyContent="flex-end">
                <Grid item xs={3}>
                    <Grid container justifyContent="flex-end">
                        {!editMode ? <Button
                            onClick={handleEditClick}
                            className={classes.addMarksButton}
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                        >
                            Edit
                        </Button> :
                            <Button
                                onClick={handleSaveClick}
                                className={classes.addMarksButton}
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                            >
                                Save
                            </Button>}
                    </Grid>
                </Grid>
            </Grid>}
            <CustomizedPopUp
                title="Assign Marks"
                open={marksModal}
                onClose={handleMarksModalOpen}
                actionConfirmed={handleAssignMarks}
                dialogTitle="Grade Assignment"
                okText={false}
                cancel={false}
            >
                <AssignMarks id={itemID} file={file} onClose={handleMarksModalOpen} status={selectedStatus} />
            </CustomizedPopUp>

            <CustomizedPopUp
                title="Check assignment"
                open={checkModal}
                onClose={handleCheckModalOpen}
                actionConfirmed={handleCheckAssignment}
                dialogTitle="Check Assignment"
                okText={false}
                cancel={false}
                fullWidth
                maxWidth="xl"
            >
                <CheckAssignment id={itemID} file={file} onClose={handleMarksModalOpen} />
            </CustomizedPopUp>
        </Paper>
    )
}

export default AssignmentTable