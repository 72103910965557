//---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  Checkbox,
  Button,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { FormatColorResetTwoTone, Send, Visibility } from '@material-ui/icons';
import { useTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import { Pagination } from '@material-ui/lab';
import cx from 'clsx';
import TableActionButtons from '../../Reusable/Buttons/TableActionButtons';
import ActionButton from '../../Reusable/Buttons/ActionButton';
import { SearchTitleI } from './SearchResultBar';
import { ResultTypeI } from '../../../actions/Examination/Result/ResultActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetMarkSheet } from '../../../actions/Examination/MarkSheet/MarkSheetAction';
import MarkSheetModal from './MarkSheetModal';
import Popups from '../../Reusable/Dialogs/Popups';
import { setSnackbar } from '../../../actions/SnackbarAction';
import { SendResultSms } from '../../../actions/Communication/SendResult/SendResultAction';
import { StudentsByClassI } from '../../../actions/Student/Student/StudentActionTypes';
import { GetStudentByClass } from '../../../actions/Student/Student/StudentAction';
import { GenerateResult } from '../../../actions/Examination/Result/ResultAction';
//----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  searchTitle: SearchTitleI | null;
  onGen: () => void;
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  // { id: "sn", label: "S.N" },
  { id: 'student_name', label: 'Student Name' },
  { id: 'roll_no', label: 'Roll No' },
  { id: 'gpa', label: 'GPA' },
  { id: 'percentage', label: 'Percentage' },
  { id: 'student_position', label: 'Position' },
  { id: 'action', label: 'Action' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const GenerateResultTable = (props: PropsI) => {
  const { searchTitle, onGen } = props;
  const classes = useTableStyles();

  // States declaration
  const [tableData, setTableData] = useState<StudentsByClassI[]>([]);
  const [tempTableData] = useState<StudentsByClassI[]>([]);

  const [totalRecord, setTotalRecord] = useState(tempTableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const [page, setPage] = useState(1);

  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(7);

  const [studentName, setStudentName] = useState<string>('');

  //============================== <START> REACT HOOKS <START> =========================//
  const dispatch = useDispatch();
  const student_selector = useSelector((state: RootStore) => state.student);

  useEffect(() => {
    const data = student_selector.student_by_class;

    if (searchTitle) {
      setTableData(data);

      setTotalRecord(data.length);
      setPageCount(Math.ceil(data.length / rowsPerPage));
    }
  }, [student_selector]);
  //============================== <END> REACT HOOKS <END> =========================//

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
    TblHead,
  } = useTable(headCells);

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search_data = tempTableData.filter((data) =>
      data.student_first_name
        .toUpperCase()
        .includes(e.target.value.toUpperCase())
    );
    setTotalRecord(search_data.length);
    setPageCount(Math.ceil(search_data.length / rowsPerPage));
    setPage(1);
    setRecordFrom(1);
    setRecordTo(rowsPerPage);
    setTableData(search_data);
  };

  const [selected, setSelected] = React.useState<string[]>([]);
  const [studentArr, setStudentArr] = useState<string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => String(n.academic_id));
      const newStudArr: string[] = [];
      setSelected(newSelecteds);

      newSelecteds.map((element) => {
        newStudArr.push(element);
      });

      setStudentArr(newStudArr);
      return;
    }
    setSelected([]);
    setStudentArr([]);
  };

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    const newStudentArr: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);

    newSelected.map((element) => {
      newStudentArr.push(element);
    });

    setStudentArr(newStudentArr);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleGenerateResult = () => {
    if (searchTitle && studentArr.length) {
      dispatch(
        GenerateResult(
          searchTitle.exam_info,
          searchTitle.grade,
          searchTitle.section_id,
          {
            exam_info: searchTitle.exam_info,
            students: studentArr,
          }
        )
      );
      onGen();
    } else {
      dispatch(
        setSnackbar(true, 'warning', 'Select Students to Generate Result')
      );
    }
  };

  return (
    <>
      <Paper className={classes.rootTableBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              {searchTitle
                ? `Result for Class ${searchTitle.class} ${
                    searchTitle.section && `(${searchTitle.section})`
                  }`
                : 'Result'}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <div className={classes.tableTop}>
            <TextField
              autoComplete="off"
              autoFocus={false}
              variant="outlined"
              name="search"
              placeholder="Search by Name ..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTableSearch(e)
              }
            />

            <TableActionButtons
              exportToExcel={exportToExcel}
              tablePrint={tablePrint}
            />
          </div>
          <TblContainer>
            <TblHead
              hasCheckbox
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={tableData.length}
            />

            <TableBody>
              {tableData
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  const isItemSelected = isSelected(String(row.academic_id));
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const cellStyle = cx(classes.cell, classes.cellSm);

                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <StyledTableCell className={cellStyle} align="left">
                        <Checkbox
                          color="default"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={() =>
                            handleCheckClick(String(row.academic_id))
                          }
                        />
                        {row.student_first_name} {row.student_last_name}
                      </StyledTableCell>
                      <StyledTableCell className={cellStyle} align="center">
                        {row.roll_number}
                      </StyledTableCell>
                      <StyledTableCell className={cellStyle} align="center">
                        -
                      </StyledTableCell>
                      <StyledTableCell className={cellStyle} align="center">
                        -
                      </StyledTableCell>
                      <StyledTableCell className={cellStyle} align="center">
                        -
                      </StyledTableCell>

                      <StyledTableCell className={cellStyle} align="center">
                        -
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </TblContainer>
          <div className={classes.tableBottom}>
            {totalRecord > 0 ? (
              <Typography>
                Showing {recordFrom} to{' '}
                {recordTo > totalRecord ? totalRecord : recordTo} of{' '}
                {totalRecord}
              </Typography>
            ) : (
              <Typography>No records found</Typography>
            )}
            <div className={classes.pageNavigation}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
        <Grid item>
          <Grid
            container
            justifyContent="flex-end"
            className={classes.tableBottomButtons}
          >
            {tableData.length ? (
              <Grid item>
                <Button
                  style={{ marginTop: '16px' }}
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<Visibility />}
                  onClick={() => handleGenerateResult()}
                >
                  Generate
                </Button>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default GenerateResultTable;
