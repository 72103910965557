import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "rgba(255, 255, 255, 0.95)",
    borderRadius: "15px",
    "& b": {
      fontWeight: 600,
    },
  },
  rootSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: "rgba(0,0,0,0.75)",
  },
  content: {
    padding: "8px 16px 16px 16px",
    position: "relative",
  },
  overlay: {
    display: "Flex",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: "-10px",
    left: "-30px",
    zIndex: 1,
  },
}));

interface DashboardWidgetCardPropsI {
  title: string;
  icon: any;
  variant?: "primary" | "secondary";
  children: any;
}

const DashboardWidgetCard = (props: DashboardWidgetCardPropsI) => {
  const classes = useStyles();
  const WidgetIcon = props.icon;

  return (
    <Card
      elevation={0}
      className={clsx(classes.root, props.variant && classes.rootSecondary)}
    >
      <CardActionArea>
        <CardContent className={classes.content}>
          <div style={{ position: "relative", zIndex: 99 }}>
            <Box display="flex" justifyContent="flex-end">
              <WidgetIcon
                style={{ fontSize: 40 }}
                color={props.variant || "secondary"}
              />
            </Box>

            <Typography gutterBottom variant="h6" component="p">
              {props.title}
            </Typography>
            <Typography variant="subtitle2" component="p">
              {props.children}
            </Typography>
          </div>

          <Box className={classes.overlay}>
            <WidgetIcon
              style={{ fontSize: 100, opacity: "0.15" }}
              color={props.variant || "secondary"}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default DashboardWidgetCard;
