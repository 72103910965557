import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_APE_LOADING,
  GET_TEACHER_APE_ERROR,
  GET_TEACHER_APE_SUCCESS,
  ADD_TEACHER_APE_LOADING,
  ADD_TEACHER_APE_ERROR,
  ADD_TEACHER_APE_SUCCESS,
  TeacherAPEDispatchTypes,
} from "./TeacherAPEActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetTeacherAPE =
  (request_params: any) =>
  async (dispatch: Dispatch<TeacherAPEDispatchTypes | SetSnackBarI>) => {
    const { exam_info, grade, section } = request_params;
    try {
      dispatch({
        type: GET_TEACHER_APE_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/add_ape_base/?grade=${grade}&section=${section}&exam_info=${exam_info}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHER_APE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_APE_ERROR,
      });
    }
  };

export const AddTeacherAPE =
  (data: any, param: any, func: any) =>
  async (dispatch: Dispatch<TeacherAPEDispatchTypes | SetSnackBarI>) => {
    const { exam_info, grade, section } = param;

    try {
      dispatch({
        type: ADD_TEACHER_APE_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/add_ape_base/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_TEACHER_APE_SUCCESS,
        payload: res.data,
      });
      func();

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "APE Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_TEACHER_APE_ERROR,
      });
    } finally {
      try {
        dispatch({
          type: GET_TEACHER_APE_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/add_ape_base/?grade=${grade}&section=${section}&exam_info=${exam_info}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_TEACHER_APE_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "APE Updated Successfully",
          },
        });
      } catch (error) {
        dispatch({
          type: GET_TEACHER_APE_ERROR,
        });
      }
    }
  };
