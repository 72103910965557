// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useTable from "../../../../components/Reusable/useStudentTable";
import { HeadCellsI } from "../../../../actions";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../../../components/utils/dateConverter";
import { RootStore } from "../../../../store";
import { tblStyle } from "../../components/Styles/TeacherDashboardStyles";

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "date", label: "Date" },
  { id: "checkin", label: "Checkin Time" },
  { id: "checkout", label: "Checkout Time" },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const MyAttendanceReport = () => {
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<any>([]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);
  const teacherAttendanceState = useSelector(
    (state: RootStore) => state.teacher_attendance?.teacher_attendance
  );

  const monthDates = useSelector(
    (state: RootStore) => state.month_dates?.data?.dates
  );

  const getCheckedinTime = (date: string, type: string) => {
    const engDate = dateConverterBsToAd(date);
    const index = teacherAttendanceState?.findIndex(
      (elem: any) => elem.attendance_date === engDate
    );
    if (index !== null || (undefined && type === "checkin")) {
      return teacherAttendanceState[index]?.data[0]?.attendance_time || "-";
    } else {
      return teacherAttendanceState[index]?.data[1]?.attendance_time || "-";
    }
  };

  useEffect(() => {
    if (monthDates !== null) {
      const data: any[] = [];
      monthDates?.map((item: any, index: number) => {
        data.push({
          id: item,
          checkin: getCheckedinTime(item, "checkin"),
          checkout: getCheckedinTime(item, "checkedout"),
        });
      });
      setTableData(data);
    }
  }, [monthDates]);

  const formatData = (items: any) => {
    return items.map((item: any) => ({
      id: item.id,
      subject: item.subject_name,
      class: item.grade_name,
      section: item.section_name,
      title: item.title,
      date: dateConverterAdToBs(item.assign_on),
      description: "ABCD",
    }));
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.id}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.checkin}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.checkout}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper style={tblStyle}>
      <TableContainer
        label="My Attendance Report"
        items={tableData}
        headCells={headCells}
        rowsPerPage={monthDates?.length}
        getCustomTableRow={getCustomTableRow}
        disableSearch
        disablePagination
      />
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default MyAttendanceReport;
