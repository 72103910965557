import {
  GET_CLASS_ATTENDANCE_LOADING,
  GET_CLASS_ATTENDANCE_ERROR,
  GET_CLASS_ATTENDANCE_SUCCESS,
  ClassAttendanceDispatchTypes,
} from "../../actions/TeacherDash/ClassAttendanceReport/AttendanceReportActionTypes";

const initialState: any = {
  loading: false,
  attendances: [],
};

const ClassAttendanceReportReducer = (
  state: any = initialState,
  action: ClassAttendanceDispatchTypes
): any => {
  switch (action.type) {
    case GET_CLASS_ATTENDANCE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CLASS_ATTENDANCE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CLASS_ATTENDANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        attendances: action.payload,
      };

    default:
      return state;
  }
};

export default ClassAttendanceReportReducer;
