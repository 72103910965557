import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useCardStyles } from "../../../../../../components/Styles/CardStyles";
import ActionButton from '../../../../../../components/Reusable/Buttons/ActionButton';
import { useHistory } from "react-router-dom";

export default function TeacherAcademic(props: any) {
  const classes = useCardStyles();
  const history = useHistory();

  const rows = [
    createData(
      "Email Address",
      props.teacher?.user?.email ? props.teacher.user.email : "Not Provided"
    ),
    createData("Contract:", props.teacher?.academic_info?.contract_name),
    createData("Department:", props.teacher?.academic_info?.department_name),
    createData(
      "Experience:",
      props.teacher?.academic_info?.experience + " years"
    ),
    createData("Highest Degree:", props.teacher?.academic_info?.highest_degree),
    createData("Blood Group:", props.teacher?.blood_group_display),
    createData("Date of Birth:", props.teacher?.date_of_birth),
    createData("Religion:", props.teacher?.religion_display),
    createData("Working Days:", props.teacher?.academic_info?.working_days),
    createData("Leave:", props.teacher?.academic_info?.leave),
  ];

  function createData(name: string, infoData: string) {
    return { name, infoData };
  }

  const onRedirect = () => {
    history.push(`/${props.teacher.user.id}/update`);
  }

  return (
    <>
      <Paper className={classes.infobar} elevation={0}>
        <TableContainer className={classes.roota}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.table}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.infoData}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <ActionButton onClick={onRedirect} title="Edit Profile" style={{ backgroundColor: "blue", fontSize: "16px", borderRadius: "0px", padding: "10px", color: "white", marginTop: "1em" }}>Edit Profile</ActionButton>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
