//---------------------------<START> import modules starts-----------------------//
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { GetMeetingTypes } from '../../../../actions/HumanResource/MeetingType/MeetingTypeAction';
import CustomizedTextField from '../../../../components/Reusable/Inputs/TextField';
import CustomizedNepaliDatePicker from '../../../../components/Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect from '../../../../components/Reusable/Inputs/Select';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../../../components/utils/dateConverter';
import { FormSendButon } from '../../../../components/Reusable/Buttons/FormButton';
import { Tupple } from '../../../../actions';
import getFullName from '../../../../components/utils/getFullName';
import { ParentMeetingII } from '../../../../actions/ParentsDash/ParentMeeting/ParentMeetingActionTypes';
import {
  AddMeeting,
  GetMeetings,
  UpdateMeeting,
} from '../../../../actions/ParentsDash/ParentMeeting/ParentMeetingAction';
import { CUR_NEPALI_DATE } from '../../../../components/utils/nepaliDateUtils';
import { CusRadioGroup } from '../../../../components/Reusable/Inputs/Radio';
//-------------------------<END> import modules ends ----------------------------//

//--------------------------<START> Add Annoucement Modal components starts---------------------------------------------------//
const MeetingRequestModal = (props: any) => {
  const { editable, item } = props;
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const [dateFrom, setDateFrom] = useState<any>(CUR_NEPALI_DATE);
  const [dateTo, setDateTo] = useState<any>(CUR_NEPALI_DATE);
  const [meetingType, setMeetingType] = useState<Tupple | null>(null);
  const [selectedChild, setSelectedChild] = useState<Tupple | null>(null);
  const [valueRadio, setValueRadio] = useState<string | null>(null);

  const meetingTypeState = useSelector(
    (state: RootStore) => state.meeting_type
  );
  const childState = useSelector((state: RootStore) => state.view_child);

  useEffect(() => {
    dispatch(GetMeetingTypes());
  }, []);

  const { register, handleSubmit, errors, setValue } = useForm<any>();

  const SELECT_CHILD_CHOICES = childState.view_child.map(
    (item: {
      id: number;
      student_first_name: string;
      student_last_name: string;
    }) => ({
      key: item.id.toString(),
      value: getFullName(item.student_first_name, item.student_last_name),
    })
  );

  const MEETING_TYPE_CHOICES = meetingTypeState.meeting_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  useEffect(() => {
    if (editable) {
      setDateFrom(item.meeting_date);
      setValue('time', item.meeting_time);
      setValue('description', item.description);
      setMeetingType(
        MEETING_TYPE_CHOICES.find(
          (childItem: any) => childItem.key === item.meeting_type_id
        ) || MEETING_TYPE_CHOICES[0]
      );
    }
  }, [editable]);

  //-------------------<START> React hooks forms starts ----------------------//

  const onSubmit = (data: any) => {
    if (data && dateFrom && meetingType) {
      const meetingData: any = {
        meeting_type: meetingType.key,
        meet_date: dateConverterBsToAd(dateFrom),
        meet_time: data.time,
        description: data.description,
      };

      if (editable) {
        dispatch(UpdateMeeting(item.id, meetingData));
      } else {
        dispatch(AddMeeting(meetingData));
      }
    }

    props.onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRadio((event.target as HTMLInputElement).value);
  };

  //------------------------<END> React Hooks form ends -------------------------//
  return (
    <Paper elevation={0}>
      <div className={classes.modalContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.formWrapper}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="choose"
                    name="choose"
                    value={valueRadio}
                    onChange={handleChange}
                    style={{ flexDirection: 'row' }}
                  >
                    <FormControlLabel
                      value="admin"
                      control={<Radio />}
                      label="Request to Admin"
                    />
                    <FormControlLabel
                      value="teacher"
                      control={<Radio />}
                      label="Request to Teacher"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {valueRadio === 'teacher' && (
                <Grid item xs={6} className={classes.formWrapper}>
                  <CustomizedSelect
                    label="Select Teacher"
                    name="select_teacher"
                    options={MEETING_TYPE_CHOICES}
                    value={meetingType}
                    setValue={setMeetingType}
                    inputRef={register({ required: false })}
                  />
                </Grid>
              )}

              <Grid
                item
                xs={valueRadio === 'teacher' ? 6 : 12}
                className={classes.formWrapper}
              >
                <CustomizedSelect
                  label="Meeting Type"
                  name="meeting_type"
                  options={MEETING_TYPE_CHOICES}
                  value={meetingType}
                  setValue={setMeetingType}
                  error={
                    errors['meeting_type']
                      ? 'Meeting type must be selected'
                      : ''
                  }
                  inputRef={register({ required: true })}
                />
              </Grid>

              {/* <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedSelect
                  label="Select Child"
                  name="child_name"
                  options={SELECT_CHILD_CHOICES}
                  value={selectedChild}
                  setValue={setSelectedChild}
                />
              </Grid> */}
            </Grid>

            <Grid container spacing={1} item xs={12}>
              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Meeting from Date"
                  value={dateFrom}
                  setValue={setDateFrom}
                />
              </Grid>

              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedTextField
                  label="Time"
                  type="time"
                  name="time"
                  error={errors['time'] ? 'Required Field.' : ''}
                  inputRef={register({ required: true })}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formWrapper}>
              <CustomizedTextField
                label="Description"
                placeholder="Label"
                name="description"
                required
                multiline
                rows={4}
                error={errors['description'] ? 'Required Field.' : ''}
                inputRef={register({ required: true })}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            style={{ paddingBottom: '8px' }}
          >
            <FormSendButon />
          </Grid>
        </form>
      </div>
    </Paper>
  );
};
//---------------------------<END> Add Annoucement Modal components end ---------------------------//
export default MeetingRequestModal;
