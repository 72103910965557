import { Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ItemViewButton } from "../../../../components/Reusable/Buttons/TableButton";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { GetStudentSyllabus } from "../../../../actions/StudentDash/StudentSyllabus/StudentSyllabusAction";
import { RootStore } from "../../../../store";
import { StudentSyllabusTypeI } from "../../../../actions/StudentDash/StudentSyllabus/StudentSyllabusActionTypes";
import Popups from "../../../../components/Reusable/Dialogs/Popups";
import SyllabusViewUnit from "../../../TeacherDashboard/pages/Syllabus/Componets/SyllabusViewUnit";
import useTable from "../../../../components/Reusable/useStudentTable";
import { tblStyle } from "../../StudentDashboardStyles";

interface HeadCellsI {
  id: string;
  label: string;
}

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "unit", label: "S.No." },
  { id: "name", label: "Unit Title" },
  { id: "teaching_hour", label: "Teaching Hour" },
  { id: "actions", label: "Actions" },
];

const StudentSyllabusTable = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { subject } = props;

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<any>([]);
  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [indexN, setIndex] = useState<number>();
  const [item, setItem] = useState<StudentSyllabusTypeI>();
  const [loading, setLoading] = useState(false);

  //*-----------------------------------REDUX MANAGEMENT-------------------*//
  useEffect(() => {
    subject && dispatch(GetStudentSyllabus(subject));
  }, [subject]);

  const syllabusSelector = useSelector(
    (state: RootStore) => state.student_syllabus
  );

  useEffect(() => {
    const data = syllabusSelector.add_unit;
    setTableData(data);
    setLoading(syllabusSelector.loading);
  }, [syllabusSelector]);

  const handleViewClicked = (data: StudentSyllabusTypeI, index: number) => {
    setItem(data);
    setIndex(index + 1);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const getCustomTableRow = (item: StudentSyllabusTypeI, index: number) => {
    return (
      <StyledTableRow key={index} hover tabIndex={-1}>
        <StyledTableCell align="center" className={classes.newcell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.teaching_hours}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          <ItemViewButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewClicked(item, index)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper
        className={classes.rootTableBox}
        style={{ ...tblStyle, marginLeft: 20 }}
      >
        <TableContainer
          label="Syllabus List"
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          rowsPerPage={7}
        />
      </Paper>
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        maxWidth="xs"
        title="View Unit"
        colored
      >
        <SyllabusViewUnit student={true} item={item} indexN={indexN} />
      </Popups>
    </>
  );
};

export default StudentSyllabusTable;
