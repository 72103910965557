import {
  Box,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { GetStaffProfile } from "../../../../actions/HumanResource/StaffProfile/StaffProfileAction";
import { RootStore } from "../../../../store";
import { TabPanel } from "../../../Student/StudentProfile/File/NewTabs";
import { useCardStyles } from "../../../Styles/CardStyles";
import StaffAcademic from "./StaffTabs/StaffAcademic";
import StaffClassesSubjects from "./StaffTabs/StaffClassesSubjects";
import StaffDocument from "./StaffTabs/StaffDocument";
import StaffExplore from "./StaffTabs/StaffExplore";
import StaffLeaveInfo from "./StaffTabs/StaffLeaveInfo";
import StaffRoles from "./StaffTabs/StaffRoles";
import StaffTimeTable from "./StaffTabs/StaffTimetable";

function StaffTabs(props: any) {
  const classes = useCardStyles();
  const [value, setValue] = React.useState(0);
  const { staffID } = useParams<{ staffID: string }>();
  const [staff, setStaff] = useState<any>([]);
  const [isTeacher, setIsTeacher] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    staffID != undefined && dispatch(GetStaffProfile(staffID));
  }, []);

  const staffList = useSelector(
    (state: RootStore) => state.staffProfile.staff_profile
  );

  const { actionPerformed } = useSelector(
    (state: RootStore) => state.staffProfile
  );

  useEffect(() => {
    if (actionPerformed) {
      dispatch(GetStaffProfile(staffID));
    }
  }, [actionPerformed]);

  useEffect(() => {
    if (staffList != null) {
      setStaff(staffList);

      try {
        const groups = staffList.user.groups;
        const role = groups.find((item: any) => item.name === "Teacher");

        if (role) {
          setIsTeacher(true);
        } else {
          setIsTeacher(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [staffList]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.tabRoot}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        // variant="fullWidth"
      >
        {/* <Tab label="Explore" /> */}
        <Tab label="Academic Info" />
        <Tab label="Documents" />
        <Tab label="System Role" />
        <Tab label="Leave Info" />
        {isTeacher && <Tab label="Time Table" />}
      </Tabs>
      {/* <TabPanel value={value} index={0}>
        <StaffExplore />
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <StaffAcademic staff={staff} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <StaffDocument hasUpload={true} hasDeleteBtn={true} Documents={true} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StaffRoles user="staff" />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <StaffLeaveInfo staff={staff} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <StaffTimeTable staffId={staffID} />
      </TabPanel>
    </Paper>
  );
}

export default StaffTabs;
