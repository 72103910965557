import {
  GET_TEACHER_EXAM_SUBJECTS_LOADING,
  GET_TEACHER_EXAM_SUBJECTS_ERROR,
  GET_TEACHER_EXAM_SUBJECTS_SUCCESS,
  TeacherExamSubjectsDispatchTypes,
  GetTeacherExamSubjectsTypeI,
} from "../../actions/TeacherDash/TeacherExamSubjects/TeacherExamSubjectsActionTypes";

interface InitialStateI {
  loading: boolean;
  exam_subjects: GetTeacherExamSubjectsTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  exam_subjects: [],
};

const TeacherExamSubjectReducer = (
  state: InitialStateI = initialState,
  action: TeacherExamSubjectsDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_EXAM_SUBJECTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_EXAM_SUBJECTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_EXAM_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        exam_subjects: action.payload,
      };

    default:
      return state;
  }
};

export default TeacherExamSubjectReducer;
