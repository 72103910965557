import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { GradeTypeI } from "../../../actions/Academics/Grade/GradeActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { GetSections } from "../../../actions/Academics/Section/SectionAction";
import { GetGrades } from "../../../actions/Academics/Grade/GradeAction";
import { Autocomplete } from "@material-ui/lab";
import {
  AddClass,
  UpdateClass,
} from "../../../actions/Academics/Class/ClassAction";
import { useForm } from "react-hook-form";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
// -----------------<start> Styling <start>-----------------------//
// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//

interface PropsI {
  editData: ClassTypeI | null;
  onEditMode: (value: boolean) => void;
}

interface SectionCheckBoxI {
  id: string;
  name: string;
  checked: boolean;
}

// -----------------<end> Interfaces <end>-----------------------//

const ClassForm = (props: PropsI) => {
  const { editData, onEditMode } = props;
  const classes = useFormStyles();

  // -----------------<start> States <start>-----------------------//
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [section, setSection] = useState<SectionCheckBoxI[]>([]);
  const [editID, setEditID] = useState<string | null>(null);
  const [gradeChoices, setGradeChoices] = useState<GradeTypeI[]>([]);
  const [grade, setGrade] = useState<GradeTypeI | null | undefined>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  // -----------------<end> States <end>-----------------------//

  //==================================<START>REDUX REDUCER<START>=============================//

  const dispatch = useDispatch();

  const studentCount = useSelector(
    (state: RootStore) => state.class.student_count
  );
  const classLoading = useSelector((state: RootStore) => state.class.loading);
  const sectionList = useSelector((state: RootStore) => state.section.sections);
  const gradeList = useSelector((state: RootStore) => state.grade.grades);
  const add_or_update = useSelector(
    (state: RootStore) => state.class.add_or_update
  );

  //==================================<END>REDUX REDUCER<END>=============================//

  //===================================<START>CYCLE HOOKS<START>===========================//

  useEffect(() => {
    dispatch(GetGrades());
    dispatch(GetSections());
  }, []);

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      setEditMode(false);
      setEditID(null);
    }
  }, [add_or_update]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  useEffect(() => {
    const sectionCheckBox: SectionCheckBoxI[] = [];
    sectionList.length &&
      sectionList.map((element) => {
        sectionCheckBox.push({
          ...element,
          checked: false,
        });
      });
    setSection(sectionCheckBox);
  }, [sectionList]);

  useEffect(() => {
    gradeList.length && setGradeChoices(gradeList);
  }, [gradeList]);

  useEffect(() => {
    setLoading(classLoading);
  }, [classLoading]);

  //===================================<END>CYCLE HOOKS<END>===========================//
  //===================================<START> EVENT HANDLERS <START>===========================//

  const { register, setError, handleSubmit, errors, clearErrors } = useForm();

  const onSubmit = () => {
    setIsBtnLoading(true);
    const selectedGrade = grade?.id;

    const findSelectedSection = (callback: (value: string[]) => void) => {
      const selectedSections: string[] = [];

      section.length &&
        section.map(
          (element) =>
            element.checked === true &&
            selectedSections.push(element.id.toString())
        );

      callback(selectedSections);
    };

    findSelectedSection((selectedSections: string[]) => {
      if (selectedGrade) {
        const makeSubmission = (callback: () => void) => {
          editID != null
            ? dispatch(
                UpdateClass(editID, {
                  section: selectedSections,
                  grade: selectedGrade,
                })
              )
            : dispatch(
                AddClass({ section: selectedSections, grade: selectedGrade })
              );

          callback();
        };

        makeSubmission(() => {
          //Reseting Form Fields/////////////////////////////
          ///////////////////////////////
        });
      }
    });
  };

  const handleReset = () => {
    setEditMode(false);
    setGrade(null);
    setEditID(null);
    onEditMode(false);
    const sectionCheckBox: SectionCheckBoxI[] = [];
    sectionList.length &&
      sectionList.map((element) => {
        sectionCheckBox.push({
          ...element,
          checked: false,
        });
      });
    setSection(sectionCheckBox);
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sectionCheckBox: SectionCheckBoxI[] = [...section];
    const index: number = sectionCheckBox.findIndex(
      (element) => element.id === event.target.value
    );
    sectionCheckBox[index] = {
      ...sectionCheckBox[index],
      checked: event.target.checked,
    };

    setSection(sectionCheckBox);
  };

  const handleEditTableData = (data: ClassTypeI) => {
    setEditMode(true);
    setEditID(data.id);

    gradeChoices.length &&
      setGrade(gradeChoices.find((element) => element.id === data.grade));

    const updateGradeChoices = (
      callback: (value: SectionCheckBoxI[]) => void
    ) => {
      const updatedData: SectionCheckBoxI[] = [];

      gradeChoices.length &&
        section.map((element) => {
          data.section.find((sec) => sec.id === element.id)
            ? updatedData.push({
                id: element.id,
                name: element.name,
                checked: true,
              })
            : updatedData.push({
                id: element.id,
                name: element.name,
                checked: false,
              });
        });

      callback(updatedData);
    };

    updateGradeChoices((updated_data) => {
      setSection(updated_data);
    });
  };
  //===================================<END> EVENT HANDLERS <END>===========================//

  const handleGrade = (value: any) => {
    setGrade(value);
    clearErrors("grade");
  };

  //----------------------ERROR HANDLING---------------------------//
  const [serverErrors, setServerErrors] = useState<any>(null);
  const classSelector = useSelector((state: RootStore) => state.class);
  const errorsData = classSelector.errors;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: "serverSideError",
            message: errorsData.error[elem] && errorsData.error[elem],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (classSelector.recent) {
      handleReset();
    }
  }, [classSelector]);

  const returnDisability = (id: string): boolean => {
    if (studentCount && editMode) {
      return studentCount[id] > 0;
    }
    return false;
  };

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Class" : "Add Class"}
        onSubmit={handleSubmit(onSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <Grid container>
          <Grid item xs={12} className={classes.formWrapper}>
            <InputLabel>
              Select Grade
              <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <Autocomplete
              value={grade}
              onChange={(
                event: React.ChangeEvent<{}>,
                value: GradeTypeI | null
              ) => handleGrade(value != null ? value : null)}
              options={gradeChoices}
              getOptionLabel={(option) => option.grade_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="grade"
                  placeholder="Grade"
                  variant="outlined"
                  inputRef={register({ required: "Grade must be selected" })}
                />
              )}
            />

            <span className={classes.validationErrorInfo}>
              {errors.grade?.type === "serverSideError" && errors.grade.message}
            </span>
          </Grid>
          <Grid item xs={12} className={classes.formWrapper}>
            <InputLabel>Select Sections</InputLabel>
            {section.length ? (
              <FormControl
                component="fieldset"
                className={classes.sectionCheckBox}
              >
                <FormGroup>
                  {section.map((element: SectionCheckBoxI) => (
                    <FormControlLabel
                      key={element.id}
                      control={
                        <Checkbox
                          color="primary"
                          value={element.id}
                          onChange={handleCheckBoxChange}
                          name={element.name}
                          checked={element.checked}
                          disabled={returnDisability(element.id)}
                        />
                      }
                      label={`Section ${element.name}`}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            ) : (
              <span className={classes.sectionUnavailableText}>
                No Records Found
              </span>
            )}
            <span className={classes.validationErrorInfo} />
          </Grid>
        </Grid>
      </FormLayout>
    </>
  );
};

export default ClassForm;
