import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_LEAVE_TYPE_LOADING,
  GET_LEAVE_TYPE_ERROR,
  GET_LEAVE_TYPE_SUCCESS,
  LeaveTypeDispatchTypes,
  ADD_LEAVE_TYPE_LOADING,
  ADD_LEAVE_TYPE_SUCCESS,
  ADD_LEAVE_TYPE_ERROR,
  DELETE_LEAVE_TYPE_SUCCESS,
  DELETE_LEAVE_TYPE_ERROR,
  DELETE_LEAVE_TYPE_LOADING,
  UPDATE_LEAVE_TYPE_LOADING,
  UPDATE_LEAVE_TYPE_SUCCESS,
  UPDATE_LEAVE_TYPE_ERROR,
} from "./LeaveTypeActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: string;
  name: string;
}

export const GetLeaveTypes =
  () => async (dispatch: Dispatch<LeaveTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_LEAVE_TYPE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/leave_type/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_LEAVE_TYPE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LEAVE_TYPE_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Leave Types Loading Failed",
      //   },
      // });
    }
  };
export const AddLeaveType =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<LeaveTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_LEAVE_TYPE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/leave_type/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_LEAVE_TYPE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Leave Type Added Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_LEAVE_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Leave Type Add Failed",
        },
      });
    }
  };

export const UpdateLeaveType =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<LeaveTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_LEAVE_TYPE_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/leave_type/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_LEAVE_TYPE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Leave Type Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_LEAVE_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Leave Type Update Failed",
        },
      });
    }
  };

export const DeleteLeaveType =
  (id: string) =>
  async (dispatch: Dispatch<LeaveTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_LEAVE_TYPE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/leave_type/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_LEAVE_TYPE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Leave Type Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_LEAVE_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Leave Type Delete Failed",
        },
      });
    }
  };
