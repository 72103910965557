import {
    GET_APPLICABLE_RUBRIC_ERROR,
    GET_APPLICABLE_RUBRIC_LOADING,
    GET_APPLICABLE_RUBRIC_SUCCESS,
    GET_STUDENT_RUBRIC_ERROR,
    GET_STUDENT_RUBRIC_LOADING,
    GET_STUDENT_RUBRIC_SUCCESS,
    APPLY_STUDENT_RUBRIC_ERROR,
    APPLY_STUDENT_RUBRIC_LOADING,
    APPLY_STUDENT_RUBRIC_SUCCESS,
    StudentI,
    TeacherRubricDispatchTypes,
} from "../../../actions/TeacherDash/TeacherRubric/TeacherRubricActionTypes"
import { RubricStaffI } from "../../../actions/Academics/AssignRubric/AssignRubricActionTypes"

interface InitialStateI {
    loading: boolean;
    action_performed: boolean;
    applicable_rubric: RubricStaffI[];
    rubric_students: StudentI[];
}

const initialState: InitialStateI = {
    loading: false,
    action_performed: false,
    applicable_rubric: [],
    rubric_students: [],
}

const TeacherRubricReducer = (
    state: InitialStateI = initialState,
    action: TeacherRubricDispatchTypes
): InitialStateI => {
    switch (action.type) {
        case GET_APPLICABLE_RUBRIC_LOADING:
            return {
                ...state,
                loading: true,
                action_performed: false,
                applicable_rubric: [],
            }
        case GET_APPLICABLE_RUBRIC_ERROR:
            return {
                ...state,
                loading: false,
                action_performed: false,
                applicable_rubric: [],
            }
        case GET_APPLICABLE_RUBRIC_SUCCESS:
            return {
                ...state,
                loading: false,
                action_performed: false,
                applicable_rubric: action.payload,
            }

        case GET_STUDENT_RUBRIC_LOADING:
            return {
                ...state,
                loading: true,
                action_performed: false,
                rubric_students: [],
            }
        case GET_STUDENT_RUBRIC_ERROR:
            return {
                ...state,
                loading: false,
                action_performed: false,
                rubric_students: [],
            }
        case GET_STUDENT_RUBRIC_SUCCESS:
            return {
                ...state,
                loading: false,
                action_performed: false,
                rubric_students: action.payload,
            }

        case APPLY_STUDENT_RUBRIC_LOADING:
            return {
                ...state,
                loading: true,
                action_performed: false,
            }
        case APPLY_STUDENT_RUBRIC_ERROR:
            return {
                ...state,
                loading: false,
                action_performed: false,
            }
        case APPLY_STUDENT_RUBRIC_SUCCESS:
            return {
                ...state,
                loading: false,
                action_performed: true,
            }


        default:
            return state;
    }
}

export default TeacherRubricReducer;