import React, { useState, useEffect } from "react";
import { Chip, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { HeadCellsI } from "../../../../../actions";
import { RootStore } from "../../../../../store";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import { dateConverterAdToBs } from "../../../../../components/utils/dateConverter";
import getFullName from "../../../../../components/utils/getFullName";
import cx from "clsx";
import { ParentLiveClassTypeI } from "../../../../../actions/ParentsDash/ParentLiveClass/ParentLiveClassActionTypes";
import { GetParentLiveClass } from "../../../../../actions/ParentsDash/ParentLiveClass/ParentLiveClassAction";
import useTable from "../../../../../components/Reusable/useStudentTable";
import moment from "moment";

const headCells: HeadCellsI[] = [
  { id: "teacher", label: "Teacher", align: "left" },
  { id: "subject", label: "Subject" },
  { id: "class_date", label: "Start Date" },
  { id: "start_time", label: "Start Time" },
  { id: "end_time", label: "End Time" },
  { id: "status", label: "Status" },
];

const ParentLiveClassList = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { academicID } = props;

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<ParentLiveClassTypeI[]>([]);

  const LiveClassSelector = useSelector(
    (state: RootStore) => state.parent_liveClass
  );

  useEffect(() => {
    academicID && dispatch(GetParentLiveClass(academicID));
  }, [academicID]);

  useEffect(() => {
    if (LiveClassSelector?.parent_liveClass != null) {
      const items = LiveClassSelector.parent_liveClass;
      setTableData(items);
    }
  }, [LiveClassSelector]);

  const getCustomTableRow = (item: ParentLiveClassTypeI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.teacher_first_name &&
            item.teacher_last_name &&
            getFullName(item.teacher_first_name, item.teacher_last_name)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.subject_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.class_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.start_time, "hh:mm:ss").format("h:mm A")}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.end_time, "hh:mm:ss").format("h:mm A")}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.is_completed ? (
            <Chip
              color="primary"
              size="small"
              label="Completed"
              className={classes.chipGreen}
            />
          ) : item.is_ongoing ? (
            <Chip
              color="primary"
              size="small"
              label="Running"
              className={classes.chipBlue}
            />
          ) : (
            <Chip
              color="primary"
              size="small"
              label="Upcoming"
              className={classes.chipRed}
            />
          )}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper
        elevation={0}
        className={cx(classes.root, classes.attachFormTableRoot)}
        style={{ padding: "8px 16px 16px 8px" }}
      >
        <TableContainer
          label="Live Classes List"
          loading={LiveClassSelector.loading}
          items={tableData}
          headCells={headCells}
          rowsPerPage={8}
          getCustomTableRow={getCustomTableRow}
          search_name="subject_name"
          placeholder="Search by subject..."
          hiddenCols={[-1]}
        />
      </Paper>
    </>
  );
};

export default ParentLiveClassList;
