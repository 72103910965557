// ---------------- <START> module import starts ------------------//
import React, { useState } from 'react';
import { useSidebar } from '../../../components/Reusable';
import { useSidebarStyles } from '../../../components/Styles/SidebarStyles';
import { SmsOutlined } from '@material-ui/icons';
import SidebarTitle from '../../../components/Reusable/Titles/SidebarTitle';
// ---------------- <END> module import ends ------------------//

//---------------------------- <START> interface start ------------------------------//
interface propsI {
  announcement?: boolean | undefined;
}
//---------------------------- <END> interface ends ------------------------------//

// ---------------- <START> Communication Sidebar Component starts ------------------//
const AccountantCommunicationSidebar = (props: propsI) => {
  const classes = useSidebarStyles();

  const [announcement] = useState(props.announcement);

  const dataSet = [
    { text: 'Announcement', url: 'announcement', activeStatus: announcement },
  ];
  const { CustomSidebar } = useSidebar(dataSet, 'FINANCE');
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<SmsOutlined />}>Announce</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default AccountantCommunicationSidebar;
// ---------------- <END> Communication Sidebar Component ends ------------------//
