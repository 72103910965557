export const GET_MY_TEACHER_LOADING = "GET_MY_TEACHER_LOADING";
export const GET_MY_TEACHER_ERROR = "GET_MY_TEACHER_ERROR";
export const GET_MY_TEACHER_SUCCESS = "GET_MY_TEACHER_SUCCESS";

export interface StudentMyTeacherTypeI {
  id: string;
  staff: string;
  staff_firstname: string;
  staff_lastname: string;
  subject: string;
  subject_id: string;
}

//===============================<START>GET MY TEACHER<START>======================//

export interface GetMyTeacherLoading {
  type: typeof GET_MY_TEACHER_LOADING;
}

export interface GetMyTeacherError {
  type: typeof GET_MY_TEACHER_ERROR;
}

export interface GetMyTeacherSuccess {
  type: typeof GET_MY_TEACHER_SUCCESS;
  payload: StudentMyTeacherTypeI[];
}

//===============================<END>GET MY TEACHER<END>======================//
export type MyTeacherDispatchTypes =
  | GetMyTeacherLoading
  | GetMyTeacherError
  | GetMyTeacherSuccess;
