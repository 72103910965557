//---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
} from "@material-ui/core";
import { PostAddOutlined } from "@material-ui/icons";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { Pagination } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import MarkSheetModal from "./MarkSheetModal";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import { SearchTitleI } from "../Results/SearchResultBar";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { ResultTypeI } from "../../../actions/Examination/Result/ResultActionTypes";
import { GetMarkSheet } from "../../../actions/Examination/MarkSheet/MarkSheetAction";

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  searchTitle: SearchTitleI | null;
}

interface HeadCellsI {
  id: string;
  label: string;
}

// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "student_name", label: "Student Name" },
  { id: "roll_no", label: "Roll No" },
  { id: "gpa", label: "GPA" },
  { id: "percentage", label: "Percentage" },
  { id: "student_position", label: "Position" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const ResultForClass = (props: PropsI) => {
  const { searchTitle } = props;
  const classes = useTableStyles();

  const [open, setOpen] = React.useState(false);

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<ResultTypeI[]>([]);
  const [tempTableData, setTempTableData] = useState<ResultTypeI[]>([]);

  const [totalRecord, setTotalRecord] = useState(tempTableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const [page, setPage] = useState(1);

  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(rowsPerPage);

  const [studentName, setStudentName] = useState<string>("");

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  //============================== <START> REACT HOOKS <START> =========================//
  const dispatch = useDispatch();
  const resultSelector = useSelector((state: RootStore) => state.result);

  useEffect(() => {
    const data = resultSelector.result;

    if (searchTitle) {
      setLoading(resultSelector.loading);

      setTableData(data);
      setTempTableData(data);

      setTotalRecord(data.length);
      setPageCount(Math.ceil(data.length / rowsPerPage));
    }
  }, [resultSelector]);
  //============================== <END> REACT HOOKS <END> =========================//

  //============================== <START> EVENT HANDLERS <START> =========================//

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search_data = tempTableData.filter((data) =>
      data.student_first_name
        .toUpperCase()
        .includes(e.target.value.toUpperCase())
    );
    setTotalRecord(search_data.length);
    setPageCount(Math.ceil(search_data.length / rowsPerPage));
    setPage(1);
    setRecordFrom(1);
    setRecordTo(rowsPerPage);
    setTableData(search_data);
  };

  const handleClickOpen = (student_id: string, student_name: string) => {
    setOpen(true);
    setStudentName(student_name);

    if (searchTitle) {
      dispatch(GetMarkSheet(student_id, searchTitle.exam_info));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Function for handling action icons click event
  const handleActionClicked = () => {
    alert("Action Clicked!");
  };
  //============================== <END> EVENT HANDLERS <END> =========================//

  return (
    <>
      <Paper className={classes.rootTableBox}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
        // className={classes.modalBox}
        >
          <MarkSheetModal onClose={handleClose} student={studentName} />
        </Dialog>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              {searchTitle
                ? `Result for Class ${searchTitle.class} ${searchTitle.section && `(${searchTitle.section})`
                }`
                : "Result"}
            </Typography>
          </Grid>
        </Grid>

        <div className={classes.tableWrapper}>
          <div className={classes.tableTop}>
            <TextField
              variant="outlined"
              name="search"
              placeholder="Search by Name ..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTableSearch(e)
              }
            />

            <TableActionButtons
              exportToExcel={exportToExcel}
              tablePrint={tablePrint}
            />
          </div>
          <TblContainer>
            <TblHead />
            <TableBody>
              {tableData
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((item: ResultTypeI, index: number) => (
                  <StyledTableRow key={item.student_academic}>
                    <StyledTableCell align="center" className={classes.cell}>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.student_first_name} {item.student_last_name}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.student_roll_number}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.obtain_gpa}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.obtain_percentage}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      <ActionButton
                        title="Add"
                        onClick={(
                          event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                        ) =>
                          handleClickOpen(
                            item.student_academic,
                            `${item.student_first_name} ${item.student_last_name}`
                          )
                        }
                      >
                        <PostAddOutlined className={classes.addIcon} />
                      </ActionButton>
                      {/* <a className={classes.Btn}>
                        <PostAddOutlined className={classes.addIcon} />
                      </a> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </TblContainer>
          <div className={classes.tableBottom}>
            {totalRecord > 0 ? (
              <Typography>
                Showing {recordFrom} to{" "}
                {recordTo > totalRecord ? totalRecord : recordTo} of{" "}
                {totalRecord}
              </Typography>
            ) : (
              <Typography>No records found</Typography>
            )}
            <div className={classes.pageNavigation}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default ResultForClass;
