import { Box } from "@material-ui/core";
import React from "react";
import TeacherProfile from "./components/TeacherProfile";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import TeacherLeaveList from "../TeacherLeave/LeaveComponents/TeacherLeaveList";
import TeacherLeaveSearch from "../TeacherLeave/LeaveComponents/TeacherLeaveSearch";

const TeacherProfilePage = () => {
  return (
    <>
      <Box>
        <PageHeader title="My Profile" />
        <PageWrapper>
          <TeacherProfile />
        </PageWrapper>
      </Box>
    </>
  );
};

export default TeacherProfilePage;
