import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { Clear, Done } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../store";
import { GetSubmittedAssignments } from "../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction";
import formatDate from "../../../../components/utils/formatDate";
import { dateConverterAdToBs } from "../../../../components/utils/dateConverter";
import getFullClassName from "../../../../components/utils/getFullClassName";

interface AssignmentProps {
  grade_name: string;
  subject_name: string;
  assign_on: string;
  deadline: string;
  active: boolean;
  attached_file: string;
  description: string;
}

interface AssingmentViewProps {
  singleAssignment: any;
}

const AssignmentViewCard = (props: AssingmentViewProps) => {
  const { singleAssignment } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (singleAssignment?.id) {
      dispatch(GetSubmittedAssignments(singleAssignment.id));
    } else {
      // dispatch(GetSubmittedAssignments());
    }
  }, [singleAssignment]);

  const submittedAssignmentSelector = useSelector(
    (state: RootStore) => state.teacher_assignment.submitted_assignment
  );

  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: "10px", marginTop: "16px" }}>
        <Grid item xs={2}>
          Status:
        </Grid>
        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            Completed
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Class:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {getFullClassName(
              submittedAssignmentSelector?.grade,
              submittedAssignmentSelector?.section
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Subject:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {submittedAssignmentSelector?.subject}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Assign On:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {dateConverterAdToBs(
              formatDate(submittedAssignmentSelector?.created_on)
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          Deadline:
        </Grid>

        <Grid item xs={10}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {submittedAssignmentSelector?.deadline
              ? dateConverterAdToBs(submittedAssignmentSelector.deadline)
              : ""}
          </Typography>
        </Grid>
      </Grid>

      {submittedAssignmentSelector?.attached_file && (
        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={2}>
            File
          </Grid>

          <Grid item xs={10}>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
              <a href={submittedAssignmentSelector.attached_file}>
                {submittedAssignmentSelector.attached_file?.substring(
                  submittedAssignmentSelector.attached_file?.lastIndexOf("/") +
                  1
                )}
              </a>
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid item xs={2}>
          Description:
        </Grid>

        <Grid
          item
          xs={10}
          style={{
            padding: "10px",
            // border: "1px solid #B6B4B3",
            // borderRadius: "6px",
          }}
        >
          {submittedAssignmentSelector?.content || (
            <em>No Description Available</em>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AssignmentViewCard;
