import { Box, Grid } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import StudentAnnouncementTable from "./StudentAnnouncementTable";
import StudentAnnounceNotification from "./StudentAnnounceNotification";
import PageWrapper from "../../components/PageWrapper";
import { useStudentEventStyles } from "../Events/StudentEventPage";

const StudentAnnouncementPage = () => {
  const classes = useStudentEventStyles();

  return (
    <Box>
      <PageHeader title="Student Annoucement" />

      <PageWrapper>
        <Grid container>
          <Grid item className={classes.flexible}>
            <StudentAnnouncementTable />
          </Grid>
          <Grid item className={classes.static}>
            <StudentAnnounceNotification />
          </Grid>
        </Grid>
      </PageWrapper>
    </Box>
  );
};

export default StudentAnnouncementPage;
