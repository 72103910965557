// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import {
  Paper,
  TableBody,
  TextField,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from '../../Reusable';
import {
  AddTeacherExamMarks,
  PostBulkDataI,
} from '../../../actions/TeacherDash/TeacherExamMarks/TeacherExamMarksAction';
import { RootStore } from '../../../store';
import { useTableStyles } from '../../Styles/TableStyles';
import { tblStyle } from '../../../dashboard/StudentDashboard/StudentDashboardStyles';
import UserAvatar from '../../Reusable/Profile/UserAvatar';
import getFullName from '../../utils/getFullName';
import { AddTeacherAPE } from '../../../actions/TeacherDash/APE/TeacherAPEAction';
import { APE } from '../../../common/json.constant';
import { Autocomplete } from '@material-ui/lab';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
// ----------------------------<END> module imports end ----------------------------------//
// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  tableTitle: SearchParamI | null;
}

interface SearchParamI {
  exam_info: string;
  grade: string;
  section: string;
}

interface HeadCellsI {
  id: string;
  label: string;
  filter?: boolean;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const AddAPETable = (props: PropsI) => {
  const { tableTitle } = props;
  const classes = useTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [marksDetail, setMarksDetail] = useState<any[]>([]);
  const [headCells, setHeadCells] = useState<any>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [updatedData, setUpdatedData] = useState<any>([]);
  const [updatedDatas, setUpdatedDatas] = useState<any>([]);
  const [editModeData, setEditModeData] = useState<any>([]);

  // -----------------<START> Pagination states and functions <START>-----------------------//

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //=============================== <START> REACT HOOKS <START> ===========================//
  const dispatch = useDispatch();
  const marksSelector = useSelector(
    (state: RootStore) => state.teacher_exam_marks
  );
  const apeSelector = useSelector(
    (state: RootStore) => state.teacher_ape.exam_apes
  );
  const marksLoading = useSelector(
    (state: RootStore) => state.exam_marks.loading
  );

  useEffect(() => {
    setLoading(marksLoading);
  }, [marksLoading]);

  useEffect(() => {
    if (apeSelector !== null) {
      setEditMode(false);
      // if (tableTitle) {
      setTableData(apeSelector?.student_ape_bases);
      // }
      const tempHeadCells = [
        {
          id: 'sn',
          label: 'S.N',
        },
        {
          id: 'student_name',
          label: 'Student Name/APE Factor',
          filter: true,
        },
      ];
      apeSelector?.ape_bases?.map((elem: any, index: string) => {
        tempHeadCells.push({ id: elem.id, label: elem.title, filter: false });
      });
      setHeadCells(tempHeadCells);
    }
  }, [apeSelector]);

  //=============================== <END> REACT HOOKS <END> ===========================//

  // -------------------------------<START> Event Handlers <START>------------------------//

  // let updatedData: any[] = [];

  const handleEditClick = () => {
    setEditMode(true);
    setEditModeData(apeSelector?.student_ape_bases);
    // updatedData = [];
  };

  const handleReset = () => {
    setEditMode(false);
    setUpdatedData([]);
    setUpdatedDatas([]);
    setEditModeData([]);
  };

  const handleSaveClick = () => {
    const data = updatedDatas;
    data.splice(0, 1);
    dispatch(AddTeacherAPE(data, tableTitle, handleReset));
  };

  const handleInputChange = (
    elem: any,
    index: number,
    jIndex: number,
    value: any,
    item_id: string
  ) => {
    console.log(index, jIndex);
    const tempUpdated = editModeData;
    const ape_factor = apeSelector?.ape_bases[jIndex];
    if (tempUpdated[index].ape_bases[jIndex]) {
      tempUpdated[index].ape_bases[jIndex].ape_mark = value;
    } else {
      tempUpdated[index].ape_bases.push({
        ape_base__title: ape_factor?.title,
        ape_mark: value,
      });
    }
    setEditModeData(tempUpdated);

    const ape_bases = elem?.ape_bases;
    const ape = apeSelector?.ape_bases[jIndex];
    const find = ape_bases?.find(
      (el: any) => el.ape_base__title === ape?.title
    );
    // console.log({ find });
    if (find !== undefined) {
      if (find.id !== null || undefined) {
        const updated = {
          id: find?.id,
          ape_mark: value,
          exam_info: tableTitle?.exam_info,
          ape_base: ape?.id,
          student_academic: elem.id,
        };
        setUpdatedData(updated);
      } else {
        const updated = {
          ape_mark: value,
          exam_info: tableTitle?.exam_info,
          ape_base: ape?.id,
          student_academic: elem.id,
        };
        setUpdatedData(updated);
      }
    } else {
      const updated = {
        ape_mark: value,
        exam_info: tableTitle?.exam_info,
        ape_base: ape?.id,
        student_academic: elem.id,
      };
      setUpdatedData(updated);
    }
  };

  useEffect(() => {
    if (updatedData) {
      const temp = updatedDatas;
      temp.push(updatedData);
      setUpdatedDatas(temp);
    }
  }, [updatedData]);

  // console.log({ updatedDatas });

  const getAPEvalue = (index: number, jIndex: number) => {
    const ape = apeSelector?.ape_bases[jIndex];
    const student_ape = apeSelector?.student_ape_bases[index];
    const ape_value = student_ape?.ape_bases?.find(
      (el: any) => el.ape_base__title === ape?.title
    );
    return ape_value?.ape_mark || '-';
  };

  const getDefaultAPEvalue = (index: number, jIndex: number) => {
    const ape = apeSelector?.ape_bases[jIndex];
    const student_ape = editModeData[index];
    const ape_value = student_ape?.ape_bases?.find(
      (el: any) => el.ape_base__title === ape?.title
    );
    return ape_value?.ape_mark;
  };

  // console.log({ editModeData });
  // console.log({ updatedData });

  // -------------------------------<END> Event Handlers <END>------------------------//
  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(item.student_first_name, item.student_last_name)}
        </StyledTableCell>
        {apeSelector?.ape_bases?.map((elem: string, jIndex: number) => {
          return (
            <>
              {editMode ? (
                <StyledTableCell align="center" className={classes.cell}>
                  {/* <TextField
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(
                        item,
                        index,
                        jIndex,
                        event.target.value,
                        item.id
                      )
                    }
                    defaultValue={getDefaultAPEvalue(index, jIndex)}
                    variant="outlined"
                    style={{ width: "50px" }}
                  /> */}
                  <FormControl variant="outlined">
                    <Select
                      native
                      style={{ width: '60px' }}
                      onChange={(event: any) =>
                        handleInputChange(
                          item,
                          index,
                          jIndex,
                          event.target.value,
                          item.id
                        )
                      }
                      value={getDefaultAPEvalue(index, jIndex)}
                    >
                      <option aria-label="0" />
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </Select>
                  </FormControl>
                </StyledTableCell>
              ) : (
                <StyledTableCell
                  align="center"
                  className={classes.cell}
                  key={jIndex}
                >
                  {getAPEvalue(index, jIndex)}
                </StyledTableCell>
              )}
            </>
          );
        })}
      </StyledTableRow>
    );
  };

  return (
    <>
      {apeSelector?.ape_bases && apeSelector?.student_ape_bases && (
        <Paper style={tblStyle}>
          <TableContainer
            label="Scored APE List"
            loading={false}
            items={tableData || []}
            disableSearch
            disablePagination
            headCells={headCells}
            getCustomTableRow={getCustomTableRow}
            // hiddenCols={[-1]}
          />
          {tableData?.length ? (
            <Grid container justifyContent="flex-end">
              <Grid item xs={3}>
                <Grid container justifyContent="flex-end">
                  {!editMode ? (
                    <Button
                      onClick={handleEditClick}
                      className={classes.addMarksButton}
                      variant="contained"
                      color="primary"
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      onClick={handleSaveClick}
                      className={classes.addMarksButton}
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                    >
                      Save
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Paper>
      )}
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default AddAPETable;
