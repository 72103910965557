export const GET_TEACHER_EXAM_SUBJECTS_LOADING =
  "GET_TEACHER_EXAM_SUBJECTS_LOADING";
export const GET_TEACHER_EXAM_SUBJECTS_ERROR =
  "GET_TEACHER_EXAM_SUBJECTS_ERROR";
export const GET_TEACHER_EXAM_SUBJECTS_SUCCESS =
  "GET_TEACHER_EXAM_SUBJECTS_SUCCESS";

export type GetTeacherExamSubjectsTypeI = {
  id: string;
  subject: string;
  subject_name: string;
  grade: string;
  section: string | null;
  exam_type: string;
  exam_type_display: string;
};

//===============================<START>GET CLASS ATTENDANCE<START>======================//

export interface GetTeacherExamSubjectsLoading {
  type: typeof GET_TEACHER_EXAM_SUBJECTS_LOADING;
}

export interface GetTeacherExamSubjectsError {
  type: typeof GET_TEACHER_EXAM_SUBJECTS_ERROR;
}

export interface GetTeacherExamSubjectsSuccess {
  type: typeof GET_TEACHER_EXAM_SUBJECTS_SUCCESS;
  payload: GetTeacherExamSubjectsTypeI[];
}
//===============================<END>GET CLASS ATTENDANCE<END>======================//

export type TeacherExamSubjectsDispatchTypes =
  | GetTeacherExamSubjectsLoading
  | GetTeacherExamSubjectsError
  | GetTeacherExamSubjectsSuccess;
