import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubjectGroupTable } from "../../../../../components";
import {
  GetSyllabus,
  DeleteUnitSyllabus,
} from "../../../../../actions/TeacherDash/Syllabus/SyllabusAction";
import { SyllabusTypeI } from "../../../../../actions/TeacherDash/Syllabus/SyllabusActionTypes";
import { GetTeacherSubject } from "../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectAction";
import { RootStore } from "../../../../../store";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from "../../../../../components/Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../../../../components/Reusable/Dialogs/DeleteDialog";
import Popups from "../../../../../components/Reusable/Dialogs/Popups";
import useTable from "../../../../../components/Reusable/useStudentTable";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import SyllabusEditUnit from "./SyllabusEditUnit";
import SyllabusViewUnit from "./SyllabusViewUnit";
import CustomBackdrop from "../../../../../components/Reusable/Inputs/CustomBackdrop";
import { useTableWithPagination } from "../../../../../components/Reusable";

interface HeadCellsI {
  id: string;
  label: string;
}

interface PropsI {
  subject: any;
  grade: any;
}

const headCells: HeadCellsI[] = [
  { id: "unit", label: "Unit" },
  { id: "name", label: "Unit Title" },
  { id: "teaching_hour", label: "Teaching Hour" },
  { id: "actions", label: "Actions" },
];

const SyllabusTable = (props: PropsI) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { subject, grade } = props;

  const [tableData, setTableData] = useState<SyllabusTypeI[]>([]);
  const [item, setItem] = useState<SyllabusTypeI>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [openCreateModalPopup, setOpenCreateModalPopup] = useState(false);
  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [indexN, setIndex] = useState<number>();
  const [loading, setLoading] = useState(false);

  const syllabusSelector = useSelector(
    (state: RootStore) => state.teacher_syllabus
  );

  useEffect(() => {
    subject && dispatch(GetSyllabus(grade, subject.id, 1));
  }, [subject, grade]);

  useEffect(() => {
    const data = syllabusSelector.add_unit.results;
    setLoading(syllabusSelector.loading);
    if (data) {
      setTableData(data);
    }
    // data && sortTable(data);
  }, [syllabusSelector]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const handleDeleteModal = (value: boolean, item: SyllabusTypeI) => {
    setItem(item);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && item?.id != undefined && dispatch(DeleteUnitSyllabus(item.id));
    setDeleteModalOpen(false);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleEditClicked = (data: SyllabusTypeI, index: number) => {
    setItem(data);
    setIndex(index + 1);
    setOpenCreateModalPopup(true);
  };

  const handleViewClicked = (data: SyllabusTypeI, index: number) => {
    setItem(data);
    setIndex(index + 1);
    setOpenViewModalPopup(true);
  };

  const handleCreateModalClose = (value: boolean) => {
    setOpenCreateModalPopup(value);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const sortTable = (tableParam: SyllabusTypeI[] = tableData) => {
    const sortArray = [...tableParam].sort(
      (a: SyllabusTypeI, b: SyllabusTypeI) => {
        if (a?.id > b?.id) return 1;
        else if (b?.id > a?.id) return -1;
        return 0;
      }
    );

    setTableData(sortArray);
  };

  const handlePaginationChange = (paginationIndex: number) => {
    if (subject?.subject) {
      dispatch(GetSyllabus(grade, subject?.subject, paginationIndex));
    }
  };

  const getCustomTableRow = (item: SyllabusTypeI, index: number) => {
    return (
      <StyledTableRow key={index} hover>
        <StyledTableCell align="center" className={classes.newcell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.teaching_hours}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          <ItemViewButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewClicked(item, index)
            }
          />
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item, index)
            }
          />
          <ItemDeleteButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <CustomBackdrop open={loading} />
      <TableContainer
        label=""
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        disableSearch
        disablePrint
        forPagination={syllabusSelector?.add_unit}
        handlePaginationChange={handlePaginationChange}
        hiddenCols={[-1]}
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this Unit?"
        okText="Delete"
      />
      <Popups
        openPopup={openCreateModalPopup}
        setOpenPopup={setOpenCreateModalPopup}
        onClose={handleCreateModalClose}
        fullWidth={true}
        maxWidth="md"
        title="Create Unit"
        colored
      >
        <SyllabusEditUnit
          EditItem={item}
          indexN={indexN}
          onClose={handleCreateModalClose}
        />
      </Popups>
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        maxWidth="xs"
        title="View Unit"
        colored
      >
        <SyllabusViewUnit item={item} indexN={indexN} />
      </Popups>
    </>
  );
};

export default SyllabusTable;
