import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_MY_COMPLAIN_LOADING,
  GET_STUDENT_MY_COMPLAIN_ERROR,
  GET_STUDENT_MY_COMPLAIN_SUCCESS,
  ADD_STUDENT_MY_COMPLAIN_LOADING,
  ADD_STUDENT_MY_COMPLAIN_SUCCESS,
  ADD_STUDENT_MY_COMPLAIN_ERROR,
  DELETE_STUDENT_MY_COMPLAIN_SUCCESS,
  DELETE_STUDENT_MY_COMPLAIN_ERROR,
  DELETE_STUDENT_MY_COMPLAIN_LOADING,
  UPDATE_STUDENT_MY_COMPLAIN_LOADING,
  UPDATE_STUDENT_MY_COMPLAIN_SUCCESS,
  UPDATE_STUDENT_MY_COMPLAIN_ERROR,
  StudentMyComplainDispatchTypes,
  StudentMyComplainII,
} from "./MyComplainsActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { STUDENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetStudentMyComplains =
  () =>
  async (dispatch: Dispatch<StudentMyComplainDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_MY_COMPLAIN_LOADING,
      });

      const res = await axios.get(
        `${STUDENT_API_URL}/complain/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STUDENT_MY_COMPLAIN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_MY_COMPLAIN_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "My Complains Loading Failed ",
        },
      });
    }
  };

export const AddStudentMyComplain =
  (data: StudentMyComplainII) =>
  async (dispatch: Dispatch<StudentMyComplainDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_STUDENT_MY_COMPLAIN_LOADING,
      });

      const res = await axios.post(
        `${STUDENT_API_URL}/complain/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_STUDENT_MY_COMPLAIN_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "My Complain Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_STUDENT_MY_COMPLAIN_ERROR,
        payload: error.response && error.response?.data,
      });
    }
  };

export const UpdateStudentMyComplain =
  (id: number, data: any) =>
  async (dispatch: Dispatch<StudentMyComplainDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_STUDENT_MY_COMPLAIN_LOADING,
      });

      const res = await axios.put(
        `${STUDENT_API_URL}/complain/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_STUDENT_MY_COMPLAIN_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "My Complain Updated Successfully",
        },
      });
    } catch (error: any) {
      // console.log({ error });
      dispatch({
        type: UPDATE_STUDENT_MY_COMPLAIN_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "My Complain Update Failed",
        },
      });
    }
  };

export const DeleteStudentMyComplain =
  (id: number) =>
  async (dispatch: Dispatch<StudentMyComplainDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_STUDENT_MY_COMPLAIN_LOADING,
      });

      const res = await axios.delete(
        `${STUDENT_API_URL}/complain/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_STUDENT_MY_COMPLAIN_SUCCESS,
        payload: { id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "My Complain Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_STUDENT_MY_COMPLAIN_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "My Complain Delete Failed",
        },
      });
    }
  };
