import React from 'react';
import { SettingSidebar, Miscellaneous } from '../../../components';
import AccountSettingsSidebar from '../../../components/GeneralSetting/AccountantSettingsSidebar';
import InvoiceForm from '../../../components/GeneralSetting/Miscellaneous/InvoiceForm';
import ReceiptForm from '../../../components/GeneralSetting/Miscellaneous/ReceiptForm';
import SubModuleLayout from '../../../components/Reusable/Layouts/SubModuleLayout';

const AccountantGeneralSettingsPage = () => {
  const nav = <AccountSettingsSidebar settings />;
  return (
    <SubModuleLayout sideNav={nav}>
      <ReceiptForm />
      <InvoiceForm />
    </SubModuleLayout>
  );
};

export default AccountantGeneralSettingsPage;
