import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { TakeStudentAttendance } from '../../../../actions/StudentDash/StudentLiveClass/StudentLiveAction';
import { createStyles, makeStyles } from '@material-ui/core';
import { RootStore } from '../../../../store';
import { URL } from '../../../../config/SERVER_API_URLS';
import { dateConverterAdToBs } from '../../../../components/utils/dateConverter';
import { CUR_NEPALI_DATE } from '../../../../components/utils/nepaliDateUtils';

const useStyles = makeStyles(() =>
  createStyles({
    customRoot: {
      // border: "5px solid rgba(0, 234, 211, 0.3)",
      '&.MuiDialog-paperScrollPaper': {
        border: '1px solid #00ead3',
      },
    },
    dialogTitle: {
      color: '#fff',
      background: 'rgb(79 95 158)',
      marginBottom: '10px',
    },
  })
);

interface UserI {
  name: string;
}

export default function TakeAttendanceAlert(props: any) {
  const dispatch = useDispatch();
  const { open, setOpen, item } = props;

  const classes = useStyles();

  const [user, setUser] = useState<UserI | null>(null);
  const [warning, setWarning] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const auth = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    if (auth) {
      setUser({ name: auth.user.first_name + ' ' + auth.user.last_name });
    }
  }, [auth]);

  const handleTakeAttendance = () => {
    const newDate = dateConverterAdToBs(item.modified_on);

    dispatch(TakeStudentAttendance(item.id));
    if (isNaN(parseInt(item.link))) {
      window.open(item.link);
    } else {
      window.open(`${URL}online-class.html?mid=${item.link}`);
      if (user?.name) {
        localStorage.setItem('user', user.name);
        // localStorage.setItem("user_photo", teacherProfileState.photo);
        localStorage.setItem('home_url', window.location.origin);
      }
    }

    // validation
    // if (newDate === CUR_NEPALI_DATE) {
    //   dispatch(TakeStudentAttendance(item.id));
    //   window.open(`${URL}online-class.html?mid=${item.link}`);
    //   if (user?.name) {
    //     localStorage.setItem("user", user.name);
    //     // localStorage.setItem("user_photo", teacherProfileState.photo);
    //     localStorage.setItem("home_url", window.location.origin);
    //   }
    // } else {
    //   setWarning("Please wait... First let the teacher joing the class");
    // }
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.customRoot}>
          <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
            {'Would you Like To Take Attendance and Go?'}
          </DialogTitle>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => handleTakeAttendance()}
              color="primary"
            >
              Take Attendance and Go
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
        {/* <div> {warning && warning} </div> */}
      </Dialog>
    </div>
  );
}
