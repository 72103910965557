import React, { useState, useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import StaffCard, {
  StaffCardTypeI,
} from "../../../../../../components/Reusable/Dialogs/StaffCard.Modal";
import formatDate from "../../../../../../components/utils/formatDate";
import GetStatus from "../../../../../../common/GetStatus";
import { Clear, Done } from "@material-ui/icons";

const TeacherMeetingView = (props: any) => {
  const [staff, setStaff] = useState<StaffCardTypeI | null>(null);

  useEffect(() => {
    const item: StaffCardTypeI = {
      name: props.items.name,
      avatar: "",
      id: "123",
      designation: "Teacher",
    };

    setStaff(item);
  }, []);

  const { name, meeting_type, meet_date, meet_time, description, status } =
    props.items;

  const handleClick = (status: string) => {
    props.onActionConfirmed(true, status);
  };

  return (
    <>
      <div>
        {staff && <StaffCard staff={staff} />}
        <Divider style={{ marginBottom: "20px" }} />
        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={4}>
            Meeting Type:
          </Grid>

          <Grid item xs={8}>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
              {meeting_type}
            </Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={4}>
            Meeting Date:
          </Grid>

          <Grid item xs={8}>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
              {formatDate(meet_date)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={4}>
            Meeting Time:
          </Grid>

          <Grid item xs={8}>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
              {meet_time}
            </Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={4}>
            Meeting Status:
          </Grid>

          <Grid item xs={8}>
            <GetStatus status={status} />
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={4}>
            Description:
          </Grid>

          <Grid item xs={8}>
            {description || "No Description"}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          style={{ marginTop: "15px", marginBottom: "5px" }}
        >
          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Clear />}
              style={{ backgroundColor: "#E96C5A" }}
              onClick={() => props.handleClose()}
            >
              Close
            </Button>
          </Grid>

          {/* <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Done />}
              style={{ backgroundColor: "#3FC581" }}
              onClick={() => handleClick("A")}
            >
              Approved
            </Button>
          </Grid> */}
        </Grid>
      </div>
    </>
  );
};

export default TeacherMeetingView;
