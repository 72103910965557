export const GET_LIVE_CLASS_LOADING = "GET_LIVE_CLASS_LOADING";
export const GET_LIVE_CLASS_ERROR = "GET_LIVE_CLASS_ERROR";
export const GET_LIVE_CLASS_SUCCESS = "GET_LIVE_CLASS_SUCCESS";

export interface ParentLiveClassTypeI {
  id?: number;
  subject: number;
  teacher_first_name?: string | undefined;
  teacher_last_name?: string | undefined;
  subject_name?: string;
  class_date: string;
  start_time: string;
  end_time: string;
  grade: number;
  section: number;
  academic_session?: number;
  link: string;
  is_completed?: boolean;
  is_upcomming?: boolean;
  is_ongoing?: boolean;
}

//===============================<START>GET LIVE CLASSES<START>======================//

export interface GetLiveClassLoading {
  type: typeof GET_LIVE_CLASS_LOADING;
}

export interface GetLiveClassError {
  type: typeof GET_LIVE_CLASS_ERROR;
}

export interface GetLiveClassSuccess {
  type: typeof GET_LIVE_CLASS_SUCCESS;
  payload: ParentLiveClassTypeI[];
}

//===============================<END>GET LIVE CLASSES<END>======================//
export type LiveClassDispatchTypes =
  | GetLiveClassLoading
  | GetLiveClassError
  | GetLiveClassSuccess;
