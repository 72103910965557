import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "@material-ui/core";
import styled from "styled-components";
import ReactToPrint from "react-to-print";
import { MarksLedgerI } from "../../../rtk/features/exam/marksLedger/marksLedger";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import MarkSheet from "./MarkSheetTemp";

const Container = styled.div`
  padding: 20px;
`;

interface GeneralInfoI {
  id: number;
  logo: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
}

interface DialogProps {
  open: boolean;
  onClose: () => void;
  studentId: string[];
}

const PrintPreview = (props: DialogProps) => {
  const { onClose, open, studentId } = props;
  const printRef = useRef<HTMLDivElement | null>(null);

  const [generalInfo, setGeneralInfo] = useState<GeneralInfoI | null>(null);
  const [marksheets, setMarksheets] = useState<MarksLedgerI[]>([]);

  const { ledger } = useSelector((state: RootStore) => state.marksLedger);
  // const { general_info } = useSelector(
  //   (state: RootStore) => state.general_info
  // );

  useEffect(() => {
    const data = localStorage.getItem("hamro_general_info");

    if (data) {
      setGeneralInfo(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    if (ledger) {
      const result = ledger.marksLedger.filter((el) =>
        studentId.includes(el.student_academic__id)
      );

      setMarksheets(result);
    }
  }, [studentId]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="print-dialog"
      open={open}
      maxWidth="lg"
    >
      <Container>
        <ReactToPrint
          trigger={() => (
            <p
              style={{
                backgroundColor: "#132E98",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "5px 0px",
                cursor: "pointer",
              }}
            >
              Print This Out !
            </p>
          )}
          content={() => printRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
        <div ref={printRef}>
          {marksheets.map((el, index) => (
            <MarkSheet
              key={index + 1}
              schoolInfo={generalInfo}
              marksheet={el}
              gradeInfo={
                ledger
                  ? {
                      grade: ledger.grade_info,
                      totalStudents: ledger.total_students,
                    }
                  : null
              }
            />
          ))}
        </div>
      </Container>
    </Dialog>
  );
};

export default PrintPreview;
