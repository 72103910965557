import {
  GET_CHILDREN_ACADEMIC_INFO_LOADING,
  GET_CHILDREN_ACADEMIC_INFO_ERROR,
  GET_CHILDREN_ACADEMIC_INFO_SUCCESS,
  GET_CHILDREN_TIMETABLE_LOADING,
  GET_CHILDREN_TIMETABLE_ERROR,
  GET_CHILDREN_TIMETABLE_SUCCESS,
  ChildrenAcademicInfoDispatchTypes,
  ChildrenAcademicInfoI,
  ChildrenTimeTableI,
} from "../../actions/ParentsDash/ChildrenAcademicInfo/ChildrenAcademicInfoActionTypes";

interface InitialStateI {
  loading: boolean;
  children_academic_info: ChildrenAcademicInfoI[];
  timeTable: ChildrenTimeTableI[];
}

const initialState: InitialStateI = {
  loading: false,
  children_academic_info: [],
  timeTable: [],
};

const ChildrenAcademicInfoReducer = (
  state: InitialStateI = initialState,
  action: ChildrenAcademicInfoDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_CHILDREN_ACADEMIC_INFO_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CHILDREN_ACADEMIC_INFO_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CHILDREN_ACADEMIC_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        children_academic_info: action.payload,
      };

    case GET_CHILDREN_TIMETABLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CHILDREN_TIMETABLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CHILDREN_TIMETABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        timeTable: action.payload,
      };

    default:
      return state;
  }
};

export default ChildrenAcademicInfoReducer;
