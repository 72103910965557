import { Button, Grid, Paper, Typography } from '@material-ui/core';
import {
  Add,
  DeleteForever,
  KeyboardArrowRight,
  Send,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../../../../actions/SnackbarAction';
import {
  AddUnitSyllabus,
  DeleteSubUnit,
  GetSubUnit,
  UpdateUnitSyllabus,
} from '../../../../../actions/TeacherDash/Syllabus/SyllabusAction';
import {
  subArrayI,
  SubUnitTypeI,
  SyllabusTypeI,
} from '../../../../../actions/TeacherDash/Syllabus/SyllabusActionTypes';
import { TeacherSubjectsI } from '../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes';
import { GetTeachingClass } from '../../../../../actions/TeacherDash/TeachingClass/TeachingClassAction';
import { TeachingClassTypeI } from '../../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes';
import { RootStore } from '../../../../../store';
import CustomizedTextField from '../../../../../components/Reusable/Inputs/TextField';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import { useTableStyles } from '../../../../../components/Styles/TableStyles';

const SyllabusEditUnit = (props: any) => {
  const { EditItem, onClose, indexN } = props;
  const classes = useFormStyles();
  const localClass = useTableStyles();
  const dispatch = useDispatch();

  const { register, handleSubmit, setValue, errors, reset } = useForm<any>({
    mode: 'onChange',
  });

  const [selectedClass, setSelectedClass] = useState<TeachingClassTypeI | null>(
    null
  );
  const [classChoices, setClassChoices] = useState<TeachingClassTypeI[]>([]);

  const [selectedSubjects, setSelectedSubjects] =
    useState<TeacherSubjectsI | null>(null);
  const [subjectChoices, setSubjectChoices] = useState<TeacherSubjectsI[]>([]);

  const [unit, setUnit] = useState<SyllabusTypeI>();
  const [subUnit, setSubUnit] = useState<any>([]);

  // * ------------------------<START> REDUX MANAGEMENT <START>  --------------------------------------------*//

  const classSelector = useSelector((state: RootStore) => state.teaching_class);
  const subjectSelector = useSelector(
    (state: RootStore) => state.teacher_subject
  );
  const syllabusSelector = useSelector(
    (state: RootStore) => state.teacher_syllabus
  );

  const subUnitNewSelector = useSelector(
    (state: RootStore) => state.teacher_syllabus.sub_unit
  );

  useEffect(() => {
    dispatch(GetTeachingClass());
  }, []);

  useEffect(() => {
    const classes = classSelector.teaching_class;
    setClassChoices(classes);
  }, [classSelector]);

  useEffect(() => {
    const subjects = subjectSelector.subjects;
    setSubjectChoices(subjects);
  }, [subjectSelector]);

  useEffect(() => {
    setSelectedClass(
      classChoices.find(
        (childItem: TeachingClassTypeI) =>
          childItem.id === EditItem.academic_class
      ) || classChoices[0]
    );
    setSelectedSubjects(
      subjectChoices.find(
        (childItem: TeacherSubjectsI) => childItem.subject === EditItem.subject
      ) || subjectChoices[0]
    );
  }, [classSelector, subjectSelector]);

  useEffect(() => {
    if (EditItem) {
      setUnit({
        id: EditItem.id,
        name: EditItem.name,
        teaching_hours: EditItem.teaching_hours,
        subject: EditItem.subject,
        academic_class: EditItem.academic_class,
      });
      setValue('name', EditItem.name);
      setValue('teaching_hours', EditItem.teaching_hours);
    }
    EditItem && dispatch(GetSubUnit(EditItem.id));
  }, [EditItem]);

  useEffect(() => {
    const requireSubUnit: any = [];
    subUnitNewSelector?.map((item: any) => {
      requireSubUnit.push({
        id: item.id,
        sub_unit: item.sub_unit,
        title: item.title,
      });
    });
    setSubUnit(requireSubUnit);
  }, [syllabusSelector]);

  // * ------------------------ <END>  REDUX MANAGEMENT <END>  --------------------------------------------*//

  let SubUnitTempData: any[] = [];

  useEffect(() => {
    SubUnitTempData = subUnit;
  }, [subUnit]);

  const handleSubUnitChange = (a: number, subUnitValue: string) => {
    const temp = { title: subUnitValue, sub_unit: a + 1 };
    setSubUnit(
      subUnit.map((item: any, index: number) => (index === a ? temp : item))
    );
  };

  const handleDeleteRecord = (sub_itemID: any, del_index: number) => {
    if (sub_itemID == undefined) {
      let newData: subArrayI[] = SubUnitTempData.length
        ? SubUnitTempData
        : subUnit;
      newData = newData.filter(
        (elem: subArrayI, index: number) => index != del_index
      );
      setSubUnit(newData);
    } else {
      dispatch(DeleteSubUnit(sub_itemID, EditItem.id));
    }
  };

  const handleReorder = (item: subArrayI[]) => {
    const temporaryData: subArrayI[] = [];
    item.map((elem: subArrayI, index: number) => {
      temporaryData.push({ ...elem, id: (index + 1).toString() });
    });
    return temporaryData;
  };

  const handleAddSubUnitChange = (event: any) => {
    event.preventDefault();
    const data = SubUnitTempData.length ? SubUnitTempData : subUnit;

    const checkEmptyRow = (callback: (value: boolean) => void) => {
      let isEmpty = false;
      data.map((element: any) => {
        if (element.title === '') {
          isEmpty = true;
          return;
        }
      });

      callback(isEmpty);
    };

    checkEmptyRow((foundEmpty) => {
      if (foundEmpty) {
        dispatch(setSnackbar(true, 'warning', 'Fields cannot be empty'));
      } else {
        const subID = subUnit.length + 1;
        if (EditItem) {
          setSubUnit(
            data.concat({
              // id: subID,
              sub_unit: subID.toString(),
              title: '',
            })
          );
        }
      }
    });
  };

  // const handleReorder = (item: any) => {
  //   const temporaryData: any[] = [];
  //   item.map((elem: any, index: number) => {
  //     temporaryData.push({ ...elem, id: index + 1 });
  //   });
  //   return temporaryData;
  // };

  const onFormSubmit = (data: SyllabusTypeI) => {
    const new_data = {
      id: EditItem.id,
      academic_class: selectedClass?.id,
      subject: selectedSubjects?.subject,
      teaching_hours: data.teaching_hours,
      name: data.name,
    };
    new_data && setUnit(new_data);

    const checkEmptyRow = (callback: (value: boolean) => void) => {
      let isEmpty = false;
      subUnit.map((element: any) => {
        if (element.title === '') {
          isEmpty = true;
          return;
        }
      });

      callback(isEmpty);
    };

    checkEmptyRow((foundEmpty) => {
      if (foundEmpty) {
        dispatch(setSnackbar(true, 'warning', 'Fields cannot be empty'));
      } else {
        // const new_sub_unit: any = [];
        // subUnit.map((item: any) => {
        //   new_sub_unit.push({ sub_unit: item.sub_unit, title: item.title });
        // });
        const submit_data = {
          unit: new_data,
          sub_unit: subUnit,
        };
        EditItem && dispatch(UpdateUnitSyllabus(EditItem?.id, submit_data));
      }
    });

    onClose();
  };

  return (
    <>
      <Paper elevation={0}>
        <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h6" color="primary">
                Class: {selectedClass?.grade_name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" color="primary">
                Subject: {selectedSubjects?.subject_name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={3}>
              <Grid
                container
                direction="row"
                style={{
                  marginBottom: '10px',
                  marginTop: '25px',
                }}
              >
                <KeyboardArrowRight />
                <Typography variant="body1" style={{ fontSize: '15px' }}>
                  Unit {indexN}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <CustomizedTextField
                label="Unit Title"
                name="name"
                type="text"
                required
                error={errors['name'] ? 'Required Field.' : ''}
                placeholder="Unit Title"
                inputRef={register({ required: true })}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomizedTextField
                label="Teaching Hour"
                name="teaching_hours"
                type="text"
                error={errors['teaching_hours'] ? 'Required Field.' : ''}
                placeholder="Teaching Hour"
                inputRef={register({ required: true })}
              />
            </Grid>
            <Grid
              item
              style={{
                marginTop: '22px',
              }}
              xs={2}
            >
              <Button
                type="submit"
                style={{ minWidth: '160px' }}
                fullWidth
                startIcon={<Add />}
                variant="outlined"
                color="primary"
                onClick={handleAddSubUnitChange}
              >
                Add Sub Unit
              </Button>
            </Grid>
          </Grid>
          {subUnit &&
            subUnit.map((item: any, index: number) => {
              return (
                <Grid key={index} container direction="row" spacing={3}>
                  <Grid item xs={2}>
                    <Grid
                      container
                      direction="row"
                      style={{
                        marginBottom: '10px',
                        marginTop: '25px',
                      }}
                    >
                      <KeyboardArrowRight />
                      <Typography variant="body1" style={{ fontSize: '15px' }}>
                        Unit {indexN}.{index + 1}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <CustomizedTextField
                      label="Sub Unit Title"
                      name="sub_unit_title"
                      type="text"
                      placeholder="Sub Unit Title"
                      value={subUnit[index].title}
                      onChange={(event: any) =>
                        handleSubUnitChange(index, event.target.value)
                      }
                      inputRef={register({ required: true })}
                    />
                  </Grid>
                  {index + 1 == subUnit.length && (
                    <>
                      <Grid
                        key={index}
                        item
                        xs={1}
                        style={{ marginTop: '25px' }}
                      >
                        <a
                          style={{ cursor: 'pointer', padding: '2px' }}
                          onClick={(
                            event: React.MouseEvent<
                              HTMLAnchorElement,
                              MouseEvent
                            >
                          ) => handleDeleteRecord(item.id, index)}
                        >
                          <DeleteForever className={localClass.deleteIcon} />
                        </a>
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}
          <Grid container justifyContent="flex-end">
            <Grid item xs={4}>
              <Button
                startIcon={<Send />}
                variant="contained"
                color="primary"
                type="submit"
                style={{ width: '100%', marginBottom: '20px' }}
                // onClick={handleSaveSubUnit}
              >
                Save All
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default SyllabusEditUnit;
