import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { GetPreTimeTables } from "../../actions/Academics/PreTimeTable/PreTimeTableAction";
import { GetTimeTables } from "../../actions/Academics/TimeTable/TimeTableAction";
import { AcademicSidebar } from "../../components";
import TimeTablePreview from "../../components/Academic/CreateTimeTable/TimeTablePreview";
import PreTimeTableList from "../../components/Academic/PreTimeTable/PreTimeTableList";
import SearchTimetable from "../../components/Academic/TimeTable/SearchTimeTable";
import TimetableSchedule from "../../components/Academic/TimeTable/TimetableSchedule";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const TimetablePage = () => {
  const nav = <AcademicSidebar timeTable />;
  const [formData, setFormData] = useState<{
    classId: string | null;
    gradeId: string | null;
    section: string | null;
    hasSection: boolean;
  }>({ classId: null, gradeId: null, section: null, hasSection: false });

  const fetchSearchData = (value: {
    classId: string | null;
    gradeId: string | null;
    section: string | null;
    hasSection: boolean;
  }) => {
    setFormData(value);
  };

  return (
    <SubModuleLayout sideNav={nav}>
      <Grid item xs={12}>
        <SearchTimetable searchData={fetchSearchData} />
        <TimeTablePreview searchData={formData} />
      </Grid>
    </SubModuleLayout>
  );
};

export default TimetablePage;
