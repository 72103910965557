export const GET_CHILDREN_ACADEMIC_INFO_LOADING =
  "GET_CHILDREN_ACADEMIC_INFO_LOADING";
export const GET_CHILDREN_ACADEMIC_INFO_ERROR =
  "GET_CHILDREN_ACADEMIC_INFO_ERROR";
export const GET_CHILDREN_ACADEMIC_INFO_SUCCESS =
  "GET_CHILDREN_ACADEMIC_INFO_SUCCESS";

export const GET_CHILDREN_TIMETABLE_LOADING = "GET_CHILDREN_TIMETABLE_LOADING";
export const GET_CHILDREN_TIMETABLE_ERROR = "GET_CHILDREN_TIMETABLE_ERROR";
export const GET_CHILDREN_TIMETABLE_SUCCESS = "GET_CHILDREN_TIMETABLE_SUCCESS";

export interface ChildrenAcademicInfoI {
  id: string;
  student: string;
  student_first_name: string;
  student_last_name: string;
  grade: string;
  grade_name: string;
  section: string | null;
  section_name: string | null;
  roll_number: number | null;
  house: number | null;
  last_school_name: string | null;
  last_percentage: string | null;
  last_grade: number | null;
  sports: string;
  academic_session: number;
}

export interface ChildrenTimeTableI {
  id: string;
  day: number;
  start_time: string;
  end_time: string;
  grade: number;
  grade_name: string;
  section: number | null;
  section_name: string | null;
  teacher_first_name: string;
  teacher_last_name: string;
  teacher: number;
  subject_name: string;
}

//===============================<START>GET CHILDREN ACADeMIC INFO<START>======================//

export interface GetChildrenAcademicInfoLoading {
  type: typeof GET_CHILDREN_ACADEMIC_INFO_LOADING;
}

export interface GetChildrenAcademicInfoError {
  type: typeof GET_CHILDREN_ACADEMIC_INFO_ERROR;
}

export interface GetChildrenAcademicInfoSuccess {
  type: typeof GET_CHILDREN_ACADEMIC_INFO_SUCCESS;
  payload: ChildrenAcademicInfoI[];
}

//===============================<END>GET CHILDREN ACADeMIC INFO<END>======================//

//===============================<START>GET CHILDREN TIMETABLE<START>======================//

export interface GetChildrenTimeTableLoading {
  type: typeof GET_CHILDREN_TIMETABLE_LOADING;
}

export interface GetChildrenTimeTableError {
  type: typeof GET_CHILDREN_TIMETABLE_ERROR;
}

export interface GetChildrenTimeTableSuccess {
  type: typeof GET_CHILDREN_TIMETABLE_SUCCESS;
  payload: ChildrenTimeTableI[];
}

//===============================<END>GET CHILDREN TIMETABLE<END>======================//

export type ChildrenAcademicInfoDispatchTypes =
  | GetChildrenAcademicInfoLoading
  | GetChildrenAcademicInfoError
  | GetChildrenAcademicInfoSuccess
  | GetChildrenTimeTableLoading
  | GetChildrenTimeTableError
  | GetChildrenTimeTableSuccess;
