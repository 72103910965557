/* eslint-disable prefer-const */
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { stubTrue } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { ApplyFeeToStudents } from '../../../actions/Fees/FeeType/FeeAction';
import { setSnackbar } from '../../../actions/SnackbarAction';
import { FinanceSidebar } from '../../../components';
import AccountantFeeSettingsSidebar from '../../../components/Event/EventSidebar/AccountantFeeSettingsSidebar';
import {
  StudentSearchForm,
  FeeCategorySelect,
  MonthSelect,
  StudentSelectTable,
} from '../../../components/index';
import BackDropLoader from '../../../components/Reusable/BackDropLoader';
import SubModuleLayout from '../../../components/Reusable/Layouts/SubModuleLayout';
import {
  ActionRoleAccess,
  PageRoleAccess,
} from '../../../components/utils/rolesConfig';
import { RootStore } from '../../../store';

interface SelectedFeesI {
  id: string;
  title: string;
  isMonthly: boolean;
}

export default function AccountApplyFeePage() {
  const [fees, setFees] = useState<SelectedFeesI[]>([]);
  const [months, setMonths] = useState<string[]>([]);
  const [students, setStudents] = useState<string[]>([]);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [grade, setGrade] = useState<any | null>(null);
  const [section, setSection] = useState<any | null>(null);
  const [tempTableData, setTempTableData] = useState<any[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const gradeLoading = useSelector((state: RootStore) => state.class.loading);
  const feeLoading = useSelector((state: RootStore) => state.fee_type.loading);
  const studentLoading = useSelector(
    (state: RootStore) => state.student.loading
  );

  const authState = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    if (!gradeLoading && !feeLoading && !studentLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [gradeLoading, feeLoading, studentLoading]);

  const nav = <AccountantFeeSettingsSidebar applyFee />;

  const handleFeeChange = (fees: SelectedFeesI[]) => {
    setFees([...fees]);
  };

  const handleMonthChange = (ids: string[]) => {
    setMonths([...ids]);
  };

  const handleStudentChange = (ids: string[]) => {
    setStudents([...ids]);
  };

  const ClearData = () => {
    setGrade(null);
    setSection(null);
    setSelected([]);
  };

  const handleApplyFees = () => {
    if (fees.length && students.length) {
      let finalData: any[] = [];
      tempTableData?.map((elem: any) => {
        // console.log({ elem })
        let filtered__month: any[] = [];
        const allMonths =
          elem.applied_month?.length > 0
            ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
            : [];
        allMonths.forEach((item: any, index: number) => {
          if (elem.applied_month[index][item]) {
            filtered__month.push(item);
          }
        });
        finalData.push({
          id: elem.id,
          months: filtered__month,
          applied_months: elem.applied_month,
        });
      });

      const res = finalData.filter((item) => selected.includes(item.id));
      const finalValues = {
        students,
        fee_type: res,
      };
      const check__empty = res.filter(
        (elem: any) =>
          elem.months?.length === 0 && elem.applied_months?.length > 0
      );
      if (check__empty?.length > 0) {
        dispatch(
          setSnackbar(
            true,
            'warning',
            'Select at least one month for monthly fee(s)'
          )
        );
      } else {
        // console.log({ finalValues })
        setIsBtnLoading(stubTrue);
        dispatch(ApplyFeeToStudents(finalValues, ClearData));
      }
    } else {
      dispatch(setSnackbar(true, 'warning', 'Select Fees and Students'));
    }
  };

  if (!PageRoleAccess(authState?.role, 'FINANCE', 'apply_fee'))
    return <Redirect to="/" />;

  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <FeeCategorySelect
                    onFeeChange={handleFeeChange}
                    setTempTableData={setTempTableData}
                    tempTableData={tempTableData}
                    setTableData={setTableData}
                    tableData={tableData}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <StudentSelectTable
                isBtnLoading={isBtnLoading}
                setIsBtnLoading={setIsBtnLoading}
                onStudentChange={handleStudentChange}
                handleApplyFees={handleApplyFees}
                grade={grade}
                section={section}
                setSection={setSection}
                setGrade={setGrade}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <BackDropLoader open={loading} />
    </SubModuleLayout>
  );
}
