import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { Redirect } from 'react-router-dom';
import { Paper } from "@material-ui/core";
import useTable from "../../../../../components/Reusable/useStudentTable";
import { HeadCellsI } from "../../../../../actions";
import { tblStyle } from "../../../components/Styles/TeacherDashboardStyles";
import { ItemViewButton, ItemEditButton } from "../../../../../components/Reusable/Buttons/TableButton";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import { TeacherClassSubjectsI } from '../../../../../rtk/features/timetable/timetable'
import { RootStore } from "../../../../../store"
import { GetTeacherAssignment } from "../../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction"
import { Tupple, assignmentCategoryOptions } from '../../../../../common/json.constant';

const headCells: HeadCellsI[] = [
    { id: "sn", label: "S.N" },
    { id: "title", label: "Title" },
    { id: "assign_category", label: "Assignment Category" },
    { id: "submission_type", label: "Submission Type" },
    { id: "submitted_count", label: "Submitted By" },
    { id: "assign", label: "Assign Date" },
    { id: "due", label: "Due Date" },
    { id: "action", label: "Action" },
];

const SingleSubjectTable = ({ data }: { data: TeacherClassSubjectsI }) => {
    const dispatch = useDispatch();
    const classes = useTableStyles();
    const { StyledTableCell, StyledTableRow, TableContainer } =
        useTable(headCells);

    const [tableData, setTableData] = useState<any>([]);
    const [assignmentID, setAssignmentID] = useState<string | null>(null);
    const [assignmentViewClicked, setAssignmentViewClicked] = useState<boolean>(false);
    const [assignmentUpdateClicked, setAssignmentUpdateClicked] = useState<boolean>(false);

    const { assignments, loading } = useSelector((state: RootStore) => state.teacher_assignment)

    useEffect(() => {
        if (data) {
            const section = data.classes?.section?.id ? data.classes?.section?.id : ""
            dispatch(GetTeacherAssignment(data.classes.grade, section, data.subject.id, null, null, true))
        }
    }, [data])

    useEffect(() => {
        if (assignments.length > 0) {
            const items = formatData(assignments)
            setTableData(items)
        } else {
            setTableData([])
        }
    }, [assignments])

    const formatData = (items: any[]) => {
        return items.map((item) => ({
            ...item,
            assign_on: new Date(item.assign_on).toLocaleString(),
            deadline: new Date(item.deadline).toLocaleString()
        }));
    };

    const handleAssignmentView = (item: any) => {
        setAssignmentID(item.id);
        setAssignmentViewClicked(true);
    }

    const handleAssignmentUpdate = (item: any) => {
        setAssignmentID(item.id);
        setAssignmentUpdateClicked(true);
    }

    if (assignmentUpdateClicked) {
        return <Redirect to={{
            pathname: `/assignment/edit/${assignmentID}`,
        }} />;
    }

    if (assignmentViewClicked) {
        return <Redirect to={{
            pathname: `/assignment/detail/view/${assignmentID}`,
        }} />;
    }

    const getCustomTableRow = (item: any, index: number) => {
        const assignCategory = assignmentCategoryOptions.filter((assign: Tupple) => item.assignment_type === assign.key)[0].value;
        return (
            <StyledTableRow key={item.id}>
                <StyledTableCell align="center" className={classes.cell}>
                    {index + 1}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.title}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {assignCategory}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.submission_type}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.submitted_students} / {item.total_students}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.assign_on}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.deadline}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    <ItemViewButton onClick={() => handleAssignmentView(item)} />
                    <ItemEditButton onClick={() => handleAssignmentUpdate(item)} />
                </StyledTableCell>
            </StyledTableRow>
        );
    };
    return (
        <Paper style={tblStyle}>
            <TableContainer
                label={data?.subject?.name}
                loading={loading}
                items={tableData}
                headCells={headCells}
                getCustomTableRow={getCustomTableRow}
                search_name="title"
                search_param='title'
            />
        </Paper>
    )
}

export default SingleSubjectTable