import {
  GET_STUDENT_DASHBOARD_DATA_LOADING,
  GET_STUDENT_DASHBOARD_DATA_ERROR,
  GET_STUDENT_DASHBOARD_DATA_SUCCESS,
  GET_STUDENT_DASHBOARD_DATE_LOADING,
  GET_STUDENT_DASHBOARD_DATE_ERROR,
  GET_STUDENT_DASHBOARD_DATE_SUCCESS,
  StudentDashboardDataDispatchTypes,
  StudentDashboardDataTypeI,
} from "../../actions/StudentDash/Dashboard/StudentDashboardActionTypes";

interface InitialStateI {
  loading: boolean;
  student_dashboard: StudentDashboardDataTypeI | null;
  online_class: StudentDashboardDataTypeI | null;
  errors: any;
}

const initialState: InitialStateI = {
  loading: false,
  student_dashboard: null,
  online_class: null,
  errors: null,
};

const StudentDashboardReducer = (
  state: InitialStateI = initialState,
  action: StudentDashboardDataDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STUDENT_DASHBOARD_DATA_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };

    case GET_STUDENT_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        errors: null,
        loading: false,
      };

    case GET_STUDENT_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        student_dashboard: action.payload,
      };
    case GET_STUDENT_DASHBOARD_DATE_LOADING:
      return {
        ...state,
        errors: null,
        loading: true,
      };

    case GET_STUDENT_DASHBOARD_DATE_ERROR:
      return {
        ...state,
        errors: null,
        loading: false,
      };

    case GET_STUDENT_DASHBOARD_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        online_class: action.payload,
      };

    default:
      return state;
  }
};

export default StudentDashboardReducer;
