export const GET_CHILDREN_ATTENDANCE_LOADING =
  "GET_CHILDREN_ATTENDANCE_LOADING";
export const GET_CHILDREN_ATTENDANCE_ERROR = "GET_CHILDREN_ATTENDANCE_ERROR";
export const GET_CHILDREN_ATTENDANCE_SUCCESS =
  "GET_CHILDREN_ATTENDANCE_SUCCESS";

export interface ChildrenAttendanceI {
  id: number;
  student: number;
  student_first_name: string;
  student_last_name: string;
  grade?: number;
  grade_name?: string;
  section?: number;
  section_name?: string;
  roll_number?: number;
  house?: number;
  last_school_name?: string;
  last_percentage?: string;
  last_grade?: number;
  sports?: string;
  academic_session?: number;
}

export interface QueryI {
  student_id: number | string;
  from_date: string;
  to_date: string;
}

//===============================<START>GET CHILDREN_ATTENDANCE<START>======================//

export interface ChildrenAttendanceLoading {
  type: typeof GET_CHILDREN_ATTENDANCE_LOADING;
}

export interface ChildrenAttendanceError {
  type: typeof GET_CHILDREN_ATTENDANCE_ERROR;
}

export interface ChildrenAttendanceSuccess {
  type: typeof GET_CHILDREN_ATTENDANCE_SUCCESS;
  payload: ChildrenAttendanceI;
}

//===============================<END>DELETE CHILDREN_ATTENDANCE<END>======================//

export type ChildrenAttendanceDispatchTypes =
  | ChildrenAttendanceLoading
  | ChildrenAttendanceError
  | ChildrenAttendanceSuccess;
