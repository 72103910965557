import { Box } from "@material-ui/core";
import React, { useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import ExamScheduleSearch from "./ExamScheduleSearch";
import ExamScheduleTable from "./ExamScheduleTable";

interface SearchDataI {
  grade: string;
  section: string;
}

const ExamSchedulePage = () => {
  const [searchParam, setSearchParam] = useState<SearchDataI | null>(null);
  const handleSearch = (data: SearchDataI | null) => {
    setSearchParam(data);
  };

  return (
    <>
      <Box>
        <PageHeader
          title="Exam Schedule"
          module={{ name: "Academics", to: "/exam-schedules" }}
        />
        <PageWrapper>
          <ExamScheduleSearch onSearch={handleSearch} />
          <ExamScheduleTable searchTitle={searchParam} />
        </PageWrapper>
      </Box>
    </>
  );
};

export default ExamSchedulePage;
