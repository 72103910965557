import {
  GET_PARENT_EVENT_LOADING,
  GET_PARENT_EVENT_ERROR,
  GET_PARENT_EVENT_SUCCESS,
  ParentEventDispatchTypes,
  ParentEventTypeI,
} from "../../actions/ParentsDash/ParentEvent/ParentEventActionTypes";

interface InitialStateI {
  loading: boolean;
  parents_event: ParentEventTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  parents_event: [],
};

const ParentEventReducer = (
  state: InitialStateI = initialState,
  action: ParentEventDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_PARENT_EVENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_PARENT_EVENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_PARENT_EVENT_SUCCESS:
      return {
        loading: false,
        parents_event: action.payload,
      };

    default:
      return state;
  }
};

export default ParentEventReducer;
