import {
  GET_PARENT_ANNOUNCEMENTS_LOADING,
  GET_PARENT_ANNOUNCEMENTS_ERROR,
  GET_PARENT_ANNOUNCEMENTS_SUCCESS,
  ParentAnnouncementsDispatchTypes,
} from "../../actions/ParentsDash/ParentAnnouncement/ParentAnnoucementActionTypes";

interface InitialStateI {
  loading: boolean;
  parent_announcements: any;
}

const initialState: InitialStateI = {
  loading: false,
  parent_announcements: [],
};

const ParentAnnouncementReducer = (
  state: InitialStateI = initialState,
  action: ParentAnnouncementsDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_PARENT_ANNOUNCEMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_PARENT_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_PARENT_ANNOUNCEMENTS_SUCCESS:
      return {
        loading: false,
        parent_announcements: action.payload,
      };

    default:
      return state;
  }
};

export default ParentAnnouncementReducer;
