import React from "react";
import { Box, Grid } from "@material-ui/core";
import { FullSubModuleLayout } from "../../../../components/Reusable/Layouts/SubModuleLayout";
import ParentsDashSidebar from "../../../../components/ParentsDash/ParentsDashSidebar";
import ParentAnnounceTable from "./Components/ParentAnnouncementTable";
import ParentAnnounceNotification from "./Components/ParentAnnounceNotification";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";

const ParentAnnouncementPage = () => {
  const nav = <ParentsDashSidebar Announcement />;

  return (
    <Box>
      <PageHeader title="Annoucement" />

      <PageWrapper>
        <Grid container>
          <Grid item xs={8}>
            <ParentAnnounceTable />
          </Grid>
          <Grid item xs={4}>
            <ParentAnnounceNotification />
          </Grid>
        </Grid>
      </PageWrapper>
    </Box>
  );
};
export default ParentAnnouncementPage;
