import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useCardStyles } from "../../../../Styles/CardStyles";
import { dateConverterAdToBs } from "../../../../utils/dateConverter";

export default function StaffAcademic(props: any) {
  const classes = useCardStyles();

  const rows = [
    createData(
      "Email Address:",
      props.staff?.user?.email ? props.staff.user.email : "None"
    ),
    createData("Contract Type:", props.staff?.staffs_academic_info?.contract_name),
    createData(
      "Department:",
      props.staff?.staffs_academic_info?.department_name
    ),
    createData("Experience in Years:", props.staff?.staffs_academic_info?.experience + " Years"),
    createData(
      "Highest Degree:",
      props.staff?.staffs_academic_info?.highest_degree
    ),
    createData("Blood Group:", props.staff?.blood_group_name),
    createData("Date of Birth:", props.staff?.date_of_birth && dateConverterAdToBs(props.staff?.date_of_birth)),
    createData("Religion:", props.staff?.religion_name),
    createData(
      "Working Days:",
      props.staff?.staffs_academic_info?.working_days + " days"
    ),
    // createData("Leave:", props.staff?.staffs_academic_info?.leave + " days"),
  ];

  function createData(name: string, infoData: string) {
    return { name, infoData };
  }

  return (
    <>
      <Paper className={classes.infobar} elevation={0}>
        <TableContainer className={classes.roota}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.table}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.infoData}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
