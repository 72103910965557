export const GET_PARENT_LEAVE_LOADING = "GET_PARENT_LEAVE_LOADING";
export const GET_PARENT_LEAVE_ERROR = "GET_PARENT_LEAVE_ERROR";
export const GET_PARENT_LEAVE_SUCCESS = "GET_PARENT_LEAVE_SUCCESS";

export const ADD_PARENT_LEAVE_LOADING = "ADD_PARENT_LEAVE_LOADING";
export const ADD_PARENT_LEAVE_ERROR = "ADD_PARENT_LEAVE_ERROR";
export const ADD_PARENT_LEAVE_SUCCESS = "ADD_PARENT_LEAVE_SUCCESS";

export const UPDATE_PARENT_LEAVE_LOADING = "UPDATE_PARENT_LEAVE_LOADING";
export const UPDATE_PARENT_LEAVE_ERROR = "UPDATE_PARENT_LEAVE_ERROR";
export const UPDATE_PARENT_LEAVE_SUCCESS = "UPDATE_PARENT_LEAVE_SUCCESS";

export const DELETE_PARENT_LEAVE_LOADING = "DELETE_PARENT_LEAVE_LOADING";
export const DELETE_PARENT_LEAVE_ERROR = "DELETE_PARENT_LEAVE_ERROR";
export const DELETE_PARENT_LEAVE_SUCCESS = "DELETE_PARENT_LEAVE_SUCCESS";

// export type LeaveTypeI = {
//   id: number;
//   name: string;
// };

export interface ParentLeaveI {
  id: string;
  first_name: string;
  last_name: string;
  leave_type: {
    id: string;
    name: string;
  };
  reason_for_leave: string;
  leave_from: string;
  leave_to: string;
  status: string;
  status_name: string;
  applied_date: string;
  student_academic: string;
  general_info: string;
}

export interface ParentLeaveII {
  leave_type: string;
  leave_from: string;
  leave_to: string;
  reason_for_leave: string;
  student_academic: string;
}

//===============================<START>GET PARENT_LEAVE<START>======================//

export interface GetLeaveLoading {
  type: typeof GET_PARENT_LEAVE_LOADING;
}

export interface GetLeaveError {
  type: typeof GET_PARENT_LEAVE_ERROR;
}

export interface GetLeaveSuccess {
  type: typeof GET_PARENT_LEAVE_SUCCESS;
  payload: ParentLeaveI[];
}

//===============================<END>GET PARENT_LEAVE<END>======================//

//===============================<START>ADD PARENT_LEAVE<START>======================//

export interface AddLeaveLoading {
  type: typeof ADD_PARENT_LEAVE_LOADING;
}

export interface AddLeaveError {
  type: typeof ADD_PARENT_LEAVE_ERROR;
}

export interface AddLeaveSuccess {
  type: typeof ADD_PARENT_LEAVE_SUCCESS;
  payload: ParentLeaveI;
}

//===============================<END>ADD PARENT_LEAVE<END>======================//

//===============================<START>UPDATE PARENT_LEAVE<START>======================//

export interface UpdateLeaveLoading {
  type: typeof UPDATE_PARENT_LEAVE_LOADING;
}

export interface UpdateLeaveError {
  type: typeof UPDATE_PARENT_LEAVE_ERROR;
}

export interface UpdateLeaveSuccess {
  type: typeof UPDATE_PARENT_LEAVE_SUCCESS;
  payload: ParentLeaveI;
}

//===============================<END>UPDATE PARENT_LEAVE<END>======================//

//===============================<START>DELETE PARENT_LEAVE<START>======================//

export interface DeleteLeaveLoading {
  type: typeof DELETE_PARENT_LEAVE_LOADING;
}

export interface DeleteLeaveError {
  type: typeof DELETE_PARENT_LEAVE_ERROR;
}

export interface DeleteLeaveSuccess {
  type: typeof DELETE_PARENT_LEAVE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE PARENT_LEAVE<END>======================//

export type LeaveDispatchTypes =
  | GetLeaveLoading
  | GetLeaveError
  | GetLeaveSuccess
  | AddLeaveLoading
  | AddLeaveError
  | AddLeaveSuccess
  | UpdateLeaveLoading
  | UpdateLeaveError
  | UpdateLeaveSuccess
  | DeleteLeaveLoading
  | DeleteLeaveError
  | DeleteLeaveSuccess;
