// ---------------- <START> module import start ------------------//
import React, { useState, useEffect } from "react";
import {
  Paper,
  TableBody,
  TextField,
  Grid,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { DeleteForeverOutlined, EditOutlined } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import ActionButton from "../../Reusable/Buttons/ActionButton";

import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  DeleteSection,
  GetSections,
} from "../../../actions/Academics/Section/SectionAction";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";

// -------------------<END> module imports end ----------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: TableDataI) => void;
  edit: { value: boolean; count: number };
}

interface TableDataI {
  id: string;
  name: string;
  created_by: string;
  general_info_name: string;
  count?: number;
}

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data starts---------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "section_name", label: "Section " },
  { id: "action", label: "Action" },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

// ---------------- <START> Section Table Component start ------------------//
const SectionTable = (props: PropsI) => {
  const { onEditData, edit } = props;
  const classes = useTableStyles();

  // section reducer
  const dispatch = useDispatch();
  const sectionState = useSelector((state: RootStore) => state.section);

  // Modal States
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  // States declaration
  const [tableData, setTableData] = useState<any>([]);
  const [editMode, setEditMode] = useState(false);

  //================================<START>LIFECYCLE HOOKS<START>=================================//

  useEffect(() => {
    edit && setEditMode(edit.value);
  }, [edit]);

  useEffect(() => {
    dispatch(GetSections());
  }, []);

  useEffect(() => {
    setLoading(sectionState.loading);
    setTableData(sectionState.sections);
  }, [sectionState]);

  //================================<END>LIFECYCLE HOOKS<END>=================================//

  const {
    StyledTableCell,
    StyledTableRow,

    TableContainer,
  } = useTable(headCells);

  // Function for handling action icons click event

  const handleEditClicked = (data: TableDataI) => {
    onEditData(data);
    setEditMode(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  // const onDeleteConfirmed = (confirmed: boolean) => {
  //   confirmed && itemId != null && dispatch(DeleteSection(itemId));
  //   setDeleteModalOpen(false);
  // };

  const getCustomTableRow = (item: TableDataI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          {/* <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Section List</Typography>
          </Grid>
        </Grid>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_param="name"
        />
      </Paper>
      {/* <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      /> */}
    </>
  );
};
// ---------------- <END> Section Table Components ends------------------//
export default SectionTable;
