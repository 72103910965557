import styled from "styled-components";
import { useHistory } from 'react-router-dom';
import { StudentMyTeacherTypeI } from "../../actions/StudentDash/StudentMyTeacher/StudentTeacherActionTypes"

const Card = styled.div`
    background-color: #eceef7;
    padding: 0.75em 1.5em;
    color: #fff;
    cursor: pointer;
`

const Subject = styled.p`
    font-size: 2em;
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: #132e98;
`

const StudentTeacherCard = ({ data }: { data: StudentMyTeacherTypeI }) => {
    const history = useHistory();
    const onRedirect = (id: string) => {
        history.push({
            pathname: `/assignment/detail/${id}`,
            state: { detail: data },
        });
    }
    return (
        <Card onClick={() => onRedirect(data.subject_id)}>
            <Subject>{data.subject}</Subject>
        </Card>
    )
}

export default StudentTeacherCard

