import {
  Grid,
  Paper,
  TableBody,
  TextField,
  Typography,
} from "@material-ui/core";
import { DeleteForeverOutlined, VisibilityOutlined } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTeacherAnnouncements } from "../../../../../actions/TeacherDash/TeacherAnnouncements/TeacherAnnouncementAction";
import { TeacherAnnouncementTypeI } from "../../../../../actions/TeacherDash/TeacherAnnouncements/TeacherAnnouncementActionTypes";
import { RootStore } from "../../../../../store";
import ActionButton from "../../../../../components/Reusable/Buttons/ActionButton";
import TableActionButtons from "../../../../../components/Reusable/Buttons/TableActionButtons";
import Popups from "../../../../../components/Reusable/Dialogs/Popups";
import useTable from "../../../../../components/Reusable/useStudentTable";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import { dateConverterAdToBs } from "../../../../../components/utils/dateConverter";
import TeacherAnnounceModal from "./TeacherAnnouceModal";
import { tblStyle } from "../../../components/Styles/TeacherDashboardStyles";

export interface DataI {
  data: any[];
  message_to: React.ReactNode;
}

interface HeadCellsI {
  id: string;
  label: string;
}

//---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "annoucement_title", label: "Annoucement Title" },
  { id: "notice_date", label: "Notice Date" },
  { id: "published_date", label: "Published Date" },
  { id: "action", label: "Action" },
];

const TeacherAnnounceTable = () => {
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<TeacherAnnouncementTypeI[]>([]);
  const [tempTableData, setTempTableData] = useState<
    TeacherAnnouncementTypeI[]
  >([]);
  const [loading, setLoading] = useState(false);

  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [item, setItem] = useState<any[] | null>(null);

  // React Hooks

  const dispatch = useDispatch();
  const announcementSelector = useSelector(
    (state: RootStore) => state.teacher_announcement
  );

  useEffect(() => {
    dispatch(GetTeacherAnnouncements());
  }, []);

  useEffect(() => {
    const data = announcementSelector.teacher_announcements;
    setTableData(data);
    setTempTableData(data);
  }, [announcementSelector]);
  ////////////////////////////////////////////////////////

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // Modal View Popup

  const handleViewModal = (item: any) => {
    setItem(item);
    setItemId(item.id);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.announcement_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.created_on)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ActionButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewModal(item)
            }
            title="View"
          >
            <VisibilityOutlined className={classes.viewIcon} />
          </ActionButton>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper style={tblStyle}>
        <TableContainer
          loading={loading}
          search_name="title"
          rowsPerPage={4}
          placeholder="Search Events"
          label="Annoucement List"
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          items={tableData}
        />
      </Paper>
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        fullWidth={true}
        maxWidth="lg"
        title="Announcement Detail"
        colored
      >
        <TeacherAnnounceModal item={item} />
      </Popups>
    </>
  );
};

export default TeacherAnnounceTable;
