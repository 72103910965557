import {
  AppBar,
  Box,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChildrenAcademicTypeI } from '../../../../../actions/ParentsDash/ViewChild/ViewChildActionTypes';
import { RootStore } from '../../../../../store';
import getFullName from '../../../../../components/utils/getFullName';
import ParentLiveClassList from './ParentLiveClassList';
import { tblStyle } from '../../../ParentDashboardStyles';
import ParentLessonPlanList from '../../LessonPlan/Components/ParentLessonPlanList';
import ParentStudentTeachersList from '../../StudentTeachers/ParentStudentTeachersList';
import ParentSyllabusList from '../../Syllabus/ParentSyllabusList';
import LeaveSearch from '../../LeaveRequest/LeaveSearch';
import LeaveList from '../../LeaveRequest/LeaveList';
import ParentTimetableView from '../../Timetable/ParentTimeTableView';
import MeetingSearch from '../../MeetingRequest/MeetingSearch';
import MeetingList from '../../MeetingRequest/MeetingList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '10px',
    padding: '0px!important',

    '& > div > .MuiBox-root': {
      padding: 0,
    },
  },

  tab: {
    '& span.MuiTab-wrapper': {
      fontSize: '14px',
      textTransform: 'capitalize',
      letterSpacing: '0.02rem',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
}));

const ChildrenListBar = (props: any) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const [children, setChildren] = React.useState<ChildrenAcademicTypeI[]>([]);
  const [childAcademicID, setChildAcademicID] = React.useState<string>();

  const viewChildrenList = useSelector(
    (state: RootStore) => state.view_child.children_academic
  );

  useEffect(() => {
    const data = viewChildrenList;
    data && setChildren(data);
  }, [viewChildrenList]);

  useEffect(() => {
    if (childAcademicID == undefined) {
      setChildAcademicID(children[0]?.id);
    }
  }, [children]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root} style={{ ...tblStyle, padding: 0 }}>
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        style={{ borderBottom: '1px solid #f5f5f5' }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          scrollButtons="off"
        >
          {children.map((item: ChildrenAcademicTypeI, index: number) => {
            return (
              <Tab
                key={index}
                onClick={() => setChildAcademicID(item.id)}
                value={index}
                className={classes.tab}
                label={getFullName(
                  item.student_first_name,
                  item.student_last_name
                )}
                wrapped
              />
            );
          })}
        </Tabs>
      </AppBar>
      {children.map((item: ChildrenAcademicTypeI, index: number) => (
        <TabPanel key={index} value={value} index={index}>
          {props.liveClassList && (
            <ParentLiveClassList academicID={childAcademicID} />
          )}
          {props.lessonPlanList && (
            <ParentLessonPlanList academicID={childAcademicID} />
          )}
          {props.syllabusList && (
            <ParentSyllabusList academicID={childAcademicID} />
          )}
          {props.studentTeachers && (
            <ParentStudentTeachersList academicID={childAcademicID} />
          )}
          {props.timetable && (
            <ParentTimetableView academicID={childAcademicID} />
          )}
          {props.leaveRequest && (
            <>
              <LeaveSearch academicID={childAcademicID} />
              <LeaveList academicID={childAcademicID} />
            </>
          )}
          {props.meetingRequest && (
            <>
              <MeetingSearch academicID={childAcademicID} />
              <MeetingList academicID={childAcademicID} />
            </>
          )}
        </TabPanel>
      ))}
    </div>
  );
};

export default ChildrenListBar;
