// ---------------- <START> module import start ------------------//
import React, { useState, useEffect } from "react";
import { Paper, TextField, Grid, Typography } from "@material-ui/core";
import { DeleteForeverOutlined, EditOutlined } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import ActionButton from "../../Reusable/Buttons/ActionButton";

import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  DeleteGrade,
  GetGrades,
} from "../../../actions/Academics/Grade/GradeAction";
import { GradeTypeI } from "../../../actions/Academics/Grade/GradeActionTypes";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";

// -------------------<END> module imports end ----------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: GradeTypeI) => void;
  edit: { value: boolean };
}

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data starts---------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "section_name", label: "Grade" },
  { id: "action", label: "Action" },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

// ---------------- <START> Section Table Component start ------------------//
const GradeTable = (props: PropsI) => {
  const { onEditData, edit } = props;
  const classes = useTableStyles();

  // section reducer
  const dispatch = useDispatch();
  const gradeState = useSelector((state: RootStore) => state.grade);

  // Modal States
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);

  // States declaration
  const [tableData, setTableData] = useState<GradeTypeI[]>([]);

  //================================<START>LIFECYCLE HOOKS<START>=================================//

  useEffect(() => {
    edit && setEditMode(edit.value);
  }, [edit]);

  useEffect(() => {
    dispatch(GetGrades());
  }, []);

  useEffect(() => {
    setLoading(gradeState.loading);
    setTableData(gradeState.grades);
  }, [gradeState]);

  //================================<END>LIFECYCLE HOOKS<END>=================================//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const handleEditClicked = (data: GradeTypeI) => {
    onEditData(data);
    setEditMode(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  // const onDeleteConfirmed = (confirmed: boolean) => {
  //   confirmed && itemId != null && dispatch(DeleteGrade(itemId));
  //   setDeleteModalOpen(false);
  // };

  const getCustomTableRow = (item: GradeTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.grade_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          {/* <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ color: "#4D4F5C" }}>
              Grade List
            </Typography>
          </Grid>
        </Grid>
        <TableContainer
          loading={loading}
          headCells={headCells}
          items={tableData}
          search_name="grade_name"
          search_param="grade_name"
          getCustomTableRow={getCustomTableRow}
        />
      </Paper>
      {/* <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      /> */}
    </>
  );
};
// ---------------- <END> Section Table Components ends------------------//
export default GradeTable;
