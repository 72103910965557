import {
  Button,
  Chip,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React from "react";
import { StudentEnotesI } from "../../../../actions/StudentDash/StudentEnote/StudentEnoteActionTypes";
import { DownloadButton } from "../../../../components/Reusable/Buttons/TableButton";
import { downloadEmployeeData } from "../../../../components/HumanResource/StaffList/StaffProfile/StaffTabs/StaffDocument";
import parse from "html-react-parser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipLink: {
      textDecoration: "none",
    },
  })
);

interface PropsI {
  handleModalClose: (value: boolean) => void;
  item: StudentEnotesI | null;
}

interface DocumentI {
  id: string;
  enote: string;
  document: string;
}

const StudentEnotesModal = (props: PropsI) => {
  const { item, handleModalClose } = props;
  const classes = useStyles();

  const createLinkChips = (arr: string[]) => {
    return (
      <Grid container spacing={1}>
        {arr.map((element: string, index: number) => (
          <Grid item key={index + 1}>
            <a href={element} className={classes.chipLink}>
              <Chip
                style={{
                  backgroundColor: "#A1ABD6",
                  color: "white",
                  cursor: "pointer",
                }}
                size="small"
                label={element}
              />
            </a>
          </Grid>
        ))}
      </Grid>
    );
  };

  const getDocName = (name: string) => {
    const target = name.split("/").pop();
    return target;
  };

  const listDocuments = (docs: DocumentI[]) => {
    return (
      <List>
        {docs.map((element: DocumentI, index: number) => (
          <ListItem key={index + 1}>
            <DownloadButton
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => downloadEmployeeData(element.document)}
            />{" "}
            {getDocName(element.document)}
          </ListItem>
        ))}
      </List>
    );
  };

  const parseHtmlContent = (content: string) => {
    const doc1 = parse(content);
    return doc1;
  };

  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Title:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {item?.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Class:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {item?.grade_name} {item?.section_name}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Subject:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {item?.subject_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Teacher:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {item?.teacher_first_name} {item?.teacher_last_name}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Description:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {item?.meta_description}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Reference Links:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {item?.reference_links && createLinkChips(item.reference_links)}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Content:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {item?.enote_documents && listDocuments(item.enote_documents)}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={8}>
        <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
          {item?.enote_description && parseHtmlContent(item.enote_description)}
        </Typography>
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />
      <Grid
        container
        spacing={2}
        style={{ marginTop: "15px", marginBottom: "5px" }}
      >
        <Grid item style={{ marginLeft: "auto" }}>
          <Button variant="contained" onClick={() => handleModalClose(false)}>
            Close
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default StudentEnotesModal;
