import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { HeadCellsI } from "../../../../actions";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../store";
import { ParentStudentTeachersI } from "../../../../actions/ParentsDash/ParentStudentTeachers/ParentStudentTeachersActionTypes";
import getFullName from "../../../../components/utils/getFullName";
import { GetParentStudentTeachers } from "../../../../actions/ParentsDash/ParentStudentTeachers/ParentStudentTeachersAction";
import { GetParentSyllabus } from "../../../../actions/ParentsDash/ParentSyllabus/ParentSyllabusAction";
import { ParentSyllabusI } from "../../../../actions/ParentsDash/ParentSyllabus/ParentSyllabusActionTypes";
import { ItemViewButton } from "../../../../components/Reusable/Buttons/TableButton";
import { tblStyle } from "../../ParentDashboardStyles";
import useTable from "../../../../components/Reusable/useStudentTable";

const headCells: HeadCellsI[] = [
  { id: "unit", label: "S.No." },
  { id: "name", label: "Unit Title" },
  { id: "teaching_hour", label: "Teaching Hour" },
  { id: "actions", label: "Actions" },
];

const ParentSyllabusList = (props: any) => {
  const { academicID } = props;

  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<ParentSyllabusI[]>([]);
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  useEffect(() => {
    if (academicID) {
      dispatch(GetParentSyllabus(academicID));
    }
  }, [academicID]);

  const ParentSyllabusState = useSelector(
    (state: RootStore) => state.parent_syllabus
  );

  useEffect(() => {
    const items = ParentSyllabusState.syllabus;
    items && setTableData(items);
  }, [ParentSyllabusState]);

  const handleViewClicked = (data: ParentSyllabusI, index: number) => {
    console.log("Not yet setup!");
  };

  const getCustomTableRow = (item: ParentSyllabusI, index: number) => {
    return (
      <StyledTableRow key={index} hover tabIndex={-1}>
        <StyledTableCell align="center" className={classes.newcell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.teaching_hours}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          <ItemViewButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewClicked(item, index)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper
      elevation={0}
      className={classes.root}
      style={{ paddingLeft: "8px", paddingRight: "8px" }}
    >
      <TableContainer
        label="Syllabus List"
        loading={ParentSyllabusState.loading}
        items={tableData}
        headCells={headCells}
        rowsPerPage={8}
        getCustomTableRow={getCustomTableRow}
        search_name="name"
      />
    </Paper>
  );
};

export default ParentSyllabusList;
