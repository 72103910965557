export const GET_TEACHER_TIMETABLE_LOADING = "GET_TEACHER_TIMETABLE_LOADING";
export const GET_TEACHER_TIMETABLE_ERROR = "GET_TEACHER_TIMETABLE_ERROR";
export const GET_TEACHER_TIMETABLE_SUCCESS = "GET_TEACHER_TIMETABLE_SUCCESS";

export const GET_TEACHER_TIMETABLE_BY_DAY_LOADING =
  "GET_TEACHER_TIMETABLE_BY_DAY_LOADING";
export const GET_TEACHER_TIMETABLE_BY_DAY_ERROR =
  "GET_TEACHER_TIMETABLE_BY_DAY_ERROR";
export const GET_TEACHER_TIMETABLE_BY_DAY_SUCCESS =
  "GET_TEACHER_TIMETABLE_BY_DAY_SUCCESS";

export const ADD_TEACHER_TIMETABLE_LOADING = "ADD_TEACHER_TIMETABLE_LOADING";
export const ADD_TEACHER_TIMETABLE_ERROR = "ADD_TEACHER_TIMETABLE_ERROR";
export const ADD_TEACHER_TIMETABLE_SUCCESS = "ADD_TEACHER_TIMETABLE_SUCCESS";

export const UPDATE_TEACHER_TIMETABLE_LOADING =
  "UPDATE_TEACHER_TIMETABLE_LOADING";
export const UPDATE_TEACHER_TIMETABLE_ERROR = "UPDATE_TEACHER_TIMETABLE_ERROR";
export const UPDATE_TEACHER_TIMETABLE_SUCCESS =
  "UPDATE_TEACHER_TIMETABLE_SUCCESS";

export const DELETE_TEACHER_TIMETABLE_LOADING =
  "DELETE_TEACHER_TIMETABLE_LOADING";
export const DELETE_TEACHER_TIMETABLE_ERROR = "DELETE_TEACHER_TIMETABLE_ERROR";
export const DELETE_TEACHER_TIMETABLE_SUCCESS =
  "DELETE_TEACHER_TIMETABLE_SUCCESS";

export type TimeTableTypeI = {
  id: number;
  day_name: string;
  start_time: string;
  subject_name: string;
  end_time: string;
  grade_name: string;
  section_name: string;
};

export interface TimeTableByDayI {
  day: number;
  timeTable: TimeTableTypeI[];
}

//===============================<START>GET TIME TABLE<START>======================//

export interface GetTimeTableLoading {
  type: typeof GET_TEACHER_TIMETABLE_LOADING;
}

export interface GetTimeTableError {
  type: typeof GET_TEACHER_TIMETABLE_ERROR;
}

export interface GetTimeTableSuccess {
  type: typeof GET_TEACHER_TIMETABLE_SUCCESS;
  payload: TimeTableTypeI[];
}

//===============================<END>GET TIME TABLE<END>======================//

//===============================<START>GET TIME TABLE BY DAY <START>======================//

export interface GetTimeTableByDayLoading {
  type: typeof GET_TEACHER_TIMETABLE_BY_DAY_LOADING;
}

export interface GetTimeTableByDayError {
  type: typeof GET_TEACHER_TIMETABLE_BY_DAY_ERROR;
}

export interface GetTimeTableByDaySuccess {
  type: typeof GET_TEACHER_TIMETABLE_BY_DAY_SUCCESS;
  payload: TimeTableByDayI;
}

//===============================<END>GET TIME TABLE BY DAY <END>======================//

//===============================<START>ADD TIME TABLE<START>======================//

export interface AddTimeTableLoading {
  type: typeof ADD_TEACHER_TIMETABLE_LOADING;
}

export interface AddTimeTableError {
  type: typeof ADD_TEACHER_TIMETABLE_ERROR;
}

export interface AddTimeTableSuccess {
  type: typeof ADD_TEACHER_TIMETABLE_SUCCESS;
  payload: TimeTableTypeI;
}

//===============================<END>ADD TIME TABLE<END>======================//

//===============================<START>UPDATE TIME TABLE<START>======================//

export interface UpdateTimeTableLoading {
  type: typeof UPDATE_TEACHER_TIMETABLE_LOADING;
}

export interface UpdateTimeTableError {
  type: typeof UPDATE_TEACHER_TIMETABLE_ERROR;
}

export interface UpdateTimeTableSuccess {
  type: typeof UPDATE_TEACHER_TIMETABLE_SUCCESS;
  payload: TimeTableTypeI;
}

//===============================<END>UPDATE TIME TABLE<END>======================//

//===============================<START>DELETE TIME TABLE<START>======================//

export interface DeleteTimeTableLoading {
  type: typeof DELETE_TEACHER_TIMETABLE_LOADING;
}

export interface DeleteTimeTableError {
  type: typeof DELETE_TEACHER_TIMETABLE_ERROR;
}

export interface DeleteTimeTableSuccess {
  type: typeof DELETE_TEACHER_TIMETABLE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE TIME TABLE<END>======================//

export type TimeTableDispatchTypes =
  | GetTimeTableLoading
  | GetTimeTableError
  | GetTimeTableSuccess
  | GetTimeTableByDayLoading
  | GetTimeTableByDayError
  | GetTimeTableByDaySuccess
  | AddTimeTableLoading
  | AddTimeTableError
  | AddTimeTableSuccess
  | UpdateTimeTableLoading
  | UpdateTimeTableError
  | UpdateTimeTableSuccess
  | DeleteTimeTableLoading
  | DeleteTimeTableError
  | DeleteTimeTableSuccess;
