import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_LESSON_PLAN_LOADING,
  GET_LESSON_PLAN_ERROR,
  GET_LESSON_PLAN_SUCCESS,
  LessonPlanDispatchTypes,
} from "./ParentLessonPlanActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { PARENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetParentLessonPlan = (academic_id: number) => async (
  dispatch: Dispatch<LessonPlanDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_LESSON_PLAN_LOADING,
    });

    const res = await axios.get(
      `${PARENT_API_URL}/lesson_plan/?student_academic_id=${academic_id}`,
      HeaderConfig()
    );

    dispatch({
      type: GET_LESSON_PLAN_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_LESSON_PLAN_ERROR,
    });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     snackbarMessage: "Lesson Plan Loading Failed",
    //   },
    // });
  }
};
