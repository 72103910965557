import axios from "axios";
import { Dispatch } from "redux";

import {
  MY_ATTENDANCE_LOADING,
  MY_ATTENDANCE_ERROR,
  MY_ATTENDANCE_SUCCESS,
  MyAttendanceDispatchTypes,
  QueryI,
} from "./MyAttendanceActionTypes";

import { SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { STUDENT_API_URL } from "../../../config/SERVER_API_URLS";

export const MyAttendances =
  (query: QueryI) =>
  async (dispatch: Dispatch<MyAttendanceDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: MY_ATTENDANCE_LOADING,
      });

      const res = await axios.get(
        `${STUDENT_API_URL}/student_attendance/?from_date=${query.from_date}&to_date=${query.to_date}`,
        HeaderConfig()
      );

      dispatch({
        type: MY_ATTENDANCE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: MY_ATTENDANCE_ERROR,
      });
    }
  };
