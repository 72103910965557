import { Grid, Container } from '@material-ui/core';
import FeeCollectionChart from './components/Chart/FeeCollectionChart';
import UsersCard from './components/NumberCard/UsersCard';
import HeaderInfo from './components/HeaderInfo';
import DueAlertsWidget from './components/DueAlerts/DueAlertsWidget';
import RecentTransactionWidget from './components/RecentTransaction/RecentTransactionWidget';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';
import { useEffect } from 'react';
import { GetDashboardData } from '../../actions/Dashboard/DashboardAction';
import styled from 'styled-components';
import AccountLayout from './core-ui/AccountLayout';
import AccountantCalendar from './components/RecentTransaction/AccountantCalendar';

const StyledContainer = styled(Container)`
  padding: 120px 15px 15px 15px;
`;

const AccountDashboardPage = (props: any) => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state: RootStore) => state.dashboard);
  const data = dashboardState.data;

  useEffect(() => {
    dispatch(GetDashboardData());
  }, []);

  return (
    <StyledContainer maxWidth="xl">
      <HeaderInfo />
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Grid container direction="column" spacing={2}>
            <Grid item md={12}>
              <UsersCard data={data} />
            </Grid>

            {/* <Grid item md={12}>
              <FeeCollectionChart />
            </Grid> */}

            <Grid item md={12}>
              <AccountantCalendar />
            </Grid>

            <Grid item md={12}>
              <RecentTransactionWidget data={data} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={5}>
          <DueAlertsWidget data={data} />
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default AccountDashboardPage;
