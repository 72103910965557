import { Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AdminEnotesI } from "../../actions/Classroom/Enotes/AdminEnotesActionTypes";
import { RootStore } from "../../store";
import { useTable } from "../Reusable";
import { ItemViewButton } from "../Reusable/Buttons/TableButton";
import Popups from "../Reusable/Dialogs/Popups";
import { useTableStyles } from "../Styles/TableStyles";
import TeacherEnotesModal from "../../dashboard/TeacherDashboard/pages/Enotes/components/EnoteModalView";
import getFullName from "../utils/getFullName";

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data starts---------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.No. " },
  { id: "subject", label: "Subject " },
  { id: "class", label: "Class " },
  { id: "section", label: "Section " },
  { id: "title", label: "Title " },
  { id: "teacher", label: "Teacher " },
  { id: "action", label: "Action" },
];

const AdminEnoteTable = (props: any) => {
  const classes = useTableStyles();
  const { classItem } = props;

  const {
    StyledTableCell,
    StyledTableRow,

    TableContainer,
  } = useTable(headCells);

  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);

  const [enoteModalOpen, setEnoteModalOpen] = useState<boolean>(false);
  const [modalItem, setModalItem] = useState<AdminEnotesI | null>(null);
  const [enotes, setEnotes] = useState<AdminEnotesI[] | null>([
    // {
    //   id: 1,
    //   grade: "",
    //   grade_name: "Play Group",
    //   section: "",
    //   section_name: "A",
    //   subject: "",
    //   subject_name: "Science",
    //   category: 1,
    //   category_name: "",
    //   lesson_plan: 1,
    //   meta_description: "Chapter 1 - Animals",
    //   enote_description: "",
    //   teacher: 1,
    //   teacher_first_name: "John",
    //   teacher_last_name: "Doe",
    //   reference_links: [],
    //   enote_documents: [],
    // },
    // {
    //   id: 1,
    //   grade: "",
    //   grade_name: "Play Group",
    //   section: "",
    //   section_name: "A",
    //   subject: "",
    //   subject_name: "Health and Population",
    //   category: 1,
    //   category_name: "",
    //   lesson_plan: 1,
    //   meta_description: "Chapter 1 Handsout",
    //   enote_description: "",
    //   teacher: 1,
    //   teacher_first_name: "Jessie",
    //   teacher_last_name: "Ronson",
    //   reference_links: [],
    //   enote_documents: [],
    // },
    // {
    //   id: 1,
    //   grade: "",
    //   grade_name: "Play Group",
    //   section: "",
    //   section_name: "B",
    //   subject: "",
    //   subject_name: "Maths",
    //   category: 1,
    //   category_name: "",
    //   lesson_plan: 1,
    //   meta_description: "Trigonometry Formulas",
    //   enote_description: "",
    //   teacher: 1,
    //   teacher_first_name: "Andy",
    //   teacher_last_name: "James",
    //   reference_links: [],
    //   enote_documents: [],
    // },
  ]);

  const enoteSelector = useSelector(
    (state: RootStore) => state.admin_enotes.enotes
  );

  useEffect(() => {
    // enoteSelector && setEnotes(enoteSelector);
  }, [enoteSelector]);

  useEffect(() => {
    // classItem && setEnotes(null);
  }, [classItem]);

  const handleView = (enotes: AdminEnotesI) => {
    setEnoteModalOpen(true);
    setModalItem(enotes);
  };

  const getCustomTableRow = (item: AdminEnotesI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.subject_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.grade_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.section_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.meta_description}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(item.teacher_first_name, item.teacher_last_name)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleView(item)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper style={{ padding: "1rem" }}>
      <Typography variant="h6">E-Notes</Typography>
      <TableContainer
        loading={loading}
        items={enotes}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
      />

      <Popups
        openPopup={enoteModalOpen}
        setOpenPopup={setEnoteModalOpen}
        onClose={() => setEnoteModalOpen(false)}
        fullWidth={true}
        maxWidth="md"
        title="Enote"
        colored
      >
        <TeacherEnotesModal
          item={modalItem}
          handleModalClose={setEnoteModalOpen}
        />
      </Popups>
    </Paper>
  );
};

export default AdminEnoteTable;
