import { UserType } from "./../../Auth/Login/LoginActionTypes";

export const GET_STAFF_PROFILE_LOADING = "GET_STAFF_PROFILE_LOADING";
export const GET_STAFF_PROFILE_ERROR = "GET_STAFF_PROFILE_ERROR";
export const GET_STAFF_PROFILE_SUCCESS = "GET_STAFF_PROFILE_SUCCESS";

export const GET_TEACHER_ACADEMIC_INFO_LOADING =
  "GET_TEACHER_ACADEMIC_INFO_LOADING";
export const GET_TEACHER_ACADEMIC_INFO_SUCCESS =
  "GET_TEACHER_ACADEMIC_INFO_SUCCESS";
export const GET_TEACHER_ACADEMIC_INFO_ERROR =
  "GET_TEACHER_ACADEMIC_INFO_ERROR";

export const GET_TEACHER_TIMETABLE_LOADING = "GET_TEACHER_TIMETABLE_LOADING";
export const GET_TEACHER_TIMETABLE_SUCCESS = "GET_TEACHER_TIMETABLE_SUCCESS";
export const GET_TEACHER_TIMETABLE_ERROR = "GET_TEACHER_TIMETABLE_ERROR";

export const UPDATE_STAFF_USERNAME_LOADING = "UPDATE_STAFF_USERNAME_LOADING";
export const UPDATE_STAFF_USERNAME_SUCCESS = "UPDATE_STAFF_USERNAME_SUCCESS";
export const UPDATE_STAFF_USERNAME_ERROR = "UPDATE_STAFF_USERNAME_ERROR";

export const GET_STAFF_PROFILE_LEAVE_LOADING =
  "GET_STAFF_PROFILE_LEAVE_LOADING";
export const GET_STAFF_PROFILE_LEAVE_ERROR = "GET_STAFF_PROFILE_LEAVE_ERROR";
export const GET_STAFF_PROFILE_LEAVE_SUCCESS =
  "GET_STAFF_PROFILE_LEAVE_SUCCESS";

export type StaffProfileTypeI = {
  id: string;
  user: UserType;
  designation_name: string;
};

export type AcademicInfoClassI = {
  id: string;
  grade_name: string;
  section: any[];
};

export type AcademicInfoSubjectI = {
  id: string;
  name: string;
  subject_type: number;
  is_optional: boolean;
  subject: string;
};

type LeaveType = {
  id: string;
  max_leave: number;
  name: string;
};

export type StaffLeaveInfoType = {
  from_date: string;
  id: string;
  modified_on: string;
  reason_for_leave: string;
  remarks: string | null;
  status: string;
  to_date: string;
  total_used: number;
  leave_type: LeaveType;
};

//===============================<START>GET STAFF<START>======================//

export interface GetStaffLoading {
  type: typeof GET_STAFF_PROFILE_LOADING;
}

export interface GetStaffError {
  type: typeof GET_STAFF_PROFILE_ERROR;
}

export interface GetStaffSuccess {
  type: typeof GET_STAFF_PROFILE_SUCCESS;
  payload: any;
}
///////////////////////////////////////////////////////////////////////////////////
export interface GetTeacherAcademicInfoLoading {
  type: typeof GET_TEACHER_ACADEMIC_INFO_LOADING;
}

export interface GetTeacherAcademicInfoError {
  type: typeof GET_TEACHER_ACADEMIC_INFO_ERROR;
}

export interface GetTeacherAcademicInfoSuccess {
  type: typeof GET_TEACHER_ACADEMIC_INFO_SUCCESS;
  payload: {
    academic_class: AcademicInfoClassI[];
    subjects: AcademicInfoSubjectI[];
  };
}
////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////
export interface GetTeacherTimeTableLoading {
  type: typeof GET_TEACHER_TIMETABLE_LOADING;
}

export interface GetTeacherTimeTableError {
  type: typeof GET_TEACHER_TIMETABLE_ERROR;
}

export interface GetTeacherTimeTableSuccess {
  type: typeof GET_TEACHER_TIMETABLE_SUCCESS;
  payload: any;
}

export interface UpdateStaffUsernameLoading {
  type: typeof UPDATE_STAFF_USERNAME_LOADING;
}

export interface UpdateStaffUsernameError {
  type: typeof UPDATE_STAFF_USERNAME_ERROR;
}

export interface UpdateStaffUsernameSuccess {
  type: typeof UPDATE_STAFF_USERNAME_SUCCESS;
}

export interface GetStaffProfileLeaveLoading {
  type: typeof GET_STAFF_PROFILE_LEAVE_LOADING;
}
export interface GetStaffProfileLeaveError {
  type: typeof GET_STAFF_PROFILE_LEAVE_ERROR;
}
export interface GetStaffProfileLeaveSuccess {
  type: typeof GET_STAFF_PROFILE_LEAVE_SUCCESS;
  payload: StaffLeaveInfoType[];
}
////////////////////////////////////////////////////////////////////////////////////
export type StaffProfileDispatchTypes =
  | GetStaffLoading
  | GetStaffError
  | GetStaffSuccess
  | GetTeacherAcademicInfoLoading
  | GetTeacherAcademicInfoError
  | GetTeacherAcademicInfoSuccess
  | GetTeacherTimeTableLoading
  | GetTeacherTimeTableError
  | GetTeacherTimeTableSuccess
  | UpdateStaffUsernameLoading
  | UpdateStaffUsernameSuccess
  | UpdateStaffUsernameError
  | GetStaffProfileLeaveLoading
  | GetStaffProfileLeaveError
  | GetStaffProfileLeaveSuccess;
