export const GET_CHILDREN_ACADEMIC_INFO_LOADING =
  "GET_CHILDREN_ACADEMIC_INFO_LOADING";
export const GET_CHILDREN_ACADEMIC_INFO_ERROR =
  "GET_CHILDREN_ACADEMIC_INFO_ERROR";
export const GET_CHILDREN_ACADEMIC_INFO_SUCCESS =
  "GET_CHILDREN_ACADEMIC_INFO_SUCCESS";

export const GET_CHILDREN_TIMETABLE_LOADING = "GET_CHILDREN_TIMETABLE_LOADING";
export const GET_CHILDREN_TIMETABLE_ERROR = "GET_CHILDREN_TIMETABLE_ERROR";
export const GET_CHILDREN_TIMETABLE_SUCCESS = "GET_CHILDREN_TIMETABLE_SUCCESS";

export const GET_CHILDREN_ASSIGNMENT_SUCCESS =
  "GET_CHILDREN_ASSIGNMENT_SUCCESS";
export const GET_CHILDREN_ASSIGNMENT_LOADING =
  "GET_CHILDREN_ASSIGNMENT_LOADING";
export const GET_CHILDREN_ASSIGNMENT_ERROR = "GET_CHILDREN_ASSIGNMENT_ERROR";

export const GET_CHILDREN_ASSIGNMENT_DETAIL_SUCCESS =
  "GET_CHILDREN_ASSIGNMENT_DETAIL_SUCCESS";
export const GET_CHILDREN_ASSIGNMENT_DETAIL_LOADING =
  "GET_CHILDREN_ASSIGNMENT_DETAIL_LOADING";
export const GET_CHILDREN_ASSIGNMENT_DETAIL_ERROR =
  "GET_CHILDREN_ASSIGNMENT_DETAIL_ERROR";

export const SUBMIT_CHILDREN_ASSIGNMENT_LOADING =
  "SUBMIT_CHILDREN_ASSIGNMENT_LOADING";
export const SUBMIT_CHILDREN_ASSIGNMENT_ERROR =
  "SUBMIT_CHILDREN_ASSIGNMENT_ERROR";
export const SUBMIT_CHILDREN_ASSIGNMENT_SUCCESS =
  "SUBMIT_CHILDREN_ASSIGNMENT_SUCCESS";

export const GET_CHILDREN_SUBMITTED_ASSIGNMENT_SUCCESS =
  "GET_CHILDREN_SUBMITTED_ASSIGNMENT_SUCCESS";
export const GET_CHILDREN_SUBMITTED_ASSIGNMENT_LOADING =
  "GET_CHILDREN_SUBMITTED_ASSIGNMENT_LOADING";
export const GET_CHILDREN_SUBMITTED_ASSIGNMENT_ERROR =
  "GET_CHILDREN_SUBMITTED_ASSIGNMENT_ERROR";

export const SUBMIT_CHILDREN_REDO_ASSIGNMENT_LOADING =
  "SUBMIT_CHILDREN_REDO_ASSIGNMENT_LOADING";
export const SUBMIT_CHILDREN_REDO_ASSIGNMENT_ERROR =
  "SUBMIT_CHILDREN_REDO_ASSIGNMENT_ERROR";
export const SUBMIT_CHILDREN_REDO_ASSIGNMENT_SUCCESS =
  "SUBMIT_CHILDREN_REDO_ASSIGNMENT_SUCCESS";

export interface ChildrenAcademicInfoI {
  id: number;
  student: number;
  student_first_name: string;
  student_last_name: string;
  grade: number;
  grade_name: string;
  section: number | null;
  section_name: string | null;
  roll_number: number | null;
  house: number | null;
  last_school_name: string | null;
  last_percentage: string | null;
  last_grade: number | null;
  sports: string;
  academic_session: number;
}

export interface ChildrenTimeTableI {
  id: number;
  day: number;
  start_time: string;
  end_time: string;
  grade: number;
  grade_name: string;
  section: number | null;
  section_name: string | null;
  teacher_first_name: string;
  teacher_last_name: string;
  teacher: number;
  subject_name: string;
}

//===============================<START>GET CHILDREN ACADeMIC INFO<START>======================//

export interface GetChildrenAcademicInfoLoading {
  type: typeof GET_CHILDREN_ACADEMIC_INFO_LOADING;
}

export interface GetChildrenAcademicInfoError {
  type: typeof GET_CHILDREN_ACADEMIC_INFO_ERROR;
}

export interface GetChildrenAcademicInfoSuccess {
  type: typeof GET_CHILDREN_ACADEMIC_INFO_SUCCESS;
  payload: ChildrenAcademicInfoI[];
}

//===============================<END>GET CHILDREN ACADeMIC INFO<END>======================//

//===============================<START>GET CHILDREN TIMETABLE<START>======================//

export interface GetChildrenTimeTableLoading {
  type: typeof GET_CHILDREN_TIMETABLE_LOADING;
}

export interface GetChildrenTimeTableError {
  type: typeof GET_CHILDREN_TIMETABLE_ERROR;
}

export interface GetChildrenTimeTableSuccess {
  type: typeof GET_CHILDREN_TIMETABLE_SUCCESS;
  payload: ChildrenTimeTableI[];
}
//===============================<END>GET CHILDREN TIMETABLE<END>======================//

//===============================<START>GET CHILDREN ASSIGNMENT<START>======================//
export interface GetChildrenAssignmentSuccess {
  type: typeof GET_CHILDREN_ASSIGNMENT_SUCCESS;
  payload: any;
}
export interface GetChildrenAssignmentLoading {
  type: typeof GET_CHILDREN_ASSIGNMENT_LOADING;
}
export interface GetChildrenAssignmentError {
  type: typeof GET_CHILDREN_ASSIGNMENT_ERROR;
  payload: any;
}
//===============================<END>GET CHILDREN ASSIGNMENT<END>======================//

//===============================<START>GET CHILDREN ASSIGNMENT DETAIL<START>======================//
export interface GetChildrenAssignmentDetailSuccess {
  type: typeof GET_CHILDREN_ASSIGNMENT_DETAIL_SUCCESS;
  payload: any;
}
export interface GetChildrenAssignmentDetailLoading {
  type: typeof GET_CHILDREN_ASSIGNMENT_DETAIL_LOADING;
}
export interface GetChildrenAssignmentDetailError {
  type: typeof GET_CHILDREN_ASSIGNMENT_DETAIL_ERROR;
  payload: any;
}
//===============================<END>GET CHILDREN ASSIGNMENT DETAIL<END>======================//

//===============================<START>SUBMIT CHILDREN ASSIGNMENT<START>======================//
export interface SubmitChildrenAssignmentSuccess {
  type: typeof SUBMIT_CHILDREN_ASSIGNMENT_SUCCESS;
  payload: any;
}
export interface SubmitChildrenAssignmentLoading {
  type: typeof SUBMIT_CHILDREN_ASSIGNMENT_LOADING;
}
export interface SubmitChildrenAssignmentError {
  type: typeof SUBMIT_CHILDREN_ASSIGNMENT_ERROR;
  payload: any;
}
//===============================<END>SUBMIT CHILDREN ASSIGNMENT<END>======================//

//===============================<START>GET CHILDREN SUBMITTED ASSIGNMENT<START>======================//
export interface GetChildrenSubmittedAssignmentSuccess {
  type: typeof GET_CHILDREN_SUBMITTED_ASSIGNMENT_SUCCESS;
  payload: any;
}
export interface GetChildrenSubmittedAssignmentLoading {
  type: typeof GET_CHILDREN_SUBMITTED_ASSIGNMENT_LOADING;
}
export interface GetChildrenSubmittedAssignmentError {
  type: typeof GET_CHILDREN_SUBMITTED_ASSIGNMENT_ERROR;
  payload: any;
}
//===============================<END>GET CHILDREN SUBMITTED ASSIGNMENT<END>======================//

export type ChildrenAcademicInfoDispatchTypes =
  | GetChildrenAcademicInfoLoading
  | GetChildrenAcademicInfoError
  | GetChildrenAcademicInfoSuccess
  | GetChildrenTimeTableLoading
  | GetChildrenTimeTableError
  | GetChildrenAssignmentSuccess
  | GetChildrenAssignmentLoading
  | GetChildrenAssignmentError
  | GetChildrenTimeTableSuccess
  | GetChildrenAssignmentDetailLoading
  | GetChildrenAssignmentDetailError
  | GetChildrenAssignmentDetailSuccess
  | SubmitChildrenAssignmentSuccess
  | SubmitChildrenAssignmentLoading
  | SubmitChildrenAssignmentError
  | GetChildrenSubmittedAssignmentSuccess
  | GetChildrenSubmittedAssignmentLoading
  | GetChildrenSubmittedAssignmentError;
