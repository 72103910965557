import {
  GET_LESSON_PLAN_LOADING,
  GET_LESSON_PLAN_ERROR,
  GET_LESSON_PLAN_SUCCESS,
  LessonPlanDispatchTypes,
  StudentLessonTypeI,
} from "../../actions/StudentDash/StudentLessonPlan/StudentLessonPlanActionTypes";

interface InitialStateI {
  loading: boolean;
  lesson_plan: StudentLessonTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  lesson_plan: [],
};

const StudentLessonPlanReducer = (
  state: InitialStateI = initialState,
  action: LessonPlanDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_LESSON_PLAN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_LESSON_PLAN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        lesson_plan: action.payload,
      };

    default:
      return state;
  }
};

export default StudentLessonPlanReducer;
