export const GET_TEACHER_MEETING_LOADING = "GET_TEACHER_MEETING_LOADING";
export const GET_TEACHER_MEETING_ERROR = "GET_TEACHER_MEETING_ERROR";
export const GET_TEACHER_MEETING_SUCCESS = "GET_TEACHER_MEETING_SUCCESS";

export const ADD_TEACHER_MEETING_LOADING = "ADD_TEACHER_MEETING_LOADING";
export const ADD_TEACHER_MEETING_ERROR = "ADD_TEACHER_MEETING_ERROR";
export const ADD_TEACHER_MEETING_SUCCESS = "ADD_TEACHER_MEETING_SUCCESS";

export const DELETE_TEACHER_MEETING_LOADING = "DELETE_TEACHER_MEETING_LOADING";
export const DELETE_TEACHER_MEETING_ERROR = "DELETE_TEACHER_MEETING_ERROR";
export const DELETE_TEACHER_MEETING_SUCCESS = "DELETE_TEACHER_MEETING_SUCCESS";

export const TEACHER_APPROVE_OR_DECLINE_LOADING =
  "TEACHER_APPROVE_OR_DECLINE_LOADING";
export const TEACHER_APPROVE_OR_DECLINE_SUCCESS =
  "TEACHER_APPROVE_OR_DECLINE_SUCCESS";
export const TEACHER_APPROVE_OR_DECLINE_ERROR =
  "TEACHER_APPROVE_OR_DECLINE_ERROR";

//===============================<START>GET STUDENT_HOUSE<START>======================//

export type MeetingPropsI = {
  id: number;
  meeting_type: number;
  status: string;
  status_name: string;
  meeting_from: string;
  meeting_to: string;
  applied_date: string;
  general_info: number;
  reason_for_meeting: string;
  meeting_name: string;
  no_of_days: string;
  first_name: string;
  last_name: string;
};

export interface GetTeacherMeetingLoading {
  type: typeof GET_TEACHER_MEETING_LOADING;
}

export interface GetTeacherMeetingError {
  type: typeof GET_TEACHER_MEETING_ERROR;
}

export interface GetTeacherMeetingSuccess {
  type: typeof GET_TEACHER_MEETING_SUCCESS;
  payload: MeetingPropsI[];
}
//===============================<END>GET STUDENT_HOUSE<END>======================//
//===============================<START>ADD MEETING<START>======================//

export interface AddTeacherMeetingLoading {
  type: typeof ADD_TEACHER_MEETING_LOADING;
}

export interface AddTeacherMeetingError {
  type: typeof ADD_TEACHER_MEETING_ERROR;
  payload: any;
}

export interface AddTeacherMeetingSuccess {
  type: typeof ADD_TEACHER_MEETING_SUCCESS;
  payload: MeetingPropsI;
}
//===============================<START>DELETE MEETING<START>======================//

export interface DeleteTeacherMeetingLoading {
  type: typeof DELETE_TEACHER_MEETING_LOADING;
}

export interface DeleteTeacherMeetingError {
  type: typeof DELETE_TEACHER_MEETING_ERROR;
}

export interface DeleteTeacherMeetingSuccess {
  type: typeof DELETE_TEACHER_MEETING_SUCCESS;
  payload: { id: number };
}

export interface TeacherApproveOrDeclineLoading {
  type: typeof TEACHER_APPROVE_OR_DECLINE_LOADING;
}

export interface TeacherApproveOrDeclineError {
  type: typeof TEACHER_APPROVE_OR_DECLINE_ERROR;
}

export interface TeacherApproveOrDeclineSuccess {
  type: typeof TEACHER_APPROVE_OR_DECLINE_SUCCESS;
  payload: any;
}
//===============================<END>ADD MEETING<END>======================//

//===============================<END>DELETE STUDENT_HOUSE<END>======================//

export type TeacherMeetingDispatchTypes =
  | GetTeacherMeetingLoading
  | GetTeacherMeetingError
  | GetTeacherMeetingSuccess
  | AddTeacherMeetingLoading
  | AddTeacherMeetingError
  | AddTeacherMeetingSuccess
  | DeleteTeacherMeetingLoading
  | DeleteTeacherMeetingError
  | TeacherApproveOrDeclineLoading
  | TeacherApproveOrDeclineError
  | TeacherApproveOrDeclineSuccess
  | DeleteTeacherMeetingSuccess;
