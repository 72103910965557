import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ExamInfoI,
  ExamSubjectI,
  initialStateI,
  MarksLedgerI,
} from "./marksLedger";
import { generateGradeResult, getMarksLedger } from "./marksLedgerApi";

const initialState: initialStateI = {
  loading: false,
  result_generated: true,
  ledger: null,
  sorted: "",
  searchParams: {
    exam: "string",
    grade: "string",
    section: "string",
  },
};

const marksLedgerSlice = createSlice({
  name: "marksLedger",
  initialState,
  reducers: {
    sortByRollNo: (state) => {
      if (state.ledger?.marksLedger.length) {
        if (state.sorted === "" || state.sorted === "des") {
          state.ledger.marksLedger = state.ledger.marksLedger.sort(
            (a, b) => a.student_roll_number - b.student_roll_number
          );
          state.sorted = "asc";
        } else {
          state.ledger.marksLedger = state.ledger.marksLedger.sort(
            (a, b) => b.student_roll_number - a.student_roll_number
          );
          state.sorted = "des";
        }
      }
    },
    setSearchParams: (
      state,
      {
        payload,
      }: PayloadAction<{ exam: string; grade: string; section: string }>
    ) => {
      state.searchParams = { ...payload };
    },
    resetState: (state) => {
      state.result_generated = true;
      state.ledger = null;
      state.sorted = "";
      state.searchParams = {
        exam: "",
        grade: "",
        section: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarksLedger.pending, (state) => {
      state.loading = true;
      state.result_generated = true;
      state.sorted = "";
    });
    builder.addCase(
      getMarksLedger.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          exam_info: ExamInfoI;
          exam_subjects: ExamSubjectI[];
          student_marks: MarksLedgerI[];
          grade_info: string;
          total_students: number;
        }>
      ) => {
        state.ledger = {
          marksLedger: payload.student_marks,
          examInfo: payload.exam_info,
          examSubjects: payload.exam_subjects,
          grade_info: payload.grade_info,
          total_students: payload.total_students,
        };
        state.loading = false;
      }
    );
    builder.addCase(getMarksLedger.rejected, (state, { payload }: any) => {
      if (payload?.result_generated === "False") {
        state.result_generated = false;
      } else {
        state.result_generated = true;
      }
      state.loading = false;
      state.ledger = null;
    });

    builder.addCase(generateGradeResult.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(generateGradeResult.fulfilled, (state) => {
      state.loading = false;
      state.result_generated = true;
    });
    builder.addCase(generateGradeResult.rejected, (state) => {
      state.loading = false;
      state.result_generated = false;
    });
  },
});

export const { resetState, sortByRollNo, setSearchParams } =
  marksLedgerSlice.actions;

export default marksLedgerSlice.reducer;
