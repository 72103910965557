import {
  GET_STUDENT_COMPLAINT_LOADING,
  GET_STUDENT_COMPLAINT_ERROR,
  GET_STUDENT_COMPLAINT_SUCCESS,
  ADD_STUDENT_COMPLAINT_LOADING,
  ADD_STUDENT_COMPLAINT_ERROR,
  ADD_STUDENT_COMPLAINT_SUCCESS,
  DELETE_STUDENT_COMPLAINT_LOADING,
  DELETE_STUDENT_COMPLAINT_ERROR,
  DELETE_STUDENT_COMPLAINT_SUCCESS,
  UPDATE_STUDENT_COMPLAINT_LOADING,
  UPDATE_STUDENT_COMPLAINT_ERROR,
  UPDATE_STUDENT_COMPLAINT_SUCCESS,
  TeacherComplaintTypeI,
  StudentComplaintDispatchTypes,
} from "../../actions/TeacherDash/TeacherComplain/TeacherComplainActionTypes";

interface InitialStateI {
  loading: boolean;
  student_complaint: TeacherComplaintTypeI[];
  recently_added: any;
  errors: any;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  student_complaint: [],
  recently_added: null,
  errors: null,
  add_or_update: false,
};

const TeacherComplainReducer = (
  state: any = initialState,
  action: StudentComplaintDispatchTypes
): any => {
  switch (action.type) {
    case GET_STUDENT_COMPLAINT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_COMPLAINT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_COMPLAINT_SUCCESS:
      return {
        loading: false,
        student_complaint: action.payload,
      };

    case ADD_STUDENT_COMPLAINT_LOADING:
      return {
        ...state,
        loading: true,
        errors: "p",
        add_or_update: false,
      };

    case ADD_STUDENT_COMPLAINT_ERROR:
      return {
        ...state,
        loading: false,
        errors: "q",
        add_or_update: true,
      };

    case ADD_STUDENT_COMPLAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        // student_complaint: [...state.student_complaint, action.payload],
        recently_added: action.payload,
        add_or_update: true,
      };

    case UPDATE_STUDENT_COMPLAINT_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_STUDENT_COMPLAINT_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case UPDATE_STUDENT_COMPLAINT_SUCCESS:
      const current_data: TeacherComplaintTypeI[] = state.student_complaint;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        student_complaint: current_data,
        add_or_update: true,
      };

    case DELETE_STUDENT_COMPLAINT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_STUDENT_COMPLAINT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_STUDENT_COMPLAINT_SUCCESS:
      const new_data: TeacherComplaintTypeI[] = state.student_complaint;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        loading: false,
        student_complaint: new_data,
      };

    default:
      return state;
  }
};

export default TeacherComplainReducer;
