import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { DesignationTypeI } from "../../actions/HumanResource/Designation/DesignationActionTypes";
import { DesignationForm, DesignationTable, HRsidebar } from "../../components";
import APEform from "../../components/Examination/APE/APEform";
import APETable from "../../components/Examination/APE/APEtable";
import ExaminationSidebar from "../../components/Examination/ExaminationSidebar/ExaminationSideBar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const APEpage = () => {
  const [formData, setFormData] = useState<any | null>(null);
  const [count, setCount] = useState<number>(0);

  const fetchEditData = (data: any) => {
    setFormData({ ...data, count: count });
  };
  const nav = <ExaminationSidebar ape />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <APEform editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <APETable onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default APEpage;
