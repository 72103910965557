import { Grid, InputLabel, Paper, TextField } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import { tblStyle } from '../../StudentDashboardStyles';
import {
  assignmentStatusOptions,
  Tupple,
} from '../../../../common/json.constant';
import { GetStudentAssignmentBySubjectID } from '../../../../actions/StudentDash/StudentAssignment/StudentAssignmentAction';

const StudentAssignmentSearch = () => {
  const classes = useFormStyles();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { register } = useForm<any>();
  const [selectedStatus, setSelectedStatus] = useState<Tupple | null>(null);

  // useEffect(() => {
  //   dispatch(GetStudentAssignmentBySubjectID(id))
  // }, [])

  useEffect(() => {
    if (selectedStatus) {
      const status = selectedStatus.key === 'S' ? true : false;
      dispatch(GetStudentAssignmentBySubjectID(id, status));
    } else {
      dispatch(GetStudentAssignmentBySubjectID(id));
    }
  }, [selectedStatus]);

  return (
    <>
      <Paper className={classes.pageContentBox} style={tblStyle}>
        <form className={classes.form}>
          <Grid container>
            <Grid item xs={9} className={classes.formTitle}>
              <span>Assignment Search</span>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Filter by status</InputLabel>
              <Autocomplete
                style={{
                  padding: 0,
                }}
                value={selectedStatus}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: Tupple | null
                ) => setSelectedStatus(value)}
                options={assignmentStatusOptions}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="status_name"
                    inputRef={register({ required: false })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default StudentAssignmentSearch;
