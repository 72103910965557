// ---------------- <START> module import starts ------------------//
import { LinearProgress, Paper } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, InputLabel, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { useForm } from "react-hook-form";
import { useFormStyles } from "../../Styles/FormStyles";
import { setSnackbar } from "../../../actions/SnackbarAction";
import AddNewButton from "../../Reusable/Buttons/AddNewButton";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  AddSection,
  UpdateSection,
} from "../../../actions/Academics/Section/SectionAction";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
// ---------------- <END> module import ends ------------------//

interface PropsI {
  editData?: FormDataI | null;
  onEditMode: (value: boolean) => void;
}

interface FormDataI {
  id: string;
  name: string;
  created_by: string;
  general_info_name: string;
}

// ---------------- <START> section form component starts ------------------//
const SectionForm = (props: PropsI) => {
  const { editData, onEditMode } = props;

  const classes = useFormStyles();

  //= =============================<START> Component States <START>=================================//
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  //= =============================<END> Component States <END>=================================//

  //==================================<START>REDUX REDUCER<START>=============================//

  const dispatch = useDispatch();
  const sectionLoading = useSelector(
    (state: RootStore) => state.section.loading
  );
  const add_or_update = useSelector((state: RootStore) => state.section.add_or_update);

  //==================================<END>REDUX REDUCER<END>=============================//

  //===================================<START>CYCLE HOOKS<START>===========================//
  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      setEditMode(false);
      setEditID(null);
    }
  }, [add_or_update])

  useEffect(() => {
    setLoading(sectionLoading);
  }, [sectionLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  //===================================<END>CYCLE HOOKS<END>===========================//

  //= =============================<START> Event Handlers <START>=================================//

  const handleEditTableData = (data: FormDataI) => {
    setEditMode(true);
    setValue("name", data.name);
    setEditID(data.id);
  };

  //= =============================<END> Event Handlers <END>=================================//

  //= ===================================<START> REACT HOOK FORM <START>==========================//

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    setError,
    clearErrors,
    errors,
  } = useForm({
    mode: "onChange",
  });

  const onFormSubmit = (data: FormDataI) => {
    setIsBtnLoading(true);
    if (editMode) {
      editID != null && dispatch(UpdateSection(editID, data));
    } else {
      dispatch(AddSection(data));
    }
  };

  //= ===================================<END> REACT HOOK FORM <END>==========================//

  const handleReset = () => {
    reset();
    setEditMode(false);
    setEditID(null);
    onEditMode(false);
  };

  //----------------------ERROR HANDLING---------------------------//
  const [serverErrors, setServerErrors] = useState<any>(null);
  const sectionSelector = useSelector((state: RootStore) => state.section);
  const errorsData = sectionSelector.errors;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: "serverSideError",
            message: errorsData.error[elem] && errorsData.error[elem],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (sectionSelector?.recent) {
      handleReset();
    }
  }, [sectionSelector]);

  return (
    <FormLayout
      title={editMode ? "Edit Section" : "Add Section"}
      onSubmit={handleSubmit(onFormSubmit)}
      editMode={editMode}
      loading={loading}
      onClick={!isBtnLoading && handleReset}
      add_or_update={isBtnLoading}
    >
      <CustomizedTextField
        label=" Section"
        placeholder="Section Name"
        name="name"
        required
        error={errors["name"] ? "Required Field." : ""}
        inputRef={register({ required: true })}
        onChange={() => clearErrors("detail")}
      />
      <span className={classes.validationErrorInfo}>
        {errors.detail?.type === "serverSideError" && errors.detail.message}
      </span>
    </FormLayout>
  );
};
// ---------------- <END> section form components ends ------------------//

export default SectionForm;
