/* eslint-disable react/no-find-dom-node */
/* eslint-disable prefer-const */
/* eslint-disable react/no-string-refs */
// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect, useRef } from 'react';
import { FileCopyOutlined, PrintOutlined } from '@material-ui/icons';
import {
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { useTableStyles } from '../../Styles/TableStyles';
import TableContainer from '@material-ui/core/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import ReactToPrint from "react-to-print";
import {
  GetGradeFee,
  PostGradeFee,
} from '../../../actions/Fees/FeeType/FeeAction';
import {
  GradeFeesI,
  GradeFeeMetaI,
  GradeFeePostRequestI,
} from '../../../actions/Fees/FeeType/FeeActionTypes';
import { Fee_TypeI } from '../../../actions/Fees/FeeType/FeeTypeActionTypes';
import { GradeTypeI } from '../../../actions/Academics/Grade/GradeActionTypes';
import { GetGrades } from '../../../actions/Academics/Grade/GradeAction';
import {
  GetAllFeeType,
  GetFeeType,
  GetFeeTypeWithFilters,
} from '../../../actions/Fees/FeeType/FeeTypeAction';
import { ActionRoleAccess } from '../../utils/rolesConfig';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useDataGridStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      '& th': {
        backgroundColor: '#e3e3e3',
      },
      '& td': {
        '&:first-child': {
          backgroundColor: '#e3e3e3',
        },
      },
      '& .MuiTableCell-sizeSmall': {
        padding: '2px 2px 0px 2px',
        borderBottom: '0px',
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

interface GradeI {
  id: string;
  name: string;
}

interface ModGradeI {
  id: string;
  name: string;
  sortIndex: number;
}

interface FeeI {
  id: string;
  name: string;
}

interface TableDataI {
  id: string;
  name: string;
  fee_type: string;
  category: boolean;
  amount: number;
  due_date: string;
  due_day_count: number;
  due_days: string;
  due_period_type: string;
  description: string;
  applies_to: any;
  apply: any;
  count: number;
}

const sortClasses = (array: GradeI[]) => {
  const mod_arr: ModGradeI[] = [];
  array.map((item: GradeI) => {
    switch (item.name) {
      case 'Play Group':
        mod_arr.push({
          ...item,
          sortIndex: 0,
        });
        break;
      case 'KG':
        mod_arr.push({
          ...item,
          sortIndex: 1,
        });
        break;
      case 'Nursery':
        mod_arr.push({
          ...item,
          sortIndex: 2,
        });
        break;

      case 'LKG':
        mod_arr.push({
          ...item,
          sortIndex: 3,
        });
        break;

      case 'UKG':
        mod_arr.push({
          ...item,
          sortIndex: 4,
        });
        break;

      case 'One':
        mod_arr.push({
          ...item,
          sortIndex: 5,
        });
        break;

      case 'Two':
        mod_arr.push({
          ...item,
          sortIndex: 6,
        });
        break;

      case 'Three':
        mod_arr.push({
          ...item,
          sortIndex: 7,
        });
        break;

      case 'Four':
        mod_arr.push({
          ...item,
          sortIndex: 8,
        });
        break;

      case 'Five':
        mod_arr.push({
          ...item,
          sortIndex: 9,
        });
        break;

      case 'Six':
        mod_arr.push({
          ...item,
          sortIndex: 10,
        });
        break;

      case 'Seven':
        mod_arr.push({
          ...item,
          sortIndex: 11,
        });
        break;

      case 'Eight':
        mod_arr.push({
          ...item,
          sortIndex: 12,
        });
        break;

      case 'Nine':
        mod_arr.push({
          ...item,
          sortIndex: 13,
        });
        break;

      case 'Ten':
        mod_arr.push({
          ...item,
          sortIndex: 14,
        });
        break;

      default:
        break;
    }
  });

  mod_arr.sort(function (a, b) {
    return a.sortIndex - b.sortIndex;
  });

  return mod_arr;
};

const FeesTable = () => {
  const classes = useTableStyles();
  const tableCss = useDataGridStyles();

  const [editMode, setEditMode] = useState<boolean>(false);

  const [tableContent, setTableContent] = useState<GradeFeePostRequestI[]>([]);

  const [feeCategory, setFeeCategory] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const gradeLoading = useSelector((state: RootStore) => state.grade.loading);
  const feeLoading = useSelector((state: RootStore) => state.fee_type.loading);
  const gradeFeeLoading = useSelector((state: RootStore) => state.fee.loading);

  const [grades, setGrades] = useState<GradeI[]>([]);
  const dispatch = useDispatch();

  const feeTypeSelector = useSelector((state: RootStore) => state.fee_type);
  const gradeSelector = useSelector((state: RootStore) => state.grade);

  const gradeFeeSelector = useSelector(
    (state: RootStore) => state.fee.gradeFees
  );
  const gradeFeeAction = useSelector(
    (state: RootStore) => state.fee.actionPerformed
  );

  useEffect(() => {
    dispatch(GetGradeFee());
    dispatch(GetGrades());
    dispatch(GetAllFeeType(1));
  }, []);

  const componentRef = useRef<any>();


  useEffect(() => {
    if (!gradeLoading && !feeLoading && !gradeFeeLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [gradeLoading, feeLoading, gradeFeeLoading]);

  useEffect(() => {
    const data = gradeFeeSelector;
    const mod_data: GradeFeePostRequestI[] = [];

    if (data?.length) {
      data.map((item: GradeFeesI) => {
        item.fees.map((element: GradeFeeMetaI) => {
          mod_data.push({
            id: element.id,
            fee: element.fee__id,
            grade: item.grade_id,
            amount: element.amount,
          });
        });
      });

      setTableContent(mod_data);
    }
  }, [gradeFeeSelector]);

  useEffect(() => {
    if (gradeFeeAction) {
      setEditMode(false);
    }
  }, [gradeFeeAction]);

  useEffect(() => {
    console.log({ feeTypeSelector });
    const data = feeTypeSelector.feeType.results;
    if (data?.length) {
      setFeeCategory(
        data.map((item: Fee_TypeI) => ({
          id: item.id,
          name: item.name,
        }))
      );
    }
  }, [feeTypeSelector]);

  useEffect(() => {
    const data = gradeSelector.grades;
    console.log({ data });
    if (data?.length) {
      const classes = data.map((item: GradeTypeI) => ({
        id: item.id,
        name: item.grade_name,
      }));

      const sorted_data = sortClasses(classes);
      setGrades(sorted_data);
    }
  }, [gradeSelector]);

  // Function for handling action icons click event

  const handleAmountChange = (
    value: string,
    grade_id: string,
    fee_id: string
  ) => {
    const fee_arr: GradeFeePostRequestI | undefined = tableContent.find(
      (item: GradeFeePostRequestI) =>
        item.grade === grade_id && item.fee === fee_id
    );

    if (fee_arr) {
      fee_arr.amount = Number(value);
    } else {
      setTableContent(
        tableContent.concat({
          id: null,
          grade: grade_id,
          fee: fee_id,
          amount: Number(value),
        })
      );
    }
  };

  const getFeeAmount = (grade_id: string, fee_id: string): number => {
    const fee_arr: GradeFeePostRequestI | undefined = tableContent.find(
      (item: GradeFeePostRequestI) =>
        item.grade === grade_id && item.fee === fee_id
    );

    if (fee_arr) {
      const amount = fee_arr.amount;

      return amount;
    }
    return 0;
  };

  const populateRow = (grade: GradeI[], fee_id: string) => {
    return grade.map((item: GradeI, index: number) => (
      <TableCell
        key={index + 1}
        align="center"
        style={{
          width: '200px',
        }}
      >
        {editMode ? (
          // <TextField
          //   variant="outlined"
          //   defaultValue={getFeeAmount(item.id, fee_id)}
          //   type="number"
          //   onBlur={(event: any) =>
          //     handleAmountChange(event.target.value, item.id, fee_id)
          //   }
          // />
          <input
            name={index.toString()}
            id={index.toString()}
            style={{ width: '100px' }}
            type="number"
            defaultValue={getFeeAmount(item.id, fee_id)}
            className="custom-input"
            onBlur={(event: any) =>
              handleAmountChange(event.target.value, item.id, fee_id)
            }
          />
        ) : getFeeAmount(item.id, fee_id) ? (
          getFeeAmount(item.id, fee_id)
        ) : (
          '-'
        )}
      </TableCell>
    ));
  };

  const handleEdit = () => {
    editMode ? dispatch(PostGradeFee(tableContent)) : setEditMode(true);
  };

  const handleCancel = () => {
    dispatch(GetGradeFee());
    setEditMode(false);
  };

  const handleAllClick = () => {
    dispatch(GetAllFeeType(1));
  };

  const handleOneTimeClick = () => {
    dispatch(GetFeeTypeWithFilters('O'));
  };

  const handleMonthlyClick = () => {
    dispatch(GetFeeTypeWithFilters('M'));
  };

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Applied Fees</Typography>
        <Grid container>
          <Grid item xs={9}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button onClick={() => handleAllClick()}>All</Button>
              <Button onClick={() => handleOneTimeClick()}>One Time</Button>
              <Button onClick={() => handleMonthlyClick()}>Monthly</Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={3}>
            <Grid container justifyContent="flex-end">
              {editMode && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleCancel()}
                  style={{
                    marginBottom: '10px',
                    minWidth: '100px',
                    marginRight: '15px',
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleEdit()}
                style={{ marginBottom: '10px', minWidth: '100px' }}
              >
                {editMode ? 'Save' : 'Edit'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ReactToPrint
              trigger={() => (
                <p
                  style={{
                    backgroundColor: "none",
                    color: "#808080",
                    fontWeight: "bold",
                    borderRadius: "0px",
                    margin: "0px",
                    textAlign: "center",
                    padding: "5px 0px",
                    cursor: "pointer",
                  }}
                >
                  <PrintOutlined />
                </p>
              )}
              content={() => componentRef.current}
              removeAfterPrint={true}
              copyStyles={true}
            />
          </div>

          <TableContainer
            component={Paper}

            style={{ maxHeight: 'calc(100vh - 10vh)', overflowY: 'auto' }}
          >
            <Table
              stickyHeader
              className={tableCss.table}
              aria-label="sticky table"
              ref={componentRef}
            >
              <TableHead>
                <TableRow>
                  <div style={{ width: '200px', backgroundColor: '#e4e3e3' }}>
                    <TableCell align="left">
                      <>
                        <span style={{ paddingLeft: '12px' }}></span>Class/Fee
                        Category
                      </>
                    </TableCell>
                  </div>
                  {grades.map((item: GradeI) => (
                    <TableCell key={item.id} align="center">
                      {item.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {feeCategory?.map((fee_item: FeeI, index: number) => (
                  <TableRow key={index + 1}>
                    <TableHead>
                      <TableCell
                        align="left"
                        style={{
                          height: '100%',
                          width: '200px',
                          paddingLeft: '12px',
                          backgroundColor: '#e4e3e3',
                        }}
                      >
                        <>
                          <span style={{ paddingLeft: '12px' }}>
                            {fee_item.name}
                          </span>
                        </>
                      </TableCell>
                    </TableHead>
                    {populateRow(grades, fee_item.id)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Paper>
      <Backdrop className={tableCss.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default FeesTable;
