import {
  Grid,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { tblStyle } from "../../components/Styles/TeacherDashboardStyles";
import { useSelector, useDispatch } from "react-redux";
import {
  GetCheckinCheckout,
  PostCheckinCheckout,
} from "../../../../actions/TeacherDash/Attendance/TeacherAttendanceAction";
import { RootStore } from "../../../../store";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

const CheckinCheckout: React.FC = () => {
  const [current] = useState(new Date());
  const [todayDate, setTodayDate] = useState<string>("");
  const [lat, setLat] = useState<any>(null);
  const [long, setLong] = useState<any>(null);

  const dispatch = useDispatch();

  const teacherAttendanceState = useSelector(
    (state: RootStore) => state.teacher_attendance?.teacher_attendance
  );
  const isLoading = useSelector(
    (state: RootStore) => state.teacher_attendance?.loading
  );

  useEffect(() => {
    if (
      teacherAttendanceState[0]?.data[0].attendance_type !== "CI" &&
      teacherAttendanceState[0]?.data[1].attendance_type !== "CO"
    ) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLong(position.coords.longitude);
      });
    }

    const dd = String(current.getDate()).padStart(2, "0");
    const mm = String(current.getMonth() + 1).padStart(2, "0");
    const yyyy = current.getFullYear();

    const date = yyyy + "-" + mm + "-" + dd;
    setTodayDate(date);
    dispatch(GetCheckinCheckout(date, date));
  }, []);

  const handleCheckin = (event: any) => {
    if (event.target.checked) {
      const finalValue = {
        attendance_type: "CI",
        location: {
          latitude: lat,
          longitude: long,
        },
      };
      dispatch(PostCheckinCheckout(finalValue, todayDate));
    }
  };
  const handleCheckout = (event: any) => {
    if (event.target.checked) {
      const finalValue = {
        attendance_type: "CO",
        location: {
          latitude: lat,
          longitude: long,
        },
      };
      dispatch(PostCheckinCheckout(finalValue, todayDate));
    }
  };

  const checkCheckoutDisable = (data: any) => {
    if (data !== null) {
      if (
        data?.data[0]?.attendance_type === "CI" &&
        data?.data[1]?.attendance_type !== "CO"
      ) {
        return false;
      } else if (data?.data[1]?.attendance_type === "CO") {
        return true;
      } else if (data?.data[0]?.attendance_type !== "CI") {
        return true;
      }
    }
  };

  const checkCheckoutText = (data: any) => {
    if (data !== null) {
      if (
        data?.data[0]?.attendance_type === "CI" &&
        data?.data[1]?.attendance_type !== "CO"
      ) {
        return "Not yet checked out";
      } else if (data?.data[1]?.attendance_type === "CO") {
        return "Yo've been already checked out";
      } else if (data?.data[0]?.attendance_type !== "CI") {
        return "Do Check in first";
      }
    }
  };

  return (
    <Paper style={tblStyle}>
      <Grid container justifyContent="center">
        <b>Attendance for Today: {todayDate}</b>
        {/* {current.getHours() +
          ":" +
          current.getMinutes() +
          ":" +
          current.getSeconds()} */}
      </Grid>
      <Grid
        container
        style={{ marginTop: "30px", gap: 40, marginBottom: "50px" }}
        justifyContent="center"
      >
        <Grid
          container
          item
          xs={5}
          justifyContent="center"
          style={{ border: "15px solid #5b7bc1", height: "200px" }}
          direction="column"
          alignItems="center"
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      name="checkin"
                      checked={
                        teacherAttendanceState[0] &&
                        teacherAttendanceState[0]?.data[0]?.attendance_type ===
                          "CI"
                          ? true
                          : false
                      }
                      onChange={(event) => handleCheckin(event)}
                      disabled={
                        teacherAttendanceState[0] &&
                        teacherAttendanceState[0]?.data[0]?.attendance_type ===
                          "CI"
                          ? true
                          : false
                      }
                    />
                  }
                  label={
                    teacherAttendanceState[0] &&
                    teacherAttendanceState[0]?.data[0]?.attendance_type === "CI"
                      ? "Yo've already checked in"
                      : "Not yet checked in"
                  }
                />
              </FormGroup>
              <i>
                {teacherAttendanceState[0] &&
                teacherAttendanceState[0]?.data[0]?.attendance_type === "CI"
                  ? "0n " +
                    moment(
                      teacherAttendanceState[0]?.data[0]?.attendance_time &&
                        "2020-10-10T" +
                          teacherAttendanceState[0]?.data[0]?.attendance_time
                    ).format("hh:mm:ss a")
                  : null}
              </i>
            </>
          )}
        </Grid>
        <Grid
          container
          item
          xs={5}
          justifyContent="center"
          style={{ border: "15px solid #5b7bc1", height: "200px" }}
          direction="column"
          alignItems="center"
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        checkCheckoutDisable(teacherAttendanceState[0])
                          ? true
                          : false
                      }
                      color="primary"
                      name="checkin"
                      disabled={checkCheckoutDisable(teacherAttendanceState[0])}
                      onChange={(event) => handleCheckout(event)}
                    />
                  }
                  label={checkCheckoutText(teacherAttendanceState[0])}
                />
              </FormGroup>
              <i>
                {teacherAttendanceState &&
                teacherAttendanceState[0]?.data[1]?.attendance_type === "CO"
                  ? "0n " +
                    moment(
                      teacherAttendanceState[0]?.data[1]?.attendance_time &&
                        "2020-10-10T" +
                          teacherAttendanceState[0]?.data[1]?.attendance_time
                    ).format("hh:mm:ss a")
                  : null}
              </i>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CheckinCheckout;
