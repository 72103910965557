import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { RootStore } from "../../../../../store";
import { useStyles } from "../../../../../components/Styles/FormStyles";
import { Grid, InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { assignmentGradeOptions, Tupple } from "../../../../../common/json.constant"
import { FormSaveLoadingButton } from "../../../../../components/Reusable/Buttons/FormButton"
import SaveIcon from "@material-ui/icons/Save";
import Spinner from "../../../../../common/spinnerSVG/Spinner"
import { AssignAssignmentMarks, GetSubmittedAssignments, GetUnSubmittedAssignments } from "../../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction"
import CustomizedNepaliDatePicker from "../../../../../components/Reusable/Inputs/NepaliDatePicker"
import { CUR_NEPALI_DATE } from "../../../../../components/utils/nepaliDateUtils"

const AssignMarks = ({ id, file, onClose, status: parentStatus }: { id: string, file: any, onClose: any, status: Tupple | null }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [status, setStatus] = useState<Tupple | null>(null);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
    const [deadlineDate, setDeadlineDate] = useState<string>(CUR_NEPALI_DATE)

    const { added } = useSelector((state: RootStore) => state.teacher_assignment)

    const { register, handleSubmit, errors, setValue, setError, clearErrors, reset } =
        useForm<any>({
            mode: "onChange",
        });

    useEffect(() => {
        reset();
    }, [status])

    useEffect(() => {
        if (parentStatus !== null && added) {
            parentStatus.key === "S" && dispatch(GetSubmittedAssignments(id))
            parentStatus.key === "U" && dispatch(GetUnSubmittedAssignments(id))
        }
    }, [added])

    const handleStatusChange = (value: Tupple) => {
        setStatus(value);
    }

    const handleDeadlineDate = (data: string) => {
        setDeadlineDate(data);
        clearErrors('assign_on');
        clearErrors('error');
    };

    const onSubmitModal = (data: any) => {
        if (status?.key === "Grade") {
            const submitData = {
                remarks: data.remarks,
                assignment_rating: data.rating,
                marks: data.marks,
                assignment_status: "Completed",
                attached_file: file
            }
            dispatch(AssignAssignmentMarks(id, submitData))
            onClose(false)
        } else if (status?.key === "Redo") {
            const submitData = {
                remarks: data.remarks,
                assignment_status: "Redo",
                extended_deadline: deadlineDate.concat(" ", data.deadline_time).concat(":", "00"),
                attached_file: file
            }
            dispatch(AssignAssignmentMarks(id, submitData))
            onClose(false)
        }
    }

    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmitModal)}>
            <Grid container>
                <Grid item md={12} className={classes.formWrapper} style={{ marginLeft: "3rem" }} justifyContent="space-between">

                    <Grid item md={10} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                        <InputLabel>
                            Status <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <Autocomplete
                            onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                handleStatusChange(value)
                            }
                            options={assignmentGradeOptions}
                            getOptionLabel={(option) => option.value}
                            openOnFocus={true}
                            value={status}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="status"
                                    placeholder="Select Status"
                                    variant="outlined"
                                    inputRef={register({ required: true })}
                                />
                            )}
                        />
                    </Grid>

                    {status !== null && status.key === "Grade" && (
                        <Grid container spacing={3}>
                            <Grid item md={5} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                                <InputLabel>
                                    Marks <span style={{ color: "red" }}>*</span>
                                </InputLabel>
                                <TextField
                                    variant="outlined"
                                    name="marks"
                                    placeholder="Marks"
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    inputRef={register({
                                        required: true,
                                    })}
                                />
                                {/* {formsValidationCheckup({
                                errors: errors,
                                inputName: "grade",
                                requiredMessage: "Class is Required",
                                serverSideMessage:
                                    errors.class?.type === "serverSideError" &&
                                    errors.class.message,
                            })} */}
                            </Grid>
                            <Grid item md={5} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                                <InputLabel>
                                    Rating
                                    <span style={{ color: "red" }}>*</span>
                                </InputLabel>
                                <TextField
                                    variant="outlined"
                                    name="rating"
                                    placeholder="Rating"
                                    type="number"
                                    inputProps={{ min: 1, max: 5 }}
                                    inputRef={register({
                                        required: true,
                                    })}
                                />
                                {/* {formsValidationCheckup({
                                errors: errors,
                                inputName: "section",
                                requiredMessage: "Section is Required",
                                serverSideMessage:
                                    errors.section?.type === "serverSideError" &&
                                    errors.section.message,
                            })} */}
                            </Grid>
                        </Grid>
                    )}

                    {status !== null && status.key === "Redo" && (
                        <Grid item md={10} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                            <CustomizedNepaliDatePicker
                                label="Assign New Deadline"
                                value={deadlineDate}
                                setValue={handleDeadlineDate}
                                name="deadline"
                                required
                                error={errors['deadline']?.message}
                                inputRef={register({
                                    required: true,
                                })}
                            />
                            <TextField
                                fullWidth={true}
                                type="time"
                                name="deadline_time"
                                variant="outlined"
                                inputRef={register({ required: "This field is required" })}
                            />
                        </Grid>
                    )}

                    <Grid item md={10} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                        <InputLabel>Remarks<span style={{ color: "red" }}>*</span></InputLabel>
                        <TextField
                            variant="outlined"
                            name="remarks"
                            placeholder="Remarks"
                            multiline
                            rows={4}
                            inputRef={register({
                                required: true,
                            })}
                        />
                    </Grid>

                    <Grid container justifyContent='flex-start' style={{ marginBottom: "1em" }}>
                        <FormSaveLoadingButton
                            type="submit"
                            icon={isBtnLoading ? <Spinner /> : <SaveIcon />}
                            disabled={isBtnLoading ? true : false}
                            text={isBtnLoading ? "Assigning..." : "Assign"}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </form >
    )
}

export default AssignMarks