import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
interface PropsI {
  title: string;
  open: boolean;
  onClose: (value: boolean) => void;
  actionConfirmed: (data?: any) => void;
  dialogTitle?: string;
  okText?: string | boolean;
  children?: React.ReactNode;
  cancel?: false;
  fullWidth?: boolean;
  maxWidth?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalHeader: {
      padding: theme.spacing(1, 3),
      color: '#fff',
      backgroundColor: '#5767a4',
      '& .MuiTypography-body1': {},
    },
    modalContainer: {
      padding: theme.spacing(3, 1),
    },
    modalFooter: {
      padding: theme.spacing(0, 0, 4, 0),
      '& .MuiButton-contained': {
        color: '#fff',
        backgroundColor: '#e96c5a',
      },
    },
  })
);

const CustomizedPopUp = (props: PropsI) => {
  const classes = useStyles();
  const { title, open, onClose, actionConfirmed } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleActionConfirmed = () => {
    actionConfirmed();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={props.maxWidth ? props.maxWidth : 'sm'}
      fullWidth={props?.fullWidth}
    >
      <div style={{ minWidth: '350px' }}>
        <Grid
          container
          className={classes.modalHeader}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{title}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </Grid>
        <Grid
          container
          className={classes.modalContainer}
          justifyContent="center"
        >
          <Typography>
            {props.dialogTitle
              ? props.dialogTitle
              : 'Are you sure to delete this record?'}
          </Typography>
        </Grid>
        {props.children}
        <Grid
          container
          className={classes.modalFooter}
          justifyContent="space-around"
        >
          {props.cancel !== false && (
            <Button onClick={handleClose} variant="outlined" color="primary">
              Cancel
            </Button>
          )}
          {props.okText !== false && (
            <Button onClick={handleActionConfirmed} variant="contained">
              {props.okText ? props.okText : 'Delete'}
            </Button>
          )}
        </Grid>
      </div>
    </Dialog>
  );
};

export default CustomizedPopUp;
