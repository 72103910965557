// ---------------- <START> module import ------------------//
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import {
  AddTeacherAssignment,
  GetTeacherAssignmentById,
  UpdateTeacherAssignment,
  GetAssignmentFiles,
} from '../../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentAction';
import CustomizedTextField from '../../../../components/Reusable/Inputs/TextField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Grid,
  InputLabel,
  Paper,
  Typography,
  makeStyles,
  Theme,
  Chip,
  TextField,
} from '@material-ui/core';
import CustomizedNepaliDatePicker from '../../../../components/Reusable/Inputs/NepaliDatePicker';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import CustomizedSelect from '../../../../components/Reusable/Inputs/Select';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import {
  FormCreateButton,
  FormDraftButton,
  FormEditButton,
} from '../../../../components/Reusable/Buttons/FormButton';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../../../components/utils/dateConverter";
import { timeFormat } from "../../../../components/utils/dateTimeFormat"
import { CUR_NEPALI_DATE } from "../../../../components/utils/nepaliDateUtils";
import { Alert } from "@material-ui/lab";
import firstWordCapital from "../../../../components/utils/firstWordCapital";
import { isObjectEmpty } from "../../../../components/utils/utils";
import FileUploadZone from "../../../../components/TeacherDash/Assignments/AssignmentForm/FileUploadZone";
import {
  filterStyle,
  newFilterStyle,
} from "../../../StudentDashboard/StudentDashboardStyles";
import { FormCheckBox } from "../../../../components/Reusable/Inputs/Checkbox";
import { Tupple, assignmentCategoryOptions, submissionTypeOptions } from "../../../../common/json.constant"
import { TeacherClassSubjectsI } from '../../../../rtk/features/timetable/timetable'
import Popup from "../../../../components/Reusable/Dialogs/Popups"
import AssignmentUpload from './AssignmentUpload'
import { CusRadioGroup } from "../../../../components/Reusable/Inputs/Radio"
import FormControl from "@material-ui/core/FormControl";
import { monthsToQuarters } from 'date-fns';
// ---------------- <END> module import ------------------//

export const useUploadStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  faceImage: {
    color: theme.palette.primary.light,
  },
  upload: {
    padding: theme.spacing(2),
  },
}));

interface LocationState {
  data: any;
}

const RADIO_OPTIONS = [
  {
    id: '1',
    name: "Assign Now"
  },
  {
    id: '2',
    name: "Assign Later"
  }
]

// ---------------- <START> Assignment Form Component ------------------//
const AssignmentForm = () => {
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const uploadClasses = useUploadStyles();
  const location = useLocation<LocationState>();

  const { register, handleSubmit, setValue, errors, setError, clearErrors } =
    useForm({ mode: 'onChange' });

  // States
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [assignDate, setAssignDate] = useState<string>(CUR_NEPALI_DATE);
  const [deadlineDate, setDeadlineDate] = useState<string>(CUR_NEPALI_DATE);
  const [editorState, setEditorState] = useState<any>();
  const [isFile, setIsFile] = useState<boolean>(false);
  const [today] = useState<any>(new Date());
  const [serverErrors, setServerErrors] = useState<any>(null);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [errorFields] = useState<string[]>(['error', 'non_field_errors']);
  const [assignmentFiles, setAssignmentFiles] = useState<any>([]);
  const [deadlineStatus, setDeadlineStatus] = useState<boolean>(false);
  const [addMarksStatus, setAddMarksStatus] = useState<boolean>(false);
  const [selectedAssignmentCategory, setSelectedAssignmentCategory] = useState<Tupple | null>(null)
  const [selectedSubmissionType, setSelectedSubmissionType] = useState<Tupple | null>(null)
  const [publish, setPublish] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [propsData, setPropsData] = useState<any>(null);
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>(RADIO_OPTIONS[0].id)

  const teacherAssignmentState = useSelector(
    (state: RootStore) => state.teacher_assignment
  );
  const { errors: errorsData, loading, assignmentById, document } = teacherAssignmentState;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (assignmentById !== null) {
      setUpdate(true)
      setUpdateData(assignmentById)
    }
  }, [assignmentById])

  useEffect(() => {
    if (location?.state) {
      setPropsData(location.state.data)
      setUpdate(false);
      setUpdateData(null);
    } else {
      dispatch(GetTeacherAssignmentById(id))
      dispatch(GetAssignmentFiles(id))
    }
  }, [location])

  useEffect(() => {
    if (!update) {
      let temp = moment(today).format("YYYY-MM-DD");
      temp = dateConverterAdToBs(temp);
      setAssignDate(temp);
      setDeadlineDate(temp);
    }
  }, []);

  // checking file state data is File Object or not.
  const isFilex = (input: any) => {
    if ('File' in window && input instanceof Blob) return true;
    else return false;
  };
  useEffect(() => {
    setIsFile(isFilex(selectedFile && selectedFile[0]));
  }, [selectedFile]);

  /* Close redirect when user has submitted form, if state is not loading,
  has not server errors and has no hook-form errors */
  useEffect(() => {
    if (hasSubmitted && !teacherAssignmentState.loading) {
      if (!errorsData && isObjectEmpty(errors)) {
        history.push('/assignment');
        setHasSubmitted(false);
      }
    }
  }, [teacherAssignmentState, hasSubmitted]);

  const handleAssignDate = (data: string) => {
    setAssignDate(data);
    clearErrors('assign_on');
    clearErrors('error');
  };

  const handleDeadline = (data: string) => {
    setDeadlineDate(data);
    clearErrors('error');
    clearErrors('deadline');
  };
  const handleDeadlineStatus = () => {
    setDeadlineStatus(!deadlineStatus);
  };
  const handleAddMarksStatus = () => {
    setAddMarksStatus(!addMarksStatus);
  };

  const checkValidityString = (value: string, field_name: string) => {
    if (value === '' || value === null || value == undefined) {
      setError(field_name, {
        type: 'required',
        message: 'This field is required',
      });
      return null;
    } else {
      return dateConverterBsToAd(value);
    }
  };

  // Populate initial filled data for input field ( in update case)
  useEffect(() => {
    if (update && updateData) {
      console.log(updateData)
      setValue("title", updateData?.title);
      setSelectedAssignmentCategory(assignmentCategoryOptions.filter((category) => category.key === updateData.assignment_type)[0]);
      setSelectedSubmissionType(submissionTypeOptions.filter((submission) => submission.key === updateData.submission_type)[0]);
      const htmlContent = updateData?.description
      setEditorState(BraftEditor.createEditorState(htmlContent))
      setDeadlineDate(dateConverterAdToBs(updateData?.deadline));
      // setSelectedFile(updateData?.attached_file);
      setDeadlineStatus(updateData?.can_submit_after_deadline)
      updateData?.full_marks ? setAddMarksStatus(true) : setAddMarksStatus(false)
      setValue("full_marks", updateData?.full_marks)
      setValue("pass_marks", updateData?.pass_marks)
      setValue("deadline_time", updateData?.deadline.split("T")[1].slice(0, -3))
      updateData?.assign_now ? setSelectedRadioValue(RADIO_OPTIONS[0].id) : setSelectedRadioValue(RADIO_OPTIONS[1].id)
      !updateData?.assign_now && setAssignDate(dateConverterAdToBs(updateData?.assign_on))
      !updateData?.assign_now && setValue("assign_time", updateData?.assign_on.split("T")[1].slice(0, -3))
    } else {
      setValue("title", null);
      setSelectedAssignmentCategory(null);
      setSelectedSubmissionType(null);
      setEditorState(null)
      setDeadlineDate(CUR_NEPALI_DATE);
      setAssignDate(CUR_NEPALI_DATE);
      setDeadlineStatus(false)
      setAddMarksStatus(false)
      setValue("full_marks", null)
      setValue("pass_marks", null)
      setValue("assign_time", null)
      setValue("deadline_time", null)
    }
  }, [updateData]);

  const onCreate = (data: any) => {
    let assign_on_: string | null, assign: string | undefined;
    const deadline_ = checkValidityString(deadlineDate, "deadline");
    const deadline = deadline_?.concat(" ", data.deadline_time)
    if (selectedRadioValue === '2') {
      assign_on_ = checkValidityString(assignDate, "assign_on");
      assign = assign_on_?.concat(" ", data.assign_time)
    }
    Promise.all(
      assignmentFiles.map(
        (image: any) =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = (file) => {
              resolve({
                file: file?.target?.result
              });
            };
            fileReader.onerror = (error) => reject(error);
            fileReader.readAsDataURL(image);
          })
      )
    ).then((base64Images) => {
      if (selectedRadioValue === '1' || (selectedRadioValue === '2' && assign_on_ && deadline_)) {
        const formData = {
          title: data.title,
          description: editorState?.toHTML(),
          assignment_type: selectedAssignmentCategory?.key,
          submission_type: selectedSubmissionType?.key,
          attached_file: update ? document : base64Images,
          grade: update ? updateData?.grade.id : propsData.classes.grade,
          section: update ? updateData?.section?.id ? updateData?.section?.id : "" : propsData.classes.section?.id ? propsData.classes.section?.id : "",
          subject: update ? updateData?.subject?.id : propsData.subject.id,
          assign_on: selectedRadioValue === '2' ? assign : moment().format('YYYY-MM-DDTHH:mm:ss'),
          deadline: deadline,
          ...addMarksStatus && { full_marks: data.full_marks },
          ...addMarksStatus && { pass_marks: data.pass_marks || null },
          publish: publish,
          can_submit_after_deadline: deadlineStatus,
          assign_now: selectedRadioValue === '1' ? true : false,
        };
        update ? dispatch(UpdateTeacherAssignment(updateData?.id, formData)) : dispatch(AddTeacherAssignment(formData))
        setHasSubmitted(true);
      }
    });
  };

  const inputStyle = { marginBottom: '0px' };

  const handleClose = (value: boolean) => {
    setOpenModal(value)
  }
  const handleSelectValue = (value: any) => {
    setSelectedRadioValue(value);
  };

  return (
    <form onSubmit={handleSubmit(onCreate)} className={classes.form}>
      <Grid container>
        <Grid item xs={8}>
          <Paper className={classes.pageContent} style={filterStyle}>
            {serverErrors &&
              serverErrors?.error &&
              Object.keys(serverErrors.error)
                .filter((elem: any) => errorFields.includes(elem))
                .map((elem: any, index: number) => {
                  return (
                    <div className={classes.serversidemessages} key={index}>
                      <Alert severity="error">
                        {firstWordCapital(serverErrors?.error[elem][0])}
                      </Alert>
                    </div>
                  );
                })}

            <Typography variant="h6" style={{ marginBottom: '12px' }}>
              Assignments
            </Typography>

            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <CustomizedSelect
                  label="Assignment Category"
                  options={assignmentCategoryOptions}
                  value={selectedAssignmentCategory}
                  setValue={setSelectedAssignmentCategory}
                  name="assignment_category"
                  error={
                    errors['assignment_category']
                      ? 'Admission Category must be selected'
                      : ''
                  }
                  required
                />
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <CustomizedSelect
                  label="Submission Type"
                  options={submissionTypeOptions}
                  value={selectedSubmissionType}
                  setValue={setSelectedSubmissionType}
                  name="submission_type"
                  error={
                    errors['submission_type']
                      ? 'Submission Type must be selected'
                      : ''
                  }
                  required
                />
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <CustomizedTextField
                  label="Title"
                  placeholder="Label"
                  name="title"
                  required
                  error={errors['title']?.message}
                  inputRef={register({
                    required: 'Assignment Title is required.',
                  })}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                className={classes.formWrapper}
                style={inputStyle}
              >
                <InputLabel>Assignment Description</InputLabel>
                <div
                  style={{
                    marginTop: '10px',
                    marginBottom: '24px',
                    border: '1px solid #ccc',
                  }}
                >
                  <BraftEditor
                    language="en"
                    value={editorState}
                    onChange={(data) => setEditorState(data)}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {update ? (
                <Grid
                  item
                  xs={12}
                  className={classes.formWrapper}
                  style={inputStyle}
                >
                  <InputLabel>
                    Assignment File (.png, .jpg, .pdf, .docx etc.)
                    <FormEditButton title="Update Files" onClick={() => setOpenModal(true)} style={{ marginLeft: "1em" }} type="button" />
                  </InputLabel>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  className={classes.formWrapper}
                  style={inputStyle}
                >
                  <InputLabel>
                    Assignment File (.png, .jpg, .pdf, .docx etc.)
                  </InputLabel>
                  <div
                    style={{
                      marginBottom: "24px",
                    }}
                  >
                    <Paper className={uploadClasses.upload} elevation={0}>
                      {/* Implement Drag and Drop File upload */}
                      <FileUploadZone
                        files={assignmentFiles}
                        setFiles={setAssignmentFiles}
                      />
                    </Paper>
                    <div>
                      {selectedFile && (
                        <Chip
                          label={selectedFile.name}
                          style={{
                            minWidth: "180px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.pageContent} style={newFilterStyle}>
            <Typography variant="h6" style={{ marginBottom: '12px' }}>
              Publish
            </Typography>

            <Grid container spacing={0}>
              <FormControl component="fieldset">
                <CusRadioGroup
                  items={RADIO_OPTIONS}
                  value={selectedRadioValue}
                  setValue={handleSelectValue}
                />
              </FormControl>
              {selectedRadioValue === '2' && <Grid item xs={12} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Assign on"
                  value={assignDate}
                  setValue={handleAssignDate}
                  name="assign_on"
                  required
                  error={errors['assign_on']?.message}
                  inputRef={register({
                    required: true,
                  })}
                />
                <TextField
                  fullWidth={true}
                  type="time"
                  name="assign_time"
                  variant="outlined"
                  inputRef={register({ required: "This field is required" })}
                />
              </Grid>}

              <Grid item xs={12} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Deadline"
                  value={deadlineDate}
                  setValue={handleDeadline}
                  name="deadline"
                  required
                  error={errors['deadline']?.message}
                  inputRef={register({
                    required: true,
                  })}
                />
                <TextField
                  fullWidth={true}
                  type="time"
                  name="deadline_time"
                  variant="outlined"
                  inputRef={register({ required: "This field is required" })}
                />
              </Grid>

              <Grid item xs={12}>
                <FormCheckBox
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => handleAddMarksStatus()}
                  checked={addMarksStatus}
                  name="add_marks_status"
                  label="Add Marks"
                />
              </Grid>

              {addMarksStatus && (
                <>
                  <Grid item xs={12} className={classes.formWrapper}>
                    <InputLabel>
                      Full Marks<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="full_marks"
                      type="number"
                      placeholder="Full Marks"
                      inputProps={{ min: 0 }}
                      error={errors['total_marks']?.message}
                      inputRef={register({
                        required: true,
                        maxLength: 3,
                        minLength: 1,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.formWrapper}>
                    <InputLabel>Pass Marks</InputLabel>
                    <TextField
                      variant="outlined"
                      name="pass_marks"
                      type="number"
                      placeholder="Pass Marks"
                      inputProps={{ min: 0 }}
                      inputRef={register({
                        maxLength: 3,
                        minLength: 1,
                      })}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <FormCheckBox
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => handleDeadlineStatus()}
                  checked={deadlineStatus}
                  name="deadline_stat"
                  label="Can Submit After Deadline"
                />
              </Grid>

              <Grid item xs={12} className={classes.formWrapper}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <FormDraftButton disabled={loading} onClick={() => setPublish(false)} type="submit" />
                  </Grid>
                  <Grid item>
                    {update ? (
                      <FormEditButton disabled={loading} onClick={() => setPublish(true)} type="submit" />
                    ) : (
                      <FormCreateButton disabled={loading} onClick={() => setPublish(true)} type="submit" />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Popup
        openPopup={openModal}
        setOpenPopup={setOpenModal}
        onClose={handleClose}
        maxWidth="md"
      >
        <AssignmentUpload
          hasUpload={true}
          hasDeleteBtn={true}
          Documents={true}
          id={id}
        />
      </Popup>
    </form>
  );
};
// ---------------- <END> Assignment Form Component ------------------//

export default AssignmentForm;
