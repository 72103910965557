import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetExams } from '../../../../actions/Examination/Exam/ExamAction';
import { GetStudentExamSchedule } from '../../../../actions/StudentDash/StudentExamSchedule/StudentExamScheduleAction';
import {
  SubmitButton,
  ResetButton,
} from '../../../../components/Reusable/Buttons/SearchButton';
import { InputBox } from '../../../../components/Reusable/Inputs/Select';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import { RootStore } from '../../../../store';
import { filterStyle } from '../../StudentDashboardStyles';

interface PropsI {
  onSearch: (data: SearchDataI) => void;
}

interface SearchDataI {
  exam: string;
}

// -----------------<end> Interfaces <end>-----------------------//

const ExamScheduleSearch = (props: PropsI) => {
  const { onSearch } = props;
  const classes = useFormStyles();

  const [examType, setExamType] = useState<any | null>(null);
  const [examChoices, setExamChoices] = useState<any | null>(null);

  const dispatch = useDispatch();
  const examSelector = useSelector((state: RootStore) => state.exam);

  useEffect(() => {
    dispatch(GetExams());
  }, []);

  useEffect(() => {
    examSelector && setExamChoices(examSelector.exams);
  }, [examSelector]);

  const handleSearch = (e: any) => {
    console.log('clicked', examType);
    if (examType != null) {
      dispatch(GetStudentExamSchedule(examType.id));
      onSearch({
        exam: examType.exam_name,
      });
    }
    e.preventDefault();
  };

  const handleFilter = () => {
    setExamType(null);
    onSearch({ exam: '' });
  };

  return (
    <>
      <Paper className={classes.pageAttachContent} style={filterStyle}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6} className={classes.formTitle}>
            <Typography variant="h6">Search Exam Schedule</Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <InputBox>Exam</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                  setExamType(value)
                }
                value={examType}
                options={examChoices}
                getOptionLabel={(option) => option.exam_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="subject_name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <SubmitButton style={{ marginTop: '5px' }} onClick={handleSearch} />
            <ResetButton style={{ marginTop: '5px' }} onClick={handleFilter} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ExamScheduleSearch;
