import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_SYLLABUS_LOADING,
  GET_SYLLABUS_ERROR,
  GET_SYLLABUS_SUCCESS,
  ADD_SYLLABUS_LOADING,
  ADD_SYLLABUS_ERROR,
  ADD_SYLLABUS_SUCCESS,
  DELETE_SYLLABUS_LOADING,
  DELETE_SYLLABUS_ERROR,
  DELETE_SYLLABUS_SUCCESS,
  UPDATE_SYLLABUS_LOADING,
  UPDATE_SYLLABUS_ERROR,
  UPDATE_SYLLABUS_SUCCESS,
  GET_SUB_UNIT_LOADING,
  GET_SUB_UNIT_ERROR,
  GET_SUB_UNIT_SUCCESS,
  ADD_SUB_UNIT_LOADING,
  ADD_SUB_UNIT_ERROR,
  ADD_SUB_UNIT_SUCCESS,
  UPDATE_SUB_UNIT_LOADING,
  UPDATE_SUB_UNIT_ERROR,
  UPDATE_SUB_UNIT_SUCCESS,
  DELETE_SUB_UNIT_LOADING,
  DELETE_SUB_UNIT_ERROR,
  DELETE_SUB_UNIT_SUCCESS,
  SyllabusDispatchTypes,
  SyllabusTypeI,
} from "./SyllabusActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetSyllabus =
  (grade: string, subject: string, page: number, per_page?: number) =>
  async (dispatch: Dispatch<SyllabusDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SYLLABUS_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/unit/?class=${grade}&subject=${subject}&page=${page}&per_page=${
          per_page || 10
        }`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SYLLABUS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SYLLABUS_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Syllabus Loading Failed",
      //   },
      // });
    }
  };

export const AddUnitSyllabus =
  (data: any) =>
  async (dispatch: Dispatch<SyllabusDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_SYLLABUS_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/unit_content/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_SYLLABUS_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Unit Added Successsfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_SYLLABUS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Unit Adding Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_SYLLABUS_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/unit/?class=${data.unit.academic_class}&subject=${data.unit.subject}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_SYLLABUS_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_SYLLABUS_ERROR,
        });
      }
    }
  };

export const UpdateUnitSyllabus =
  (id: string, data: any) =>
  async (dispatch: Dispatch<SyllabusDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_SYLLABUS_LOADING,
      });
      const res = await axios.put(
        `${TEACHER_API_URL}/unit_content/${id}/?unit=${id}`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_SYLLABUS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Unit Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SYLLABUS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Unit Update Failed",
        },
      });
    }
  };

export const DeleteUnitSyllabus =
  (id: string) =>
  async (dispatch: Dispatch<SyllabusDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_SYLLABUS_LOADING,
      });

      const res = await axios.delete(
        `${TEACHER_API_URL}/delete_unit/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_SYLLABUS_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Unit Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_SYLLABUS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Unit Delete Failed",
        },
      });
    }
  };

export const GetSubUnit =
  (id: string) =>
  async (dispatch: Dispatch<SyllabusDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SUB_UNIT_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/unit_content/?unit=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SUB_UNIT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUB_UNIT_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Sub Unit Loading Failed",
      //   },
      // });
    }
  };

export const AddSubUnit =
  (unit: string, data: any) =>
  async (dispatch: Dispatch<SyllabusDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_SUB_UNIT_LOADING,
      });

      const res = await axios.post(
        `${TEACHER_API_URL}/unit_content/?unit=${unit}`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_SUB_UNIT_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Sub Unit Added Successsfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_SUB_UNIT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Sub Unit Adding Failed",
        },
      });
    }
  };

export const UpdateSubUnit =
  (unit: string, id: string, data: any) =>
  async (dispatch: Dispatch<SyllabusDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_SUB_UNIT_LOADING,
      });

      const res = await axios.put(
        `${TEACHER_API_URL}/unit_content/${id}/?unit=${unit}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_SUB_UNIT_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "SubUnit Type Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SUB_UNIT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "SubUnit Type Update Failed",
        },
      });
    }
  };

export const DeleteSubUnit =
  (id: string, unitId: string) =>
  async (dispatch: Dispatch<SyllabusDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_SUB_UNIT_LOADING,
      });

      const res = await axios.delete(
        `${TEACHER_API_URL}/unit_content/${id}/?unit=${unitId}`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_SUB_UNIT_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Sub Unit Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_SUB_UNIT_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Sub Unit Delete Failed",
      //   },
      // });
    }
  };
