import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useSidebar } from '../Reusable';
import { useSidebarStyles } from '../Styles/SidebarStyles';

interface propsI {
  settings?: boolean | undefined;
}

const AccountSettingsSidebar = (props: propsI) => {
  const classes = useSidebarStyles();
  const [settings] = useState(props.settings);

  const dataSet = [
    {
      text: 'Settiings',
      url: '/general-settings',
      activeStatus: settings,
    },
  ];

  const { CustomSidebar } = useSidebar(dataSet, 'FINANCE');

  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <div className={classes.sidebarTitle}>
          <Typography variant="h3">General Settings</Typography>
        </div>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default AccountSettingsSidebar;
