import {
  GET_TEACHER_DASHBOARD_LOADING,
  GET_TEACHER_DASHBOARD_ERROR,
  GET_TEACHER_DASHBOARD_SUCCESS,
  GET_TEACHER_DASHBOARD_ONLINECLASS_LOADING,
  GET_TEACHER_DASHBOARD_ONLINECLASS_ERROR,
  GET_TEACHER_DASHBOARD_ONLINECLASS_SUCCESS,
  GET_TEACHER_DASHBOARD_EVENTS_LOADING,
  GET_TEACHER_DASHBOARD_EVENTS_ERROR,
  GET_TEACHER_DASHBOARD_EVENTS_SUCCESS,
  TeacherDashboardDispatchTypes,
} from "../../actions/TeacherDash/Dasbhoard/TeacherDashboardActionTypes";

interface InitialStateI {
  loading: boolean;
  dashboard: any;
  online_class: any;
  events: any;
}

const initialState: InitialStateI = {
  loading: false,
  dashboard: null,
  online_class: null,
  events: [],
};

const TeacherDashboardReducer = (
  state: InitialStateI = initialState,
  action: TeacherDashboardDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_DASHBOARD_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_DASHBOARD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: action.payload,
      };
    case GET_TEACHER_DASHBOARD_ONLINECLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_DASHBOARD_ONLINECLASS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_DASHBOARD_ONLINECLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        online_class: action.payload,
      };
    case GET_TEACHER_DASHBOARD_EVENTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_DASHBOARD_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_DASHBOARD_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload,
      };

    default:
      return state;
  }
};

export default TeacherDashboardReducer;
