import {
  Grid,
  Paper,
  Typography,
  Button,
  makeStyles,
  createStyles,
  Theme,
  TableContainer,
  Checkbox,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Avatar,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import cx from "clsx";
import { useCheckBoxTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { Fee_TypeI } from "../../../actions/Fees/FeeType/FeeTypeActionTypes";
import {
  GetAllFeeType,
  GetFeeTypeWithFilters,
} from "../../../actions/Fees/FeeType/FeeTypeAction";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import getMonthName from "../../utils/getMonthName";

interface PropsI {
  onFeeChange: (ids: TableDataI[]) => void;
  tempTableData: any;
  setTempTableData: (data: any) => void;
  tableData: any;
  setTableData: (data: any) => void;
  selected: Array<string>;
  setSelected: (data: Array<string>) => void;
}
interface TableDataI {
  id: string;
  title: string;
  isMonthly: boolean;
}

interface HeadCellsI {
  id: string;
  label: string;
}

const useCustomStyles = makeStyles((theme) => ({
  root: {
    "&: hover": {
      backgroundColor: "#118FCF",
    },
  },
  active: {
    backgroundColor: "#118FCF",
    color: "white",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const headCells: HeadCellsI[] = [
  // { id: "index", label: "S.N" },
  { id: "fee", label: "Fee Title" },
];

const FeeCategorySelect = (props: PropsI) => {
  const {
    onFeeChange,
    tempTableData,
    setTempTableData,
    selected,
    setSelected,
    tableData,
    setTableData,
  } = props;
  const classes = useTableStyles();
  const customClasses = useCustomStyles();

  // const [tempTableData, setTempTableData] = useState<any[]>([]);
  const [secondaryTableData, setSecondaryTableData] = useState<any[]>([]);

  //////////////////////////////////////////////////////////////////

  const dispatch = useDispatch();
  const feeTypeSelector = useSelector((state: RootStore) => state.fee_type);

  const applyFeeAction = useSelector(
    (state: RootStore) => state.fee.actionPerformed
  );
  const [allMonths, setAllMonths] = React.useState(false);

  useEffect(() => {
    if (applyFeeAction) {
      setSelected([]);
    }
  }, [applyFeeAction]);

  // React Cycle Hooks
  useEffect(() => {
    dispatch(GetAllFeeType(1));
  }, []);

  useEffect(() => {
    const data = feeTypeSelector.feeType;
    // console.log({data})

    const data__ = data?.results?.map((item: Fee_TypeI) => ({
      id: item.id,
      title: item.name,
      applied_month: item.billing_period,
    }));

    setTableData(data__);

    const temp__ = data?.results?.map((item: Fee_TypeI) => ({
      id: item.id,
      title: item.name,
      checkall: false,
      applied_month:
        item.billing_period?.length > 0
          ? [
              {
                1: false,
              },
              {
                2: false,
              },
              {
                3: false,
              },
              {
                4: false,
              },
              {
                5: false,
              },
              {
                6: false,
              },
              {
                7: false,
              },
              {
                8: false,
              },
              {
                9: false,
              },
              {
                10: false,
              },
              {
                11: false,
              },
              {
                12: false,
              },
            ]
          : [],
    }));
    setTempTableData(temp__);
    setSecondaryTableData(temp__);
  }, [feeTypeSelector]);

  useEffect(() => {
    const selected_fees = tableData.filter((item: TableDataI) =>
      selected.includes(item.id)
    );
    onFeeChange(selected_fees);
  }, [selected]);
  //////////////////////////////////////////////////////////////

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useCheckBoxTable(headCells);

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleMonthClick = (index: number, jindex: number) => {
    // console.log(index, jindex)
    const temp = tempTableData;
    const singleData = temp[index];
    const months__ = singleData.applied_month;
    months__[jindex][jindex + 1] = !months__[jindex][jindex + 1];
    const modified__single = {
      ...singleData,
      applied_month: months__,
    };
    // console.log({ modified__single })
    temp[index] = modified__single;
    // console.log({ temp });
    // console.log(temp[index].applied_month[jindex][jindex + 1]);
    setTempTableData(temp);
    handleCheckClick("44");
  };

  const handleSelectAllMonthClick = (index: number) => {
    const temp = tempTableData;
    const singleData = temp[index];
    if (!singleData["checkall"]) {
      singleData["applied_month"] = [
        {
          1: true,
        },
        {
          2: true,
        },
        {
          3: true,
        },
        {
          4: true,
        },
        {
          5: true,
        },
        {
          6: true,
        },
        {
          7: true,
        },
        {
          8: true,
        },
        {
          9: true,
        },
        {
          10: true,
        },
        {
          11: true,
        },
        {
          12: true,
        },
      ];
    } else {
      singleData["applied_month"] = [
        {
          1: false,
        },
        {
          2: false,
        },
        {
          3: false,
        },
        {
          4: false,
        },
        {
          5: false,
        },
        {
          6: false,
        },
        {
          7: false,
        },
        {
          8: false,
        },
        {
          9: false,
        },
        {
          10: false,
        },
        {
          11: false,
        },
        {
          12: false,
        },
      ];
    }
    singleData["checkall"] = !singleData["checkall"];
    const months__ = singleData.applied_month;
    const modified__single = {
      ...singleData,
    };
    // console.log({ modified__single })
    temp[index] = modified__single;
    // console.log({ temp });
    // console.log(temp[index].applied_month[jindex][jindex + 1]);
    setTempTableData(temp);
    handleCheckClick("44");
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const getCustomTableRow = (row: any, index: number) => {
    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);
    return (
      <StyledTableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={index}
        selected={isItemSelected}
      >
        <StyledTableCell className={cellStyle} align="left">
          <>
            <Grid
              container
              spacing={1}
              alignItems="center"
              style={{ backgroundColor: "#989bad", color: "white" }}
            >
              <Grid item>
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  onClick={() => handleCheckClick(row.id)}
                />
              </Grid>
              <Grid item>{row.title}</Grid>
            </Grid>
            {isItemSelected && row?.applied_month?.length > 0 && (
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "50px",
                  marginTop: "10px",
                }}
              >
                <Chip
                  avatar={
                    <Avatar>
                      <CheckCircleOutlinedIcon
                        className={row.checkall ? customClasses.active : ""}
                      />
                    </Avatar>
                  }
                  label="Check All"
                  onClick={() => handleSelectAllMonthClick(index)}
                  variant="outlined"
                  style={{
                    width: "65%",
                    marginBottom: "10px",
                    marginLeft: "35px",
                  }}
                  className={row.checkall ? customClasses.root : ""}
                />
                {row?.applied_month?.map((data: any, jindex: number) => {
                  const item: Array<string> = [];
                  Object.entries(data).forEach(([k, v]) => {
                    item.push(k);
                  });
                  const value = item && item[0];
                  return (
                    <>
                      <Grid item key={index} xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={
                                tempTableData[index].applied_month[jindex][
                                  value
                                ]
                              }
                              onChange={() => handleMonthClick(index, jindex)}
                              name="gilad"
                              style={{
                                marginTop: "-10px",
                              }}
                            />
                          }
                          label={getMonthName(value?.toString())}
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            )}
          </>
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  const handleAllClick = () => {
    dispatch(GetAllFeeType(1));
  };

  const handleOneTimeClick = () => {
    dispatch(GetFeeTypeWithFilters("O"));
  };

  const handleMonthlyClick = () => {
    dispatch(GetFeeTypeWithFilters("M"));
  };

  const handleChange = (event: any) => {
    // setAllMonths(false)
    // setMonths([])
    // setFeeHeads(event.target.value);
    console.log("aaa");
  };
  return (
    <Paper className={classes.rootTableBox} style={{ marginRight: "5px" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.title}>Fee Categories</Typography>
        </Grid>

        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button onClick={() => handleAllClick()}>All</Button>
          <Button onClick={() => handleOneTimeClick()}>One Time</Button>
          <Button onClick={() => handleMonthlyClick()}>Monthly</Button>
        </ButtonGroup>
        <Grid item xs={12}>
          <div style={{ maxHeight: "calc(100vh - 10vh)", overflowY: "auto" }}>
            <TableContainer
              hasCheckbox
              handleSelectAllClicked={handleSelectAllClick}
              rowCount={tempTableData?.length}
              numSelected={selected?.length}
              loading={false}
              items={tempTableData}
              headCells={headCells}
              getCustomTableRow={getCustomTableRow}
              hiddenCols={[-1]}
              disablePagination
              disableSearch
              disablePrint
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FeeCategorySelect;
