import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { CancelOutlined, CheckRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PostClassAttendance } from '../../../../actions/TeacherDash/ClassAttendance/ClassAttendanceAction';
import { GetClassAttendanceTypeI } from '../../../../actions/TeacherDash/ClassAttendance/ClassAttendanceActionTypes';
import { RootStore } from '../../../../store';
import { ItemViewButton } from '../../../../components/Reusable/Buttons/TableButton';
import useTable from '../../../../components/Reusable/useStudentTable';
import { checkedIcon } from '../../../../components/Styles/ReusableStyles';
import { useTableStyles } from '../../../../components/Styles/TableStyles';
import { dateConverterAdToBs } from '../../../../components/utils/dateConverter';
import { tblStyle } from '../../components/Styles/TeacherDashboardStyles';
// ---------------------------- <START> interface starts ----------------------------------//

interface PropsI {
  searchParam: SearchParamI | null;
}

interface SearchParamI {
  grade: string;
  date: string;
  section: string;
}

interface HeadCellsI {
  id: string;
  label: string;
  filter?: boolean;
  type?: string;
}
const useStyles = makeStyles({
  container: {
    maxHeight: 440,
  },
});
//---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'student_first_name', label: 'Student Name', filter: true },
  { id: 'roll_no', label: 'Roll No.' },
  { id: 'status', label: 'Status' },
  // { id: "action", label: "Action" },
];

export const StudentAttendanceTake = (props: PropsI) => {
  const { searchParam } = props;
  const classes = useTableStyles();

  const localclass = useStyles();
  const [tableData, setTableData] = useState<GetClassAttendanceTypeI[]>([]);
  const [loading, setLoading] = useState(false);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //REACT HOOKS///////////////////////////////////////////////////////
  const dispatch = useDispatch();
  const attendanceSelector = useSelector(
    (state: RootStore) => state.class_attendance
  );

  console.log(searchParam)

  useEffect(() => {
    const data = attendanceSelector.student_attendances;
    searchParam && setTableData(data);
  }, [attendanceSelector]);

  useEffect(() => {
    !searchParam && setTableData([])
  }, [searchParam])
  /////////////////////////////////////////////////////////////////////

  const handleAttendance = (item_id: number) => {
    const current_data: GetClassAttendanceTypeI[] = tableData;
    const index: number = current_data.findIndex(
      (element) => element.student_academic === item_id
    );
    current_data[index].status =
      current_data[index].status === 'P'
        ? 'I'
        : current_data[index].status === 'I'
          ? 'A'
          : 'P';

    setTableData(current_data.concat([]));
  };

  const handleAttendanceSubmit = () => {
    const bulk_data: any[] = [];
    tableData.map((element: GetClassAttendanceTypeI) => {
      bulk_data.push({
        student_academic: element.student_academic,
        status: element.status === null ? 'A' : element.status,
      });
    });
    searchParam && dispatch(PostClassAttendance(bulk_data, searchParam));
  };

  const getCustomTableRow = (item: GetClassAttendanceTypeI, index: number) => {
    return (
      <StyledTableRow key={item.student_academic} hover tabIndex={-1}>
        <StyledTableCell align="center" className={classes.newcell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.student_first_name} {item.student_last_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.roll_number}
        </StyledTableCell>
        <StyledTableCell
          align="center"
          style={{ width: '20%' }}
          className={classes.newcell}
        >
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={() => handleAttendance(item.student_academic)}
            className={classes.btn}
          >
            {item.status === 'P' ? (
              <CheckRounded className={classes.present} />
            ) : item.status === 'I' ? (
              <CancelOutlined className={classes.informed} />
            ) : (
              <CancelOutlined className={classes.absent} />
            )}
          </IconButton>
          {item.status === 'P'
            ? 'Present'
            : item.status === 'I'
              ? 'Informed'
              : 'Absent'}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper style={tblStyle}>
        <TableContainer
          label={`Student Attendance ${searchParam ? `(${dateConverterAdToBs(searchParam.date)})` : ''
            }`}
          loading={false}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="student_first_name"
          search_param="student_first_name"
          hiddenCols={[-1]}
        />
        <Grid style={{ marginTop: 20 }} container justifyContent="flex-end">
          {tableData.length ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleAttendanceSubmit}
            >
              Update
            </Button>
          ) : null}
        </Grid>
      </Paper>
    </>
  );
};
