import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useCardStyles } from "../../../../../components/Styles/CardStyles";
import { dateConverterAdToBs } from "../../../../../components/utils/dateConverter";
import ActionButton from '../../../../../components/Reusable/Buttons/ActionButton';
import { useHistory } from "react-router-dom";

export default function StudentAcademic(props: any) {
  const classes = useCardStyles();
  const history = useHistory();

  const rows = [
    createData(
      "Email Address",
      props.student?.student_user?.email
        ? props.student.student_user.email
        : "Not Provided"
    ),
    createData("Blood Group:", props.student?.blood_group_display),
    createData("Gender:", props.student?.gender),
    createData("Phone Number:", props.student?.phone_number),
    createData("Religion:", props.student?.religion_display),
    createData("Type:", props.student?.category_name),
    createData(
      "Date of Birth:",
      props.student?.date_of_birth
        ? dateConverterAdToBs(props.student?.date_of_birth)
        : ""
    ),
    createData("Medical History:", props.student?.medical_history),
  ];

  function createData(name: string, infoData: string) {
    return { name, infoData };
  }

  const onRedirect = () => {
    history.push(`/${props.student.student_user.id}/update`);
  }

  return (
    <>
      <Paper className={classes.infobar} elevation={0}>
        <TableContainer className={classes.roota}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableBody>
              {rows?.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.table}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.infoData}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <ActionButton onClick={onRedirect} title="Edit Profile" style={{ backgroundColor: "blue", fontSize: "16px", borderRadius: "0px", padding: "10px", color: "white", marginTop: "1em" }}>Edit Profile</ActionButton>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
