import {
  GET_VIEW_CHILD_LOADING,
  GET_VIEW_CHILD_ERROR,
  GET_VIEW_CHILD_SUCCESS,
  GET_VIEW_CHILDREN_LOADING,
  GET_VIEW_CHILDREN_ERROR,
  GET_VIEW_CHILDREN_SUCCESS,
  GET_CHILDREN_ACADEMIC_LOADING,
  GET_CHILDREN_ACADEMIC_ERROR,
  GET_CHILDREN_ACADEMIC_SUCCESS,
  ViewChildDispatchTypes,
  ViewChildDetailProps,
  ChildrenAcademicTypeI,
} from "../../actions/ParentsDash/ViewChild/ViewChildActionTypes";

interface InitialStateI {
  loading: boolean;
  view_child?: any;
  children?: ViewChildDetailProps | null;
  children_academic?: ChildrenAcademicTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  view_child: [],
  children: null,
  children_academic: [],
};

const ViewChildReducer = (
  state: InitialStateI = initialState,
  action: ViewChildDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_VIEW_CHILD_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_VIEW_CHILD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_VIEW_CHILD_SUCCESS:
      return {
        ...state,
        loading: false,
        view_child: action.payload,
      };

    case GET_VIEW_CHILDREN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_VIEW_CHILDREN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_VIEW_CHILDREN_SUCCESS:
      return {
        ...state,
        loading: false,
        children: action.payload,
      };

    case GET_CHILDREN_ACADEMIC_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CHILDREN_ACADEMIC_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CHILDREN_ACADEMIC_SUCCESS:
      return {
        ...state,
        loading: false,
        children_academic: action.payload,
      };

    default:
      return state;
  }
};

export default ViewChildReducer;
