import { Box } from "@material-ui/core";
import React from "react";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import TeacherMeetingList from "./MeetingComponents/TeacheMeetingList";
import TeacherMeetingSearch from "./MeetingComponents/TeacherMeetingSearch";

const MeetingRequestPage = () => {
  return (
    <Box>
      <PageHeader title="Meeting Request" />
      <PageWrapper>
        <TeacherMeetingSearch />
        <TeacherMeetingList />
      </PageWrapper>
    </Box>
  );
};

export default MeetingRequestPage;
