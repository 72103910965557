// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import 'nepali-datepicker-reactjs/dist/index.css';
import EnoteCard from './ParentEnotesCard';
import CustomizedDialogs from '../../../../components/Reusable/Dialogs/DeleteDialog';
import { SmsTemplateTypeI } from '../../../../actions/Communication/SmsTemplate/SmsTemplateActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { DeleteSmsTemplate } from '../../../../actions/Communication/SmsTemplate/SmsTemplateAction';

import { Autocomplete } from '@material-ui/lab';

import { TeacherSubjectsI } from '../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes';
import { GetStudentSubjects } from '../../../../actions/StudentDash/StudentSubjects/StudentSubjectAction';
import { GetStudentEnotes } from '../../../../actions/StudentDash/StudentEnote/StudentEnoteAction';
import { StudentEnotesI } from '../../../../actions/StudentDash/StudentEnote/StudentEnoteActionTypes';
import ParentEnotesModal from './ParentEnotesModal';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import { ChildrenAcademicInfoI } from '../../../../actions/ParentsDash/ChildrenAcademicInfo/ChildrenAcademicInfoActionTypes';
import { GetChildrenAcademicInfo } from '../../../../actions/ParentsDash/ChildrenAssignment/ChildrenAssignmentAction';
import { GetChildrenEnotes } from '../../../../actions/ParentsDash/ChildrenEnote/ChildrenEnoteAction';
import { tblStyle } from '../../ParentDashboardStyles';
import { GetChildren } from "../../../../actions/ParentsDash/ViewChild/ViewChildAction"
import { GetChildrenSubjects } from "../../../../actions/ParentsDash/ChildrenSubjects/ChildrenSubjectAction"
// -----------------<END> imports module ends -----------------------//
interface PropsI {
  enoteCategory: EnoteCategoryI | null;
}

interface EnoteCategoryI {
  id: string;
  name: string;
}

// -----------------<START> Leave Search component starts-----------------------//
const ParentEnoteList = (props: PropsI) => {
  const { enoteCategory } = props;
  const classes = useFormStyles();

  const [enoteModalOpen, setEnoteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [modalItem, setModalItem] = useState<StudentEnotesI | null>(null);
  const [selectedStudent, setSelectedStudent] =
    useState<any>(null);
  const [selectedSubject, setSelectedSubject] =
    useState<any>(null);

  const [subjectDisabler, setSubjectDisabler] = useState<boolean>(true);

  const [templatesData, setTemplatesData] = useState<any[]>([]);

  // ======================== <START> REACT HOOKS <START> ==================================//
  const dispatch = useDispatch();
  const enoteSelector = useSelector((state: RootStore) => state.children_enote);

  useEffect(() => {
    // dispatch(GetStudentSubjects());
    dispatch(GetChildren());
  }, []);

  console.log(selectedStudent)

  useEffect(() => {
    setSubjectDisabler(true);
    setSelectedStudent(null);
    setSelectedSubject(null);
    setTemplatesData([]);
  }, [enoteCategory]);

  useEffect(() => {
    const data: StudentEnotesI[] = enoteSelector.enotes;
    setTemplatesData(data);
  }, [enoteSelector]);

  // ======================== <END> REACT HOOKS <END> ==================================//

  const handleModalClose = (value: boolean) => {
    setItemId(null);
    setEnoteModalOpen(value);
  };

  const handleViewModalOpen = (data: StudentEnotesI) => {
    setModalItem(data);
    setEnoteModalOpen(true);
  };

  const SUBJECT_CHOICES = useSelector(
    (state: RootStore) => state.children_subjects.subjects
  );

  const STUDENT_CHOICES = useSelector(
    (state: RootStore) => state.view_child.view_child
  );
  console.log(SUBJECT_CHOICES)

  const handleStudentChange = (value: any) => {
    setSelectedStudent(value);
    if (value) {
      setSubjectDisabler(false);
      dispatch(GetChildrenEnotes(value.academic_info[0].id));
      dispatch(GetChildrenSubjects(value.academic_info[0].id))
    } else {
      setSubjectDisabler(true);
    }
  };

  const handleSubjectChange = (value: any) => {
    setSelectedSubject(value);
    if (value && enoteCategory) {
      dispatch(GetStudentEnotes(value.id, enoteCategory.id));
    }
    !value && setTemplatesData([]);
  };

  return (
    <Paper
      className={classes.pageAttachContent}
      style={{ ...tblStyle, marginLeft: '24px' }}
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={6} className={classes.formTitle}>
          <Typography variant="h6">
            {enoteCategory ? enoteCategory.name : ''}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={5} className={classes.formWrapper}>
          <Autocomplete
            onChange={(
              event: React.ChangeEvent<{}>,
              value: any
            ) => handleStudentChange(value)}
            options={STUDENT_CHOICES}
            value={selectedStudent}
            getOptionLabel={(option) =>
              `${option.student_user.first_name} ${option.student_user.last_name}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Student"
                name="student"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={5} className={classes.formWrapper}>
          <Autocomplete
            onChange={(
              event: React.ChangeEvent<{}>,
              value: any
            ) => handleSubjectChange(value)}
            options={SUBJECT_CHOICES[0].subject}
            value={selectedSubject}
            disabled={subjectDisabler}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Subject"
                name="class_name"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {templatesData.map((template: StudentEnotesI, index: number) => (
          <Grid item md={6} key={index}>
            <EnoteCard item={template} handleView={handleViewModalOpen} />
          </Grid>
        ))}
      </Grid>

      <Popups
        openPopup={enoteModalOpen}
        setOpenPopup={setEnoteModalOpen}
        onClose={handleModalClose}
        fullWidth={true}
        maxWidth="md"
        title="Enote"
        colored
      >
        <ParentEnotesModal
          item={modalItem}
          handleModalClose={setEnoteModalOpen}
        />
      </Popups>
    </Paper>
  );
};

//-----------------<ENDS> Leave Search component ends-----------------------//
export default ParentEnoteList;
