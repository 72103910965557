import { makeStyles, Theme, Box } from '@material-ui/core';
import { Switch, useLocation } from 'react-router-dom';
import { Header, PageNotFound } from '../../components';
import CheckinCheckoutPage from '../../components/Attendance/CheckinCheckoutPage';
import MyAttendancePage from '../../components/Attendance/MyAttendancePage';
import AddAPEpage from '../../components/TeacherDash/AddAPE/AddAPEpage';
import PrivateRoute from '../../components/utils/PrivateRoute';
import StudentDashboardPage from '../StudentDashboard/pages/Dashboard/StudentDashboardPage';
import TeacherSidebar from './components/Nav/TeacherSidebar';
import AssignmentAddPage from './pages/AssignmentDetails/AssignmentAddPage';
import AssignmentDetailsPage from './pages/AssignmentDetails/AssignmentDetailsPage';
import SingleSubjectPage from "./pages/AssignmentDetails/SingleSubject/SingleSubjectPage"
import AssignmentViewPage from "./pages/AssignmentDetails/AssignmentView/AssignmentViewPage"
import AttendanceReportPage from './pages/AttendanceReport/AttendanceReportPage';
import TeacherDashboardPage from './pages/Dashboard/TeacherDashboardPage';
import TeacherEnoteCreatePage from './pages/Enotes/TeacherEnoteCreatePage';
import TeacherEnotesPage from './pages/Enotes/TeacherEnotesPage';
import ExamMarksPage from './pages/ExamMarks/ExamMarksPage';
import ExamSchedulePage from './pages/ExamSchdulePage/ExamSchedulePage';
import LessonPlanCreatePage from './pages/LessonPlan/LessonPlanCreatePage';
import LessonplanPage from './pages/LessonPlan/LessonplanPage';
import TeacherLiveClassesPage from './pages/LiveClasses/TeacherLiveClassesPage';
import MeetingRequestPage from './pages/MeetingRequest/MeetingRequestPage';
import TeacherSubjectsPage from './pages/MySubjects/TeacherSubjectsPage';
import TeacherTimeTablePage from './pages/MyTimeTable/TeacherTimeTablePage';
import AddAssignmentMarksPage from './pages/SubmittedAssignment/AddAssignmentMarksPage';
import TeacherSyllabusPage from './pages/Syllabus/TeacherSyllabusPage';
import TeacherAnnouncementPage from './pages/TeacherAnnouncement/TeacherAnnouncementPage';
import TeacherAttendancePage from './pages/TeacherAttendance/TeacherAttendancePage';
import TeacherEcaEvents from './pages/TeacherEcaEvents/TeacherEcaEvents';
import TeacherEventsPage from './pages/TeacherEvents/TeacherEventsPage';
import TeacherFeedbackPage from './pages/TeacherFeedback/TeacherFeedbackPage';
import TeacherLeavePage from './pages/TeacherLeave/TeacherLeavePage';
import TeacherProfilePage from './pages/TeacherProfile/TeacherProfilePage';
import TeacherUpdateProfile from './pages/TeacherProfile/TeacherUpdateProfile';
import AddMarksPage from './pages/AddMarks/AddMarks'
import RubricPage from "./pages/Rubric/RubricPage"

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    background: 'white',
    minHeight: '100vh',
  },
  container: {
    display: 'block',
    position: 'relative',
  },
}));

const TeacherDashboardIndex = () => {
  const classes = useStyles();
  const location = useLocation();

  const checkParam = () => {
    if (location.pathname.includes('/online-conference')) {
      console.log('true');
    }
  };

  checkParam();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <Header />
        {location.pathname.includes('/online-conference') ? (
          ''
        ) : (
          <TeacherSidebar />
        )}
        <Switch>
          <PrivateRoute exact path="/" component={TeacherDashboardPage} />
          <PrivateRoute
            exact
            path="/my-subjects"
            component={TeacherSubjectsPage}
          />
          <PrivateRoute exact path="/leave" component={TeacherLeavePage} />
          <PrivateRoute exact path="/meeting" component={MeetingRequestPage} />
          <PrivateRoute exact path="/profile" component={TeacherProfilePage} />

          <PrivateRoute
            exact
            path="/timetable"
            component={TeacherTimeTablePage}
          />
          <PrivateRoute
            exact
            path="/announcement"
            component={TeacherAnnouncementPage}
          />
          <PrivateRoute exact path="/events" component={TeacherEventsPage} />
          <PrivateRoute exact path="/eca-events" component={TeacherEcaEvents} />
          <PrivateRoute
            exact
            path="/:id/update"
            component={TeacherUpdateProfile}
          />
          <PrivateRoute
            exact
            path="/assignment"
            component={AssignmentDetailsPage}
          />

          <PrivateRoute
            exact
            path="/assignment/add/:id"
            component={AssignmentAddPage}
          />
          <PrivateRoute
            exact
            path="/assignment/edit/:id"
            component={AssignmentAddPage}
          />

          <PrivateRoute
            exact
            path="/assignment/detail/:id"
            component={SingleSubjectPage}
          />
          <PrivateRoute
            exact
            path="/assignment/detail/view/:id"
            component={AssignmentViewPage}
          />
          {/* <PrivateRoute
            exact
            path="/assignment-edit/:assignmentID"
            component={StudentDashboardPage}
          /> */}
          <PrivateRoute exact path="/enote" component={TeacherEnotesPage} />

          <PrivateRoute
            exact
            path="/enote-add"
            component={TeacherEnoteCreatePage}
          />
          {/* <PrivateRoute
            exact
            path="/add-marks"
            component={AddAssignmentMarksPage}
          /> */}
          <PrivateRoute
            exact
            path="/student-attendance"
            // path="/student-attendance"
            component={TeacherAttendancePage}
          />
          <PrivateRoute
            exact
            path="/student-attendance/view"
            component={StudentDashboardPage}
          />
          <PrivateRoute exact path="/exam-marks" component={ExamMarksPage} />
          <PrivateRoute
            exact
            path="/exam-schedules"
            component={ExamSchedulePage}
          />
          <PrivateRoute
            exact
            path="/exam-result"
            component={StudentDashboardPage}
          />
          <PrivateRoute
            exact
            path="/syllabus"
            component={TeacherSyllabusPage}
          />
          <PrivateRoute exact path="/lesson-plan" component={LessonplanPage} />
          <PrivateRoute
            exact
            path="/complaint"
            component={TeacherFeedbackPage}
          />
          <PrivateRoute
            exact
            path="/lesson-plan-create"
            component={LessonPlanCreatePage}
          />
          <PrivateRoute
            exact
            path="/attendance-report"
            component={AttendanceReportPage}
          />

          <PrivateRoute
            exact
            path="/online-classes"
            component={TeacherLiveClassesPage}
          />
          <PrivateRoute
            exact
            path="/rubric"
            component={RubricPage}
          />
          <PrivateRoute
            exact
            path="/add-marks"
            component={AddMarksPage}
          />
          <PrivateRoute
            exact
            path="/checkin-checkout"
            component={CheckinCheckoutPage}
          />
          <PrivateRoute
            exact
            path="/my-attendance-report"
            component={MyAttendancePage}
          />
          <PrivateRoute exact path="/exam-ape-factor" component={AddAPEpage} />

          <PrivateRoute component={PageNotFound} />
        </Switch>
      </Box>
    </Box>
  );
};

export default TeacherDashboardIndex;
