import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_EXAM_SUBJECTS_LOADING,
  GET_TEACHER_EXAM_SUBJECTS_ERROR,
  GET_TEACHER_EXAM_SUBJECTS_SUCCESS,
  TeacherExamSubjectsDispatchTypes,
} from "./TeacherExamSubjectsActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

interface GetRequestParamsI {
  exam_info: string;
  grade: string;
  section: string;
}

export const GetTeacherExamSubjects =
  (request_params: GetRequestParamsI) =>
  async (
    dispatch: Dispatch<TeacherExamSubjectsDispatchTypes | SetSnackBarI>
  ) => {
    const { exam_info, grade, section } = request_params;
    try {
      dispatch({
        type: GET_TEACHER_EXAM_SUBJECTS_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/exam_subject/?exam_info=${exam_info}&grade=${grade}&section=${section}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHER_EXAM_SUBJECTS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_EXAM_SUBJECTS_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Teacher Exam Subjects Loading Failed",
      //   },
      // });
    }
  };
