import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { HeadCellsI } from "../../../../actions";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../store";
import { ParentStudentTeachersI } from "../../../../actions/ParentsDash/ParentStudentTeachers/ParentStudentTeachersActionTypes";
import getFullName from "../../../../components/utils/getFullName";
import { GetParentStudentTeachers } from "../../../../actions/ParentsDash/ParentStudentTeachers/ParentStudentTeachersAction";
import useTable from "../../../../components/Reusable/useStudentTable";

const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "name", label: "Teacher Name" },
  { id: "subject", label: "Subject" },
];

const ParentStudentTeachersList = (props: any) => {
  const { academicID } = props;

  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<ParentStudentTeachersI[]>([]);
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  useEffect(() => {
    if (academicID) {
      dispatch(GetParentStudentTeachers(academicID));
    }
  }, [academicID]);

  const ParentStudentTeachersState = useSelector(
    (state: RootStore) => state.parent_student_teachers
  );

  useEffect(() => {
    const items = ParentStudentTeachersState.student_teachers;
    items && setTableData(items);
  }, [ParentStudentTeachersState]);

  const getCustomTableRow = (item: ParentStudentTeachersI, index: number) => {
    return (
      <StyledTableRow key={index} hover tabIndex={-1}>
        <StyledTableCell align="center" className={classes.newcell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {getFullName(item.teacher_first_name, item.teacher_last_name)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.subject_name}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper
      elevation={0}
      className={classes.root}
      style={{ padding: "16px 16px 16px 8px" }}
    >
      <TableContainer
        label="Teachers List"
        loading={ParentStudentTeachersState.loading}
        items={tableData}
        headCells={headCells}
        rowsPerPage={8}
        getCustomTableRow={getCustomTableRow}
        search_name="subject"
        placeholder="Search by subject..."
      />
    </Paper>
  );
};

export default ParentStudentTeachersList;
