import { useState, useEffect } from 'react';
import { RootStore } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  StudentDashboardAssignmentI as AssignmentType,
  StudentDashboardLiveClassI as LiveClassType,
  StudentDashboardSubjectI as SubjectType,
} from '../../../../actions/StudentDash/Dashboard/StudentDashboardActionTypes';
import MySyllabusWidget from '../../components/Widget/MySyllabusWidget';
import DashboardWidgetCard from '../../components/Card/DashboardWidgetCard';
import StudentProfileCard from '../../components/Card/StudentProfileCard';
import MyAssignmentWidget from '../../components/Widget/MyAssignmentWidget';
import EventCalendar from '../../components/Widget/EventCalendar';
import MyLiveClassesWidget from '../../components/Widget/MyLiveClassesWidget';
import {
  GetStudentDashboardData,
  GetStudentDashboardDays,
} from '../../../../actions/StudentDash/Dashboard/StudentDashboardAction';
import generateGreetings from '../../../../components/utils/generateGreetings';
import StarIcon from '@material-ui/icons/Star';
import { FaMedal } from 'react-icons/fa';
import NewLiveClassesTabs from '../../../TeacherDashboard/components/Widget/NewLiveClassesTabs';
import NepaliCalendarPage from '../../../../pages/Dashboard/Calendar/NepaliCalendar';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '240px',
    minHeight: '100vh',
    borderRadius: '30px 0 0 30px',
    background: '#fff',
  },
  main: {
    flex: '1',
    padding: '16px 24px',
    '& b': {
      fontWeight: 600,
    },
  },
  title: { marginTop: '10px', fontWeight: 400 },
  widget: {
    padding: '20px',
    position: 'relative',
    width: '370px',
    minHeight: '100vh',
    background: '#f0f0fc',
  },
  paper: {
    borderRadius: 18,
    boxShadow: 'rgb(0 0 0 / 6%) 1px 1px 18px 1px',
  },
}));

const StudentDashboardPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [subjects, setSubjects] = useState<SubjectType[]>([]);
  const [liveClasses, setLiveClasses] = useState<LiveClassType[]>([]);
  const [assignments, setAssignments] = useState<AssignmentType[]>([]);

  const authState = useSelector((state: RootStore) => state.auth);

  // useEffect(() => {
  //   dispatch(GetStudentDashboardDays("4"));
  // }, []);

  const dashboardState = useSelector(
    (state: RootStore) => state.student_dashboard
  );

  useEffect(() => {
    const studentDashboardData = dashboardState.student_dashboard;

    if (studentDashboardData) {
      const { assignment, live_class, subject } = studentDashboardData;

      if (live_class && live_class.length > 0) {
        setLiveClasses(live_class.slice(0, 8));
      }

      if (assignment && assignment.length > 0) {
        setAssignments(assignment.slice(0, 8));
      }

      if (subject && subject.length > 0) {
        setSubjects(subject.slice(0, 8));
      }
    }
  }, [dashboardState]);

  const greeting = generateGreetings();
  const firstName = authState.user.first_name;

  return (
    <Box className={classes.content}>
      <Box className={classes.main}>
        <Typography variant="h5" color="primary" className={classes.title}>
          <b>{greeting}</b> {firstName}!
        </Typography>

        <Typography variant="subtitle2">
          Welcome back to your Dashboard.
        </Typography>

        <Grid container spacing={3} style={{ marginTop: '16px' }}>
          <Grid item md={12}>
            <Paper className={classes.paper}>
              <NewLiveClassesTabs student onlineClass={liveClasses} />
            </Paper>
          </Grid>

          <Grid item>
            <Paper className={classes.paper} style={{ padding: 16 }}>
              <NepaliCalendarPage showBySize="small" />
            </Paper>
          </Grid>

          {/* <Grid item>
            <Paper className={classes.paper}>
              <EventCalendar />
            </Paper>
          </Grid> */}

          <Grid item style={{ flex: 1 }}>
            <Paper className={classes.paper}>
              <MyAssignmentWidget items={assignments} />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.widget}>
        <StudentProfileCard />

        <Grid container spacing={3} style={{ marginBottom: '16px' }}>
          <Grid item>
            <DashboardWidgetCard
              title="Your Attendance is Good"
              icon={StarIcon}
            >
              You are <b>300 Days</b> present out of <b>365 Days</b>
            </DashboardWidgetCard>
          </Grid>

          <Grid item>
            <DashboardWidgetCard
              title="Hey!!"
              icon={FaMedal}
              variant="secondary"
            >
              You got no complains so far. We are proud of you <b>Raman.</b>
            </DashboardWidgetCard>
          </Grid>
        </Grid>

        <MySyllabusWidget items={subjects} />
      </Box>
    </Box>
  );
};

export default StudentDashboardPage;
