import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../components/utils/PrivateRoute";
import PageNotFound from "./pages/Error/PageNotFound";
import { GetStudentProfile } from "../../actions/StudentDash/StudentProfile/StudentProfileAction";
import StudentDashboardSidebar from "./StudentDashboardSidebar";
import {
  ExamSchedulePage,
  MyAttendancePage,
  MyComplainPage,
  MyLessonPlanPage,
  MyLiveClassesPage,
  MySyllabusPage,
  MyTeacherPage,
  MyTimeTablePage,
  StudentAssignmentPage,
  StudentAssignmentSubmitPage,
  StudentDashboardPage,
  StudentEnotesPage,
  StudentEventPage,
  StudentLeavePage,
  StudentProfilePage,
  StudentSubjectPage,
} from "./pages";
import StudentAnnouncementPage from "./pages/Annoucement/StudentAnnouncementPage";
import StudentProfileUpdate from "./pages/StudentProfile/StudentProfileUpdate";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    background: theme.palette.primary.main,
    minHeight: "100vh",
  },
  container: {
    display: "block",
    position: "relative",
  },
}));

const StudentDashboardApp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      localStorage.getItem("hamro_user") &&
      GetStudentProfile()
    );
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <StudentDashboardSidebar />

        <Switch>
          <PrivateRoute exact path="/" component={StudentDashboardPage} />
          <PrivateRoute exact path="/profile" component={StudentProfilePage} />

          {/* Academics */}
          <PrivateRoute exact path="/my-teachers" component={MyTeacherPage} />
          <PrivateRoute
            exact
            path="/my-attendance"
            component={MyAttendancePage}
          />
          <PrivateRoute exact path="/timetable" component={MyTimeTablePage} />
          <PrivateRoute
            exact
            path="/exam-schedule"
            component={ExamSchedulePage}
          />
          <PrivateRoute exact path="/my-complains" component={MyComplainPage} />
          <PrivateRoute
            exact
            path="/lesson-plan"
            component={MyLessonPlanPage}
          />
          <PrivateRoute exact path="/syllabus" component={MySyllabusPage} />
          <PrivateRoute
            exact
            path="/live-classes"
            component={MyLiveClassesPage}
          />

          <PrivateRoute
            exact
            path="/assignment"
            component={StudentAssignmentPage}
          />

          <PrivateRoute
            exact
            path="/assignment/detail/:id"
            component={StudentSubjectPage}
          />

          <PrivateRoute
            exact
            path="/assignment/submit/:id/"
            component={StudentAssignmentSubmitPage}
          />
          <PrivateRoute exact path="/enote" component={StudentEnotesPage} />
          <PrivateRoute exact path="/leave" component={StudentLeavePage} />
          <PrivateRoute
            exact
            path="/announcement"
            component={StudentAnnouncementPage}
          />
          <PrivateRoute exact path="/events" component={StudentEventPage} />
          <PrivateRoute exact path="/:studentID/update" component={StudentProfileUpdate} />
          <PrivateRoute component={PageNotFound} />
        </Switch>
      </Box>
    </Box>
  );
};

export default StudentDashboardApp;
