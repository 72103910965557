import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_ANNOUNCEMENTS_LOADING,
  GET_TEACHER_ANNOUNCEMENTS_ERROR,
  GET_TEACHER_ANNOUNCEMENTS_SUCCESS,
  TeacherAnnouncementsDispatchTypes,
} from "./TeacherAnnouncementActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetTeacherAnnouncements = () => async (
  dispatch: Dispatch<TeacherAnnouncementsDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_TEACHER_ANNOUNCEMENTS_LOADING,
    });

    const res = await axios.get(
      `${TEACHER_API_URL}/announcement/`,
      HeaderConfig()
    );

    dispatch({
      type: GET_TEACHER_ANNOUNCEMENTS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEACHER_ANNOUNCEMENTS_ERROR,
    });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     snackbarMessage: "Announcements Loading Failed",
    //   },
    // });
  }
};
