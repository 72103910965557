import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import getFullName from "../../../../components/utils/getFullName";
import { useState } from "react";
import { DashboardLeaveRequestI } from "../../../../actions/TeacherDash/Dasbhoard/TeacherDashboardActionTypes";
import formatDate from "../../../../components/utils/formatDate";
import { dateConverterAdToBs } from "../../../../components/utils/dateConverter";
import { exludeYear } from "../../../../components/utils/excludeYear";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",

    "& th, & td": {
      padding: "6px",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    "& tbody tr:last-child td ": {
      borderBottom: "none",
    },
  },

  approveBtn: {
    "& span": {
      fontSize: "13px",
    },
  },
});

export default function LeaveRequestData(props: any) {
  const classes = useStyles();
  const { leave } = props;

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="Leave Requests List">
        <TableBody>
          {leave &&
            leave.length > 0 &&
            leave.map((row: DashboardLeaveRequestI, index: number) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                    {row.reason_for_leave}
                  </Typography>
                  {/* <Typography variant="body1" style={{ color: "#555" }}>
                    {row.leave_name}
                  </Typography> */}
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" style={{ color: "#555" }}>
                    Leave From
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: 500 }}>
                    {exludeYear(row.leave_from)} / {exludeYear(row.leave_to)} (
                    {moment(row.leave_to).diff(row.leave_from, "days")} days)
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Button
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      fontSize: "20px!important",
                    }}
                    className={classes.approveBtn}
                    // onClick={() => {
                    //   dispatch(
                    //     LeaveApproveOrDecline({ id: row.id, status: "A" })
                    //   );
                    //   dispatch(GetDashboardData());
                    // }}
                  >
                    {row.status === "P" ? "Pending" : "Approved"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const Data = [
  {
    applied_date: "2021-06-30",
    first_name: "Amit",
    general_info: 3,
    id: "488d7fc0-aa9e-4f51-8af4-7e4568a7a5c6",
    last_name: "Khatri",
    leave_from: "2021-07-01",
    leave_name: "Sick",
    leave_to: "2021-07-03",
    leave_type: "f05c0a2b-ac76-4422-ba3e-6d61650a68d5",
    reason_for_leave: "sick",
    status: "P",
    status_name: "Pending",
  },
  {
    applied_date: "2021-06-30",
    first_name: "Rabindra",
    general_info: 3,
    id: "02f3d8bc-bf04-4184-b7fa-ee198759902e",
    last_name: "Maharjan",
    leave_from: "2021-06-30",
    leave_name: "Sick",
    leave_to: "2021-06-30",
    leave_type: "f05c0a2b-ac76-4422-ba3e-6d61650a68d5",
    reason_for_leave: "test",
    status: "P",
    status_name: "Pending",
  },
];
