import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";

export interface DaysI {
  id: string;
  day: string;
  checked: boolean;
}
interface PropsI {
  modalState: boolean;
  handleClose: (val: false) => void;
  handleApply: (val: DaysI[]) => void;
  editDays: any;
}

const days: DaysI[] = [
  { id: "7", day: "Sunday", checked: false },
  { id: "1", day: "Monday", checked: false },
  { id: "2", day: "Tuesday", checked: false },
  { id: "3", day: "Wednesday", checked: false },
  { id: "4", day: "Thursday", checked: false },
  { id: "5", day: "Friday", checked: false },
];

const SelectDaysDialog = (props: PropsI) => {
  const { modalState, handleClose, handleApply, editDays } = props;

  const closeDialog = () => {
    handleClose(false);
  };

  const [applyDays, setApplyDays] = React.useState<DaysI[]>(days);

  const checkDays = (id: string) => {
    setApplyDays(
      applyDays.map((element) =>
        element.id === id ? { ...element, checked: !element.checked } : element
      )
    );
  };

  const onApplyClick = () => {
    const apppliedDays: DaysI[] = applyDays.filter(
      (element) => element.checked
    );

    handleApply(apppliedDays);
  };

  function comparer(otherArray: any) {
    return function (current: any) {
      return (
        otherArray.filter(function (other: any) {
          return other.id === current.id;
        }).length === 0
      );
    };
  }

  useEffect(() => {
    if (editDays.length) {
      const onlyDays = days.filter(comparer(editDays));
      const final = [...editDays, ...onlyDays];
      Array.prototype.push.apply(editDays, onlyDays);
      setApplyDays(final);
    }
  }, [editDays]);

  return (
    <>
      <Dialog
        open={modalState}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Select Days to Apply to"}
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              {applyDays.map((element: DaysI, index: number) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={element.checked}
                      onChange={() => checkDays(element.id)}
                      name={element.day}
                      color="primary"
                    />
                  }
                  label={element.day}
                />
              ))}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onApplyClick} color="primary" autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectDaysDialog;
