import {
    ChildrenTimeTableDispatchTypes,
    ChildrenTimeTableI,
    GET_CHILDREN_TIMETABLE_ERROR,
    GET_CHILDREN_TIMETABLE_LOADING,
    GET_CHILDREN_TIMETABLE_SUCCESS,
    GET_CHILDREN_PERIOD_ERROR,
    GET_CHILDREN_PERIOD_LOADING,
    GET_CHILDREN_PERIOD_SUCCESS,
} from '../../actions/ParentsDash/ChildrenTimeTable/ChildrenTimeTableActionTypes'

import { StudentPeriods } from "../../actions/StudentDash/StudentTimeTable/StudentTimeTableActionTypes"

interface InitialStateI {
    loading: boolean;
    timeTable: ChildrenTimeTableI;
    periods: StudentPeriods;
}

const initialState: InitialStateI = {
    loading: false,
    timeTable: {} as ChildrenTimeTableI,
    periods: {} as StudentPeriods,
};

const ChildrenTimeTableReducer = (
    state: InitialStateI = initialState,
    action: ChildrenTimeTableDispatchTypes
): InitialStateI => {
    switch (action.type) {
        case GET_CHILDREN_TIMETABLE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_CHILDREN_TIMETABLE_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_CHILDREN_TIMETABLE_SUCCESS:
            return {
                ...state,
                loading: false,
                timeTable: action.payload,
            };

        case GET_CHILDREN_PERIOD_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_CHILDREN_PERIOD_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_CHILDREN_PERIOD_SUCCESS:
            return {
                ...state,
                loading: false,
                periods: action.payload,
            };

        default:
            return state;
    }
}

export default ChildrenTimeTableReducer;