import { Box } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import AssignmentCardPage from './AssignmentCardPage'

const AssignmentDetailsPage = () => {
  return (
    <>
      <Box>
        <PageHeader
          title="Assignment Details"
        />
        <PageWrapper>
          <AssignmentCardPage />
        </PageWrapper>
      </Box>
    </>
  );
};

export default AssignmentDetailsPage;
