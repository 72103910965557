import React from 'react';
import { Switch } from 'react-router-dom';
import { StudentListTable, StudentProfile } from '../../components';
import {
  StudentListPage,
  StudentHousePage,
  DisabledStudentListPage,
  ErrorPage,
  StudentCategoryPage,
  BusUserPage,
  RoutesPage,
  VehiclePage,
  GuardianDetailPage,
  StudentLeavePage,
  MonthlyFeeReportPage,
  EventsPage,
  AnnoucementPage,
  IncomePage,
  ExpensePage,
  AddIncomePage,
  ApplyFeePage,
  FeesPage,
  PaymentMethodPage,
  StudentAttendanceReportPage,
} from '../../pages';
import PrivateRoute from '../../components/utils/PrivateRoute';
import StudentComplaintPage from '../../pages/Student/StudentComplaintPage';
import ScholarshipTypePage from '../../pages/Scholarship/ScholarshipTypePage';
import AccountantNavbar from '../../components/Navbar/AccountantNavbar';
import ScholarshipApplyPage from '../../pages/Scholarship/ScholarshipApplyPage';
import ScholarshipReportPage from '../../pages/Scholarship/ScholarshipReportPage';
import ScholarshipReportDetail from '../../components/Scholarship/ScholarshipReport/ScholarshipReportDetial';
import AccountDashboardPage from './AccountDashboardPage';
import AccountantGeneralSettingsPage from './pages/GeneralSettingsPage';
import StudentRollNumPage from '../../pages/Student/StudentRollNumberPage';
import AccountantEventsPage from './pages/AccountantEventsPage';
import AccountantAnnoucementPage from './pages/AccountantAnnoucementPage';
import CollectFeePage from '../../components/Finance/CollectFees/CollectFeePage';
import FeeTypePage from '../../pages/Finance/FeeTypePage';
import OpeningBalancePage from '../../components/Finance/OpeningBalance/OpeningBalancePage';
import MiscFeePage from '../../components/Finance/Misc/MiscFeePage';
import BillAdjustmentPage from '../../components/Finance/BillAdjustment/BillAdjustmentPage';
import AccountFeeTypePage from './pages/AccountFeeTypePage';
import AccountFeesPage from './pages/AccountFeesPage';
import AccountApplyFeePage from './pages/AccountApplyFeePage';
import AccountOpeningBalancePage from './pages/AccountOpeningBalancePage';
import AccountCollectFeePage from './pages/AccountCollectFeePage ';
import AccountMiscFeePage from './pages/AccountMiscFeePage';
import AccountBillAdjustmentPage from './pages/AccountBillAdjustmentPage';
import AccountPaymentMethodPage from './pages/AccountPaymentMethodPage';
import AccountDayBookPage from './pages/AccountDaybookPage';
import AccountFeeStatementPage from './pages/AccountFeeStatementPage';
import AccountVoidPage from './pages/AccountVoidPage';
import AccountFeeIncomePage from './pages/AccountFeeIncomePage';
import AccountFeeTypeReport from './pages/AccountFeeTypeReport';
import AccountDiscountSummaryPage from './pages/AccountDiscountSummaryPage';
import AccountDueReportPage from './pages/AccountDueReportPage';
import GuardianProfile from '../../components/Student/GuardianDetail/GuardianProfile/GuardianProfile';

/* AccountantDashboardApp contains navbar,
 * and overall accoutant dashboard routes
 * Dashboard, Student, Finance, Finance Report, Transport
 * Scholarship, General Settings etc.
 */

/*
  Error on Miscellaneous Fee 
*/

const AccountantDashboardApp = () => {
  return (
    <React.Fragment>
      <AccountantNavbar />

      <Switch>
        {/* Dashboard Route */}
        <PrivateRoute exact path="/" component={AccountDashboardPage} />

        {/* Student Route */}
        <PrivateRoute exact path="/student" component={StudentListPage} />
        <PrivateRoute
          exact
          path="/student/profile/:studentID"
          component={StudentProfile}
        />
        <PrivateRoute
          exact
          path="/guardian-details"
          component={GuardianDetailPage}
        />
        <PrivateRoute
          exact
          path="/guardian-details/profile/:guardianID"
          component={GuardianProfile}
        />
        <PrivateRoute
          exact
          path="/disabled-student"
          component={DisabledStudentListPage}
        />
        <PrivateRoute
          exact
          path="/student-roll-num"
          component={StudentRollNumPage}
        />
        <PrivateRoute
          exact
          path="/student-categories"
          component={StudentCategoryPage}
        />
        <PrivateRoute
          exact
          path="/student-house"
          component={StudentHousePage}
        />
        <PrivateRoute
          exact
          path="/student-list-table"
          component={StudentListTable}
        />
        <PrivateRoute
          exact
          path="/student-complaint"
          component={StudentComplaintPage}
        />
        <PrivateRoute
          exact
          path="/student-leave"
          component={StudentLeavePage}
        />
        <PrivateRoute
          exact
          path="/student-attendance-report"
          component={StudentAttendanceReportPage}
        />

        {/* Finance Route  */}
        <PrivateRoute exact path="/fee-type" component={AccountFeeTypePage} />
        <PrivateRoute exact path="/fees" component={AccountFeesPage} />
        <PrivateRoute exact path="/apply-fee" component={AccountApplyFeePage} />
        <PrivateRoute
          exact
          path="/opening-balance"
          component={AccountOpeningBalancePage}
        />
        <PrivateRoute
          exact
          path="/miscellaneous-fee"
          component={AccountMiscFeePage}
        />
        <PrivateRoute
          exact
          path="/collect-fees"
          component={AccountCollectFeePage}
        />
        <PrivateRoute
          exact
          path="/bill-adjustment"
          component={AccountBillAdjustmentPage}
        />
        <PrivateRoute
          exact
          path="/payment-method"
          component={AccountPaymentMethodPage}
        />

        {/* <PrivateRoute exact path="/add-expenses" component={AddExpensePage} />
          <PrivateRoute exact path="/apply-fee" component={ApplyFeePage} />
          <PrivateRoute
            exact
            path="/opening-balance"
            component={OpeningBalancePage}
          />
          <PrivateRoute exact path="/miscellaneous-fee" component={MiscFeePage} />
          <PrivateRoute
            exact
            path="/bill-adjustment"
            component={BillAdjustmentPage}
          /> */}
        {/* <PrivateRoute exact path="/income" component={CollectFeePageTest} />
        <PrivateRoute exact path="/expenses" component={CollectFeePageTest} />
        <PrivateRoute exact path="/add-income" component={CollectFeePageTest} />
        <PrivateRoute
          exact
          path="/add-expenses"
          component={CollectFeePageTest}
        />
        <PrivateRoute exact path="/apply-fee" component={CollectFeePageTest} />
        <PrivateRoute
          exact
          path="/opening-balance"
          component={CollectFeePageTest}
        />
        <PrivateRoute
          exact
          path="/miscellaneous-fee"
          component={CollectFeePageTest}
        />
        <PrivateRoute
          exact
          path="/bill-adjustment"
          component={CollectFeePageTest}
        /> */}

        {/* Fees Route */}

        <PrivateRoute exact path="/day-book" component={AccountDayBookPage} />
        <PrivateRoute exact path="/void-report" component={AccountVoidPage} />
        <PrivateRoute
          exact
          path="/due-report"
          component={AccountDueReportPage}
        />
        {/* <PrivateRoute
          exact
          path="/fee-statement"
          component={AccountFeeStatementPage}
        />
        <PrivateRoute
          exact
          path="/fee-statement/:invoiceID/:studentID"
          component={ApplyFeePage}
        /> */}
        {/* <PrivateRoute
          exact
          path="/void-detail/:invoiceID"
          component={ApplyFeePage}
        /> */}
        <PrivateRoute
          exact
          path="/fee-income"
          component={AccountFeeIncomePage}
        />
        <PrivateRoute
          exact
          path="/fee-type-report"
          component={AccountFeeTypeReport}
        />
        <PrivateRoute exact path="/void-report" component={ApplyFeePage} />
        <PrivateRoute
          exact
          path="/fee-statement"
          component={AccountFeeStatementPage}
        />
        <PrivateRoute
          exact
          path="/discount-summary"
          component={AccountDiscountSummaryPage}
        />
        {/* <PrivateRoute exact path="/fees" component={CollectFeePageTest} />
        <PrivateRoute exact path="/fee-type" component={CollectFeePageTest} />
        <PrivateRoute exact path="/fine-type" component={CollectFeePageTest} />

        <PrivateRoute
          exactApplyFeePage
          path="/discount-type"
          component={CollectFeePageTest}
        />
        <PrivateRoute
          exact
          path="/payment-method"
          component={CollectFeePageTest}
        />
        <PrivateRoute exact path="/fee-ledger" component={CollectFeePageTest} />
        <PrivateRoute exact path="/collect-fees" component={CollectFeePage} />
        <PrivateRoute
          exact
          path="/day-book"
          component={FinanceReportPageTest}
        />
        <PrivateRoute
          exact
          path="/void-report"
          component={FinanceReportPageTest}
        />
        <PrivateRoute
          exact
          path="/fee-statement"
          component={FinanceReportPageTest}
        />
        <PrivateRoute
          exact
          path="/fee-statement/:invoiceID/:studentID"
          component={FinanceReportPageTest}
        />
        <PrivateRoute
          exact
          path="/void-detail/:invoiceID"
          component={CollectFeePageTest}
        />
        <PrivateRoute
          exact
          path="/fee-income"
          component={FinanceReportPageTest}
        />
        <PrivateRoute
          exact
          path="/fee-type-report"
          component={CollectFeePageTest}
        />
        <PrivateRoute
          exact
          path="/void-report"
          component={CollectFeePageTest}
        />
        <PrivateRoute
          exact
          path="/discount-summStudentRollNumberPage
          StudentRollNumberPageary"
          component={FinanceReportPageTest}
        />
        <PrivateRoute
          exact
          path="/due-report"
          component={FinanceReportPageTest}
        />
        <PrivateRoute
          exact
          path="/monthly-report"
          component={MonthlyFeeReportPage}
        /> */}

        {/* Transport Route */}
        <PrivateRoute exact path="/vehicles" component={VehiclePage} />
        <PrivateRoute exact path="/routes" component={RoutesPage} />
        <PrivateRoute exact path="/bus-user" component={BusUserPage} />

        {/* Scholarship Route */}
        <PrivateRoute
          exact
          path="/scholarship-type"
          component={ScholarshipTypePage}
        />
        <PrivateRoute
          exact
          path="/apply-scholarship"
          component={ScholarshipApplyPage}
        />
        <PrivateRoute
          exact
          path="/scholarship-report"
          component={ScholarshipReportPage}
        />
        <PrivateRoute
          exact
          path="/scholarship-report/:scholarshipID"
          component={ScholarshipReportDetail}
        />

        {/* Events Route */}
        <PrivateRoute exact path="/events" component={AccountantEventsPage} />

        {/* Announcement Route */}
        <PrivateRoute
          exact
          path="/announcement"
          component={AccountantAnnoucementPage}
        />

        {/* General Settings Route */}
        <PrivateRoute
          exact
          path="/general-settings"
          component={AccountantGeneralSettingsPage}
        />

        {/* Error Route */}
        <PrivateRoute component={ErrorPage} />
      </Switch>
    </React.Fragment>
  );
};

export default AccountantDashboardApp;
