import React from "react";
import styled from "styled-components";
import moment from "moment";

import { MarksLedgerI } from "../../../rtk/features/exam/marksLedger/marksLedger";
import { dateConverterAdToBs } from "../../utils/dateConverter";

const Sheet = styled.div`
  padding: 10px 5px;

  @media print {
    width: 720px;
    page-break-after: always;

    margin: 0 auto;
    text-align: center;
    clear: both;
    display: block;
    text-align: center;
  }
`;

const SheetWrapper = styled.div`
  position: relative;
  padding: 15px 15px 0;
  min-width: 720px;
`;

const Header = styled.div`
  text-align: center;

  padding: 10px;

  border-top: 2px solid #000;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
`;

const Logo = styled.div`
  width: 200px;
  height: 200px;

  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  opacity: 0.3;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;

  margin-top: 2px;
  margin-bottom: 5px;
  line-height: 1.1;

  text-transform: uppercase;
  color: #403c80;
`;

const Subtitle = styled.h2`
  font-size: 12px;
  font-weight: 300;
  color: #403c80;
`;

const ReportTitle = styled.h1`
  font-size: 22px;
  font-weight: 500;

  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 1.1;
  text-transform: uppercase;
  color: #403c80;
`;

const ExamTitle = styled.h2`
  font-size: 12px;
  font-weight: 500;

  margin-top: 2px;
  margin-bottom: 10px;
  line-height: 1.1;
  text-transform: uppercase;
  text-decoration: underline;
`;

const StudentText = styled.p`
  font-size: 11px;
  font-weight: 500;
  line-height: 1.2;

  display: flex;

  span {
    &:nth-child(1) {
      width: 70px;
    }
  }
`;

const Subheader = styled.div`
  padding: 5px 10px;
  font-weight: 400;

  border-left: 2px solid #000;
  border-right: 2px solid #000;
  display: "flex";
`;

const SubheaderRow = styled.div`
  display: flex;
  justify-content: "space-between";

  div {
    flex: 1;
  }
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  text-transform: uppercase;

  border-left: 2px solid #000;
  border-right: 2px solid #000;
`;

const TableHeadData = styled.th`
  border: 1px solid #000;
  padding: 2px 4px;

  font-size: 11px;
  font-weight: 500;
`;

const FakeRow = styled.tr`
  border-left: 2px solid #000;
  border-right: 2px solid #000;

  td {
    padding-top: 40px;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
  }
`;

const TableBottom = styled.tr`
  border: 1px solid #000;

  border-left: 2px solid #000;
  border-right: 2px solid #000;
`;

const Rotated = styled.span`
  font-size: 12px;
  font-weight: 400;

  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
`;

const TableRow = styled.tr``;

const TableBody = styled.tbody`
  border-top: 1px solid #000;

  border-left: 2px solid #000;
  border-right: 2px solid #000;
`;

const TableData = styled.td<{
  textAlign: "left" | "center" | "right";
  main?: boolean;
}>`
  border-right: 1px solid #000;
  border-left: 1px solid #000;

  text-align: ${(props) => props.textAlign};
  padding: 0 2px;

  font-size: 11px;
  font-weight: 500;

  line-height: 2;

  padding-right: ${(props) => props.main && "20px"};
`;

const Row = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  border-bottom: 1px solid #000;

  border-left: 2px solid #000;
  border-right: 2px solid #000;
`;

const TableFooter = styled.div`
  display: flex;
  align-items: center;

  padding: 5px;

  border-left: 2px solid #000;
  border-right: 2px solid #000;
  border-bottom: 1px solid #000;

  div {
    flex: 1;
  }
`;

const TFText = styled.p`
  font-size: 12px;
  font-weight: 500;

  line-height: 1.2;

  span {
    font-weight: 400;
  }
`;

const Footer = styled.div`
  padding: 10px 10px 5px 10px;

  border-left: 2px solid #000;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoHeader = styled.h2`
  font-size: 11px;
  font-weight: 500;
`;

const Overall = styled.div`
  display: flex;
  gap: 60px;
  padding: 5px 0;

  font-size: 11px;
  font-weight: 500;
`;

const Factor = styled.span`
  display: flex;
  gap: 15px;
`;

const GradeInfo = styled.div`
  margin-bottom: 10px;
  thead,
  tbody {
    border: 1px solid #000;
  }

  th {
    border: 1px solid #000;

    line-height: 1;
    padding: 10px 18px;
  }

  td {
    font-size: 11px;
    font-weight: 500;
    border: 1px solid #000;

    line-height: 1;
    padding: 10px;
  }
`;

const SignSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 50px;
`;

const IssuedDate = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
`;

const DateValue = styled.span`
  text-decoration: underline;
  font-size: 12px;
  font-weight: 400;
`;

const DateLabel = styled.span``;

const SignMarker = styled.span`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;

  text-decoration: overline;
`;

const Note = styled.p`
  font-size: 11px;
  font-weight: 500;

  margin-left: 25px;
`;

interface GeneralInfoI {
  id: number;
  logo: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
}

interface PropsI {
  schoolInfo: GeneralInfoI | null;
  marksheet: MarksLedgerI;
  gradeInfo: {
    grade: string;
    totalStudents: number;
  } | null;
}

const MarkSheetTemp = (props: PropsI) => {
  const { schoolInfo, marksheet, gradeInfo } = props;

  const today = moment(new Date()).format("YYYY-MM-DD");

  const fullMarks = marksheet.obtain_subject_marks.reduce(
    (a, b) => a + (b.full_marks_theory + b.full_marks_practical),
    0
  );

  const passMarks = marksheet.obtain_subject_marks.reduce(
    (a, b) => a + (b.pass_marks_theory + b.pass_marks_practical),
    0
  );

  const obtainedMarksTh = marksheet.obtain_subject_marks.reduce(
    (a, b) => a + b.marks_obtained_theory,
    0
  );

  const obtainedMarksPr = marksheet.obtain_subject_marks.reduce(
    (a, b) => a + b.marks_obtained_practical,
    0
  );

  return (
    <Sheet>
      <SheetWrapper>
        <Header>
          <Title>{schoolInfo?.school_name || ""}</Title>
          <Subtitle>{schoolInfo?.address || ""}</Subtitle>
          <ReportTitle>Progress Report</ReportTitle>
          <ExamTitle>{marksheet.exam_info__exam_name} - 2079</ExamTitle>
          <Logo>
            {schoolInfo?.logo ? (
              <img src={schoolInfo.logo} width={200} height={200} />
            ) : null}
          </Logo>
        </Header>

        <Subheader>
          <SubheaderRow>
            <div>
              <StudentText>
                <span>Name</span>
                <span>
                  : {marksheet.student_first_name} {marksheet.student_last_name}
                </span>
              </StudentText>
              <StudentText>
                <span>Class</span>
                <span>: {gradeInfo?.grade}</span>
              </StudentText>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <StudentText>
                <span>Roll No.:</span>
                <span>{marksheet.student_roll_number}</span>
              </StudentText>
            </div>
          </SubheaderRow>
        </Subheader>

        <Table>
          <TableHead>
            <TableRow>
              <TableHeadData rowSpan={2}>Subjects</TableHeadData>
              <TableHeadData rowSpan={2}>F.M.</TableHeadData>
              <TableHeadData rowSpan={2}>P.M.</TableHeadData>
              <TableHeadData colSpan={2}>
                <span>Obtained Marks</span>
              </TableHeadData>
              <TableHeadData rowSpan={2}>Total</TableHeadData>
              <TableHeadData rowSpan={2}>Grade</TableHeadData>
              <TableHeadData rowSpan={2}>GPA</TableHeadData>
            </TableRow>
            <TableRow>
              <TableHeadData>TH</TableHeadData>
              <TableHeadData>PR</TableHeadData>
            </TableRow>
          </TableHead>

          <TableBody>
            {marksheet.obtain_subject_marks.map((el, index) => {
              return (
                <TableRow key={index + 1}>
                  <TableData main textAlign="left">
                    {el.exam_subject__subject__name}
                  </TableData>

                  <TableData textAlign="center">
                    {Number(el.full_marks_theory ? el.full_marks_theory : 0) +
                      Number(
                        el.full_marks_practical ? el.full_marks_practical : 0
                      ) || "-"}
                  </TableData>

                  <TableData textAlign="center">
                    {Number(el.pass_marks_theory ? el.pass_marks_theory : 0) +
                      Number(
                        el.pass_marks_practical ? el.pass_marks_practical : 0
                      ) || "-"}
                  </TableData>

                  <TableData textAlign="center">
                    {el.marks_obtained_theory || "-"}
                  </TableData>
                  <TableData textAlign="center">
                    {el.marks_obtained_practical || "-"}
                  </TableData>
                  <TableData textAlign="center">
                    {el.total_marks_obtained}
                  </TableData>

                  <TableData textAlign="center">{el.average_grade}</TableData>

                  <TableData textAlign="center">
                    {el.grade_point_total}
                  </TableData>
                </TableRow>
              );
            })}
          </TableBody>
          <FakeRow>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </FakeRow>
          <TableBottom>
            <TableData textAlign="left">Total</TableData>
            <TableData textAlign="center">{fullMarks}</TableData>
            <TableData textAlign="center">{passMarks}</TableData>
            <TableData textAlign="center">{obtainedMarksTh}</TableData>
            <TableData textAlign="center">{obtainedMarksPr || "-"}</TableData>
            <TableData textAlign="center">
              {marksheet.total_marks_obtained}
            </TableData>
            <TableData textAlign="center">-</TableData>
            <TableData textAlign="center">-</TableData>
          </TableBottom>
        </Table>

        <Footer>
          <Info>
            <GradeInfo>
              <InfoHeader>Conduct:</InfoHeader>
              <table>
                <tbody>
                  <tr>
                    <td>Handwriting</td>
                    <th></th>
                    <td>Punctuality</td>
                    <th></th>
                  </tr>
                  <tr>
                    <td>Drawing</td>
                    <th></th>
                    <td>Discipline</td>
                    <th></th>
                  </tr>
                  <tr>
                    <td>Conversation</td>
                    <th></th>
                    <td>Cleanliness</td>
                    <th></th>
                  </tr>
                </tbody>
              </table>
            </GradeInfo>
          </Info>

          <Overall>
            <Factor>
              <span>Percentage:</span>
              <span>{marksheet.obtain_percentage}</span>
            </Factor>
            <Factor>
              <span>GPA:</span>
              <span>{marksheet.obtain_gpa}</span>
            </Factor>
            <Factor>
              <span>Attendance:</span>
              <span>{marksheet.obtain_subject_marks[0].attendance || 0}</span>
            </Factor>
          </Overall>

          <Overall>
            <Factor>
              <span>Remarks:</span>
              <span>{marksheet.remarks}</span>
            </Factor>
          </Overall>

          <SignSection>
            <IssuedDate>
              <DateValue>{dateConverterAdToBs(today)}</DateValue>
              <DateLabel>Date</DateLabel>
            </IssuedDate>
            <SignMarker>Class Teacher</SignMarker>
            <SignMarker>School Stamp</SignMarker>
            <SignMarker>Principal</SignMarker>
          </SignSection>
        </Footer>
      </SheetWrapper>
    </Sheet>
  );
};

export default MarkSheetTemp;
