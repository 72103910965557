import { Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AccountantFeeReportSidebar from '../../../components/Event/EventSidebar/AccountantFeeReportSidebar';
import BackDropLoader from '../../../components/Reusable/BackDropLoader';
import SubModuleLayout from '../../../components/Reusable/Layouts/SubModuleLayout';
import FinanceSidebar from '../../../components/Finance/FinanceSidebar';
import FeeIncomeSearch from '../../../components/Finance/FeeIncome/FeeIncomeSearch';
import FeeIncomeTable from '../../../components/Finance/FeeIncome/FeeIncomeTable';

interface SelectedFeesI {
  id: string;
  title: string;
  isMonthly: boolean;
}

export default function AccountFeeIncomePage() {
  const [student, setStudent] = useState<any>(null);
  const [fee, setFee] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(false);

  // const [selected, setSelected] = useState<any[]>([]);

  const dispatch = useDispatch();

  const nav = <AccountantFeeReportSidebar feeIncome />;

  return (
    <SubModuleLayout sideNav={nav}>
      <Paper style={{ margin: '10px' }}>
        <FeeIncomeSearch
          student={student}
          setStudent={setStudent}
          fee={fee}
          setFee={setFee}
        />
        <FeeIncomeTable fee={fee} setFee={setFee} />
      </Paper>
      <BackDropLoader open={loading} />
    </SubModuleLayout>
  );
}
