import {
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  LinearProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AttendenceColorBox } from "../../../../components/Reusable";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { dateConverterBsToAd } from "../../../../components/utils/dateConverter";
import getDayName from "../../../../components/utils/getDayName";
import { RootStore } from "../../../../store";
import { tblStyle } from "../../StudentDashboardStyles";

const AttendanceTable = (props: any) => {
  const classes = useTableStyles();
  const [dateCells, setDateCells] = useState<any | []>([]);

  const monthDates = useSelector((state: RootStore) => state.month_dates);
  const MyAttendance = useSelector(
    (state: RootStore) => state.my_attendance.my_attendance
  );

  const checkStatus = (date: string) => {
    const dateObject = new Date(date);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today_day = days[dateObject.getDay()];
    if (MyAttendance != null) {
      const index: number = MyAttendance.findIndex(
        (element: any) => element.date === date
      );

      if (today_day != "Sat") {
        if (index >= 0) {
          return MyAttendance[index].status;
        } else {
          return "x";
        }
      } else {
        return "holiday";
      }
    }
  };

  useEffect(() => {
    if (MyAttendance != null) {
      const monthData = monthDates.data.dates;
      if (monthData != null) {
        const prepared = [];

        for (let i = 0; i < monthData.length; i++) {
          const convertedDate = dateConverterBsToAd(monthData[i]);
          const checked = monthData[i].substring(8, 10);
          prepared.push({
            status: checkStatus(convertedDate),
            date: checked,
            day: getDayName(convertedDate),
          });
        }
        setDateCells(prepared);
      }
    }
  }, [MyAttendance]);

  return (
    <>
      <Paper className={classes.rootTableBox} style={tblStyle}>
        {monthDates.loading && (
          <LinearProgress style={{ margin: "-24px -24px 24px -24px" }} />
        )}
        {MyAttendance.length ? (
          <>
            <AttendenceColorBox
              monthName={props.monthName}
              childrenName={props.childrenName}
            />
            <div className={classes.tableWrapper}>
              <TableContainer>
                <Table
                  className={classes.table}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      {dateCells.map((column: any) => (
                        <TableCell
                          key={column.id}
                          align="center"
                          className={classes.newcell}
                        >
                          {column.date}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      {dateCells.map((column: any) => (
                        <TableCell
                          key={column.id}
                          align="center"
                          className={classes.cellDayName}
                        >
                          <span key={column.id}>{column.day}</span>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover tabIndex={-1}>
                      {dateCells.map((item: any, index: number) => {
                        return (
                          <TableCell
                            key={index}
                            align="center"
                            className={classes.newcell}
                            style={{
                              backgroundColor:
                                item.status == "P"
                                  ? "#51AB67"
                                  : item.status == "holiday"
                                  ? "#EA5E42"
                                  : "#B9BCBE",
                            }}
                          ></TableCell>
                        );
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        ) : (
          "There is no data to show."
        )}
      </Paper>
    </>
  );
};

export default AttendanceTable;
