import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_PARENT_MEETING_LOADING,
  GET_PARENT_MEETING_ERROR,
  GET_PARENT_MEETING_SUCCESS,
  MeetingDispatchTypes,
  ADD_PARENT_MEETING_LOADING,
  ADD_PARENT_MEETING_SUCCESS,
  ADD_PARENT_MEETING_ERROR,
  DELETE_PARENT_MEETING_SUCCESS,
  DELETE_PARENT_MEETING_ERROR,
  DELETE_PARENT_MEETING_LOADING,
  UPDATE_PARENT_MEETING_LOADING,
  UPDATE_PARENT_MEETING_SUCCESS,
  UPDATE_PARENT_MEETING_ERROR,
  ParentMeetingII,
} from './ParentMeetingActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { PARENT_API_URL } from '../../../config/SERVER_API_URLS';

export const GetMeetings =
  () => async (dispatch: Dispatch<MeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_PARENT_MEETING_LOADING,
      });

      const res = await axios.get(
        `${PARENT_API_URL}/meeting_request/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_PARENT_MEETING_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PARENT_MEETING_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Meetings Loading Failed',
        },
      });
    }
  };

export const GetSearchedMeetings =
  (query: any) =>
  async (dispatch: Dispatch<MeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_PARENT_MEETING_LOADING,
      });

      const url = `${PARENT_API_URL}/meeting_request/?meeting_type=${query.meetingType}&date_first=${query.date_first}&date_second=${query.date_second}`;

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_PARENT_MEETING_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PARENT_MEETING_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Meetings Loading Failed',
        },
      });
    }
  };

export const AddMeeting =
  (data: ParentMeetingII) =>
  async (dispatch: Dispatch<MeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_PARENT_MEETING_LOADING,
      });

      const res = await axios.post(
        `${PARENT_API_URL}/meeting_request/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_PARENT_MEETING_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Meeting Added Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_PARENT_MEETING_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Meeting Add Failed',
        },
      });
    }
  };

export const UpdateMeeting =
  (id: string, data: any) =>
  async (dispatch: Dispatch<MeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_PARENT_MEETING_LOADING,
      });

      const res = await axios.put(
        `${PARENT_API_URL}/meeting_request/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_PARENT_MEETING_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Meeting Updated Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PARENT_MEETING_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Meeting Update Failed',
        },
      });
    }
  };

export const DeleteMeeting =
  (id: string) =>
  async (dispatch: Dispatch<MeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_PARENT_MEETING_LOADING,
      });

      const res = await axios.delete(
        `${PARENT_API_URL}/meeting_request/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_PARENT_MEETING_SUCCESS,
        payload: { id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Meeting Deleted Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_PARENT_MEETING_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Meeting Delete Failed',
        },
      });
    }
  };
