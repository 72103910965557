import {
  Box,
  Button,
  Chip,
  createStyles,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Add, ChevronRight } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  GetTeacherSubject,
  GetTeachingSubject,
} from '../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectAction';
import {
  TeacherSubjectsI,
  TeacherSubjectByClass,
} from '../../../../../actions/TeacherDash/TeacherSubject/TeacherSubjectActionTypes';
import { GetTeachingClass } from '../../../../../actions/TeacherDash/TeachingClass/TeachingClassAction';
import { TeachingClassTypeI } from '../../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes';

import { RootStore } from '../../../../../store';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import LessonPlanTable from './LessonPlanTable';
import {
  filterStyle,
  newFilterStyle,
  tableStyle,
  tblStyle,
} from '../../../components/Styles/TeacherDashboardStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // margin: theme.spacing(2, 0, 2, 4),
      backgroundColor: theme.palette.background.paper,
      '& .MuiTypography-body1': {
        fontSize: '12px',
        textAlign: 'center',
      },
      '& .MuiTypography-body2': {
        fontSize: '11px',
      },
      '& .Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        '& p': {
          color: theme.palette.secondary.main,
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        },
      },
    },
  })
);

interface SectionII {
  id: string;
  name: string;
}
interface ClassII {
  id: string;
  grade: string;
  grade_name: string;
  section: SectionII[] | null | string;
  section_name?: string;
}

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

const LessonPlanManage = () => {
  const classess = useFormStyles();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedClass, setSelectedClass] = useState<ClassII | null>(null);
  const [classChoices, setClassChoices] = useState<ClassII[]>([]);
  const [subjectList, setSubjectList] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [tempSubject, setTempSubject] = useState<any>();
  const [data, setData] = useState<any>({
    class: '',
    subject: '',
    classID: null,
    subID: null,
  });
  const [subjectID, setSubjectID] = useState<string | undefined>();
  const [openCreateModalPopup, setOpenCreateModalPopup] = useState(false);

  const classSelector = useSelector((state: RootStore) => state.teaching_class);
  const subjectSelector = useSelector(
    (state: RootStore) => state.teacher_subject
  );
  const LessonPlanSelector = useSelector(
    (state: RootStore) => state.teacher_lessonPlan.lesson_plan
  );

  useEffect(() => {
    if (selectedClass) {
      const section: any = selectedClass.section ? selectedClass.section : '';
      dispatch(GetTeachingSubject(selectedClass.grade, section));
    }
  }, [selectedClass]);

  useEffect(() => {
    setSubjectList(subjectSelector.subject_by_class);
    subjectSelector?.subject_by_class &&
      setTempSubject(subjectSelector?.subject_by_class[0]);
    setData({
      class: selectedClass?.grade_name,
      classID: selectedClass,
      subID: tempSubject ? tempSubject : subjectSelector?.subjects[0]?.subject,
      subject: subjectID
        ? subjectID
        : subjectSelector?.subjects[0]?.subject_name,
    });
  }, [selectedClass, subjectSelector, subjectID]);

  useEffect(() => {
    dispatch(GetTeachingClass());
  }, []);

  useEffect(() => {
    const classes = classSelector.teaching_class;
    const finalArray: ClassII[] = [];
    classes.forEach((item: any) => {
      if (item.section === null) {
        finalArray.push(item);
      } else {
        for (let i = 0; i < item.section.length; i++) {
          const data1 = {
            ...item,
            section: item.section[i].id,
            section_name: item.section[i].name,
          };
          finalArray.push(data1);
        }
      }
    });
    setClassChoices(finalArray);
    setSelectedClass(finalArray[0]);
  }, [classSelector.teaching_class]);

  const handleClassChange = (value: ClassII | null) => {
    value != null && setSelectedClass(value);
    setTempSubject(undefined);
    setSelectedIndex(0);
  };

  const handleListItemClick = (
    event: any,
    index: number,
    item: TeacherSubjectByClass
  ) => {
    setSelectedIndex(index);
    setTempSubject(item);
    setSubjectID(item.id);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <Paper style={filterStyle}>
            <div className={classes.root}>
              <Typography variant="h6">Lesson Plan</Typography>
              <Divider />
              <List component="nav" aria-label="subject list">
                {subjectList &&
                  subjectList.map(
                    (item: TeacherSubjectByClass, index: number) => {
                      return (
                        <ListItemLink
                          key={index}
                          selected={selectedIndex === index}
                          onClick={(event) =>
                            handleListItemClick(event, index, item)
                          }
                        >
                          <ListItemText
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                            primary={item.name}
                            secondary={`${selectedClass?.grade_name}  ${
                              selectedClass?.section_name
                                ? `(${selectedClass?.section_name})`
                                : ''
                            }`}
                          />
                          {/* <ListItemText
                          primary={`(${item.grade_name}${item.section_name})`}
                        /> */}
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="comments">
                              <ChevronRight />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItemLink>
                      );
                    }
                  )}
              </List>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Paper style={tableStyle}>
            <form className={classess.form}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={4} className={classess.formWrapper}>
                  <Autocomplete
                    classes={{
                      input: classess.smallfont,
                      option: classess.smallfont,
                    }}
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: ClassII | null
                    ) => handleClassChange(value)}
                    options={classChoices}
                    value={selectedClass}
                    getOptionLabel={(option) =>
                      `Class ${option.grade_name} ${
                        option.section_name !== undefined
                          ? `(${option?.section_name})`
                          : ''
                      }`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="--select Class--"
                        name="class_name"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Chip
                    style={{ marginTop: '10px' }}
                    variant="default"
                    size="small"
                    label={`${LessonPlanSelector.length} Lessons`}
                    color="primary"
                  />
                </Grid>

                <Grid style={{ marginTop: '5px' }} item xs={3}>
                  <Link
                    style={{ textDecoration: 'none' }}
                    // to="/lesson-plan/create"
                    to={{
                      pathname: 'lesson-plan-create',
                      state: data,
                    }}
                  >
                    <Button
                      startIcon={<Add />}
                      style={{ minWidth: '140px' }}
                      variant="outlined"
                      color="primary"
                    >
                      Lesson Plan
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </form>
            <LessonPlanTable data={data} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default LessonPlanManage;
