import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_CHILDREN_ENOTES_LOADING,
  GET_CHILDREN_ENOTES_ERROR,
  GET_CHILDREN_ENOTES_SUCCESS,
  ChildrenEnotesDispatchTypes,
} from "./ChildrenEnoteActionTypes";

import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { PARENT_API_URL } from "../../../config/SERVER_API_URLS";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";

export const GetChildrenEnotes = (student_id: string) => async (
  dispatch: Dispatch<ChildrenEnotesDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_CHILDREN_ENOTES_LOADING,
    });

    const res = await axios.get(
      `${PARENT_API_URL}/parent_enote/?student_academic_id=${student_id}`,
      HeaderConfig()
    );

    dispatch({
      type: GET_CHILDREN_ENOTES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CHILDREN_ENOTES_ERROR,
    });
  }
};
