import { Box, Button, makeStyles, Theme } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import PageHeader from "../../components/Header/PageHeader";
import ConstructionPageImg from "../../../../assets/images/ConstructionPage.jpg";
import PageWrapper from "../../components/PageWrapper";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    paddingTop: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "center",
  },
  primaryBtn: {
    width: "auto",
    marginTop: "16px",
    marginRight: "12px",
  },
  secondaryBtn: {
    width: "auto",
    marginTop: "16px",
  },
}));

const PageNotFound = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };


  return (
    <Box>
      <PageHeader title="Under Construction" />

      <PageWrapper>
        <div className={classes.root}>
          <div>
            <img src={ConstructionPageImg} width="500px" />
            <div className={classes.actContainer}>
              <Button
                type="submit"
                color="default"
                variant="contained"
                component={Link}
                to="/"
                className={classes.primaryBtn}
              >
                Return To Dashboard
              </Button>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={handleBack}
                className={classes.secondaryBtn}
              >
                Go Back
              </Button>
            </div>
          </div>
        </div>
      </PageWrapper>
    </Box>
  );
};

export default PageNotFound;
