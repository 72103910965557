import {
  Avatar,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StudentSearchByTeacher } from '../../../../actions/TeacherDash/TeacherStudentList/TeacherStudentListAction';
import { PRIMARY_DATE_FORMAT } from '../../../../common/constant';
import checkImageUrl from '../../../../components/utils/checkImageUrl';
import { dateConverterAdToBs } from '../../../../components/utils/dateConverter';
import getFullName from '../../../../components/utils/getFullName';
import { RootStore } from '../../../../store';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  profileImg: {
    width: '60px',
    height: '60px',
    alignItems: 'center',
    margin: '5px 20px',
    border: '2px solid #5767a6',
  },
  title: {
    marginTop: '5px',
    color: 'rgba(0,0,0,0.75)',
    fontWeight: 500,
    fontSize: '20px',
  },
  content: {
    maxWidth: '250px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.2rem 1.5rem 0.2rem 0rem',
  },
  contentTitle: {
    color: 'gray',
  },
});

const TeacherComplainView = (props: any) => {
  const classes = useStyles();
  const { viewData } = props;
  const dispatch = useDispatch();

  const [student, setStudent] = React.useState<any>();

  const studentSelector = useSelector(
    (state: RootStore) => state.teacher_studentList.student_list
  );

  React.useEffect(() => {
    dispatch(StudentSearchByTeacher(viewData.grade, viewData.section));
  }, [viewData]);

  React.useEffect(() => {
    const findStudent = studentSelector.find(
      (elem) => elem.id == viewData.student
    );
    setStudent(findStudent);
  }, [viewData, studentSelector]);

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          spacing={3}
          style={{ padding: '1rem 0' }}
          justifyContent="space-between"
        >
          <Grid item xs={5} style={{ padding: 0 }}>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Avatar
                  className={classes.profileImg}
                  alt=""
                  src={checkImageUrl(viewData?.student_photo)}
                />
              </Grid>
              <Grid>
                <Typography
                  className={classes.title}
                  align="center"
                  gutterBottom
                  variant="h4"
                  component="h3"
                >
                  {getFullName(
                    viewData?.student_info?.first_name,
                    viewData?.student_info?.last_name
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <div className={classes.content}>
              <Typography
                variant="body2"
                className="contentTitle"
                component="p"
              >
                Address:
              </Typography>
              <Typography variant="body2" component="p">
                {student?.address}
              </Typography>
            </div>
            <div className={classes.content}>
              <Typography
                variant="body2"
                className="contentTitle"
                component="p"
              >
                Guardian:
              </Typography>
              <Typography variant="body2" component="p">
                {getFullName(
                  student?.guardian_first_name,
                  student?.guardian_last_name
                )}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <Divider style={{ marginBottom: '20px' }} />
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Feedbacker Name:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {/* {getFullName(viewData?.complainer.first_name, viewData?.last_name)} */}
            {viewData?.complainer.first_name} {viewData?.complainer.last_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Feedback Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {dateConverterAdToBs(
              moment(viewData?.created_on).format(PRIMARY_DATE_FORMAT)
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Description:
        </Grid>

        <Grid item xs={8}>
          {viewData?.description}
        </Grid>
      </Grid>
    </>
  );
};

export default TeacherComplainView;
