import { Box } from '@material-ui/core';
import { useParams, useLocation } from 'react-router-dom';
import PageHeader from '../../../components/Header/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GetStudentAssignmentbyID } from '../../../../../actions/StudentDash/StudentAssignment/StudentAssignmentAction';
import { RootStore } from '../../../../../store';
import AssignmentCard from './AssignmentCard';
import SubmitAssignment from './SubmitAssignment';
import PageWrapper from '../../../components/PageWrapper';
import { GetChildrenAssignmentDetail } from "../../../../../actions/ParentsDash/ChildrenAssignment/ChildrenAssignmentAction"

const ROLE = localStorage.getItem("role");

const AssignmentSubmitPage = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const location: any = useLocation();

  useEffect(() => {
    ROLE === "Student" && dispatch(GetStudentAssignmentbyID(params.id));
    ROLE === "Parent" && dispatch(GetChildrenAssignmentDetail(location?.state?.id, params.id));
  }, []);

  const { assignmentbyID } = useSelector((state: RootStore) => state.student_assignment)
  const { assignment_detail } = useSelector((state: RootStore) => state.children_assignment)

  return (
    <Box>
      <PageHeader title="Submit Assignment" />

      <PageWrapper>
        {assignmentbyID && ROLE === "Student" && (
          <Box>
            <AssignmentCard item={assignmentbyID} />
            <SubmitAssignment />
          </Box>
        )}
        {assignment_detail && ROLE === "Parent" && location.state.id && (
          <Box>
            <AssignmentCard item={assignment_detail} />
            <SubmitAssignment academicId={location?.state?.id} />
          </Box>
        )}
      </PageWrapper>
    </Box>
  );
};

export default AssignmentSubmitPage;
