import React, { useEffect, useState } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import {
  fade,
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from '@react-spring/web'; // web.cjs is required for IE 11 support
import { TransitionProps } from '@material-ui/core/transitions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import { GetSubUnit } from '../../../../../actions/TeacherDash/Syllabus/SyllabusAction';
import { SubUnitTypeI } from '../../../../../actions/TeacherDash/Syllabus/SyllabusActionTypes';
import { GetStudentSubUnit } from '../../../../../actions/StudentDash/StudentSyllabus/StudentSyllabusAction';
import { StudentSubUnitTypeI } from '../../../../../actions/StudentDash/StudentSyllabus/StudentSyllabusActionTypes';
import { Typography } from '@material-ui/core';

import {
  ArrowDropDown,
  ArrowRight,
  DoubleArrow,
  Folder,
  FolderOpen,
} from '@material-ui/icons';

declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  // labelInfo?: string;
  labelText: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label':
        {
          backgroundColor: 'transparent',
        },
    },
    // content: {
    //   color: theme.palette.text.secondary,
    //   borderTopRightRadius: theme.spacing(2),
    //   borderBottomRightRadius: theme.spacing(2),
    //   paddingRight: theme.spacing(1),
    //   fontWeight: theme.typography.fontWeightMedium,
    //   "$expanded > &": {
    //     fontWeight: theme.typography.fontWeightRegular,
    //   },
    // },
    group: {
      marginLeft: 0,
      borderLeft: `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
  })
);

function StyleTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    // labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      TransitionComponent={TransitionComponent}
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          {/* <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography> */}
        </div>
      }
      // style={{
      //   "--tree-view-color": color,
      //   "--tree-view-bg-color": bgColor,
      // }}
      classes={{
        root: classes.root,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 20, height: 20 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
  })
)((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 264,
      flexGrow: 1,
      // maxWidth: 400,
      marginTop: 10,
      '& .MuiTypography-body1': {
        fontSize: '16px',
      },
    },
  })
);

const SyllabusViewUnit = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { item, indexN, student, admin } = props;

  const [subUnit, setSubUnit] = useState<any>([]);

  const syllabusSelector = useSelector(
    (state: RootStore) => state.teacher_syllabus
  );

  const studentSyllabusSelector = useSelector(
    (state: RootStore) => state.student_syllabus
  );

  const adminSyllabusSelector = useSelector(
    (state: RootStore) => state.admin_syllabus
  );

  useEffect(() => {
    item && student
      ? dispatch(GetStudentSubUnit(item.id))
      : dispatch(GetSubUnit(item.id));
  }, [student]);

  useEffect(() => {
    const tempSubUnit: any = [];
    student
      ? studentSyllabusSelector?.sub_unit.map((item: StudentSubUnitTypeI) => {
          tempSubUnit.push({
            id: item.id,
            title: item.title,
          });
        })
      : syllabusSelector?.sub_unit.map((item: SubUnitTypeI) => {
          tempSubUnit.push({
            id: item.id,
            title: item.title,
          });
        });
    setSubUnit(tempSubUnit);
  }, [syllabusSelector?.sub_unit]);

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['1']}
      // defaultCollapseIcon={<MinusSquare />}
      defaultCollapseIcon={<ArrowDropDown />}
      // defaultExpandIcon={<PlusSquare />}
      defaultExpandIcon={<ArrowRight />}
      // defaultEndIcon={<CloseSquare />}
    >
      <StyleTreeItem
        nodeId="1"
        labelIcon={Folder}
        labelText={`Unit ${indexN} - ${item.name}`}
      >
        {/* <StyledTreeItem nodeId="2" label={item.name}> */}
        {subUnit &&
          subUnit.map((elem: any) => {
            return (
              <StyleTreeItem
                labelIcon={DoubleArrow}
                key={elem.id}
                nodeId={elem.id}
                labelText={elem.title}
              />
            );
          })}
        {/* <StyledTreeItem nodeId="9" label="Child 1" />
          <StyledTreeItem nodeId="10" label="Child 2" />
          <StyledTreeItem nodeId="11" label="Child 3" /> */}
        {/* </StyledTreeItem> */}
      </StyleTreeItem>
    </TreeView>
  );
};

export default SyllabusViewUnit;
