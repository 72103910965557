import {
  GET_CLASS_ATTENDANCE_LOADING,
  GET_CLASS_ATTENDANCE_ERROR,
  GET_CLASS_ATTENDANCE_SUCCESS,
  POST_CLASS_ATTENDANCE_ERROR,
  POST_CLASS_ATTENDANCE_LOADING,
  POST_CLASS_ATTENDANCE_SUCCESS,
  ClassAttendanceDispatchTypes,
  GetClassAttendanceTypeI,
} from "../../actions/TeacherDash/ClassAttendance/ClassAttendanceActionTypes";

interface InitialStateI {
  loading: boolean;
  student_attendances: GetClassAttendanceTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  student_attendances: [],
};

const ClassAttendanceReducer = (
  state: InitialStateI = initialState,
  action: ClassAttendanceDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_CLASS_ATTENDANCE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CLASS_ATTENDANCE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CLASS_ATTENDANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        student_attendances: action.payload,
      };

    case POST_CLASS_ATTENDANCE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case POST_CLASS_ATTENDANCE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case POST_CLASS_ATTENDANCE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default ClassAttendanceReducer;
