import { Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetStudentEvents } from "../../../../actions/StudentDash/StudentEvents/StudentEventAction";
import { StudentEventTypeI } from "../../../../actions/StudentDash/StudentEvents/StudentEventActionTypes";
import { RootStore } from "../../../../store";
import Popups from "../../../../components/Reusable/Dialogs/Popups";
import { ItemViewButton } from "../../../../components/Reusable/Buttons/TableButton";
import { ViewEventModal } from "../../../TeacherDashboard/pages/TeacherEvents/ModalView/ViewEventModal";
import { useTableStyles } from "../../../../components/Styles/TableStyles";
import { dateConverterAdToBs } from "../../../../components/utils/dateConverter";
import useTable from "../../../../components/Reusable/useStudentTable";
import moment from "moment";
import { tblStyle } from "../../StudentDashboardStyles";
import { fromToConverter } from "../../../../components/utils/dateTimeFormat";

interface HeadCellsI {
  id: string;
  label: string;
  filter?: boolean;
  align?: "left" | "right" | "center";
}
// ---------------- <END> Interface------------------//

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: "title", label: "Title", filter: true, align: "left" },
  // { id: "event_type", label: "Type" },
  { id: "location", label: "Location", align: "left" },
  { id: "date", label: "Date", align: "left" },
  { id: "time", label: "Time", align: "left" },
  { id: "is_holiday", label: "Holiday" },
  { id: "action", label: "Action" },
];

const StudentEventsTable = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<StudentEventTypeI[]>([]);

  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [item, setItem] = useState<StudentEventTypeI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const studentEventSelector = useSelector(
    (state: RootStore) => state.student_event
  );

  useEffect(() => {
    dispatch(GetStudentEvents());
  }, []);

  useEffect(() => {
    const data = studentEventSelector.student_event;
    setTableData(data);
    setLoading(studentEventSelector.loading);
  }, [studentEventSelector]);

  const handleViewModal = (item: any) => {
    setItem(item);
    setItemId(item.id);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const getCustomTableRow = (item: StudentEventTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.location}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          style={{ width: "15%" }}
          className={classes.cell}
        >
          {fromToConverter(item.from_date, item.to_date)}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {moment(item.time, "HH:mm:ss").format("LT")}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.is_holiday ? "Yes" : "No"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton
            title="View"
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewModal(item)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root} style={tblStyle}>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="title"
          rowsPerPage={4}
          placeholder="Search Events"
          label="Events"
        />
      </Paper>

      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        fullWidth={true}
        maxWidth="sm"
        title="Events Detail"
        colored
      >
        <ViewEventModal item={item} />
      </Popups>
    </>
  );
};

export default StudentEventsTable;
