import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_CHILDREN_SUBJECTS_LOADING,
  GET_CHILDREN_SUBJECTS_ERROR,
  GET_CHILDREN_SUBJECTS_SUCCESS,
  ChildrenSubjectsDispatchTypes,
} from "./ChildrenSubjectActionTypes";

import { PARENT_API_URL } from "../../../config/SERVER_API_URLS";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";

export const GetChildrenSubjects =
  (id: string) =>
  async (dispatch: Dispatch<ChildrenSubjectsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_CHILDREN_SUBJECTS_LOADING,
      });

      const res = await axios.get(
        `${PARENT_API_URL}/student_subjects/?student_academic_id=${id}`,
        HeaderConfig()
      );
      dispatch({
        type: GET_CHILDREN_SUBJECTS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CHILDREN_SUBJECTS_ERROR,
      });
    }
  };
