import { createTheme } from '@material-ui/core';
import theme from '../../common/Global.css';

export const StudentDashboardTheme = createTheme({
  ...theme,
  props: {
    MuiButton: {
      disableRipple: false,
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: false,
    },

    MuiCheckbox: {
      disableRipple: false,
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: 'Montserrat, Poppins, Roboto, sans-serif',
        fontSize: '14px',
      },
    },
  },

  palette: {
    ...theme.palette,
    primary: {
      ...theme.palette.primary,
      main: '#132e98',
    },
    secondary: {
      ...theme.palette.secondary,
      main: '#f2e82c',
    },
  },
  typography: {
    ...theme.typography,
    fontFamily: 'Montserrat, Poppins, Roboto, sans-serif',
  },
});
