import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useFormStyles } from "../../../../../components/Styles/FormStyles";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemSecondaryAction,
  Paper,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../../../actions/Academics/Class/ClassAction";
import { RootStore } from "../../../../../store";
import { green, pink, deepOrange } from "@material-ui/core/colors";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { GetTeacherClassSection } from "../../../../../actions/TeacherDash/TeacherClass/TeacherClassAction";
import { GetTeachingClass } from "../../../../../actions/TeacherDash/TeachingClass/TeachingClassAction";
import { SectionTypeI } from "../../../../../actions/Academics/Section/SectionActionTypes";
import { TeachingClassTypeI } from "../../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";
import { GetLiveClass } from "../../../../../actions/TeacherDash/TeacherLiveClass/TeacherLiveAction";
import { filterStyle } from "../../../components/Styles/TeacherDashboardStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      "& .MuiTypography-body1": {
        fontSize: "15px",
        textAlign: "left",
      },
      "& .MuiTypography-body2": {
        fontSize: "11px",
      },
      "& .Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        "& p": {
          color: theme.palette.secondary.main,
        },
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        },
      },
    },
    pink: {
      color: theme.palette.getContrastText(pink[500]),
      backgroundColor: pink[200],
    },
    green: {
      color: "#fff",
      backgroundColor: green[200],
    },
    orange: {
      color: "#fff",
      backgroundColor: deepOrange[200],
    },
  })
);

export default function SelectedListItem(props: any) {
  const { onClassData } = props;
  const classes = useStyles();
  const classesX = useFormStyles();
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [classChoices, setClassChoices] = useState<any>(
    []
  );
  const [classItem, setClassItem] = useState<any>([]);
  const [searchItem, setSearchItem] = useState<any>(null);

  useEffect(() => {
    dispatch(GetTeachingClass());
  }, []);

  const classesState = useSelector(
    (state: RootStore) => state.teaching_class.teaching_class
  );

  useEffect(() => {
    if (classesState != null) {
      const finalClass = [];
      for (let i = 0; i < classesState.length; i++) {
        if (classesState[i].section?.length) {
          for (let j = 0; j < classesState[i].section?.length; j++) {
            finalClass.push({
              id: classesState[i].id,
              grade: classesState[i].grade,
              grade_name: classesState[i].grade_name,
              section_id: classesState[i].section[j].id,
              name: classesState[i].section[j].name,
            });
          }
        } else {
          finalClass.push({
            id: classesState[i].id,
            grade: classesState[i].grade,
            grade_name: classesState[i].grade_name,
            section_id: "",
            name: "",
          });
        }
      }
      setClassItem(finalClass);
    }
    classesState && setClassChoices(classesState);
  }, [classesState]);

  useEffect(() => {
    if (searchItem == null && classItem.length) {
      setSearchItem(classItem[0]);
      onClassData(classItem[0]);
    }
  }, [classItem]);

  useEffect(() => {
    if (searchItem) {
      dispatch(GetLiveClass(searchItem.grade, searchItem.section_id));
    }
  }, [searchItem]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    classItem: any
  ) => {
    setSearchItem(classItem);
    setSelectedIndex(index);
    onClassData(classItem);
  };

  const getRandomColors = (num: number) => {
    const arr = [classes.green, classes.pink, classes.orange];
    return arr[num % arr.length];
  };

  return (
    <Paper style={filterStyle}>
      <Typography variant="h6">Classes</Typography>
      <Divider />

      <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders">
          {classItem?.map((classItem: any, index: number) => (
            <ListItem
              key={+`${index}`}
              button
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index, classItem)}
            >
              {/* <ListItemIcon>
                <Avatar className={getRandomColors(+classItem.grade)}>
                  {classItem.grade_name.slice(0, 2)}
                </Avatar>
              </ListItemIcon> */}
              <ListItemText
                primary={`Class ${classItem.grade_name} ${classItem.name ? classItem.name : ""
                  }`}
              />

              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments">
                  <ChevronRightIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </Paper>
  );
}
