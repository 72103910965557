import {
  GET_STUDENT_ANNOUNCEMENTS_LOADING,
  GET_STUDENT_ANNOUNCEMENTS_ERROR,
  GET_STUDENT_ANNOUNCEMENTS_SUCCESS,
  StudentAnnouncementsDispatchTypes,
  StudentAnnouncementTypeI,
} from "../../actions/StudentDash/StudentAnnouncement/StudentAnnouncementActionTypes";

interface InitialStateI {
  loading: boolean;
  announcements: StudentAnnouncementTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  announcements: [],
};

const StudentAnnouncementReducer = (
  state: InitialStateI = initialState,
  action: StudentAnnouncementsDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STUDENT_ANNOUNCEMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        announcements: action.payload,
      };

    default:
      return state;
  }
};

export default StudentAnnouncementReducer;
