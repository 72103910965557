import { StudentPeriods } from "../../StudentDash/StudentTimeTable/StudentTimeTableActionTypes";

export const GET_CHILDREN_TIMETABLE_LOADING = "GET_CHILDREN_TIMETABLE_LOADING";
export const GET_CHILDREN_TIMETABLE_ERROR = "GET_CHILDREN_TIMETABLE_ERROR";
export const GET_CHILDREN_TIMETABLE_SUCCESS = "GET_CHILDREN_TIMETABLE_SUCCESS";

export const GET_CHILDREN_PERIOD_LOADING = "GET_CHILDREN_PERIOD_LOADING";
export const GET_CHILDREN_PERIOD_ERROR = "GET_CHILDREN_PERIOD_ERROR";
export const GET_CHILDREN_PERIOD_SUCCESS = "GET_CHILDREN_PERIOD_SUCCESS";

export interface ChildrenTimeTable {
  day: number;
  period_info: {
    id: string;
    name: string;
    grade: string;
    start_time: string;
    end_time: string;
  };
  subject: {
    id: string;
    name: string;
  };
  teacher: {
    id: string;
    first_name: string;
    last_name: string;
  };
}
export interface ChildrenTimeTableI {
  grade: {
    created_by: string;
    general_info: number;
    grade_name: string;
    id: string;
    name: string;
  };
  section: {
    created_by: string;
    general_info: number;
    id: string;
    name: string;
  };
  timetable: ChildrenTimeTable[];
}

//===============================<START>GET CHILDREN TIMETABLE<START>======================//

export interface GetChildrenTimeTableLoading {
  type: typeof GET_CHILDREN_TIMETABLE_LOADING;
}

export interface GetChildrenTimeTableError {
  type: typeof GET_CHILDREN_TIMETABLE_ERROR;
}

export interface GetChildrenTimeTableSuccess {
  type: typeof GET_CHILDREN_TIMETABLE_SUCCESS;
  payload: ChildrenTimeTableI;
}

//===============================<END>GET CHILDREN TIMETABLE<END>======================//

//===============================<START>GET CHILDREN PERIOD<START>======================//

export interface GetChildrenPeriodLoading {
  type: typeof GET_CHILDREN_PERIOD_LOADING;
}

export interface GetChildrenPeriodError {
  type: typeof GET_CHILDREN_PERIOD_ERROR;
}

export interface GetChildrenPeriodSuccess {
  type: typeof GET_CHILDREN_PERIOD_SUCCESS;
  payload: StudentPeriods;
}

//===============================<END>GET CHILDREN PERIOD<END>======================//

export type ChildrenTimeTableDispatchTypes =
  | GetChildrenTimeTableLoading
  | GetChildrenTimeTableError
  | GetChildrenTimeTableSuccess
  | GetChildrenPeriodLoading
  | GetChildrenPeriodError
  | GetChildrenPeriodSuccess;
