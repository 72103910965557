import { Box } from '@material-ui/core';
import PageHeader from '../../components/Header/PageHeader';
import PageWrapper from '../../components/PageWrapper';
import TeacherEventsList from './ModalView/TeacherEventsList';

/*
 * Extra curicular activities (ECA) page
 * Teacher can mark as attented or not with remarks
 * Teacher can see event result, winner, and can celebrate it.
 */

const TeacherEcaEvents = () => {
  return (
    <Box>
      <PageHeader title="ECA Event" />
      <PageWrapper>
        <TeacherEventsList />
      </PageWrapper>
    </Box>
  );
};

export default TeacherEcaEvents;
