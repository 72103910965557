import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import {
  AddLeaveType,
  UpdateLeaveType,
} from '../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import FormLayout from '../../Reusable/Layouts/Form.Layout';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import { LeaveTypeTypeI } from '../../../actions/HumanResource/LeaveType/LeaveTypeActionTypes';
import { Grid } from '@material-ui/core';

interface LeaveTypeFormProps {
  editData: LeaveTypeTypeI | null;
}

const LeaveTypeForm = (props: LeaveTypeFormProps) => {
  const { editData } = props;
  const dispatch = useDispatch();

  const { register, handleSubmit, setValue, reset, errors } = useForm({
    mode: 'onChange',
  });

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const leaveTypeLoading: boolean = useSelector(
    (state: RootStore) => state.leave_type.loading
  );

  const add_or_update = useSelector((state: RootStore) => state.leave_type.add_or_update)

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    setLoading(leaveTypeLoading);
  }, [leaveTypeLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const handleEditTableData = (data: LeaveTypeTypeI) => {
    setEditMode(true);
    setValue('name', data.name);
    setValue('max_leave', data.max_leave);
    setEditID(data.id);
  };

  const onSubmit = (data: LeaveTypeTypeI) => {
    setIsBtnLoading(true);
    if (editMode) {
      editID != null && dispatch(UpdateLeaveType(editID, data));
    } else {
      dispatch(AddLeaveType(data));
    }
  };

  const handleReset = () => {
    reset();
    setEditID("");
    setEditMode(false);
    setValue('name', "");
    setValue('max_leave', null);
  }

  return (
    <FormLayout
      title="Add Leave Type"
      onSubmit={handleSubmit(onSubmit)}
      editMode={editMode}
      loading={loading}
      onClick={!isBtnLoading && handleReset}
      add_or_update={isBtnLoading}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CustomizedTextField
            label="Name"
            placeholder="Label"
            name="name"
            required
            error={errors['name'] ? 'Please enter leave name.' : ''}
            inputRef={register({ required: true })}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomizedTextField
            type="number"
            label="Max. Leave Days"
            placeholder="Number of leave days"
            name="max_leave"
            required
            error={
              errors['max_leave'] ? 'Please enter maximum days of leave.' : ''
            }
            inputRef={register({ required: true })}
          />
        </Grid>
      </Grid>
    </FormLayout>
  );
};

export default LeaveTypeForm;
