// ---------------------------- <START> Import Module Starts ---------------------------//
import React, { useEffect, useState } from "react";
import {
  Paper,
  TableBody,
  TextField,
  Grid,
  Typography,
  Tooltip,
} from "@material-ui/core";
import {
  Add,
  DeleteForeverOutlined,
  GetApp,
  NoteAdd,
  PictureAsPdfOutlined,
} from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { useTable } from "../../../../../components/Reusable";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import TableActionButtons from "../../../../../components/Reusable/Buttons/TableActionButtons";
import Popups from "../../../../../components/Reusable/Dialogs/Popups";
import AddNewButton from "../../../../../components/Reusable/Buttons/AddNewButton";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import {
  GetDocument,
  DeleteDocument,
} from "../../../../../actions/Student/StudentDocument/StudentDocumentAction";
import { DocumentTypeI } from "../../../../../actions/Student/StudentDocument/StudentDocumentActionTypes";
import {
  ItemDeleteButton,
  DownloadButton,
} from "../../../../../components/Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../../../../components/Reusable/Dialogs/DeleteDialog";
import { downloadEmployeeData } from "../../../../../components/HumanResource/StaffList/StaffProfile/StaffTabs/StaffDocument";
import { GetViewChildData } from "../../../../../actions/ParentsDash/ViewChild/ViewChildAction";
import { SITE_URL } from "../../../../../config/SERVER_API_URLS";
// ---------------------------- <END> Import Module Ends---------------------------//

// ---------------------------- <START> Interface Starts ----------------------------------//

export interface HeadCellsI {
  id: string;
  label: string;
}

interface PropsI {
  studentID: number;
  item: number;
}
// ---------------------------- <END> Interface Ends ----------------------------------//

// ---------------------------- <START> Table Dummy Starts---------------------------//

// ---------------------------- <START> Table Dummy Ends ---------------------------//

// ---------------------------- <START> Table Headings Data Starts ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "file", label: "File" },
  { id: "file_name", label: "File Name" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Ends ---------------------------//

// ---------------------------- <START> Document Table Starts ---------------------------//
const ChildDocument = (props: any) => {
  const classes = useTableStyles();

  // State Declarations
  const [tableData, setTableData] = useState<DocumentTypeI[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  // ---------------------------- <END> Pagination States and Functions Ends ---------------------------//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, TableContainer, StyledTableRow } =
    useTable(headCells);

  useEffect(() => {
    const data = props.childDocument;
    setTableData(data);
  }, [props.childDocument]);

  const getDocument = (source: string) => {
    if (source[0] == "/") {
      return SITE_URL + source;
    }
    return source;
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.document.split(".").pop() == "pdf" ? (
            <PictureAsPdfOutlined style={{ color: "#c91a69" }} />
          ) : (
            <img
              src={getDocument(item.document)}
              alt=""
              height="30px"
              width="30px"
            />
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.title}
        </StyledTableCell>

        <StyledTableCell align="center" className={classes.cell}>
          <DownloadButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              downloadEmployeeData(getDocument(item.document))
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper elevation={0} className={classes.roottable}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6">Documents</Typography>
        </Grid>
      </Grid>
      <TableContainer
        loading={loading}
        headCells={headCells}
        items={tableData}
        getCustomTableRow={getCustomTableRow}
        search_name="title"
      />
    </Paper>
  );
};
// ---------------------------- <END> Document Table ends---------------------------//
export default ChildDocument;
