import { useState } from 'react'
import { Box } from '@material-ui/core'
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import AddMarksSearch from './AddMarksSearch';
import AddMarksTable from './AddMarksTable';
import { TeachingClassTypeI } from "../../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes";
import { SectionTypeI } from "../../../../actions/Academics/Section/SectionActionTypes";
import { StudentI } from "../../../../actions/TeacherDash/TeacherRubric/TeacherRubricActionTypes"
import { T_ExamMarksI, T_ExamI, T_ExamSubjectsI } from "../../../../actions/TeacherDash/AddMarks/AddMarksActionTypes"

const AddMarks = () => {
  const [selectedExamType, setSelectedExamType] = useState<T_ExamI | null>(null);
  const [selectedClass, setSelectedClass] = useState<TeachingClassTypeI | null>(null)
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(null)
  const [selectedSubject, setSelectedSubject] = useState<T_ExamSubjectsI | null>(null);

  return (
    <Box>
      <PageHeader
        title="Rubric"
      />
      <PageWrapper>
        <AddMarksSearch stateProps={{ selectedClass, setSelectedClass, selectedSection, setSelectedSection, selectedExamType, setSelectedExamType, selectedSubject, setSelectedSubject }} />
        <AddMarksTable stateProps={{ selectedClass, selectedSection, selectedExamType, selectedSubject }} />
      </PageWrapper>
    </Box>
  )
}

export default AddMarks