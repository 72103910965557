import {
  Box,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import BreadCrumbs from '../../../StudentDashboard/components/Breadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
  content1: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '240px',
    background: '#f0f0fc',
    position: 'relative',
  },
}));

const PageHeader = (props: any) => {
  const classes = useStyles();

  return (
    <Box className={classes.content1}>
      <Box style={{ flex: '1', padding: '8px 24px 0 24px' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              variant="h5"
              color="primary"
              style={{ marginTop: '10px' }}
            >
              <span style={{ fontWeight: 600 }}>{props.title}</span>
            </Typography>
            <BreadCrumbs module={props.module} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PageHeader;
