import {
  GET_TEACHER_CLASS_LOADING,
  GET_TEACHER_CLASS_ERROR,
  GET_TEACHER_CLASS_SUCCESS,
  GET_TEACHER_SECTION_LOADING,
  GET_TEACHER_SECTION_ERROR,
  GET_TEACHER_SECTION_SUCCESS,
  TeacherClassDispatchTypes,
  TeacherClassSectionI,
} from "../../../actions/TeacherDash/TeacherClass/TeacherClassActionTypes";

interface InitialStateI {
  loading: boolean;
  classes: TeacherClassSectionI[];
  sections: any[];
  class_section: TeacherClassSectionI[];
}

const initialState: InitialStateI = {
  loading: false,
  classes: [],
  sections: [],
  class_section: [],
};

const TeacherClassReducer = (
  state: InitialStateI = initialState,
  action: TeacherClassDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_CLASS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        classes: action.payload,
      };
    case GET_TEACHER_SECTION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_SECTION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        sections: action.payload,
        class_section: action.payload,
      };

    default:
      return state;
  }
};

export default TeacherClassReducer;
