import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Search, SettingsBackupRestore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Tupple } from '../../../../actions';
import { RootStore } from '../../../../store';
import AddNewButton from '../../../../components/Reusable/Buttons/AddNewButton';
import FormButton from '../../../../components/Reusable/Buttons/FormButton';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import CustomizedNepaliDatePicker from '../../../../components/Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect from '../../../../components/Reusable/Inputs/Select';
import {
  GetStudentDashLeave,
  GetStudentSearchedLeaves,
} from '../../../../actions/StudentDash/StudentLeave/StudentDashLeaveAction';
import { useFormStyles } from '../../../../components/Styles/FormStyles';
import StudentLeaveEditModal from './StudentLeaveEditModal';
import { dateConverterBsToAd } from '../../../../components/utils/dateConverter';
import { SubmitButton } from '../../../../components/Reusable/Buttons/SearchButton';
import { filterStyle } from '../../StudentDashboardStyles';
import { CUR_NEPALI_DATE } from '../../../../components/utils/nepaliDateUtils';

const StudentLeaveSearch = () => {
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { register, handleSubmit, errors } = useForm();

  const [fromDate, setFromDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [toDate, setToDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    dispatch(GetStudentDashLeave());
  }, []);

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const onSubmit = (data: any) => {
    if (fromDate && toDate) {
      const query: any = {
        date_first: (fromDate && dateConverterBsToAd(fromDate)) || '',
        date_second: (toDate && dateConverterBsToAd(toDate)) || '',
      };
      dispatch(GetStudentSearchedLeaves(query));
    }
  };

  const handleReset = () => {
    setFromDate(CUR_NEPALI_DATE);
    setToDate(CUR_NEPALI_DATE);

    const query: any = {
      date_first: '',
      date_second: '',
    };

    dispatch(GetStudentSearchedLeaves(query));
  };

  return (
    <>
      <Paper className={classes.pageAttachContent} style={filterStyle}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="space-between">
            <Grid item xs={6} className={classes.formTitle}>
              <Typography variant="h6">Leave Requests</Typography>
            </Grid>

            <Grid container item xs={3}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Tooltip title="Clear Search Query">
                    <IconButton
                      aria-label="delete"
                      style={{ padding: '2px' }}
                      onClick={handleReset}
                    >
                      <SettingsBackupRestore />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <AddNewButton
                    title="Request a new leave"
                    type="button"
                    color="primary"
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={() => setOpenPopup(true)}
                  >
                    Request New Leave
                  </AddNewButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedNepaliDatePicker
                label="From"
                value={fromDate}
                setValue={setFromDate}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedNepaliDatePicker
                label="To"
                value={toDate}
                setValue={setToDate}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapper}>
              <FormButton
                startIcon={<Search />}
                className={classes.searchBoxButton1}
              >
                Search
              </FormButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        title="Request Leave"
        maxWidth="sm"
        colored
      >
        <StudentLeaveEditModal onClose={handleClose} />
      </Popups>
    </>
  );
};

export default StudentLeaveSearch;
