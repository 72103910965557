import { Grid, Paper, TableCell, Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeadCellsI } from '../../../../actions';
import { GetLeaveTypes } from '../../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import { GetStudentDashLeave } from '../../../../actions/StudentDash/StudentLeave/StudentDashLeaveAction';
import { StudentLeaveTypeI } from '../../../../actions/StudentDash/StudentLeave/StudentDashLeaveActionTypes';
import { PRIMARY_DATE_FORMAT } from '../../../../common/constant';
import GetStatus from '../../../../common/GetStatus';
import {
  ItemEditButton,
  ItemViewButton,
} from '../../../../components/Reusable/Buttons/TableButton';
import CustomizedDialogs from '../../../../components/Reusable/Dialogs/DeleteDialog';
import Popups from '../../../../components/Reusable/Dialogs/Popups';
import useTable from '../../../../components/Reusable/useStudentTable';
import StudentLeaveEditModal from './StudentLeaveEditModal';
import { useTableStyles } from '../../../../components/Styles/TableStyles';
import { dateConverterAdToBs } from '../../../../components/utils/dateConverter';
import { RootStore } from '../../../../store';
import useLoading from '../../../../useHook/useLoading';
import { tblStyle } from '../../StudentDashboardStyles';
import StudentLeaveView from './StudentLeaveView';

const headCells: HeadCellsI[] = [
  { id: 'leave_date', label: 'Leave Date', align: 'left' },
  { id: 'days', label: 'Days' },
  { id: 'applied_date', label: 'Applied Date' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' },
];

const StudentLeavesTable = () => {
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<StudentLeaveTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openFormPopup, setOpenFormPopup] = useState<boolean>(false);
  const [leaveData, setLeaveData] = useState([]);
  const [item, setItem] = useState<object | null>(null);
  const [status, setStatus] = useState<string>('');

  const leaveSelector = useSelector(
    (state: RootStore) => state.studentDash_leave
  );
  const { loading } = useLoading(leaveSelector.loading);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  useEffect(() => {
    dispatch(GetLeaveTypes());
    dispatch(GetStudentDashLeave());
  }, []);

  useEffect(() => {
    // const items = formatData(dummyData);
    const items = formatData(leaveSelector.studentDash_leave);
    setTableData(items);
  }, [leaveSelector]);

  const formatData = (items: any) => {
    return items.map((item: any) => ({
      id: item.id,
      leave_name: item.leave_name,
      leave_date: dateConverterAdToBs(item.leave_from),
      leave_to: dateConverterAdToBs(item.leave_to),
      days: dateConverterAdToBs(item.leave_to),
      applied_date: dateConverterAdToBs(item.applied_date),
      reason_for_leave: item.reason_for_leave,
      student_academic: item.student_academic,
      status: item.status,
    }));
  };

  const handleDeleteModal = (id: number) => {
    setItemId(id);
    setDeleteModalOpen(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    // confirmed && itemId != null && dispatch(DeleteStudentLeave(itemId));
    setDeleteModalOpen(false);
  };

  const handleViewModal = (item: any) => {
    setLeaveData(item);
    setItemId(parseInt(item.id));
    setOpenPopup(true);
  };

  const Edit = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleFormClose = (value: boolean) => {
    setOpenFormPopup(value);
  };

  const handleEditModal = (item: any) => {
    if (item.status == 'P') {
      setOpenFormPopup(true);
      setItem(item);
    }
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.leave_date} - {item.days}
          {/* {moment().format(PRIMARY_DATE_FORMAT)} -{' '}
          {moment(item.days).format(PRIMARY_DATE_FORMAT)} */}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.days).diff(item.leave_date, 'days') + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.applied_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <GetStatus status={item.status} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleViewModal(item)} />
          <ItemEditButton
            disabled={item.status !== 'P'}
            onClick={() => handleEditModal(item)}
          />
          {/* <ItemDeleteButton onClick={() => handleDeleteModal(item.id)} /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper
      className={clsx(classes.root, classes.attachFormTableRoot)}
      style={tblStyle}
    >
      <TableContainer
        label="Leave List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        rowsPerPage={4}
        getCustomTableRow={getCustomTableRow}
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />

      <Popups
        openPopup={openFormPopup}
        setOpenPopup={setOpenFormPopup}
        onClose={handleFormClose}
        title="Update Request Leave"
        maxWidth="sm"
      >
        <StudentLeaveEditModal onClose={handleFormClose} item={item} editable />
      </Popups>

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={Edit}
        maxWidth="sm"
        title="Leave Request"
        colored
      >
        <StudentLeaveView items={leaveData} />
      </Popups>
    </Paper>
  );
};

export default StudentLeavesTable;
