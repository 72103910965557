import {
  GET_PARENT_SYLLABUS_LOADING,
  GET_PARENT_SYLLABUS_ERROR,
  GET_PARENT_SYLLABUS_SUCCESS,
  ParentSyllabusDispatchTypes,
  ParentSyllabusI,
} from "../../actions/ParentsDash/ParentSyllabus/ParentSyllabusActionTypes";

interface InitialStateI {
  loading: boolean;
  syllabus: ParentSyllabusI[];
}

const initialState: InitialStateI = {
  loading: false,
  syllabus: [],
};

const ParentSyllabusReducer = (
  state: InitialStateI = initialState,
  action: ParentSyllabusDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_PARENT_SYLLABUS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_PARENT_SYLLABUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_PARENT_SYLLABUS_SUCCESS:
      return {
        ...state,
        loading: false,
        syllabus: action.payload,
      };

    default:
      return state;
  }
};

export default ParentSyllabusReducer;
