import {
  GET_TEACHER_ASSIGNMENT_LOADING,
  GET_TEACHER_ASSIGNMENT_ERROR,
  GET_TEACHER_ASSIGNMENT_SUCCESS,
  GET_SUBMITTED_ASSIGNMENT_LOADING,
  GET_SUBMITTED_ASSIGNMENT_ERROR,
  GET_SUBMITTED_ASSIGNMENT_SUCCESS,
  GET_UNSUBMITTED_ASSIGNMENT_ERROR,
  GET_UNSUBMITTED_ASSIGNMENT_LOADING,
  GET_UNSUBMITTED_ASSIGNMENT_SUCCESS,
  ADD_TEACHER_ASSIGNMENT_LOADING,
  ADD_TEACHER_ASSIGNMENT_ERROR,
  ADD_TEACHER_ASSIGNMENT_SUCCESS,
  UPDATE_TEACHER_ASSIGNMENT_LOADING,
  UPDATE_TEACHER_ASSIGNMENT_ERROR,
  UPDATE_TEACHER_ASSIGNMENT_SUCCESS,
  DELETE_TEACHER_ASSIGNMENT_LOADING,
  DELETE_TEACHER_ASSIGNMENT_ERROR,
  DELETE_TEACHER_ASSIGNMENT_SUCCESS,
  GET_TEACHER_ASSIGNMENT_BY_ID_LOADING,
  GET_TEACHER_ASSIGNMENT_BY_ID_ERROR,
  GET_TEACHER_ASSIGNMENT_BY_ID_SUCCESS,
  GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING,
  GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR,
  GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS,
  ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING,
  ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR,
  ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS,
  GET_ASSIGNMENT_FILES_ERROR,
  GET_ASSIGNMENT_FILES_LOADING,
  GET_ASSIGNMENT_FILES_SUCCESS,
  ADD_ASSIGNMENT_FILES_ERROR,
  ADD_ASSIGNMENT_FILES_LOADING,
  ADD_ASSIGNMENT_FILES_SUCCESS,
  DELETE_ASSIGNMENT_FILES_ERROR,
  DELETE_ASSIGNMENT_FILES_LOADING,
  DELETE_ASSIGNMENT_FILES_SUCCESS,
  GET_ASSIGNMENT_SUBJECT_ERROR,
  GET_ASSIGNMENT_SUBJECT_LOADING,
  GET_ASSIGNMENT_SUBJECT_SUCCESS,
  ASSIGN_MARKS_ASSIGNMENT_ERROR,
  ASSIGN_MARKS_ASSIGNMENT_LOADING,
  ASSIGN_MARKS_ASSIGNMENT_SUCCESS,
  TeacherAssignmentDispatchTypes,
} from "../../../actions/TeacherDash/TeacherAssignment/TeacherAssignmentActionTypes";

const initialState: any = {
  loading: false,
  assignments: [],
  submitted_assignment: null,
  unsubmitted_assignment: null,
  assignmentById: null,
  assignment_marks: null,
  assignment_subjects: [],
  errors: null,
  document: [],
  added: false,
};

const TeacherAssignmentReducer = (
  state: any = initialState,
  action: TeacherAssignmentDispatchTypes
): any => {
  switch (action.type) {
    case GET_TEACHER_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case GET_TEACHER_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case GET_TEACHER_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        assignments: action.payload,
        errors: null,
      };

    case ADD_TEACHER_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case ADD_TEACHER_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case ADD_TEACHER_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        assignments: [...state.assignments, action.payload],
        errors: null,
      };
    case DELETE_TEACHER_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case DELETE_TEACHER_ASSIGNMENT_SUCCESS:
      const new_data: any = state.assignments;
      const item_index = new_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        assignments: new_data,
        errors: null,
      };

    case DELETE_TEACHER_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case UPDATE_TEACHER_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case UPDATE_TEACHER_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case UPDATE_TEACHER_ASSIGNMENT_SUCCESS:
      const current_data: any = state.assignments;
      const index: number = current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      current_data[index] = action.payload;
      return {
        ...state,
        loading: false,

        assignments: current_data,
        errors: null,
      };

    case GET_TEACHER_ASSIGNMENT_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        assignmentById: null,
        errors: null,
      };

    case GET_TEACHER_ASSIGNMENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        assignmentById: action.payload,
        errors: null,
      };

    case GET_TEACHER_ASSIGNMENT_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case GET_ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        assignment_marks: action.payload,
        errors: null,
      };

    case ADD_TEACHER_ASSIGNMENT_ADD_MARKS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case ADD_TEACHER_ASSIGNMENT_ADD_MARKS_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };
    case ADD_TEACHER_ASSIGNMENT_ADD_MARKS_SUCCESS:
      const temp: any[] = state.assignment_marks;
      const onindex: number = temp.findIndex(
        (element) => element.id === action.payload.id
      );
      temp[onindex] = action.payload;

      return {
        ...state,
        loading: false,
        assignment_marks: temp,
        errors: null,
      };

    case GET_SUBMITTED_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        submitted_assignment: null,
        errors: null,
      };

    case GET_SUBMITTED_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case GET_SUBMITTED_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        submitted_assignment: action.payload,
        errors: null,
      };

    case GET_UNSUBMITTED_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        unsubmitted_assignment: null,
        errors: null,
      };

    case GET_UNSUBMITTED_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case GET_UNSUBMITTED_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        unsubmitted_assignment: action.payload,
        errors: null,
      };

    case GET_ASSIGNMENT_FILES_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      }
    case GET_ASSIGNMENT_FILES_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      }
    case GET_ASSIGNMENT_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        document: action.payload,
        errors: null,
        added: false,
      }

    case ADD_ASSIGNMENT_FILES_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        added: false,
      }
    case ADD_ASSIGNMENT_FILES_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        added: false,
      }
    case ADD_ASSIGNMENT_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        added: true,
      }

    case DELETE_ASSIGNMENT_FILES_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      }
    case DELETE_ASSIGNMENT_FILES_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      }
    case DELETE_ASSIGNMENT_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        added: true,
      }

    case GET_ASSIGNMENT_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      }
    case GET_ASSIGNMENT_SUBJECT_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        assignment_subjects: [],
      }
    case GET_ASSIGNMENT_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        assignment_subjects: action.payload,
        errors: null,
      }

    case ASSIGN_MARKS_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
        added: false,
      }
    case ASSIGN_MARKS_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        added: false,
      }
    case ASSIGN_MARKS_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        added: true,
      }

    default:
      return state;
  }
};

export default TeacherAssignmentReducer;
