export const GET_STUDENT_ACADEMIC_LOADING = "GET_STUDENT_ACADEMIC_LOADING";
export const GET_STUDENT_ACADEMIC_ERROR = "GET_STUDENT_ACADEMIC_ERROR";
export const GET_STUDENT_ACADEMIC_SUCCESS = "GET_STUDENT_ACADEMIC_SUCCESS";

export const GET_STUDENT_ACADEMIC_MARKS_LOADING =
  "GET_STUDENT_ACADEMIC_MARKS_LOADING";
export const GET_STUDENT_ACADEMIC_MARKS_ERROR =
  "GET_STUDENT_ACADEMIC_MARKS_ERROR";
export const GET_STUDENT_ACADEMIC_MARKS_SUCCESS =
  "GET_STUDENT_ACADEMIC_MARKS_SUCCESS";

export type StudentAcademicInfoTypeI = {
  id: number;
  student: number;
  student_first_name: string;
  student_last_name: StaticRange;
  grade: number;
  grade_name: number;
  section: number;
  section_name: string;
  roll_number?: number;
  house?: number;
  last_school_name?: string;
  last_percentage?: number;
  last_grade?: number;
  sports?: string;
  academic_session: number;
};

//===============================<START>GET CHILDREN ACADEMIC INFO<START>======================//

export interface GetStudentAcademicLoading {
  type: typeof GET_STUDENT_ACADEMIC_LOADING;
}

export interface GetStudentAcademicError {
  type: typeof GET_STUDENT_ACADEMIC_ERROR;
}

export interface GetStudentAcademicSuccess {
  type: typeof GET_STUDENT_ACADEMIC_SUCCESS;
  payload: StudentAcademicInfoTypeI[];
}

//===============================<START>GET CHILDREN ACADEMIC INFO<START>======================//

//===============================<START>GET ACADEMIC MARK<START>======================//

export interface GetStudentAcademicMarkLoading {
  type: typeof GET_STUDENT_ACADEMIC_MARKS_LOADING;
}

export interface GetStudentAcademicMarkError {
  type: typeof GET_STUDENT_ACADEMIC_MARKS_ERROR;
}

export interface GetStudentAcademicMarkSuccess {
  type: typeof GET_STUDENT_ACADEMIC_MARKS_SUCCESS;
  payload: StudentAcademicInfoTypeI[];
}

//===============================<START>GET ACADEMIC MARK<START>======================//

export type StudentAcademicInfoDispatchTypes =
  | GetStudentAcademicLoading
  | GetStudentAcademicError
  | GetStudentAcademicSuccess
  | GetStudentAcademicMarkLoading
  | GetStudentAcademicMarkError
  | GetStudentAcademicMarkSuccess;
