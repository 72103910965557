import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_CHILDREN_ATTENDANCE_LOADING,
  GET_CHILDREN_ATTENDANCE_ERROR,
  GET_CHILDREN_ATTENDANCE_SUCCESS,
  ChildrenAttendanceDispatchTypes,
  QueryI,
} from "./AttendanceReportActionTypes";

import { SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { PARENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetChildrenAttendanceReport =
  (query: QueryI) =>
  async (
    dispatch: Dispatch<ChildrenAttendanceDispatchTypes | SetSnackBarI>
  ) => {
    try {
      dispatch({
        type: GET_CHILDREN_ATTENDANCE_LOADING,
      });

      const res = await axios.get(
        `${PARENT_API_URL}/monthly_student_attendance?student_id=${query.student_id}&from_date=${query.from_date}&to_date=${query.to_date}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_CHILDREN_ATTENDANCE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CHILDREN_ATTENDANCE_ERROR,
      });
    }
  };
