import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { dateConverterAdToBs } from "../../../../../components/utils/dateConverter";
import parse from "html-react-parser";

function TeacherAnnouceModal(props: any) {
  const parseHtmlContent = (content: string) => {
    const doc1 = parse(content);
    return doc1;
  };

  return (
    <>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Title:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {props.item?.title}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Priority:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {props.item?.priority_display}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Notice Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {props.item?.announcement_date}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Published Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {props.item && dateConverterAdToBs(props.item.created_on)}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Description:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {props.item?.description &&
              parseHtmlContent(props.item.description)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default TeacherAnnouceModal;
