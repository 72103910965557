import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import FormButton from "../../../../../components/Reusable/Buttons/FormButton";
import CustomizedTextField from "../../../../../components/Reusable/Inputs/TextField";
import { UpdateMeeting } from "../../../../../actions/HumanResource/Meeting/MeetingAction";
import { UpdateLiveMeet } from "../../../../../actions/TeacherDash/TeacherLiveClass/TeacherLiveAction";
import { setSnackbar } from "../../../../../actions/SnackbarAction";

const LiveClassConfirm = (props: any) => {
  const { classTitle, itemId, handleConfirmClose, onConfirmedMeet } = props;
  const [title, setTitle] = useState(classTitle);
  const dispatch = useDispatch();

  const UpdateMeet = () => {
    if (itemId && title) {
      dispatch(UpdateLiveMeet(itemId, { title }));
      onConfirmedMeet(true);
    } else {
      dispatch(setSnackbar(true, "warning", "Title Fields cannot be empty"));
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CustomizedTextField
            label="Title"
            placeholder="Label"
            name="title"
            defaultValue={title}
            onChange={(event: any) => setTitle(event.target.value)}
          />
        </Grid>
        <Grid container justifyContent="flex-end">
          <FormButton startIcon={<Send />} onClick={() => UpdateMeet()}>
            Submit
          </FormButton>
        </Grid>
      </Grid>
    </>
  );
};

export default LiveClassConfirm;
