export const GET_TEACHER_EVENT_LOADING = "GET_TEACHER_EVENT_LOADING";
export const GET_TEACHER_EVENT_ERROR = "GET_TEACHER_EVENT_ERROR";
export const GET_TEACHER_EVENT_SUCCESS = "GET_TEACHER_EVENT_SUCCESS";

//===============================<START>GET STUDENT_HOUSE<START>======================//

export interface TeacherEventTypeI {
  id: number;
  event_type: number;
  event_type_name: string;
  title: string;
  location: string;
  from_date: string;
  time: string;
  is_holiday: boolean;
  academic_session: number;
  academic_session_name: string;
  description: string;
}

export interface GetTeacherEventLoading {
  type: typeof GET_TEACHER_EVENT_LOADING;
}

export interface GetTeacherEventError {
  type: typeof GET_TEACHER_EVENT_ERROR;
}

export interface GetTeacherEventSuccess {
  type: typeof GET_TEACHER_EVENT_SUCCESS;
  payload: TeacherEventTypeI[];
}
//===============================<END>GET STUDENT_HOUSE<END>======================//

//===============================<END>DELETE STUDENT_HOUSE<END>======================//

export type TeacherEventDispatchTypes =
  | GetTeacherEventLoading
  | GetTeacherEventError
  | GetTeacherEventSuccess;
