import {
  GET_PARENT_PROFILE_LOADING,
  GET_PARENT_PROFILE_SUCCESS,
  GET_PARENT_PROFILE_ERROR,
  ProfileDispatchTypes,
} from "../../actions/ParentsDash/ParentProfile/ParentProfileActionTypes";

interface InitialStateI {
  loading: boolean;
  guardian_profile_by_id?: any;
}

const initialState: InitialStateI = {
  loading: false,
  guardian_profile_by_id: null,
};

const ParentProfileReducer = (
  state: any = initialState,
  action: ProfileDispatchTypes
): any => {
  switch (action.type) {
    case GET_PARENT_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_PARENT_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_PARENT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        guardian_profile_by_id: action.payload,
      };

    default:
      return state;
  }
};

export default ParentProfileReducer;
