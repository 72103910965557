import React, { useState, useEffect } from 'react';
import { Paper, TableCell } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { HeadCellsI } from '../../../../../actions';
import { GetLeaveTypes } from '../../../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import {
  DeleteTeacherLeave,
  GetTeacherLeave,
} from '../../../../../actions/TeacherDash/TeacherLeave/TeacherLeaveAction';
import { PRIMARY_DATE_FORMAT } from '../../../../../common/constant';
import GetStatus from '../../../../../common/GetStatus';
import { RootStore } from '../../../../../store';
import {
  ItemEditButton,
  ItemViewButton,
} from '../../../../../components/Reusable/Buttons/TableButton';
import CustomizedDialogs from '../../../../../components/Reusable/Dialogs/DeleteDialog';
import Popups from '../../../../../components/Reusable/Dialogs/Popups';
import { useTableStyles } from '../../../../../components/Styles/TableStyles';
import { dateConverterAdToBs } from '../../../../../components/utils/dateConverter';
import getFullName from '../../../../../components/utils/getFullName';
import TeacherLeaveView from './TeacherLeaveView';
import useTable from '../../../../../components/Reusable/useStudentTable';
import { tblStyle } from '../../../components/Styles/TeacherDashboardStyles';
import TeacherLeaveAdd from './TeacherLeaveAdd';

const headCells: HeadCellsI[] = [
  { id: 'name', label: 'Teacher Name', filter: true },
  { id: 'leave_type', label: 'Leave Type', filter: true },
  { id: 'leave_date', label: 'Leave Date' },
  { id: 'days', label: 'Days' },
  { id: 'applied_date', label: 'Applied Date', filter: true },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' },
];

interface TableDataI {
  id: number;
  name: string;
  leave_type: string;
  leave_date: string;
  days: string;
  applied_date: string;
  description: string;
  status: string;
  photo: string;
}

const TeacherLeaveList = () => {
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const [openFormPopup, setOpenFormPopup] = useState<boolean>(false);
  const [item, setItem] = useState<object | null>(null);
  const [nonEditPopup, setNoneEditPopup] = useState<boolean>(false);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const teacherLeave = useSelector((state: RootStore) => state.teacher_leave);

  useEffect(() => {
    dispatch(GetLeaveTypes());
    dispatch(GetTeacherLeave());
  }, []);

  useEffect(() => {
    const dataleave = teacherLeave.teacher_leave;
    const data: any = [];
    dataleave.map((item: any) => {
      data.push({
        id: item.id || new Date().getTime().toString(),
        name: item.staff.full_name,
        leave_type: item.leave_type.name,
        leave_date: dateConverterAdToBs(item.from_date),
        days: dateConverterAdToBs(item.to_date),
        applied_date: dateConverterAdToBs(item.created_on),
        description: item.reason_for_leave,
        status: item.status,
        photo: item.photo || '',
        remarks: item.remarks || <em>No remarks yet</em>,
      });
    });
    setTableData(data);
    setLoading(teacherLeave.loading);
  }, [teacherLeave]);

  const handleViewModal = (item: any) => {
    setLeaveData(item);
    setItemId(parseInt(item.id));
    setOpenPopup(true);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteTeacherLeave(itemId));
    setDeleteModalOpen(false);
  };

  const handleEditModal = (item: any) => {
    if (item.status == 'P') {
      setOpenFormPopup(true);
      setItem(item);
    } else {
      setNoneEditPopup(true);
    }
  };

  const handleFormClose = (value: boolean) => {
    setOpenFormPopup(value);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.leave_type}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.leave_date).format(PRIMARY_DATE_FORMAT)} -{' '}
          {moment(item.days).format(PRIMARY_DATE_FORMAT)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.days).diff(item.leave_date, 'days')}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.applied_date).format(PRIMARY_DATE_FORMAT)}
        </StyledTableCell>
        <TableCell align="center" className={classes.cell}>
          <GetStatus status={item.status} />
        </TableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleViewModal(item)} />
          <ItemEditButton
            disabled={item.status !== 'P'}
            onClick={() => handleEditModal(item)}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper style={tblStyle}>
      <TableContainer
        label="Leave List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        rowsPerPage={4}
        getCustomTableRow={getCustomTableRow}
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        maxWidth="sm"
        title="Teacher Leave Request"
        colored
      >
        <TeacherLeaveView items={leaveData} />
      </Popups>

      <Popups
        openPopup={openFormPopup}
        setOpenPopup={setOpenFormPopup}
        onClose={handleFormClose}
        title="Update Request Leave"
        maxWidth="sm"
      >
        <TeacherLeaveAdd onClose={handleFormClose} item={item} editable />
      </Popups>
    </Paper>
  );
};

export default TeacherLeaveList;
