import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_ENOTES_CATEGORIES_LOADING,
  GET_STUDENT_ENOTES_CATEGORIES_ERROR,
  GET_STUDENT_ENOTES_CATEGORIES_SUCCESS,
  GET_STUDENT_ENOTES_LOADING,
  GET_STUDENT_ENOTES_ERROR,
  GET_STUDENT_ENOTES_SUCCESS,
  StudentEnotesDispatchTypes,
} from "./StudentEnoteActionTypes";

import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { STUDENT_API_URL } from "../../../config/SERVER_API_URLS";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";

export const GetStudentEnotesCategories = () => async (
  dispatch: Dispatch<StudentEnotesDispatchTypes>
) => {
  try {
    dispatch({
      type: GET_STUDENT_ENOTES_CATEGORIES_LOADING,
    });

    const res = await axios.get(
      `${STUDENT_API_URL}/enote_category/`,
      HeaderConfig()
    );

    dispatch({
      type: GET_STUDENT_ENOTES_CATEGORIES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_ENOTES_CATEGORIES_ERROR,
    });
  }
};

export const GetStudentEnotes = (subject: string, category: string) => async (
  dispatch: Dispatch<StudentEnotesDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_STUDENT_ENOTES_LOADING,
    });

    const res = await axios.get(
      `${STUDENT_API_URL}/student_enote/?subject=${subject}&category=${category}`,
      HeaderConfig()
    );

    dispatch({
      type: GET_STUDENT_ENOTES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_ENOTES_ERROR,
    });
  }
};
