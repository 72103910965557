import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import StudentTeacherCard from "../../../../components/Reusable/StudentTeacherCard"
import { GetMyTeacher } from '../../../../actions/StudentDash/StudentMyTeacher/StudentTeacherAction'
import { StudentMyTeacherTypeI } from '../../../../actions/StudentDash/StudentMyTeacher/StudentTeacherActionTypes'
import { RootStore } from '../../../../store'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      maxHeight: "70vh",
      marginTop: "1em",
    },
    cardContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gridTemplateRows: "auto",
      gap: "1.25em",
    },
  })
)

const StudentAssignmentPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(GetMyTeacher())
  }, [])
  const { my_teachers } = useSelector((state: RootStore) => state.student_myTeacher)

  return (
    <Box>
      <PageHeader title="Student Subjects" />
      <PageWrapper>
        <Paper className={classes.container} elevation={1}>
          <div className={classes.cardContainer}>
            {my_teachers.map((teacher: StudentMyTeacherTypeI) => teacher.staff !== null && <StudentTeacherCard key={teacher.id} data={teacher} />)}
          </div>
        </Paper>
      </PageWrapper>
    </Box>
  );
};

export default StudentAssignmentPage;
