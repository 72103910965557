import axios from "axios";
import { Dispatch } from "redux";
import {
    GET_APPLICABLE_RUBRIC_ERROR,
    GET_APPLICABLE_RUBRIC_LOADING,
    GET_APPLICABLE_RUBRIC_SUCCESS,
    GET_STUDENT_RUBRIC_ERROR,
    GET_STUDENT_RUBRIC_LOADING,
    GET_STUDENT_RUBRIC_SUCCESS,
    APPLY_STUDENT_RUBRIC_ERROR,
    APPLY_STUDENT_RUBRIC_LOADING,
    APPLY_STUDENT_RUBRIC_SUCCESS,
    ApplyRubricPayload,
    TeacherRubricDispatchTypes,
} from "./TeacherRubricActionTypes"
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetApplicableRubric = () => async (dispatch: Dispatch<TeacherRubricDispatchTypes | SetSnackBarI>) => {
    try {
        dispatch({
            type: GET_APPLICABLE_RUBRIC_LOADING,
        })
        const url = `${TEACHER_API_URL}/applicable-rubric-category/`
        const res = await axios.get(url, HeaderConfig())
        dispatch({
            type: GET_APPLICABLE_RUBRIC_SUCCESS,
            payload: res.data,
        })
    } catch (error: any) {
        dispatch({
            type: GET_APPLICABLE_RUBRIC_ERROR,
        });
    }
}

export const GetStudentRubric = (grade: string, section: string | null, rubric: string) => async (dispatch: Dispatch<TeacherRubricDispatchTypes | SetSnackBarI>) => {
    try {
        dispatch({
            type: GET_STUDENT_RUBRIC_LOADING,
        })
        let url = `${TEACHER_API_URL}/add-student-rubric-grade?grade=${grade}&rubric=${rubric}`
        if (section) {
            url = `${TEACHER_API_URL}/add-student-rubric-grade?grade=${grade}&section=${section}&rubric=${rubric}`
        }
        const res = await axios.get(url, HeaderConfig())
        dispatch({
            type: GET_STUDENT_RUBRIC_SUCCESS,
            payload: res.data,
        })
    } catch (error: any) {
        dispatch({
            type: GET_STUDENT_RUBRIC_ERROR,
        });
    }
}

export const ApplyStudentRubric = (data: ApplyRubricPayload) => async (dispatch: Dispatch<TeacherRubricDispatchTypes | SetSnackBarI>) => {
    try {
        dispatch({
            type: APPLY_STUDENT_RUBRIC_LOADING
        })
        const url = `${TEACHER_API_URL}/add-student-rubric-grade`
        const res = await axios.post(url, data, HeaderConfig())
        dispatch({
            type: APPLY_STUDENT_RUBRIC_SUCCESS,
            payload: res.data,
        })
        dispatch({
            type: SET_SNACKBAR,
            payload: {
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: 'Rubric grade assigned successfully.',
            },
        });
    } catch (error: any) {
        dispatch({
            type: APPLY_STUDENT_RUBRIC_ERROR,
            payload: error.response?.data,
        })
        dispatch({
            type: SET_SNACKBAR,
            payload: {
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: 'Something went wrong!',
            },
        });
    }
}