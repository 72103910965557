import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TextField,
} from "@material-ui/core";
import {
  Add,
  ArrowBackIos,
  ArrowForwardIos,
  Delete,
  Save,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { Link } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { RootStore } from "../../../store";
import { useTable } from "../../Reusable";
import { HeadCellsI } from "../../../actions";
import { ItemDeleteButton } from "../../Reusable/Buttons/TableButton";
import { setSnackbar } from "../../../actions/SnackbarAction";
import BackDropLoader from "../../Reusable/BackDropLoader";
import {
  addPeriodAction,
  updatePeriodAction,
  deletePeriodAction,
  removeAllPeriodsAction,
} from "../../../rtk/features/timetable/periodSlices";
import { addTimePeriods } from "../../../rtk/features/timetable/periodApi";

const headCells: HeadCellsI[] = [
  { id: "period", label: "Period" },
  { id: "start_time", label: "Start Time" },
  { id: "end_time", label: "End Time" },
  { id: "action", label: "Action" },
];

const periodChoices = [
  { id: "1", name: "Period 1" },
  { id: "2", name: "Period 2" },
  { id: "3", name: "Period 3" },
  { id: "4", name: "Period 4" },
  { id: "5", name: "Period 5" },
  { id: "6", name: "Period 6" },
  { id: "7", name: "Period 7" },
  { id: "8", name: "Period 8" },
  { id: "break1", name: "Break 1" },
  { id: "break2", name: "Break 2" },
  { id: "break3", name: "Break 3" },
  { id: "extra1", name: "Extra Class 1" },
  { id: "extra2", name: "Extra Class 2" },
];

const PreTimeTableForm = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  //Retrieving re-usable components from useTable
  const { TblHead, StyledTableCell, StyledTableRow } = useTable(headCells);

  const [classList, setClassList] = useState<{ id: string; name: string }[]>(
    []
  );

  // .......................................................................
  const [tableEdit, setTableEdit] = useState<boolean>(false);
  const [checkedClasses, setCheckedClasses] = useState<
    { id: string; name: string }[]
  >([]);

  const { periods, actionPerformed, classPeriods, loading } = useSelector(
    (state: RootStore) => state.period
  );

  // .......................................................................

  const classState = useSelector((state: RootStore) => state.class);

  // HOOK MANAGEMENT //////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(GetClasses());
  }, [dispatch]);

  useEffect(() => {
    if (actionPerformed) {
      setCheckedClasses([]);
      setTableEdit(false);
    }
  }, [dispatch, actionPerformed]);

  useEffect(() => {
    const existingClasses = classPeriods.map((item) => item.grade.id);
    const currentClasses = classState.classes.map((item) => ({
      id: item.grade,
      name: item.grade_name,
    }));
    setClassList(
      currentClasses.filter((item) => !existingClasses.includes(item.id))
    );
  }, [dispatch, classPeriods, classState]);

  /////////////////////////////////////////////////////////////////////////////////////////////

  // Event handlers
  const handleClassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedClasses([
        ...checkedClasses,
        { id: event.target.value, name: event.target.name },
      ]);
    } else {
      const newCheckedClasses = checkedClasses.filter(
        (item) => item.id !== event.target.value
      );
      setCheckedClasses([...newCheckedClasses]);
    }
  };

  const handleAddClick = () => {
    setTableEdit(true);
    dispatch(addPeriodAction());
  };

  const handleInputChange = (
    id: string,
    field: "name" | "start_time" | "end_time",
    value: string
  ) => {
    dispatch(updatePeriodAction({ id, field, value }));
  };

  const handleDeleteClick = (id: string) => {
    dispatch(deletePeriodAction(id));
  };

  const handleDiscard = () => {
    setCheckedClasses([]);
    setTableEdit(false);
    dispatch(removeAllPeriodsAction());
  };

  const handleSave = () => {
    let emptyValid = true;
    let timeValid = true;
    let startTimeValid = true;

    periods.forEach((item) => {
      if (item.name === "") {
        emptyValid = false;
        return;
      }
      if (item.start_time === "") {
        emptyValid = false;
        return;
      }
      if (item.end_time === "") {
        emptyValid = false;
        return;
      }
      if (item.start_time >= item.end_time) {
        startTimeValid = false;
        return;
      }
      const newPeriods = periods.filter((e) => e.id !== item.id);
      newPeriods.forEach((i) => {
        if (item.start_time >= i.start_time && item.start_time < i.end_time) {
          timeValid = false;
          return;
        }
      });
    });
    if (emptyValid && timeValid && startTimeValid) {
      dispatch(
        addTimePeriods({
          grades: checkedClasses.map((item) => item.id),
          periods: periods.map((item) => ({
            name: item.name,
            start_time: item.start_time,
            end_time: item.end_time,
          })),
        })
      );
    } else {
      if (!emptyValid) {
        dispatch(setSnackbar(true, "warning", "Please fill all the fields"));
      }
      if (!timeValid) {
        dispatch(setSnackbar(true, "warning", "Period times are overlapping"));
      }
      if (!startTimeValid) {
        dispatch(
          setSnackbar(
            true,
            "warning",
            "End time must be greater than start time"
          )
        );
      }
    }
  };
  //////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Paper className={classes.pageContentBox}>
        <Grid container spacing={1} justifyContent="space-between">
          <Button
            variant="outlined"
            color="primary"
            to="/time-table"
            component={Link}
          >
            <ArrowBackIos fontSize="small" /> Back
          </Button>
          <Button
            variant="outlined"
            color="primary"
            to="/create-timetable"
            component={Link}
          >
            Create TimeTable <ArrowForwardIos fontSize="small" />
          </Button>
        </Grid>
        <form className={classes.form} style={{ marginTop: "20px" }}>
          <Grid container justifyContent="flex-end">
            <Button
              onClick={handleAddClick}
              color="primary"
              variant="contained"
              disabled={!Boolean(checkedClasses.length)}
              startIcon={<Add />}
            >
              Add Period
            </Button>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <i style={{ fontSize: "12px" }}>
                Selected: {checkedClasses.length}
              </i>

              <FormControl
                component="fieldset"
                className={classes.sectionCheckBox}
              >
                <FormGroup>
                  {classList.map((element: any) => (
                    <FormControlLabel
                      key={element.id}
                      control={
                        <Checkbox
                          color="primary"
                          value={element.id}
                          onChange={handleClassChange}
                          name={element.name}
                          checked={checkedClasses
                            .map((item) => item.id)
                            .includes(element.id)}
                          disabled={tableEdit}
                        />
                      }
                      label={`Class ${element.name}`}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={9}>
              <Table style={{ marginTop: "10px" }}>
                <TblHead align="center" />
                <TableBody>
                  {periods.map((item, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <Autocomplete
                          value={
                            periodChoices.find(
                              (element) => element.name === item.name
                            ) || null
                          }
                          onChange={(
                            event: React.ChangeEvent<{}>,
                            value: { id: string; name: string } | null
                          ) =>
                            handleInputChange(
                              item.id,
                              "name",
                              value?.name || ""
                            )
                          }
                          options={periodChoices.filter(
                            (pd) =>
                              !periods.map((p) => p.name).includes(pd.name)
                          )}
                          filterSelectedOptions
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Label"
                              name="period"
                              variant="outlined"
                            />
                          )}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          fullWidth={true}
                          type="time"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChange(
                              item.id,
                              "start_time",
                              event.target.value
                            )
                          }
                          value={item.start_time}
                          name="start_time"
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          fullWidth={true}
                          type="time"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleInputChange(
                              item.id,
                              "end_time",
                              event.target.value
                            )
                          }
                          value={item.end_time}
                          name="end_time"
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <ItemDeleteButton
                          onClick={() => handleDeleteClick(item.id)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>

              <Grid
                container
                justifyContent="flex-end"
                style={{ marginTop: "10px" }}
              >
                <Button
                  onClick={handleDiscard}
                  color="primary"
                  variant="contained"
                  startIcon={<Delete />}
                >
                  Discard
                </Button>

                <Button
                  onClick={handleSave}
                  disabled={!Boolean(periods.length)}
                  color="secondary"
                  variant="contained"
                  startIcon={<Save />}
                  style={{ marginLeft: "10px" }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <BackDropLoader open={loading} />
    </>
  );
};

export default PreTimeTableForm;
