import { StudentEnotesI } from "../../StudentDash/StudentEnote/StudentEnoteActionTypes";

export const GET_CHILDREN_ENOTES_LOADING = "GET_CHILDREN_ENOTES_LOADING";
export const GET_CHILDREN_ENOTES_ERROR = "GET_CHILDREN_ENOTES_ERROR";
export const GET_CHILDREN_ENOTES_SUCCESS = "GET_CHILDREN_ENOTES_SUCCESS";

//===============================<START>GET Student Enotes <START>======================//

export interface GetChildrenEnotesLoading {
  type: typeof GET_CHILDREN_ENOTES_LOADING;
}

export interface GetChildrenEnotesError {
  type: typeof GET_CHILDREN_ENOTES_ERROR;
}

export interface GetChildrenEnotesSuccess {
  type: typeof GET_CHILDREN_ENOTES_SUCCESS;
  payload: StudentEnotesI[];
}

//===============================<END>GET Children Enotes <END>======================//

export type ChildrenEnotesDispatchTypes =
  | GetChildrenEnotesLoading
  | GetChildrenEnotesError
  | GetChildrenEnotesSuccess;
