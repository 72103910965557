export const GET_MY_SUBJECT_LOADING = "GET_MY_SUBJECT_LOADING";
export const GET_MY_SUBJECT_ERROR = "GET_MY_SUBJECT_ERROR";
export const GET_MY_SUBJECT_SUCCESS = "GET_MY_SUBJECT_SUCCESS";

export interface MySubjectTypeI {
  grade_name: string;
  section_name: string;
  subject: number;
  subject_name: string;
  subject_type: string;
  subject_credit_hour: string;
}

//===============================<START>GET MY_SUBJECT<START>======================//

export interface GetMySubjectLoading {
  type: typeof GET_MY_SUBJECT_LOADING;
}

export interface GetMySubjectError {
  type: typeof GET_MY_SUBJECT_ERROR;
}

export interface GetMySubjectSuccess {
  type: typeof GET_MY_SUBJECT_SUCCESS;
  payload: MySubjectTypeI[];
}

//===============================<END>GET MY_SUBJECT<END>======================//

export type MySubjectDispatchTypes =
  | GetMySubjectLoading
  | GetMySubjectError
  | GetMySubjectSuccess;
