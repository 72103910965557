export const GET_TEACHER_DASHBOARD_LOADING = "GET_TEACHER_DASHBOARD_LOADING";
export const GET_TEACHER_DASHBOARD_ERROR = "GET_TEACHER_DASHBOARD_ERROR";
export const GET_TEACHER_DASHBOARD_SUCCESS = "GET_TEACHER_DASHBOARD_SUCCESS";

export const GET_TEACHER_DASHBOARD_ONLINECLASS_LOADING =
  "GET_TEACHER_DASHBOARD_ONLINECLASS_LOADING";
export const GET_TEACHER_DASHBOARD_ONLINECLASS_ERROR =
  "GET_TEACHER_DASHBOARD_ONLINECLASS_ERROR";
export const GET_TEACHER_DASHBOARD_ONLINECLASS_SUCCESS =
  "GET_TEACHER_DASHBOARD_ONLINECLASS_SUCCESS";

export const GET_TEACHER_DASHBOARD_EVENTS_LOADING =
  "GET_TEACHER_DASHBOARD_EVENTS_LOADING";
export const GET_TEACHER_DASHBOARD_EVENTS_ERROR =
  "GET_TEACHER_DASHBOARD_EVENTS_ERROR";
export const GET_TEACHER_DASHBOARD_EVENTS_SUCCESS =
  "GET_TEACHER_DASHBOARD_EVENTS_SUCCESS";

export interface DashboardOnlineClassI {
  end_time: string;
  id: string;
  class_date: string;
  days: string[];
  is_completed: boolean;
  is_ongoing: boolean;
  is_upcoming: boolean;
  start_time: string;
  subject_name: string;
  teacher_first_name: string;
  teacher_last_name: string;
  title: string;
}

export interface DashboardSubmitAssignmentI {
  id: string;
  deadline: string;
  grade: string;
  section: string;
  student_first_name: string;
  student_last_name: string;
  title: string;
  subject: string;
}

export interface DashboardLeaveRequestI {
  id: string;
  leave_from: string;
  leave_to: string;
  status: string;
  reason_for_leave: string;
}

export interface DashboardSyllabusI {
  id: string;
  name: string;
}

//===============================<START>GET Class_ATTENDANCE<START>======================//

export interface TeacherDashboardLoading {
  type: typeof GET_TEACHER_DASHBOARD_LOADING;
}

export interface TeacherDashboardError {
  type: typeof GET_TEACHER_DASHBOARD_ERROR;
}

export interface TeacherDashboardSuccess {
  type: typeof GET_TEACHER_DASHBOARD_SUCCESS;
  payload: any;
}

//===============================<END>DELETE Class_ATTENDANCE<END>======================//

export interface TeacherDashboardEventsLoading {
  type: typeof GET_TEACHER_DASHBOARD_EVENTS_LOADING;
}

export interface TeacherDashboardEventsError {
  type: typeof GET_TEACHER_DASHBOARD_EVENTS_ERROR;
}

export interface TeacherDashboardEventsSuccess {
  type: typeof GET_TEACHER_DASHBOARD_EVENTS_SUCCESS;
  payload: any;
}

//===============================<START>GET Class_ATTENDANCE<START>======================//

export interface TeacherDashboardOnlineClassLoading {
  type: typeof GET_TEACHER_DASHBOARD_ONLINECLASS_LOADING;
}

export interface TeacherDashboardOnlineClassError {
  type: typeof GET_TEACHER_DASHBOARD_ONLINECLASS_ERROR;
}

export interface TeacherDashboardOnlineClassSuccess {
  type: typeof GET_TEACHER_DASHBOARD_ONLINECLASS_SUCCESS;
  payload: any;
}

//===============================<END>DELETE Class_ATTENDANCE<END>======================//

export type TeacherDashboardDispatchTypes =
  | TeacherDashboardLoading
  | TeacherDashboardError
  | TeacherDashboardSuccess
  | TeacherDashboardEventsLoading
  | TeacherDashboardEventsError
  | TeacherDashboardEventsSuccess
  | TeacherDashboardOnlineClassLoading
  | TeacherDashboardOnlineClassError
  | TeacherDashboardOnlineClassSuccess;
