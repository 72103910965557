import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableHead,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { HeadCellsI } from "../../../actions";
import { timeConvertor } from "../../../dashboard/StudentDashboard/pages/MyTimetable/StudentTimeTableView";
import { useTable } from "../../Reusable";
import {
  ItemSaveButton,
  ItemEditButton,
  ItemCancelButton,
  ItemDeleteButton,
} from "../../Reusable/Buttons/TableButton";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { setSnackbar } from "../../../actions/SnackbarAction";
import {
  AddPreTimeTable,
  DeletePreTimeTable,
  GetAllPreTimeTables,
  GetPreTimeTables,
  UpdatePreTimeTable,
} from "../../../actions/Academics/PreTimeTable/PreTimeTableAction";
import { Add, Cancel, Delete, Save } from "@material-ui/icons";
import { useFormStyles } from "../../Styles/FormStyles";
import MultiSelectCustom, {
  MultiRowSelect,
} from "../../Reusable/Inputs/MultiSelectCustom";
import {
  copyTimePeriods,
  getTimePeriodByGrade,
  updateTimePeriods,
} from "../../../rtk/features/timetable/periodApi";
import {
  addPeriodToClassAction,
  deletePeriodFromClassAction,
  updatePeriodOnClassAction,
} from "../../../rtk/features/timetable/periodSlices";
import BackDropLoader from "../../Reusable/BackDropLoader";

interface GradeChoiceI {
  id: number;
  label: string;
  value: string;
}

const headCells: HeadCellsI[] = [
  { id: "sn", label: "SN" },
  { id: "period", label: "Period" },
  { id: "start_time", label: "Start Time" },
  { id: "end_time", label: "End Time" },
  { id: "action", label: "Action" },
];

const periodChoices = [
  { id: "1", name: "Period 1" },
  { id: "2", name: "Period 2" },
  { id: "3", name: "Period 3" },
  { id: "4", name: "Period 4" },
  { id: "5", name: "Period 5" },
  { id: "6", name: "Period 6" },
  { id: "7", name: "Period 7" },
  { id: "8", name: "Period 8" },
  { id: "break1", name: "Break 1" },
  { id: "break2", name: "Break 2" },
  { id: "break3", name: "Break 3" },
  { id: "extra1", name: "Extra Class 1" },
  { id: "extra2", name: "Extra Class 2" },
];

const gradeState: GradeChoiceI[] = [
  { id: 0, value: "Play Group", label: "play-group" },
  { id: 1, value: "Nursery", label: "nursery" },
  { id: 2, value: "LKG", label: "lkg" },
  { id: 3, value: "UKG", label: "ukg" },
  { id: 4, value: "One", label: "1" },
  { id: 5, value: "Two", label: "2" },
  { id: 6, value: "Three", label: "3" },
  { id: 7, value: "Four", label: "4" },
  { id: 8, value: "Five", label: "5" },
  { id: 9, value: "Six", label: "6" },
  { id: 10, value: "Seven", label: "7" },
  { id: 11, value: "Eight", label: "8" },
  { id: 12, value: "Nine", label: "9" },
  { id: 13, value: "Ten", label: "10" },
];

interface PropsI {
  classEditID: string;
}

const EditPreTimeTableForm = (props: PropsI) => {
  const { classEditID } = props;
  const dispatch = useDispatch();
  const classes = useFormStyles();

  const [tableData, setTableData] = useState<any>([]);
  const [classChoices, setClassChoices] = useState<any>([]);

  //Retrieving re-usable components from useTable

  const { TblHead, StyledTableCell, StyledTableRow } = useTable(headCells);

  const preTimeTableState = useSelector(
    (state: RootStore) => state.pre_timeTable.preTimeTables
  );

  //   ///////////////////////////////////////////////////////////////////////////////
  const { periodsByClass, actionPerformed, loading } = useSelector(
    (state: RootStore) => state.period
  );
  const classState = useSelector((state: RootStore) => state.class);

  const [classList, setClassList] = useState<{ id: string; name: string }[]>(
    []
  );

  const [selectedClassList, setSelectedClassList] = useState<
    { id: string; name: string }[]
  >([]);

  const [tableEdit, setTableEdit] = useState<boolean>(false);
  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getTimePeriodByGrade(classEditID));
  }, [dispatch, classEditID]);

  useEffect(() => {
    if (actionPerformed) {
      setTableEdit(false);
      setCheckedRows([]);
      setSelectedClassList([]);
      dispatch(getTimePeriodByGrade(classEditID));
    }
  }, [actionPerformed]);

  useEffect(() => {
    if (periodsByClass) {
      periodsByClass.periods.length === checkedRows.length
        ? setAllChecked(true)
        : setAllChecked(false);
    } else {
      setAllChecked(false);
    }
  }, [checkedRows, periodsByClass]);

  useEffect(() => {
    const allClasses = classState.classes;
    setClassList(
      allClasses.map((item) => ({ id: item.grade, name: item.grade_name }))
    );
  }, [dispatch, classState]);

  const handleClassChange = (value: { id: string; name: string }[]) => {
    setSelectedClassList(value);
  };

  const handleRowCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedRows([...checkedRows, event.target.value]);
    } else {
      const newCheckedRows = checkedRows.filter(
        (item) => item !== event.target.value
      );
      setCheckedRows([...newCheckedRows]);
    }
  };

  const handleRowCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allRows = periodsByClass?.periods.map((item) => item.id);
      allRows && setCheckedRows([...allRows]);
    } else {
      setCheckedRows([]);
    }
  };

  const handleDiscard = () => {
    setTableEdit(false);
    dispatch(getTimePeriodByGrade(classEditID));
  };

  const handleTableEdit = () => {
    if (tableEdit) {
      let emptyValid = true;
      let timeValid = true;
      let startTimeValid = true;

      if (periodsByClass) {
        periodsByClass.periods.forEach((item) => {
          if (item.name === "") {
            emptyValid = false;
            return;
          }
          if (item.start_time === "") {
            emptyValid = false;
            return;
          }
          if (item.end_time === "") {
            emptyValid = false;
            return;
          }
          if (item.start_time >= item.end_time) {
            startTimeValid = false;
            return;
          }
          const newPeriods = periodsByClass.periods.filter(
            (e) => e.id !== item.id
          );
          newPeriods.forEach((i) => {
            if (
              item.start_time >= i.start_time &&
              item.start_time < i.end_time
            ) {
              timeValid = false;
              return;
            }
          });
        });
      }
      if (emptyValid && timeValid && startTimeValid) {
        if (periodsByClass) {
          const post_data: any = [];
          periodsByClass.periods.forEach((item) => {
            if (item.from_db) {
              post_data.push({
                id: item.id,
                name: item.name,
                start_time: item.start_time,
                end_time: item.end_time,
              });
            } else {
              post_data.push({
                name: item.name,
                start_time: item.start_time,
                end_time: item.end_time,
              });
            }
          });
          dispatch(
            updateTimePeriods({
              grade: periodsByClass.grade.id,
              periods: post_data,
            })
          );
        }
      } else {
        if (!emptyValid) {
          dispatch(setSnackbar(true, "warning", "Please fill all the fields"));
        }
        if (!timeValid) {
          dispatch(
            setSnackbar(true, "warning", "Period times are overlapping")
          );
        }
        if (!startTimeValid) {
          dispatch(
            setSnackbar(
              true,
              "warning",
              "End time must be greater than start time"
            )
          );
        }
      }
    } else {
      setTableEdit(true);
      setCheckedRows([]);
      setSelectedClassList([]);
    }
  };

  const handleAddClick = () => {
    dispatch(addPeriodToClassAction());
  };

  const handleDeleteRow = (id: string) => {
    dispatch(deletePeriodFromClassAction(id));
  };

  const handleApplyChanges = () => {
    if (checkedRows.length && selectedClassList.length) {
      dispatch(
        copyTimePeriods({
          from_grade: classEditID,
          to_grades: selectedClassList.map((item) => item.id),
          periods: checkedRows,
        })
      );
    }
  };

  const handleFieldChange = (
    id: string,
    field: "name" | "start_time" | "end_time",
    value: string
  ) => {
    dispatch(
      updatePeriodOnClassAction({
        id,
        field,
        value,
      })
    );
  };
  // ////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(GetPreTimeTables(classEditID));
  }, []);

  useEffect(() => {
    const temp: any = [];
    const classes: string[] = [];
    preTimeTableState.map((elem: any) => {
      if (props) {
        temp.push({
          period: elem.period,
          start_time: elem.start_time,
          end_time: elem.end_time,
          id: elem.id,
          edit: false,
          classes: elem.classes,
        });
      }
    });

    setTableData(temp);
  }, [preTimeTableState]);

  useEffect(() => {
    const classCheckBoxes: any = [];
    const classes = classState.classes;

    gradeState.map((element: any) => {
      for (let i = 0; i < gradeState.length; i++) {
        if (element.value === classes[i]?.grade_name) {
          classCheckBoxes.push({ ...classes[i], checked: false });
        }
      }
    });
    setClassChoices(classCheckBoxes);
  }, [classState]);

  return (
    <div style={{ marginBottom: "3rem" }}>
      <form className={classes.form} style={{ marginTop: "20px" }}>
        <Grid
          spacing={2}
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: "2rem" }}
        >
          <Grid item xs={2}>
            Copy Periods To:
          </Grid>
          <Grid item xs={7}>
            <Autocomplete
              multiple
              disabled={tableEdit}
              id="tags-outlined"
              options={classList.filter((item) => item.id !== classEditID)}
              getOptionLabel={(option) => option.name}
              onChange={(event: React.ChangeEvent<{}>, value) =>
                handleClassChange(value)
              }
              value={selectedClassList}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Choose Class"
                  placeholder="Favorites"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={
                !Boolean(selectedClassList.length && checkedRows.length)
              }
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => handleApplyChanges()}
            >
              Apply
            </Button>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end">
          <Button
            onClick={handleAddClick}
            color="primary"
            variant="contained"
            disabled={!tableEdit}
            startIcon={<Add />}
          >
            Add Period
          </Button>
        </Grid>
        <Table style={{ marginTop: "10px" }}>
          <TableHead>
            <StyledTableRow>
              {!tableEdit && (
                <StyledTableCell align="center">
                  <Checkbox
                    color="default"
                    onChange={handleRowCheckAll}
                    checked={allChecked}
                  />
                </StyledTableCell>
              )}
              <StyledTableCell align="center">S.N</StyledTableCell>
              <StyledTableCell align="center">Period</StyledTableCell>
              <StyledTableCell align="center">Start Time</StyledTableCell>
              <StyledTableCell align="center">End Time</StyledTableCell>
              {tableEdit && (
                <StyledTableCell align="center">Action</StyledTableCell>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {periodsByClass?.periods.map((item, index) => (
              <StyledTableRow key={item.id}>
                {!tableEdit && (
                  <StyledTableCell align="center">
                    <Checkbox
                      color="default"
                      checked={checkedRows.includes(item.id)}
                      value={item.id}
                      onChange={handleRowCheck}
                    />
                  </StyledTableCell>
                )}
                <StyledTableCell align="center">{index + 1}</StyledTableCell>

                <StyledTableCell align="center">
                  {tableEdit ? (
                    <Autocomplete
                      value={
                        periodChoices.find(
                          (element) => element.name === item.name
                        ) || null
                      }
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        value: { id: string; name: string } | null
                      ) =>
                        handleFieldChange(item.id, "name", value?.name || "")
                      }
                      options={periodChoices.filter(
                        (pd) =>
                          !periodsByClass.periods
                            .map((p) => p.name)
                            .includes(pd.name)
                      )}
                      filterSelectedOptions
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Label"
                          name="period"
                          variant="outlined"
                        />
                      )}
                    />
                  ) : (
                    item.name
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {tableEdit ? (
                    <TextField
                      fullWidth={true}
                      type="time"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleFieldChange(
                          item.id,
                          "start_time",
                          event.target.value
                        )
                      }
                      value={item.start_time}
                      name="start_time"
                      variant="outlined"
                    />
                  ) : (
                    timeConvertor(item.start_time)
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {tableEdit ? (
                    <TextField
                      fullWidth={true}
                      type="time"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleFieldChange(
                          item.id,
                          "end_time",
                          event.target.value
                        )
                      }
                      value={item.end_time}
                      name="end_time"
                      variant="outlined"
                    />
                  ) : (
                    timeConvertor(item.end_time)
                  )}
                </StyledTableCell>
                {tableEdit && (
                  <StyledTableCell align="center">
                    <ItemDeleteButton
                      onClick={() => handleDeleteRow(item.id)}
                    />
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container justifyContent="flex-end" style={{ marginTop: "10px" }}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Cancel />}
            disabled={!tableEdit}
            onClick={handleDiscard}
          >
            Discard
          </Button>

          <Button
            color="secondary"
            variant="contained"
            startIcon={<Save />}
            onClick={handleTableEdit}
            style={{ marginLeft: "10px" }}
          >
            {tableEdit ? "Save" : "Edit"}
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default EditPreTimeTableForm;
