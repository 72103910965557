import { Box, Chip, Divider, Grid, Paper, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TeacherAnnouncementTypeI } from "../../../../../actions/TeacherDash/TeacherAnnouncements/TeacherAnnouncementActionTypes";
import { RootStore } from "../../../../../store";
import { useFormStyles } from "../../../../../components/Styles/FormStyles";
import { tableStyle } from "../../../components/Styles/TeacherDashboardStyles";
import parse from "html-react-parser";

interface WrapperI {
  title: string;
  data: TeacherAnnouncementTypeI[];
  occurrence: string;
  selected?: boolean;
}

export const getDateOccurrence = (data: string): string => {
  const dateFormatted = data?.replaceAll("-", "/");
  const dateArg = Date.parse(dateFormatted);

  const dateToday = new Date();
  const dateTodayString = dateToday.toLocaleDateString();
  const dateTodayNum = Date.parse(dateTodayString);

  const dateTomorrow = new Date();
  dateTomorrow.setDate(dateTomorrow.getDate() + 1);
  const dateTomorrowString = dateTomorrow.toLocaleDateString();
  const dateTomorrowNum = Date.parse(dateTomorrowString);

  if (dateArg == dateTodayNum) {
    return "today";
  } else if (dateArg == dateTomorrowNum) {
    return "tomorrow";
  } else if (dateArg > dateTomorrowNum) {
    return "upcoming";
  } else {
    return "past";
  }
};

function TeacherAnnounce() {
  const classes = useFormStyles();

  //STATES
  const [announcementToday, setAnnouncementToday] = useState<
    TeacherAnnouncementTypeI[]
  >([]);
  const [announcementTomorrow, setAnnouncementTomorrow] = useState<
    TeacherAnnouncementTypeI[]
  >([]);
  const [announcementUpcoming, setAnnouncementUpcoming] = useState<
    TeacherAnnouncementTypeI[]
  >([]);

  const [dataSliced, setDataSliced] = useState({
    today: false,
    tomorrow: false,
    upcoming: false,
  });
  /////////////////////////////////////////////////////////////////

  // REACT HOOKS
  const announcementSelector = useSelector(
    (state: RootStore) => state.teacher_announcement
  );

  useEffect(() => {
    const data = announcementSelector.teacher_announcements;

    const todays: TeacherAnnouncementTypeI[] = [];
    const tomorrows: TeacherAnnouncementTypeI[] = [];
    const upcomings: TeacherAnnouncementTypeI[] = [];

    if (data.length) {
      data.map((element) => {
        switch (getDateOccurrence(element.announcement_date)) {
          case "today":
            todays.push(element);
            break;

          case "tomorrow":
            tomorrows.push(element);
            break;

          case "upcoming":
            upcomings.push(element);
            break;

          default:
            break;
        }
      });
    }

    setAnnouncementToday(todays);
    setAnnouncementTomorrow(tomorrows);
    setAnnouncementUpcoming(upcomings);
  }, [announcementSelector]);
  ////////////////////////////////////////////////////////////

  //EVENT HANDLER///////////////////////////////////////////

  const changeViewState = (state: string) => {
    switch (state) {
      case "today":
        setDataSliced({
          ...dataSliced,
          today: !dataSliced.today,
        });
        break;

      case "tomorrow":
        setDataSliced({
          ...dataSliced,
          tomorrow: !dataSliced.tomorrow,
        });
        break;

      case "upcoming days":
        setDataSliced({
          ...dataSliced,
          upcoming: !dataSliced.upcoming,
        });
        break;

      default:
        break;
    }
  };

  const getViewStatus = (state: string): string => {
    switch (state) {
      case "today":
        return dataSliced.today ? "View Less" : "View More";

      case "tomorrow":
        return dataSliced.tomorrow ? "View Less" : "View More";

      case "upcoming days":
        return dataSliced.upcoming ? "View Less" : "View More";

      default:
        return "";
    }
  };

  const checkOccurrence = (card: string): boolean => {
    switch (card) {
      case "today":
        return dataSliced.today;

      case "tomorrow":
        return dataSliced.tomorrow;

      case "upcoming days":
        return dataSliced.upcoming;

      default:
        return false;
    }
  };

  //////////////////////////////////////////////////////////

  const AnnounceWrapper = (props: WrapperI) => {
    const { title, data, occurrence } = props;
    const margin = {
      marginLeft: `5px`,
      color: !props.selected ? "#132e98" : "white",
    };
    const mainStyle = {
      maxHeight: "400px",
      overflow: "auto",
    };

    const parseHtmlContent = (content: string) => {
      const doc1 = parse(content);
      return doc1;
    };

    return (
      <Paper
        style={{
          ...tableStyle,
          backgroundColor: props.selected ? "#132e98" : "white",
          boxShadow: props.selected
            ? "none"
            : "rgb(0 0 0 / 7%) 1px 1px 20px 1px",
        }}
      >
        <Box
          className={classes.formTitle}
          style={{
            position: "static",
            margin: 0,
            marginBottom: 4,
            fontSize: "18px",
            color: "white",
            fontWeight: 600,
          }}
        >
          <span style={{ color: props.selected ? "white" : "#132e98" }}>
            {" "}
            {title}{" "}
          </span>
        </Box>
        <Box style={mainStyle}>
          {data.length ? (
            data
              .slice(0, checkOccurrence(occurrence) ? data.length : 3)
              .map((element: TeacherAnnouncementTypeI) => (
                <>
                  <Chip
                    label={element.announcement_date}
                    size="small"
                    style={{ fontSize: "11px" }}
                  />
                  <Grid>
                    <Typography
                      style={{ color: props.selected ? "white" : "" }}
                      gutterBottom
                      variant="caption"
                    >
                      Title:
                    </Typography>
                    <Typography gutterBottom variant="caption" style={margin}>
                      {element.title}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      style={{ color: props.selected ? "white" : "" }}
                      gutterBottom
                      variant="caption"
                    >
                      Description:
                    </Typography>
                    <Typography gutterBottom variant="caption" style={margin}>
                      {element.description &&
                        parseHtmlContent(element.description)}
                    </Typography>
                  </Grid>
                  <Divider
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  />
                </>
              ))
          ) : (
            <Typography
              gutterBottom
              variant="caption"
              style={{
                margin: 0,
                fontSize: "13px",
                color: props.selected ? "white" : "#132e98",
                fontWeight: 500,
              }}
            >
              No events for {occurrence}
            </Typography>
          )}
        </Box>
        {data.length > 3 && (
          <Grid container justifyContent="center">
            <a
              style={{ cursor: "pointer" }}
              onClick={() => changeViewState(occurrence)}
            >
              {getViewStatus(occurrence)}
            </a>
          </Grid>
        )}
      </Paper>
    );
  };

  return (
    <>
      <AnnounceWrapper
        data={announcementToday}
        title="Today's Announcements"
        occurrence="today"
        selected
      />
      <AnnounceWrapper
        data={announcementTomorrow}
        title="Tomorrow's Announcements"
        occurrence="tomorrow"
      />
      <AnnounceWrapper
        data={announcementUpcoming}
        title="Upcoming Announcements"
        occurrence="upcoming days"
      />
    </>
  );
}

export default TeacherAnnounce;
