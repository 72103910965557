import axios from "axios";
import { Dispatch } from "redux";
import { PARENT_API_URL } from "../../../../config/SERVER_API_URLS";
import { HeaderConfig } from "../../../Auth/Login/LoginAction";
import { SetSnackBarI, SET_SNACKBAR } from "../../../SnackbarActionTypes";

import {
    KhaltiPaymentDispatchTypes,
    ADD_KHALTI_PAYMENT_LOADING,
    ADD_KHALTI_PAYMENT_SUCCESS,
    ADD_KHALTI_PAYMENT_ERROR,
    RequsetKhaltiDataI,
} from "./KhaltiActionTypes";

export const KhaltiGuardianPay =
    (data: RequsetKhaltiDataI) =>
        async (dispatch: Dispatch<KhaltiPaymentDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: ADD_KHALTI_PAYMENT_LOADING,
                });

                const res = await axios.post(
                    `${PARENT_API_URL}/generated_invoices/`,
                    data,
                    HeaderConfig()
                );

                dispatch({
                    type: ADD_KHALTI_PAYMENT_SUCCESS,
                    payload: res.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: "Khalti Payment Done Successfully",
                    },
                });
            } catch (error) {
                dispatch({
                    type: ADD_KHALTI_PAYMENT_ERROR,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Khalti Payment Failed",
                    },
                });
            }
        };