import { Box, Chip, Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NepaliCalendar } from '../../../core-ui/NepaliCalendar/index';
import data from '../../../core-ui/NepaliCalendar/csvjson.json';
import AddIcon from '@material-ui/icons/Add';
import NepaliDate from 'nepali-date-converter';
import Popups from '../../../components/Reusable/Dialogs/Popups';
import EventCalendarForm from './EventCalendarForm';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../store';
import { GetEvents } from '../../../actions/Event/Events/EventsAction';
import moment from 'moment';
import { EventsTypeI } from '../../../actions/Event/Events/EventsActionTypes';
import { GetEventTypes } from '../../../actions/Event/EventType/EventTypeAction';
import EventCalendarDayInfo from './EventCalendarDayInfo';
import styled from 'styled-components';

const availableYears = data.reduce((total: any, cur: any) => {
  total = [...total, cur.Year];
  return total;
}, []);

const months = [
  'Baishakh',
  'Jestha',
  'Asar',
  'Shrawan',
  'Bhadau',
  'Aswin',
  'Kartik',
  'Mangsir',
  'Poush',
  'Magh',
  'Falgun',
  'Chaitra',
];

const findNumberOfDaysInMonth = (year: number, month: string) => {
  const findYear = data.find((yr) => yr.Year === year);
  if (findYear) {
    return findYear[month];
  }

  return 0;
};

// const tithi = [
//   'एकादशी',
//   'द्वादशी',
//   'त्रयोदशी',
//   'चतुर्दशी',
//   'औंसी',
//   'प्रतिपदा',
//   'द्वितीया',
//   'तृतिया',
//   'चतुर्थी',
//   'पञ्चमी',
//   'षष्ठी',
//   'सप्तमी',
//   'अष्टमी',
//   'नवमी',
//   'दशमी',
// ];

const englishWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const nepaliWeek = [
  'आइतवार',
  'सोमवार',
  'मङ्गलवार',
  'बुधवार',
  'बिहिवार',
  'शुक्रवार',
  'शनिवार',
];

const weeksIndex = [0, 1, 2, 3, 4, 5];

const daysIndex = [0, 1, 2, 3, 4, 5, 6];

function NepaliCoreCalendar(props: any) {
  const { loading } = props;
  let day = 1;

  return (
    <NepaliCalendar.TableContainer>
      {/* Loading Animation */}
      {loading && <LinearProgress />}

      <NepaliCalendar.Table>
        <NepaliCalendar.TableHead>
          <NepaliCalendar.TableRow>
            {/* This is week day name */}
            {englishWeek.map((name, index) =>
              props.showBySize === 'small' ? (
                <NepaliCalendar.TableCell key={name}>
                  {name.slice(0, 3)}
                </NepaliCalendar.TableCell>
              ) : (
                <NepaliCalendar.TableCell key={name}>
                  {nepaliWeek[index]} <br /> {name}
                </NepaliCalendar.TableCell>
              )
            )}
          </NepaliCalendar.TableRow>
        </NepaliCalendar.TableHead>

        <NepaliCalendar.TableBody>
          {weeksIndex.map((week) => (
            <NepaliCalendar.TableRow key={week}>
              {daysIndex.map((weekDay) => {
                const key = `a${week}${weekDay}`;
                const checkW1 = week === 0 && weekDay >= props.firstWeekDay;
                const totalDayCount = week * 7 + weekDay + 1;
                const checkWN =
                  week > 0 &&
                  totalDayCount <= props.numberOfDays + props.firstWeekDay;
                const curNepaliDate = new NepaliDate(
                  props.year,
                  +props.month,
                  day
                );
                const curEnglishDate = curNepaliDate.toJsDate();
                const isCurrent =
                  curEnglishDate.toDateString() === new Date().toDateString();
                const curEvents = props.findCurEvents(curEnglishDate);
                const text = curEvents.length > 0 ? curEvents[0].title : '-';
                const isHoliday =
                  curEvents && curEvents.some((event: any) => event.is_holiday);

                if (checkW1 || checkWN) {
                  return (
                    <NepaliCalendar.DayCell
                      onClick={() => props.handleDayClick(curNepaliDate)}
                      holiday={Boolean(isHoliday)}
                      current={Boolean(isCurrent)}
                      key={key}
                    >
                      {/* This is event title */}
                      {props.showBySize === 'small' ? null : (
                        <NepaliCalendar.DaySubTitle
                          onClick={(event: React.MouseEvent) => {
                            event.preventDefault();

                            if (curEvents.length > 0 && props.isAdmin) {
                              event.stopPropagation();
                              props.handleEventClick(
                                curNepaliDate,
                                curEvents[0]
                              );
                            }
                          }}
                        >
                          {text.trunc(18)}
                        </NepaliCalendar.DaySubTitle>
                      )}

                      {/* Nepali Day */}
                      <Box padding="8px 0">{day++}</Box>

                      {/* Number of events if greather than two */}
                      {props.showBySize === 'large' && curEvents.length > 1 ? (
                        <NepaliCalendar.Chip
                          size="small"
                          label={'+' + (curEvents.length - 1).toString()}
                        />
                      ) : null}

                      {/* Additional Info */}
                      {props.showBySize === 'large' && (
                        <Box display="flex" justifyContent="space-between">
                          <NepaliCalendar.DaySubTitle>
                            {/* एकादशी */} -
                          </NepaliCalendar.DaySubTitle>

                          <NepaliCalendar.DaySubTitle>
                            {curNepaliDate.toJsDate().getDate()}
                          </NepaliCalendar.DaySubTitle>
                        </Box>
                      )}
                    </NepaliCalendar.DayCell>
                  );
                } else {
                  return (
                    <NepaliCalendar.TableCell key={key}>
                      -
                    </NepaliCalendar.TableCell>
                  );
                }
              })}
            </NepaliCalendar.TableRow>
          ))}
        </NepaliCalendar.TableBody>
      </NepaliCalendar.Table>
    </NepaliCalendar.TableContainer>
  );
}

const NepaliCalendarPage = (props: any) => {
  const dispatch = useDispatch();
  const { showBySize = 'large' } = props;

  const newNepaliDate = new NepaliDate();
  newNepaliDate.setDate(1);

  const [nepaliDate, setNepaliDate] = useState(
    newNepaliDate.format('YYYY/MM/DD')
  );
  const [englishDate, setEnglishDate] = useState(
    new Date().toEnglish(nepaliDate)
  );
  const [month, setMonth] = useState(newNepaliDate.getMonth());
  const [year, setYear] = useState(newNepaliDate.getYear());
  const [onEdit, setOnEdit] = useState(false);
  const [manageEventOpen, setManageEventOpen] = useState(false);
  const [eventDayOpen, setEventDayOpen] = useState(false);
  const [eventItem, setEventItem] = useState<any>(null);
  const [selectedNepaliDate, setSelectedNepaliDate] = useState(
    new NepaliDate()
  );
  const [eventsByDate, setEventsByDate] = useState<EventsTypeI[]>([]);
  const [firstWeekDay, setFirstWeekDay] = useState(
    new Date(englishDate).getDay()
  );
  const [numberOfDays, setNumberOfDays] = useState(32);

  const eventState = useSelector((state: RootStore) => state.events);
  const role = localStorage.getItem('role') || '';
  const isAdmin = role === 'Administrator';

  const findCurEvents = (date: Date) => {
    const events = eventState.events;
    const tempEvents = events.filter((event: any) =>
      moment(date).isBetween(event.from_date, event.to_date, 'days', '[]')
    );

    if (tempEvents && tempEvents.length > 0) {
      return tempEvents;
    }

    return [];
  };

  useEffect(() => {
    const startDate = new NepaliDate(year, month, 1).format('YYYY/MM/DD');
    const endDate = new NepaliDate(year, month, numberOfDays).format(
      'YYYY/MM/DD'
    );

    dispatch(
      GetEvents({
        fromDate: new Date().toEnglish(startDate),
        toDate: new Date().toEnglish(endDate),
      })
    );
    dispatch(GetEventTypes());
  }, [year, month, numberOfDays]);

  useEffect(() => {
    const date: any = new NepaliDate(nepaliDate);
    date.setMonth(month);
    date.setYear(year);
    const dateBs = date.format('YYYY/MM/DD');

    setNepaliDate(dateBs);
    setEnglishDate(new Date().toEnglish(dateBs));
    setFirstWeekDay(date.toJsDate().getDay());
    setNumberOfDays(
      findNumberOfDaysInMonth(date.getYear(), months[date.getMonth()])
    );
  }, [month, year]);

  const handleMonthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMonth(parseInt(event.target.value as string));
  };

  const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as number);
  };

  const handleManageEventClose = () => {
    setManageEventOpen(false);
  };

  const handleManageEventOpen = () => {
    setManageEventOpen(true);
  };

  const handleEventDayClose = () => {
    setEventDayOpen(false);
  };

  const handleEventDayOpen = () => {
    setEventDayOpen(true);
  };

  const handleAddNew = (date?: NepaliDate) => {
    const finalDate = date ? date : new NepaliDate();
    setOnEdit(false);
    setSelectedNepaliDate(finalDate);
    handleManageEventOpen();
  };

  const handleDayClick = (date: NepaliDate) => {
    setOnEdit(false);
    setSelectedNepaliDate(date);
    handleEventDayOpen();
    setEventsByDate(findCurEvents(date.toJsDate()));
  };

  const handleEventClick = (date: NepaliDate, event: EventsTypeI) => {
    console.log('eventt', event);
    setOnEdit(true);
    setSelectedNepaliDate(date);
    handleManageEventOpen();
    setEventItem(event);
  };

  NepaliCalendar.TableCell = styled(NepaliCalendar.TableCell)`
    &.MuiTableCell-root {
      width: ${showBySize === 'small' ? 'auto' : '105px'} !important;
      height: ${showBySize === 'small' ? 'auto' : '95px'} !important;
      border-color: ${showBySize === 'small'
        ? 'transparent'
        : 'rgb(222, 222, 222)'} !important;
    }
  `;

  NepaliCalendar.DayCell = styled(NepaliCalendar.DayCell)`
    &.MuiTableCell-root {
      width: ${showBySize === 'small' ? 'auto' : '105px'} !important;
      height: ${showBySize === 'small' ? 'auto' : '95px'} !important;
      font-size: ${showBySize === 'small' ? '14px' : '18px'} !important;
      border-color: ${showBySize === 'small'
        ? 'transparent'
        : 'rgb(222, 222, 222)'} !important;

      border-radius: ${(props) =>
        props.current === true && showBySize === 'small' ? '50%' : '0px'};
    }
  `;

  return (
    <NepaliCalendar>
      <Grid container spacing={1}>
        <Grid item>
          <NepaliCalendar.FormControl size="small" variant="outlined">
            <NepaliCalendar.Select value={year} onChange={handleYearChange}>
              {availableYears.map((year: number) => (
                <NepaliCalendar.MenuItem value={year} key={year}>
                  {year} BS
                </NepaliCalendar.MenuItem>
              ))}
            </NepaliCalendar.Select>
          </NepaliCalendar.FormControl>
        </Grid>

        <Grid item>
          <NepaliCalendar.FormControl size="small" variant="outlined">
            <NepaliCalendar.Select value={month} onChange={handleMonthChange}>
              {months.map((month: string, index: number) => (
                <NepaliCalendar.MenuItem value={index} key={month}>
                  {month}
                </NepaliCalendar.MenuItem>
              ))}
            </NepaliCalendar.Select>
          </NepaliCalendar.FormControl>
        </Grid>

        {isAdmin && (
          <Grid item style={{ marginLeft: 'auto' }}>
            <NepaliCalendar.Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              size="large"
              onClick={() => handleAddNew()}
            >
              Add Event
            </NepaliCalendar.Button>
          </Grid>
        )}
      </Grid>

      <NepaliCoreCalendar
        month={month}
        year={year}
        firstWeekDay={firstWeekDay}
        numberOfDays={numberOfDays}
        isAdmin={isAdmin}
        findCurEvents={findCurEvents}
        handleDayClick={handleDayClick}
        handleEventClick={handleEventClick}
        showBySize={showBySize}
        loading={eventState.loading}
      ></NepaliCoreCalendar>

      <Popups
        openPopup={manageEventOpen}
        setOpenPopup={setManageEventOpen}
        onClose={handleManageEventClose}
        maxWidth="xs"
        title={onEdit ? 'Update Event' : 'Add Event'}
      >
        <EventCalendarForm
          date={selectedNepaliDate.format('YYYY-MM-DD')}
          onClose={handleManageEventClose}
          onEdit={onEdit}
          event={eventItem}
        />
      </Popups>

      <Popups
        openPopup={eventDayOpen}
        setOpenPopup={setEventDayOpen}
        onClose={handleEventDayClose}
        maxWidth="md"
        title={selectedNepaliDate.format('MMMM DD, YYYY, ddd')}
      >
        <EventCalendarDayInfo
          date={selectedNepaliDate.format('YYYY-MM-DD')}
          onClose={handleEventDayClose}
          onEdit={onEdit}
          event={eventItem}
          eventsByDate={eventsByDate}
          handleEventClick={handleEventClick}
          handleDayClick={handleAddNew}
          isAdmin={isAdmin}
        />
      </Popups>
    </NepaliCalendar>
  );
};

export default NepaliCalendarPage;
