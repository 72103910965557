import React, { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { FullSubModuleLayout } from "../../../../components/Reusable/Layouts/SubModuleLayout";
import ParentsDashSidebar from "../../../../components/ParentsDash/ParentsDashSidebar";
import ParentEnoteNav from "./ParentEnotesNav";
import ParentEnoteList from "./ParentEnotesList";
import PageHeader from "../../components/Header/PageHeader";
import PageWrapper from "../../components/PageWrapper";

interface CategoryNavI {
  id: string;
  name: string;
}

const ParentEnotePage = () => {
  const [categoryNav, setCategoryNav] = useState<CategoryNavI | null>(null);
  const nav = <ParentsDashSidebar Enotes />;

  const onCategoryClick = (val: CategoryNavI) => {
    setCategoryNav(val);
  };

  return (
    <Box>
      <PageHeader title="Child Enote" />

      <PageWrapper>
        <Grid container>
          <Grid item xs={3}>
            <ParentEnoteNav categoryNav={onCategoryClick} />
          </Grid>
          <Grid item xs={9}>
            <ParentEnoteList enoteCategory={categoryNav} />
          </Grid>
        </Grid>
      </PageWrapper>
    </Box>
  );
};

export default ParentEnotePage;
