import React, { useState } from 'react';
import { useSidebar } from '../../Reusable';
import { useSidebarStyles } from '../../Styles/SidebarStyles';
import { QueueOutlined } from '@material-ui/icons';
import SidebarTitle from '../../Reusable/Titles/SidebarTitle';

interface PropsI {
  dayBook?: boolean;
  feeStatement?: boolean;
  feeIncome?: boolean;
  dueReport?: boolean;
  voidReport?: boolean;
  discountSummary?: boolean;
}

interface DataSetI {
  text: string;
  url: string;
  activeStatus?: boolean;
}

const AccountantFeeReportSidebar = (props: PropsI) => {
  const classes = useSidebarStyles();

  const [dayBook] = useState(props.dayBook);
  const [feeStatement] = useState(props.feeStatement);
  const [feeIncome] = useState(props.feeIncome);
  const [dueReport] = useState(props.dueReport);
  const [voidReport] = useState(props.voidReport);
  const [discountSummary] = useState(props.discountSummary);

  const dataSet: DataSetI[] = [
    { text: 'Day Book', url: 'day-book', activeStatus: dayBook },
    {
      text: 'Fee Statement',
      url: 'fee-statement',
      activeStatus: feeStatement,
    },
    { text: 'Fee Income', url: 'fee-income', activeStatus: feeIncome },
    {
      text: 'Due Report',
      url: 'due-report',
      activeStatus: dueReport,
    },
    { text: 'Void Report', url: 'void-report', activeStatus: voidReport },
    {
      text: 'Discount Summary',
      url: 'discount-summary',
      activeStatus: discountSummary,
    },
  ];
  const { CustomSidebar } = useSidebar(dataSet, 'FINANCE');
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<QueueOutlined />}>Fee Reports</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default AccountantFeeReportSidebar;
