import { Grid, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Tupple } from '../../../../../actions';
import { GetLeaveTypes } from '../../../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import {
  AddTeacherLeave,
  UpdateTeacherLeave,
} from '../../../../../actions/TeacherDash/TeacherLeave/TeacherLeaveAction';
import { RootStore } from '../../../../../store';
import { FormSendButon } from '../../../../../components/Reusable/Buttons/FormButton';
import CustomizedNepaliDatePicker from '../../../../../components/Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect from '../../../../../components/Reusable/Inputs/Select';
import CustomizedTextField from '../../../../../components/Reusable/Inputs/TextField';
import { useFormStyles } from '../../../../../components/Styles/FormStyles';
import { dateConverterBsToAd } from '../../../../../components/utils/dateConverter';
import firstWordCapital from '../../../../../components/utils/firstWordCapital';
import { CUR_NEPALI_DATE } from '../../../../../components/utils/nepaliDateUtils';
import { isObjectEmpty } from '../../../../../components/utils/utils';

const TeacherLeaveAdd = (props: any) => {
  const { editable, item } = props;
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const [dateFrom, setDateFrom] = useState<string>(CUR_NEPALI_DATE);
  const [dateTo, setDateTo] = useState<string>(CUR_NEPALI_DATE);
  const [leaveType, setLeaveType] = useState<Tupple | null>(null);
  const [serverErrors, setServerErrors] = useState<any>(null);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [errorFields] = useState<string[]>(['error', 'non_field_errors']);

  const { handleSubmit, register, errors, setError, clearErrors, setValue } =
    useForm<any>({
      mode: 'onChange',
    });

  useEffect(() => {
    dispatch(GetLeaveTypes());
  }, []);

  const leaveTypeState = useSelector((state: RootStore) => state.leave_type);
  const teacherLeave = useSelector((state: RootStore) => state.teacher_leave);
  const errorsData = teacherLeave.errors;
  const loading = teacherLeave.loading;
  const initialErrorsData = useRef(errorsData);

  const LEAVE_TYPE_CHOICES = leaveTypeState.leave_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  /* Close modal when user has submitted form, if state is not loading,
  has not server errors and has no hook-form errors */
  useEffect(() => {
    if (hasSubmitted && !loading) {
      if (!errorsData && isObjectEmpty(errors)) {
        props.onClose();
      }
    }
  }, [teacherLeave, hasSubmitted]);

  // Set hook-form error on server error
  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData != null && errorsData?.error) {
        const keys = Object.keys(errorsData.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  // handle form submit
  const onSubmit = (data: any) => {
    if (data && dateFrom && dateTo && leaveType) {
      const leaveData = {
        leave_type: leaveType.key,
        leave_name: leaveType.value,
        from_date: dateConverterBsToAd(dateFrom),
        to_date: dateConverterBsToAd(dateTo),
        reason_for_leave: data.reason_for_leave,
      };

      if (editable) {
        // dispatch(UpdateStudentLeave(item.id, leaveData));
        dispatch(UpdateTeacherLeave(item.id, leaveData));
        console.log('FIRED!');
      } else {
        dispatch(AddTeacherLeave(leaveData));
      }

      console.log('leavedata', leaveData);
      setHasSubmitted(true);
    }
  };

  useEffect(() => {
    if (editable) {
      setDateFrom(item.leave_date);
      setDateTo(item.days);
      setValue('reason_for_leave', item.description);
      setLeaveType(
        LEAVE_TYPE_CHOICES.find(
          (childItem: any) => childItem.key === item.leave_type_id
        ) || LEAVE_TYPE_CHOICES[0]
      );
    }
  }, [editable]);

  useEffect(() => {
    errorFields.map((field) => {
      clearErrors(field);
    });
  }, [dateFrom, dateTo]);

  const handleDateFrom = (value: any) => {
    setDateFrom(value?.toString());
    clearErrors('leave_from');
  };

  const handleDateTo = (value: any) => {
    setDateTo(value?.toString());
    clearErrors('leave_to');
  };

  return (
    <>
      <Paper elevation={0}>
        <div className={classes.modalContent}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            {serverErrors &&
              serverErrors?.error &&
              Object.keys(serverErrors.error)
                .filter((elem: any) => errorFields.includes(elem))
                .map((elem: any, index: number) => {
                  return (
                    <div className={classes.serversidemessages} key={index}>
                      <Alert severity="error">
                        {firstWordCapital(serverErrors?.error[elem][0])}
                      </Alert>
                    </div>
                  );
                })}

            <Grid container>
              <Grid container>
                <Grid item xs={6} className={classes.formWrapper}>
                  <CustomizedSelect
                    label="Leave Type"
                    name="leave_type"
                    options={LEAVE_TYPE_CHOICES}
                    value={leaveType}
                    setValue={setLeaveType}
                    required
                    error={errors['leave_type']?.message}
                    inputRef={register({
                      required: 'Leave type must be selected.',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} item xs={12}>
                <Grid item xs={6} className={classes.formWrapper}>
                  <CustomizedNepaliDatePicker
                    label="Leave from Date"
                    value={dateFrom}
                    setValue={handleDateFrom}
                    name="from_date"
                    required
                    error={errors['leave_from']?.message}
                    inputRef={register({
                      required: true,
                    })}
                  />
                </Grid>

                <Grid item xs={6} className={classes.formWrapper}>
                  <CustomizedNepaliDatePicker
                    label="Leave to Date"
                    value={dateTo}
                    setValue={handleDateTo}
                    name="to_date"
                    required
                    error={errors['leave_to']?.message}
                    inputRef={register({
                      required: true,
                    })}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.formWrapper}>
                <CustomizedTextField
                  label="Reason for Leave"
                  placeholder="Label"
                  name="reason_for_leave"
                  multiline
                  rows={4}
                  required
                  error={errors['reason_for_leave']?.message}
                  inputRef={register({
                    required: 'Reason for leave is required.',
                  })}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-end"
              style={{ paddingBottom: '8px' }}
            >
              <FormSendButon disabled={loading} />
            </Grid>
          </form>
        </div>
      </Paper>
    </>
  );
};

export default TeacherLeaveAdd;
