import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useDashboardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "58px",
      width: "100%",
      maxWidth: 360,
    },
    nested: {
      paddingLeft: theme.spacing(2),
      marginLeft: theme.spacing(2),
      paddingTop: 5,
      paddingBottom: 5,
      // borderRadius: "40px 0 0 40px",

      "&.Mui-selected": {
        color: theme.palette.primary.main,
        background: "rgba(0, 0, 0, 0.12) !important",
        "& > div > svg": {
          color: theme.palette.primary.main,
        },
      },
    },
    header: {
      paddingTop: 24,
      textAlign: "center",
      color: "black",
      marginBottom: 30,
    },
    sidenav: {
      color: "black",
      position: "fixed",
      top: 0,
      left: 0,
      width: "240px",
      minHeight: "100vh",
    },
    listContainer: {
      paddingLeft: "25px",
      maxHeight: "calc(100vh - 150px)",
      overflowY: "scroll",
      overflowX: "hidden",
    },
    listItem: {
      // borderRadius: "40px 0 0 40px",
      "&.Mui-selected": {
        background: theme.palette.primary.main,
        color: "white",
        "& > div > svg": {
          color: `white !important`,
        },
        "&:hover": {
          background: theme.palette.primary.main,
          color: "white",
        },
      },
    },
    listItemText: {
      "& > span": {
        fontSize: 13,
        fontWeight: 500,
      },
    },
    footer: {
      padding: "16px 16px 24px 24px",
      marginTop: "auto",
      color: "black",
    },
  })
);
