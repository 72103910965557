export const GET_TEACHER_EXAM_MARKS_LOADING = "GET_TEACHER_EXAM_MARKS_LOADING";
export const GET_TEACHER_EXAM_MARKS_ERROR = "GET_TEACHER_EXAM_MARKS_ERROR";
export const GET_TEACHER_EXAM_MARKS_SUCCESS = "GET_TEACHER_EXAM_MARKS_SUCCESS";

export const ADD_TEACHER_EXAM_MARKS_LOADING = "ADD_TEACHER_EXAM_MARKS_LOADING";
export const ADD_TEACHER_EXAM_MARKS_ERROR = "ADD_TEACHER_EXAM_MARKS_ERROR";
export const ADD_TEACHER_EXAM_MARKS_SUCCESS = "ADD_TEACHER_EXAM_MARKS_SUCCESS";

type GetTeacherExamMarksTypeI = {
  student: T_GetStudentMarksI[];
  subject_mark_detail: T_GetMarksDetailI[];
};

export type T_GetStudentMarksI = {
  id: string;
  student_first_name: string;
  student_last_name: string;
  marks_obtained: string;
  remarks: string;
  student_photo: string;
};

export type T_GetMarksDetailI = {
  full_marks: number;
  pass_marks: number;
};

//===============================<START>GET TEACHER EXAM MARKS<START>======================//

export interface GetTeacherExamMarksLoading {
  type: typeof GET_TEACHER_EXAM_MARKS_LOADING;
}

export interface GetTeacherExamMarksError {
  type: typeof GET_TEACHER_EXAM_MARKS_ERROR;
}

export interface GetTeacherExamMarksSuccess {
  type: typeof GET_TEACHER_EXAM_MARKS_SUCCESS;
  payload: GetTeacherExamMarksTypeI;
}
//===============================<END>GET TEACHER EXAM MARKS<END>======================//

//===============================<START>GET TEACHER EXAM MARKS<START>======================//

export interface AddTeacherExamMarksLoading {
  type: typeof ADD_TEACHER_EXAM_MARKS_LOADING;
}

export interface AddTeacherExamMarksError {
  type: typeof ADD_TEACHER_EXAM_MARKS_ERROR;
}

export interface AddTeacherExamMarksSuccess {
  type: typeof ADD_TEACHER_EXAM_MARKS_SUCCESS;
  payload: { message: string };
}
//===============================<END>GET TEACHER EXAM MARKS<END>======================//

export type TeacherExamMarksDispatchTypes =
  | GetTeacherExamMarksLoading
  | GetTeacherExamMarksError
  | GetTeacherExamMarksSuccess
  | AddTeacherExamMarksLoading
  | AddTeacherExamMarksError
  | AddTeacherExamMarksSuccess;
