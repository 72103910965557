import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../components/utils/PrivateRoute";
import PageNotFound from "./pages/Error/PageNotFound";
import { GetStudentProfile } from "../../actions/StudentDash/StudentProfile/StudentProfileAction";
import SuperAdminDashboardSidebar from "./SuperAdminDashboardSidebar";
import { SuperAdminDashboardPage } from "./pages";
import ParentLiveClassPage from "../ParentDashboard/pages/LiveClasses/ParentLiveClassPage";
import SchoolPage from "./pages/School/SchoolPage";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    background: theme.palette.primary.main,
    minHeight: "100vh",
  },
  container: {
    display: "block",
    position: "relative",
  },
}));

const StudentDashboardApp = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <SuperAdminDashboardSidebar />

        <Switch>
          <PrivateRoute exact path="/" component={SuperAdminDashboardPage} />
          <PrivateRoute exact path="/school-list" component={SchoolPage} />
          <PrivateRoute component={SuperAdminDashboardPage} />
        </Switch>
      </Box>
    </Box>
  );
};

export default StudentDashboardApp;
