import {
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../../../actions/SnackbarAction";
import {
  CompleteLessonPlan,
  DeleteLessonPlan,
  GetLessonPlan,
} from "../../../../../actions/TeacherDash/LessonPlan/LessonPlanAction";
import { GetTeachingStatus } from "../../../../../common/GetStatus";
import { RootStore } from "../../../../../store";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from "../../../../../components/Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../../../../components/Reusable/Dialogs/DeleteDialog";
import Popups from "../../../../../components/Reusable/Dialogs/Popups";
import useTable from "../../../../../components/Reusable/useStudentTable";
import { useTableStyles } from "../../../../../components/Styles/TableStyles";
import LessonPlanView from "./LessonPlanView";

interface HeadCellsI {
  id: string;
  label: string;
}

interface LessonTypeI {
  id: number;
  unit: number;
  unit_title: string;
  sub_unit_title: string;
  name: string;
  status: string;
  start_date: string;
  end_date: string;
  completion_date?: string;
}

const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "unit_title", label: "Unit Title" },
  { id: "sub_unit_title", label: "Sub Unit Title" },
  { id: "lesson_title", label: "Lesson Title" },
  { id: "status", label: "Status" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  { id: "actions", label: "Actions" },
];

const LessonPlanTable = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { data } = props;

  const [tableData, setTableData] = useState<LessonTypeI[]>([]);
  const [item, setItem] = useState<any>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false);
  // const [openCreateModalPopup, setOpenCreateModalPopup] = useState(false);
  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [index, setIndex] = useState<number>();
  const [completeID, setCompleteID] = useState<number>();
  const [check, setCheck] = React.useState({
    checkedA: false,
  });

  //*---------------------------------REDUX STATE MANAGEMENT -----------------------------------*//

  useEffect(() => {
    data?.class &&
      data.subject &&
      dispatch(GetLessonPlan(data.subID, data.classID.grade));
  }, [data]);

  const LessonPlanSelector = useSelector(
    (state: RootStore) => state.teacher_lessonPlan
  );

  useEffect(() => {
    const items = LessonPlanSelector.lesson_plan;
    setTableData(items);
  }, [LessonPlanSelector]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const handleDeleteModal = (value: boolean, item: LessonTypeI) => {
    setItem(item);
    if (item.completion_date) {
      dispatch(
        setSnackbar(true, "warning", "Cannot Delete Completed Lesson Plan")
      );
    } else {
      setDeleteModalOpen(value);
    }
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && item?.id != undefined && dispatch(DeleteLessonPlan(item.id));
    setDeleteModalOpen(false);
  };

  const handleCompleteClick = (value: boolean, item_id: number) => {
    setCompleteModalOpen(value);
    setCompleteID(item_id);
  };

  const handleCompleteModalClose = (value: boolean) => {
    setCompleteModalOpen(value);
  };

  const onCompleteConfirmed = (confirmed: boolean) => {
    if (confirmed && completeID) {
      dispatch(CompleteLessonPlan(completeID, data));
    }
    setCompleteModalOpen(false);
  };

  // const handleEditClicked = (data: any, index: number) => {
  //   setItem(data);
  //   setIndex(index + 1);
  // };

  const handleViewClicked = (data: any, index: number) => {
    setItem(data);
    setIndex(index + 1);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  };

  const getCustomTableRow = (item: LessonTypeI, index: number) => {
    return (
      <StyledTableRow key={index} hover tabIndex={-1}>
        <StyledTableCell align="center" className={classes.newcell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.unit_title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.sub_unit_title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          <GetTeachingStatus
            status={item.completion_date ? "completed" : "remaining"}
          />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.start_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.end_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.threeCell}>
          <ItemViewButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewClicked(item, index)
            }
          />
          <Switch
            onClick={() => handleCompleteClick(true, item.id)}
            checked={check.checkedA}
            onChange={handleSwitchChange}
            disabled={item.completion_date ? true : false}
            color="primary"
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <TableContainer
        label=""
        loading={false}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        disableSearch
        disablePrint
        hiddenCols={[-1]}
      />
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        maxWidth="xl"
        fullWidth={true}
        title="View Lesson Plan"
        colored
      >
        <LessonPlanView item={item} indexN={index} />
      </Popups>
      <CustomizedDialogs
        open={completeModalOpen}
        onClose={handleCompleteModalClose}
        title="Complete Lesson"
        deleteConfirmed={onCompleteConfirmed}
        dialogTitle="Are you sure to complete this lesson?"
        okText="Complete"
      />
    </>
  );
};

export default LessonPlanTable;
