import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_MY_TEACHER_LOADING,
  GET_MY_TEACHER_ERROR,
  GET_MY_TEACHER_SUCCESS,
  MyTeacherDispatchTypes,
} from "./StudentTeacherActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import {
  STUDENT_API_URL,
  TEACHER_API_URL,
} from "../../../config/SERVER_API_URLS";

export const GetMyTeacher =
  () => async (dispatch: Dispatch<MyTeacherDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_MY_TEACHER_LOADING,
      });

      const res = await axios.get(
        `${STUDENT_API_URL}/my-teacher/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_MY_TEACHER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MY_TEACHER_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "My Teacher Loading Failed",
      //   },
      // });
    }
  };
